import { SearchContext } from 'candidates-search/contexts'
import { useContext } from 'react'
import { Text } from 'shared/components/atoms'

export const ScreeningDescription = () => {
  const { search } = useContext(SearchContext)
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center gap-2">
        <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
          Job Listing:
        </Text>
        <Text size="text-sm" weight="font-medium">
          {search?.listing?.title || ''}
        </Text>
      </div>
      <div className="flex flex-row items-center gap-2">
        <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
          Company:
        </Text>
        <Text size="text-sm" weight="font-medium">
          {search?.listing?.company?.name || ''}
        </Text>
      </div>
    </div>
  )
}
