import { useSensitiveInformation } from './useSensitiveInformation'

interface SensitiveInformationProps {
  children: React.ReactNode | JSX.Element | ((hidden: boolean) => JSX.Element)
}

// In order to have proper behavior of this component, its parent must be a flex element
export const SensitiveInformation = ({ children }: SensitiveInformationProps) => {
  const { blurSensitiveInfo } = useSensitiveInformation()

  if (typeof children === 'function') return children(blurSensitiveInfo)

  if (blurSensitiveInfo) {
    return (
      <span className="backdrop-blur-none overflow-hidden rounded-[3px]">
        <span className="blur overflow-hidden inline-flex">{children}</span>
      </span>
    )
  }

  return children
}
