import classNames from 'classnames'
import { Text, Tooltip } from 'shared/components/atoms'
import { ReferralStatus } from 'modules/referrals/enum'
import { ReactComponent as InfoIcon } from 'icons/info-icon.svg'

interface ReferralStatusBadgeProps {
  status: ReferralStatus
  statusDescription?: string
  companyName?: string
  className?: string
}

export const ReferralStatusBadge = ({
  status,
  statusDescription,
  className,
}: ReferralStatusBadgeProps) => {
  const defaultBadge = {
    label: (
      <Text size="text-xs" weight="font-medium" className="text-neutral-dark">
        Unknown
      </Text>
    ),
    background: 'bg-neutral-lightest',
  }

  const badge =
    {
      [ReferralStatus.Hired]: {
        label: (
          <Text size="text-xs" weight="font-medium" className="text-neutral-day">
            Hired
          </Text>
        ),
        background: 'bg-neutral-darkest',
      },
      [ReferralStatus.Probation]: {
        label: (
          <Text size="text-xs" weight="font-medium" className="text-neutral-day">
            Hired - on probation
          </Text>
        ),
        background: 'bg-neutral-darkest',
      },
      [ReferralStatus.LeftBeforeProbationPeriod]: {
        label: (
          <Text size="text-xs" weight="font-medium" className="text-warning-darker">
            Left during trial period
          </Text>
        ),
        background: 'bg-warning-lighter',
      },
      [ReferralStatus.Pending]: {
        label: (
          <Text size="text-xs" weight="font-medium" className="text-neutral-dark">
            Pending
          </Text>
        ),
        background: 'bg-neutral-lightest',
      },
      [ReferralStatus.ProfileCompleted]: {
        label: (
          <Text size="text-xs" weight="font-medium" className="text-success-darker">
            Completed profile
          </Text>
        ),
        background: 'bg-success-lighter',
      },
      [ReferralStatus.Rejected]: {
        label: (
          <Text size="text-xs" weight="font-medium" className="text-warning-darker">
            Not a fit
          </Text>
        ),
        background: 'bg-warning-lighter',
        tooltip: statusDescription,
      },
      [ReferralStatus.SignedUp]: {
        label: (
          <Text size="text-xs" weight="font-medium" className="text-info-dark">
            Signed up
          </Text>
        ),
        background: 'bg-info-lighter',
        tooltip: null,
      },
    }[status] || defaultBadge

  return (
    <div className="flex gap-2 items-center print:hidden">
      <div className={classNames('flex w-fit px-2 py-1 rounded', badge.background, className)}>
        {badge.label}
      </div>
      {Boolean(badge.tooltip) && (
        <Tooltip content={badge.tooltip!}>
          <InfoIcon />
        </Tooltip>
      )}
    </div>
  )
}
