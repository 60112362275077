import { Suspense, lazy } from 'react'
import classNames from 'classnames'

import { TertiaryButton } from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'
import { Tags } from 'shared/components/atoms'
import { CompanyExperience } from './CompanyExperience'
import { ImportingButton } from '../ImportingButton'
import { ProfileEmptyStateSection } from '../ProfileEmptyStateSection'
import { ProfileSectionWrapper } from '../ProfileSectionWrapper'
import { ProfileSectionTitle } from '../ProfileSectionTitle'

import { useProfileExperiences } from 'profile/hooks'
import { WorkExperience } from 'modules/work-experience'
import { GroupedExperience } from 'pages/JobSeekerProfile/types'
import { TruncateButton } from 'shared/components/molecules'
import { useTruncateItems } from 'shared/components/molecules/TruncateButton/hooks'

const OnlineLearningIllustration = lazy(() =>
  import('icons/ilustrations/streamlinehq-time-in-for-work.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface ProfileWorkExperienceSectionProps {
  readOnly: boolean
  experiences: Array<WorkExperience>
  groupedExperiences: Array<GroupedExperience>
  onAddWorkExperienceClick(): void
  onEditWorkExperienceClick(workExperience: WorkExperience): void
  wrapperClassName?: string
  titleSize?: 'heading' | 'large-heading'
  profileId?: string
  placeholder?: JSX.Element
  shouldTruncate?: boolean
}

export const ProfileWorkExperienceSection = ({
  readOnly,
  experiences,
  groupedExperiences,
  onAddWorkExperienceClick,
  onEditWorkExperienceClick,
  wrapperClassName,
  titleSize,
  profileId,
  placeholder,
  shouldTruncate,
}: ProfileWorkExperienceSectionProps) => {
  const { totalWorkExperienceLabel, totalManagementWorkExperienceLabel } =
    useProfileExperiences(experiences)

  const { visibleItems, truncatedItemsCount, setTruncate } = useTruncateItems<GroupedExperience>(
    groupedExperiences,
    shouldTruncate,
  )

  const tags = [
    { id: 1, value: totalWorkExperienceLabel, label: `${totalWorkExperienceLabel} of exp.` },
    {
      id: 2,
      value: totalManagementWorkExperienceLabel,
      label: `${totalManagementWorkExperienceLabel} in people management`,
    },
  ].filter(({ value }) => Boolean(value))

  const handleTruncateClick = () => setTruncate(false)

  if (!groupedExperiences.length && readOnly) return placeholder ?? null

  return (
    <ProfileSectionWrapper
      className={wrapperClassName}
      aria-labelledby="work-experience-title"
      role="region"
    >
      {Boolean(groupedExperiences.length) && (
        <ContentWrapper>
          <ProfileSectionTitle
            readOnly={readOnly}
            titleSize={titleSize}
            title="Work experience"
            onButtonClick={onAddWorkExperienceClick}
            id="work-experience-title"
          >
            <Tags
              isReadOnly
              values={tags}
              background="bg-neutral-lightest"
              tagClassName="!text-xs !py-0 h-6"
            />
          </ProfileSectionTitle>
        </ContentWrapper>
      )}
      <ContentWrapper>
        {Boolean(groupedExperiences.length) && (
          <ul className="flex flex-col gap-10">
            {visibleItems.map((experience, index) => (
              <CompanyExperience
                readOnly={readOnly}
                key={index}
                companyExperience={experience}
                onEditWorkExperienceClick={onEditWorkExperienceClick}
              />
            ))}
          </ul>
        )}
        <div className={classNames({ hidden: groupedExperiences.length })}>
          <ProfileEmptyStateSection
            id="work-experience-title"
            title="Work experiences"
            content="Add your work experiences, main skills, and share the details of what you did in each position."
            actions={
              <div className="flex flex-col sm:flex-row gap-4">
                <ImportingButton profileId={profileId!} readOnly={readOnly} />
                <TertiaryButton size="sm" onClick={onAddWorkExperienceClick}>
                  Add manually
                </TertiaryButton>
              </div>
            }
          >
            <Suspense fallback={null}>
              <OnlineLearningIllustration className="h-[150px] min-w-[150px]" />
            </Suspense>
          </ProfileEmptyStateSection>
        </div>
      </ContentWrapper>
      <TruncateButton truncatedItemsCount={truncatedItemsCount} onClick={handleTruncateClick}>
        Show other {truncatedItemsCount} work experiences
      </TruncateButton>
    </ProfileSectionWrapper>
  )
}
