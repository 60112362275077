import { SignUp as ClerkSignUpWidget } from '@clerk/clerk-react'

import { signUpAppearance } from 'authentication/consts'
import { AppearanceStyles } from 'authentication/consts/appearance'

interface SignUpFormProps extends AppearanceStyles {
  redirectUrl: string | null
}

export const SignUpForm = ({
  redirectUrl,
  centralized = false,
  theme,
  hideLogo,
}: SignUpFormProps) => {
  return (
    <ClerkSignUpWidget
      signInUrl="/login"
      redirectUrl={redirectUrl}
      appearance={signUpAppearance({ centralized, theme, hideLogo })}
    />
  )
}
