export enum EnglishLevel {
  Proficient = 'proficient',
  Independent = 'independent',
  Basic = 'basic',
}

export enum EnglishVettingProgress {
  NotRecorded = 'not_recorded',
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  WithIssues = 'with_issues',
  RecordAgain = 'record_again',
  Paused = 'paused',
  Disqualified = 'disqualified',
  Done = 'done',
  Unknown = 'unknown',
}

export const selfEvaluationLabel = {
  [EnglishLevel.Basic]: 'Self-evaluated as (A1, A2)',
  [EnglishLevel.Independent]: 'Self-evaluated as (B1, B2)',
  [EnglishLevel.Proficient]: 'Self-evaluated as (C1, C2)',
}
