import { Text } from 'shared/components/atoms'
import { useDate } from 'shared/hooks'
import { useFormatting } from 'shared/hooks'

interface Props {
  seconds: number
  className?: string
}

export const Countdown = ({ seconds, className }: Props) => {
  const { formatTwoDigitsNumber } = useFormatting()

  const isValueGreaterThanZero = seconds > 0

  const { getTimeToDisplay } = useDate()
  const {
    hours,
    minutes,
    seconds: secondsToDisplay,
  } = getTimeToDisplay(isValueGreaterThanZero ? seconds : 0)

  return (
    <Text size="text-sm" className={className}>
      {formatTwoDigitsNumber(hours)}:{formatTwoDigitsNumber(minutes)}:
      {formatTwoDigitsNumber(secondsToDisplay)} to reply
    </Text>
  )
}
