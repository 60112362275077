import { CheckboxGroupOption } from 'shared/components/atoms'
import { ReferralStatus } from 'modules/referrals/enum'

export const statusOptions = [
  {
    name: 'pending',
    label: 'Pending',
  },
  {
    name: 'signedUp',
    label: 'Signed up',
  },
  {
    name: 'profileCompleted',
    label: 'Profile completed',
  },
  {
    name: 'hired',
    label: 'Hired',
  },
  {
    name: 'Probation',
    label: 'Hired on probation',
  },
  {
    name: 'rejected',
    label: 'Not a fit',
  },
  {
    name: 'leftOnProbation',
    label: 'Left during trial period',
  },
] as Array<CheckboxGroupOption>

export const statusMapping = {
  leftOnProbation: ReferralStatus.LeftBeforeProbationPeriod,
  pending: ReferralStatus.Pending,
  signedUp: ReferralStatus.SignedUp,
  profileCompleted: ReferralStatus.ProfileCompleted,
  hired: ReferralStatus.Hired,
  probation: ReferralStatus.Probation,
  rejected: ReferralStatus.Rejected,
} as { [x: string]: ReferralStatus }
