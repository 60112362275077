import { Text } from 'shared/components/atoms'

interface Props {
  text: string
  image: string
  name: string
  role: string
}

export const TestimonialCard = ({ text, image, name, role }: Props) => (
  <div className="bg-neutral-day bg-opacity-5 rounded-2xl flex flex-col gap-y-8 p-6 mx-4 w-[420px]">
    <Text size="text-xl" className="text-neutral-300 text-left">
      {text}
    </Text>
    <div className="flex gap-x-4">
      <img src={image} alt="" className="h-14 w-14" />
      <div className="flex flex-col justify-center">
        <Text size="text-base" weight="font-medium" className="text-neutral-300 text-left">
          {name}
        </Text>
        <Text className="text-neutral-400">{role}</Text>
      </div>
    </div>
  </div>
)
