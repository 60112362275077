import { useMutation, useQueryClient } from 'react-query'

import { useMonitoring, useNetwork } from 'shared/hooks'
import { Roles } from 'account/enums'

export const useWorkspaceSignUp = (id: string | null) => {
  const { post } = useNetwork()
  const client = useQueryClient()
  const { captureException } = useMonitoring()

  const { mutateAsync: doWorkspaceOnboarding, isLoading } = useMutation(
    async () =>
      post<{ jobListingId: string | null; role: Roles } | null>('/sign-ups', {
        linkId: id,
      })
        .then(({ data }) => {
          return data
        })
        .catch((error: RequestError) => {
          if (error.response?.status !== 409) captureException(error)
          return null
        }),
    {
      onSuccess: async () => {
        await client.invalidateQueries('pending-agreements')
        await client.invalidateQueries('accounts/me')
      },
    },
  )

  return { doWorkspaceOnboarding, isLoading }
}
