import { CircularProgressBar } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

interface Props {
  currentStep: number
  totalSteps: number
}

export const ReviewingProgress = ({ currentStep, totalSteps }: Props) => {
  const percentage = (currentStep * 100) / totalSteps

  return (
    <div className="flex gap-4 mt-4">
      <CircularProgressBar
        percentage={percentage}
        size={40}
        strokeWidth={8}
        strokeLineCap="square"
        successClassName="stroke-brand-medium"
      />
      <div className="flex flex-col gap-1">
        <Text size="text-sm" weight="font-medium">
          {currentStep} of {totalSteps} work experiences
        </Text>
        <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
          Please give us more information about your work experiences
        </Text>
      </div>
    </div>
  )
}
