export enum MatchingEventFilter {
  MatchingUpdates = 'matching-updates',
  ScreeningProgress = 'screening-progress',
  CommunicationSent = 'communication-sent',
}

export const matchingEventFilterLabels = {
  [MatchingEventFilter.MatchingUpdates]: 'Matching updates',
  [MatchingEventFilter.ScreeningProgress]: 'Screening progress',
  [MatchingEventFilter.CommunicationSent]: 'Communication sent',
}
