import { Filter, ListingInfo } from '../domain'
import { Tab } from '../enums'
import { SortType } from '../enums/sort-type.enum'
import {
  FetchSearchResultRequest,
  FetchSearchResultResponse,
  FetchSearchResultDTO,
} from './fetch-search-results.dto'
import { SavedSearchResponse } from './fetch-search.dto'

interface UpdateSearchRequest {
  searchURL: string
  search: FetchSearchResultRequest
}
export interface UpdateSearchResponse {
  search: SavedSearchResponse
  searchResult: FetchSearchResultResponse
}

interface UpdateSearchDTOProps {
  filter: Filter
  sort: SortType
  page: number
  listing: ListingInfo | null | undefined
  activeTab: Tab
  activeTabItems: Array<string>
  applyTabFilter: boolean
  targetProfileId: string | null
}

export class UpdateSearchDTO {
  constructor(private readonly props: UpdateSearchDTOProps) {}

  toJSON(): UpdateSearchRequest {
    const { filter } = this.props

    return {
      searchURL: filter.url,
      search: new FetchSearchResultDTO(this.props).toJSON(),
    }
  }
}

interface UpdateSearchTitleRequest {
  title: string
}

export interface UpdateSearchTitleValues {
  id: string
  title: string
}

export class UpdateSearchTitleDTO {
  constructor(private readonly values: UpdateSearchTitleValues) {}

  toJSON(): UpdateSearchTitleRequest {
    const { title } = this.values

    return {
      title,
    }
  }
}

interface LinkToListingRequest {
  id: string
}

export interface LinkToListingValues {
  id: string
  listingId: string
}

export class LinkToListingDTO {
  constructor(private readonly values: LinkToListingValues) {}

  toJSON(): LinkToListingRequest {
    const { listingId } = this.values

    return {
      id: listingId,
    }
  }
}
