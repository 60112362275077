import { Text } from 'shared/components/atoms'
import { PreviewLabel } from 'shared/components/atoms'
import { Tags } from 'shared/components/atoms'

import { useGroupCompanySizes } from '../../useGroupCompanySizes'

import { ReactComponent as XIcon } from 'icons/x-circle.svg'
import { Preferences } from 'modules/profiles/profile-preferences'

export const CompanySizes = ({ preferences }: { preferences: Preferences }) => {
  const { idealCompanies, openToCompanies, notInterestedCompanies } =
    useGroupCompanySizes(preferences)

  const notInterestedLabel = notInterestedCompanies
    .join(', ')
    .replace(/,([^,]*)$/, ' and'.concat('$1'))

  return (
    <>
      <div className="flex flex-col md:flex-row items-start justify-start gap-10">
        {Boolean(idealCompanies.length) && (
          <PreviewLabel title="Ideal company size" disabled id="ideal-company-size">
            <Tags values={idealCompanies} background="bg-neutral-lightest" isReadOnly />
          </PreviewLabel>
        )}
        {Boolean(openToCompanies.length) && (
          <PreviewLabel title="Open to work" disabled id="open-to-work">
            <Tags values={openToCompanies} background="bg-neutral-lightest" isReadOnly />
          </PreviewLabel>
        )}
      </div>
      {Boolean(notInterestedCompanies.length) && (
        <div className="flex flex-row items-center gap-2 -mt-6">
          <XIcon />
          <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
            Not interested in {notInterestedLabel}.
          </Text>
        </div>
      )}
    </>
  )
}
