import { AnchorHTMLAttributes, MouseEvent, ReactNode } from 'react'

import { cn } from 'modules/shared'
import { useBrowserURL } from 'shared/hooks'

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: string | ReactNode
  className?: string
  url: string
  target?: '_blank' | '_self' | '_parent' | '_top'
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
}

export const Link = ({
  children,
  className,
  url,
  target = '_blank',
  onClick = (event) => event.stopPropagation(),
  ...restProps
}: LinkProps) => {
  const { normalizeUrl } = useBrowserURL()
  const normalizedHref = normalizeUrl(url)

  return (
    <a
      {...restProps}
      href={normalizedHref}
      target={target}
      rel="noopener noreferrer"
      className={cn('text-inherit cursor-pointer text-info-medium', className)}
      onClick={onClick}
    >
      {children}
    </a>
  )
}
