import { ProgressBar } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

export const Deadline = ({ deadline }: { deadline?: number | null }) => {
  if (!deadline) return null

  return (
    <div className="flex flex-col my-3">
      <Text size="text-sm" className="text-neutral-dark mb-2">
        Response time limit for this screening is{' '}
        <span className="text-success-dark">{deadline}h</span>
      </Text>
      <ProgressBar color="bg-success-medium" progress={100} />
    </div>
  )
}
