import { useContext, useEffect } from 'react'

import { Spinner } from 'shared/components/atoms'
import { EmployerHomepage } from './Employer'
import { JobSeekerHomepage } from './JobSeeker'

import { useAuth } from 'shared/hooks'
import { LayoutContext } from 'contexts/layout'
import { usePageTitle } from 'shared/hooks'
import { Refer } from 'pages/Refer/Refer'

export const HomePage = () => {
  usePageTitle('Home')
  const { user, isPending } = useAuth()
  const { setDefaultContainer } = useContext(LayoutContext)

  useEffect(() => {
    if (!user.isSeeker) setDefaultContainer()
  }, [])

  if (isPending) {
    return <Spinner />
  }

  if (user.isSeeker) return <JobSeekerHomepage />
  if (user.isEmployer) return <EmployerHomepage />
  if (user.isRecruiter) return <Refer />

  return <></>
}
