import { RichTextEditor } from './RichTextEditor'

interface Props {
  children: string | null
  className?: string
}

export const RichText = ({ children, className }: Props) => {
  return (
    <RichTextEditor
      readOnly
      defaultValue={children}
      name="readonly-content"
      className={className}
    />
  )
}
