import { useQuery } from 'react-query'
import { useNetwork } from 'shared/hooks'

export const useMarketType = () => {
  const { get } = useNetwork()

  const { data = [], isLoading } = useQuery(
    'market-types',
    async () =>
      await get<Array<{ id: number; label: string }>>('company/market-type').then(
        ({ data }) => data,
      ),
    { staleTime: Infinity },
  )

  const getLabelById = (valueId: number) => data.find(({ id }) => valueId === id)?.label

  return { marketTypes: data, isLoading, getLabelById }
}
