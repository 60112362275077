import { Link } from 'react-router-dom'

import { Text } from 'shared/components/atoms'
import { IconBanner } from 'shared/components/molecules'

import { ReactComponent as AlertIcon } from 'icons/alert-triangle--filled.svg'

import { usePendingTasks } from 'pages/HomePage/JobSeeker/pages/Dashboard/hooks'

import { VettingPackageResponse } from 'vetting/domain'
import { JobOpportunity } from 'job-opportunity/domain'

interface JobOpportunityOnHoldProps {
  jobOpportunity: JobOpportunity
  vetting: Nullable<VettingPackageResponse>
}

export const JobOpportunityOnHold = ({ jobOpportunity, vetting }: JobOpportunityOnHoldProps) => {
  const { holdJobOpportunityTasks } = usePendingTasks(vetting)

  if (!holdJobOpportunityTasks.length || !jobOpportunity.isApplied) return null

  return (
    <IconBanner
      icon={<AlertIcon className="w-6 h-6 fill-warning-dark" />}
      bgColor="bg-warning-lighter"
      border="border-warning-light"
      className="mt-4"
    >
      <Text>
        Your English Check recording is still pending. The job opportunity will remain on hold until
        you <Link to="/english-vetting">record your English Check</Link>.
      </Text>
    </IconBanner>
  )
}
