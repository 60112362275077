import { JobSeekerProfilesManagerOnly } from 'components/Access/JobSeekerProfilesManagerOnly'
import { JobSeekerProfileViewPage } from '../JobSeekerProfileViewPage/JobSeekerProfileViewPage'

export const EditJobSeekerProfilePage = () => {
  return (
    <JobSeekerProfilesManagerOnly>
      <JobSeekerProfileViewPage />
    </JobSeekerProfilesManagerOnly>
  )
}
