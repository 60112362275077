import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { JobOpportunity } from 'job-opportunity/domain'
import { FormControl } from 'shared/hooks'
import { Questions } from 'screening/exports'
import { TeamReviewBanner } from '../TeamReviewBanner'

interface Props {
  jobOpportunity: JobOpportunity
  formControl: FormControl
  isLoading: boolean
}

export const CompatibilityCheck = ({ jobOpportunity, formControl, isLoading }: Props) => {
  return (
    <div className="px-1">
      <Text size="text-xl" weight="font-medium">
        Compatibility check
      </Text>
      <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark mt-2">
        To make our process smoother, we need to confirm some information from your profile. This
        will help us clear up any questions and move forward to the next steps.
      </Paragraph>

      <TeamReviewBanner />

      <Questions
        questions={jobOpportunity.screening.questions}
        formControl={formControl}
        isLoading={isLoading}
        jobOpportunityId={jobOpportunity.screening.id}
      />
    </div>
  )
}
