import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import classNames from 'classnames'
import React, { MouseEvent, useState } from 'react'

import { Text, ButtonType } from 'shared/components/atoms'
import { buildSecondaryButtonStyles } from 'shared/components/atoms/Button/SecondaryButton/SecondaryButton'
import { buildTertiaryButtonStyles } from 'shared/components/atoms/Button/TertiaryButton/TertiaryButton'
import { AlertDialog } from 'shared/components/molecules'

import { CollapsedMenuItem } from '.'

interface CollapsedMenuProps extends DropdownMenu.DropdownMenuTriggerProps {
  items: Array<CollapsedMenuItem>
  triggerElement: React.ReactNode
  modal?: boolean
  triggerElementSize?: 'sm' | 'md' | 'lg' | 'xl'
  buttonType?: ButtonType
  children?: React.ReactNode
}

export const CollapsedMenu = ({
  items,
  triggerElement,
  modal = false,
  triggerElementSize = 'md',
  buttonType = ButtonType.Tertiary,
  children,
  ...restProps
}: CollapsedMenuProps) => {
  const triggerStyles = {
    [ButtonType.Secondary]: buildSecondaryButtonStyles(triggerElementSize, children),
    [ButtonType.Tertiary]: buildTertiaryButtonStyles(triggerElementSize, children),
  }

  const [confirmationModalOpenedIndex, setConfirmationModalOpenedIndex] = useState(-1)
  const [dropdownOpened, setDropdownOpened] = useState(false)

  const openConfirmationModalForIndex = (index: number) => setConfirmationModalOpenedIndex(index)
  const closeConfirmationModal = () => setConfirmationModalOpenedIndex(-1)

  const closeDropdown = () => setDropdownOpened(false)

  const onConfirmClick = () => {
    items[confirmationModalOpenedIndex].callback()
    closeConfirmationModal()
  }

  const handleClick = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleMenuItemMouseDown = (
    event: MouseEvent<HTMLElement>,
    callback: () => void,
    index: number,
    isDestructive?: boolean,
  ) => {
    event.stopPropagation()

    if (isDestructive) {
      closeDropdown()
      return openConfirmationModalForIndex(index)
    }

    callback()
    closeDropdown()
  }

  return (
    <>
      <DropdownMenu.Root modal={modal} open={dropdownOpened} onOpenChange={setDropdownOpened}>
        <DropdownMenu.Trigger
          className={classNames('transition duration-100', triggerStyles[buttonType])}
          onClick={handleClick}
          {...restProps}
        >
          {triggerElement}
          {children}
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          align="end"
          className="l-0 bg-white rounded-lg border border-neutral-light mt-1 z-20"
        >
          {items.map(({ callback, label, isDestructive, isInfo }, index) => {
            return (
              <DropdownMenu.Item
                className={classNames('px-4 top-1/2 transition duration-100', {
                  'border-t border-neutral-lighter': isDestructive,
                  'py-3 hover:bg-neutral-lightest cursor-pointer': !isInfo,
                  'py-1': isInfo,
                })}
                // onMouseDown is used when the contextual menu is inside a table row, to avoid events conflicts
                onMouseDown={(event) =>
                  handleMenuItemMouseDown(event, callback, index, isDestructive)
                }
                key={`menu-${index}`}
              >
                <Text
                  size={isInfo ? 'text-xs' : 'text-sm'}
                  weight="font-normal"
                  className={classNames({
                    'text-warning-darker': isDestructive,
                    'text-neutral-dark': isInfo,
                  })}
                >
                  {label}
                </Text>
              </DropdownMenu.Item>
            )
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Root>

      <div className="hidden" onMouseDown={handleClick} role="button" tabIndex={-1}>
        <AlertDialog
          isOpen={confirmationModalOpenedIndex !== -1}
          title="Are you sure?"
          description={
            <Text size="text-base" weight="font-normal">
              This action cannot be undone.
            </Text>
          }
          confirmText="Yes, I want to proceed"
          onConfirmClick={onConfirmClick}
          onCancelClick={closeConfirmationModal}
        />
      </div>
    </>
  )
}
