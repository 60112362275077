import { useEffect } from 'react'
import classNames from 'classnames'

import {
  FieldError,
  FieldHint,
  PrimaryButton,
  Select,
  TertiaryButton,
  Textarea,
  Text,
  Label,
} from 'shared/components/atoms'
import { Banner, Modal } from 'shared/components/molecules'

import { RateOptions } from './RateOption'

import { getProducts, MAX_COMMENT_LENGTH, RATE_OPTIONS, schema } from './settings'

import { useAuth, useToast, useForm } from 'shared/hooks'
import { useSurvey } from './useSurvey'

import { useHelp } from 'modules/help'
import { Product, SurveyAnswer } from 'modules/surveys'

import { SurveyAnswer as SurveyAnswerEvent, useTracking } from 'tracking'
import { SurveyModalView } from 'tracking/events'
import { SurveyClosing } from 'tracking/events/surveys/survey-closing.event'

interface SatisfactionSurveyProps {
  product?: Product
  onClose: () => void
}

export const SatisfactionSurvey = ({ product, onClose }: SatisfactionSurveyProps) => {
  const { user } = useAuth()
  const { trackEvent } = useTracking()
  const { toastSuccess } = useToast()
  const { register, getError, watch, handleSubmit, getValues } = useForm({
    schema,
    defaultValues: { rate: '', product },
  })
  const { toggleChat } = useHelp()
  const { submitAnswer, isLoading } = useSurvey()

  const products = getProducts(user)
  const selectedRate = (watch('rate') as string) || ''
  const comments = (watch('comments') as string) || ''

  const handleSubmissionAttempt = handleSubmit(() => {
    const values = getValues()

    submitAnswer(values as SurveyAnswer).then(() => {
      toastSuccess('Thank you for helping us get even better!')
      trackEvent(new SurveyAnswerEvent(values.product, values.rate))
      onClose()
    })
  })

  const handleClose = () => {
    onClose()
    trackEvent(new SurveyClosing(product))
  }

  useEffect(() => {
    trackEvent(new SurveyModalView(product))
  }, [])

  return (
    <Modal
      open
      title="Help us improve"
      handleClose={handleClose}
      content={
        <form className="flex flex-col gap-5" onSubmit={handleSubmissionAttempt} id="survey-form">
          <div className="mb-5">
            <Banner>
              <div>
                We value your feedback! If you need product support or help from our talent team,
                please contact us through our{' '}
                <Text
                  size="text-sm"
                  weight="font-normal"
                  className="text-info-medium cursor-pointer"
                  onClick={toggleChat}
                >
                  Help Center
                </Text>
                .
              </div>
            </Banner>
          </div>
          <div className={classNames({ hidden: product })}>
            <Label htmlFor="product">Select the product you want to rate</Label>
            <Select register={register} name="product" options={products} placeholder="Select" />
          </div>

          <div>
            <div className="flex gap-3 justify-between">
              {RATE_OPTIONS.map((option) => (
                <RateOptions
                  key={option.value}
                  label={option.label}
                  value={option.value}
                  selected={option.value === selectedRate}
                  register={register}
                  icon={option.icon}
                />
              ))}
            </div>
            <FieldError message={getError('rate')} />
          </div>

          <div>
            <Label htmlFor="comments">Share your thoughts</Label>
            <Textarea register={register} name="comments" maxLength={MAX_COMMENT_LENGTH}>
              <FieldHint>
                {comments.length || 0} / {MAX_COMMENT_LENGTH}
              </FieldHint>
            </Textarea>
          </div>
        </form>
      }
      footer={
        <div className="flex gap-4">
          <TertiaryButton onClick={handleClose}>Cancel</TertiaryButton>
          <PrimaryButton type="submit" form="survey-form" isLoading={isLoading} autoFocus>
            Send feedback
          </PrimaryButton>
        </div>
      }
    />
  )
}
