import { CheckboxGroupOption } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'
import { matchingEventFilterLabels, MatchingEventFilter } from 'candidates-search/enums'

import { defaultMatchingEvents } from '../../schema'
import { Filter } from '../Filter'
import { useEffect } from 'react'

interface MatchingEventsFilterProps {
  formControl: FormControl
  disabled: boolean
}

export const MatchingEventsFilter = ({ formControl, disabled }: MatchingEventsFilterProps) => {
  const { setValue, watch } = formControl

  const handleClear = () => {
    setValue('matchingEvents', defaultMatchingEvents)
  }

  const noneSelected = watch('matchingEventsNone')
  const someSelected = Object.values(watch('matchingEvents')).some((value) => value)

  const options: Array<CheckboxGroupOption> = [
    {
      label: matchingEventFilterLabels[MatchingEventFilter.MatchingUpdates],
      name: MatchingEventFilter.MatchingUpdates,
    },
    {
      label: matchingEventFilterLabels[MatchingEventFilter.ScreeningProgress],
      name: MatchingEventFilter.ScreeningProgress,
    },
    {
      label: matchingEventFilterLabels[MatchingEventFilter.CommunicationSent],
      name: MatchingEventFilter.CommunicationSent,
    },
  ]

  useEffect(() => {
    if (noneSelected) {
      setValue('matchingEvents', defaultMatchingEvents)
    }
  }, [noneSelected])

  useEffect(() => {
    if (someSelected) {
      setValue('matchingEventsNone', false)
    }
  }, [someSelected])

  return (
    <Filter
      text="Matching"
      formControl={formControl}
      name="matchingEvents"
      options={options}
      onClear={handleClear}
      disabled={disabled}
      showNone
    />
  )
}
