import { Suspense, useEffect, useRef, useState } from 'react'

interface LazyInitializingProps {
  children: JSX.Element
  fallback: JSX.Element
}

export const LazyInitializing = ({ children, fallback }: LazyInitializingProps) => {
  const [shouldRender, setShouldRender] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const callback = (entries: Array<IntersectionObserverEntry>) => {
    const [entry] = entries
    if (!shouldRender) setShouldRender((prev) => prev || entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callback)
    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current)
      }
    }
  }, [])

  return (
    <div ref={containerRef}>
      {shouldRender && <Suspense fallback={fallback}>{children}</Suspense>}
    </div>
  )
}
