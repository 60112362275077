export { NoticePeriod, buildNoticePeriodLabel, isMissingNoticePeriod } from './NoticePeriod'

export const noticePeriodOptions = [
  { id: 0, label: 'Immediately' },
  { id: 1, label: '1 week' },
  { id: 2, label: '2 weeks' },
  { id: 3, label: '3 weeks' },
  { id: 4, label: '4 weeks' },
  { id: 5, label: '5 weeks' },
  { id: 6, label: '6+ weeks' },
]
