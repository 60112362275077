import { useEffect } from 'react'
import { UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { Counter } from 'shared/components/atoms'
import { Label } from 'shared/components/atoms'
import { SingleSelectorSlider } from 'shared/components/atoms'
import { FieldError } from 'shared/components/atoms'

import { emptyState } from './'

interface ManagementFieldsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues: UseFormGetValues<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  isVisible: boolean
  getError: (field: string) => string | undefined
  isNew: boolean
}

export const ManagementFields = ({
  setValue,
  getValues,
  register,
  watch,
  getError,
  isVisible,
  isNew,
}: ManagementFieldsProps) => {
  const numberOfManagedPeople = watch('numberOfManagedPeople') as number
  const percentageOfWorkBuilding = watch('percentageOfWorkBuilding') as number

  useEffect(() => {
    if (isVisible) {
      const normalizedPercentageOfWorkBuilding = isNew
        ? percentageOfWorkBuilding || 50
        : percentageOfWorkBuilding
      setValue('numberOfManagedPeople', numberOfManagedPeople || 2, { shouldTouch: true })
      setValue('percentageOfWorkBuilding', normalizedPercentageOfWorkBuilding, {
        shouldTouch: true,
      })
      return
    }

    setValue('numberOfManagedPeople', emptyState.numberOfManagedPeople, { shouldTouch: true })
    setValue('percentageOfWorkBuilding', emptyState.percentageOfWorkBuilding, {
      shouldTouch: true,
    })
  }, [isVisible])

  if (!isVisible) return null

  return (
    <div className="flex flex-col gap-3 bg-neutral-lightest p-6 rounded-lg mt-4">
      <div aria-labelledby="numberOfManagedPeople">
        <Label htmlFor="numberOfManagedPeople" id="numberOfManagedPeople">
          Number of people you have managed simultaneously in this role
        </Label>
        <Counter
          aria-labelledby="numberOfManagedPeople"
          name="numberOfManagedPeople"
          register={register}
          getValues={getValues}
          setValue={setValue}
        />
      </div>
      <div aria-labelledby="percentageOfWorkBuilding">
        <Label htmlFor="percentageOfWorkBuilding" id="percentageOfWorkBuilding">
          What is the distribution of your work as a manager?
        </Label>
        <SingleSelectorSlider
          name="percentageOfWorkBuilding"
          setFormValue={setValue}
          watch={watch}
          trackBackground="bg-info-medium"
          step={10}
        >
          {(value) => (
            <div className="flex flex-row w-full justify-center text-sm text-neutral-dark mt-6">
              <span className="flex w-1/3 justify-start gap-1">
                <span className="text-neutral-darkest font-medium">{value}%</span> building
              </span>
              <span className="flex w-1/3 justify-center">over</span>
              <span className="flex w-1/3 justify-end gap-1">
                <span className="text-neutral-darkest font-medium">{100 - value}%</span> managing
                people
              </span>
            </div>
          )}
        </SingleSelectorSlider>
        <FieldError message={getError('percentageOfWorkBuilding')} />
      </div>
    </div>
  )
}
