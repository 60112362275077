import { useContext } from 'react'
import { plainToInstance } from 'class-transformer'
import { useMutation } from 'react-query'

import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'

import { SearchContext } from 'candidates-search/contexts'

import { Search } from 'candidates-search/domain'
import {
  CreateSearchDTO,
  CreateSearchValues,
  UpdateSearchTitleDTO,
  LinkToListingValues,
  LinkToListingDTO,
  UpdateSearchTitleValues,
} from 'candidates-search/dtos'
import { useMonitoring } from 'shared/hooks'

export const useSavedSearch = () => {
  const { setSearch } = useContext(SearchContext)
  const { post, patch, delete: deleteReq } = useNetwork()
  const { toastError, toastSuccess } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: create, isLoading: isCreating } = useMutation(
    (values: CreateSearchValues & { skipStateUpdate?: boolean }) =>
      post<Search>('advanced-search/new', new CreateSearchDTO(values).toJSON()).then(({ data }) => {
        const search = plainToInstance(Search, data)
        if (!values.skipStateUpdate) setSearch(plainToInstance(Search, data))
        return search
      }),
    {
      onError: (error: RequestError) => {
        toastError('Error when creating the search: ' + error.response?.data.message)
        captureException(error)
      },
    },
  )

  const { mutateAsync: updateTitle, isLoading: isUpdating } = useMutation(
    (values: UpdateSearchTitleValues) =>
      patch<Search>(`advanced-search/${values.id}/title`, new UpdateSearchTitleDTO(values).toJSON())
        .then(({ data }) => setSearch(plainToInstance(Search, data)))
        .catch((error: RequestError) => {
          toastError('Error when editing the search title: ' + error.response?.data.message)
          captureException(error)
        }),
  )

  const { mutateAsync: deleteSearch } = useMutation((id: string) =>
    deleteReq(`advanced-search/${id}`)
      .then(() => {
        setSearch(undefined)
        toastSuccess('Search deleted')
      })
      .catch((error: RequestError) => {
        toastError('Error when deleting the search: ' + error.response?.data.message)
        captureException(error)
      }),
  )

  const { mutateAsync: linkToListing, isLoading: isLinkingToListing } = useMutation(
    (values: LinkToListingValues) =>
      post<Search>(`advanced-search/${values.id}/listing`, new LinkToListingDTO(values).toJSON())
        .then(({ data }) => {
          setSearch(plainToInstance(Search, data))
          toastSuccess('Listing linked')
        })
        .catch((error: RequestError) => {
          toastError(
            'Error when linking the search to the listing: ' + error.response?.data.message,
          )
          captureException(error)
        }),
  )

  const { mutateAsync: unlinkListing } = useMutation((id: string) =>
    deleteReq<Search>(`advanced-search/${id}/listing`)
      .then(({ data }) => {
        setSearch(plainToInstance(Search, data))
        toastSuccess('Listing unlinked')
      })
      .catch((error: RequestError) => {
        toastError(
          'Error when unlinking the listing from the search: ' + error.response?.data.message,
        )
        captureException(error)
      }),
  )

  return {
    create,
    updateTitle,
    deleteSearch,
    linkToListing,
    unlinkListing,
    isCreating,
    isUpdating,
    isLinkingToListing,
  }
}
