import { array, object, string } from 'yup'

export const schema = object().shape({
  flags: array().of(
    object({
      id: string().required(),
      label: string().required(),
    }),
  ),
})
