import { Link } from 'react-router-dom'

import { SecondaryButton } from 'shared/components/atoms'
import { Task } from './Task'

export const CompleteProfileTask = () => {
  return (
    <Task
      iconPath="images/painting.png"
      iconClassName="!bg-success-lighter"
      title="Complete your profile"
      description="We use the information of your profile such as skills, experiences and preferences to match you with job opportunities."
      cta={
        <Link to={'/profile'}>
          <SecondaryButton>Complete profile</SecondaryButton>
        </Link>
      }
    />
  )
}
