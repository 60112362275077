import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { Slider } from 'shared/components/atoms'
import { CheckboxGroup, CheckboxGroupOption } from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'
import { FieldError } from 'shared/components/atoms'
import { Label } from 'shared/components/atoms'
import { useEngagementPreferences } from 'hooks/engagementPreferences'

interface JobTypesProps {
  register: (key: string) => Array<string>
  watch: UseFormWatch<Record<string, boolean>>
  setValue: UseFormSetValue<Record<string, string>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: { [x: string]: any }
}

export const JobTypes = ({ register, watch, setValue, errors }: JobTypesProps) => {
  const { workingHoursOptions } = useEngagementPreferences()

  const jobTypeError =
    errors.jobTypes?.longTerm || errors.jobTypes?.shortTerm || errors.freelancingJobHours

  const isShortTermSelected = watch('jobTypes.shortTerm') as boolean

  const options = [
    { name: 'longTerm', label: 'Long-term engagement (Full-time - 40h/week)' },
    { name: 'shortTerm', label: 'Short-term engagement / freelance' },
  ] as Array<CheckboxGroupOption>

  return (
    <ContentWrapper>
      <Label htmlFor="jobTypes" className="mb-2" size="text-sm">
        What type of job are you interested in?
      </Label>
      <CheckboxGroup name="jobTypes" register={register} options={options} />
      {isShortTermSelected && (
        <div className="mt-10 flex flex-col gap-4">
          <Label htmlFor="freelancingJobHours" size="text-sm" className="!mb-0">
            How many hours are you available to work? Please select all that apply.
          </Label>
          <Slider
            name="freelancingJobHours"
            setFormValue={setValue}
            watch={watch}
            options={workingHoursOptions}
            isRangeSlider
          />
        </div>
      )}
      {jobTypeError && <FieldError message={jobTypeError.message} />}
    </ContentWrapper>
  )
}
