import { PrimaryButton, SecondaryButton } from 'shared/components/atoms'
import { Link } from 'react-router-dom'

interface ActionProps {
  profileCompleted: boolean
  englishCheckCompleted: boolean
  onDismiss: () => void
}

export const Action = ({ profileCompleted, englishCheckCompleted, onDismiss }: ActionProps) => {
  if (!profileCompleted)
    return (
      <Link to="/profile">
        <PrimaryButton className="w-full md:w-fit">Complete profile</PrimaryButton>
      </Link>
    )
  if (!englishCheckCompleted)
    return (
      <Link to="/english-vetting">
        <PrimaryButton className="w-full md:w-fit">Check your English level</PrimaryButton>
      </Link>
    )
  return (
    <SecondaryButton onClick={onDismiss} className="w-full md:w-fit">
      Dismiss
    </SecondaryButton>
  )
}
