import { ReactComponent as CloseIcon } from 'icons/close.svg'

export const CloseButton = ({ closeToast }: { closeToast: () => void }) => {
  return (
    <CloseIcon
      className="flex-shrink-0 flex self-center stroke-white w-[20px] h-[20px] mb-auto mt-[6px]"
      onClick={closeToast}
    />
  )
}
