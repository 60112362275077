import classNames from 'classnames'
import { Title } from 'shared/components/atoms'

interface SlideProps {
  children: JSX.Element | Array<JSX.Element>
  title: string | JSX.Element
  image: string
  className?: string
}

export const Slide = ({ children, title, image, className }: SlideProps) => {
  return (
    <div
      className={classNames(
        'flex flex-col gap-4 min-h-[530px] px-6 lg:px-10 w-full text-sm md:text-base font-normal',
        className,
      )}
    >
      <div className="h-[244px] flex items-center">
        <img src={image} className="mt-10" />
      </div>
      <Title size="heading" className="font-medium flex gap-2">
        {title}
      </Title>
      {children}
    </div>
  )
}
