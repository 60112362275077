import { Title } from 'shared/components/atoms'
import { useFetchPreferences } from 'hooks/profile'
import { PayRate } from 'pages/JobSeekerProfile/components/PayRateSection/components'

interface PayRateSectionProps {
  candidateId: string
}

export const PayRateSection = ({ candidateId }: PayRateSectionProps) => {
  const { preferences } = useFetchPreferences(candidateId)
  const { payRate } = preferences
  return (
    <div
      className="
        flex flex-col gap-10
        mb-8 sm:mb-16 grow w-full print:mb-6
        print:hidden
      "
    >
      <Title size="heading" className="font-medium">
        Pay rate
      </Title>

      <PayRate
        monthlyRate={payRate.monthlyRate}
        hourlyRate={payRate.hourlyRate}
        openToNegotiation={false}
      />
    </div>
  )
}
