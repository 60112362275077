import { useEffect, useRef } from 'react'

import { PrimaryButton, Suggestion, Text } from 'shared/components/atoms'
import { Footer, IconBanner, Modal } from 'shared/components/molecules'
import { useForm } from 'shared/hooks'
import { ProficiencyTag } from 'modules/profiles/domain'
import { ReactComponent as InfoIcon } from 'icons/info-circle.svg'

import { Tags } from '../Tags'
import { useAddProficiency, useDeleteProficiency } from '../../hooks'
import { schema } from './schema'
import { SelectStacks } from 'profile/exports'

interface Props {
  isOpen: boolean
  profileId: string
  onClose: (tags: Array<ProficiencyTag>) => void
  proficiencies: Array<ProficiencyTag>
}

export const AddProficiencyTagsModal = (props: Props) => {
  const { isOpen, profileId, onClose } = props

  const formControl = useForm({
    schema,
    defaultValues: { proficiencies: props.proficiencies || [] },
  })
  const { watch, setValue, reset } = formControl

  const { addProficiencies, isLoading: isAdding } = useAddProficiency(profileId)
  const { deleteProficiency, isDeleting } = useDeleteProficiency(profileId)

  const selectedTags = watch('proficiencies') as Array<ProficiencyTag>

  const modalTitleRef = useRef(
    (() => {
      const prefix = selectedTags.length ? 'Edit' : 'Add'
      return `${prefix} proficiencies`
    })(),
  )

  const handleAdd = async (value: Suggestion) => {
    setValue('proficiencies', [...selectedTags, value])
    await addProficiencies([value as ProficiencyTag])
  }

  const handleRemove = async (id: number) => {
    const proficiencies = selectedTags.filter((tag: ProficiencyTag) => tag.id !== id)
    setValue('proficiencies', proficiencies)
    await deleteProficiency(id)
  }

  const handleClose = () => onClose(selectedTags || [])

  useEffect(() => {
    if (!isOpen) return
    reset({ proficiencies: props.proficiencies || [] })
  }, [isOpen])

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Modal
        open={isOpen}
        handleClose={handleClose}
        title={modalTitleRef.current}
        contentContainerClassName="!p-10 overflow-visible"
        content={
          <div className="flex flex-col gap-6">
            <form id="add-proficiencies-form" onSubmit={handleClose}>
              <SelectStacks
                formControl={formControl}
                name="proficiencies"
                selectedOptions={selectedTags}
                placeholder="Select proficiencies"
                containerClassName="mb-4"
                allowCreation
                shouldPublish
                hideTags
                onAdd={handleAdd}
              />
              <Tags proficiencies={selectedTags} onRemove={handleRemove} />
            </form>
            <IconBanner
              bgColor="bg-warning-lighter"
              icon={<InfoIcon className="w-6 h-6 stroke-warning-medium shrink-0" />}
            >
              <Text>
                Once a proficiency is created and no similar skill exists, it will be also created
                as a skill option visible for users managing their profile so they can link it to
                work experiences, education, and other profile content.
              </Text>
            </IconBanner>
          </div>
        }
        footer={
          <Footer>
            <PrimaryButton
              type="submit"
              form="add-proficiencies-form"
              isLoading={isAdding || isDeleting}
            >
              Done
            </PrimaryButton>
          </Footer>
        }
      />
    </div>
  )
}
