import { array, number, object, string } from 'yup'

const errorMessages = {
  missingSubscribers: 'Please add at least one person to listen for updates',
}

export const schema = object({
  subscribers: array()
    .of(
      object().shape({
        id: number(),
        label: string(),
      }),
    )
    .min(1, errorMessages.missingSubscribers),
}).required()
