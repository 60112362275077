import { Dispatch, ReactNode, SetStateAction, createContext, useContext } from 'react'
import { useLocalStorage } from 'shared/hooks'

const STORAGE_KEY = 'str.blurred-mode'

const SensitiveInformationContext = createContext({
  blurSensitiveInfo: false,
  setBlurSensitiveInfo: (() => {}) as Dispatch<SetStateAction<boolean>>,
})

export const SensitiveInformationProvider = ({ children }: { children: ReactNode }) => {
  const [blurSensitiveInfo, setBlurSensitiveInfo] = useLocalStorage(STORAGE_KEY, false)

  return (
    <SensitiveInformationContext.Provider
      value={{ blurSensitiveInfo: blurSensitiveInfo || false, setBlurSensitiveInfo }}
    >
      {children}
    </SensitiveInformationContext.Provider>
  )
}

export const useSensitiveInformation = () => useContext(SensitiveInformationContext)
