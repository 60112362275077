import { useEffect } from 'react'

import { TertiaryButton, Text, Tooltip } from 'shared/components/atoms'
import { useForm } from 'shared/hooks'
import { TimelineFilterValues } from 'candidates-search/domain'
import { ConditionalWrapper, IconBanner } from 'shared/components/molecules'
import { useProfileMatchings } from 'matching/exports'
import { ReactComponent as InfoIcon } from 'icons/info-circle.svg'

import { MatchingEventsFilter, MatchingFilter, ProfileEventsFilter } from './components'
import { buildDefaultValues, schema } from './schema'

interface TimelineFilterProps {
  profileId: string
  matchingId?: string
  showMissingMatchingBanner?: boolean
  onFilterChange: (values: TimelineFilterValues) => void
}

export const TimelineFilter = ({
  profileId,
  matchingId,
  showMissingMatchingBanner,
  onFilterChange,
}: TimelineFilterProps) => {
  const defaultValues = buildDefaultValues(matchingId)
  const formControl = useForm({ schema, defaultValues })
  const { watch, reset } = formControl

  const { matchings } = useProfileMatchings(profileId)
  const matchingFiltersDisabled = !matchings.length

  const bannerCopy = (() => {
    if (!matchings.length) {
      return "This candidate hasn't been selected for any job listing yet."
    }

    if (showMissingMatchingBanner) {
      return "This candidate hasn't been selected for the linked job listing yet. You are viewing events from all job listings this candidate is linked to."
    }

    return ''
  })()

  const handleClearAll = () => {
    reset(buildDefaultValues())
  }

  useEffect(() => {
    const defaultValues = buildDefaultValues(matchingId)
    reset(defaultValues)
    onFilterChange(defaultValues)
  }, [profileId, matchingId])

  useEffect(() => {
    const subscription = watch((values) => {
      onFilterChange(new TimelineFilterValues(values as Plain<TimelineFilterValues>))
    })

    return () => subscription.unsubscribe()
  }, [])

  return (
    <>
      <div className="flex items-center gap-6">
        <Text size="text-sm" className="text-neutral-dark">
          Filter by
        </Text>
        <ConditionalWrapper
          shouldWrap={matchingFiltersDisabled}
          wrapper={(children) => (
            <Tooltip content="This candidate hasn't been considered for any job listings yet.">
              {children}
            </Tooltip>
          )}
        >
          <MatchingFilter
            matchings={matchings}
            formControl={formControl}
            disabled={matchingFiltersDisabled}
          />
        </ConditionalWrapper>

        <ConditionalWrapper
          shouldWrap={matchingFiltersDisabled}
          wrapper={(children) => (
            <Tooltip content="This candidate has no matchings yet.">{children}</Tooltip>
          )}
        >
          <MatchingEventsFilter formControl={formControl} disabled={matchingFiltersDisabled} />
        </ConditionalWrapper>
        <ProfileEventsFilter formControl={formControl} />
        <TertiaryButton onClick={handleClearAll}>Clear all filters</TertiaryButton>
      </div>
      {Boolean(bannerCopy) && (
        <IconBanner icon={<InfoIcon className="w-6 h-6 stroke-neutral-medium" />}>
          {bannerCopy}
        </IconBanner>
      )}
    </>
  )
}
