import { plainToInstance } from 'class-transformer'
import { useQuery } from 'react-query'
import { useMemo } from 'react'

import { useAuth, useNetwork } from 'shared/hooks'

import { ListingInfo } from 'job-listing/domains'
import { FormListing } from 'job-listing/domains'
import { ListingResponse } from 'job-listing/dtos'
import { PaymentModel, StackPreferences } from 'job-listing/enums'

const getSalaryFrom = (listing: ListingResponse) => {
  if (listing.paymentModel === PaymentModel.Hourly)
    return (listing.minimumHourlyRate || 1).toString()
  return listing.minimumSalary ? (listing.minimumSalary / 12).toFixed(2) : '1'
}

const getSalaryTo = (listing: ListingResponse) => {
  if (listing.paymentModel === PaymentModel.Hourly)
    return (listing.maximumHourlyRate || 1).toString()
  return listing.maximumSalary ? (listing.maximumSalary / 12).toFixed(2) : '1'
}

export const getFormListing = (listing: ListingResponse): FormListing => {
  return {
    id: listing.id,
    jobTitle: listing.title,
    roleId: listing.role ? listing.role.id : undefined,
    roleLabel: listing.role ? listing.role.label : listing.otherRoleName!,
    roleType: listing.role ? listing.role.roleType : 'other',
    projectDescription: listing.projectDescription,
    stacks: listing.stacks.map((stack) => {
      return {
        id: stack.stack.id,
        preference: StackPreferences.Required, // show all old stacks classifications to enable editing
        label: stack.stack.label,
        preferenceLabel: stack.preference,
      }
    }),
    numberOfOpenings: listing.numberOfOpenings,
    finalInterviewDuration: listing.finalInterviewDuration,
    finalInterviewDescription: listing.finalInterviewDescription || '',
    paymentModel: listing.paymentModel,
    salaryFrom: getSalaryFrom(listing),
    salaryTo: getSalaryTo(listing),
    equity: listing.equity,
    contractMode: listing.contractMode,
    extraContent: listing.extraContent || '',

    // second step
    skipIntroCall: listing.skipIntroCall,
    scheduleLink: listing.introCallLink || '',

    status: listing.status,
    listingTerm: listing.listingTerm,

    termId: listing.listingTerm?.id,
    detailedRequirements: listing.detailedRequirements ?? undefined,
    subscribers: listing.subscriptions.map(({ subscriberId, email }) => ({
      id: subscriberId,
      label: email,
    })),
    experienceRange: {
      min: listing.minimumExperience ?? 0,
      max: listing.maximumExperience ?? 10,
    },
    engagementType: listing.engagementType,
    contractLength: listing.contractLength,
    freelancingWorkingHoursPerWeek: listing.freelancingWorkingHoursPerWeek || 5,
    hasHourlyMatchings: listing.hasHourlyMatchings,
    hasMonthlyMatchings: listing.hasMonthlyMatchings,
    isTalentReview: listing.isTalentReview,
  }
}

export const useJobListingPreview = (id: string | undefined) => {
  const { get } = useNetwork()

  const { data: listing, isLoading } = useQuery<ListingInfo, RequestError>(
    ['listings', id, 'preview'],
    () =>
      get<ListingResponse>(`listings/${id}/preview`).then(({ data }) =>
        plainToInstance(ListingInfo, data),
      ),
    {
      enabled: Boolean(id),
      retry: (failureCount: number, error: RequestError) =>
        error.response?.status === 404 || failureCount === 2 ? false : true,
    },
  )

  return { listing, isLoading }
}

export const useJobListing = (id: string | undefined) => {
  const {
    user: { isManager },
  } = useAuth()

  const { get } = useNetwork()

  const { data, isLoading, isFetching } = useQuery<ListingResponse, RequestError>(
    [`/listings/${id}`, id],
    async () => await get<ListingResponse>(`listings/${id}`).then(({ data }) => data),
    {
      enabled: Boolean(id) && isManager,
      retry: (_, error: RequestError) => error.response?.status !== 404,
    },
  )

  const listing = useMemo(() => data && plainToInstance(ListingInfo, data), [data])
  const listingForm = useMemo(() => data && getFormListing(data), [data])

  return { listing, listingForm, isLoading: isLoading || isFetching }
}
