import { CompanySize } from 'company/enums'

export const CompanyEmployees = {
  [CompanySize.Micro]: '1-10',
  [CompanySize.Small]: '11-50',
  [CompanySize.Medium]: '51-250',
  [CompanySize.Large]: '251-500',
  [CompanySize.VeryLarge]: '501-1000',
  [CompanySize.Huge]: '1001+',
} as Record<CompanySize, string>
