import { useOnboardingParamsTracker, useQueryParams } from 'shared/hooks'
import { useReferralLink } from 'hooks/referrals'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ReferrerCard } from './ReferrerCard'

const REDIRECT_DELAY = 3000
const MAX_WAIT_TIME = 1 * 60 * 1000

export const ReferralRedirectCallback = ({ isSignUp }: { isSignUp?: boolean }) => {
  const [token, setToken] = useState('')

  const { referrerSlug } = useParams()
  const queryParams = useQueryParams()
  const { decodeJobParam } = useReferralLink()
  const jobUrl = decodeJobParam(queryParams)
  const navigate = useNavigate()
  const { setReferrerSlug } = useOnboardingParamsTracker()

  const redirect = () => {
    if (jobUrl) {
      return (window.location.href = jobUrl)
    }

    if (isSignUp) {
      const inviteId = queryParams.get('inviteId')
      let redirectUrl = `/join?redirectUrl=/job-seeker-onboarding?referral=${referrerSlug}`
      if (inviteId) redirectUrl = redirectUrl.concat(`&inviteId=${inviteId}`)

      return navigate(redirectUrl, {
        replace: true,
      })
    }

    return navigate(`/job-seeker-onboarding?referral=${referrerSlug}`, { replace: true })
  }

  useEffect(() => {
    const noTokenTimer = setTimeout(() => redirect(), MAX_WAIT_TIME)

    if (!token) return () => clearTimeout(noTokenTimer)

    const timer = setTimeout(redirect, REDIRECT_DELAY)
    setReferrerSlug(referrerSlug)

    return () => {
      clearTimeout(timer)
      clearTimeout(noTokenTimer)
    }
  }, [token])

  return <ReferrerCard referrerSlug={referrerSlug!} onToken={setToken} />
}
