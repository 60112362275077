import { useMutation, useQueryClient } from 'react-query'
import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useCancelAccountDeletionRequest = (accountId: string) => {
  const { delete: remove } = useNetwork()
  const { toastError, toastSuccess } = useToast()
  const { captureException } = useMonitoring()
  const client = useQueryClient()

  const { mutateAsync: cancelAccountDeletionRequest, isLoading } = useMutation(
    (requestId: string) => remove(`accounts/${accountId}/deletion-requests/${requestId}`),
    {
      onError: (error) => {
        toastError('Failed to cancel the account deletion request')
        captureException(error)
      },
      onSuccess: () => {
        toastSuccess('Account deletion request canceled')
        client.invalidateQueries(['account-deletion-request', accountId])
      },
    },
  )

  return { cancelAccountDeletionRequest, isLoading }
}
