import { FieldWrapper, TagsArea } from 'shared/components/atoms'
import { useMemo } from 'react'

interface Props {
  employees: Array<{ id: number; email: string }>
  subscribers: Array<{ id: number; label: string }>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: (key: string, value: any, options: any) => void
  getError: (key: string) => string
}

export const Subscribers = ({ employees, subscribers, setValue, getError }: Props) => {
  const employeeOptions = useMemo(
    () =>
      employees
        .filter(({ id }) => !subscribers.find((subscriber) => id === subscriber.id))
        .map(({ id, email }) => ({ id, label: email })),
    [employees, subscribers],
  )

  return (
    <FieldWrapper>
      <TagsArea
        isVisible
        tags={subscribers ?? []}
        options={employeeOptions}
        name="subscribers"
        label="Who should receive matching notifications?"
        setValue={setValue}
        getError={() => getError('subscribers')}
        hint=" Enter the emails of the people who should receive emails with the candidates selected for
        this job listing."
      />
    </FieldWrapper>
  )
}
