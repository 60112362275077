export enum BooleanExpectedAnswer {
  Yes = 'yes',
  No = 'no',
  Both = 'both',
}

export const booleanExpectedAnswerLabels: Record<BooleanExpectedAnswer, string> = {
  [BooleanExpectedAnswer.Yes]: 'Yes',
  [BooleanExpectedAnswer.No]: 'No',
  [BooleanExpectedAnswer.Both]: 'Any',
}
