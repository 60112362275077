import { PayRate } from '../domain'

interface UpdatePayRateRequest {
  minMonthlyRate: number
  maxMonthlyRate: number
  minHourlyRate: number
  maxHourlyRate: number
  openToNegotiation: boolean
}

export class UpdatePayRateDTO {
  constructor(private readonly payRate: PayRate) {}

  toJSON(): UpdatePayRateRequest {
    return {
      minMonthlyRate: this.payRate.monthlyRate.min || 0,
      maxMonthlyRate: this.payRate.monthlyRate.max || 0,
      minHourlyRate: this.payRate.hourlyRate.min || 0,
      maxHourlyRate: this.payRate.hourlyRate.max || 0,
      openToNegotiation: this.payRate.openToNegotiation || false,
    }
  }
}
