import { Type } from 'class-transformer'

class Company {
  id: string
  name: string
}

class Listing {
  id: string
  title: string
}

export class ProfileMatchingItem {
  id: string
  externalId: string
  profileId: string

  @Type(() => Listing)
  listing: Listing

  @Type(() => Company)
  company: Company

  get listingTitle() {
    return this.listing.title
  }

  get companyName() {
    return this.company.name
  }
}
