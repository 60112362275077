import { Tooltip } from 'shared/components/atoms'
import { ClaimProtected } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { AccountClaim } from 'shared/hooks'
import { useFormatting } from 'shared/hooks'

export const SharingDate = ({ sharedDate }: { sharedDate: string | null }) => {
  const { formatDateTime, getWrittenTimeDifference } = useFormatting()
  const days = getWrittenTimeDifference(sharedDate || '')

  if (!sharedDate) return null

  return (
    <ClaimProtected require={AccountClaim.ManageMatchings}>
      <Tooltip content={formatDateTime({ date: new Date(sharedDate) })}>
        <Text size="text-xs" weight="font-normal" className="text-neutral-dark">
          Shared with client {days}
        </Text>
      </Tooltip>
    </ClaimProtected>
  )
}
