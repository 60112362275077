import { useMemo } from 'react'
import { SliderOption } from 'shared/components/atoms'
import { PayRateType } from 'modules/shared/enums'

export const HOURLY_MIN = 5
export const HOURLY_MAX = 200
export const HOURLY_GROWING_RATE = 5

export const MONTHLY_MIN = 1000
export const MONTHLY_MAX = 30000
export const MONTHLY_GROWING_RATE = 1000

export const ANNUAL_MIN = Number((MONTHLY_MIN * 12).toFixed(2))
export const ANNUAL_MAX = Number((MONTHLY_MAX * 12).toFixed(2))
export const ANNUAL_GROWING_RATE = 5000

export const NEUTRAL_AVAILABLE_OPPORTUNITIES = 40
export const GOOD_AVAILABLE_OPPORTUNITIES = 70

const getMinValue = (type: PayRateType, override?: number) => {
  if (typeof override === 'number') return override

  return (
    {
      [PayRateType.Monthly]: MONTHLY_MIN,
      [PayRateType.Hourly]: HOURLY_MIN,
    }[type] || HOURLY_MIN
  )
}

const getMaxValue = (type: PayRateType, override?: number) => {
  if (typeof override === 'number') return override

  return (
    {
      [PayRateType.Monthly]: MONTHLY_MAX,
      [PayRateType.Hourly]: HOURLY_MAX,
    }[type] || HOURLY_MAX
  )
}

const getStep = (type: PayRateType, override?: number) => {
  if (typeof override === 'number') return override

  return (
    {
      [PayRateType.Monthly]: MONTHLY_GROWING_RATE,
      [PayRateType.Hourly]: HOURLY_GROWING_RATE,
    }[type] || HOURLY_GROWING_RATE
  )
}

const buildLabel = (value: number, maxValue: number, openEnded: boolean) => {
  const formattedValue = '$' + value.toLocaleString('en-US', { style: 'decimal' })

  if (openEnded && value == maxValue) return formattedValue + '+'

  return formattedValue
}

const normalizeValue = (value: number, maxValue: number, openEnded: boolean) => {
  if (openEnded && value === maxValue) return Infinity

  return value
}

export const usePayRateOptions = (options: {
  type: PayRateType
  minValue?: number
  maxValue?: number
  step?: number
  openEnded?: boolean
}): Array<SliderOption> => {
  const { type, openEnded } = options

  const payRateOptions = useMemo(() => {
    const newOptions: Array<SliderOption> = []

    const minValue = getMinValue(type, options.minValue)
    const maxValue = getMaxValue(type, options.maxValue)
    const defaultStep = getStep(type, options.step)

    let value = options?.minValue || minValue

    while (value <= maxValue) {
      newOptions.push({
        label: buildLabel(value, maxValue, Boolean(openEnded)),
        value: normalizeValue(value, maxValue, Boolean(openEnded)),
      })

      value += options?.step || defaultStep
    }

    return newOptions
  }, [type])

  return payRateOptions
}
