import { MouseEvent } from 'react'

import { Wrapper } from './Wrapper'
import { Header } from './Header'
import { Dot, Line } from './Timeline'
import { MonthsGap } from '../MonthsGap'

import { useExpirenceLocation, useWorkExperience } from 'profile/hooks'
import { Text } from 'components/Text'
import { GroupedExperience } from 'pages/JobSeekerProfile/types'
import { RichText } from 'shared/components/atoms'
import { Link } from 'shared/components/atoms'
import { WorkExperience } from 'modules/work-experience'

import { WorkExperienceTags } from './WorkExperienceTags'
import { SensitiveInformation } from 'shared/components/atoms'
import classNames from 'classnames'
import { useFormatting } from 'shared/hooks'

interface CompanyExperienceProps {
  companyExperience: GroupedExperience
  onEditWorkExperienceClick(workExperience: WorkExperience): void
  readOnly: boolean
}

export const CompanyExperience = ({
  readOnly,
  companyExperience,
  onEditWorkExperienceClick,
}: CompanyExperienceProps) => {
  const { calculateTotalWorkExperience } = useWorkExperience({ readOnly })
  const { formatMonthsGap } = useFormatting()
  const { getLocationLabel } = useExpirenceLocation()
  const linkClassName = '!text-neutral-dark'

  const handleLinkClick =
    (blurSensitiveInfo: boolean) => (event: MouseEvent<HTMLAnchorElement>) => {
      if (blurSensitiveInfo) event.preventDefault()
    }

  if (companyExperience.experiences.length === 1) {
    const experience = companyExperience.experiences[0]
    const startDate = experience.startDate as Date
    const endDate = experience.endDate as Date | undefined
    const label = `Work experience at ${experience.experienceOrganization.name} - ${formatMonthsGap(startDate, endDate, false)}`

    const website = experience.experienceOrganization.website ? (
      <Link url={experience.experienceOrganization.website} className={linkClassName}>
        {experience.experienceOrganization.name}
      </Link>
    ) : (
      <>{experience.experienceOrganization.name}</>
    )
    const location = experience.location?.trim() ? <>• {getLocationLabel(experience)}</> : null

    return (
      <Wrapper domain={companyExperience.companyWebsite} ariaLabel={label}>
        <Header
          title={experience.title}
          experience={experience}
          subtitle={<MonthsGap startDate={startDate} endDate={endDate} />}
          handleEdit={() => onEditWorkExperienceClick(experience)}
          readOnly={readOnly}
          subHeader={
            <>
              <SensitiveInformation>{website}</SensitiveInformation> {location}
            </>
          }
        />
        <RichText>{experience.description}</RichText>
        <WorkExperienceTags skills={experience.stacks} readOnly={readOnly} />
      </Wrapper>
    )
  }
  const { experiences, companyWebsite, companyName } = companyExperience
  const { duration, begin, end } = calculateTotalWorkExperience(companyExperience.experiences)
  const { years, months } = duration
  const label = `Work experience at ${companyName} - ${formatMonthsGap(begin, end, true)}`

  return (
    <Wrapper domain={companyExperience.companyWebsite} ariaLabel={label}>
      <Header
        title={
          <SensitiveInformation>
            <>{companyExperience.companyName}</>
          </SensitiveInformation>
        }
        subtitle={
          <Text className="!text-neutral-dark">{`${years ? `${years} yr ` : ''}${
            months ? `${months} mos` : ''
          }`}</Text>
        }
        readOnly
        subHeader={
          companyWebsite ? (
            <SensitiveInformation>
              {(blurSensitiveInfo) => (
                <Link
                  url={!blurSensitiveInfo ? companyWebsite : ''}
                  className={classNames(linkClassName, {
                    'blur hover:cursor-default': blurSensitiveInfo,
                    'hover:underline': !blurSensitiveInfo,
                  })}
                  onClick={handleLinkClick(blurSensitiveInfo)}
                >
                  {companyWebsite}
                </Link>
              )}
            </SensitiveInformation>
          ) : null
        }
        className="mb-[30px]"
      />
      <ul className="flex flex-col gap-10">
        {experiences.map((experience, index) => {
          const startDate = experience.startDate as Date
          const endDate = experience.endDate as Date | undefined

          return (
            <li
              key={index}
              className="flex-grow flex flex-col items-stretch justify-start gap-[10px]"
            >
              <div className="flex flex-row items-start justify-start">
                <Dot />
                <Header
                  title={experience.title}
                  experience={experience}
                  subtitle={<MonthsGap startDate={startDate} endDate={endDate} />}
                  readOnly={readOnly}
                  handleEdit={() => onEditWorkExperienceClick(experience)}
                  subHeader={<>{getLocationLabel(experience)}</>}
                />
              </div>
              <div className="flex flex-row">
                <Line
                  showLine={index < experiences.length - 1}
                  expanded={Boolean(getLocationLabel(experience))}
                />
                <div className="flex flex-col gap-4 w-full">
                  {experience.description && <RichText>{experience.description}</RichText>}

                  <WorkExperienceTags skills={experience.stacks} readOnly={readOnly} />
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </Wrapper>
  )
}
