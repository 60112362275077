import { useContext, useEffect } from 'react'

import { Label, SelectTags, Tooltip } from 'shared/components/atoms'
import { OrganizationIcon, ConditionalWrapper } from 'shared/components/molecules'
import { CandidateContext } from 'contexts/candidate'
import { FormControl, useFormatting } from 'shared/hooks'
import { BooleanExpectedAnswer, QuestionType } from 'screening/enums'
import { useProfileWorkExperiences } from 'hooks/profile'
import { WorkExperienceItem } from 'job-opportunity/exports'

interface Props {
  name: string
  formControl: FormControl
  isOptional: boolean
  disabled: boolean
  questionType: QuestionType
  error?: { message: string }
}

export const WorkExperiences = ({
  name,
  formControl,
  disabled,
  isOptional,
  questionType,
  error,
}: Props) => {
  const { formatMonthsGap } = useFormatting()
  const { candidate } = useContext(CandidateContext)

  const { setValue, watch } = formControl
  const workExperiencesDictionary = useProfileWorkExperiences(candidate.experiencesHistory)

  const questionValue = watch(`${name}.value`)
  const workExperiencesName = `${name}.workExperiences`
  const workExperiences: Array<{ id: string; label: string }> = watch(workExperiencesName)

  const handleRemove = (workExperienceId: string) => {
    const updatedWorkExperiences = workExperiences.filter(
      (workExperience) => workExperience.id !== workExperienceId,
    )

    setValue(workExperiencesName, updatedWorkExperiences)
  }

  const shouldDisable =
    (questionType === QuestionType.Boolean && questionValue !== BooleanExpectedAnswer.Yes) ||
    (questionType === QuestionType.Numeric && !questionValue)

  useEffect(() => {
    if (shouldDisable) setValue(workExperiencesName, [])
  }, [shouldDisable])

  return (
    <div className="mt-6">
      <Label htmlFor={workExperiencesName}>Link work experiences{isOptional ? '' : '*'}</Label>
      <ConditionalWrapper
        shouldWrap={shouldDisable}
        wrapper={(children) => (
          <Tooltip
            childrenClassName="w-full"
            content={
              "No need to inform work experience when 'No' is chosen. If you have work experience to link, select 'Yes'."
            }
          >
            {children}
          </Tooltip>
        )}
      >
        <SelectTags
          name={workExperiencesName}
          options={candidate.experiencesHistory.map(
            ({ id, title, startDate, endDate, experienceOrganization: { name, website } }) => ({
              id,
              label: title,
              description: `${name} - ${formatMonthsGap(
                new Date(startDate),
                endDate ? new Date(endDate) : undefined,
                false,
              )}`,
              icon: website && <OrganizationIcon domain={website} size="sm" />,
            }),
          )}
          setValue={setValue}
          watch={watch}
          disabled={disabled || shouldDisable}
          placeholder="Select work experience"
          hideTags
          error={error?.message}
          selectedOptions={[]}
        />
      </ConditionalWrapper>
      <div className="flex flex-col gap-y-4 mt-4">
        {workExperiences?.map(({ id }) => (
          <WorkExperienceItem
            key={id}
            workExperience={workExperiencesDictionary[id]}
            onRemove={() => handleRemove(id)}
          />
        ))}
      </div>
    </div>
  )
}
