import { Text } from 'components/Text'
import { useFormatting } from 'shared/hooks'

import { ReactComponent as CheckIcon } from 'icons/check-circle-outlined.svg'

interface WithinBudgetBannerProps {
  budget: number
  client: string
}

export const WithinBudgetBanner = ({ client, budget }: WithinBudgetBannerProps) => {
  const { formatCurrency } = useFormatting()

  return (
    <div className="flex gap-3 px-3 py-2 bg-success-lighter rounded-lg">
      <CheckIcon className="stroke-success-medium h-6 w-6 min-w-[24px]" />
      <Text>
        The total talent cost for this job fits the {client} budget of {formatCurrency(budget)}.
      </Text>
    </div>
  )
}
