import { useContext, useEffect, useState } from 'react'

import { Drawer } from 'shared/components/molecules'

import { CandidateCompensation, DrawerCTA, ListingDetails, MainSkillsMatch } from './components'

import { JobListingSelector } from 'components/organisms/ListingSelector/ListingSelector'
import { CandidateInfo } from '../../CandidateInfo'

import { MatchBriefing } from 'matching/exports'

import { ReactComponent as ArrowLeftIcon } from 'icons/left-arrow.svg'

import { useJobListingPreview } from 'job-listing/exports'
import { useProfileMatcher } from 'hooks/matchings'
import { SearchContext, ResultCardStateContext } from 'candidates-search/contexts'
import { PaymentModel } from 'job-listing/enums'

import { Candidate } from 'candidates-search/domain'
import { useForm } from 'shared/hooks'
import { schema } from './schema'

interface ProfileDrawerProps {
  candidate: Candidate
  onBack: () => void
}

export const ProfileMatcher = ({ candidate, onBack }: ProfileDrawerProps) => {
  const { search } = useContext(SearchContext)
  const [minCompensation, setMinCompensation] = useState(candidate.monthlyRate.min)
  const [maxCompensation, setMaxCompensation] = useState(candidate.monthlyRate.max)
  const { matching } = useContext(ResultCardStateContext)

  const formControl = useForm({
    mode: 'onChange',
    schema,
    defaultValues: {
      highlightedSkills: [],
    },
  })

  const { watch, reset, isValid, setValue } = formControl

  const compensation = Number(watch('compensation'))

  const {
    clients,
    jobListings,
    onClientIdChange,
    onJobListingIdChange,
    simulation,
    jobListingId,
    isMissingParams,
    client,
    isLoadingSimulation,
    matchingSettings,
    isLoadingMatchingSettings,
  } = useProfileMatcher(candidate.id, compensation, isValid)

  const { listing } = useJobListingPreview(jobListingId)

  useEffect(() => {
    if (!listing) return

    const compensationRange = listing.hourlyRatesEnabled
      ? candidate.hourlyRate
      : candidate.monthlyRate
    const paymentModel = listing.hourlyRatesEnabled ? PaymentModel.Hourly : PaymentModel.Monthly

    setMinCompensation(compensationRange.min)
    setMaxCompensation(compensationRange.max)
    reset({
      compensation:
        matching?.compensation ?? matchingSettings?.compensation ?? compensationRange.min,
      paymentModel,
      highlightedSkills: matchingSettings?.highlightedSkills ?? [],
      briefing: matchingSettings?.briefing ?? '',
    })
  }, [listing?.hourlyRatesEnabled, matchingSettings])

  useEffect(() => {
    if (!listing?.stacks) return

    setValue(
      'highlightedSkills',
      listing
        .getCandidateMatchingSkills(
          candidate.primaryStacks.map(({ id, label, experience }) => ({
            stack: { id, label },
            experience,
          })),
        )
        .filter(({ highlighted }) => highlighted)
        .map(({ stack: { id, label } }) => ({ id, label })),
    )
  }, [candidate.primaryStacks, listing?.stacks])

  return (
    <Drawer
      position="right"
      title="Match candidate"
      isOpen
      handleClose={onBack}
      closeIcon={<ArrowLeftIcon className="w-6 h-6" />}
      cta={
        <DrawerCTA
          candidateId={candidate.id}
          simulation={simulation}
          isLoadingSimulation={isLoadingSimulation}
          isMissingParams={isMissingParams}
          formControl={formControl}
          onBack={onBack}
        />
      }
    >
      <div className="w-screen max-w-[760px] flex flex-col border-t border-neutral-lightest">
        <div className="flex flex-col gap-8 p-10">
          <CandidateInfo candidate={candidate} fromDrawer />
          {!search?.isListingLinked && (
            <JobListingSelector
              clients={clients}
              onClientIdChange={onClientIdChange}
              onJobListingIdChange={onJobListingIdChange}
              jobListings={jobListings}
            />
          )}
        </div>

        <div className="flex flex-col gap-[72px] p-10">
          <CandidateCompensation
            formControl={formControl}
            compensation={{ min: minCompensation, max: maxCompensation }}
            simulation={simulation}
            paymentModel={listing?.hourlyRatesEnabled ? PaymentModel.Hourly : PaymentModel.Monthly}
            client={client}
            isMissingParams={isMissingParams}
          />

          <MatchBriefing
            formControl={formControl}
            candidateFirstName={candidate.firstName}
            showDescription
            isLoading={isLoadingMatchingSettings}
          />

          <MainSkillsMatch candidate={candidate} listing={listing} />

          <ListingDetails listing={listing} />
        </div>
      </div>
    </Drawer>
  )
}
