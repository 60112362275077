import classNames from 'classnames'

interface ContentWrapperProps {
  children: React.ReactNode
  isForm?: boolean
  className?: string
  ariaLabel?: string
}

export const ContentWrapper = ({ children, isForm, className, ariaLabel }: ContentWrapperProps) => {
  return (
    <div
      aria-label={ariaLabel}
      className={classNames(
        'flex-wrap print:mb-3',
        {
          'mb-5': isForm,
          'sm:mb-10 mb-8': !isForm,
        },
        className,
      )}
    >
      {children}
    </div>
  )
}
