import React from 'react'

import { TertiaryButton } from 'shared/components/atoms'
import { Title } from 'shared/components/atoms'

import { ProfileOverview } from 'pages/JobSeekerProfile/types'

import { ReactComponent as CodeIcon } from 'icons/code-browser.svg'

export const TechnicalAssessmentSection = React.forwardRef(function TechnicalAssessmentSection(
  { profile }: { profile: ProfileOverview },
  ref: React.Ref<HTMLDivElement>,
) {
  const handleViewReportClick = () => {
    const { url } = profile.assessments[0]
    if (!url) return

    window.open(url, '_blank')
  }

  if (!profile.assessments.length) return null

  return (
    <section className="mb-14 print:hidden" ref={ref}>
      <Title size="heading" className="mb-8">
        Technical Assessment
      </Title>
      <div className="flex flex-row items-center justify-between border border-neutral-light rounded-lg p-6">
        <div className="flex flex-row items-center justify-start gap-4">
          <div className="w-12 h-12 bg-neutral-lightest rounded-lg flex items-center justify-center">
            <CodeIcon className="w-6 h-6 stroke-neutral-darkest" />
          </div>
          <Title size="small-heading">Strider technical assessment</Title>
        </div>
        <TertiaryButton onClick={handleViewReportClick}>View report</TertiaryButton>
      </div>
    </section>
  )
})
