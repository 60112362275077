import { Step } from '../domain'

export const useSteps = (profileCompleted: boolean, englishCheckCompleted: boolean) => {
  return [
    { title: 'Basic information', isCurrent: false, isDone: true, isLast: false },
    {
      title: 'Profile completion',
      isCurrent: !profileCompleted,
      isDone: profileCompleted,
      isLast: false,
    },
    {
      title: 'English check',
      isCurrent: profileCompleted && !englishCheckCompleted,
      isDone: profileCompleted && englishCheckCompleted,
      isLast: false,
    },
    {
      title: "You're ready",
      isCurrent: profileCompleted && englishCheckCompleted,
      isDone: false,
      isLast: true,
    },
  ] as Array<Step>
}
