import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { ClaimProtected } from 'shared/components/atoms'
import { FieldError } from 'shared/components/atoms'
import { Label } from 'shared/components/atoms'
import { CurrencyInput } from 'shared/components/atoms'
import { FieldWrapper } from 'shared/components/atoms'

import { AccountClaim, useAuth } from 'shared/hooks'
import { PaymentModel } from 'job-listing/enums'

interface BudgetProps {
  register: (key: string) => Array<string>
  watch: UseFormWatch<Record<string, string>>
  setValue: UseFormSetValue<Record<string, string>>
  getError: (field: string) => string | undefined
}

export const Budget = ({ register, watch, setValue, getError }: BudgetProps) => {
  const { user } = useAuth()

  const paymentModel = watch('paymentModel') as PaymentModel
  const rateError = getError('salaryFrom') || getError('salaryTo')

  const hiringThroughUs = Boolean(user.company?.hiringThroughUs)
  const hiringModeLabel = hiringThroughUs ? 'budget' : 'salary'

  const salaryRangeLabel = {
    [PaymentModel.Monthly]: `Monthly ${hiringModeLabel} (USD)`,
    [PaymentModel.Hourly]: `Hourly ${hiringModeLabel} (USD)`,
  }[paymentModel]

  return (
    <ClaimProtected require={AccountClaim.Staff}>
      <FieldWrapper className="!mt-0">
        <Label htmlFor="salaryTo" size="text-sm">
          {salaryRangeLabel}
        </Label>
        <div className="flex gap-4">
          <CurrencyInput
            name="salaryFrom"
            register={register}
            watch={watch}
            setValue={setValue}
            className="w-[140px]"
            showError={false}
          />
          <span className="flex items-center h-[40px] text-base font-normal text-neutral-darker">
            to
          </span>
          <CurrencyInput
            name="salaryTo"
            register={register}
            watch={watch}
            setValue={setValue}
            className="w-[140px]"
            showError={false}
          />
        </div>
        <FieldError message={rateError} />
      </FieldWrapper>
    </ClaimProtected>
  )
}
