import classNames from 'classnames'
import { Label } from 'shared/components/atoms'
import { Radio } from 'shared/components/atoms'
import { ReactNode } from 'react'

interface RateOptionsProps {
  selected: boolean
  value: string
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  icon: (className: string) => ReactNode
}

export const RateOptions = ({ selected, value, label, register, icon }: RateOptionsProps) => {
  return (
    <Label
      htmlFor={value}
      key={value}
      className={classNames(
        'flex flex-col items-center px-6 py-4 w-1/3 border-[1.5px] !font-normal',
        'cursor-pointer border-neutral-light rounded-lg gap-4',
        {
          'border-neutral-darker': selected,
        },
      )}
    >
      <span
        className={classNames('flex rounded-full h-10 w-10 items-center justify-center', {
          'bg-neutral-lightest': selected,
        })}
      >
        {icon(selected ? 'stroke-neutral-darker' : 'stroke-neutral-light')}
      </span>
      <span className={selected ? '' : 'text-neutral-light'}>{label}</span>
      <Radio register={register} value={value} name="rate" id={value} className="hidden" />
    </Label>
  )
}
