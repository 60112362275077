import { useEffect, useRef } from 'react'
import classNames from 'classnames'

import { HiddenForDemoAccounts } from 'components/Access'
import { PrimaryButton, TertiaryButton } from 'shared/components/atoms'
import { Input } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { Text } from 'components/Text'

import { SignerContact } from './SignerContact'
import { HiringCost } from '../HiringCost'
import { StartDateField } from '../fields/StartDateField'
import { Wrapper } from '../../Wrapper'

import { useForm } from 'shared/hooks'
import { HiringMode, useAuth } from 'shared/hooks'
import { useMakeOffer } from '../../hooks/useMakeOffer'

import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { OfferFormValues } from 'modules/hires/domain'
import { PaymentModel } from 'job-listing/enums'

import { schema } from './'
import { buildDefaultValues } from '../../build-default-values'
import { ModalHeader } from '../ModalHeader'
import { useHireLabel } from '../../hooks/useHireLabel'
import { useNavigate } from 'react-router-dom'

const contractUrl = String(process.env.REACT_APP_RETENTION_OFFER_AGREEMENT_URL)

export const RetentionOfferForm = ({
  matchingId,
  hiringCost,
  paymentModel,
  profile,
  isOpen,
  onClose,
}: {
  matchingId: string
  hiringCost: number
  paymentModel: PaymentModel
  profile: ProfileOverview
  isOpen: boolean
  onClose: () => void
}) => {
  const navigate = useNavigate()

  const jobTitleRef = useRef<HTMLInputElement>(null)
  const { makeOffer, isRequesting } = useMakeOffer(matchingId, HiringMode.ThroughUsEmployerOfRecord)
  const { label: hireLabel } = useHireLabel(profile.firstName)
  const {
    user: { company },
  } = useAuth()
  const defaultValues = buildDefaultValues(company?.name, contractUrl)

  const { register, setValue, handleSubmit, getValues, watch, getError, isValid, reset } = useForm({
    mode: 'onChange',
    schema,
    defaultValues,
  })

  const handleNavigate = () => navigate('/offer-sent', { state: { firstName: profile.firstName } })

  const handleSubmitAttempt = handleSubmit(() => {
    const formValues = getValues() as OfferFormValues
    makeOffer(formValues).then(handleNavigate)
  })

  const handleClose = () => {
    onClose()
    reset()
  }

  useEffect(() => {
    jobTitleRef.current?.focus()
  }, [])

  const FormContent = (
    <form className="px-8" noValidate onSubmit={handleSubmitAttempt}>
      <Wrapper label="Job title" id="jobTitle" className="!mb-7">
        <Input register={register} name="jobTitle" id="jobTitle" ref={jobTitleRef} />
      </Wrapper>
      <Wrapper label="Description of services" id="description">
        <Text size="body" truncate={false}>
          {defaultValues.description}
        </Text>
      </Wrapper>
      <Wrapper label="Preferred start date" id="preferredStartDates">
        <StartDateField
          name="preferredStartDates"
          getError={getError}
          profile={profile}
          setValue={setValue}
          watch={watch}
        />
      </Wrapper>
      <HiddenForDemoAccounts>
        <HiringCost hiringCost={hiringCost} paymentModel={paymentModel} />
      </HiddenForDemoAccounts>
      <Wrapper>
        <SignerContact
          register={register}
          watch={watch}
          setValue={setValue}
          profile={profile}
          getError={getError}
          companyName={company?.name!}
        />
      </Wrapper>
      <div className="flex flex-col gap-4">
        <PrimaryButton
          className={classNames('w-full', { 'opacity-25': !isValid })}
          type="submit"
          isLoading={isRequesting}
        >
          {hireLabel}
        </PrimaryButton>
        <TertiaryButton className="w-full" onClick={handleClose}>
          Cancel
        </TertiaryButton>
      </div>
    </form>
  )

  return (
    <>
      <Modal
        open={isOpen}
        withBorders={false}
        showClose={false}
        titleClassName="mt-5 w-full text-center"
        title={<ModalHeader profile={profile} />}
        content={FormContent}
      />
    </>
  )
}
