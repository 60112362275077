import { SignIn as ClerkSignInWidget } from '@clerk/clerk-react'

import { signUpAppearance } from 'authentication/consts'

interface SignInProps {
  redirectUrl: string | null
}

export const SignInForm = ({ redirectUrl }: SignInProps) => {
  return (
    <ClerkSignInWidget
      signUpUrl="/signup"
      redirectUrl={redirectUrl}
      appearance={signUpAppearance({ hideLogo: true })}
    />
  )
}
