import { useContext } from 'react'
import { plainToInstance } from 'class-transformer'

import { useNetwork } from 'shared/hooks'
import { SearchContext } from 'candidates-search/contexts'

import { Search } from 'candidates-search/domain'

export const useUpdateSearchInfo = () => {
  const { setSearch, setFilter } = useContext(SearchContext)
  const { get } = useNetwork()

  const updateSearchInfo = (searchId?: string) =>
    get<Search>(`advanced-search/${searchId}/info`).then(({ data }) => {
      const search = plainToInstance(Search, data)
      setSearch(search)
      setFilter(search.filter)
    })

  return { updateSearchInfo }
}
