import { useState } from 'react'
import { ReactComponent as InfoIcon } from 'icons/info-icon.svg'
import { TermsOfService } from 'onboarding/exports'
import {
  Checkbox,
  Label,
  LocationSearchInput,
  Paragraph,
  PrimaryButton,
  RadioGroup,
  Title,
  Tooltip,
} from 'shared/components/atoms'

import { useAuth, useForm } from 'shared/hooks'
import { useCreateRecruiter, useAgreements, useAcceptSupplementalTerms } from 'onboarding/exports'

import { usePendingAgreements } from 'pages/PendingAgreementChecker/usePendingAgreements'
import { usePendingSupplementalTerms } from 'pages/PendingSupplementalTermsChecker/usePendingSupplementalTerms'

import { Agreements } from 'account/types'

import { SupplementalTermsFeature } from 'modules/supplemental-terms/enums'
import { CreateSeekerRecruiterValues } from 'modules/recruiter'
import { buildSchema } from '.'
import { LocationType } from 'shared/components/atoms/Form/Input/LocationSearchInput/location-info'

const USTaxPayerOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
]

export const SeekerOnboarding = () => {
  const {
    user: { isCommunicationAllowed },
  } = useAuth()
  const [agreement] = usePendingAgreements()
  const [supplementalTerms] = usePendingSupplementalTerms(SupplementalTermsFeature.Refer)
  const { acceptAgreement } = useAgreements()
  const { acceptTerms } = useAcceptSupplementalTerms(SupplementalTermsFeature.Refer)

  const { createSeekerRecruiter, isCreating } = useCreateRecruiter()

  const { isValid, register, handleSubmit, getValues, setValue, watch } = useForm({
    schema: buildSchema(isCommunicationAllowed),
    defaultValues: {},
    mode: 'onBlur',
  })

  const [activeAgreement, setActiveAgreement] = useState<Agreements | undefined>()

  const handleTosLinkClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setActiveAgreement(agreement!)
  }

  const handleSupplementalTermsLinkClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setActiveAgreement(supplementalTerms!)
  }

  const handleTosAccept = () => {
    setValue('isTosAccepted', true, { shouldValidate: true })
    setActiveAgreement(undefined)
  }

  const handleTosDisagree = () => {
    setValue('isTosAccepted', false, { shouldValidate: true })
    setActiveAgreement(undefined)
  }

  const onSubmit = handleSubmit(async () => {
    const values = getValues() as CreateSeekerRecruiterValues

    if (agreement) {
      await acceptAgreement(agreement)
    }

    if (supplementalTerms) {
      await acceptTerms(supplementalTerms)
    }

    createSeekerRecruiter(values)
  })

  const location = watch('location')

  return (
    <div className="w-full grid grid-cols-12 gap-x-6 mt-4">
      <header className="col-start-3 col-end-11 flex flex-col gap-2">
        <Title size="display" color="text-neutral-darkest">
          Refer talent to Strider
        </Title>
        <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark">
          Refer is an easy way for you to earn money by referring people to Strider. You get paid
          when your referrals complete their profile and again when they get hired. Please share a
          bit of information below so we can verify your eligibility.
        </Paragraph>
      </header>
      <form
        className="col-start-3 col-end-11 mt-10 grid grid-cols-8 gap-x-6 gap-y-10"
        onSubmit={onSubmit}
      >
        <div className="col-span-4">
          <Tooltip
            content="An individual's country of 'tax residence', also known as 'fiscal residence', is the country to which an individual is responsible for paying taxes"
            className="z-50 max-w-[200px]"
          >
            <div className="flex gap-2 items-center cursor-pointer">
              <Label htmlFor="location" size="text-sm">
                Which country are you a tax resident of?
              </Label>
              <InfoIcon className="mb-2" />
            </div>
          </Tooltip>

          <LocationSearchInput
            name="location"
            setValue={setValue}
            location={location}
            showFieldError={false}
            locationType={LocationType.Country}
            placeholder="e.g. Brazil"
          />
        </div>

        <div className="col-span-8">
          <Label htmlFor="USTaxPayer">Are you a US citizen or taxpayer?</Label>
          <RadioGroup
            register={register}
            watch={watch}
            options={USTaxPayerOptions}
            name="USTaxPayer"
            omitErrors
            outlined
          />
        </div>

        <div className="col-span-8 flex flex-col items-stretch gap-4">
          <div className="flex gap-2">
            <Label htmlFor="isTosAccepted" className="cursor-pointer">
              <Checkbox register={register} name="isTosAccepted" id="isTosAccepted" />I agree to the{' '}
              {Boolean(agreement) && (
                <>
                  <span className="text-info-medium z-40" onClick={handleTosLinkClick}>
                    Terms of Service and Privacy Policy and
                  </span>{' '}
                </>
              )}
              {Boolean(supplementalTerms) && (
                <span className="text-info-medium" onClick={handleSupplementalTermsLinkClick}>
                  Refer Terms{' '}
                </span>
              )}
            </Label>
          </div>

          {!isCommunicationAllowed && (
            <div className="col-span-8 flex gap-2">
              <Label htmlFor="isCommunicationAccepted" className="cursor-pointer">
                <Checkbox
                  register={register}
                  name="isCommunicationAccepted"
                  id="isCommunicationAccepted"
                />
                I agree to be contacted by Strider
              </Label>
            </div>
          )}
        </div>

        <PrimaryButton
          type="submit"
          size="lg"
          disabled={!isValid}
          isLoading={isCreating}
          className="col-span-8 w-fit"
        >
          Continue to refer
        </PrimaryButton>
      </form>

      {Boolean(activeAgreement) && (
        <TermsOfService
          title="Referral Terms"
          agreement={activeAgreement!}
          onAccept={handleTosAccept}
          onDisagree={handleTosDisagree}
        />
      )}
    </div>
  )
}
