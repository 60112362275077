import { Text, Tags } from 'shared/components/atoms'
import { ReactComponent as CheckIcon } from 'icons/check-circle-outlined.svg'
import { ReactComponent as CheckIcon2 } from 'icons/check.svg'

interface Props {
  skills: Array<{ id: number; label: string }>
}

export const MatchingSkills = ({ skills }: Props) => {
  if (!skills.length) return null
  return (
    <div>
      <div className="flex gap-x-2 mb-4 items-center">
        <CheckIcon className="stroke-success-medium h-4 w-4" />
        <Text size="text-sm" className="text-success-darker">
          <span className="text-success-darker font-medium">{skills!.length} main skills</span>{' '}
          matched with your profile
        </Text>
      </div>
      <div>
        <Tags
          icon={<CheckIcon2 className=" stroke-success-light w-4 h-4" />}
          background="bg-success-lighter"
          values={skills!}
          isReadOnly
        />
      </div>
    </div>
  )
}
