import { useMutation, useQueryClient } from 'react-query'
import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useScreening = (listingId: string, screeningId: string) => {
  const client = useQueryClient()
  const { patch, delete: deleteReq } = useNetwork()
  const { toastSuccess, toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: archive } = useMutation(
    () => patch(`listings/${listingId}/screenings/${screeningId}/archive`),
    {
      onSuccess: () => {
        toastSuccess('Screening archived.')
        client.invalidateQueries(['screenings'])
      },
      onError: (error: RequestError) => {
        toastError(`Failed to update the screening: ${error.response?.data.message}`)
        captureException(error)
      },
    },
  )

  const { mutateAsync: deleteScreening } = useMutation(
    () => deleteReq(`/listings/${listingId}/screenings/${screeningId}`).then(({ data }) => data),
    {
      onSuccess: () => {
        toastSuccess('Screening deleted.')
        client.invalidateQueries(['screenings'])
      },
      onError: (error: RequestError) => {
        toastError(`Failed to delete the screening: ${error.response?.data.message}`)
        captureException(error)
      },
    },
  )

  return { archive, deleteScreening }
}
