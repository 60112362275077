import React, { Dispatch, SetStateAction, useState } from 'react'
import { IntercomProvider } from 'react-use-intercom'

const intercomAppId = String(process.env.REACT_APP_INTERCOM_APP_ID)
const intercomApiBase = process.env.REACT_APP_INTERCOM_API_BASE

export const HelpContext = React.createContext({
  isChatOpen: false,
  setIsChatOpen: (() => {}) as Dispatch<SetStateAction<boolean>>,
  unreadMessagesCount: 0,
  isChatInitialized: false,
  setIsChatInitialized: (() => {}) as Dispatch<SetStateAction<boolean>>,
})

export const HelpProvider = ({ children }: { children: React.ReactNode }) => {
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [isChatInitialized, setIsChatInitialized] = useState(false)
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)

  const handleHide = () => setIsChatOpen(false)
  const handleShow = () => setIsChatOpen(true)

  const context = {
    isChatOpen,
    setIsChatOpen,
    unreadMessagesCount,
    isChatInitialized,
    setIsChatInitialized,
  }

  return (
    <HelpContext.Provider value={context}>
      <IntercomProvider
        appId={intercomAppId}
        apiBase={intercomApiBase}
        onUnreadCountChange={setUnreadMessagesCount}
        onHide={handleHide}
        onShow={handleShow}
      >
        {children}
      </IntercomProvider>
    </HelpContext.Provider>
  )
}
