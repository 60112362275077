export class CandidatePhoto {
  constructor(private readonly defaultUrl: string | null) {}

  get url() {
    return this.defaultUrl || this.fallbackUrl
  }

  get fallbackUrl() {
    return '/icons/empty-candidate-photo.svg'
  }
}
