import { useState } from 'react'
import { cn } from 'modules/shared'

import { Text, Tooltip } from 'shared/components/atoms'
import { DescriptionBlock, ItemDetails } from './components'

import { useFormatting } from 'shared/hooks'

import { ChangeLog } from 'candidates-search/domain'

interface TimelineItemProps {
  item: ChangeLog
  isLast?: boolean
}

export const TimelineItem = ({ item, isLast }: TimelineItemProps) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)

  const { getWrittenTimeDifference, formatDateTime } = useFormatting()

  const handleClick = () => {
    if (!item.hasDetails) return
    setIsDetailsOpen((prev) => !prev)
  }

  return (
    <div
      className={cn('flex gap-4 items-start pr-8 pl-2 rounded-lg relative', {
        'outline outline-1 outline-neutral-lightest': isDetailsOpen,
        'hover:cursor-pointer': item.hasDetails,
      })}
      onClick={handleClick}
    >
      <div className="flex flex-col items-center gap-1 w-12 px-4 absolute inset-0">
        <div className="w-[1px] h-[20px] bg-neutral-lighter" />
        <div
          className={cn('w-[10px] h-[10px] bg-neutral-lighter rounded-full', {
            'bg-neutral-darkest': isDetailsOpen,
          })}
        />
        <div
          className={cn('w-[1px] h-[calc(100%-38px)] bg-neutral-lighter', { 'opacity-0': isLast })}
        />
      </div>
      <div className="flex flex-col w-full ml-12">
        <div className="flex gap-2 items-center justify-between py-4">
          <Text size="text-sm" weight="font-normal" className="flex gap-1 items-center flex-wrap">
            {item.description!.map((block, index) => (
              <>
                <DescriptionBlock key={index} block={block} />{' '}
              </>
            ))}
          </Text>
          <Tooltip
            content={formatDateTime({ date: new Date(item.date) })}
            childrenClassName="flex items-center"
          >
            <Text
              size="text-xs"
              weight="font-normal"
              className="text-neutral-dark !whitespace-nowrap"
            >
              {getWrittenTimeDifference(item.date)}
            </Text>
          </Tooltip>
        </div>
        <ItemDetails isOpen={isDetailsOpen} changeLog={item} />
      </div>
    </div>
  )
}
