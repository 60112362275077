import { useVettingPackage } from 'vetting/exports'
import { ImproveProfileDialog } from './components/ImproveProfileDialog'
import { EnglishCheckMandatoryDialog } from './components/EnglishCheckMandatoryDialog'

interface ApplicationSentProps {
  onFinish: () => void
  jobOpportunityId: string
}

export const ApplicationSent = ({ onFinish, jobOpportunityId }: ApplicationSentProps) => {
  const { vettingPackage } = useVettingPackage()

  const isEnglishCheckCompleted = Boolean(vettingPackage)

  if (isEnglishCheckCompleted) {
    return <ImproveProfileDialog onFinish={onFinish} jobOpportunityId={jobOpportunityId} />
  }

  return <EnglishCheckMandatoryDialog jobOpportunityId={jobOpportunityId} />
}
