import { boolean, BooleanSchema, object } from 'yup'

import { TimelineFilterValues } from 'candidates-search/domain'
import { MatchingEventFilter, ProfileEventFilter } from 'candidates-search/enums'

export const schema = object().shape({
  matchingEventsNone: boolean(),
  matchingEvents: object().shape<Record<MatchingEventFilter, BooleanSchema>>({
    [MatchingEventFilter.MatchingUpdates]: boolean(),
    [MatchingEventFilter.ScreeningProgress]: boolean(),
    [MatchingEventFilter.CommunicationSent]: boolean(),
  }),
  profileEventsNone: boolean(),
  profileEvents: object().shape<Record<ProfileEventFilter, BooleanSchema>>({
    [ProfileEventFilter.ProfileUpdates]: boolean(),
    [ProfileEventFilter.AssessmentTags]: boolean(),
    [ProfileEventFilter.Proficiencies]: boolean(),
    [ProfileEventFilter.RolesOfInterest]: boolean(),
    [ProfileEventFilter.MainSkills]: boolean(),
    [ProfileEventFilter.WorkExperience]: boolean(),
    [ProfileEventFilter.PayRate]: boolean(),
    [ProfileEventFilter.EnglishCheck]: boolean(),
    [ProfileEventFilter.STA]: boolean(),
    [ProfileEventFilter.Education]: boolean(),
    [ProfileEventFilter.Certification]: boolean(),
    [ProfileEventFilter.Bio]: boolean(),
    [ProfileEventFilter.PersonalInfo]: boolean(),
    [ProfileEventFilter.Preferences]: boolean(),
    [ProfileEventFilter.AdvertisementConsent]: boolean(),
  }),
  matchings: object().shape({}),
})

export const defaultMatchingEvents: Record<MatchingEventFilter, boolean> = {
  [MatchingEventFilter.MatchingUpdates]: false,
  [MatchingEventFilter.ScreeningProgress]: false,
  [MatchingEventFilter.CommunicationSent]: false,
}

export const defaultProfileEvents: Record<ProfileEventFilter, boolean> = {
  [ProfileEventFilter.ProfileUpdates]: false,
  [ProfileEventFilter.AssessmentTags]: false,
  [ProfileEventFilter.Proficiencies]: false,
  [ProfileEventFilter.RolesOfInterest]: false,
  [ProfileEventFilter.MainSkills]: false,
  [ProfileEventFilter.WorkExperience]: false,
  [ProfileEventFilter.PayRate]: false,
  [ProfileEventFilter.EnglishCheck]: false,
  [ProfileEventFilter.STA]: false,
  [ProfileEventFilter.Education]: false,
  [ProfileEventFilter.Certification]: false,
  [ProfileEventFilter.Bio]: false,
  [ProfileEventFilter.PersonalInfo]: false,
  [ProfileEventFilter.Preferences]: false,
  [ProfileEventFilter.AdvertisementConsent]: false,
}

export const buildDefaultValues = (matchingId?: string) => {
  const defaultValues: TimelineFilterValues = new TimelineFilterValues({
    matchings: {},
    matchingEventsNone: false,
    matchingEvents: defaultMatchingEvents,
    profileEventsNone: false,
    profileEvents: defaultProfileEvents,
  })

  if (matchingId) defaultValues.matchings[matchingId] = true

  return defaultValues
}
