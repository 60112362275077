interface CreateSearchRequest {
  title: string
  url: string
}

export interface CreateSearchValues {
  title: string
  url: string
}

export class CreateSearchDTO {
  constructor(private readonly values: CreateSearchValues) {}

  toJSON(): CreateSearchRequest {
    return {
      title: this.values.title,
      url: this.values.url,
    }
  }
}
