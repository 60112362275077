import { number, object, string } from 'yup'
import { JobOpportunityDeclinationReasons } from 'job-opportunity/enums'

export const schema = object().shape({
  notes: string().optional(),
  reasons: object().test(
    'at_least_one_option_selected',
    'Select at least one option.',
    (reasons) => {
      if (!reasons) return false
      return Object.values(reasons).some((reason) => reason)
    },
  ),
  otherReason: string().when('reasons', {
    is: (reasons: { [key: string]: boolean }) => reasons[JobOpportunityDeclinationReasons.Other],
    then: string().required('Please inform the reason.'),
    otherwise: string().optional(),
  }),
  pausedDays: string().when('reasons', {
    is: (reasons: { [key: string]: boolean }) =>
      reasons[JobOpportunityDeclinationReasons.NotAvailable],
    then: string().required('Select one option.'),
    otherwise: string().optional(),
  }),
  desiredPayRate: number().when('reasons', {
    is: (reasons: { [key: string]: boolean }) =>
      reasons[JobOpportunityDeclinationReasons.LowPayRate],
    then: number()
      .required('Please inform the value.')
      .min(1000, "The desired pay rate can't be less than $1000")
      .max(30000, "The desired pay rate can't be greater than $30000"),
    otherwise: number().optional(),
  }),
})
