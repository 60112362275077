import { HTMLAttributes, useEffect, useState } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import classNames from 'classnames'
import * as SliderPrimitive from '@radix-ui/react-slider'

import { Text } from 'components/Text'
import { FieldError } from 'shared/components/atoms'

import { SliderOption } from '.'

interface SliderProps extends HTMLAttributes<HTMLDivElement> {
  isRangeSlider: boolean
  icon?: React.ReactNode
  name: string
  options: Array<SliderOption>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormValue: UseFormSetValue<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  showFieldError?: boolean
  error?: string
  updatePositionOnWatch?: boolean
}

const Thumb = () => (
  <SliderPrimitive.Thumb className="block w-6 h-6 rounded-full border-2 border-neutral-light bg-neutral-day hover:bg-neutral-lightest shadow-[0_4px_8px_-4px_rgba(0,0,0,0.08) cursor-pointer" />
)

export const Slider = ({
  isRangeSlider,
  icon,
  name,
  options,
  setFormValue,
  watch,
  showFieldError,
  error,
  updatePositionOnWatch = false,
  ...restProps
}: SliderProps) => {
  const minSuffix = isRangeSlider ? '.min' : ''

  const minValue = watch(`${name}${minSuffix}`)
  const maxValue = watch(`${name}.max`)
  const getMinPosition = (minValue: number) => options.findIndex(({ value }) => value === minValue)
  const getMaxPosition = (maxValue: number) => options.findIndex(({ value }) => value === maxValue)

  const [initialRender, setInitialRender] = useState(true)
  const [sliderPosition, setSliderPosition] = useState(
    isRangeSlider
      ? [getMinPosition(minValue), getMaxPosition(maxValue)]
      : [getMinPosition(minValue)],
  )

  const handleSlidePositionChange = (sliderPosition: number[]) => {
    if (!sliderPosition || !sliderPosition.length) return
    setSliderPosition(sliderPosition)

    const newMinValue = options[sliderPosition[0]]?.value
    setFormValue(`${name}${minSuffix}`, newMinValue, { shouldDirty: true })
    if (isRangeSlider) {
      const newMaxValue = options[sliderPosition[1]]?.value
      setFormValue(`${name}.max`, newMaxValue, { shouldDirty: true })
    }
  }

  useEffect(() => {
    if (initialRender) return setInitialRender(false)
    if (isRangeSlider) return handleSlidePositionChange([0, options.length - 1])
    handleSlidePositionChange([0])
  }, [options])

  useEffect(() => {
    if (!updatePositionOnWatch) return
    if (isRangeSlider) {
      return setSliderPosition([getMinPosition(minValue), getMaxPosition(maxValue)])
    }
    setSliderPosition([getMinPosition(minValue)])
  }, [minValue, maxValue])

  return (
    <div {...restProps} className="flex flex-col">
      <SliderPrimitive.Root
        className="flex items-center h-5 relative mb-3"
        minStepsBetweenThumbs={1}
        value={sliderPosition}
        onValueChange={handleSlidePositionChange}
        defaultValue={[getMinPosition(minValue), getMaxPosition(maxValue)]}
        min={0}
        max={options.length - 1}
      >
        <SliderPrimitive.Track className="grow bg-neutral-light h-0.5 relative">
          <SliderPrimitive.Range className="absolute h-0.5 bg-neutral-darker" />
        </SliderPrimitive.Track>

        <Thumb />

        {isRangeSlider && <Thumb />}
      </SliderPrimitive.Root>

      <div
        className={classNames('grid', {
          'grid-cols-5': isRangeSlider,
          'grid-cols-1': !isRangeSlider,
        })}
      >
        <Text size="body" className="col-span-2 text-start">
          {icon}
          {options.find(({ value }) => value === minValue)?.label}
        </Text>
        {Boolean(isRangeSlider) && (
          <>
            <Text color="text-neutral-medium" className="col-span-1 text-center">
              to
            </Text>
            <Text size="body" className="col-span-2 text-right">
              {icon}
              {options.find(({ value }) => value === maxValue)?.label}
            </Text>
          </>
        )}
      </div>
      {showFieldError && <FieldError message={error} />}
    </div>
  )
}
