import { useMutation, useQueryClient } from 'react-query'

import { useMonitoring, useNetwork } from 'shared/hooks'

export const useStacks = () => {
  const client = useQueryClient()
  const { post } = useNetwork()
  const { captureException } = useMonitoring()

  const { mutateAsync: createStack, isLoading } = useMutation(
    ({ label, shouldPublish }: { label: string; shouldPublish?: boolean }) =>
      post<{ id: number; label: string; source: string }>('listings-stacks', {
        label,
        source: 'platform',
        shouldPublish,
      }),
    {
      onSuccess: () => client.invalidateQueries('stacks'),
      onError: (error: RequestError) => {
        captureException(error)
      },
    },
  )

  return { createStack, isLoading }
}
