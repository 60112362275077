import React, { useState, Dispatch, SetStateAction } from 'react'

export enum BackgroundColor {
  NeutralDay = 'bg-neutral-day',
  NeutralLightest = 'bg-neutral-lightest',
}

export const LayoutContext = React.createContext({
  shouldHideNavbar: false,
  hideBanner: false,
  setHideBanner: (() => null) as Dispatch<SetStateAction<boolean>>,
  setHideNavbar: (() => null) as Dispatch<SetStateAction<boolean>>,
  shouldHideTerms: false,
  setHideTerms: (() => null) as Dispatch<SetStateAction<boolean>>,
  shouldRootHavePadding: true,
  setRootPadding: (() => null) as Dispatch<SetStateAction<boolean>>,
  shouldRootHaveLimitedSize: true,
  setBackgroundColor: (() => null) as Dispatch<SetStateAction<BackgroundColor>>,
  backgroundColor: '',
  setRootHaveLimitedSize: (() => null) as Dispatch<SetStateAction<boolean>>,
  setFluidContainer: () => {},
  setDefaultContainer: () => {},
  shouldScroll: true,
  setShouldScroll: (() => null) as Dispatch<SetStateAction<boolean>>,
})

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [shouldHideNavbar, setHideNavbar] = useState(false)
  const [shouldHideTerms, setHideTerms] = useState(false)
  const [shouldRootHavePadding, setRootPadding] = useState(true)
  const [shouldRootHaveLimitedSize, setRootHaveLimitedSize] = useState(true)
  const [backgroundColor, setBackgroundColor] = useState(BackgroundColor.NeutralDay)
  const [hideBanner, setHideBanner] = useState(false)
  const [shouldScroll, setShouldScroll] = useState(true)

  const setFluidContainer = () => {
    setHideNavbar(true)
    setRootPadding(false)
    setRootHaveLimitedSize(false)
  }

  const setDefaultContainer = () => {
    setShouldScroll(true)
    setHideNavbar(false)
    setRootPadding(true)
    setRootHaveLimitedSize(true)
    setBackgroundColor(BackgroundColor.NeutralDay)
  }

  const layoutContext = {
    shouldHideNavbar,
    setHideNavbar,
    shouldHideTerms,
    setHideTerms,
    shouldRootHavePadding,
    setRootPadding,
    shouldRootHaveLimitedSize,
    setBackgroundColor,
    backgroundColor,
    setRootHaveLimitedSize,
    setFluidContainer,
    setDefaultContainer,
    hideBanner,
    setHideBanner,
    shouldScroll,
    setShouldScroll,
  }

  return <LayoutContext.Provider value={layoutContext}>{children}</LayoutContext.Provider>
}
