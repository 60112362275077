import { ReactComponent as CheckIcon } from 'icons/check-circle.svg'
import { cn } from 'modules/shared'
import { Text } from 'shared/components/atoms'

interface Props {
  isSelected: boolean
  title: string
  description: string
  onClick: () => void
}

export const Option = ({ isSelected, title, description, onClick }: Props) => {
  return (
    <div
      className={cn(
        'flex flex-grow p-6 gap-6 rounded-lg items-center backdrop-blur-xs hover:bg-neutral-lightest',
        {
          'border-2 border-neutral-darkest pointer-events-none': isSelected,
          'border border-neutral-light cursor-pointer': !isSelected,
        },
      )}
      onClick={onClick}
    >
      <div className="flex flex-grow justify-between items-center">
        <div className="flex flex-col">
          <Text size="text-lg" className="text-neutral-darkest">
            {title}
          </Text>
          <Text size="text-sm" className="text-neutral-dark">
            {description}
          </Text>
        </div>
        {Boolean(isSelected) && <CheckIcon className="fill-neutral-darkest w-6 h-6" />}
      </div>
    </div>
  )
}
