import { useCallback, useRef, useState } from 'react'

export const useBrowserPrint = () => {
  const [printableKey, setPrintableKey] = useState<number>(0)
  const printableRef = useRef<HTMLDivElement>(null)

  const print = (
    element: HTMLDivElement,
    updateCallerKeyCallback: () => void,
    fileName: string,
  ) => {
    const newWindow = window.open() as Window

    newWindow.document.write('<!DOCTYPE html')
    newWindow.document.write('<html>')
    newWindow.document.write('<head>')
    newWindow.document.write('</head>')
    newWindow.document.write('<body>')
    newWindow.document.write('</body>')
    newWindow.document.write('</html>')

    newWindow.document.title = fileName
    newWindow.document.body.appendChild(element)

    const parentHead = window.document.querySelector('head')!.childNodes
    parentHead.forEach((item) => {
      newWindow.document.head.appendChild(item.cloneNode(true))
    })

    setTimeout(() => {
      newWindow.document.close()
      newWindow.focus()
      newWindow.print()
      newWindow.close()
    }, 250)
    updateCallerKeyCallback()
  }

  const handlePrint = (fileName: string) =>
    print(printableRef.current!, forcePrintableKeyUpdate, fileName)

  const forcePrintableKeyUpdate = useCallback(() => setPrintableKey(Math.random()), [])

  return { printableKey, printableRef, handlePrint }
}
