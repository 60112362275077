export const REFERRAL_ARTICLES_IDS = {
  PaymentGuide: 6986019,
  JobSeekerQualificationGuide: 8834651,
  RecruiterQualificationGuide: 7061501,
  GettingStarted: 7061500,
  Guidelines: 7061501,
  Process: 7061506,
  FeeSchedule: 6986019,
  FAQ: 7203606,
}
