import { Title } from 'shared/components/atoms'
import { SearchContext } from 'candidates-search/contexts'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import React, { useContext, useMemo, useState } from 'react'
import { TimelineItem, TimelineItemProps } from './Timeline'
import { Text } from 'shared/components/atoms'
import { RepliedScreeningTitle } from './RepliedScreeningTitle'
import { AnswersModal } from '../AnswersModal'
import { ScreeningDescription } from './ScreeningDescription'
import { ScreeningFeedback } from './ScreeningFeedback'
import { useProfileWorkExperiences } from 'hooks/profile'
import { ProfileSection } from 'candidates-search/enums'

interface TimelineItemScreeningProps extends TimelineItemProps {
  visible: boolean
}

interface ScreeningSectionProps {
  profile: ProfileOverview
  openAnswers?: boolean
  setDefaultSection: (section: ProfileSection) => void
}

export const ScreeningSection = React.forwardRef(function ScreeningSection(
  { profile, openAnswers = false, setDefaultSection }: ScreeningSectionProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const { search } = useContext(SearchContext)
  const [answersModalOpen, setAnswersModalOpen] = useState(openAnswers)

  const handleAnswersModalOpen = () => setAnswersModalOpen(true)
  const handleAnswersModalClose = () => {
    setAnswersModalOpen(false)
    setDefaultSection(ProfileSection.Overview)
  }

  const { jobOpportunities, experiencesHistory } = profile
  const jobOpportunity = useMemo(
    () =>
      jobOpportunities.find((jobOpportunity) => jobOpportunity.listingId == search?.listing?.id),
    [jobOpportunities, search?.listing?.id],
  )
  const candidateExperiences = useProfileWorkExperiences(experiencesHistory)

  const descriptions = [<ScreeningDescription key="screening-description" />]
  const timeLineItems: TimelineItemScreeningProps[] = [
    {
      date: jobOpportunity?.declinedAt,
      title: <RepliedScreeningTitle jobOpportunity={jobOpportunity} />,
      descriptions: [
        ...descriptions,
        <ScreeningFeedback
          key="screening-feedback"
          additionalNotes={jobOpportunity?.additionalNotes}
          declinedReasons={jobOpportunity?.declinedReasons}
        />,
      ],
      visible: Boolean(jobOpportunity?.declinedAt),
    },
    {
      date: jobOpportunity?.expiredAt,
      title: (
        <div className="flex flex-row gap-1">
          <Text size="text-base" weight="font-medium">
            Expired
          </Text>
        </div>
      ),
      visible: Boolean(jobOpportunity?.expiredAt),
      descriptions: descriptions,
    },
    {
      date:
        jobOpportunity?.approvedAt ||
        jobOpportunity?.rejectedAt ||
        jobOpportunity?.submittedAt ||
        jobOpportunity?.updatedAt,
      title: <RepliedScreeningTitle jobOpportunity={jobOpportunity} />,
      descriptions: descriptions,
      action: handleAnswersModalOpen,
      visible: Boolean(
        jobOpportunity?.approvedAt ||
          jobOpportunity?.rejectedAt ||
          (jobOpportunity?.submittedAt && jobOpportunity?.screeningAnswers) ||
          jobOpportunity?.screeningAnswers,
      ),
    },
    {
      date: jobOpportunity?.startedFillingAt,
      title: (
        <Text size="text-base" weight="font-medium">
          Started answering
        </Text>
      ),
      descriptions,
      visible: Boolean(jobOpportunity?.startedFillingAt),
    },
    {
      date: jobOpportunity?.firstViewedAt,
      title: (
        <Text size="text-base" weight="font-medium">
          Viewed
        </Text>
      ),
      descriptions,
      visible: Boolean(jobOpportunity?.firstViewedAt),
    },
    {
      date: jobOpportunity?.createdAt,
      title: (
        <div className="flex flex-row gap-1">
          <Text size="text-base" weight="font-medium">
            Assigned
          </Text>
        </div>
      ),
      descriptions: descriptions,
      showLine: false,
      visible: Boolean(jobOpportunity?.createdAt),
    },
  ]

  if (!jobOpportunity) return null

  return (
    <section ref={ref} className="mb-8 sm:mb-16 grow w-full print:mb-6">
      <Title size="heading" className="mb-8">
        Screening
      </Title>

      <ul className="pl-16 flex flex-col gap-12">
        {timeLineItems
          .filter((item) => item.visible)
          .map((item, index) => (
            <TimelineItem key={item.date} {...item} isFirst={index === 0} />
          ))}
      </ul>

      <AnswersModal
        profile={profile}
        jobOpportunity={jobOpportunity}
        open={answersModalOpen}
        onClose={handleAnswersModalClose}
        workExperiences={candidateExperiences}
      />
    </section>
  )
})
