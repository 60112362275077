import { Link } from 'shared/components/atoms'
import { OrganizationIcon } from 'shared/components/molecules'
import { Text } from 'shared/components/atoms'
import { JobDescription as JobDescriptionInJobOpportunity } from 'job-opportunity/domain'
import { JobDescription as JobDescriptionInScreening } from 'screening/domain'

interface Props {
  jobDescription: JobDescriptionInJobOpportunity | JobDescriptionInScreening
  title: string
  children?: JSX.Element
}

export const Header = ({ jobDescription, title, children }: Props) => {
  const { companyName, companyWebsite } = jobDescription

  if (!companyName && !companyWebsite && !title) return null
  return (
    <div className="mb-12">
      {companyName && companyWebsite && (
        <div className="flex gap-3 mb-3">
          <OrganizationIcon domain={companyWebsite} size="sm" />
          <Text weight="font-medium" size="text-base">
            {companyName}
          </Text>
          <Link url={companyWebsite}>{companyWebsite}</Link>
        </div>
      )}
      <Text weight="font-medium" size="text-2xl">
        {title}
      </Text>
      {children}
    </div>
  )
}
