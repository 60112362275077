import { AnySchema, boolean, number, object } from 'yup'

import { PayRate } from 'modules/profiles/domain'
import { HOURLY_GROWING_RATE } from 'hooks/payRate/usePayRateOptions'
import { monthlyRateShape } from 'components/molecules/MonthlyRateField'
export { PayRate } from './PayRate'

export const HOURLY_STEP = HOURLY_GROWING_RATE

export const schema = object().shape({
  monthlyRate: monthlyRateShape,
  hourlyRate: object().shape({
    min: number(),
    max: number(),
  }),
  openToNegotiation: boolean(),
} as Record<keyof PayRate, AnySchema>)

export const EDIT_PAY_RATE_PATH = '/profile/pay-rate/edit'
