export enum SortType {
  Relevance = 'relevance',
  HighestCost = 'highest_cost',
  LowestCost = 'lowest_cost',
  ScreeningProgress = 'screening_progress',
  Favorites = 'favorites',
  MatchingFlags = 'matching_flags',
  NewerScreeningAssignmentDate = 'newer_screening_assignment_date',
  OlderScreeningAssignmentDate = 'older_screening_assignment_date',
}
