import { useContext } from 'react'

import { SearchContext } from 'candidates-search/contexts'
import { Candidate, SearchMetadata } from 'candidates-search/domain'
import { HighlightMetadata } from 'candidates-search/enums/search-metadata.enum'

interface Stack {
  id?: number
  label: string
}

const parseStacks = (stacks: Array<Stack>) =>
  stacks.map((stack) => {
    return stack.label
  })

const getHighlightedStacks = (
  highlights?: Record<HighlightMetadata, Array<string>>,
): Array<string> => {
  if (!highlights) return []

  return (highlights[HighlightMetadata.PrimayStackLabel] || []).concat(
    highlights[HighlightMetadata.SecondaryStackLabel] || [],
  )
}

export const useResultCardTags = (candidate: Candidate & SearchMetadata) => {
  const { filter } = useContext(SearchContext)

  const allStacks = [
    ...candidate.primaryStacks.sort((a, b) => b.experience - a.experience),
    ...candidate.secondaryStacks
      .sort((a, b) => (b.label > a.label ? -1 : 1))
      .map(({ id, label }) => ({
        id,
        label,
        experience: null,
      })),
  ].filter(
    ({ id }, indexFilter, stackArray) =>
      indexFilter === stackArray.findIndex((stack) => stack.id === id),
  )

  const filteredStacks = parseStacks(filter?.skills?.stacks || []).concat(
    getHighlightedStacks(candidate.highlights),
  )

  return allStacks
    .map((stack) => ({
      ...stack,
      highlight: filteredStacks.includes(stack.label),
    }))
    .sort((a, b) => {
      const bothHighlighted = a.highlight && b.highlight
      const noneHighlighted = !(a.highlight || b.highlight)

      if (bothHighlighted || noneHighlighted) return 0

      if (!a.highlight && b.highlight) return 1

      return -1
    })
}
