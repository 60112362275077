import { lazy, useEffect, useState } from 'react'
import { object } from 'yup'

import {
  Text,
  Paragraph,
  PrimaryButton,
  SecondaryButton,
  StepsWizard,
  StepsWizardInstance,
  NullStepsWizard,
} from 'shared/components/atoms'
import { Footer, Modal } from 'shared/components/molecules'
import { JobDescription, Questions } from 'screening/exports'
import { Deadline } from './components/Deadline'

import { useForm } from 'shared/hooks'
import { useCompensation } from 'hooks/payRate'

import { emptyJobDescription } from '../../consts'
import { Screening } from 'screening/domain'

const JobApplicationIllustration = lazy(() =>
  import('icons/ilustrations/job-application.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface Props {
  isOpen: boolean
  screening?: Screening
  onClose: () => void
}

export const JobOpportunityPreview = ({ screening, isOpen, onClose }: Props) => {
  const formControl = useForm({ schema: object(), defaultValues: {} })
  const [instance, setInstance] = useState<StepsWizardInstance>(NullStepsWizard)
  const [currentStep, setCurrentStep] = useState(1)

  const { compensationLabel, compensationTitle } = useCompensation({
    minimumPayRate: screening?.minimumPayRate,
    maximumPayRate: screening?.maximumPayRate,
    paymentModel: screening?.paymentModel,
  })

  const handleNext = () => {
    instance.goToStep(currentStep + 1)
    setCurrentStep(currentStep + 1)
  }

  const handleBack = () => {
    instance.goToStep(currentStep - 1)
    setCurrentStep(currentStep - 1)
  }

  useEffect(() => {
    setCurrentStep(1)
  }, [isOpen])

  return (
    <Modal
      open={isOpen}
      title="Job opportunity preview"
      containerClassName="!h-full"
      contentContainerClassName="flex flex-grow"
      content={
        <div className="flex-grow flex flex-col">
          <StepsWizard instance={setInstance} className="flex-grow" isLazyMount>
            <div className="flex flex-col gap-8">
              <JobDescription
                jobDescription={screening?.jobDescription || emptyJobDescription}
                title={screening?.jobDescription?.title || ''}
                headerChildren={<Deadline deadline={screening?.deadline} />}
                compensationLabel={compensationLabel}
                compensationTitle={compensationTitle}
                isPreview
              />
            </div>
            <div className="flex flex-col w-full flex-grow">
              <Text weight="font-medium" size="text-2xl">
                Screening questions
              </Text>
              {screening?.questions?.length ? (
                <Questions
                  questions={screening?.questions || []}
                  formControl={formControl}
                  isLoading={false}
                  isPreview
                />
              ) : (
                <div className="flex flex-col items-center m-auto">
                  <JobApplicationIllustration />
                  <Paragraph size="body-md" weight="font-normal">
                    No questions were defined yet.
                  </Paragraph>
                </div>
              )}
            </div>
          </StepsWizard>
        </div>
      }
      handleClose={onClose}
      footer={
        <Footer>
          <SecondaryButton
            onClick={handleBack}
            disabled={currentStep === 1}
            className="w-full sm:w-fit"
          >
            Back
          </SecondaryButton>
          <PrimaryButton
            onClick={handleNext}
            disabled={currentStep === 2}
            className="w-full sm:w-fit"
          >
            Next
          </PrimaryButton>
        </Footer>
      }
    />
  )
}
