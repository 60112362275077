export enum Product {
  EnglishVerification = 'EnglishVerification',
  EnglishEvaluation = 'EnglishEvaluation',
  MyProfile = 'MyProfile',
  Benefits = 'Benefits',
  JobListing = 'JobListing',
  InterviewRequesting = 'InterviewRequesting',
  JobOffers = 'JobOffers',
  TechnicalAssessment = 'TechnicalAssessment',
  Settings = 'Settings',
  LiveInterview = 'LiveInterview',
  Other = 'Other',
  ReferTalent = 'ReferTalent',
  ReferHandbook = 'ReferHandbook',
  JobBoard = 'JobBoard',
  LiveChat = 'LiveChat',
}
