import { CompanySize } from 'company/enums'
import { Preferences } from 'modules/profiles/profile-preferences'
import { useEffect, useState } from 'react'
import { CompanyEmployees } from './consts'

export const useGroupCompanySizes = (preferences?: Preferences) => {
  const [idealCompanies, setIdealCompanies] = useState<Array<{ id: number; label: string }>>([])
  const [openToCompanies, setOpenToCompanies] = useState<Array<{ id: number; label: string }>>([])
  const [notInterestedCompanies, setNotInterestedCompanies] = useState<Array<string>>([])

  useEffect(() => {
    if (!preferences) return

    const idealCompanies = [] as Array<{ id: number; label: string }>
    const openToCompanies = [] as Array<{ id: number; label: string }>
    const notInterestedCompanies = [] as Array<string>

    const { companySizes } = preferences
    Object.keys(companySizes).map((size, index) => {
      if (companySizes[size as CompanySize] === 'ideal')
        idealCompanies.push({ id: index, label: CompanyEmployees[size as CompanySize] })
      if (companySizes[size as CompanySize] === 'yes')
        openToCompanies.push({ id: index, label: CompanyEmployees[size as CompanySize] })
      if (companySizes[size as CompanySize] === 'no')
        notInterestedCompanies.push(CompanyEmployees[size as CompanySize])
    })

    setIdealCompanies(idealCompanies)
    setOpenToCompanies(openToCompanies)
    setNotInterestedCompanies(notInterestedCompanies)
  }, [preferences?.companySizes])

  return { idealCompanies, openToCompanies, notInterestedCompanies }
}
