import { RecordingStep, Steps } from './enums'

interface StepData {
  title: string
  subtitle?: Optional<string>
  dos: Array<string>
  dosDescription?: string
  boost: Array<string>
  donts: Array<string>
  minLengthInSeconds: number
  maxLengthInSeconds: number
}

export const STEPS = [
  Steps.HardwareCheckup,
  Steps.TechnicalChallenges,
  Steps.FutureInterviews,
  Steps.Submit,
]

export const STEP_DATA: Record<RecordingStep, StepData> = {
  [Steps.TechnicalChallenges]: {
    title: 'Tell us about your past technical challenges',
    subtitle: 'Follow the instructions to record a video as part of you English evaluation.',
    dos: [
      'Describe the situation and its context',
      'What were your tasks and responsibilities?',
      'What steps did you take to address them?',
      'What was the outcome?',
      'What lessons did you learn from it?',
    ],
    dosDescription: 'Tell us the most significant technical challenge you have ever faced',
    boost: [
      'Be specific and showcase your expertise',
      'Tell the whole story to show how good you were at resolving the problem',
      'Share results achieved and the business impact of your solution ',
    ],
    donts: [
      "Avoid reciting the script. It'll sound artificial",
      'Do not speak any language other than English',
      'Do not record this video vertically (like an Instagram story), in a room with poor lighting, or a noisy place',
    ],
    minLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_TECHNICAL_CHALLENGES_MIN_LENGTH ?? '60',
    ),
    maxLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_TECHNICAL_CHALLENGES_MAX_LENGTH ?? '180',
    ),
  },
  [Steps.FutureInterviews]: {
    title: 'Show us your English skills for future interviews',
    dos: [
      'Ask your potential interviewers three open-ended questions that you would do in your live interview with them',
      'Explain the reasoning behind each question',
    ],
    boost: [
      'Questions about culture, product, industry, technical or business challenges have higher engagement than others',
      'Ask the questions one by one and organize your thoughts and speaking',
    ],
    donts: [
      'Avoid asking questions about salary, time off, lunchtime, company size, insurance, equity, etc. You can know those things when you receive your first interview request.',
    ],
    minLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_FUTURE_REVIEWS_MIN_LENGTH ?? '60',
    ),
    maxLengthInSeconds: Number(
      process.env.REACT_APP_ENGLISH_CHECK_FUTURE_REVIEWS_MAX_LENGTH ?? '120',
    ),
  },
}
