import { Link } from 'react-router-dom'

import { Text } from 'shared/components/atoms'
import { IconBanner } from 'shared/components/molecules'

import { ReactComponent as WarningIcon } from 'icons/x-square.svg'

import { JobDescription, Preferences } from 'job-opportunity/domain'

interface MisalignmentBannerProps {
  jobDescription: JobDescription
  showMisalignments?: boolean
}

export const MisalignmentBanner = ({
  jobDescription,
  showMisalignments,
}: MisalignmentBannerProps) => {
  const misalignments = jobDescription.misalignments.filter(
    ({ preference }) => preference !== Preferences.EnglishLevelB1Applicant,
  )

  if (!showMisalignments || !misalignments.length) return null
  return (
    <IconBanner
      icon={<WarningIcon className="w-6 h-6 stroke-warning-medium shrink-0" />}
      bgColor="bg-warning-lighter"
      className="mt-4"
    >
      <div className="flex flex-col gap-2">
        <Text>
          Your{' '}
          <Link to="/profile" target="_blank">
            profile
          </Link>{' '}
          preferences are not totally compatible with this job:
        </Text>
        {misalignments.length === 1 ? (
          <Text className="-mt-2">{misalignments[0].label}</Text>
        ) : (
          <ol className="list-decimal ml-6">
            {misalignments.map((misalignment, index) => (
              <li key={index}>
                <Text>{misalignment.label}</Text>
              </li>
            ))}
          </ol>
        )}
        <Text className="pt-2">Keep this in mind if you decide to proceed.</Text>
      </div>
    </IconBanner>
  )
}
