import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { ReactNode, useState } from 'react'

import { TertiaryButton, Text } from 'shared/components/atoms'

import { useNavigate } from 'react-router-dom'

interface ErrorBoundaryProps {
  children: ReactNode
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const [imageClicks, setImageClicks] = useState(0)
  const renderError = imageClicks > 5

  const navigate = useNavigate()

  const handleImageClick = () => setImageClicks((previous) => previous + 1)

  const handleGoHome = (resetError: () => void) => () => {
    resetError()
    navigate('/')
    setImageClicks(0)
  }

  return (
    <SentryErrorBoundary
      fallback={({ resetError, componentStack, eventId }) => (
        <div className="flex flex-col items-center md:justify-center w-full h-screen px-2 text-center gap-2 overflow-y-auto">
          <button onClick={handleImageClick} onKeyPress={handleImageClick}>
            <img src="/images/hurt-flamingo.png" className="w-44 h-44" alt="" />
          </button>
          <Text size="text-2xl" weight="font-semibold">
            Something went wrong
          </Text>
          <Text>We apologize for any inconvenience. Our team is currently working on it.</Text>
          <Text>Event ID: {eventId}</Text>
          <TertiaryButton onClick={handleGoHome(resetError)}>Go home</TertiaryButton>
          {renderError && <pre className="w-full p-2">{componentStack}</pre>}
        </div>
      )}
    >
      {children}
    </SentryErrorBoundary>
  )
}
