import classNames from 'classnames'
import { Avatar } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

interface Props {
  name: string
  email?: string
  photoUrl: string | null
  description?: string
  notification?: string
  showNotification?: boolean
  className?: string
  photoSize?: 'sm' | 'md'
}

const photoFallback = '/icons/evaluation-photo-fallback.svg'
const striderPhotoFallback = '/icons/evaluation-photo-strider-fallback.png'

export const PersonInformation = ({
  name,
  photoUrl,
  email,
  notification,
  description,
  className,
  showNotification,
  photoSize = 'md',
}: Props) => {
  const photo =
    photoUrl ||
    (email?.includes('@onstrider.com') && striderPhotoFallback) ||
    (!name && photoFallback) ||
    undefined

  return (
    <div className={classNames('flex items-center gap-4', className)}>
      <Avatar text={name} size={photoSize} photo={photo}>
        {Boolean(notification && showNotification) && (
          <div className="absolute flex items-center justify-center w-4 h-4 bottom-0 right-0 border-2 border-neutral-day bg-neutral-lightest rounded-full">
            <Text size="text-2xs" weight="font-medium">
              {notification}
            </Text>
          </div>
        )}
      </Avatar>
      <div className="flex flex-col gap-1">
        <Text size="text-sm" weight="font-normal">
          {name}
        </Text>
        <Text size="text-xs" weight="font-normal" className="text-neutral-dark">
          {description}
        </Text>
      </div>
    </div>
  )
}
