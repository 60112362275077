import { useNavigate } from 'react-router-dom'

import { Text, Tooltip } from 'shared/components/atoms'
import { PersonInformation } from 'shared/components/molecules'
import { ReferralStatusBadge } from 'components/molecules/Badges'

import { ReactComponent as RightIcon } from 'icons/chevron-right.svg'

import { useFormatting } from 'shared/hooks'
import { Referral } from 'modules/referrals/domains'

interface ContentProps {
  referrals: Array<Referral>
}

export const Content = ({ referrals }: ContentProps) => {
  const { formatDate } = useFormatting()
  const navigate = useNavigate()

  const buildRoleLabel = (roles: Array<string>) => {
    if (roles.length) return `${roles[0]}${roles.length - 1 > 0 ? ` +${roles.length - 1}` : ''}`
  }

  const openCandidateProfile = (referralId: string) => navigate(`/referrals/${referralId}/profile`)

  return (
    <tbody>
      {referrals.map(
        ({
          id,
          profile: { fullName, photoUrl, interestRoles },
          profileEmail,
          status,
          statusDescription,
          createdAt,
        }) => (
          <tr
            key={id}
            className="h-[75px] cursor-pointer hover:bg-neutral-lightest"
            onClick={() => openCandidateProfile(id)}
          >
            <td>
              <PersonInformation name={fullName} photoUrl={photoUrl} description={profileEmail} />
            </td>
            <td>
              <Tooltip content={interestRoles.join('\n')}>
                <Text size="text-sm" weight="font-normal">
                  {buildRoleLabel(interestRoles)}
                </Text>
              </Tooltip>
            </td>
            <td>
              <Text size="text-sm" weight="font-normal">
                {formatDate({ date: new Date(createdAt) })}
              </Text>
            </td>
            <td>
              <div className="flex justify-between">
                <ReferralStatusBadge status={status} statusDescription={statusDescription} />
                <RightIcon className="stroke-neutral-dark h-6 w-6" />
              </div>
            </td>
          </tr>
        ),
      )}
    </tbody>
  )
}
