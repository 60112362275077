import { JobSeekerLandingPage } from './JobSeekerLandingPage'
import { RecruiterLandingPage } from './RecruiterLandingPage'

export const LandingPages = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const type = urlParams.get('t')

  if (type?.endsWith('recruiters')) return <RecruiterLandingPage />
  return <JobSeekerLandingPage />
}
