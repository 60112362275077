import { ConflictBanner } from './ConflictBanner'
import { WithinBudgetBanner } from './WithinBudgetBanner'
import { OverBudgetBanner } from './OverBudgetBanner'
import { MissingParamsWarning } from './MissingParamsWarning'
import { PaymentModel } from 'job-listing/enums'

interface BannersProps {
  isMissingParams: boolean
  withinBudget: boolean
  budgetDifference: number
  budget: number
  inConflict: boolean
  client: string
  paymentModel: PaymentModel
}

export const Banners = ({
  isMissingParams,
  budgetDifference,
  client,
  budget,
  inConflict,
  withinBudget,
  paymentModel,
}: BannersProps) => {
  if (isMissingParams) {
    return <MissingParamsWarning />
  }

  const conflictWarning = inConflict ? <ConflictBanner /> : null
  const budgetWarning = withinBudget ? <WithinBudgetBanner client={client} budget={budget} /> : null
  const overflowWarning =
    withinBudget === false ? (
      <OverBudgetBanner difference={budgetDifference} client={client} paymentModel={paymentModel} />
    ) : null

  return (
    <>
      {conflictWarning} {budgetWarning} {overflowWarning}
    </>
  )
}
