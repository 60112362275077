import { ApplicationModalSteps } from '../../enums'
import { StepsWizardInstance, Text } from 'shared/components/atoms'
import { ProgressBar } from 'shared/components/atoms'
import { StepsWizard } from 'shared/components/atoms'

interface Props {
  currentStep: ApplicationModalSteps
  tabs: Array<{ component: JSX.Element }>
  setCurrentStep: (step: ApplicationModalSteps) => void
  setInstance: (instance: StepsWizardInstance) => void
  showHeader: boolean
}

export const ModalContent = ({
  currentStep,
  tabs,
  setCurrentStep,
  setInstance,
  showHeader,
}: Props) => {
  const percentage = (100 * currentStep) / tabs.length

  const handleStepChange = ({ activeStep }: { activeStep: number }) => setCurrentStep(activeStep)

  return (
    <div>
      {showHeader && (
        <>
          <div className="flex justify-between items-center mb-2">
            <Text>
              Step {currentStep}/{tabs.length}
            </Text>
            <Text>{Math.round(percentage)}%</Text>
          </div>
          <div className="mb-6">
            <ProgressBar
              progress={percentage}
              color="bg-neutral-darkest"
              background="bg-neutral-lighter"
            />
          </div>
        </>
      )}
      <StepsWizard
        onStepChange={handleStepChange}
        instance={setInstance}
        isLazyMount
        className="no-scrollbar"
      >
        {tabs.map(({ component }) => component)}
      </StepsWizard>
    </div>
  )
}
