import { useMutation, useQueryClient } from 'react-query'

import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

import { SendInvitesDTO, SendInvitesValues } from 'company/dtos'
import { Teammate } from 'company/domain'

export const useSendInvites = (teammates: Array<Teammate> = [], emails: Array<string> = []) => {
  const client = useQueryClient()
  const { toastError, toastSuccess } = useToast()
  const { post } = useNetwork()
  const { captureException } = useMonitoring()

  const { mutateAsync: sendInvites, isLoading } = useMutation(
    (values: SendInvitesValues) => {
      const payload = new SendInvitesDTO(values).toJSON()
      return post('/onboarding/invites/employees', payload)
    },
    {
      retry: (failureCount, error: RequestError) =>
        error.response?.status !== 409 && failureCount < 2,
      onSuccess: () => {
        toastSuccess('Invites sent.')
        client.invalidateQueries('company-info')
      },
      onError: (error: RequestError) => {
        toastError(error.response?.data.message)
        captureException(error)
      },
    },
  )

  const uniqueValidation = (email: string | undefined) => {
    const normalizedEmail = String(email).toLowerCase()
    const allEmails = teammates.map(({ email }) => email).concat(emails)
    return !allEmails.some((email) => email.toLowerCase() === normalizedEmail)
  }

  return { uniqueValidation, sendInvites, isLoading }
}
