import { OrganizationIcon } from 'shared/components/molecules'

export const Wrapper = ({
  ariaLabel,
  children,
  domain,
}: {
  children: React.ReactNode
  domain: string | null
  ariaLabel?: string
}) => {
  return (
    <div aria-label={ariaLabel} className="flex items-start justify-center gap-8">
      <OrganizationIcon domain={domain} />
      <div className="flex-grow flex flex-col items-stretch justify-start gap-[10px] w-[calc(100%-80px)]">
        {children}
      </div>
    </div>
  )
}
