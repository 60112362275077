import { useEffect, useState } from 'react'
import qs from 'qs'

import { InfiniteScroll } from 'shared/components/atoms'
import { Content } from './components/Content'
import { EmptyState } from './components/EmptyState'
import { Header } from './components/Header'
import { TalentFunnel } from './components/TalentFunnel'

import { useReferrals } from './hooks/useReferrals'
import { useReferralStats } from './hooks/useReferralStats'
import { useForm } from 'shared/hooks'
import { schema, defaultValues } from './schema'
import { statusMapping } from './options'
import { Filter } from './components/Filter'
import { useDebounce } from 'shared/hooks'

export const ReferTalent = () => {
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState('')

  const { debouncedValue } = useDebounce(filter, 500)
  const { referrals, referralCount, isLoading } = useReferrals(page, debouncedValue)
  const { report, generateReport, isLoading: isLoadingReport } = useReferralStats()

  const { register, watch, setValue } = useForm({
    schema,
    defaultValues,
  })

  useEffect(() => {
    generateReport()
    const subscription = watch((value) => {
      const criteria = {
        statuses: Object.entries(value.status)
          .filter(([_, value]) => value)
          .map(([key, _]) => statusMapping[key]),
        roles: value.roles.map((role: { id: number; label: string }) => role.id),
        query: value.search,
      }

      setFilter(qs.stringify(criteria))
    })

    return () => subscription.unsubscribe()
  }, [])

  const handleFetchMore = async () => {
    setPage(page + 1)
  }

  return (
    <div>
      <TalentFunnel counts={report.counts} />
      <Filter register={register} setValue={setValue} watch={watch} />
      {referrals.length ? (
        <InfiniteScroll
          dataLength={referrals.length}
          onFetchMore={handleFetchMore}
          hasMore={referralCount > referrals.length}
          isFirstPage={page === 1}
        >
          <table className="w-full text-left">
            <Header />
            <Content referrals={referrals} />
          </table>
        </InfiniteScroll>
      ) : (
        <EmptyState isLoading={isLoading || isLoadingReport} />
      )}
    </div>
  )
}
