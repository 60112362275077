import { Text } from 'shared/components/atoms'
import { AccountClaim, useAuth } from 'shared/hooks'

interface TalentReviewBadgeProps {
  isTalentReview: boolean
}

export const TalentReviewBadge = ({ isTalentReview }: TalentReviewBadgeProps) => {
  const {
    user: { claims },
  } = useAuth()

  if (!isTalentReview || !claims.includes(AccountClaim.ManageJobListingSettings)) return null

  return (
    <Text
      weight="font-medium"
      size="text-xs"
      className="flex w-fit justify-center items-center px-2 py-1 rounded bg-info-lighter text-info-medium"
    >
      Talent Review
    </Text>
  )
}
