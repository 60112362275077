import { Roles } from 'account/enums'
import { AsideContent, TrustpilotScore } from 'authentication/components'
import { GetHelp } from 'onboarding/exports'

import { SidePanelContainer } from './SidePanelContainer'
import { Testimonials } from './SidePanelContainer/Testimonials'
import { Developers } from './SidePanelContainer/Developers'

interface SidePanelProps {
  accountRole?: Roles
}

const SidePanel = ({ accountRole }: SidePanelProps) => {
  const content: Record<Roles, Nullable<JSX.Element>> = {
    [Roles.EmployerOwner]: <GetHelp />,
    [Roles.Recruiter]: <AsideContent />,
    [Roles.JobSeeker]: (
      <SidePanelContainer
        mode={'darkMode'}
        title={'Join Strider for high-paying, remote jobs with US companies'}
      >
        <>
          <div className="relative w-full overflow-hidden h-[346px]">
            <div className="absolute flex h-[346px]">
              <Testimonials />
              <Testimonials />
            </div>
          </div>
          <TrustpilotScore theme={accountRole === Roles.JobSeeker ? 'dark' : 'light'} />
        </>
      </SidePanelContainer>
    ),
    [Roles.EmployerViewer]: null,
  }

  if (!accountRole || !content[accountRole]) {
    return (
      <SidePanelContainer
        mode="lightMode"
        title="Strider connects exceptional developers with top companies"
      >
        <div className="relative w-full overflow-hidden h-[346px]">
          <div className="absolute flex h-[346px]">
            <Developers />
            <Developers />
          </div>
        </div>
      </SidePanelContainer>
    )
  }

  return content[accountRole]
}

export { SidePanel }
