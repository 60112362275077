import { AssessmentTag } from 'modules/profiles/domain'
import { Avatar } from 'shared/components/atoms'
import { useGravatar } from 'shared/hooks'

interface Props {
  tags: Array<AssessmentTag>
}

export const LastUsers = ({ tags }: Props) => {
  const { getAvatarLink } = useGravatar()

  if (!tags.length) return null

  const emails = tags
    .map((tag) => tag.email)
    .reduce((acc, email) => {
      if (!acc.includes(email)) {
        acc.push(email)
      }
      return acc
    }, [] as Array<string>)

  const avatars = emails.slice(-3).reverse()

  return (
    <div className="ml-3 mt-3 flex items-center">
      {avatars.map((avatar) => (
        <Avatar
          text={avatar}
          photo={getAvatarLink(avatar)}
          size="sm"
          className="-ml-3"
          key={avatar}
        />
      ))}
    </div>
  )
}
