import { Paragraph } from 'shared/components/atoms'
import { AssessmentTagsSection, ProficiencyTagsSection } from 'profile/components'

import { BioContent, MainSkills, RolesOfInterest } from './components'

import { SelfIntroductionAudio } from './SelfIntroductionAudio'
import { ProfileSectionWrapper } from '../ProfileSectionWrapper'
import { ProfileSectionTitle } from '../ProfileSectionTitle'

import { ProfileOverview, StackExperience } from 'pages/JobSeekerProfile/types'

interface ProfileOverviewSectionProps {
  readOnly: boolean
  profile: ProfileOverview
  onEdit: () => void
  onBioEdit: () => void
  bioPlaceholder?: JSX.Element
  titleChildren?: JSX.Element
  showAssessmentTags?: boolean
  isUnavailable?: boolean
  highlightedSkills?: Array<StackExperience & { highlighted?: boolean }>
}

export const ProfileOverviewSection = ({
  profile,
  readOnly,
  onEdit,
  onBioEdit,
  bioPlaceholder,
  titleChildren,
  showAssessmentTags,
  isUnavailable,
  highlightedSkills,
}: ProfileOverviewSectionProps) => {
  return (
    <ProfileSectionWrapper className="flex flex-col gap-6" aria-labelledby="overview-title">
      <div className="flex flex-col gap-6">
        <ProfileSectionTitle readOnly={readOnly} title="Overview" id="overview-title">
          {titleChildren}
        </ProfileSectionTitle>
        <div className="flex flex-col gap-6 md:gap-2">
          {isUnavailable && (
            <div className="mb-8 bg-warning-lighter px-3 py-1 rounded w-fit">
              <Paragraph size="body-xs" className="text-warning-darker">
                Not available
              </Paragraph>
            </div>
          )}

          <RolesOfInterest profile={profile} readOnly={readOnly} onEdit={onEdit} />

          <MainSkills skills={profile.profileStacks} highlightedSkills={highlightedSkills} />

          <ProficiencyTagsSection profileId={profile.id} proficiencies={profile.proficiencies} />

          <SelfIntroductionAudio profile={profile} />
        </div>
      </div>

      {showAssessmentTags && (
        <AssessmentTagsSection
          profileId={profile.id}
          tags={profile.assessmentTags}
          lastUpdatedAt={profile.tagsUpdatedAt}
        />
      )}
      <BioContent
        content={profile.bioText}
        onEdit={onBioEdit}
        readOnly={readOnly}
        placeholder={bioPlaceholder}
      />
    </ProfileSectionWrapper>
  )
}
