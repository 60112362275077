import classNames from 'classnames'
import { lazy, Suspense } from 'react'

import { Text } from 'shared/components/atoms'

import { Step as StepType } from '../../domain'

const CheckIcon = lazy(() =>
  import('icons/check--thin.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const FlagIcon = lazy(() =>
  import('icons/dashboard/flag-04.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface StepProps {
  step: StepType
  index: number
}

export const Step = ({ step: { isDone, isLast, isCurrent, title }, index }: StepProps) => {
  const buildStateLabel = () => {
    if (isDone) return 'Done'
    if (isCurrent) return 'Now'
    return `Step ${index + 1}`
  }
  const stateLabel = buildStateLabel()

  return (
    <li className={classNames('flex flex-col gap-4', { 'flex-grow': !isLast })}>
      <div className="flex items-center gap-3">
        {isLast ? (
          <Suspense fallback={null}>
            <FlagIcon
              className={classNames({
                'fill-brand-medium': isCurrent,
                'fill-neutral-light': !isCurrent,
              })}
            />
          </Suspense>
        ) : (
          <>
            <div
              className={classNames(
                'flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center',
                {
                  'border-[6px] border-neutral-darkest': isCurrent,
                  'bg-neutral-darkest': isDone,
                  'border-2 border-neutral-light': !isCurrent && !isDone,
                },
              )}
            >
              {isDone && (
                <Suspense fallback={null}>
                  <CheckIcon className="w-4 h-4 stroke-neutral-day flex-shrink-0" />
                </Suspense>
              )}
            </div>
            <div
              className={classNames('w-full h-[2px] bg-neutral-darkest rounded-full', {
                'bg-neutral-darkest': isDone,
                'bg-neutral-light': !isDone,
              })}
            />
          </>
        )}
      </div>
      <div className="hidden lg:flex flex-col gap-1">
        <Text size="text-xs" weight="font-medium" className="text-neutral-medium">
          {stateLabel}
        </Text>
        <Text
          size="text-base"
          weight="font-medium"
          className={classNames('text-neutral-darkest !whitespace-nowrap', {
            'text-neutral-darkest': isDone || isCurrent,
            'text-neutral-medium': !isDone && !isCurrent,
          })}
        >
          {title}
        </Text>
      </div>
    </li>
  )
}
