import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

import { Steps } from './components/Steps'
import { Action } from './components/Action'

import { Card } from '../Card'

import { useDismissed } from './hooks'

interface StepperProps {
  profileCompleted: boolean
  englishCheckCompleted: boolean
}

export const Stepper = ({ profileCompleted, englishCheckCompleted }: StepperProps) => {
  const { isDismissed, setDismissed } = useDismissed()

  const buildCopies = () => {
    if (!profileCompleted)
      return {
        title: 'Complete your profile',
        description:
          'We use the information of your profile such as skills, experiences and preferences to match you with job opportunities. Make sure to create a great profile and standout.',
      }
    if (!englishCheckCompleted)
      return {
        title: 'Showcase your communication skills',
        description:
          "We'll need you to record 3 short videos about yourself and your professional experience in English. This will help us gauge your language skills and introduce you to companies you'll communicate well with.",
      }
    return {
      title: 'All good! You are active on our talent-pool',
      description:
        'Now you just sit and relax! We will contact you once international opportunities align with your profile. In the meantime, you can explore the benefits and perks we have prepared for you.',
    }
  }

  const copies = buildCopies()

  if (isDismissed) return null
  return (
    <Card className="col-span-12 flex flex-col-reverse lg:flex-col gap-4 lg:gap-10" role="banner">
      <div className="flex flex-col lg:flex-row lg:items-start justify-between gap-6">
        <div className="flex flex-col gap-6 lg:gap-4 max-w-[640px]">
          <Text size="text-xl" weight="font-medium" className="text-neutral-darkest">
            {copies.title}
          </Text>
          <Paragraph size="body-sm" weight="font-normal" className="text-neutral-darkest">
            {copies.description}
          </Paragraph>
        </div>
        <Action
          profileCompleted={profileCompleted}
          englishCheckCompleted={englishCheckCompleted}
          onDismiss={setDismissed}
        />
      </div>
      <Steps profileCompleted={profileCompleted} englishCheckCompleted={englishCheckCompleted} />
    </Card>
  )
}
