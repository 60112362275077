import { Type } from 'class-transformer'

import { DegreeType } from 'pages/JobSeekerProfile/types'

import { EducationType, ProfileFulfillmentStatus, STAStatus } from 'candidates-search/enums'

import { Assessment, EnglishVetting } from 'modules/candidates/domain'
import { ProfileStatus, InstitutionTier } from 'modules/candidates/enums'

import { AssessmentTag, ProficiencyTag } from 'modules/profiles/domain'

import { ExperienceRange } from 'modules/candidates/experience-range/experience-range'
import { ScreeningAssignmentProgress } from 'modules/screening-assignment/enums'
import { ScreeningRejectionReason } from 'modules/screening-assignment/enums'
import { MatchingApplicationSource, MatchingFlagType } from 'matching/enums'

interface PrimaryStacks {
  id: number
  label: string
  experience: number
}

interface SecondaryStacks {
  id: number
  label: string
}

interface WorkExperience {
  title: string
  description: string
  organizationName: string
  organizationWebsite: string
}

export interface EducationRecord {
  degreeType: DegreeType
  fieldOfStudy: string
  institutionCountry: string
  institutionName: string
  institutionTier: InstitutionTier
  institutionWebsite: string
}

interface InterestRoles {
  id: number
  label: string
}

interface PayRateRange {
  min: number
  max: number
}

export interface Matching {
  applicationSource: MatchingApplicationSource | null
  flags: Array<MatchingFlagType>
  priority: number
  jobListingId: string
  favorite: number
  compensation: number
  fee: number
}

export interface JobOpportunity {
  jobListingId: string
  firstViewedAt: string | null
  startedFillingAt: string | null
  submittedAt: string | null
  approvedAt: string | null
  declinedAt: string | null
  rejectedAt: string | null
  updatedAt: string | null
  createdAt: string | null
  isAnswered: boolean
  expiredAt: string | null
  progress: ScreeningAssignmentProgress
  rejectionReason: Nullable<ScreeningRejectionReason>
}

export class Candidate {
  id: string
  firstName: string
  lastName: string
  fullName: string
  location: {
    label: string
    country: string
  }
  photoUrl: string
  interestRoles: Array<InterestRoles>
  primaryStacks: Array<PrimaryStacks>
  secondaryStacks: Array<SecondaryStacks>
  yearlyRate: PayRateRange
  monthlyRate: PayRateRange
  hourlyRate: PayRateRange
  openToNegotiation: boolean
  availabilityStatus: ProfileStatus
  availableFrom: Date | null
  educationType: EducationType
  filledProfile: number
  fulfillmentStatus: ProfileFulfillmentStatus
  workExperienceTime: {
    relativeStartYear: string | null
    totalYears: number | null
  }
  workExperiences: Array<WorkExperience>

  @Type(() => EnglishVetting)
  englishLevel: EnglishVetting | null

  staStatus: STAStatus
  educationLevel: string | null
  noticePeriodDays: number | null

  @Type(() => Assessment)
  assessments: Array<Assessment>

  educations: Array<EducationRecord>
  externalId: string | null
  closeUrl: string | null
  intercomUrl: string | null
  whatsAppNumber: Optional<string>
  closePhoneNumbers: Array<string>
  matchings: Array<Matching>
  jobOpportunities: Array<JobOpportunity>

  assessmentTags: Array<AssessmentTag>
  proficiencies: Array<ProficiencyTag>

  mockInterviewDoneAt: Nullable<string>

  getMatchingByJobListingId(jobListingId: string): Matching | undefined {
    if (!jobListingId || !this.matchings) return
    return this.matchings.find((matching) => matching.jobListingId === jobListingId)
  }

  getFlagsByJobListingId(jobListingId: string): Array<MatchingFlagType> {
    if (!jobListingId) return []
    const matching = this.getMatchingByJobListingId(jobListingId)
    return matching?.flags || []
  }

  get experienceTimeLabel() {
    const { relativeStartYear, totalYears } = this.workExperienceTime

    if (!relativeStartYear && !totalYears) return 'No work experiences'

    if (totalYears) return `${totalYears} year${totalYears > 1 ? 's' : ''} of experience`

    const { years, months } = new ExperienceRange(new Date(relativeStartYear!), new Date())

    if (years < 1) return 'less than 1 year of experience'
    if (years >= 10) return '10+ years of experience'
    if (months < 3) return `${years} year${years > 1 ? 's' : ''} of experience`
    if (months < 8) return `${years}.5 years of experience`
    return `${years + 1} years of experience`
  }

  get isPaused() {
    return [ProfileStatus.Paused, ProfileStatus.Unavailable].includes(this.availabilityStatus)
  }

  get mockInterviewDone() {
    return Boolean(this.mockInterviewDoneAt)
  }
}
