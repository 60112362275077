import { Tooltip } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

import { ReactComponent as ChartIcon } from 'icons/briefcase-02-outlined.svg'

interface OpeningsLabelProps {
  value: number
  color: 'neutral-dark' | 'neutral-darkest'
}

export const OpeningsLabel = ({ value, color }: OpeningsLabelProps) => {
  if (!value) return null

  return (
    <Tooltip content="Number of openings">
      <div className="flex items-center gap-2 shrink-0">
        <ChartIcon className={`w-4 h-4 stroke-${color}`} />
        <Text size="text-sm" weight="font-normal" className={`text-${color}`}>
          {value} opening{Boolean(value > 1) && 's'}
        </Text>
      </div>
    </Tooltip>
  )
}
