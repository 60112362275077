const MAX_FILE_LENGTH_MBS = 20
const ALLOWED_FILE_EXTENSION = /(\.pdf|\.docx|\.doc)$/i

export class AgreementUpload {
  public readonly file: Blob | null = null

  constructor(private input: EventTarget & HTMLInputElement) {
    const file = input.files && input.files[0]

    if (!file) {
      return
    }

    const mbSize = file.size / 1024 / 1024
    if (mbSize > MAX_FILE_LENGTH_MBS) {
      throw new Error('Max allowed file size: 20 MB')
    }

    if (!ALLOWED_FILE_EXTENSION.test(input.value)) {
      throw new Error('Invalid file extension, please upload a PDF, DOC, or DOCX file.')
    }

    this.file = input.files && input.files[0]
  }

  get empty() {
    return !this.file
  }

  get fileName() {
    return this.input.value.split(/(\\|\/)/g).pop() ?? 'file.pdf'
  }
}
