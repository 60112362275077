import { PreviewLabel } from 'shared/components/atoms'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { AssessmentLink } from './AssessmentLink'

interface AssessmentsCollectionProps {
  profile: ProfileOverview
}

export const AssessmentsCollection = ({ profile }: AssessmentsCollectionProps) => {
  if (profile.assessments.length === 0) {
    return null
  }

  return (
    <PreviewLabel title="Technical assessment" disabled className="lg:mb-[50vh]">
      <ul className="flex flex-col gap-4 mt-2 mb-8 max-w-[194px]">
        {profile.assessments.map((assessment, index) => (
          <AssessmentLink assessment={assessment} key={index} />
        ))}
      </ul>
    </PreviewLabel>
  )
}
