import { useMutation, useQueryClient } from 'react-query'
import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { Screening } from 'screening/domain'
import { UpsertScreeningDTO } from 'screening/dtos'
import { useMonitoring } from 'shared/hooks'

export const useUpdateScreening = (listingId: string, screeningId: string) => {
  const client = useQueryClient()
  const { put } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: updateScreening, isLoading } = useMutation(
    async ({ screening }: { screening: Screening; dismissSuccessMessage?: boolean }) => {
      const payload = new UpsertScreeningDTO(screening)
      return put(`listings/${listingId}/screenings/${screeningId}`, payload)
    },
    {
      retry: 3,
      onSuccess: () => {
        client.invalidateQueries(['screening', screeningId])
      },
      onError: (error: RequestError) => {
        toastError(`Failed to update the screening: ${error.response?.data.message}`)
        captureException(error)
      },
    },
  )

  return { updateScreening, isLoading }
}
