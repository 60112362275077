import classNames from 'classnames'
import { Paragraph } from 'shared/components/atoms'

import { ReactComponent as WarningIcon } from 'icons/advanced-search/warning.svg'

interface RateLowerThanRequestedBannerProps {
  className?: string
}

export const RateLowerThanRequestedBanner = ({ className }: RateLowerThanRequestedBannerProps) => {
  return (
    <div className={classNames('flex gap-2', className)}>
      <WarningIcon className="w-4 h-4 my-1 flex-shrink-0" />
      <Paragraph size="body-sm" weight="font-normal" className="text-warning-darker">
        The amount you entered is lower than expected by the candidate. Please consider aligning
        with the candidate before proceeding.
      </Paragraph>
    </div>
  )
}
