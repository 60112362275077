import diff from 'fast-diff'
import { Size, Text } from '../Text'

interface TextDiffViewerProps {
  oldValue: string
  newValue: string
  size?: Size
}

const Content = ({ operation, value }: { operation: number; value: string }) => {
  if (operation === diff.DELETE) {
    return (
      <span className="line-through text-warning-dark bg-warning-darker bg-opacity-5">{value}</span>
    )
  }
  if (operation === diff.INSERT) {
    return <span className="text-info-darker bg-info-dark bg-opacity-10">{value}</span>
  }
  return <>{value}</>
}

export const TextDiffViewer = ({ oldValue, newValue, size = 'text-sm' }: TextDiffViewerProps) => {
  const diffResult = diff(oldValue, newValue)

  return (
    <Text size={size} weight="font-normal" className="text-neutral-darkest">
      {diffResult.map((diffItem, index) => (
        <Content key={index} operation={diffItem[0]} value={diffItem[1]} />
      ))}
    </Text>
  )
}
