import { HistogramData } from './histogram-data'

export const useHistogram = (declaredYearsExperience: number) => {
  if (declaredYearsExperience >= 7) {
    return {
      monthlyHistogram: HistogramData.Upper.Monthly,
      hourlyHistogram: HistogramData.Upper.Hourly,
    }
  }

  if (declaredYearsExperience >= 4) {
    return {
      monthlyHistogram: HistogramData.Mid.Monthly,
      hourlyHistogram: HistogramData.Mid.Hourly,
    }
  }

  return {
    monthlyHistogram: HistogramData.Entry.Monthly,
    hourlyHistogram: HistogramData.Entry.Hourly,
  }
}
