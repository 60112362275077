import classNames from 'classnames'
import * as ProgressPrimitive from '@radix-ui/react-progress'

interface ProgressBarProps {
  progress: number
  color:
    | 'bg-neutral-darker'
    | 'bg-success-medium'
    | 'bg-warning-light'
    | 'bg-danger-light'
    | 'bg-neutral-darkest'
  background?: 'bg-neutral-day' | 'bg-neutral-lighter' | 'bg-neutral-darkest'
}

export const ProgressBar = ({
  progress,
  color = 'bg-neutral-darker',
  background = 'bg-neutral-day',
}: ProgressBarProps) => {
  return (
    <ProgressPrimitive.Root
      className={classNames(
        'relative overflow-hidden rounded-full flex-grow h-1 w-full',
        background,
      )}
    >
      <ProgressPrimitive.Indicator
        className={classNames('w-full h-full duration-500', color)}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </ProgressPrimitive.Root>
  )
}
