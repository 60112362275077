import classNames from 'classnames'

import { Paragraph, TertiaryButton, Text } from 'shared/components/atoms'

import { ReactComponent as CloseIcon } from 'icons/close.svg'
import { ReactComponent as AddIcon } from 'icons/add.svg'

import { AssessmentTag } from 'modules/profiles/domain'
import { AssessmentTagType } from 'modules/profiles/enums'
import { useSortTags } from './useSortTags'
import { cn } from 'modules/shared'

interface Props {
  tags: Array<AssessmentTag>
  editOnHover?: boolean
  hideEmptyState?: boolean
  containerClassName?: string
  onRemove: (id: string) => void
  onAdd?: () => void
  readOnly?: boolean
}

interface TagProps {
  tag: AssessmentTag
  editOnHover?: boolean
  onRemove: (id: string) => void
  readOnly?: boolean
}

const Tag = ({ tag, onRemove, editOnHover, readOnly }: TagProps) => {
  return (
    <div
      className={classNames(
        'flex items-center gap-1 py-2 px-3 rounded-full group-hover:bg-opacity-50 transition-all',
        {
          'bg-info-lighter': tag.type === AssessmentTagType.Quality,
          'bg-warning-lighter': tag.type === AssessmentTagType.Warning,
          'bg-danger-lighter': tag.type === AssessmentTagType.RedFlag,
        },
      )}
    >
      <Text
        size="text-xs"
        className={classNames({
          'text-info-dark': tag.type === AssessmentTagType.Quality,
          'text-warning-dark': tag.type === AssessmentTagType.Warning,
          'text-danger-dark': tag.type === AssessmentTagType.RedFlag,
        })}
      >
        {tag.name}
      </Text>
      {!readOnly && (
        <CloseIcon
          className={classNames('stroke-neutral-medium hover:cursor-pointer transition-all', {
            'w-0 h-0 group-hover:w-4 group-hover:h-4': editOnHover,
            'w-4 h-4': !editOnHover,
          })}
          onClick={() => onRemove(tag.id)}
        />
      )}
    </div>
  )
}

export const Tags = ({
  tags,
  editOnHover,
  readOnly,
  hideEmptyState,
  containerClassName,
  onRemove,
  onAdd,
}: Props) => {
  const sortedTags = useSortTags(tags)

  const AddAssessmentButton = () => {
    if (!editOnHover || readOnly) return null
    return (
      <TertiaryButton
        size="sm"
        className="opacity-0 group-hover:opacity-100 transition-all"
        icon={<AddIcon />}
        isIconBefore
        onClick={onAdd}
      >
        Add assessment
      </TertiaryButton>
    )
  }

  if (!tags.length && !hideEmptyState) {
    return (
      <div className="flex gap-4 items-center">
        <Paragraph size="body-sm" className="text-neutral-medium">
          No assessments yet
        </Paragraph>
        <AddAssessmentButton />
      </div>
    )
  }

  return (
    <div className={cn('flex gap-4 flex-wrap', containerClassName)}>
      {sortedTags.map((tag) => (
        <Tag
          key={tag.id}
          tag={tag}
          onRemove={onRemove}
          editOnHover={editOnHover}
          readOnly={readOnly}
        />
      ))}
      <AddAssessmentButton />
    </div>
  )
}
