import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { lazy } from 'react'

import { BenefitsClick, useTracking } from 'tracking'
import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { SecondaryButton } from 'shared/components/atoms'

import { Card } from '../Card'

const BenefitsBannerIllustration = lazy(() =>
  import('icons/benefits/benefits-banner.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

export const BenefitsCard = () => {
  const { trackEvent } = useTracking()
  const navigate = useNavigate()

  const handleClick = () => {
    trackEvent(new BenefitsClick())
    navigate('/benefits')
  }

  return (
    <Card className="overflow-hidden relative" borderOnMobile>
      <div className="flex flex-col w-full lg:w-[316px]">
        <Text size="text-xs" weight="font-medium" className="text-neutral-medium">
          Benefits
        </Text>
        <Text size="text-xl" weight="font-medium" className="text-neutral-darkest">
          Remote work benefits
        </Text>
        <Paragraph size="body-sm" weight="font-normal" className="text-neutral-darkest mt-2">
          See all the discounts you get by being a Strider member
        </Paragraph>
      </div>
      <SecondaryButton size="md" onClick={handleClick} className="mt-6">
        Explore Benefits
      </SecondaryButton>
      <div
        className={classNames(
          'min-w-[400px] h-[400px]',
          'bg-neutral-light opacity-[0.16] rounded-full',
          'hidden lg:block absolute -top-[77px] left-[356px]',
        )}
      />
      <BenefitsBannerIllustration
        className="
          w-[122px] h-[122px] 
          absolute -bottom-[25px] -right-[7px]
          md:w-[170px] md:h-[170px] lg:bottom-auto lg:right-auto lg:top-[37px] lg:left-[356px]
        "
      />
    </Card>
  )
}
