import { useState } from 'react'

import { Text, SecondaryButton, TertiaryButton } from 'shared/components/atoms'
import { InviteTeammateModal } from './components/InviteTeammateModal'
import { EditPermissionModal } from './components/EditPermissionModal'

import { useAuth } from 'shared/hooks'

import { Roles } from 'account/enums'
import { Teammate } from 'company/domain'

import { ReactComponent as AddIcon } from 'icons/add.svg'
import { ReactComponent as EditIcon } from 'icons/edit.svg'

interface TeammatesProps {
  teammates: Array<Teammate>
  setTeammates: (newTeammates: Array<Teammate>) => void
}

export const Teammates = ({ teammates, setTeammates }: TeammatesProps) => {
  const {
    user: { email },
  } = useAuth()
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const [teammateToEdit, setTeammateToEdit] = useState<number | undefined>()

  const handleInviteModalOpen = () => setIsInviteModalOpen(true)
  const handleInviteModalClose = (newTeammates: Array<Teammate>) => {
    setIsInviteModalOpen(false)
    setTeammates([...newTeammates])
  }

  const handleEditTeammateOpen = (teammateIndex: number) => setTeammateToEdit(teammateIndex)
  const handleEditTeammateClose = (newTeammates: Array<Teammate>) => {
    setTeammateToEdit(undefined)
    setTeammates([...newTeammates])
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-2 items-center justify-between">
          <Text size="text-base" weight="font-medium" className="text-neutral-darkest">
            Who should have access to developer profiles?
          </Text>
          <SecondaryButton icon={<AddIcon />} isIconBefore onClick={handleInviteModalOpen}>
            <span className="hidden sm:block">Invite members</span>
          </SecondaryButton>
        </div>
        <ul>
          {teammates.map((teammate, index) => (
            <li
              key={teammate.email}
              className="flex flex-row items-center justify-between gap-4 py-4 border-b border-neutral-lighter overflow-hidden"
            >
              <Text
                size="text-base"
                weight="font-normal"
                className="text-neutral-darkest overflow-hidden text-ellipsis flex-grow"
              >
                {teammate.email}
              </Text>
              <div className="h-8 flex-shrink-0 flex items-center justify-center px-3 bg-neutral-lightest rounded-full">
                <Text size="text-sm" weight="font-normal" className="text-neutral-darkest">
                  {teammate.role === Roles.EmployerOwner ? 'Admin' : 'View only'}
                </Text>
              </div>
              {teammate.email !== email ? (
                <TertiaryButton
                  size="sm"
                  icon={<EditIcon />}
                  onClick={() => handleEditTeammateOpen(index)}
                />
              ) : (
                <div className="w-10" />
              )}
            </li>
          ))}
        </ul>
      </div>
      <InviteTeammateModal
        isOpen={isInviteModalOpen}
        onClose={handleInviteModalClose}
        teammates={teammates}
      />
      <EditPermissionModal
        isOpen={Boolean(teammateToEdit)}
        onClose={handleEditTeammateClose}
        teammateIndex={teammateToEdit}
        teammates={teammates}
      />
    </>
  )
}
