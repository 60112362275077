import { useContext, useState } from 'react'

import { TertiaryButton } from 'shared/components/atoms'
import { ActionProps } from '.'
import { ProfileMatcher } from 'candidates-search/components'

import { ReactComponent as ShareIcon } from 'icons/advanced-search/share.svg'

import { ProfileStatus } from 'modules/candidates/enums'
import { SearchContext } from 'candidates-search/contexts'

export const Share = ({ profile, onMatchClick }: ActionProps & { onMatchClick?: () => void }) => {
  const { search } = useContext(SearchContext)
  const [isMatcherOpen, setIsMatcherOpen] = useState(false)

  const handleCreateMatching = () => {
    if (onMatchClick) return onMatchClick()
    setIsMatcherOpen(true)
  }
  const handleMatcherBack = () => setIsMatcherOpen(false)

  if (!search?.isListingLinked || profile.availabilityStatus === ProfileStatus.Unavailable) {
    return null
  }

  return (
    <>
      <TertiaryButton
        onClick={handleCreateMatching}
        icon={<ShareIcon className="!w-6 !h-6" />}
        isIconBefore
      >
        Share with {search?.listing?.company.name}
      </TertiaryButton>
      {isMatcherOpen && <ProfileMatcher candidate={profile} onBack={handleMatcherBack} />}
    </>
  )
}
