import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Text } from 'shared/components/atoms'

import { JobOpportunities } from '../JobOpportunities'
import { JobOpportunityModals } from 'job-opportunity/exports'

import { useBrowserURL } from 'shared/hooks'
import { useGreeting } from './hooks'

import { JobOpportunity } from 'job-opportunity/domain'
import { VettingPackageResponse } from 'vetting/domain'
import { DeactivatedAccountBanner } from 'components/molecules/DeactivatedAccountBanner'
import { cn } from 'modules/shared'
import { Preferences } from 'modules/profiles/profile-preferences'
import { WelcomeBackModal } from 'components/molecules/WelcomeBackModal'

interface HeaderProps {
  firstName: string
  jobOpportunities: Array<JobOpportunity>
  vetting: Nullable<VettingPackageResponse>
  profileId: string
  preferences: Preferences
  isDeactivated: boolean
}

export const Header = ({
  firstName,
  jobOpportunities,
  vetting,
  profileId,
  preferences,
  isDeactivated,
}: HeaderProps) => {
  const [jobOpportunityId, setJobOpportunityId] = useState<string>()
  const [isWelcomeBackModalOpen, setIsWelcomeBackModalOpen] = useState(false)

  const openWelcomeBackModal = () => setIsWelcomeBackModalOpen(true)
  const closeWelcomeBackModal = () => setIsWelcomeBackModalOpen(false)

  const { replaceCurrentURL } = useBrowserURL()
  const params = useParams()
  const greeting = useGreeting()

  const handleJobOpportunityItemClick = (id: string) => {
    setJobOpportunityId(id)
  }

  const handleModalClose = () => {
    setJobOpportunityId(undefined)
    replaceCurrentURL('/')
  }

  useEffect(() => {
    setJobOpportunityId(params.jobOpportunityId)
  }, [params.jobOpportunityId])

  return (
    <div
      className={cn('relative pt-6 bg-neutral-darker w-screen overflow-hidden', {
        'h-[230px]': !isDeactivated,
      })}
    >
      <img
        src="images/dashboard/world-map.png"
        className="absolute sm:w-full min-w-[1430px] left-[-184px] md:left-0"
        alt=""
        role="presentation"
        loading="lazy"
      />
      <div className="max-w-[1032px] px-6 xl:px-0 md:m-auto md:pt-12">
        <div className="flex flex-col md:flex-row gap-3 justify-between items-center">
          <Text
            size="text-3xl"
            weight="font-medium"
            className="flex items-center text-neutral-day h-20 md:h-fit"
          >
            {greeting}, {firstName}
          </Text>
          <JobOpportunities
            jobOpportunities={jobOpportunities}
            vetting={vetting}
            onItemClick={handleJobOpportunityItemClick}
          />
        </div>
        {isDeactivated && (
          <div className="my-12 relative">
            <DeactivatedAccountBanner
              profileId={profileId}
              preferences={preferences}
              onUpdate={openWelcomeBackModal}
            />
          </div>
        )}
      </div>
      <JobOpportunityModals
        jobOpportunityId={jobOpportunityId}
        vetting={vetting}
        onClose={handleModalClose}
      />
      <WelcomeBackModal isOpen={isWelcomeBackModalOpen} onClose={closeWelcomeBackModal} />
    </div>
  )
}
