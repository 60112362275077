import { useNetwork } from 'shared/hooks'
import { useProfileAttachments } from 'pages/JobSeekerProfile/hooks/useProfileAttachments'
import { useState } from 'react'
import { useQueryClient } from 'react-query'

const UPLOAD_PRESET = 'profile-pictures'
const CLOUD = process.env.REACT_APP_CLOUDINARY_CLOUD
const API_KEY = process.env.REACT_APP_CLOUDINARY_API_KEY
const UPLOAD_URL = `https://api.cloudinary.com/v1_1/${CLOUD}/image/upload`

const buildUploadParams = ({
  blob,
  profileId,
  signature,
  timestamp,
}: {
  blob: Blob
  profileId: string
  signature: string
  timestamp: number
}) => {
  const formData = new FormData()
  formData.append('file', blob)
  formData.append('upload_preset', UPLOAD_PRESET)
  formData.append('public_id', profileId)
  formData.append('signature', signature)
  formData.append('api_key', String(API_KEY))
  formData.append('timestamp', String(timestamp))

  return formData
}

export const useProfilePictureUploader = ({ profileId }: { profileId: string }) => {
  const [isUploading, setIsUploading] = useState(false)
  const client = useQueryClient()

  const { post } = useNetwork()
  const { handleAttachmentUpdate } = useProfileAttachments(profileId)

  const generateUploadSignature = async () => {
    setIsUploading(true)

    return await post<{ signature: string; timestamp: number }>(
      `/profile/${profileId}/attachment-signatures`,
      { uploadPreset: UPLOAD_PRESET },
    )
      .then(({ data }) => data)
      .finally(() => setIsUploading(false))
  }

  const uploadFile = async (blob: Blob) => {
    const { signature, timestamp } = await generateUploadSignature()
    const body = buildUploadParams({ blob, timestamp, signature, profileId })
    setIsUploading(true)

    return fetch(UPLOAD_URL, { method: 'POST', body })
      .then(handleAttachmentUpdate('photoUrl'))
      .then(() => {
        client.invalidateQueries('profile')
        client.invalidateQueries(`candidates/${profileId}`)
      })
      .finally(() => setIsUploading(false))
  }

  return { uploadFile, isUploading }
}
