import { TrackingEvent } from '../tracking-event'

export class PayRateUpdate implements TrackingEvent {
  name = 'Pay rate update'

  constructor(
    private id: string,
    private readonly isDirectAccess: boolean,
  ) {}

  get payload() {
    return {
      id: this.id,
      isDirectAccess: this.isDirectAccess,
    }
  }
}
