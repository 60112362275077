import { FormControl } from 'shared/hooks'
import { QuestionSchema } from 'screening/domain'
import { usePersisJobOpportunityAnswers } from 'job-opportunity/hooks'

import { QuestionCard } from './components/QuestionCard'

interface Props {
  questions: Array<QuestionSchema>
  formControl: FormControl
  isLoading?: boolean
  jobOpportunityId?: string
  isPreview?: boolean
}

export const Questions = ({
  questions,
  formControl,
  isLoading,
  jobOpportunityId,
  isPreview,
}: Props) => {
  const { persist } = usePersisJobOpportunityAnswers(jobOpportunityId || '')
  return (
    <div>
      {questions.map((question, index) => (
        <QuestionCard
          key={index}
          index={index}
          question={question}
          name={`answers.${String(index)}`}
          formControl={formControl}
          persist={persist}
          isLoading={isLoading}
          isPreview={isPreview}
        />
      ))}
    </div>
  )
}
