import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as SettingsIcon } from 'icons/listings/settings.svg'

import { AccountClaim, useAuth } from 'shared/hooks'

import { ListingInfo } from 'job-listing/domains'

import { ListingSettingsModal } from './components'
import { CollapsedMenu, CollapsedMenuItem } from 'shared/components/molecules'
import { ButtonType } from 'shared/components/atoms'
import { ListingStatus } from 'job-listing/enums'
import { JobListingCloserDialog, JobListingPauserDialog } from 'job-listing/exports'

interface ListingManagementMenuProps {
  listing: ListingInfo
  readOnly?: boolean
}

export const ListingManagementMenu = ({ listing, readOnly }: ListingManagementMenuProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCloseJobListingOpened, setIsCloseJobListingOpened] = useState<boolean>(false)
  const [isPauseJobListingOpened, setIsPauseJobListingOpened] = useState<boolean>(false)

  const {
    user: { claims },
  } = useAuth()
  const navigate = useNavigate()

  const handleOpenListingSettings = () => setIsModalOpen(true)
  const handleCloseListingSettings = () => setIsModalOpen(false)
  const openCloseJobListingDialog = () => setIsCloseJobListingOpened(true)
  const dismissCloseJobListingDialog = () => setIsCloseJobListingOpened(false)
  const openPauseJobListingDialog = () => setIsPauseJobListingOpened(true)
  const dismissPauseJobListingDialog = () => setIsPauseJobListingOpened(false)

  const handleOpenScreenings = () => navigate(`/listings/${listing.id}/screenings`)

  const menuItems = useMemo<Array<CollapsedMenuItem>>(
    () =>
      [
        {
          label: 'Listing settings',
          callback: handleOpenListingSettings,
          visible: claims.includes(AccountClaim.ManageJobListingSettings),
        },
        {
          label: 'Application forms',
          callback: handleOpenScreenings,
          visible: claims.includes(AccountClaim.ScreeningManagement),
        },
        {
          label: 'Pause job listing',
          callback: openPauseJobListingDialog,
          visible:
            listing.status === ListingStatus.Active &&
            claims.includes(AccountClaim.PauseJobListings),
        },
        {
          label: 'Close job listing',
          callback: openCloseJobListingDialog,
          visible:
            listing.status !== ListingStatus.Closed &&
            claims.includes(AccountClaim.CloseJobListings),
        },
      ].filter(({ visible }) => visible),
    [listing.status],
  )

  if (readOnly || !menuItems.length) return null

  return (
    <>
      <CollapsedMenu
        buttonType={ButtonType.Tertiary}
        triggerElement={<SettingsIcon className="stroke-neutral-darker" />}
        items={menuItems}
        triggerElementSize="lg"
        aria-label="Listing settings"
      />
      <ListingSettingsModal
        isOpen={isModalOpen}
        listing={listing}
        onClose={handleCloseListingSettings}
      />
      <JobListingCloserDialog
        listingId={listing.id}
        isTalentReview={listing.isTalentReview}
        isOpen={isCloseJobListingOpened}
        closeDialog={dismissCloseJobListingDialog}
      />
      <JobListingPauserDialog
        listingId={listing.id}
        isOpen={isPauseJobListingOpened}
        closeDialog={dismissPauseJobListingDialog}
      />
    </>
  )
}
