export enum AssessmentTagType {
  RedFlag = 'red-flag',
  Warning = 'warning',
  Quality = 'quality',
}

export const tagTypePriority = {
  [AssessmentTagType.RedFlag]: 3,
  [AssessmentTagType.Warning]: 2,
  [AssessmentTagType.Quality]: 1,
}

export enum AssessmentTagGroup {
  Experience = 'experience',
  Behavior = 'behavior',
  Vetting = 'vetting',
}
