import classNames from 'classnames'

import { Text } from 'shared/components/atoms'
import { EnglishVettingStep } from 'vetting/enums'
import { STEP_DATA } from 'vetting/pages/EnglishVetting/consts'

interface StepsProps {
  currentStep: number
  onStepClick: (step: number) => void
  orientation: 'horizontal' | 'vertical'
  theme: 'light' | 'dark'
}

const steps = [
  STEP_DATA[EnglishVettingStep.TechnicalChallenges].title,
  STEP_DATA[EnglishVettingStep.FutureInterviews].title,
]

export const Steps = ({ currentStep, onStepClick, orientation, theme }: StepsProps) => {
  return (
    <ul
      className={classNames('flex items-stretch justify-center gap-2 z-30', {
        'flex-col gap-2 w-[310px]': orientation === 'horizontal',
        'flex-row gap-10': orientation === 'vertical',
      })}
    >
      {steps.map((step, index) => {
        const isLast = index === steps.length - 1
        const isCurrent = currentStep === index

        return (
          <li key={index} className="flex flex-col items-start gap-2">
            <div
              className="flex flex-row gap-4 items-center hover:cursor-pointer"
              onClick={() => onStepClick(index)}
            >
              <div
                className={classNames(
                  'w-8 h-8 flex items-center justify-center border-2 rounded-full shrink-0',
                  {
                    'border-neutral-day': isCurrent && theme === 'dark',
                    'border-neutral-darkest bg-neutral-darkest': isCurrent && theme === 'light',
                    'border-neutral-light': !isCurrent,
                  },
                )}
              >
                <Text
                  size="text-xs"
                  weight="font-medium"
                  className={classNames({
                    'text-neutral-day': isCurrent,
                    'text-neutral-medium': !isCurrent,
                  })}
                >
                  {index + 1}
                </Text>
              </div>
              <Text
                size="text-sm"
                weight="font-medium"
                className={classNames({
                  'text-neutral-day': isCurrent && theme === 'dark',
                  'text-neutral-darkest': isCurrent && theme === 'light',
                  'text-neutral-dark': !isCurrent,
                })}
              >
                {step}
              </Text>
            </div>
            {!isLast && orientation === 'horizontal' && (
              <div className="w-[2px] h-[30px] relative left-4 bg-neutral-light" />
            )}
          </li>
        )
      })}
    </ul>
  )
}
