import { matchingEvents, profileEvents } from 'candidates-search/consts'
import { TimelineFilterValues } from 'candidates-search/domain'
import { ChangeLogType } from 'candidates-search/enums'

interface FetchTimelineRequest {
  profileId: string
  matchingIds: Optional<Array<string>>
  eventTypes: Optional<Array<ChangeLogType>>
  page: Optional<number>
  pageSize: Optional<number>
}

interface Props {
  profileId: string
  filter: TimelineFilterValues
  page: number
  pageSize: number
}
export class FetchTimelineDTO {
  constructor(private readonly props: Props) {}

  private get matchingEvents() {
    const {
      filter: { selectedMatchingEvents, matchingEventsNone },
    } = this.props

    if (matchingEventsNone) {
      return []
    }

    if (!selectedMatchingEvents.length) {
      return Object.values(matchingEvents).flat()
    }

    return selectedMatchingEvents.reduce((acc, event) => {
      return acc.concat(matchingEvents[event])
    }, [] as Array<ChangeLogType>)
  }

  private get profileEvents() {
    const {
      filter: { selectedProfileEvents, profileEventsNone },
    } = this.props

    if (profileEventsNone) {
      return []
    }

    if (!selectedProfileEvents.length) {
      return Object.values(profileEvents).flat()
    }

    return selectedProfileEvents.reduce((acc, event) => {
      return acc.concat(profileEvents[event])
    }, [] as Array<ChangeLogType>)
  }

  private get eventTypes() {
    const events: Array<ChangeLogType> = []

    events.push(...this.matchingEvents)
    events.push(...this.profileEvents)

    return events
  }

  toJSON(): FetchTimelineRequest {
    return {
      profileId: this.props.profileId,
      matchingIds: this.props.filter.selectedMatchingIds,
      eventTypes: this.eventTypes,
      page: this.props.page,
      pageSize: this.props.pageSize,
    }
  }
}
