import { Candidate, Filter, defaultFilter, ListingInfo } from '../domain'
import { SortType } from '../enums/sort-type.enum'
import { DegreeType } from 'pages/JobSeekerProfile/types'
import { EngagementType } from 'job-listing/enums'
import { Tab } from '../enums'
import { InstitutionTier } from 'modules/candidates/enums'
import { PayRateType } from 'modules/shared/enums'

export interface FetchSearchResultRequest {
  filters: {
    keywords?: string
    roles?: Array<{ id: number; label: string }>
    skills?: {
      stacks: Array<{ id: number; label: string }>
      matchAll: boolean
    }
    payRate?: {
      type: PayRateType
      min: number
      max: number
    }
    yearsOfExperience?: {
      min: number
      max: number
    }
    englishLevel?: {
      onlyVerifiedCategories: boolean
      categories: Array<string>
    }
    fulfillmentStatus?: Array<string>
    profileStatus?: Array<string>
    staStatus?: Array<string>
    educationDegrees?: Array<DegreeType>
    educationInstitutionTiers?: {
      tiers: Array<InstitutionTier>
      degreeTypes: Array<DegreeType>
    }
    candidates?: Array<string>
    hiddenCandidates?: Array<string>
    engagementPreferences?: {
      type: EngagementType
      minFreelancingHoursPerWeek?: number
      maxFreelancingHoursPerWeek?: number
    }
    assessmentTags?: {
      mustHave: Array<string>
      mustNotHave: Array<string>
    }
    screeningProgress?: Array<string>
  }
  page: number
  sortBy: string
  listingId?: string
}

export interface FetchSearchResultResponse {
  totalRecords: number
  totalPages: number
  profiles: Array<Candidate>
}

interface FetchSearchResultDTOProps {
  filter: Filter
  sort: SortType
  page: number
  listing: Optional<Nullable<ListingInfo>>
  activeTab: Tab
  activeTabItems: Array<string>
  applyTabFilter: boolean
  targetProfileId: string | null
}

export class FetchSearchResultDTO {
  constructor(private readonly props: FetchSearchResultDTOProps) {}

  get englishLevel() {
    const { filter } = this.props

    return {
      onlyVerifiedCategories: filter.onlyVerifiedEnglishLevel,
      categories: Object.entries(filter.englishLevel)
        .filter(([_, value]) => value)
        .map(([key, _]) => key),
    }
  }

  get profileFulfillmentStatus() {
    const { filter } = this.props

    const entries = Object.entries(filter.profileFulfillmentStatus).filter(([_, value]) => value)
    return entries.map(([key, _]) => key)
  }

  get profileStatus() {
    const { filter } = this.props

    const entries = Object.entries(filter.profileStatus).filter(([_, value]) => value)
    return entries.map(([key, _]) => key)
  }

  get staStatus() {
    const { filter } = this.props

    const entries = Object.entries(filter.staStatus).filter(([_, value]) => value)
    return entries.map(([key, _]) => key)
  }

  get screeningProgress() {
    const { filter, activeTab, listing } = this.props

    if (activeTab === Tab.AllCandidates || !listing) return []

    const entries = Object.entries(filter.screeningProgress).filter(([_, value]) => value)
    return entries.map(([key, _]) => key)
  }

  get engagementPreferences() {
    const { filter } = this.props

    if (!filter.engagementPreferences.type) return

    const { type, freelancingWorkingHoursPerWeek } = filter.engagementPreferences

    return {
      type: filter.engagementPreferences.type,
      maxFreelancingHoursPerWeek:
        type === EngagementType.ShortTerm ? freelancingWorkingHoursPerWeek.max : undefined,
      minFreelancingHoursPerWeek:
        type === EngagementType.ShortTerm ? freelancingWorkingHoursPerWeek.min : undefined,
    }
  }

  get assessmentTags() {
    const { filter } = this.props

    return {
      mustHave: filter.assessmentTags.mustHave.map(({ id }) => id),
      mustNotHave: filter.assessmentTags.mustNotHave.map(({ id }) => id),
    }
  }

  get primarySkills() {
    const { filter } = this.props

    return {
      stacks: filter.primarySkills.stacks
        .filter((stack) => stack.stackId > 0)
        .map(({ stackId, stackLabel, yearsOfExperience }) => ({
          id: stackId,
          label: stackLabel,
          experience: Number(yearsOfExperience),
        })),
      matchAll: filter.primarySkills.matchAll,
    }
  }

  get proficiencies() {
    const { filter } = this.props

    return {
      mustHave: filter.proficiencies.mustHave.map(({ id }) => id),
      mustNotHave: filter.proficiencies.mustNotHave.map(({ id }) => id),
    }
  }

  private get candidates() {
    const { activeTab, activeTabItems } = this.props
    if (activeTab === Tab.AllCandidates) return undefined
    return activeTabItems
  }

  private get filters() {
    const { filter, activeTab, applyTabFilter } = this.props

    if (activeTab !== Tab.AllCandidates && !applyTabFilter)
      return {
        candidates: this.candidates,
        payRate: defaultFilter.payRate,
        targetProfileId: this.props.targetProfileId,
        jobListingId: this.props.listing?.id,
        activeTab,
      }

    return {
      keywords: filter.keywords || undefined,
      roles: filter.roles,
      skills: filter.skills,
      payRate: filter.payRate,
      yearsOfExperience: filter.yearsOfExperience,
      englishLevel: this.englishLevel,
      fulfillmentStatus: this.profileFulfillmentStatus,
      profileStatus: this.profileStatus,
      staStatus: this.staStatus,
      screeningProgress: this.screeningProgress,
      educationDegrees: filter.educationDegree ? [filter.educationDegree] : undefined,
      educationInstitutionTiers: {
        tiers: filter.tierFilters,
        degreeTypes: filter.restrictTierToBachelors ? [DegreeType.Bachelor] : [],
      },
      engagementPreferences: this.engagementPreferences,
      assessmentTags: this.assessmentTags,
      proficiencies: this.proficiencies,
      candidates: this.candidates,
      onlyTalentWithoutActiveJob: filter.onlyTalentWithoutActiveJob,
      onlyTalentWithAdvertiseConsent: filter.onlyTalentWithAdvertiseConsent,
      onlyTalentWithPersonalWebsite: filter.onlyTalentWithPersonalWebsite,
      country: filter.country,
      targetProfileId: this.props.targetProfileId,
      primarySkills: this.primarySkills,
      mockInterviewDone: filter.mockInterviewDone,
      jobListingId: this.props.listing?.id,
      activeTab,
    }
  }

  toJSON(): FetchSearchResultRequest {
    const { page, sort } = this.props

    return {
      filters: this.filters,
      page,
      sortBy: sort,
    }
  }
}
