import { OrganizationIcon } from 'shared/components/molecules'
import { Paragraph } from 'shared/components/atoms'
import { WorkExperience } from 'modules/work-experience'
import { ReactComponent as CloseIcon } from 'icons/close.svg'
import { useFormatting } from 'shared/hooks'

interface Props {
  workExperience?: WorkExperience
  onRemove?: () => void
}

export const WorkExperienceItem = ({ workExperience, onRemove }: Props) => {
  const { formatMonthsGap } = useFormatting()

  const copy = workExperience
    ? formatMonthsGap(
        new Date(workExperience.startDate),
        workExperience.endDate ? new Date(workExperience.endDate) : undefined,
      )
    : ''

  const workExperienceLabels = {
    experienceOrganization: {
      name: workExperience?.experienceOrganization.name || 'N/A',
      website: workExperience?.experienceOrganization.website || 'N/A',
    },
    title: workExperience?.title || 'Deleted Work Experience',
    location: workExperience?.location || 'N/A',
  }

  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-x-4">
        <OrganizationIcon domain={workExperienceLabels.experienceOrganization.website} />
        <div className="flex flex-col">
          <Paragraph className="flex items-center h-10">{workExperienceLabels.title}</Paragraph>
          <Paragraph size="body-xs" className="text-neutral-dark">
            {workExperienceLabels.experienceOrganization.name} - {workExperienceLabels.location}
          </Paragraph>
          {Boolean(workExperience) && (
            <Paragraph size="body-xs" className="text-neutral-dark">
              {copy}
            </Paragraph>
          )}
        </div>
      </div>
      {Boolean(onRemove) && (
        <div onClick={onRemove} className="inline-flex cursor-pointer">
          <CloseIcon className="h-4 w-4 stroke-neutral-medium" />
        </div>
      )}
    </div>
  )
}
