import { useNetwork } from 'shared/hooks'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

const labelsMap = {
  software_engineer: 'Software Developer',
  data_engineer: 'Data Engineer',
  design: 'Design',
  'infrastructure_&_dev_ops': 'Infrastructure & DevOps',
  quality_assurance: 'Quality Assurance',
  leadership: 'Leadership',
  other: 'Other',
}

export const useProfile = () => {
  const { get } = useNetwork()

  const { data: profileRoles = [], refetch: getProfileRoles } = useQuery(
    'profile/profile-roles',
    async () =>
      await get<Array<{ id: number; label: string; roleType: string; description: string }>>(
        'profile/profile-roles',
      ).then(({ data }) => data),
  )

  const profileRolesWithGroups = useMemo(() => {
    return profileRoles.map((role) =>
      Object.assign({}, role, {
        groupLabel: labelsMap[role.roleType as keyof typeof labelsMap] || 'Other',
      }),
    )
  }, [profileRoles])

  const getProfileRoleOptions = (usedRoles: Array<{ id: number; label: string }>) => {
    return profileRoles.filter(({ id }) => !usedRoles.find(({ id: roleId }) => id === roleId))
  }

  const getRoleType = (roleId: number) =>
    profileRoles.find(({ id }: { id: number }) => id === roleId)?.roleType

  return {
    profileRoles,
    profileRolesWithGroups,
    getProfileRoles,
    getProfileRoleOptions,
    getRoleType,
  }
}
