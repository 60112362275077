import { useMutation, useQueryClient } from 'react-query'

import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

import { ListingSettings } from 'job-listing/domains'

export const useListingSettings = (listingId: string) => {
  const client = useQueryClient()
  const { patch } = useNetwork()
  const { captureException } = useMonitoring()
  const { toastError, toastSuccess } = useToast()

  const { mutateAsync: updateSettings, isLoading } = useMutation(
    async (values: ListingSettings) => patch(`listings/${listingId}/settings`, values),
    {
      onSuccess: () => {
        toastSuccess("Listing's settings updated.")
        client.invalidateQueries(['listings', listingId, 'preview'])
        client.invalidateQueries('matchings')
      },
      onError: (error: RequestError) => {
        toastError('It was not possible to update the listing settings.')
        captureException(error)
      },
    },
  )

  return { updateSettings, isUpdating: isLoading }
}
