import { cn } from 'modules/shared'

import { Size, Text, Weight } from '../Text'
import { ReactComponent as EmptyPhotoIcon } from 'icons/empty-photo.svg'
import { cloneElement } from 'react'

type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

interface AvatarProps {
  children?: React.ReactNode
  text: string
  size?: AvatarSize
  photo?: string
  showIconWhenEmpty?: boolean
  className?: string
}

export const Avatar = ({
  children,
  text,
  photo,
  size = 'md',
  showIconWhenEmpty,
  className,
}: AvatarProps) => {
  const sizes = {
    xs: 'w-4 h-4',
    sm: 'w-6 h-6',
    md: 'w-10 h-10',
    lg: 'w-14 h-14',
    xl: 'w-24 h-24',
    '2xl': 'w-32 h-32',
  }

  const textStyles = {
    xs: { size: 'text-2xs', weight: 'font-normal' },
    sm: { size: 'text-2xs', weight: 'font-normal' },
    md: { size: 'text-2xs', weight: 'font-normal' },
    lg: { size: 'text-xl', weight: 'font-medium' },
    xl: { size: 'text-4xl', weight: 'font-medium' },
    '2xl': { size: 'text-4xl', weight: 'font-medium' },
  } as Record<AvatarSize, { size: Size; weight: Weight }>

  const icon = cloneElement(
    <EmptyPhotoIcon className="absolute top-0 right-0 bottom-0 left-0 m-auto" />,
    {
      style: {
        ...(size === '2xl' && { height: '72px', width: '72px' }),
        ...(size === 'xl' && { height: '56px', width: '56px' }),
        ...(size === 'lg' && { height: '24px', width: '24px' }),
        ...((size === 'md' || size === 'sm' || size === 'xs') && { height: '16px', width: '16px' }),
      },
    },
  )

  if (photo)
    return (
      <div
        className={cn(
          'shrink-0 relative rounded-full object-cover bg-no-repeat bg-cover',
          sizes[size],
          className,
        )}
        style={{
          backgroundImage: `url("${photo}")`,
          WebkitPrintColorAdjust: 'exact',
          colorAdjust: 'exact',
          printColorAdjust: 'exact',
        }}
      >
        {children}
      </div>
    )

  if (showIconWhenEmpty)
    return (
      <div
        className={cn('rounded-full bg-neutral-lighter shrink-0 relative', sizes[size], className)}
        style={{
          WebkitPrintColorAdjust: 'exact',
          colorAdjust: 'exact',
          printColorAdjust: 'exact',
        }}
      >
        {children}
        {icon}
      </div>
    )

  return (
    <div
      className={cn(
        'shrink-0 relative rounded-full flex items-center justify-center bg-neutral-dark',
        sizes[size],
        className,
      )}
      style={{
        WebkitPrintColorAdjust: 'exact',
        colorAdjust: 'exact',
        printColorAdjust: 'exact',
      }}
    >
      <Text
        size={textStyles[size].size}
        weight={textStyles[size].weight}
        className="text-neutral-day text-center"
      >
        {text.charAt(0).toUpperCase()}
      </Text>
      {children}
    </div>
  )
}
