import classNames from 'classnames'

export const CircularProgressBar = ({
  percentage,
  children,
  className,
  size = 144,
  strokeWidth = 8,
  strokeLineCap = 'round',
  successClassName = 'stroke-success-light',
}: {
  percentage: number
  children?: React.ReactNode
  className?: string
  size?: number
  strokeWidth?: number
  strokeLineCap?: 'round' | 'square'
  successClassName?: string
}) => {
  const radius = size / 2 - strokeWidth / 2
  const circumference = radius * 2 * Math.PI

  return (
    <div
      className={classNames(
        'flex items-center justify-center overflow-hidden rounded-full shrink-0',
        className,
      )}
    >
      <svg width={size} height={size} className="transform -rotate-90">
        <circle
          className="stroke-neutral-lightest"
          strokeWidth={strokeWidth}
          fill="transparent"
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />

        <circle
          className={percentage === 100 ? successClassName : 'stroke-brand-medium'}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLineCap}
          fill="transparent"
          r={radius}
          cx={size / 2}
          cy={size / 2}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (percentage / 100) * circumference}
        />
      </svg>
      <div className="absolute">{children}</div>
    </div>
  )
}
