import { Text } from 'shared/components/atoms'

import { ListingInfo } from 'job-listing/domains'
import { SkillTagWithExperience } from 'profile/exports'
import { Candidate } from 'candidates-search/domain'

interface MainSkillsMatchProps {
  candidate: Candidate
  listing: Optional<ListingInfo>
}

export const MainSkillsMatch = ({ candidate, listing }: MainSkillsMatchProps) => {
  if (!listing) return null

  const skills = candidate.primaryStacks
    .map((stack) => ({
      id: stack.id,
      label: stack.label,
      experience: stack.experience,
      highlighted:
        listing.stacks?.some((desiredSkill) => desiredSkill.stack.id === stack.id) || false,
    }))
    .sort((a, b) => (a.highlighted ? -1 : 1))

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <Text size="text-xl">Main skills match</Text>
        <Text size="text-sm" className="text-neutral-dark">
          The mains skills that match with the listing desired skills will be highlighted.
        </Text>
      </div>
      <div className="flex gap-6 flex-wrap p-6 border border-neutral-light rounded-lg">
        {skills.map((skill) => (
          <SkillTagWithExperience
            key={skill.id}
            label={skill.label}
            experience={skill.experience}
            highlighted={skill.highlighted}
            size="md"
          />
        ))}
      </div>
    </div>
  )
}
