import { ReactNode } from 'react'

import { Text } from 'shared/components/atoms'

import { ReactComponent as CheckIcon } from 'icons/checked.svg'
import { ReactComponent as WarningIcon } from 'icons/alert-triangle--filled.svg'
import classNames from 'classnames'

interface ProgressRuleProps {
  children: ReactNode
  completed: boolean
  error?: boolean
  onClick: () => void
}
export const ProgressRule = ({ children, completed, error, onClick }: ProgressRuleProps) => {
  return (
    <li className="flex flex-rows gap-4 items-center" onClick={completed ? () => {} : onClick}>
      <Text
        size="text-sm"
        weight="font-normal"
        className={classNames('!whitespace-nowrap cursor-pointer', {
          'text-neutral-medium !pointer-events-none': completed,
          'hover:text-neutral-dark': !completed,
          'line-through': error,
        })}
      >
        {children}
      </Text>
      {completed ? <CheckIcon className="h-4 w-4 ml-auto fill-neutral-darker" /> : null}
      {error ? <WarningIcon className="h-4 w-4 ml-auto fill-warning-medium" /> : null}
    </li>
  )
}
