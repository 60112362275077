import { useMutation, useQueryClient } from 'react-query'

import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { useTracking } from 'tracking'

import { UpdatePayRateDTO } from 'modules/profiles/dtos'
import { PayRate } from 'modules/profiles/domain'
import { PayRateUpdate } from 'tracking/events/profile/pay-rate-update.event'
import { useMonitoring } from 'shared/hooks'

export const useUpdatePayRate = (profileId: string, isDirectAccess: boolean) => {
  const { toastError } = useToast()
  const { patch } = useNetwork()
  const client = useQueryClient()
  const { trackEvent } = useTracking()
  const { captureException } = useMonitoring()

  const { mutateAsync: updatePayRate, isLoading } = useMutation(
    (payRate: PayRate) => {
      const payload = new UpdatePayRateDTO(payRate).toJSON()
      return patch(`/profile/${profileId}/preferences/pay-rate`, payload)
    },
    {
      onSuccess: () => {
        trackEvent(new PayRateUpdate(profileId, isDirectAccess))
        client.invalidateQueries(`candidates/${profileId}`)
        client.invalidateQueries('profile')
        client.invalidateQueries(`${profileId}-preferences`)
      },
      onError: (error) => {
        toastError('Failed to update the pay rate')
        captureException(error)
      },
    },
  )

  return { updatePayRate, isLoading }
}
