import { GetItemPropsOptions, GetMenuPropsOptions, GetPropsCommonOptions } from 'downshift'
import { useMemo } from 'react'
import pickBy from 'lodash/pickBy'
import keys from 'lodash/keys'
import classNames from 'classnames'

import { Suggestion } from '../../types'
import { SuggestionItem } from '../SuggestionItem'
import { useFuzzySearch } from 'shared/hooks'

interface SuggestionListProps {
  suggestions: Suggestion[]
  selectedSuggestions: Suggestion[]
  inputValue: string | null
  isOpen: boolean
  selectedItem: Suggestion | null
  highlightedIndex: number | null
  getMenuProps(
    options?: GetMenuPropsOptions | undefined,
    otherOptions?: GetPropsCommonOptions | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItemProps(options: GetItemPropsOptions<Suggestion>): any
  allowCreation?: boolean
  matchDescription?: boolean
  showDescription?: boolean
  className?: string
  threshold?: number
  distance?: number
}

export const SuggestionList = ({
  suggestions,
  selectedSuggestions,
  inputValue,
  isOpen,
  selectedItem,
  highlightedIndex,
  getItemProps,
  allowCreation = true,
  matchDescription,
  showDescription,
  className,
  threshold = 0.2,
  distance = 100,
}: SuggestionListProps) => {
  const { search, checkExactMatch } = useFuzzySearch<Suggestion>(suggestions, {
    keys: keys(pickBy({ label: true, description: matchDescription })),
    findAllMatches: false,
    threshold,
    includeScore: true,
    distance,
  })

  const groupsEnabled =
    Array.isArray(suggestions) && suggestions.some(({ groupLabel }) => Boolean(groupLabel))

  const formattedInput = inputValue ? inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').trim() : ''

  const isValueSelected = useMemo(
    () =>
      selectedSuggestions.some(
        ({ label }) => inputValue?.toLowerCase().trim() === label.toLowerCase().trim(),
      ),
    [inputValue, selectedSuggestions],
  )

  const filteredSuggestions = useMemo(() => {
    const matches = search(formattedInput)

    // group related items
    const groups = matches.reduce(
      (accumulator, suggestion) => {
        const groupLabel = String(suggestion.groupLabel)
        accumulator[groupLabel] = accumulator[groupLabel] ?? []
        accumulator[groupLabel].push(suggestion)

        return accumulator
      },
      {} as Record<string, Array<Suggestion>>,
    )

    const items = Object.values(groups).flatMap((items) => items)

    const hasExactMatch = items.some(({ score }) => checkExactMatch(score))
    const hasNewItem = !hasExactMatch && inputValue?.length
    const shouldShowNewItemOption = hasNewItem || items.length < 100

    if (!(shouldShowNewItemOption && allowCreation && !isValueSelected)) return items

    if (!inputValue) return items

    if (items.length > 0 && items[items.length - 1].id === undefined) {
      items[items.length - 1].label = inputValue ?? ''
    } else {
      items.push({ id: undefined, label: inputValue ?? '' })
    }

    if (hasExactMatch && items[items.length - 1].id === undefined) items.pop()

    return items
  }, [formattedInput, suggestions, isValueSelected, allowCreation])

  const shouldRenderGroupLabel = (suggestion: Suggestion, index: number) => {
    return groupsEnabled && suggestion.groupLabel !== filteredSuggestions[index - 1]?.groupLabel
  }

  return (
    <ul
      className={classNames(
        `
        z-20 bg-white w-full overflow-y-auto max-h-60
        min-w-fit shadow-md cursor-pointer text-sm
        absolute shadow-[0_4px_8px_-4px_rgba(0,0,0,0.08) top-full before:w-full before:max-h-64
      `,
        className,
      )}
    >
      {isOpen &&
        filteredSuggestions
          .slice(0, 100)
          .map((suggestion, index) => (
            <SuggestionItem
              key={suggestion.id || `new-suggestion-${index}`}
              index={index}
              suggestion={suggestion}
              isSelected={selectedItem?.label === suggestion.label}
              isHighlighted={highlightedIndex === index}
              getItemProps={getItemProps}
              renderGroupLabel={shouldRenderGroupLabel(suggestion, index)}
              groupsEnabled={groupsEnabled}
              showDescription={showDescription}
            />
          ))}
    </ul>
  )
}
