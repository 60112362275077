import { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { PageView, useTracking } from 'tracking'

export const NavigationTracker = memo(function NavigationTracker() {
  const { trackEvent } = useTracking()
  const location = useLocation()

  useEffect(() => {
    const event = new PageView(location)
    trackEvent(event) // Mixpanel
  }, [location])

  return null
})
