import { SatisfactionSurvey } from 'pages/Surveys'
import React, { useState } from 'react'
import { Product } from './enums/product.enum'

export const SurveysContext = React.createContext({
  showSurvey: (product?: Product) => {},
})

export const SurveysProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSurveyOpen, setIsSurveyOpen] = useState(false)
  const [surveyProduct, setSurveyProduct] = useState<Product | undefined>()

  const handleShowSurvey = (product?: Product) => {
    setSurveyProduct(product)
    setIsSurveyOpen(true)
  }

  const handleCloseSurvey = () => {
    setIsSurveyOpen(false)
    setSurveyProduct(undefined)
  }

  const context = {
    showSurvey: handleShowSurvey,
  }

  return (
    <SurveysContext.Provider value={context}>
      {children}
      {isSurveyOpen && <SatisfactionSurvey product={surveyProduct} onClose={handleCloseSurvey} />}
    </SurveysContext.Provider>
  )
}
