import { ReactComponent as UploadIcon } from 'icons/upload-symbol.svg'

interface DropWarningProps {
  children: JSX.Element
  isVisible: boolean
}

export const DropWarning = ({ children, isVisible }: DropWarningProps) => {
  if (isVisible !== true) {
    return children
  }

  return (
    <div className="flex justify-center w-full gap-4">
      <UploadIcon className="h-6 w-6 stroke-brand-medium" />
      <span className="font-medium text-sm text-brand-dark h-8">Drop your file here</span>
    </div>
  )
}
