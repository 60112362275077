import { useEffect } from 'react'

import { FieldHint, TertiaryButton } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { Label } from 'shared/components/atoms'
import { Textarea } from 'shared/components/atoms'
import { PrimaryButton } from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'

import { useOverview } from 'hooks/profile/useOverview'
import { useForm } from 'shared/hooks'

import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { schema, MAX_BIO_TEXT_LENGTH } from '.'

interface BioProps {
  profile: ProfileOverview
  handleClose(): void
}

export const Bio = ({ handleClose, profile }: BioProps) => {
  const { updateOverview, parseOverviewToFormSchema, isUpdating } = useOverview(profile.id)

  const { register, handleSubmit, reset, getValues, watch, isDirty } = useForm({
    schema,
    defaultValues: { bioText: '' },
  })
  const bioText = watch('bioText') as string

  const onSubmit = handleSubmit(async () => {
    const formValues = getValues()
    updateOverview({ overview: formValues, profile }).then(() => {
      handleClose()
    })
  })

  useEffect(() => {
    reset(parseOverviewToFormSchema(profile))
  }, [reset, profile])

  return (
    <Modal
      handleClose={handleClose}
      showDismissalConfirmation={isDirty}
      title="Bio"
      content={
        <form onSubmit={onSubmit} id="bio-form" className="flex flex-col gap-10">
          <FieldHint className="m-0">
            You can write about your years of experience, industry, or skills. You could also
            highlight previous job experiences or top achievements in your career.
          </FieldHint>
          <ContentWrapper isForm>
            <Label htmlFor="bioText">Short bio</Label>
            <Textarea
              register={register}
              id="bioText"
              name="bioText"
              maxLength={MAX_BIO_TEXT_LENGTH}
              placeholder="Share more about yourself and what you have accomplished."
            >
              <FieldHint>
                {bioText?.length || 0} / {MAX_BIO_TEXT_LENGTH}
              </FieldHint>
            </Textarea>
          </ContentWrapper>
        </form>
      }
      footer={
        <>
          <TertiaryButton size="md" onClick={handleClose} className="w-full md:w-fit">
            Cancel
          </TertiaryButton>
          <PrimaryButton
            size="md"
            type="submit"
            form="bio-form"
            isLoading={isUpdating}
            className="w-full md:w-fit"
          >
            Save
          </PrimaryButton>
        </>
      }
    />
  )
}
