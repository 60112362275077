import { useQueryClient } from 'react-query'
import { useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import classNames from 'classnames'

import { Text } from 'components/Text'
import { PrimaryButton, SecondaryButton } from 'shared/components/atoms'
import { AddWatchersModal } from './AddWatchersModal'

import { useAuth } from 'shared/hooks'
import { useFetchCompanyInfo } from 'company/exports'
import { useSubscription } from './useSubscription'

import { ReactComponent as CloseIcon } from 'icons/close.svg'
import { ReactComponent as EyeIcon } from 'icons/eye.svg'
import { ReactComponent as AddIcon } from 'icons/add.svg'
import { ListingInfo } from 'job-listing/domains'

export const Subscribers = ({ listing }: { listing: ListingInfo }) => {
  const {
    user: { email: userEmail, isManager },
  } = useAuth()

  const client = useQueryClient()
  const { subscribe, unsubscribe, isUserSubscribed } = useSubscription()
  const { company } = useFetchCompanyInfo()
  const employees = company?.employees || []

  const [isModalOpen, setIsModalOpen] = useState(false)

  const userEmployeeId = employees.find(({ email }) => email === userEmail)?.id
  const subscribers = listing.subscriptions.map(({ subscriberId, email }) => {
    return { id: subscriberId, label: email }
  })

  const showStartWatchingButton = !isUserSubscribed(userEmail, listing.subscriptions)

  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  const handleStartWatching = () =>
    subscribe({
      listingId: listing.id,
      subscribers: [...subscribers, { id: userEmployeeId!, label: userEmail }],
    }).then(() => client.invalidateQueries(['listings', listing.id, 'preview']))

  const handleRemoveSubscription = (subscriptionId: string) =>
    unsubscribe({ listingId: listing.id, subscriptionId }).then(() =>
      client.invalidateQueries(['listings', listing.id, 'preview']),
    )

  return (
    <>
      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger className="flex flex-row items-center justify-center gap-3 px-3 py-1 rounded hover:bg-neutral-lightest transition-all">
          <Text size="small-body">Watching</Text>
          <div className="bg-neutral-lightest rounded px-2">
            <Text size="caption" color="text-neutral-dark">
              {listing.subscriptions.length}
            </Text>
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          align="end"
          className={classNames(
            'w-56 h-[270px] p-4 mt-1',
            'flex flex-col gap-2',
            'bg-white shadow-md rounded-lg',
            {
              hidden: isModalOpen,
              flex: !isModalOpen,
            },
          )}
        >
          <Text size="small-body" color="text-neutral-dark">
            Watching this listing
          </Text>

          <ul className="flex-grow mt-2 overflow-auto">
            {listing.subscriptions.map((subscription) => {
              return (
                <li
                  key={subscription.id}
                  className="p-1 cursor-pointer hover:bg-neutral-lightest rounded flex justify-between items-center group"
                >
                  <Text size="small-body" className="w-full overflow-hidden">
                    {subscription.email}
                  </Text>
                  {(isManager || subscription.email === userEmail) && (
                    <CloseIcon
                      className="w-4 h-4 stroke-neutral-dark hidden group-hover:flex"
                      onClick={() => handleRemoveSubscription(subscription.id)}
                    />
                  )}
                </li>
              )
            })}
          </ul>

          {showStartWatchingButton && (
            <PrimaryButton
              isIconBefore
              icon={<EyeIcon className="w-4 h-4" />}
              size="sm"
              onClick={handleStartWatching}
            >
              Start watching
            </PrimaryButton>
          )}
          {isManager && (
            <SecondaryButton
              isIconBefore
              icon={<AddIcon className="w-4 h-4" />}
              size="sm"
              onClick={handleOpenModal}
            >
              Add watchers
            </SecondaryButton>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Root>

      {isModalOpen && (
        <AddWatchersModal listing={listing} employees={employees} handleClose={handleCloseModal} />
      )}
    </>
  )
}
