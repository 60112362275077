import { OverridedMixpanel } from 'mixpanel-browser'

import { Roles } from 'account/enums'
import { TrackingIdentity } from 'tracking/domain/tracking-identity'

import { TrackingEvent } from '../events/tracking-event'
import { PageView } from 'tracking/events'

declare const window: Window & {
  dataLayer: Record<string, unknown>[]
  _cio: {
    identify: (params: Record<string, string | number | undefined>) => void
    page: (pageName: string, payload: Record<string, string>) => void
    track: (pageName: string, payload: Record<string, unknown>) => void
  }
}

const mixpanelToken = String(process.env.REACT_APP_MIXPANEL_TOKEN)
let mixpanel: OverridedMixpanel
let mixpanelIdentifyCalled = false
const mixpanelEventsBuffer: Array<TrackingEvent> = []
const maxBufferSize = 32

const resolveMixpanel = async () => {
  if (mixpanel) return mixpanel

  mixpanel = await import(/*  webpackPrefetch: true */ 'mixpanel-browser').then(
    // default mapping is required in here. Otherwise the import will follow a module behavior and mixpanel will run custom code for module scenarios
    (module) => module.default,
  )
  mixpanel.init(mixpanelToken)

  return mixpanel
}

export const useTracking = () => {
  const trackEvent = async (event: TrackingEvent) => {
    const mixpanel = await resolveMixpanel()

    const isPageView = event instanceof PageView
    if (mixpanelIdentifyCalled || !isPageView) {
      mixpanel.track(event.name, event.payload)
    } else {
      mixpanelEventsBuffer.push(event)
      mixpanelEventsBuffer.splice(0, mixpanelEventsBuffer.length - maxBufferSize) // limits buffer size (LRU)
    }

    if (event instanceof PageView) {
      window._cio.page(event.url, event.payload)
    } else {
      window._cio.track(event.name, event.payload)
    }
  }

  const identifyUser = async ({
    accountId,
    role,
    company,
    email,
  }: {
    accountId: string
    email: string
    role?: Roles
    company: null | Partial<{ id: number; name: string }>
  }) => {
    if (!accountId) {
      return
    }

    const mixpanel = await resolveMixpanel()
    const identity = new TrackingIdentity(role, email, company)

    const customerIoPayload = Object.assign({ id: identity.payload.email }, identity.payload)
    window._cio.identify(customerIoPayload)

    if (identity.trackingBlocked) {
      return mixpanel.opt_out_tracking()
    }

    mixpanel.identify(accountId)
    mixpanel.people.set(identity.payload)
    mixpanelIdentifyCalled = true

    await Promise.all(mixpanelEventsBuffer.map(trackEvent))
  }

  return { trackEvent, identifyUser, dataLayer: window.dataLayer }
}
