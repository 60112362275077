import classNames from 'classnames'

import { Text } from 'shared/components/atoms'
import { SharingDate } from './components/SharingDate'
import { ProfilePicture } from 'pages/JobSeekerProfile/components/ProfileHeader/ProfilePicture'
import { WorkExperience } from 'modules/work-experience'

interface ProfileHeadingProps {
  profile: {
    id: string
    photoUrl: string
    lastWorkExperience: WorkExperience
    fullName: string
  }
  firstSharedAt: string | null
}

export const ProfileHeading = ({ profile, firstSharedAt }: ProfileHeadingProps) => {
  return (
    <div className="flex gap-4">
      <ProfilePicture profileId={profile.id} photoUrl={profile.photoUrl} readOnly>
        {({ imageUrl, onLoadError, className }) => (
          <div className="h-16 w-16 cursor-pointer rounded-full overflow-hidden place-self-start">
            <img
              src={imageUrl}
              className={classNames('rounded-lg', className)}
              onError={onLoadError}
            />
          </div>
        )}
      </ProfilePicture>
      <div className="flex flex-col justify-center">
        <Text size="text-xl" weight="font-medium">
          {profile.fullName}
        </Text>
        {profile.lastWorkExperience && (
          <div className="flex gap-1">
            <Text className="text-neutral-dark">{profile.lastWorkExperience?.title}</Text>
          </div>
        )}

        <SharingDate sharedDate={firstSharedAt} />
      </div>
    </div>
  )
}
