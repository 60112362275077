import { countryShape } from 'shared/components/atoms'
import { CreateSeekerRecruiterValues } from 'modules/recruiter'
import { AnySchema, boolean, object } from 'yup'

export { SeekerOnboarding } from './SeekerOnboarding'

export const buildSchema = (isCommunicationAllowed: boolean) =>
  object({
    location: countryShape,
    USTaxPayer: boolean(),
    isTosAccepted: boolean().isTrue(),
    isCommunicationAccepted: !isCommunicationAllowed && boolean().isTrue(),
  } as Record<keyof CreateSeekerRecruiterValues, AnySchema>)
