import { Agreements } from 'account/types'
import { useNetwork } from 'shared/hooks'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

const nullAgreement: Agreements = { content: '', statement: '', id: '' }

export const useLastAcceptedAgreement = () => {
  const { get } = useNetwork()

  const { data } = useQuery(
    'agreements/latest-accepted',
    async () => await get<Agreements>('agreements/latest-accepted').then(({ data }) => data),
    {
      retry: 3,
    },
  )

  const agreement = useMemo(() => data || nullAgreement, [data, nullAgreement])

  return {
    agreement,
  }
}
