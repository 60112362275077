import { HiringMode } from 'shared/hooks'
import { CompanySize } from '../../company/enums'
import { CompanySettings } from './company-settings.domain'
import { Employee } from './employee.domain'
import { Invite } from './invite.domain'
import { Teammate } from './teammate.domain'

export class Company {
  id: number
  name: string
  website: string
  approved: boolean
  employees: Array<Employee>
  invites: Array<Invite>
  settings: CompanySettings
  marketType: {
    id: number
    label: string
  } | null
  companySize: CompanySize | null
  hiringMode: HiringMode

  get teammates(): Array<Teammate> {
    return this.employees
      .map(({ email, role }) => ({ email, role, accepted: true }))
      .concat(this.invites.map(({ email, role }) => ({ email, role, accepted: false })))
  }

  get normalizedWebsite() {
    return this.website?.replace('http://', '').replace('https://', '').replace('www.', '')
  }
}

export class CompanyBasicInfo {
  id: number
  name: string
  website: string
  approved: boolean
  activeListingsCount: number
  usersCount: number
}
