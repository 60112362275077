import { Bar } from 'shared/components/atoms'
import { SliderOption } from 'shared/components/atoms'
import { useMemo } from 'react'

export const useComputedBars = (
  bars: Array<Pick<Bar, 'heightPercentage'>>,
  options: Array<SliderOption>,
  minValue: number,
  maxValue: number,
) => {
  const computedBars = useMemo<Array<Bar>>(() => {
    const minValueIndex = options.findIndex(({ value }) => minValue === value)
    const maxValueIndex = options.findIndex(({ value }) => maxValue === value)

    return bars.map(({ heightPercentage }, index) => {
      const barPosition = (index / bars.length) * 100
      const minSliderPosition = (minValueIndex / options.length) * 100
      const maxSliderPosition = Math.floor((maxValueIndex / options.length) * 100)

      return {
        heightPercentage,
        color:
          barPosition >= minSliderPosition && barPosition <= maxSliderPosition
            ? 'bg-neutral-darker'
            : 'bg-neutral-lightest',
      }
    })
  }, [bars, minValue, maxValue])

  return computedBars
}
