import { PreviewLabel } from 'shared/components/atoms'
import { buildNoticePeriodLabel } from 'components/molecules/NoticePeriod'
import { Tags } from 'shared/components/atoms'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'

interface PublicPreferencesProps {
  profile: ProfileOverview
}

export const PublicPreferences = ({ profile }: PublicPreferencesProps) => {
  const {
    noticePeriodDays,
    longTerm,
    shortTerm,
    minFreelancingHoursPerWeek,
    maxFreelancingHoursPerWeek,
  } = profile.preferences || {}

  const noticePeriodAvailable = typeof noticePeriodDays === 'number'
  const readyToStartTags = [
    { id: 1, visible: noticePeriodAvailable, label: buildNoticePeriodLabel(noticePeriodDays) },
    { id: 2, visible: !noticePeriodAvailable, label: 'Not informed' },
  ].filter((tag) => tag.visible)

  const openToTags = [
    { id: 1, visible: longTerm, label: 'Long-term' },
    {
      id: 2,
      visible: shortTerm && minFreelancingHoursPerWeek && maxFreelancingHoursPerWeek,
      label: `Short-term (${minFreelancingHoursPerWeek} - ${maxFreelancingHoursPerWeek}h/week)`,
    },
    { id: 3, visible: !longTerm && !shortTerm, label: 'Not informed' },
  ].filter((tag) => tag.visible)

  return (
    <div className="flex flex-col gap-8 print:hidden lg:w-[264px]">
      <PreviewLabel title="Ready to start" disabled>
        <Tags values={readyToStartTags} background="bg-neutral-lightest" isReadOnly />
      </PreviewLabel>

      <PreviewLabel title="Open to" disabled>
        <Tags values={openToTags} background="bg-neutral-lightest" isReadOnly />
      </PreviewLabel>
    </div>
  )
}
