import { TestimonialCard } from './TestimonialCard'

export const Testimonials = () => {
  const testimonials: Array<{ name: string; role: string; text: string; image: string }> = [
    {
      name: 'Lucas B.',
      role: 'Mobile Software Developer',
      text: '“Strider offers real international opportunities. They give real attention to the people. They provide real support. They ask for real feedback. They are real people. I am thankful for everything, and I hope this partnership stays for years!',
      image: 'images/signup/job-seekers/lucas-b.png',
    },
    {
      name: 'Felipe S.',
      role: 'Front-End Software Developer',
      text: "“Strider's approach to job applications is refreshingly effective. The team's communication was clear and professional throughout the process. If you're aiming for a top-notch job abroad with a competitive USD salary, Strider is worth a shot!",
      image: 'images/signup/job-seekers/felipe-s.png',
    },
    {
      name: 'Ginevra H.',
      role: 'Product Designer',
      text: "“I was so impressed with the way they do what they promise: they landed me a new job within a week. Every person I talked to was extremely professional. Strider is truly incredible it's almost too good to be true, but it is!",
      image: 'images/signup/job-seekers/ginevra-h.png',
    },
  ]

  return (
    <section className="flex animate-swipe">
      {testimonials.map(({ name, image, role, text }, i) => (
        <TestimonialCard key={`${name}-${i}`} name={name} image={image} role={role} text={text} />
      ))}
    </section>
  )
}
