import { useDemoMode } from '../DemoModeToggler/useDemoMode'

export const AvaiabilityIcon = () => {
  const { active } = useDemoMode()

  if (!active) return null

  return (
    <span
      className="
      h-3 w-3 bg-brand-medium border-neutral-darker border-2 
      rounded-full box-content absolute bottom-0 right-0"
    />
  )
}
