export enum DisqualifyReviewedReasons {
  LackOfExperience = 'Lack of experience with desired technologies',
  InsufficientSeniority = 'Insufficient seniority',
  InsufficientIndustryExperience = 'Insufficient industry-related experiences',
  Overqualified = 'Overqualified for the role',
  JobHopper = 'Switch jobs frequently',
  UnclearWorkExperiences = 'Unclear work experiences',
  Other = 'Other...',
}

export enum DisqualifyInterviewedReasons {
  OtherBetterFits = 'Other candidates better fit the role',
  CulturalMismatch = 'Cultural mismatch',
  CareerFit = 'Career fit',
  InsufficientCommunication = 'Insufficient communication abilities',
  SuperficialAnswers = 'Vague or superficial answers',
  InsufficientExperience = 'Insufficient skills/experience',
  InsufficientSeniority = 'Insufficient seniority',
  InsufficientIndustryExperience = 'Insufficient industry-related experiences',
  Other = 'Other...',
}
