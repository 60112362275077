import classNames from 'classnames'
import { Text } from 'shared/components/atoms'
import { cn } from 'modules/shared'

interface ReviewListingCardProps {
  title: string
  description: string | number | null
  warning?: boolean
  descriptionClassName?: string
  className?: string
}

export const ReviewListingCard = ({
  title,
  description,
  warning,
  descriptionClassName,
  className,
}: ReviewListingCardProps) => {
  return (
    <div
      className={cn(
        'w-full flex flex-col gap-1 p-4 sm:p-6 border border-neutral-lighter rounded-lg',
        {
          'bg-warning-lighter border-warning-light': warning,
        },
        className,
      )}
    >
      <Text
        size="text-sm"
        weight="font-normal"
        className={cn('text-neutral-dark', { 'text-warning-dark': warning })}
      >
        {title}
      </Text>
      <Text
        size="text-lg"
        weight="font-medium"
        className={classNames('text-neutral-darkest', descriptionClassName)}
      >
        {description}
      </Text>
    </div>
  )
}
