import { JobOpportunity } from 'job-opportunity/domain'
import { StringSchema, boolean, object } from 'yup'
import { MixedSchema } from 'yup/lib/mixed'
import { schemaBuilder } from '../schemaBuilder'
import { usePersisJobOpportunityAnswers } from 'job-opportunity/exports'
import { WorkExperience } from 'modules/work-experience'
import { useProfileWorkExperiences } from 'hooks/profile'
import { Answer } from 'job-opportunity/domain/answer.domain'
import { SavedAnswer } from 'job-opportunity/hooks/usePersistJobOpportunityAnswers'
import { QuestionSchema } from 'screening/domain'
import { QuestionType } from 'screening/enums'

export interface WorkExperiencesDictionary {
  [key: string]: WorkExperience
}
export interface WorkExperienceSchema {
  id: string
  label: string
}

export interface AnswerFormSchema {
  value: Nullable<string | number>
  workExperiences: Array<WorkExperienceSchema>
  noExperience: Optional<boolean>
}

export interface AnswersDictionary {
  [index: string]: AnswerFormSchema
}

const getAnswersDictionary = (savedAnswers: Nullable<Array<SavedAnswer>>) => {
  return savedAnswers?.reduce((obj, { index, workExperiences, value, noExperience }) => {
    obj[index] = { value, workExperiences, noExperience }
    return obj
  }, {} as AnswersDictionary)
}

const getWorkExperiences = (
  workExperiences: Array<string>,
  workExperiencesDictionary: WorkExperiencesDictionary,
) => {
  return workExperiences
    .map((id) =>
      workExperiencesDictionary[id]
        ? {
            id: workExperiencesDictionary[id].id,
            label: workExperiencesDictionary[id].title,
          }
        : undefined,
    )
    .filter((workExperience): workExperience is WorkExperienceSchema => Boolean(workExperience))
}

const noExperience = (value: Nullable<string | number>, workExperiences: Array<string>) => {
  return !value && !workExperiences.length
}

export const getDefaultValue = (
  index: number,
  workExperiencesDictionary: WorkExperiencesDictionary,
  screeningAnswers: Array<Answer> | null,
  question: QuestionSchema,
  savedAnswersDictionary?: AnswersDictionary,
) => {
  const dictionaryIndex = `answers.${index}`
  const isNumeric = question.type == QuestionType.Numeric
  if (savedAnswersDictionary && savedAnswersDictionary[dictionaryIndex]) {
    const workExperiencesIds =
      savedAnswersDictionary[dictionaryIndex].workExperiences?.map(({ id }) => id) ?? []
    return {
      value: savedAnswersDictionary[dictionaryIndex].value,
      workExperiences: getWorkExperiences(workExperiencesIds, workExperiencesDictionary),
      noExperience: isNumeric
        ? noExperience(savedAnswersDictionary[dictionaryIndex].value, workExperiencesIds)
        : undefined,
    }
  }

  if (screeningAnswers) {
    return {
      value: screeningAnswers[index].value,
      workExperiences: getWorkExperiences(
        screeningAnswers[index].linkedWorkExperienceIds,
        workExperiencesDictionary,
      ),
      noExperience: isNumeric
        ? noExperience(
            screeningAnswers[index].value,
            screeningAnswers[index].linkedWorkExperienceIds,
          )
        : undefined,
    }
  }

  return { value: null, workExperiences: [], noExperience: isNumeric ? false : undefined }
}

export const useFormBuilder = (
  jobOpportunity: JobOpportunity,
  experiencesHistory: Array<WorkExperience>,
) => {
  let isLoading = true
  const { getAnswers } = usePersisJobOpportunityAnswers(jobOpportunity.screening.id)

  const {
    screeningAnswers,
    screening: { questions },
  } = jobOpportunity
  const schemaShape: { [name: string]: StringSchema | MixedSchema } = {}

  const savedAnswersDictionary = getAnswersDictionary(getAnswers())
  const workExperiencesDictionary = useProfileWorkExperiences(experiencesHistory)
  const defaultValues = {}
  questions.forEach((question, index) => {
    const name = String(index)
    const defaultValue = getDefaultValue(
      index,
      workExperiencesDictionary,
      screeningAnswers,
      question,
      savedAnswersDictionary,
    )

    Object.assign(schemaShape, schemaBuilder(name, question))
    Object.assign(defaultValues, { [name]: defaultValue })
  })

  const schema = object().shape({
    answers: object().shape(schemaShape),
    improvementsApplied: boolean(),
  })
  isLoading = false

  return { schema, defaultValues, isLoading }
}
