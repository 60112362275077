import { ProfileStatus } from 'modules/candidates/enums'
import { CompanySize } from 'company/enums'
import { Preferences } from 'modules/profiles/profile-preferences'

export { useAssessmentTags } from './useAssessmentTags'
export { useProfile } from './useProfile'
export { useFetchPreferences } from './useFetchPreferences'
export { useOverview } from './useOverview'
export * from './useProfileWorkExperiences'
export * from './useUpdateProfileAvailability'
export * from './useProfileAdvertisement'

export const nullPreferences: Preferences = {
  availability: ProfileStatus.Active,
  payRate: {
    openToNegotiation: false,
    monthlyRate: {
      min: undefined,
      max: undefined,
    },
    hourlyRate: {
      min: undefined,
      max: undefined,
    },
  },
  noticePeriod: null,
  jobTypes: {
    longTerm: false,
    shortTerm: false,
  },
  freelancingJobHours: {
    min: undefined,
    max: undefined,
  },
  notWillingToWorkIn: [],
  companySizes: {} as Record<CompanySize, 'ideal' | 'yes' | 'no'>,
  allowsAdvertiseProfile: false,
  isRevokingAdvertisementConsent: true,
  updated: false,
}
