import classNames from 'classnames'
import { Input, Label } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'

interface Props {
  name: string
  formControl: FormControl
  disabled?: boolean
  isOptional: boolean
  error: string
}

export const ShortTextQuestion = ({ name, formControl, disabled, isOptional, error }: Props) => {
  const { register, watch } = formControl
  const valueName = `${name}.value`
  const value = watch(valueName)

  return (
    <div>
      <Label htmlFor={valueName}>Provide your answer in a few words{isOptional ? '' : '*'}</Label>
      <Input
        name={valueName}
        register={register}
        maxLength={300}
        placeholder="I've worked with"
        disabled={disabled}
        enableErrorState
        customError={error}
        showFieldError={false}
        className={classNames({
          'bg-warning-lighter outline-warning-medium': Boolean(error && !value),
        })}
      />
    </div>
  )
}
