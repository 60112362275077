import { TrackingEvent } from '../tracking-event'

export class JobOpportunityInterestedClick implements TrackingEvent {
  name = 'Job opportunity interested click'

  constructor(private id: string) {}

  get payload() {
    return { id: this.id }
  }
}
