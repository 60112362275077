import classNames from 'classnames'
import styles from './Radio.module.scss'

interface RadioProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  name: string
  id?: string
  disabled?: boolean
  value?: string | number | boolean
  className?: string
}

const Radio = ({ register, name, disabled, id, value, className }: RadioProps) => {
  const [, fieldRegister] = register(name)

  return (
    <div className={classNames('inline-block mr-2', styles.custom__radio, className)}>
      <input
        type="radio"
        name={name}
        id={id}
        disabled={disabled}
        value={value}
        {...fieldRegister()}
      />
    </div>
  )
}

export { Radio }
