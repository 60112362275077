import { useRef, useState } from 'react'
import classNames from 'classnames'

import { PrimaryButton, SecondaryButton, TertiaryButton } from 'shared/components/atoms'
import { Text } from 'components/Text'

import { useMonitoring, useToast } from 'shared/hooks'
import { useAgreementUpload } from './hooks/useAgreementUpload'

import { AgreementUpload } from './domains/agreement-upload'

import { ReactComponent as DocumentIcon } from 'icons/agreement.svg'
import { ReactComponent as CloseIcon } from 'icons/close.svg'

interface AgreementsProps {
  onChange: (url: string | null) => void
  profileId: string
}

const defaultFileInfo = {
  name: 'Strider sample agreement',
  url: process.env.REACT_APP_DEFAULT_SAMPLE_AGREEMENT_URL,
  default: true,
}

export const Agreements = ({ onChange, profileId }: AgreementsProps) => {
  const [showOptions, setShowOptions] = useState(false)
  const [fileInfo, setFileInfo] = useState(defaultFileInfo)

  const { isUploading, uploadFile } = useAgreementUpload(profileId)
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleRemoveDefault = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    setShowOptions(true)
  }

  const handleUseDefault = () => {
    setShowOptions(false)
    onChange(null)
    setFileInfo(defaultFileInfo)
  }

  const handleUpload = () => fileInputRef.current?.click()

  const handleFileChange = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const document = new AgreementUpload(target)

      if (document.empty) return

      const { secure_url } = await uploadFile(document.file as Blob)

      setFileInfo({
        name: document.fileName,
        url: secure_url,
        default: false,
      })

      onChange(secure_url)
      setShowOptions(false)
    } catch (error: RequestError) {
      toastError('Could not upload your file: ' + error.message)

      captureException(error)
    }
  }

  const handleFileClick = () => {
    if (fileInfo.default) {
      window.open(fileInfo.url, '_blank')
    }
  }

  if (showOptions) {
    return (
      <>
        <Text className="mt-2 mb-6">
          Strider provides a sample agreement that you can use or upload your own.
        </Text>
        <div className="flex items-center gap-4">
          <PrimaryButton onClick={handleUseDefault}>
            Use Strider's provided sample agreement
          </PrimaryButton>
          <span>OR</span>
          <SecondaryButton onClick={handleUpload} isLoading={isUploading}>
            Upload your own
          </SecondaryButton>
        </div>
        <input
          ref={fileInputRef}
          type="file"
          accept=".pdf, .doc, .docx"
          id="audioFile"
          className="hidden w-0"
          onChange={handleFileChange}
        />
      </>
    )
  }

  return (
    <div
      className={classNames(
        'w-full py-2 px-4 mt-4',
        'flex flex-row justify-between items-center',
        'border border-neutral-lighter rounded-lg',
        'hover:cursor-pointer',
      )}
      onClick={handleFileClick}
    >
      <div className="flex flex-row items-center gap-4">
        <DocumentIcon className="w-8 h-8 p-1 stroke-brand-medium bg-brand-lighter rounded" />
        <Text size="small-body">{fileInfo.name}</Text>
      </div>
      <TertiaryButton
        icon={<CloseIcon className="w-6 h-6 stroke-neutral-dark" />}
        className="relative right-0"
        onClick={handleRemoveDefault}
      />
    </div>
  )
}
