import { useNetwork } from 'shared/hooks'

type AttachmentKey = 'photoUrl' | 'selfIntroductionAudioUrl'

export const useProfileAttachments = (profileId: string) => {
  const { post } = useNetwork()

  const handleAttachmentUpdate = (key: AttachmentKey) => async (response: Response) => {
    if (response.ok !== true) {
      throw response
    }

    const { secure_url } = await response.json()
    await post(`/profile/${profileId}/attachments`, { [key]: secure_url })
  }

  return { handleAttachmentUpdate }
}
