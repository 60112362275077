import { Paragraph, SecondaryButton, ToggleFormField } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'
import { Link } from 'shared/components/atoms'
import { Link as SelfLink } from 'react-router-dom'
import { Preferences } from 'modules/profiles/profile-preferences'

import { ReactComponent as CheckCircle } from 'icons/check-circle-outlined.svg'
import { ReactComponent as XCircle } from 'icons/x-circle-outlined.svg'
import { ReactComponent as Hourglass } from 'icons/hourglass-03.svg'
import { FormControl } from 'shared/hooks'

interface AllowAdvertisementProps {
  formControl: FormControl
  preferences: Preferences
}

export const AllowAdvertisement = ({ formControl, preferences }: AllowAdvertisementProps) => {
  const { updated, allowsAdvertiseProfile, isRevokingAdvertisementConsent } = preferences

  if (!updated && !allowsAdvertiseProfile) {
    return <FirstTimeAdvertisementConsent formControl={formControl} />
  }

  if (isRevokingAdvertisementConsent) {
    return <RevokingAdvertisementConsent />
  }

  if (updated && allowsAdvertiseProfile) {
    return <AdvertisementAllowed />
  }

  if (updated && !allowsAdvertiseProfile) {
    return <AdvertisementNotAllowed />
  }

  return null
}

const FirstTimeAdvertisementConsent = ({ formControl }: { formControl: FormControl }) => {
  return (
    <ContentWrapper>
      <ToggleFormField
        name="allowsAdvertiseProfile"
        formControl={formControl}
        label="Allow Strider to advertise my profile on the website"
        description={<AdvertisementBenefits allowed />}
      />
    </ContentWrapper>
  )
}

const AdvertisementAllowed = () => {
  return (
    <ContentWrapper className="mt-3 flex flex-col gap-4 items-start !flex-nowrap !mb-0">
      <div className="flex gap-2 items-center">
        <CheckCircle className="w-6 h-6 stroke-success-dark" />
        <Text weight="font-medium" className="text-neutral-darkest">
          You've allowed Strider to advertise your profile
        </Text>
      </div>

      <AdvertisementBenefits allowed />
      <ManageAccountSettings />
    </ContentWrapper>
  )
}

const AdvertisementNotAllowed = () => {
  return (
    <ContentWrapper className="mt-3 flex flex-col gap-4 items-start !flex-nowrap !mb-0">
      <div className="flex gap-2 items-center">
        <XCircle className="w-6 h-6 stroke-danger-dark" />
        <Text weight="font-medium">You've not allowed Strider to advertise your profile</Text>
      </div>

      <AdvertisementBenefits />
      <ManageAccountSettings />
    </ContentWrapper>
  )
}

const RevokingAdvertisementConsent = () => {
  return (
    <ContentWrapper className="mt-3 flex flex-col gap-4 items-start !flex-nowrap !mb-0">
      <div className="flex gap-2 items-center">
        <Hourglass className="w-4 h-4 stroke-warning-dark" />
        <Text weight="font-medium">
          You've sent a request to revoke Strider's permission to advertise your profile
        </Text>
      </div>

      <Paragraph className="text-neutral-dark">
        Strider may may take 2-3 weeks to process your request.
      </Paragraph>

      <AdvertisementBenefits />
      <ManageAccountSettings />
    </ContentWrapper>
  )
}
const AdvertisementBenefits = ({ allowed }: { allowed?: boolean }) => {
  const benefits = [
    'Increased visibility to potential employers',
    'A higher likelihood of receiving more job offers from a wider range of companies',
    'An advantage over candidates who have not opted to advertise their profiles',
  ]

  return (
    <div>
      {allowed ? (
        <Paragraph className="text-neutral-dark">
          Allowing Strider to advertise your profile means giving permission for Strider to use your
          profile data (such as your photo, role, and skills) to promote you to potential employers
          on <Link url="https://onstrider.com">www.onstrider.com</Link>
        </Paragraph>
      ) : (
        <Paragraph className="text-neutral-dark">
          If you allow Strider to advertise your profile, you give permission for Strider to use
          your profile data (such as your photo, role, and skills) to promote you to potential
          employers on <Link url="https://onstrider.com">www.onstrider.com</Link>
        </Paragraph>
      )}

      {allowed ? (
        <Paragraph weight="font-medium" className="text-neutral-dark mt-6">
          The benefits include:
        </Paragraph>
      ) : (
        <Paragraph weight="font-medium" className="text-neutral-dark mt-6">
          Consider allowing Strider to advertise your profile. The benefits include:
        </Paragraph>
      )}

      <ul className="text-inherit list-disc px-6 flex flex-col gap-2 mt-4">
        {benefits.map((benefit, index) => (
          <li key={`benefits-${index}`}>
            <Paragraph className="text-neutral-dark">{benefit}</Paragraph>
          </li>
        ))}
      </ul>
    </div>
  )
}

const ManageAccountSettings = () => {
  return (
    <div className="flex items-center gap-3 p-4 rounded-lg bg-info-lighter mt-2 w-full">
      <Paragraph>Control your advertising permissions on Account Settings</Paragraph>

      <SelfLink to="/account-settings">
        <SecondaryButton>Go to Account Settings</SecondaryButton>
      </SelfLink>
    </div>
  )
}
