import MD5 from 'crypto-js/md5'

interface Options {
  size?: number
  fallbackUrl?: string
}

export const useGravatar = () => {
  const buildHash = (email: string) => {
    return MD5(email).toString()
  }

  const getParameters = (options: Options) => {
    const { size, fallbackUrl } = options
    return [
      size ? `s=${size}` : '',
      fallbackUrl ? `d=${encodeURIComponent(fallbackUrl)}` : '',
    ].join('&')
  }

  const getAvatarLink = (email: string, options?: Options) => {
    return [
      'https://www.gravatar.com/avatar/',
      buildHash(email),
      `?${getParameters(options || {})}`,
    ].join('')
  }

  return { getAvatarLink }
}
