import { Text } from 'shared/components/atoms'
import { Paragraph } from 'shared/components/atoms'
import { QuestionSchema } from 'screening/domain'
import { QuestionType } from 'screening/enums'

interface Props {
  index: number
  question: QuestionSchema
}

export const QuestionItem = ({ index, question }: Props) => {
  const badge = (() => {
    if (question.type === QuestionType.Boolean) {
      return (
        <Text
          size="text-xs"
          weight="font-medium"
          className="text-info-dark px-2 py-1 bg-info-lighter rounded-[4px]"
        >
          Yes or No
        </Text>
      )
    }

    if (question.type === QuestionType.ShortText) {
      return (
        <Text
          size="text-xs"
          weight="font-medium"
          className="text-warning-dark px-2 py-1 bg-warning-lighter rounded-[4px]"
        >
          Short answer
        </Text>
      )
    }

    if (question.type === QuestionType.LongText) {
      return (
        <Text
          size="text-xs"
          weight="font-medium"
          className="text-warning-dark px-2 py-1 bg-warning-lighter rounded-[4px]"
        >
          Long answer
        </Text>
      )
    }

    return null
  })()

  return (
    <li className="flex flex-col gap-2">
      <div className="flex gap-2 items-start">
        <Text
          size="text-xs"
          weight="font-medium"
          className="text-neutral-dark px-2 py-1 bg-neutral-lightest rounded-[4px]"
        >
          {index + 1}
        </Text>
        {badge}
      </div>
      <Paragraph>{question.title}</Paragraph>
      <Paragraph size="body-xs" className="text-neutral-dark">
        {question.description}
      </Paragraph>
    </li>
  )
}
