import { Avatar, Text } from 'shared/components/atoms'
import { useGravatar } from 'shared/hooks'
import { ChangeLog } from 'candidates-search/domain'

interface ChangeLogAuthorProps {
  changeLog: ChangeLog
}

export const ChangeLogAuthor = ({ changeLog }: ChangeLogAuthorProps) => {
  const { getAvatarLink } = useGravatar()

  const showAvatar = Boolean(changeLog.author?.photoUrl || changeLog.author?.email)
  return (
    <div className="flex items-center gap-2">
      <Text size="text-xs" weight="font-normal" className="text-neutral-medium">
        Author:
      </Text>
      {showAvatar && (
        <Avatar
          photo={changeLog.author!.photoUrl || getAvatarLink(changeLog.author!.email)}
          text={changeLog.author!.email}
          size="xs"
          showIconWhenEmpty
        />
      )}
      <Text size="text-xs" weight="font-normal" className="text-neutral-darkest">
        {changeLog.author?.email || 'Automation'}
      </Text>
    </div>
  )
}
