import classNames from 'classnames'
import { RadioGroup, RadioGroupProps } from './RadioGroup'

export const ColumnsRadioGroup = (props: RadioGroupProps) => {
  const containerClassName = classNames(props.containerClassName, 'flex-col gap-3')
  const controlClassName = classNames(props.controlClassName, 'w-full !text-left')

  return (
    <RadioGroup
      {...props}
      containerClassName={containerClassName}
      controlClassName={controlClassName}
    />
  )
}
