import { useQuery } from 'react-query'

import { useMonitoring, useUnauthenticatedNetwork } from 'shared/hooks'
import { useState } from 'react'
import { useToast } from 'shared/hooks'

export const useSignUpLink = (id?: string | null) => {
  const [gone, setGone] = useState(false)
  const { get } = useUnauthenticatedNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { data, isLoading, isRefetching } = useQuery(
    ['sign-up-link', id],
    async () =>
      await get<{ id: string; companyName: string }>(`sign-up-links/${id}`).then(
        ({ data }) => data,
      ),
    {
      enabled: Boolean(id),
      staleTime: Infinity,
      retry: (failureCount, error: RequestError) =>
        failureCount <= 3 && ![404, 410].some((status) => status === error.response?.status),
      onError: (error: RequestError) => {
        if (error.response?.status === 410) return setGone(true)
        if (error.response?.status === 404) {
          toastError('Invalid link. Please reache out to your account executive for help.')
        }
        captureException(error)
      },
    },
  )

  return { signUpLink: data, isLoading: isLoading || isRefetching, gone }
}
