import { MatchingSimulation } from 'matching/exports'
import { useJobListingPreview } from 'job-listing/exports'
import { useProfileMatcher } from 'hooks/matchings'
import { PaymentModel } from 'job-listing/enums'
import { Candidate } from 'candidates-search/domain'

interface Props {
  jobListingId?: string
  candidate: Candidate
  compensation: number
  isValid: boolean
  showMinimumStatement?: boolean
}

export const Simulation = ({
  jobListingId,
  candidate,
  compensation,
  isValid,
  showMinimumStatement,
}: Props) => {
  const { listing } = useJobListingPreview(jobListingId)

  const candidateCompensation = listing?.hourlyRatesEnabled
    ? candidate.hourlyRate
    : candidate.monthlyRate

  const { simulation, isMissingParams, client } = useProfileMatcher(
    candidate.id,
    compensation || candidateCompensation.min,
    true,
  )

  return (
    <div className="mt-9">
      <MatchingSimulation
        client={client}
        isMissingParams={isMissingParams}
        isValid={isValid}
        minCompensation={candidateCompensation.min}
        maxCompensation={candidateCompensation.max}
        paymentModel={listing?.hourlyRatesEnabled ? PaymentModel.Hourly : PaymentModel.Monthly}
        simulation={simulation}
        showMinimumStatement={showMinimumStatement && !compensation}
      />
    </div>
  )
}
