import { useEffect } from 'react'
import { useTracking } from 'tracking'
import { JobOpportunitiesDropdownImpression } from 'tracking/events/job-opportunities'

export const JobOpportunitiesImpressionTracker = () => {
  const { trackEvent } = useTracking()

  useEffect(() => {
    trackEvent(new JobOpportunitiesDropdownImpression())
  }, [])

  return null
}
