import { lazy, Suspense } from 'react'
import classNames from 'classnames'
import { Text } from 'shared/components/atoms'
import { OrganizationIcon } from 'shared/components/molecules'
import { Tooltip } from 'shared/components/atoms'

import { Company } from 'job-listing/dtos'

const BuildingIcon = lazy(() =>
  import('icons/listings/building-06-outlined.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface OwnerLabelProps {
  company?: Company
  className?: string
}

export const OwnerLabel = ({ company, className }: OwnerLabelProps) => {
  if (!company) return null

  return (
    <Tooltip content="Client">
      <div className={classNames('flex items-center gap-2 shrink-0', className)}>
        <Suspense fallback={null}>
          <OrganizationIcon
            domain={company.website}
            icon={<BuildingIcon className="w-4 h-4 stroke-neutral-medium" />}
            size="sm"
          />
        </Suspense>

        <Text size="text-sm" weight="font-normal">
          {company.name}
        </Text>
      </div>
    </Tooltip>
  )
}
