import classNames from 'classnames'
import * as Dialog from '@radix-ui/react-dialog'

import { Title } from 'shared/components/atoms'
import { TertiaryButton } from 'shared/components/atoms'

import { ReactComponent as CloseIcon } from 'icons/close.svg'

interface OverlayProps {
  content: React.ReactElement
  title: string | JSX.Element
  handleClose(): void
  open?: boolean
  footer?: React.ReactElement
  showClose?: boolean
  titleClassName?: string
  titleContainerClassName?: string
  contentContainerClassName?: string
  containerClassName?: string
}

export const Overlay = ({
  title,
  content,
  footer,
  handleClose,
  open = true,
  showClose = true,
  titleClassName,
  titleContainerClassName,
  contentContainerClassName,
  containerClassName,
}: OverlayProps) => {
  return (
    <Dialog.Root open={open}>
      <Dialog.Overlay />
      <Dialog.Portal>
        <Dialog.Content
          className={classNames(
            'fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-30',
            'w-screen h-screen flex flex-col bg-white',
            containerClassName,
          )}
        >
          <div
            className={classNames(
              'flex items-center justify-between',
              'border-b border-neutral-lighter p-4 sm:px-10 sm:py-4',
              titleContainerClassName,
            )}
          >
            {typeof title === 'string' ? (
              <Title size="large-heading" className={titleClassName}>
                {title}
              </Title>
            ) : (
              title
            )}

            {showClose && (
              <TertiaryButton icon={<CloseIcon />} onClick={handleClose} showFocus={false} />
            )}
          </div>
          <div
            className={classNames(
              'w-full h-full flex justify-center overflow-auto',
              'p-6 sm:px-10 sm:py-4 relative',
              contentContainerClassName,
            )}
          >
            {content}
          </div>
          {Boolean(footer) && (
            <div className="flex items-center justify-center border-t border-neutral-lighter p-4">
              {footer}
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
