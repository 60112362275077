import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Spinner, Text } from 'shared/components/atoms'
import { SignUpForm } from 'authentication/components'

import { usePageTitle } from 'shared/hooks'

import { useSignUpLink } from './hooks/useSignUpLink'

export const JoinWorkspace = () => {
  usePageTitle('Join workspace')

  const { id } = useParams()
  const navigate = useNavigate()

  const { signUpLink, isLoading, gone } = useSignUpLink(id)

  useEffect(() => {
    if (gone) navigate('/welcome-link-expired')
  }, [gone])

  return (
    <main
      id="join-workspace"
      className="min-h-screen flex flex-col flex-grow justify-center items-center bg-white"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="relative flex flex-col justify-center">
          <SignUpForm redirectUrl={`/join-workspace/${id}`} centralized />
          {Boolean(signUpLink) && (
            <div
              className="
                flex flex-col w-full
                bg-white text-center items-center
                absolute -top-2 left-1/2 transform -translate-x-1/2
              "
            >
              <Text
                size="text-xl"
                weight="font-medium"
                className=" flex w-full bg-neutral-day items-center justify-center text-center h-[60px]"
              >
                Join {signUpLink!.companyName} on Strider
              </Text>
              <Text size="text-sm" weight="font-normal" className="text-neutral-dark max-w-sm">
                Register to access your workspace and review your job openings
              </Text>
            </div>
          )}
        </div>
      )}
    </main>
  )
}
