import { ProfileEventFilter, profileEventFilterLabels } from 'candidates-search/enums'
import { CheckboxGroupOption } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'

import { defaultProfileEvents } from '../../schema'
import { Filter } from '../Filter'
import { useEffect } from 'react'

interface ProfileEventsFilterProps {
  formControl: FormControl
}

export const ProfileEventsFilter = ({ formControl }: ProfileEventsFilterProps) => {
  const { setValue, watch } = formControl

  const handleClear = () => {
    setValue('profileEvents', defaultProfileEvents)
  }

  const noneSelected = watch('profileEventsNone')
  const someSelected = Object.values(watch('profileEvents')).some((value) => value)

  const options: Array<CheckboxGroupOption> = [
    {
      label: profileEventFilterLabels[ProfileEventFilter.ProfileUpdates],
      name: ProfileEventFilter.ProfileUpdates,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.AssessmentTags],
      name: ProfileEventFilter.AssessmentTags,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.Proficiencies],
      name: ProfileEventFilter.Proficiencies,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.RolesOfInterest],
      name: ProfileEventFilter.RolesOfInterest,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.MainSkills],
      name: ProfileEventFilter.MainSkills,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.WorkExperience],
      name: ProfileEventFilter.WorkExperience,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.PayRate],
      name: ProfileEventFilter.PayRate,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.EnglishCheck],
      name: ProfileEventFilter.EnglishCheck,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.STA],
      name: ProfileEventFilter.STA,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.Education],
      name: ProfileEventFilter.Education,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.Certification],
      name: ProfileEventFilter.Certification,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.Bio],
      name: ProfileEventFilter.Bio,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.PersonalInfo],
      name: ProfileEventFilter.PersonalInfo,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.Preferences],
      name: ProfileEventFilter.Preferences,
    },
    {
      label: profileEventFilterLabels[ProfileEventFilter.AdvertisementConsent],
      name: ProfileEventFilter.AdvertisementConsent,
    },
  ]

  useEffect(() => {
    if (noneSelected) {
      setValue('profileEvents', defaultProfileEvents)
    }
  }, [noneSelected])

  useEffect(() => {
    if (someSelected) {
      setValue('profileEventsNone', false)
    }
  }, [someSelected])

  return (
    <Filter
      text="Profile"
      formControl={formControl}
      name="profileEvents"
      options={options}
      onClear={handleClear}
      disabled={false}
      showNone
    />
  )
}
