import classNames from 'classnames'
import { Label, Textarea } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'

interface Props {
  name: string
  formControl: FormControl
  disabled?: boolean
  isOptional: boolean
  error: string
}

export const LongTextQuestion = ({ name, formControl, disabled, isOptional, error }: Props) => {
  const { register, watch } = formControl

  const valueName = `${name}.value`
  const value: string = watch(valueName)

  return (
    <div>
      <Label htmlFor={valueName}>Provide a detailed answer{isOptional ? '' : '*'}</Label>
      <Textarea
        id={valueName}
        name={valueName}
        register={register}
        maxLength={800}
        omitErrors
        placeholder="I've worked with"
        disabled={disabled}
        className={classNames({
          'bg-warning-lighter outline-warning-medium outline outline-2 outline-offset-[-2px]':
            Boolean(error && !value),
        })}
      />
      <Text>{value?.length ?? 0}/800</Text>
    </div>
  )
}
