import { useMonitoring, useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { useMutation, useQueryClient } from 'react-query'

export const useReferralLink = () => {
  const { post } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()
  const client = useQueryClient()

  const { mutateAsync: createLink, isLoading } = useMutation(
    (slug: string) => post('/referral-links', { slug }),
    {
      onSuccess: () => client.invalidateQueries('recruiter'),
      onError: (error: RequestError) => {
        toastError(error.response.data?.message)
        captureException(error)
      },
    },
  )

  const { mutateAsync: validate } = useMutation((slug: string) =>
    post('/referral-links/availability-checks', { slug }),
  )

  return {
    createLink,
    validate,
    isLoading,
  }
}
