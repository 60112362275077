import { cn } from 'modules/shared'
import React from 'react'

interface PlaceholderProps {
  children: React.ReactNode
  className?: string
}
export const Placeholder = ({ children, className }: PlaceholderProps) => {
  return (
    <div
      role="status"
      className={cn('flex flex-col gap-10 animate-pulse text-gray-200', className)}
    >
      {children}
      <span className="sr-only">Loading...</span>
    </div>
  )
}
