import { FetchScreeningDTO, FetchScreeningResponse } from 'screening/dtos'
import { useQuery } from 'react-query'
import { useNetwork, useToast } from 'shared/hooks'

export const useFetchActiveScreening = (jobListingId: string) => {
  const { get } = useNetwork()
  const { toastError } = useToast()
  const { data: screening, isLoading } = useQuery(
    [jobListingId, 'active-screening'],
    () =>
      get<FetchScreeningResponse>(`listings/${jobListingId}/screenings/active`).then(({ data }) =>
        new FetchScreeningDTO(data).toDomain(),
      ),
    {
      retry: (failureCount, error: RequestError) =>
        failureCount <= 3 && error.response?.status !== 404,
      enabled: Boolean(jobListingId),
      onError: (error: RequestError) => {
        if (error.response?.status === 404) return
        toastError('Failed to fetch active screening.')
      },
    },
  )

  return { screening, isLoading }
}
