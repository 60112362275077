import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { nullProfile } from './null-profile'

import { useCandidate } from './useCandidate'
import { useFetchPendingAccountDeletionRequest } from 'account/exports'
import { UserContext } from 'shared/hooks'
import { useNavigate } from 'react-router-dom'

export const CandidateContext = createContext({
  candidate: nullProfile,
  isLoading: false,
  isReloading: false,
  loadCandidate: (_id: string | null = null) => {},
  readOnly: true,
  refetch: () => {},
  isFirstLoading: false,
})

export const CandidateProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useContext(UserContext)
  const [candidateId, setCandidateId] = useState<null | string | undefined>()
  const { candidate, isLoading, isReloading, readOnly, refetch, isFirstLoading } =
    useCandidate(candidateId)
  const navigate = useNavigate()
  const { accountDeletionRequest } = useFetchPendingAccountDeletionRequest(
    user.accountId,
    user.isSeeker,
  )

  useEffect(() => {
    if (candidate && accountDeletionRequest?.pending) {
      navigate('/welcome-back')
    }
  }, [accountDeletionRequest, candidate])

  const handleLoadCandidate = (id: string | null = null) => setCandidateId(id)

  const value = {
    candidate,
    isLoading,
    loadCandidate: handleLoadCandidate,
    isReloading,
    readOnly,
    refetch,
    isFirstLoading,
  }

  return <CandidateContext.Provider value={value}>{children}</CandidateContext.Provider>
}
