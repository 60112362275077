import StepWizard, { StepWizardProps } from 'react-step-wizard'

import { cn } from 'modules/shared'

import './StepsWizard.scss'

export type StepsWizardInstance = {
  isActive: boolean
  currentStep: number
  totalSteps: number
  firstStep: () => void
  lastStep: () => void
  nextStep: () => void
  previousStep: () => void
  goToStep: (step: number) => void
  goToNamedStep: (step: string) => void
  hashKey?: string
  stepName?: string
  null?: boolean
}

const noop = (method: string) => () => {
  throw new Error('NOOP method invoked. ' + method)
}

export const NullStepsWizard: StepsWizardInstance = {
  null: true,
  isActive: false,
  currentStep: -1,
  totalSteps: 0,
  firstStep: noop('firstStep'),
  lastStep: noop('lastStep'),
  nextStep: noop('nextStep'),
  previousStep: noop('previousStep'),
  goToStep: noop('goToStep'),
  goToNamedStep: noop('goToNamedStep'),
}

interface Transitions {
  enterRight?: string
  enterLeft?: string
  exitRight?: string
  exitLeft?: string
  intro?: string
}

export const StepsWizard = ({
  children,
  className,
  onStepChange,
  initialStep,
  isLazyMount,
  instance,
  isHashEnabled,
  transitions,
}: {
  children: JSX.Element | JSX.Element[] | React.ReactElement
  className?: string
  onStepChange?: (stepChange: { previousStep: number; activeStep: number }) => void
  initialStep?: number
  isLazyMount?: boolean
  instance?: (wizard: StepsWizardInstance) => void
  isHashEnabled?: boolean
  transitions?: Transitions
}) => {
  return (
    <StepWizard
      // Casting required because the library definition is wrong
      instance={instance as (props: StepWizardProps) => void}
      isHashEnabled={isHashEnabled}
      isLazyMount={isLazyMount}
      initialStep={initialStep}
      onStepChange={onStepChange}
      className={cn('w-full', 'flex-grow flex flex-col justify-start items-center', className)}
      transitions={transitions}
    >
      {children}
    </StepWizard>
  )
}
