import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Autocomplete,
  Checkbox,
  FieldError,
  Input,
  Label,
  Paragraph,
  RichTextEditor,
  Suggestion,
  Text,
} from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'
import { Modal } from 'shared/components/molecules'
import { useAuth, useFavicon, useForm, useMonitoring, useToast, validWebsite } from 'shared/hooks'
import { WorkExperience as WorkExperienceType } from 'modules/work-experience'
import { useWorkExperience, useOrganization } from 'profile/hooks'
import { Stack } from 'pages/JobSeekerProfile/types'
import { ReactComponent as BuildingIcon } from 'icons/profile/building-06.svg'
import { ReactComponent as Icon } from 'icons/x-square.svg'

import { ManagementFields } from './ManagementFields'
import { Footer } from '../components/Footer'

import { emptyState, schema } from './'
import { SelectStacks } from 'profile/components'

interface WorkExperienceProps {
  profileId: string
  workExperience?: WorkExperienceType
  isEdit?: boolean
  handleClose(): void
  render?: ({
    onDelete,
    onSubmit,
    onClose,
    form,
  }: {
    onDelete: () => void
    onSubmit: () => Promise<void>
    onClose: () => void
    isUpserting: boolean
    isDeleting: boolean
    form: JSX.Element
  }) => JSX.Element
  fieldsToTriggerInitialWarning?: Array<keyof typeof emptyState>
  refetch?: boolean
}

const MAX_SKILLS = 12

export const WorkExperience = ({
  profileId,
  workExperience,
  isEdit,
  handleClose,
  render,
  fieldsToTriggerInitialWarning = [],
  refetch,
}: WorkExperienceProps) => {
  const {
    user: { isJobSeekerProfilesManager },
  } = useAuth()
  const { captureException } = useMonitoring()

  const { toastError } = useToast()

  const { organizations, isLoading: isOrganizationsLoading } = useOrganization(profileId)
  const {
    upsertWorkExperience,
    deleteWorkExperience,
    parseWorkExperienceToFormSchema,
    isUpserting,
    isDeleting,
  } = useWorkExperience({ profileId, refetch })
  const { getUrl } = useFavicon()
  const triggerInitialValidation = Boolean(fieldsToTriggerInitialWarning.length)

  const formControl = useForm({
    mode: triggerInitialValidation ? 'onChange' : 'onSubmit',
    schema,
    defaultValues: {
      technologies: [],
    },
  })
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    getError,
    watch,
    reset,
    trigger,
    submitCount,
    isDirty,
  } = formControl
  const [formVersion, setFormVersion] = useState(1)

  const warningOnly = submitCount === 0
  const technologies = watch('technologies') as Array<Stack>
  const isManagement = watch('isManagement') as boolean
  const isCurrently = watch('isCurrently') as boolean
  const startDate = watch('startDate') as string
  const endDate = watch('endDate') as string
  const companyId = watch('companyId') as string
  const companyName = watch('companyName') as string
  const companyWebsite = watch('companyWebsite') as string

  const organizationOptions = useMemo(
    () =>
      organizations.map(({ id, name, authorId, website }) => {
        const iconUrl = getUrl(website)

        return {
          id,
          label: name,
          description: website,
          icon: <img src={iconUrl} className="w-4 h-4 rounded-[4px]" />,
          authorId,
        } as Suggestion & {
          authorId?: number
        }
      }),
    [organizations],
  )

  const shouldShowCompanyWebsiteInput = useMemo(() => {
    const isNewCompany = Boolean(companyName) && !companyId
    const isUserAuthor =
      Boolean(companyId) &&
      Boolean(organizationOptions.find(({ id }) => id === companyId)?.authorId)
    return isNewCompany || isUserAuthor
  }, [companyId, companyName, organizationOptions])

  const companyInputIcon = useMemo(() => {
    const fallbackIcon = <BuildingIcon className="w-4 h-4 fill-neutral-medium" />
    if (!companyWebsite) return fallbackIcon

    const isWebsiteValid = validWebsite(companyWebsite)

    if (!isWebsiteValid) return fallbackIcon

    const iconUrl = getUrl(companyWebsite)
    return <img src={iconUrl} className="rounded-[4px]" />
  }, [companyWebsite])

  const handleCompanyChange = useCallback(
    async (value: Suggestion) => {
      const organization = organizations.find(({ id }) => id === value.id)
      const website = organization && organization.website

      setValue('companyId', value.id)
      setValue('companyName', value.label, { shouldValidate: true })
      setValue('companyWebsite', website ?? '')
    },
    [organizations],
  )

  const handleFormSubmission = (addAnother = false) => {
    return new Promise<void>((resolve, reject) => {
      const submit = handleSubmit(
        async () => {
          const values = getValues()

          await upsertWorkExperience({
            ...values,
            publishOrganization: shouldShowCompanyWebsiteInput || isJobSeekerProfilesManager,
          }).then(() => {
            if (addAnother) {
              setFormVersion((previous) => previous + 1)
              return reset(emptyState)
            }

            handleClose()
          })
          resolve()
        },
        (errors) => {
          if (render) reject(errors)
        },
      )

      if (companyId && !companyWebsite && !shouldShowCompanyWebsiteInput) {
        captureException(
          `Error on saving work experience for user ${profileId}. Company ${companyId} missing website.`,
        )
      }

      submit()
    })
  }

  const handleDelete = () => {
    if (!workExperience) return toastError('It was not possible to delete the work experience.')
    deleteWorkExperience(workExperience.id)
    workExperience.id = ''
    handleClose()
  }

  useEffect(() => {
    isCurrently && setValue('endDate', null)
  }, [isCurrently])

  useEffect(() => {
    if (endDate) trigger('endDate')
  }, [startDate, endDate])

  useEffect(() => {
    if (isEdit && workExperience) {
      reset(parseWorkExperienceToFormSchema(workExperience))

      if (triggerInitialValidation) {
        trigger()
      }
      return
    }

    reset(emptyState)
  }, [])

  const content = (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleFormSubmission(false)
      }}
      id="workExperienceForm"
      className="w-full p-1"
    >
      {isJobSeekerProfilesManager && shouldShowCompanyWebsiteInput && (
        <div className="flex gap-2 p-4 rounded text-sm bg-neutral-lightest">
          <Text size="text-sm" weight="font-normal" className="text-neutral-darkest">
            When saving this work experience the organization will be published to everyone
          </Text>
        </div>
      )}

      <ContentWrapper className="mt-4" isForm>
        <Label htmlFor="companyName">Company name</Label>
        <div className="flex flex-col">
          <Autocomplete
            id="companyName"
            allowCreation
            showHint={false}
            clearInputValue={false}
            register={register}
            watch={watch}
            name="companyName"
            suggestions={organizationOptions}
            handleAdd={handleCompanyChange}
            placeholder="Tell us the company name"
            inputIcon={companyInputIcon}
            autoFocus={formVersion > 1}
            matchDescription
            threshold={0.4}
            isLoading={isOrganizationsLoading}
          />
          {!shouldShowCompanyWebsiteInput && Boolean(companyWebsite) && (
            <Text size="text-sm" weight="font-normal" className="text-neutral-dark mt-2">
              {companyWebsite}
            </Text>
          )}
        </div>
        <FieldError message={getError('companyName')} />
      </ContentWrapper>
      {Boolean(companyId && !shouldShowCompanyWebsiteInput && getError('companyWebsite')) && (
        <div className="flex gap-3 bg-danger-lighter p-4 rounded-lg sm:mb-10 mb-8">
          <div>
            <Icon className="h-6 w-6 stroke-danger-medium" />
          </div>
          <div className="flex flex-col gap-1">
            <Text weight="font-medium">Something went wrong while saving this work experience</Text>
            <Text>
              Our team has been informed and is working on a solution. In the meantime, you can
              continue adding other experiences
            </Text>
          </div>
        </div>
      )}
      {shouldShowCompanyWebsiteInput && (
        <ContentWrapper isForm>
          <Label htmlFor="companyWebsite">Company website</Label>
          <Input
            register={register}
            name="companyWebsite"
            placeholder="e.g. www.google.com"
            warningOnly={warningOnly}
            errorStyles={warningOnly ? 'warning' : 'danger'}
          >
            <Paragraph weight="font-normal" size="body-sm" className="text-neutral-dark mt-2">
              We will use the website favicon to generate the work experience thumbnail.
            </Paragraph>
          </Input>
        </ContentWrapper>
      )}
      <ContentWrapper>
        <Label htmlFor="title">Title</Label>
        <Input register={register} name="title" placeholder="What was your role here?" />
        <Label htmlFor="isManagement" className="mb-0" weight="font-normal">
          <Checkbox register={register} name="isManagement" id="isManagement" /> This is a people
          management position
        </Label>
        <ManagementFields
          isVisible={isManagement}
          getValues={getValues}
          register={register}
          setValue={setValue}
          watch={watch}
          getError={getError}
          isNew={!isEdit}
        />
      </ContentWrapper>
      <ContentWrapper className="!mb-5">
        <SelectStacks
          formControl={formControl}
          name="technologies"
          label="What are your main skills?"
          selectedOptions={technologies}
          allowCreation
          warningOnly={warningOnly}
          containerClassName="mb-2"
        />
        {Boolean(technologies.length) && (
          <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
            {technologies.length} of {MAX_SKILLS} skills
          </Text>
        )}
      </ContentWrapper>
      <ContentWrapper isForm>
        <Label htmlFor="location">Company location</Label>
        <Input
          register={register}
          name="location"
          placeholder="e.g. Rio de Janeiro"
          warningOnly={warningOnly}
          errorStyles={warningOnly ? 'warning' : 'danger'}
        />
        <ContentWrapper>
          <Label htmlFor="isRemote" className="mb-0" weight="font-normal">
            <Checkbox register={register} name="isRemote" id="isRemote" /> This is a remote position
          </Label>
        </ContentWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <div className="grid grid-cols-2 md:inline-flex sm:gap-6 w-full">
          <div className="w-full">
            <Label htmlFor="startDate">Start date</Label>
            <Input register={register} name="startDate" placeholder="Select a date" type="date" />
          </div>
          {!isCurrently && (
            <div className="w-full">
              <Label htmlFor="endDate">End date</Label>
              <Input register={register} name="endDate" placeholder="Select a date" type="date" />
            </div>
          )}
        </div>
        <Label htmlFor="isCurrently" className="mb-0" weight="font-normal">
          <Checkbox register={register} name="isCurrently" id="isCurrently" /> I currently work in
          this role
        </Label>
      </ContentWrapper>
      <ContentWrapper className="!mb-0">
        <Label htmlFor="description">Description</Label>
        <RichTextEditor
          ariaLabel="Description"
          error={getError('description')}
          name="description"
          setValue={setValue}
          defaultValue={workExperience?.description}
          warningOnly={warningOnly}
        >
          <Paragraph weight="font-normal" size="body-sm" className="text-neutral-dark mt-2">
            Describe your main activities and the results achieved in this position in two or three
            short paragraphs. Be objective and format to make it easier for companies to read when
            viewing your profile.
          </Paragraph>
        </RichTextEditor>
      </ContentWrapper>
    </form>
  )

  if (render) {
    return render({
      onDelete: handleDelete,
      onSubmit: handleFormSubmission,
      form: content,
      onClose: handleClose,
      isUpserting,
      isDeleting,
    })
  }

  return (
    <Modal
      handleClose={handleClose}
      showDismissalConfirmation={isDirty}
      title={isEdit ? 'Edit work experience' : 'Add work experience'}
      content={content}
      key={formVersion}
      footer={
        <Footer
          isEdit={isEdit}
          onSubmit={handleFormSubmission}
          handleClose={handleClose}
          handleDelete={handleDelete}
          form="workExperienceForm"
          deleteButtonLabel="Delete work experience"
          isLoading={isUpserting}
          isDeleting={isDeleting}
        />
      }
    />
  )
}
