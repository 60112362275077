import classNames from 'classnames'

import { Text } from 'components/Text'
import { Tooltip } from 'shared/components/atoms'

import { useFormatting } from 'shared/hooks'

import { ReactComponent as AlertIcon } from 'icons/alert-circle.svg'

const DateTagWrapper = ({
  isWarning,
  className,
  children,
}: {
  isWarning: boolean
  className: string
  children: React.ReactNode
}) => {
  if (isWarning)
    return (
      <Tooltip
        content="This date is earlier than the date informed by the candidate."
        className="z-50"
      >
        <div className={className}>
          {children}
          <AlertIcon />
        </div>
      </Tooltip>
    )

  return <div className={className}>{children}</div>
}

export const DateTag = ({ date, noticeDays }: { date: Date; noticeDays: number }) => {
  const { formatDate } = useFormatting()

  const noticeDate = new Date()
  noticeDate.setDate(noticeDate.getDate() + noticeDays)

  const isWarning = date < noticeDate

  return (
    <DateTagWrapper
      isWarning={isWarning}
      className={classNames(
        'px-3 py-2 rounded cursor-default',
        'flex flex-row gap-2 items-center',
        {
          'bg-neutral-lightest': !isWarning,
          'bg-warning-lighter': isWarning,
        },
      )}
    >
      <Text size="small-body">{formatDate({ date })}</Text>
    </DateTagWrapper>
  )
}
