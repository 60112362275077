import { Type } from 'class-transformer'
import { EnglishLevel } from 'vetting/enums'
import { selfEvaluationLabel } from 'modules/candidates/enums'
import {
  EnglishResult,
  ReviewStatus,
  VideoRecordingSubject,
  englishResultLabel,
} from 'vetting/enums'
import { EvaluationAnswers } from './evaluation-answers.domain'

export class Recorder {
  id: string
  firstName: string
  photoUrl: string
  declaredEnglishLevel: EnglishLevel

  get declaredEnglishLevelLabel() {
    return selfEvaluationLabel[this.declaredEnglishLevel]
  }
}

export class EnglishPackageEvaluation {
  id: string
  status: ReviewStatus
  interruptible: boolean
  grammarScore: number
  fluencyScore: number
  vocabularyScore: number
  pronunciationScore: number
  level: EnglishResult
  higherDeclaredEnglishLevel: boolean
  reviewedAt: string

  videos: Array<{ embeddingUrl: string; subject: VideoRecordingSubject }>
  computedIssues: Array<{ label: string; description: string | null }>

  @Type(() => EvaluationAnswers)
  answers: EvaluationAnswers

  @Type(() => Recorder)
  recorder: Recorder

  get levelLabel() {
    return englishResultLabel[this.level]
  }
}
