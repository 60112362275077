import { Fragment, useState } from 'react'

import { Text } from 'shared/components/atoms'
import { ProgressBar } from 'shared/components/atoms'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { ProgressRule } from './components/ProgressRule'
import { ProfileSections } from 'modules/profiles/enums'
import { ProfilePictureInstructions } from '../ProfilePictureInstructions'
import { ProfilePicture } from '../ProfileHeader/ProfilePicture'
import { ExperiencesReviewer } from '../ImportingButton/components/ExperiencesReviewer'
import { ImportingModal } from '../ImportingButton/ImportingModal'

interface ProgressIndicatorProps {
  readOnly: boolean
  profile: ProfileOverview
  onAddWorkExperienceClick: () => void
  onAddEducationClick: () => void
  onEditBio: () => void
  onPreferencesClick: () => void
  onEditRolesAndSkillsClick: () => void
  onAboutEdit: () => void
  onPayRateClick: () => void
}

export const ProgressIndicator = ({
  profile,
  readOnly,
  onAddWorkExperienceClick,
  onAddEducationClick,
  onEditBio,
  onPreferencesClick,
  onEditRolesAndSkillsClick,
  onAboutEdit,
  onPayRateClick,
}: ProgressIndicatorProps) => {
  const {
    id,
    fulfillment: { percentage, rules },
  } = profile
  const complete = percentage === 100

  const [isExperienceReviewerOpen, setIsExperienceReviewerOpen] = useState(false)
  const [isImportingModalOpen, setIsImportingModalOpen] = useState(false)

  const handleCloseReviewer = () => setIsExperienceReviewerOpen(false)
  const handleCloseImporting = () => setIsImportingModalOpen(false)
  const handleAddExperienceManuallyClick = () => {
    setIsImportingModalOpen(false)
    onAddWorkExperienceClick()
  }

  const onClickFunctions = {
    [ProfileSections.BasicInfo]: onAboutEdit,
    [ProfileSections.Biography]: onEditBio,
    [ProfileSections.Education]: onAddEducationClick,
    [ProfileSections.Photo]: () => void 0,
    [ProfileSections.RolesAndSkills]: onEditRolesAndSkillsClick,
    [ProfileSections.WorkExperience]: (error?: boolean) =>
      error ? setIsExperienceReviewerOpen(true) : setIsImportingModalOpen(true),
    [ProfileSections.Preferences]: onPreferencesClick,
    [ProfileSections.PayRate]: onPayRateClick,
  }

  if (readOnly) return null

  return (
    <>
      <div className="bg-neutral-lightest p-6 rounded-lg mb-6 lg:mb-[50vh]">
        <header className="flex flex-rows items-center gap-2">
          <Text size="text-4xl" weight="font-normal">
            {percentage}%
          </Text>
          <Text size="text-base" weight="font-medium">
            complete
          </Text>
        </header>
        <ProgressBar
          progress={percentage}
          color={complete ? 'bg-success-medium' : 'bg-neutral-darker'}
        />
        <div className="mt-6">
          <ul className="flex flex-col gap-3">
            {rules.map((rule, index) => (
              <Fragment key={index}>
                {rule.name === ProfileSections.Photo && !rule.complete ? (
                  <ProfilePicture
                    profileId={profile.id}
                    photoUrl={profile.photoUrl}
                    readOnly={readOnly}
                  >
                    {({ onTriggered, onFileSet, isCropping }) => (
                      <ProfilePictureInstructions
                        onConfirm={onTriggered}
                        disabled={readOnly}
                        isCropping={isCropping}
                        onFileSet={onFileSet}
                      >
                        <ProgressRule
                          completed={rule.complete}
                          error={rule.error}
                          onClick={() => onClickFunctions[rule.name](rule.error)}
                        >
                          {rule.name}
                        </ProgressRule>
                      </ProfilePictureInstructions>
                    )}
                  </ProfilePicture>
                ) : (
                  <ProgressRule
                    completed={rule.complete}
                    error={rule.error}
                    onClick={() => onClickFunctions[rule.name](rule.error)}
                  >
                    {rule.name}
                  </ProgressRule>
                )}
              </Fragment>
            ))}
          </ul>
        </div>
      </div>
      {isExperienceReviewerOpen && (
        <ExperiencesReviewer profileId={id} onClose={handleCloseReviewer} isImporting={false} />
      )}
      <ImportingModal
        profileId={id}
        isOpen={isImportingModalOpen}
        onClose={handleCloseImporting}
        onAddWorkExperienceClick={handleAddExperienceManuallyClick}
      />
    </>
  )
}
