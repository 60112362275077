import { useNetwork } from 'shared/hooks'
import { useMutation, useQueryClient } from 'react-query'
import { Agreements } from 'account/types'

export const useAgreements = () => {
  const { post } = useNetwork()
  const client = useQueryClient()

  const { mutateAsync: acceptAgreement, isLoading: isAccepting } = useMutation(
    async (agreement: Agreements) => await post(`agreements/${agreement!.id}/acceptances`),
    {
      onSuccess: () => {
        client.invalidateQueries('pending-agreements')
        client.invalidateQueries('agreements/latest-accepted')
      },
    },
  )

  return { acceptAgreement, isAccepting }
}
