import { lazy, Suspense } from 'react'

import { Paragraph } from 'shared/components/atoms'
import { Spinner } from 'shared/components/atoms'
import { Title } from 'shared/components/atoms'

const Illustration = lazy(() =>
  import('icons/ilustrations/refer/treasure-chest.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

export const EmptyState = ({ isLoading }: { isLoading: boolean }) => {
  if (isLoading) return <Spinner />

  return (
    <div className="flex flex-col items-center justify-center gap-4 mt-24">
      <Suspense fallback={null}>
        <Illustration />
      </Suspense>
      <Title size="heading" color="text-neutral-darkest">
        Start referring candidates to see them here
      </Title>
      <Paragraph size="body-md" weight="font-normal" className="text-neutral-dark">
        Once you have referrals that sign up with your unique link, they'll show up here.
      </Paragraph>
    </div>
  )
}
