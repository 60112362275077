import { Avatar } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { ProfileFulfillmentBadge } from 'components/molecules/Badges'
import { Candidate } from 'candidates-search/domain'
import { LastExperienceLabel } from 'candidates-search/components'

interface Props {
  candidate: Candidate
}

export const Header = ({ candidate }: Props) => {
  return (
    <div className="flex gap-6">
      <Avatar photo={candidate.photoUrl} text={candidate.fullName} size="lg" className="w-16 h-16">
        <div className="absolute right-[-8px] bottom-0">
          <ProfileFulfillmentBadge status={candidate.fulfillmentStatus} />
        </div>
      </Avatar>
      <div className="flex flex-col gap-1">
        <div className="flex gap-4">
          <Text size="text-xl">{candidate.fullName}</Text>
        </div>
        <LastExperienceLabel candidate={candidate} />
      </div>
    </div>
  )
}
