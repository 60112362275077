import { Text } from 'components/Text'

export const MissingParamsWarning = () => {
  return (
    <div className="bg-neutral-lightest py-6 px-2 text-center rounded-lg">
      <Text color="text-neutral-dark" size="body">
        Select a company and job listing to see if the budget matches.
      </Text>
    </div>
  )
}
