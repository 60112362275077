import classNames from 'classnames'
import { HTMLAttributes } from 'react'

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  borderOnMobile?: boolean
  children: React.ReactNode
}

export const Card = ({ className, borderOnMobile, children, ...restProps }: CardProps) => {
  return (
    <div
      {...restProps}
      className={classNames('md:p-10 bg-neutral-day', className, {
        'border border-neutral-lighter rounded-xl p-6': borderOnMobile,
        'md:border md:border-neutral-lighter md:rounded-xl': !borderOnMobile,
      })}
    >
      {children}
    </div>
  )
}
