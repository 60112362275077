import { PreviewLabel } from 'shared/components/atoms'
import { buildNoticePeriodLabel } from 'components/molecules/NoticePeriod'
import { Tags } from 'shared/components/atoms'
import { Preferences } from 'modules/profiles/profile-preferences'

export const ReadyToStart = ({ preferences }: { preferences: Preferences }) => {
  const { noticePeriod } = preferences

  return (
    <PreviewLabel title="Ready to start" disabled id="ready-to-start">
      <Tags
        values={[
          {
            id: 0,
            label: buildNoticePeriodLabel(noticePeriod && noticePeriod * 7),
            className: noticePeriod === null ? 'text-neutral-dark' : undefined,
          },
        ]}
        background="bg-neutral-lightest"
        isReadOnly
      />
    </PreviewLabel>
  )
}
