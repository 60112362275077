import { AccountDeletionRequest } from 'account/domain'
import { useQuery } from 'react-query'
import { useMonitoring, useNetwork } from 'shared/hooks'

export const useFetchPendingAccountDeletionRequest = (
  accountId: string,
  isSeekerAccount: boolean,
) => {
  const { get } = useNetwork()
  const { captureException } = useMonitoring()

  const fetchAccountDeletionRequest = () =>
    get<AccountDeletionRequest>(`accounts/${accountId}/deletion-requests/scheduled`)
      .then(({ data }) => data)
      .catch((error: RequestError) => {
        if (error?.response?.status === 404) return null

        throw error
      })

  const { data: accountDeletionRequest, isLoading } = useQuery(
    ['account-deletion-request', accountId],
    fetchAccountDeletionRequest,
    {
      onError: (error: RequestError) => {
        captureException(error)
      },
      staleTime: 5 * 60 * 1000,
      enabled: Boolean(accountId && isSeekerAccount),
    },
  )

  return { accountDeletionRequest, isLoading, fetchAccountDeletionRequest }
}
