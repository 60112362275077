import classNames from 'classnames'
import { ReactNode, useState } from 'react'

export interface TextProps {
  children: ReactNode
  className?: string
  size?: 'large-body' | 'body' | 'small-body' | 'caption'
  // @todo - Remove this prop and pass colors through className since texts appears to have any kinda color
  color?:
    | 'text-neutral-day'
    | 'text-neutral-darkest'
    | 'text-neutral-dark'
    | 'text-neutral-medium'
    | 'text-brand-medium'
    | 'text-info-medium'
    | 'text-inherit'
    | 'text-warning-medium'
    | 'text-success-medium'
    | 'text-info-dark'
    | 'text-warning-dark'
    | 'text-neutral-day'
    | 'text-white'
    | 'text-success-darker'
    | 'text-neutral-darker'
    | 'text-danger-dark'
  weight?: 'font-normal' | 'font-medium' | 'font-medium'
  truncate?: boolean
  onClick?: () => void
}

export const Text = ({
  children,
  className,
  size = 'small-body',
  color = 'text-neutral-darkest',
  weight = 'font-normal',
  truncate = true,
  onClick,
}: TextProps) => {
  const [showReadMore, setShowReadMore] = useState(children?.toString().length! > 300)

  return (
    <div
      className={classNames(
        'font-inter tracking-[-0.02em] whitespace-pre-wrap',
        {
          'text-base md:text-xl leading-7 md:leading-8': size === 'large-body',
          'text-sm md:text-base leading-6 md:leading-6': size === 'body',
          'text-xs md:text-sm leading-5 md:leading-6': size === 'small-body',
          'text-[10px] md:text-xs leading-4 md:leading-5': size === 'caption',
        },
        color,
        weight,
        className,
      )}
      onClick={onClick}
    >
      {showReadMore && truncate ? (
        <>
          <p className="hidden print:block">{children}</p>
          <div className="relative line-clamp-3 text-ellipsis print:hidden">
            <p>{children}</p>
            <div
              onClick={() => setShowReadMore(false)}
              className="absolute right-0 bottom-0 bg-white cursor-pointer "
            >
              {'... '}
              <span className="text-inherit !text-neutral-darkest !font-medium">Read more</span>
            </div>
          </div>
        </>
      ) : (
        <p className="text-inherit overflow-hidden text-ellipsis">{children}</p>
      )}
    </div>
  )
}
