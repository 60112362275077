import * as RadixTabs from '@radix-ui/react-tabs'

interface TabContentProps {
  children: React.ReactNode
  value: string
  className?: string
}

export const TabContent = ({ children, value, className }: TabContentProps) => {
  return (
    <RadixTabs.Content value={value} className={className}>
      {children}
    </RadixTabs.Content>
  )
}
