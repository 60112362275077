import { ReactNode } from 'react'

interface CollectionProps {
  children: ReactNode
}

export const Collection = ({ children }: CollectionProps) => {
  return (
    <ul className="flex flex-col items-stretch rounded-lg border border-neutral-lighter mt-4">
      {children}
    </ul>
  )
}
