import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { useMutation, useQueryClient } from 'react-query'
import { useContext } from 'react'
import { CandidateContext } from 'contexts/candidate'
import { useMonitoring } from 'shared/hooks'

interface DeclinationFeedbackRequest {
  reasons: Array<string>
  otherReason?: string
  desiredPayRate?: number
  pausedDays?: number
  additionalNotes?: string
}
export interface DeclinationRequest {
  declinationFeedback: DeclinationFeedbackRequest
}

export const useDeclineJobOpportunity = (
  jobOpportunityId: string,
  screeningAssignmentId: string,
) => {
  const { post } = useNetwork()
  const { toastSuccess, toastError } = useToast()
  const { candidate } = useContext(CandidateContext)
  const client = useQueryClient()
  const { captureException } = useMonitoring()

  const { mutateAsync: decline, isLoading } = useMutation(
    async ({ declinationFeedback }: DeclinationRequest) =>
      post(`profile/${candidate.id}/screening-assignments/${screeningAssignmentId}/declinations`, {
        declinationFeedback,
      }),
    {
      onSuccess: () => {
        toastSuccess('Job opportunity feedback was submitted.')
        client.invalidateQueries('job-opportunities')
        client.invalidateQueries(`${candidate.id}-preferences`)
        client.invalidateQueries(['job-opportunity', jobOpportunityId])
      },
      onError: (err) => {
        toastError('Failed to submit the job opportunity feedback.')
        captureException(err)
      },
    },
  )

  return { decline, isLoading }
}
