import { FormControl } from 'shared/hooks'
import { cn } from 'modules/shared/class-names'

import { Toggle } from './Toggle'
import { Text } from '../../Text'
import { Label } from '../Label'
import { Paragraph } from '../../Paragraph'

interface ToggleFormFieldProps {
  name: string
  label: string
  description?: string | JSX.Element
  containerClassName?: string
  disabled?: boolean
  formControl: FormControl
  onClick?: () => void
}

export const ToggleFormField = ({
  name,
  label,
  description,
  containerClassName,
  disabled,
  formControl,
  onClick,
}: ToggleFormFieldProps) => {
  const { watch, setValue } = formControl

  return (
    <div className={cn('flex gap-4 py-2', containerClassName)} onClick={onClick}>
      <Toggle
        name={name}
        setValue={setValue}
        checked={watch(name)}
        showCheck
        ariaLabelledBy={`${name}Label`}
        disabled={disabled}
      />
      <Label htmlFor={name} className="flex flex-col gap-1 items-start">
        <Text weight="font-medium" className="text-neutral-darkest" id={`${name}Label`}>
          {label}
        </Text>
        {typeof description === 'string' && (
          <Paragraph className="text-neutral-dark">{description}</Paragraph>
        )}
        {typeof description !== 'string' && description}
      </Label>
    </div>
  )
}
