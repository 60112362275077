import { useFormatting } from 'shared/hooks'

interface RateRange {
  min?: number
  max?: number
}

interface Options {
  monthlySuffix?: '/ month' | 'mos'
  hourlySuffix?: '/ hour' | 'hr'
}

export const usePayRateTags = (
  {
    monthlyRate,
    hourlyRate,
  }: {
    monthlyRate: RateRange
    hourlyRate?: RateRange
  },
  options?: Options,
) => {
  const { formatCurrency } = useFormatting()
  const { monthlySuffix = '/ month', hourlySuffix = '/ hour' } = options || {}

  const payRateTags = [
    {
      id: 1,
      label: `${formatCurrency(monthlyRate.min!)} - ${formatCurrency(
        monthlyRate.max!,
      )} ${monthlySuffix}`,
      available: monthlyRate.min && monthlyRate.max,
    },
    {
      id: 2,
      label: `${formatCurrency(hourlyRate?.min ?? 0)} - ${formatCurrency(
        hourlyRate?.max ?? 0,
      )} ${hourlySuffix}`,
      available: hourlyRate?.min && hourlyRate.max,
    },
  ].filter(({ available }) => available)

  return payRateTags.length
    ? payRateTags
    : [{ id: 0, label: 'Not informed', className: 'text-neutral-dark' }]
}
