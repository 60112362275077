import { Text } from 'shared/components/atoms'
import { MatchingSkills, MissingSkills } from './components'

interface Props {
  isPreview: boolean
  matchingSkills?: Array<{ id: number; label: string }>
  missingSkills?: Array<{ id: number; label: string }>
}

export const Skills = ({ matchingSkills, missingSkills, isPreview }: Props) => {
  return (
    <div className="flex flex-col gap-y-6 mb-12">
      <Text size="text-base" weight="font-medium">
        Skills related to this position
      </Text>

      {!matchingSkills?.length && !missingSkills?.length ? (
        <Text>Not provided</Text>
      ) : (
        <>
          {Boolean(matchingSkills?.length) && <MatchingSkills skills={matchingSkills || []} />}
          {Boolean(missingSkills?.length) && (
            <MissingSkills skills={missingSkills || []} isPreview={isPreview} />
          )}
        </>
      )}

      {!isPreview && (
        <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
          You can include additional skills to your profile after the application.
        </Text>
      )}
    </div>
  )
}
