import { AlertDialog } from 'shared/components/molecules'
import { TertiaryButton } from 'shared/components/atoms'
import { Paragraph } from 'shared/components/atoms'
import { useAuth } from 'shared/hooks'
import { Teammate } from 'company/domain'

interface RemoveAccessDialogProps {
  onDeleteCancel: () => void
  onDeleteConfirm: () => void
  teammate: Teammate
}

export const RemoveAccessDialog = ({
  onDeleteCancel,
  onDeleteConfirm,
  teammate,
}: RemoveAccessDialogProps) => {
  const {
    user: { company },
  } = useAuth()

  return (
    <AlertDialog
      isOpen
      title="Remove access"
      titleSize="large-heading"
      isCancellable={false}
      isDismissible
      onCancelClick={onDeleteCancel}
      description={
        <Paragraph size="body-md" weight="font-normal" className="text-neutral-darkest">
          After removing access, <span className="text-brand-medium">{teammate.email}</span> will no
          longer be able to access the {company?.name} workspace nor candidate profiles.
        </Paragraph>
      }
      customActions={
        <>
          <TertiaryButton onClick={onDeleteCancel}>Keep access</TertiaryButton>
          <TertiaryButton onClick={onDeleteConfirm}>
            <span className="text-danger-medium">Remove access</span>
          </TertiaryButton>
        </>
      }
    />
  )
}
