import { AnySchema, object, string } from 'yup'
import { FormValues } from '.'

export const buildSchema = (uniqueValidation: (email: string | undefined) => boolean) =>
  object().shape({
    email: string()
      .required('Email required')
      .email('Insert a valid email')
      .test('unique', 'Email already taken', uniqueValidation),
  } as Record<keyof FormValues, AnySchema>)
