import { ContentWrapper } from '../../../../components/ContentWrapper'
import { ProfileCertification } from 'pages/JobSeekerProfile/types'
import { Text } from 'components/Text'
import { Tags, Title } from 'shared/components/atoms'
import { InstitutionIcon } from 'components/InstitutionIcon'
import { ReactComponent as AwardIcon } from 'icons/award-02.svg'
import { UpsertButton } from '../UpsertButton'
import { useFormatting } from 'shared/hooks'

interface CertificationRecordProps {
  readOnly: boolean
  certification: ProfileCertification
  onButtonClick(certification: ProfileCertification): void
  className?: string
}

export const CertificationRecord = ({
  readOnly,
  certification,
  onButtonClick,
  className,
}: CertificationRecordProps) => {
  const { formatDate } = useFormatting()

  const formatCertificationDate = (date: string) =>
    formatDate({
      date: new Date(date),
      month: 'short',
      year: 'numeric',
      timeZone: 'UTC',
      day: null,
    })

  const buildIssuedLabel = () => `Issued ${formatCertificationDate(certification.issueDate)} •`
  const label = `Certification: ${certification.name}`

  const buildExpiresLabel = () =>
    certification.expireDate
      ? `Expires in ${formatCertificationDate(certification.expireDate)}`
      : 'No Expiration Date'

  return (
    <ContentWrapper key={`education-${certification.id}`} className={className} ariaLabel={label}>
      <div className="flex gap-4">
        <div>
          <div className="flex items-center justify-center w-10 h-10 sm:w-12 sm:h-12 rounded-lg bg-neutral-lightest">
            <InstitutionIcon
              domain={certification.institution.website}
              icon={<AwardIcon className="stroke-neutral-medium w-6 h-6" />}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 flex-grow">
          <div className="flex flex-col gap-1">
            <div className="flex justify-between items-start gap-1">
              <div className="flex flex-col gap-1">
                <Title size="small-heading" className="sm:text-base">
                  {certification.name}
                </Title>
                <Text className="text-neutral-dark">{certification.institution.label}</Text>
              </div>

              <UpsertButton onClick={() => onButtonClick(certification)} readOnly={readOnly} />
            </div>

            <Text>
              {buildIssuedLabel()} {buildExpiresLabel()}
            </Text>
          </div>
          <Tags isReadOnly values={certification.stacks} />
        </div>
      </div>
    </ContentWrapper>
  )
}
