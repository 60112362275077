export const useBrowserURL = () => {
  const replaceCurrentURL = (path: string) =>
    window.history.replaceState(null, '', new URL(path, window.location.origin).href)

  const normalizeUrl = (url: string) => {
    const validProtocol = ['http', 'mailto'].some((protocol) => url?.startsWith(protocol))
    return validProtocol ? url : `https://${url}`
  }

  const transformToURL = (path: string) => {
    const newPath = normalizeUrl(path)
    try {
      return new URL(newPath)
    } catch {
      return null
    }
  }

  return { normalizeUrl, replaceCurrentURL, transformToURL }
}
