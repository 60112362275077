import { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { HiddenForDemoAccounts } from 'components/Access'
import {
  Checkbox,
  FieldError,
  Input,
  Label,
  Link,
  PrimaryButton,
  TertiaryButton,
  Textarea,
} from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'

import { HiringCost } from '../HiringCost'
import { ModalHeader } from '../ModalHeader'
import { Agreements } from '../../Agreements'
import { Wrapper } from '../../Wrapper'

import { useForm, HiringMode, useAuth } from 'shared/hooks'
import { useMakeOffer } from '../../hooks/useMakeOffer'
import { useHireLabel } from '../../hooks/useHireLabel'

import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { OfferFormValues } from 'modules/hires/domain'

import { schema } from '.'
import { buildDefaultValues } from '../../build-default-values'
import { StartDateField } from '../fields/StartDateField'
import { PaymentModel } from 'job-listing/enums'

export const RegularOfferForm = ({
  matchingId,
  hiringCost,
  paymentModel,
  profile,
  isOpen,
  onClose,
}: {
  matchingId: string
  hiringCost: number
  paymentModel: PaymentModel
  profile: ProfileOverview
  isOpen: boolean
  onClose: () => void
}) => {
  const navigate = useNavigate()

  const jobTitleRef = useRef<HTMLInputElement>(null)
  const { makeOffer, isRequesting } = useMakeOffer(matchingId, HiringMode.ThroughUs)
  const { label: hireLabel } = useHireLabel(profile.firstName)
  const {
    user: { company },
    supplementalTermsUrl,
    supplementalTermsLabel,
  } = useAuth()

  const defaultValues = buildDefaultValues(company?.name)

  const { register, setValue, handleSubmit, getValues, watch, getError, isValid, reset } = useForm({
    mode: 'onChange',
    schema,
    defaultValues,
  })

  const handleAgreementChange = (contractUrl: string | null) =>
    setValue('contractUrl', contractUrl, { shouldTouch: true })

  const handleNavigate = () => navigate('/offer-sent', { state: { firstName: profile.firstName } })

  const handleSubmitAttempt = handleSubmit(() => {
    const formValues = getValues() as OfferFormValues
    makeOffer(formValues).then(handleNavigate)
  })

  const handleClose = () => {
    onClose()
    reset()
  }

  useEffect(() => {
    jobTitleRef.current?.focus()
  }, [])

  const FormContent = (
    <form className="px-8" noValidate onSubmit={handleSubmitAttempt}>
      <Wrapper label="Job title" id="jobTitle" className="!mb-1">
        <Input register={register} name="jobTitle" id="jobTitle" ref={jobTitleRef} />
      </Wrapper>
      <Wrapper label="Preferred start date" id="preferredStartDates">
        <StartDateField
          name="preferredStartDates"
          getError={getError}
          profile={profile}
          setValue={setValue}
          watch={watch}
        />
      </Wrapper>
      <Wrapper label="Description of services" id="description" className="!mb-7">
        <Textarea register={register} name="description" maxLength={1000} />
      </Wrapper>
      <Wrapper title="Hiring agreement">
        <Agreements onChange={handleAgreementChange} profileId={profile.id} />
      </Wrapper>
      <HiddenForDemoAccounts>
        <HiringCost hiringCost={hiringCost} paymentModel={paymentModel} />
      </HiddenForDemoAccounts>
      <Label
        htmlFor="termsAccepted"
        size="text-sm"
        className="cursor-pointer mb-6"
        weight="font-normal"
      >
        <div className="flex items-center">
          <Checkbox register={register} name="termsAccepted" id="termsAccepted" />
          <span>
            I agree to the <Link url={supplementalTermsUrl}>{supplementalTermsLabel}</Link>
          </span>
        </div>
        <FieldError message={getError('termsAccepted')} />
      </Label>
      <div className="flex flex-col gap-4">
        <PrimaryButton
          className={classNames('w-full', { 'opacity-25': !isValid })}
          type="submit"
          isLoading={isRequesting}
        >
          {hireLabel}
        </PrimaryButton>
        <TertiaryButton className="w-full" onClick={handleClose}>
          Cancel
        </TertiaryButton>
      </div>
    </form>
  )

  return (
    <>
      <Modal
        open={isOpen}
        withBorders={false}
        showClose={false}
        titleClassName="mt-5 w-full text-center"
        title={<ModalHeader profile={profile} />}
        content={FormContent}
      />
    </>
  )
}
