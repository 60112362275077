import { useMonitoring } from 'shared/hooks'

const knownBrokenLinks = [
  // Claudia broken url (already raised, job is closed now)
  'dy5vbnN0cmlkZXIuY29tL2pvcy9zZW5pb3ItYmFjay1lbmQtZW5naW5lZXItcGVybC1mMzcwZGMxMz9yZWZlcnJhbD1jbGF1ZGlhYW50dW5lcw==5k',
  'c2VuaW9yLWZ1bGwtc3RhY2stZW5naW5lZXItdHlwZXNjcmlwdC1wb3N0Z3Jlc3FsLTc4ZmNhNWFhP3JlZmVycmFsPWNsYXVkaWFhbnR1bmVz)',
]

export const useReferralLink = () => {
  const { captureException } = useMonitoring()

  const decodeJobParam = (params: URLSearchParams) => {
    const job = params.get('job')

    if (!job || knownBrokenLinks.includes(job)) return null

    try {
      return `https://www.onstrider.com/jobs/${window.atob(job)}`
    } catch (error) {
      captureException(error)
    }

    return null
  }

  const createJobReferralUrl = (referrerLink: string, referrerSlug: string, jobUrl: string) => {
    const parts = jobUrl.split('?')
    const queryParams = new URLSearchParams(parts[1])
    queryParams.set('referral', referrerSlug)
    const normalizedUrl = `${parts[0]}?${queryParams.toString()}`

    return `${referrerLink}?job=${btoa(normalizedUrl)}`
  }

  return { decodeJobParam, createJobReferralUrl }
}
