import { useEffect } from 'react'
import { cn } from 'modules/shared'

import { PrimaryButton, Text } from 'shared/components/atoms'
import { OrganizationIcon } from 'shared/components/molecules'
import { DeadlineProgressBar, JobOpportunityBadge } from 'job-opportunity/exports'
import { JobOpportunity as JobOpportunityDomain } from 'job-opportunity/domain'
import { EngagementType } from 'job-listing/enums'
import { JobOpportunityStatus } from 'job-opportunity/enums'
import { useDate, useFormatting } from 'shared/hooks'

interface Props {
  jobOpportunity: JobOpportunityDomain
  pendingTask: boolean
  onClick: (id: string) => void
}

export const JobOpportunity = ({ jobOpportunity, pendingTask, onClick }: Props) => {
  const handleClick = () => {
    if (jobOpportunity.isApplication) return
    onClick(jobOpportunity.id)
  }

  const {
    jobDescription: { companyName, companyWebsite, engagementType },
    screening: { maximumPayRate, minimumPayRate },
    compensation,
  } = jobOpportunity

  const { formatCurrencyRange, formatCurrency } = useFormatting()

  const engagementTypeOptions = {
    [EngagementType.ShortTerm]: 'Short-term contract',
    [EngagementType.LongTerm]: 'Full-time contract',
  }

  const getEngagementTypeText = () => {
    let text = ''

    if (!engagementType) return text
    if (minimumPayRate || maximumPayRate || compensation) text += ' • '

    text += engagementTypeOptions[engagementType]
    return text
  }

  const buildCompensationLabel = () => {
    if (compensation) return formatCurrency(compensation)

    return formatCurrencyRange(minimumPayRate, maximumPayRate)
  }

  const { getDifferenceInSeconds } = useDate()
  const missingTime = Math.trunc(getDifferenceInSeconds(new Date(), jobOpportunity.deadlineAt))

  useEffect(() => {
    if (missingTime > 0 || jobOpportunity.status !== JobOpportunityStatus.Pending) return

    jobOpportunity.status = JobOpportunityStatus.Expired
  }, [missingTime])

  const noActionPending =
    jobOpportunity.isApplication && jobOpportunity.status !== JobOpportunityStatus.Closed

  const shouldFadeText = [
    JobOpportunityStatus.Closed,
    JobOpportunityStatus.NotInterested,
    JobOpportunityStatus.NotPicked,
  ].includes(jobOpportunity.status)

  return (
    <div
      className={cn({
        'hover:bg-neutral-lightest hover:rounded-t-xl cursor-pointer':
          !jobOpportunity.isApplication,
      })}
      onClick={handleClick}
    >
      <div className="inline-flex justify-between gap-4 xs:gap-6 p-4 sm:p-6 w-full">
        <div>
          <Text
            size="text-xl"
            weight="font-medium"
            className={cn({ 'text-neutral-medium': shouldFadeText })}
          >
            {jobOpportunity.jobDescription.title}
          </Text>
          {noActionPending && (
            <div className="mt-1">
              <Text size="text-sm" className="text-neutral-dark">
                No action pending
              </Text>
            </div>
          )}
          {jobOpportunity.status === JobOpportunityStatus.Pending ? (
            <DeadlineProgressBar jobOpportunity={jobOpportunity} now={new Date()} />
          ) : (
            <div className="mt-1">
              <Text
                size="text-sm"
                className={cn({
                  'text-neutral-dark': !shouldFadeText,
                  'text-neutral-medium': shouldFadeText,
                })}
              >
                {buildCompensationLabel()}
                {getEngagementTypeText()}
              </Text>
            </div>
          )}
          {companyName && companyWebsite && (
            <div className="flex gap-3 mt-3 items-center">
              <OrganizationIcon domain={companyWebsite} size="sm" />
              <Text weight="font-medium" size="text-sm">
                {companyName}
              </Text>
            </div>
          )}
        </div>
        <div className="flex items-center">
          {jobOpportunity.status === JobOpportunityStatus.Pending ? (
            <PrimaryButton onClick={handleClick}>View</PrimaryButton>
          ) : (
            <JobOpportunityBadge jobOpportunity={jobOpportunity} pendingTasks={pendingTask} />
          )}
        </div>
      </div>
    </div>
  )
}
