import { TrackingEvent } from '../tracking-event'
import { Location } from 'react-router-dom'

export class PageView implements TrackingEvent {
  name = 'Page view'

  constructor(private location: Location) {}

  get payload() {
    return {
      hash: this.location.hash,
      search: this.location.search,
    }
  }

  get url() {
    const { pathname, search, hash } = this.location

    return [pathname, hash, search].filter((slice) => slice).join('')
  }
}
