import Fuse from 'fuse.js'

interface FuseOptions {
  keys: Array<string>
  findAllMatches: boolean
  threshold: number
  shouldSort?: boolean
  includeScore?: boolean
  distance?: number
}

export const useFuzzySearch = <T>(items: Array<T>, options: FuseOptions) => {
  const fuse = new Fuse(items, options)

  const search = (input: string | null) =>
    input ? fuse.search(input).map(({ item, score }) => ({ ...item, score })) : items

  const checkExactMatch = (score?: number) => score !== undefined && Math.trunc(score * 1000) === 0

  return { search, checkExactMatch }
}
