import { useEffect, useState } from 'react'
import { useLocalStorage } from '../storage'

const vettedByEmailKey = 'cmp'
const recruiterLinkKey = 'rec'
const referrerSlugKey = 'referral'
const inviteIdKey = 'inviteId'

// @todo: consider refactoring to be generic and handle a params list
export const useOnboardingParamsTracker = () => {
  const [vettedByEmail, setVettedByEmail, clearVettedByEmail] = useLocalStorage(
    vettedByEmailKey,
    '',
  )
  const [recruiterLink, setRecruiterLink] = useLocalStorage(recruiterLinkKey, '')
  const [referrerSlug, setReferrerSlug] = useLocalStorage(referrerSlugKey, '')

  const [inviteId, setInviteId] = useState<string | null>(null)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const email = urlParams.get(vettedByEmailKey)
    setVettedByEmail((prev) => email || prev)

    const link = urlParams.get(recruiterLinkKey)
    setRecruiterLink((prev) => link || prev)

    const referrerSlug = urlParams.get(referrerSlugKey)
    setReferrerSlug((prev) => referrerSlug || prev)

    const inviteId = urlParams.get(inviteIdKey)
    setInviteId(inviteId)
  }, [])

  const reset = () => {
    clearVettedByEmail()
  }

  const handleReferrerSlugSetting = (slug: string | undefined) =>
    setReferrerSlug((previous) => slug || previous)

  return {
    vettedByEmail,
    recruiterLink,
    referrerSlug,
    inviteId,
    reset,
    setReferrerSlug: handleReferrerSlugSetting,
  }
}
