import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'modules/shared'

import { Text, Picture, SecondaryButton, Paragraph } from 'shared/components/atoms'
import { DeactivatedAccountBadge, ProfileStatusBadge } from 'components/molecules/Badges'

import { OpenToRoles } from './components/OpenToRoles'
import { PayRate } from './components/PayRate'
import { Card } from '../Card'

import { useDate } from 'shared/hooks'
import { useFetchPreferences } from 'hooks/profile'
import { useProfileAvailability } from 'hooks/profile/useProfileAvailability'

import { ProfilePreview as ProfilePreviewDomain } from 'modules/profiles'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'

interface Props {
  profile: ProfileOverview
}

export const ProfilePreview = ({ profile }: Props) => {
  const { getDifferenceInDays } = useDate()
  const { preferences } = useFetchPreferences(profile.id)
  const { isPaused } = useProfileAvailability(preferences?.availability)
  const days = getDifferenceInDays(preferences.pausedUntil)
  const { lastWorkExperience } = profile
  const lastExperience =
    lastWorkExperience &&
    `${lastWorkExperience.title} at ${lastWorkExperience.experienceOrganization.name}`

  const { photoUrl, roles, additionalRolesLabel } = useMemo(
    () => new ProfilePreviewDomain(profile),
    [profile],
  )

  return (
    <Card borderOnMobile>
      <div
        className={cn('flex flex-col items-center gap-1 md:flex-row md:items-start md:gap-6', {
          'opacity-70': isPaused,
        })}
      >
        <Picture photoUrl={photoUrl} size="xl" className="hidden md:block" />
        <Picture photoUrl={photoUrl} className="md:hidden !w-16 !h-16" />

        <div className="flex flex-col lg:flex-row gap-4 md:gap-6 flex-grow justify-between">
          <div className="flex-grow flex flex-col items-center md:items-start gap-4">
            <div className="text-center md:text-start">
              <Text size="text-2xl" weight="font-medium" className="text-neutral-darkest">
                {profile.firstName} {profile.lastName}
              </Text>
              {lastExperience && (
                <Paragraph weight="font-normal" size="body-sm" className="text-neutral-dark">
                  {lastExperience}
                </Paragraph>
              )}
            </div>
            {Boolean(preferences?.availability) &&
              (isPaused ? (
                <DeactivatedAccountBadge date={preferences.pausedUntil} />
              ) : (
                <ProfileStatusBadge
                  status={preferences.availability}
                  days={days}
                  withBackground
                  showIcon={false}
                  className="w-fit !py-1"
                />
              ))}
          </div>

          <div className="hidden md:flex flex-col gap-4">
            <OpenToRoles roles={roles} additionalRolesLabel={additionalRolesLabel} />
            <PayRate
              monthlyRate={preferences.payRate.monthlyRate}
              hourlyRate={preferences.payRate.hourlyRate}
            />
          </div>

          <Link to="/profile">
            <SecondaryButton className="w-full md:w-fit">View profile</SecondaryButton>
          </Link>
        </div>
      </div>
    </Card>
  )
}
