import {
  useMonitoring,
  useBrowserURL,
  useLandingTracking,
  useNetwork,
  useToast,
} from 'shared/hooks'

import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

export const useJobListingApplication = () => {
  const { toastError } = useToast()
  const { post } = useNetwork()
  const { getLandingLocationParams, resetLandingLocation } = useLandingTracking()
  const sessionStorageSearchParams = getLandingLocationParams()
  const { replaceCurrentURL } = useBrowserURL()
  const { captureException } = useMonitoring()
  const navigate = useNavigate()
  const client = useQueryClient()

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)

  const { searchParams } = new URL(window.location.href)

  const jobListing =
    searchParams.get('job_listing') ||
    (sessionStorageSearchParams.get('job_listing') ?? '').split('#')[0]

  const { mutateAsync: apply, isLoading } = useMutation(
    (externalJobListingId: string) =>
      post<{ id: string | null }>('/job-listing-applications', {
        externalJobListingId,
      }).then(({ data }) => {
        if (data.id) {
          navigate(`/job-opportunities/${data.id}`)
          return
        }

        client.invalidateQueries('job-opportunities')
        replaceCurrentURL('/')

        setIsFeedbackModalOpen(true)
      }),
    {
      onError: (error: RequestError) => {
        replaceCurrentURL('/')
        const status = error.response?.status ?? 0
        const messages: { [key: number]: string } = {
          422: error.response?.data?.message ?? 'A problem occurred while applying for the job.',
          409: "You've already applied for this job.",
          404: 'Job not found.',
          403: error.response?.data?.message ?? 'Job opportunity unavailable at your location.',
        }
        const message = messages[status]

        if (message) {
          toastError(message)
          return
        }

        captureException(error)
      },
      onSettled: () => {
        resetLandingLocation()
      },
      retry: (_, error: RequestError) =>
        ![404, 409, 400, 403, 422].includes(Number(error.response?.status)),
    },
  )

  const applyOnJobListing = () => {
    if (isLoading || !jobListing) return

    apply(jobListing)
  }

  const isJobApplicationFlow = () => Boolean(jobListing)

  return {
    isJobApplicationFlow,
    applyOnJobListing,
    jobListing,
    isFeedbackModalOpen,
    setIsFeedbackModalOpen,
  }
}
