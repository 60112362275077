import { ToggleFormField } from 'shared/components/atoms'
import { ListingInfo } from 'job-listing/domains'
import { FormControl } from 'shared/hooks'

interface EnableOfferMakingProps {
  listing: ListingInfo
  formControl: FormControl
}

const FT_HIRE_BUTTON_ALWAYS_VISIBLE = process.env.REACT_APP_FT_HIRE_BUTTON_ALWAYS_VISIBLE === 'true'

export const EnableOfferMaking = ({ listing, formControl }: EnableOfferMakingProps) => {
  const description = `The ${listing.company.name} members can make offer for any candidate linked to this job listing. Consider reviewing fees before doing so.`

  if (FT_HIRE_BUTTON_ALWAYS_VISIBLE) return null

  return (
    <ToggleFormField
      name="jobOffersEnabled"
      formControl={formControl}
      label="Enable offer making"
      description={description}
    />
  )
}
