export enum ChangeLogType {
  ProfileCreated = 'profile.created',
  ProfileCompleted = 'profile.completed',

  ProfilePreferencesUpserted = 'profile.preferences.upserted',
  ProfilePayRateUpserted = 'profile.pay-rate.upserted',
  ProfileInterestRolesUpserted = 'profile.interest-roles.upserted',
  ProfileMainSkillsUpserted = 'profile.main-skills.upserted',
  ProfileEducationUpserted = 'profile.education.upserted',
  ProfileEducationDeleted = 'profile.education.deleted',
  ProfileCertificationUpserted = 'profile.certification.upserted',
  ProfileCertificationDeleted = 'profile.certification.deleted',
  ProfileBioUpserted = 'profile.bio.upserted',
  ProfilePersonalInfoUpserted = 'profile.personal-info.upserted',
  ProfileWorkExperienceUpserted = 'profile.work-experience.upserted',
  ProfileWorkExperienceDeleted = 'profile.work-experience.deleted',
  ProfileAdvertisementConsentGranted = 'profile.advertisement-consent.granted',
  ProfileAdvertisementConsentRevokingRequested = 'profile.advertisement-consent-revoking.requested',
  ProfileAdvertisementConsentRevokingUndone = 'profile.advertisement-consent-revoking.undone',
  ProfileAdvertisementConsentRevoked = 'profile.advertisement-consent.revoked',

  EnglishPackageSubmitted = 'profile.english-package.submitted',
  EnglishPackageEvaluationUpserted = 'profile.english-package-evaluation.upserted',
  ProfileAssessmentTagsUpserted = 'profile.assessment-tags.upserted',
  ProfileProficienciesUpserted = 'profile.proficiencies.upserted',
  ProfileFulfillmentStatusUpdated = 'profile.fulfillment-status.updated',
  ProfileUpdatesAcknowledged = 'profile.updates.acknowledged',
  ProfileAssessmentEnabled = 'profile.assessment.enabled',
  ProfileAssessmentSubmitted = 'profile.assessment.submitted',
  ProfileAssessmentEvaluated = 'profile.assessment.evaluated',

  ScreeningAssigned = 'screening.assigned',
  ScreeningAssignedMailed = 'screening.assigned.mailed',
  ScreeningApplied = 'screening.applied',
  ScreeningMissed = 'screening.missed',
  ScreeningMissedMailed = 'screening.missed.mailed',
  ScreeningViewed = 'screening.viewed',
  ScreeningStarted = 'screening.started',
  ScreeningDeclined = 'screening.declined',
  ScreeningAnswered = 'screening.answered',
  ScreeningSubmitted = 'screening.submitted',
  MatchingUpserted = 'matching.upserted',
  MatchingFlagsUpserted = 'matching.flags.upserted',
  ScreeningApproved = 'screening.approved',
  ScreeningAwaitingReviewMailed = 'screening.awaiting-review.mailed',
  ScreeningRejected = 'screening.rejected',
  ScreeningRejectedMailed = 'screening.rejected.mailed',
  OpportunityUpserted = 'opportunity.upserted',
  OpportunityDeleted = 'opportunity.deleted',
  LeadDeleted = 'lead.deleted',
  LeadUpserted = 'lead.upserted',
  JobListingClosedMailed = 'job-listing.closed.mailed',
  MatchingRejectedMailed = 'matching.rejected.mailed',
}
