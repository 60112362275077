import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { Text } from 'shared/components/atoms'
import { SecondaryButton } from 'shared/components/atoms'
import { Task } from './Task'

import { ReactComponent as PlayIcon } from 'icons/play-square.svg'

import { useRecordedVideosCount } from 'vetting/exports'
import { CandidateContext } from 'contexts/candidate'
import {
  fewExperienceStartRecording,
  resumeRecordings,
  startRecording,
} from 'pages/HomePage/JobSeeker/consts'

export const EnglishVettingTask = () => {
  const navigate = useNavigate()
  const { candidate } = useContext(CandidateContext)
  const videosRecorded = useRecordedVideosCount(candidate)

  const isInProgress = videosRecorded > 0

  const getCopy = () => {
    if (isInProgress) return resumeRecordings

    if (
      candidate.beginnerEnglishSpeaker ||
      candidate.fewYearsOfExperience ||
      candidate.outOfBounds.byReferralCountry
    ) {
      return fewExperienceStartRecording
    }

    return startRecording
  }

  const handleClick = () => navigate('/english-vetting')

  return (
    <Task
      iconPath="images/camera.png"
      iconClassName="!bg-brand-lighter"
      title={isInProgress ? 'English check in progress' : 'English check'}
      description={getCopy()}
      cta={
        <div className="flex flex-row justify-start items-center gap-6">
          <SecondaryButton size="sm" onClick={handleClick}>
            {isInProgress ? 'Resume English check' : 'Check your English level'}
          </SecondaryButton>
          {isInProgress && (
            <div>
              <PlayIcon className="w-4 h-4 fill-warning-light inline mr-2" />
              <Text size="text-xs" weight="font-normal" className="text-warning-darker inline">
                {videosRecorded} of 2 videos uploaded
              </Text>
            </div>
          )}
        </div>
      }
    />
  )
}
