export enum EnglishResult {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
}

export const englishResultLabel: Record<EnglishResult, string> = {
  [EnglishResult.C2]: 'Proficient Speaker (C2)',
  [EnglishResult.C1]: 'Proficient Speaker (C1)',
  [EnglishResult.B2]: 'Independent Speaker (B2)',
  [EnglishResult.B1]: 'Independent Speaker (B1)',
  [EnglishResult.A2]: 'Basic Speaker (A2)',
  [EnglishResult.A1]: 'Basic Speaker (A1)',
}
