import { Suspense, useContext } from 'react'
import { Outlet } from 'react-router-dom'

import { LayoutContext } from 'contexts/layout'
import classNames from 'classnames'
import { Spinner } from 'shared/components/atoms'

export const SignedOutLayout = () => {
  const { shouldRootHavePadding } = useContext(LayoutContext)

  const fallbackSpinner = (
    <div className="h-screen w-screen bg-neutral-day">
      <Spinner />
    </div>
  )

  return (
    <main
      className={classNames('w-full flex flex-col', {
        'px-6 py-6 lg:px-16 lg:py-12 pb-20 sm:pb-6': shouldRootHavePadding,
        'p-0': !shouldRootHavePadding,
      })}
    >
      <Suspense fallback={fallbackSpinner}>
        <Outlet />
      </Suspense>
    </main>
  )
}
