import { CompanySize } from '../enums'

export const COMPANY_SIZE_EMPLOYEES_NUMBER = {
  [CompanySize.Micro]: '1-10',
  [CompanySize.Small]: '11-50',
  [CompanySize.Medium]: '51-250',
  [CompanySize.Large]: '251-500',
  [CompanySize.VeryLarge]: '501-1000',
  [CompanySize.Huge]: '1001+',
}
