import { ContractLength, EngagementType, PaymentModel } from 'job-listing/enums'
import { QuestionSchema, Screening } from '../domain'
import { JobDescription } from '../domain/job-description.domain'
import { ScreeningStatus } from '../enums'
import { EnglishResult } from 'vetting/enums'
import { CompanySize } from 'company/enums'
import { plainToInstance } from 'class-transformer'

interface JobDescriptionResponse {
  title: string
  marketType: string
  minimumYearsOfExperience: number
  minimumEnglishLevel: EnglishResult
  engagementType: EngagementType
  contractDuration: ContractLength
  workingHoursPerWeek: number
  requiredSkills: Array<{ id: number; label: string }>
  detailedRequirements: string
  projectDescription: string | undefined
  benefits: string
  processOverview: string | undefined
  companyName: string
  companyWebsite: string
  companySize: CompanySize
  hiringThroughUs: boolean
}

export interface FetchScreeningResponse {
  id: string
  title: string
  questions: Array<QuestionSchema>
  deadline: number | null
  minimumPayRate: number
  maximumPayRate: number
  paymentModel: PaymentModel | null
  jobDescription: JobDescriptionResponse | null
  status: ScreeningStatus
}

export class FetchScreeningDTO {
  constructor(private readonly response: FetchScreeningResponse) {}

  private get jobDescription(): JobDescription | null {
    const { jobDescription } = this.response
    if (!jobDescription) return null

    return plainToInstance(JobDescription, {
      title: jobDescription.title,
      engagementType: jobDescription.engagementType,
      contractDuration: jobDescription.contractDuration,
      workingHoursPerWeek: jobDescription.workingHoursPerWeek || 5,
      minimumTotalExperience: jobDescription.minimumYearsOfExperience,
      minimumEnglishLevel: jobDescription.minimumEnglishLevel,
      requiredSkills: jobDescription.requiredSkills,
      detailedRequirements: jobDescription.detailedRequirements,
      projectDescription: jobDescription.projectDescription,
      benefits: jobDescription.benefits,
      processOverview: jobDescription.processOverview,
      marketType: jobDescription.marketType,
      companyName: jobDescription.companyName,
      companyWebsite: jobDescription.companyWebsite,
      companySize: jobDescription.companySize,
      hiringThroughUs: jobDescription.hiringThroughUs ?? true,
    })
  }

  toDomain() {
    return new Screening({
      id: this.response.id,
      title: this.response.title,
      questions: this.response.questions,
      deadline: this.response.deadline,
      minimumPayRate: this.response.minimumPayRate,
      maximumPayRate: this.response.maximumPayRate,
      paymentModel: this.response.paymentModel,
      jobDescription: this.jobDescription,
      status: this.response.status,
    })
  }
}
