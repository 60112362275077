import { Expose, Type } from 'class-transformer'
import { Location } from 'modules/location'
import { WorkExperience } from 'modules/work-experience'
import { MatchingStatus } from 'matching/enums'
import { Stack } from 'modules/stack'

@Expose()
class Profile {
  id: string
  firstName: string
  lastName: string
  photoUrl: string
  yearlyRate: string
  noticePeriodDays: number

  @Type(() => Location)
  location: Location

  @Type(() => WorkExperience)
  experiencesHistory: Array<WorkExperience>

  @Type(() => WorkExperience)
  lastWorkExperience: WorkExperience

  get fullName() {
    return [this.firstName, this.lastName].filter((item) => item).join(' ')
  }

  get blurName() {
    return `${this.firstName} ${this.lastName[0]}.`
  }
}

@Expose()
class RejectionFeedback {
  reasons: Array<string>
  otherReason?: string
}

@Expose()
export class Matching {
  id: string

  status: MatchingStatus

  bookmarked: boolean

  @Type(() => Profile)
  profile: Profile

  stacks: Array<string>

  hiringCost?: number

  compensation?: number

  fee?: number

  notes: string | null

  @Type(() => RejectionFeedback)
  rejectionFeedback: Array<RejectionFeedback>

  updatedAt: string

  firstSharedAt: string | null

  briefing: string

  highlightedSkills: Optional<Array<Stack>>

  interviewed: boolean

  get rejectionNotes() {
    if (this.status === 'rejected') return this.notes

    return null
  }

  get canBeDeleted() {
    const statusesAllowedForDeletion = [
      MatchingStatus.Screening,
      MatchingStatus.ScreeningApproved,
      MatchingStatus.Saved,
      MatchingStatus.Shortlisted,
      MatchingStatus.NotAFit,
      MatchingStatus.Matched,
    ]

    const neverSharedWithClient = this.status === MatchingStatus.Unavailable && !this.firstSharedAt

    return statusesAllowedForDeletion.includes(this.status) || neverSharedWithClient
  }

  get hasBriefing() {
    return Boolean(this.briefing)
  }

  get pendingReview() {
    return this.status === MatchingStatus.Matched
  }

  transitToMatched() {
    this.status = MatchingStatus.Matched
  }

  transitToRejected() {
    this.status = MatchingStatus.Rejected
  }

  transitToInterviewRequested() {
    this.status = MatchingStatus.InterviewRequested
  }

  toggleSaved() {
    this.bookmarked = !this.bookmarked
  }
}
