import { useState } from 'react'

import { SecondaryButton, Text } from 'shared/components/atoms'
import { AlertDialog } from 'shared/components/molecules'

import { useUpsertMatching } from 'candidates-search/hooks'

import { MatchingStatus } from 'matching/enums'

interface Props {
  candidateId: string
  onShortlisted: () => void
}

export const ShortlistWithoutScreening = ({ candidateId, onShortlisted }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { upsertMatching, isUpserting } = useUpsertMatching(candidateId)

  const handleButtonClick = () => setIsDialogOpen(true)
  const handleDialogCancel = () => setIsDialogOpen(false)
  const handleDialogConfirm = () => {
    upsertMatching({ status: MatchingStatus.Shortlisted }).then(() => {
      setIsDialogOpen(false)
      onShortlisted()
    })
  }

  return (
    <>
      <SecondaryButton onClick={handleButtonClick}>Shortlist without screening</SecondaryButton>
      <AlertDialog
        isOpen={isDialogOpen}
        title="Confirm shortlist"
        titleClassName="pb-0"
        description={
          <Text>You are about to shortlist a candidate without assigning a screening.</Text>
        }
        onCancelClick={handleDialogCancel}
        onConfirmClick={handleDialogConfirm}
        isLoading={isUpserting}
      />
    </>
  )
}
