import { Text } from 'components/Text'
import { ListingTerm } from 'job-listing/domains'

interface ListingTermsPreviewProps {
  term: ListingTerm
}
export const ListingTermsPreview = ({ term }: ListingTermsPreviewProps) => {
  return (
    <div className="flex flex-col md:flex-row bg-neutral-lightest p-6 lg:py-8 lg:px-10 rounded-lg gap-4 md:gap-24">
      <div className="flex flex-col gap-1">
        <Text size="small-body" color="text-neutral-dark">
          Listing fee
        </Text>
        <Text size="body" truncate={false}>
          {term.upfrontFee}
        </Text>
      </div>
      <div className="flex flex-col gap-1">
        <Text size="small-body" color="text-neutral-dark">
          Success fee
        </Text>
        <Text size="body" truncate={false}>
          {term.successFee}% of first year pay
        </Text>
      </div>
      <div className="flex flex-col gap-1">
        <Text size="small-body" color="text-neutral-dark">
          Guarantee period
        </Text>
        <Text size="body" truncate={false}>
          {term.guaranteeDays} days
        </Text>
      </div>
    </div>
  )
}
