import { ReactComponent as Logo } from 'icons/brand/brand-colored-negative.svg'
import { ReactComponent as LogoFrame } from 'icons/logo-frame.svg'

export const LogoIcon = ({ teleportable }: { teleportable: boolean }) => {
  if (teleportable) {
    return <LogoFrame className="h-5 w-[16px] hidden md:flex" />
  }

  return (
    <>
      <LogoFrame className="h-5 w-[16px] md:hidden" />
      <Logo className="h-5 w-[93px] hidden md:flex" />
    </>
  )
}
