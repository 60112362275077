import { useEffect, useRef, useState } from 'react'

import { Tags, Text } from 'shared/components/atoms'
import { useInterval } from 'shared/hooks'
import { ReactComponent as GlobeIcon } from 'icons/globe-04.svg'

import { CandidatePhoto } from './components'

interface Candidate {
  name: string
  role: string
  seniority: string
  photo: string
  skills: Array<string>
}

const SLIDE_WIDTH = 288.5

const PhotosSlider = ({ candidates }: { candidates: Array<Candidate> }) => {
  return (
    <>
      {candidates.map(({ name, role, photo }) => (
        <CandidatePhoto key={name} name={name} role={role} photo={photo} />
      ))}
    </>
  )
}

export const Candidates = () => {
  const candidates: Array<Candidate> = [
    {
      name: 'Rychillie U.',
      role: 'Front-end developer',
      seniority: 'Senior Front-end Developer',
      photo: '/images/recruiter-authentication/rychillie.png',
      skills: ['Vue.js', 'JavaScript', 'CSS', 'HTML', 'Jest'],
    },
    {
      name: 'Cauê T.',
      role: 'Back-end developer',
      seniority: 'Back-end Developer',
      photo: '/images/recruiter-authentication/caue.png',
      skills: ['Node.js', 'Express', 'Docker', 'MongoDB', 'PostgreSQL'],
    },
    {
      name: 'Rômulo P.',
      role: 'Full-stack developer',
      seniority: 'Full-stack Developer',
      photo: '/images/recruiter-authentication/romulo.png',
      skills: ['JavaScript', 'React.js', 'Node.js', 'MySQL', 'AWS'],
    },
    {
      name: 'Luíza F.',
      role: 'Mobile developer',
      seniority: 'Mobile Developer',
      photo: '/images/recruiter-authentication/luiza.png',
      skills: ['Swift', 'Kotlin', 'React Native', 'Firebase', 'GraphQL'],
    },
    {
      name: 'Tasiana F.',
      role: 'QA Engineer',
      seniority: 'QA Engineer',
      photo: '/images/recruiter-authentication/tasiana.png',
      skills: ['Selenium', 'Cypress', 'Jenkins', 'Jira', 'Python'],
    },
    {
      name: 'Pedro L.',
      role: 'DevOps Engineer',
      seniority: 'DevOps Engineer',
      photo: '/images/recruiter-authentication/pedro.png',
      skills: ['AWS', 'Docker', 'Kubernetes', 'Terraform', 'Jenkins'],
    },
    {
      name: 'Dina A.',
      role: 'Data Engineer',
      seniority: 'Data Engineer',
      photo: '/images/recruiter-authentication/dina.png',
      skills: ['Python', 'Apache Spark', 'Hadoop', 'SQL', 'Airflow'],
    },
  ]

  const [candidateIndex, setCandidateIndex] = useState(1)
  const [activeCandidate, setActiveCandidate] = useState(1)

  const infosContainer = useRef<HTMLDivElement>(null)
  const slider = useRef<HTMLDivElement>(null)

  const nextSlide = async () => {
    if (!slider.current || !infosContainer.current) return

    setCandidateIndex((prev) => (prev + 1) % (candidates.length + 1) || 1)

    slider.current.style.transition = 'transform 1s ease-in-out'
    slider.current.style.transform = `translateX(-${candidateIndex * SLIDE_WIDTH + SLIDE_WIDTH * candidates.length}px)`
    infosContainer.current.style.opacity = '0'

    await new Promise((resolve) => setTimeout(resolve, 1000))

    setActiveCandidate(candidateIndex % candidates.length)
    infosContainer.current.style.opacity = '1'

    if (candidateIndex === candidates.length) {
      slider.current.style.transition = 'none'
      slider.current.style.transform = `translateX(-${SLIDE_WIDTH * candidates.length}px)`
    }
  }

  const { clear } = useInterval(nextSlide, 3000)

  useEffect(() => {
    return () => clear()
  }, [])

  return (
    <div className="flex flex-col gap-4 p-4 rounded-lg bg-neutral-day bg-opacity-60 w-[289px] h-[562px]">
      <div
        className="flex gap-8"
        ref={slider}
        style={{ transform: `translateX(-${SLIDE_WIDTH * candidates.length}px)` }}
      >
        <PhotosSlider candidates={candidates} />
        <PhotosSlider candidates={candidates} />
        <PhotosSlider candidates={candidates} />
      </div>

      <div className="flex flex-col gap-4 transition-opacity duration-300" ref={infosContainer}>
        <Text size="text-lg" className="text-neutral-600">
          {candidates[activeCandidate].seniority}
        </Text>
        <div className="flex flex-col gap-2">
          <div className="flex gap-[6.4px] items-center">
            <Text size="text-base">🇺🇸</Text>
            <Text size="text-sm">United States</Text>
          </div>
          <div className="flex gap-[6.4px] items-center">
            <GlobeIcon className="w-5 h-5 stroke-neutral-500" />
            <Text size="text-sm">100% remote</Text>
          </div>
        </div>

        <Tags
          isReadOnly
          values={candidates[activeCandidate].skills.map((skill) => ({ label: skill, id: null }))}
        />
      </div>
    </div>
  )
}
