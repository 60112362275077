import { useState } from 'react'

import { AccountClaim, useAuth } from 'shared/hooks'
import { Label, Text, Toggle } from 'shared/components/atoms'
import { AlertDialog } from 'shared/components/molecules'
import { ReactComponent as AlertIcon } from 'icons/alert-triangle.svg'

import { useDemoMode } from './useDemoMode'

export const DemoModeToggler = () => {
  const { user } = useAuth()
  const { active, toggle, isToggling } = useDemoMode()

  const [showConfirmation, setShowConfirmation] = useState(false)

  const visible = active || user.claims.includes(AccountClaim.ToggleDemoMode)

  const handleToggle = () => toggle().then(() => window.location.reload())

  const descriptionItems = [
    {
      key: 1,
      visible: !active,
      text: 'By activating the demo mode, your permissions will be temporarily suspended, giving you the opportunity to explore the app as a regular client user.',
    },
    {
      key: 2,
      visible: !active,
      text: 'Any modifications you make during this time will be retained even after exiting demo mode.',
    },
    { key: 3, visible: !active, text: 'You can leave the mode at anytime.' },
    {
      key: 4,
      visible: active,
      text: 'By leaving the demo mode, your permissions will be fully restored to the original state.',
    },
    { key: 5, visible: active, text: 'You can enable it again at anytime.' },
  ].filter(({ visible }) => visible)

  if (!visible) return null

  return (
    <div>
      <Label
        htmlFor="demoModeEnabled"
        className="flex items-center gap-4 !m-0 px-4 py-3"
        onClick={() => setShowConfirmation(true)}
      >
        <Toggle name="demoModeEnabled" setValue={() => {}} checked={active} showCheck />
        <Text
          size="text-xs"
          weight="font-normal"
          className="text-neutral-darkest md:!text-sm !whitespace-nowrap"
        >
          Demo mode
        </Text>
      </Label>

      <AlertDialog
        isOpen={showConfirmation}
        isDismissible
        title={`Confirm demo mode ${active ? 'disabling' : 'enabling'}`}
        description={
          <div className="flex flex-col gap-4">
            <div className="flex gap-3 px-3 py-2 bg-warning-lighter rounded">
              <AlertIcon className="stroke-warning-medium h-6 w-6 min-w-[24px]" />
              <Text size="text-sm" weight="font-medium">
                Page refresh required. Unsaved changes will be lost.
              </Text>
            </div>

            <ul className="list-disc pl-10">
              {descriptionItems.map((item) => (
                <li key={item.key}>
                  <Text size="text-sm" weight="font-normal">
                    {item.text}
                  </Text>
                </li>
              ))}
            </ul>
          </div>
        }
        onCancelClick={() => setShowConfirmation(false)}
        onConfirmClick={handleToggle}
        autoFocusConfirmButton
        isLoading={isToggling}
      />
    </div>
  )
}
