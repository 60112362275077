import { SecondaryButton } from 'shared/components/atoms'
import { useState } from 'react'
import { ImportingModal } from './ImportingModal'

interface ImportingButtonProps {
  className?: string
  profileId: string
  readOnly: boolean
}

export const ImportingButton = ({ className, readOnly, profileId }: ImportingButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleClose = () => setIsModalOpen(false)

  if (readOnly) {
    return null
  }

  return (
    <>
      <SecondaryButton
        size="sm"
        onClick={() => setIsModalOpen(true)}
        className={className}
        isIconBefore
      >
        Import work experiences
      </SecondaryButton>
      <ImportingModal profileId={profileId} isOpen={isModalOpen} onClose={handleClose} />
    </>
  )
}
