import { ProfilePhoto } from 'modules/profiles/profile-photo'
import { ReactNode, useRef } from 'react'
import { ProfilePictureUploader } from './ProfilePictureUploader'
import { SensitiveInformation } from 'shared/components/atoms'

interface ProfilePictureProps {
  profileId: string
  readOnly?: boolean
  photoUrl: string | null
  children: ({
    imageUrl,
    onLoadError,
    onTriggered,
    className,
    onFileSet,
    isCropping,
  }: {
    imageUrl: string
    isCropping: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onLoadError: ({ target }: any) => void
    onTriggered: () => void
    onFileSet: (file: File | null) => void
    className: string
  }) => ReactNode
}

export const ProfilePicture = ({
  children,
  profileId,
  readOnly = true,
  photoUrl,
}: ProfilePictureProps) => {
  const photo = new ProfilePhoto(photoUrl)
  const title = readOnly ? undefined : 'Change your profile photo'
  const inputFileRef = useRef<HTMLInputElement>(null)

  const handleLoadError = ({ target }: { target: HTMLImageElement }) => {
    if (!target.src.match(photo.fallbackUrl)) {
      target.src = photo.fallbackUrl
    }
  }

  const handleSelectionTrigger = () => {
    inputFileRef.current?.click()
  }

  return (
    <SensitiveInformation>
      {(blurSensitiveInfo) => (
        <ProfilePictureUploader profileId={profileId}>
          {({ signature, onSelectFile, onFileSet, imgSrc, isCropping }) => (
            <label htmlFor="profilePictureUpload" title={title}>
              <input
                type="file"
                accept="image/*"
                id="profilePictureUpload"
                className="hidden"
                onChange={onSelectFile}
                disabled={readOnly}
                ref={inputFileRef}
              />
              <div key={signature}>
                {children({
                  imageUrl: imgSrc || photo.url,
                  isCropping,
                  onLoadError: handleLoadError,
                  onTriggered: handleSelectionTrigger,
                  onFileSet: onFileSet,
                  className: blurSensitiveInfo ? 'blur-sm pl-1' : 'none',
                })}
              </div>
            </label>
          )}
        </ProfilePictureUploader>
      )}
    </SensitiveInformation>
  )
}
