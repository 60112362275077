import { forwardRef, lazy, Suspense, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Text, AppealingTitle } from 'shared/components/atoms'
import { LayoutContext } from 'contexts/layout'
import { cn } from 'modules/shared'
import { ReactComponent as LogoPositive } from 'icons/brand/brand-colored-positive.svg'
import { ReactComponent as LogoNegative } from 'icons/brand/brand-colored-negative.svg'

const Loop = lazy(() =>
  import('icons/loop.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface OnboardingContainerProps {
  children: React.ReactNode
  background: React.ReactNode
  asideContent?: JSX.Element
  title?: string
  hideCardContainer?: boolean
  chooseDifferentAccount?: string
  className?: string
  containerClassNames?: string
  contentClassNames?: string
  asideClassName?: string
  cardDescription?: JSX.Element
  isDarkMode?: boolean
  logoClassName?: string
}

export const Container = forwardRef<HTMLDivElement, OnboardingContainerProps>(function Container(
  {
    children,
    background,
    asideContent,
    title,
    hideCardContainer = false,
    chooseDifferentAccount,
    className,
    containerClassNames,
    contentClassNames,
    asideClassName,
    cardDescription,
    isDarkMode,
    logoClassName,
  }: OnboardingContainerProps,
  ref,
) {
  const { setFluidContainer, setDefaultContainer } = useContext(LayoutContext)

  useEffect(() => {
    setFluidContainer()
    return () => setDefaultContainer()
  }, [])

  const Logo = isDarkMode ? LogoNegative : LogoPositive

  return (
    <div className={cn('flex justify-center min-h-screen overflow-hidden', className)}>
      <div
        className="flex grow flex-col-reverse lg:flex-row items-center justify-center max-w-[1920px] relative"
        ref={ref}
      >
        {background}

        <div
          className={cn(
            'flex grow xl:gap-[91px] 2xl:gap-[105px] 3xl:gap-[225px] w-full',
            containerClassNames,
          )}
        >
          <div className="flex grow justify-center items-center xl:ml-6">
            <div className="flex flex-col items-center gap-5 mx-6 md:mx-0 w-full">
              {hideCardContainer ? (
                <>{children}</>
              ) : (
                <>
                  <div
                    className={cn(
                      'flex flex-col p-6 sm:p-12 rounded-2xl bg-neutral-day bg-opacity-60 backdrop:blur-xs sm:w-[588px] w-full',
                      contentClassNames,
                    )}
                  >
                    <Logo className={cn('w-[111.41px] h-auto mb-8', logoClassName)} />
                    {Boolean(title) && <AppealingTitle className="mb-2">{title}</AppealingTitle>}
                    {children}
                  </div>
                  {cardDescription}
                </>
              )}

              {chooseDifferentAccount && (
                <Text
                  size="text-sm"
                  weight="font-medium"
                  className={cn('z-10 text-center', {
                    'text-neutral-lightest': isDarkMode,
                    'text-neutral-darkest': !isDarkMode,
                  })}
                >
                  {chooseDifferentAccount}{' '}
                  <Link
                    to={'/signup'}
                    className={cn({ 'text-blue-700': !isDarkMode, 'text-blue-300': isDarkMode })}
                  >
                    Choose a different account type
                  </Link>
                </Text>
              )}
            </div>
          </div>

          {Boolean(asideContent) && (
            <aside
              className={cn(
                'hidden xl:flex grow justify-start items-center mr-6 my-6',
                asideClassName,
              )}
            >
              {asideContent}
            </aside>
          )}
        </div>
      </div>
    </div>
  )
})

export const LightBackground = ({ classNames }: { classNames?: string }) => {
  return (
    <div className={cn('relative', classNames)}>
      <div className="absolute">
        <div
          className={cn(
            'hidden lg:block absolute bg-transparent-white-transparent -z-20 h-[1080px] w-[1400px] xl:w-[1520px] 2xl:w-[1600px] 3xl:w-[1800px] 4xl:w-[2000px]',
            '-top-[272px] -left-10 2xl:left-0 3xl:-left-14',
          )}
        ></div>
        <Suspense fallback={null}>
          <Loop
            className={cn(
              'hidden lg:block absolute -z-30 w-[1458px] xl:w-[1712px] 2xl:[1800px] 3xl:w-[2000px] 4xl:w-[2200px]',
              '-left-[140px] -top-[272px] xl:-left-[56px]',
            )}
          />
        </Suspense>

        <div className="absolute flex rotate-[30deg] -left-[600px] -top-[600px] lg:left-3 lg:-top-20 -z-10 blur-[256px] opacity-20">
          <div className="bg-accent-600 h-[640px] w-[640px] shrink-0"></div>
          <div className="bg-warning-400 h-[640px] w-[640px] shrink-0"></div>
        </div>
      </div>
    </div>
  )
}

export const DarkBackground = () => (
  <>
    <div className="hidden xl:block absolute bg-gradient-to-r from-warning-light via-brand-medium to-blue-blur opacity-60 h-1/2 w-2/3 -rotate-[30deg] blur-gradient -bottom-[100px] left-1/2 -translate-x-1/2"></div>
    <div className="block xl:hidden absolute bg-blue-blur opacity-60 h-1/2 w-2/3 -rotate-[30deg] blur-gradient -bottom-[100px] left-1/2 -translate-x-1/2"></div>
  </>
)
