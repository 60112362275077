import { UseFormWatch } from 'react-hook-form'

import { FieldError, RadioGroup, Text } from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'
import { COMPANY_SIZE_EMPLOYEES_NUMBER } from 'company/consts'
import { CompanySize } from 'company/enums'

interface CompanySizesProps {
  register: (key: string) => Array<string>
  watch: UseFormWatch<Record<string, boolean>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: { [x: string]: any }
}

export const CompanySizes = ({ register, watch, errors }: CompanySizesProps) => {
  const companySizesError =
    errors.companySizes && 'Choosing all the sizes of companies is required.'

  const companySizes = [
    {
      name: `companySizes.${CompanySize.Micro}`,
      label: 'Micro',
      hint: `(${COMPANY_SIZE_EMPLOYEES_NUMBER[CompanySize.Micro]} employees)`,
    },
    {
      name: `companySizes.${CompanySize.Small}`,
      label: 'Small',
      hint: `(${COMPANY_SIZE_EMPLOYEES_NUMBER[CompanySize.Small]} employees)`,
    },
    {
      name: `companySizes.${CompanySize.Medium}`,
      label: 'Medium',
      hint: `(${COMPANY_SIZE_EMPLOYEES_NUMBER[CompanySize.Medium]} employees)`,
    },
    {
      name: `companySizes.${CompanySize.Large}`,
      label: 'Large',
      hint: `(${COMPANY_SIZE_EMPLOYEES_NUMBER[CompanySize.Large]} employees)`,
    },
    {
      name: `companySizes.${CompanySize.VeryLarge}`,
      label: 'Very large',
      hint: `(${COMPANY_SIZE_EMPLOYEES_NUMBER[CompanySize.VeryLarge]} employees)`,
    },
    {
      name: `companySizes.${CompanySize.Huge}`,
      label: 'Huge',
      hint: `(${COMPANY_SIZE_EMPLOYEES_NUMBER[CompanySize.Huge]} employees)`,
    },
  ]

  const radioGroupOptions = [
    { value: 'ideal', label: 'Ideal' },
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ]

  return (
    <ContentWrapper className="!mb-0">
      <Text size="text-sm" weight="font-medium" className="text-neutral-darkest">
        Would you like to work at companies of these sizes?
      </Text>
      <ul className="mt-6">
        {companySizes.map((size) => (
          <li
            key={size.name}
            className="mb-2 flex flex-row items-center justify-between"
            aria-labelledby={size.name}
          >
            <div className="flex flex-col md:flex-row gap-1">
              <Text
                id={size.name}
                size="text-sm"
                weight="font-medium"
                className="text-neutral-darkest"
              >
                {size.label}
              </Text>
              <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
                {size.hint}
              </Text>
            </div>
            <RadioGroup
              name={size.name}
              register={register}
              watch={watch}
              options={radioGroupOptions}
              omitErrors
            />
          </li>
        ))}
      </ul>
      <FieldError message={companySizesError} />
    </ContentWrapper>
  )
}
