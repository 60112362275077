import { TrackingEvent } from '../tracking-event'

export class SurveyAnswer implements TrackingEvent {
  name = 'Survey answer'

  constructor(
    private readonly product: string,
    private readonly rate: string,
  ) {}

  get payload() {
    return { product: this.product, rate: this.rate }
  }
}
