import { useState } from 'react'

import { AccountClaim } from 'shared/hooks'
import {
  ClaimProtected,
  Label,
  Text,
  Toggle,
  useSensitiveInformation,
} from 'shared/components/atoms'
import { AlertDialog } from 'shared/components/molecules'
import { ReactComponent as AlertIcon } from 'icons/alert-triangle.svg'

export const BlurredModeToggler = () => {
  const { blurSensitiveInfo, setBlurSensitiveInfo } = useSensitiveInformation()
  const [showConfirmation, setShowConfirmation] = useState(false)

  const descriptionItems = [
    {
      key: 1,
      visible: blurSensitiveInfo,
      text: 'By disabling the demo blurred all profiles information will be visible.',
    },
    { key: 2, visible: blurSensitiveInfo, text: 'You can enable it again at anytime.' },
    {
      key: 3,
      visible: !blurSensitiveInfo,
      text: 'By enabling the blurred mode, sensitive profile information will be blurred: candidate names, company names, and university names.',
    },
    { key: 4, visible: !blurSensitiveInfo, text: 'You can disable it at anytime.' },
  ].filter(({ visible }) => visible)

  return (
    <ClaimProtected require={AccountClaim.ToggleBlurMode}>
      <div>
        <Label
          htmlFor="blurrModeEnabled"
          className="flex items-center gap-4 !m-0 px-4 py-3"
          onClick={() => setShowConfirmation(true)}
        >
          <Toggle
            name="blurrModeEnabled"
            setValue={() => {}}
            checked={blurSensitiveInfo}
            showCheck
          />
          <Text
            size="text-xs"
            weight="font-normal"
            className="text-neutral-darkest md:!text-sm !whitespace-nowrap"
          >
            Blurred mode
          </Text>
        </Label>

        <AlertDialog
          isOpen={showConfirmation}
          isDismissible
          title={`Confirm blurred mode ${blurSensitiveInfo ? 'disabling' : 'enabling'}`}
          description={
            <div className="flex flex-col gap-4">
              <div className="flex gap-3 px-3 py-2 bg-warning-lighter rounded">
                <AlertIcon className="stroke-warning-medium h-6 w-6 min-w-[24px]" />
                <Text size="text-sm" weight="font-medium">
                  Changes are only applied to your current tab. Make sure to reload any other tabs
                  you may have in order to apply new definitions.
                </Text>
              </div>
              <ul className="list-disc pl-10">
                {descriptionItems.map((item) => (
                  <li key={item.key}>
                    <Text size="text-sm" weight="font-normal">
                      {item.text}
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
          }
          onCancelClick={() => setShowConfirmation(false)}
          onConfirmClick={() => setBlurSensitiveInfo(!blurSensitiveInfo)}
          autoFocusConfirmButton
        />
      </div>
    </ClaimProtected>
  )
}
