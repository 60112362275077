export const useDate = () => {
  const getDifferenceInSeconds = (startDate: Date, endDate: Date) => {
    const oneSecond = 1000
    return (new Date(endDate).getTime() - new Date(startDate).getTime()) / oneSecond
  }

  const getTimeToDisplay = (seconds: number) => {
    const secondsToDisplay = seconds % 60
    const minutesRemaining = (seconds - secondsToDisplay) / 60
    const minutesToDisplay = minutesRemaining % 60
    const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60

    return { seconds: secondsToDisplay, minutes: minutesToDisplay, hours: hoursToDisplay }
  }

  const getDifferenceInDays = (startDate?: Date | string, endDate: Date = new Date()) => {
    return startDate
      ? Math.floor((new Date(startDate).getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24))
      : undefined
  }

  const getDifferenceInWeeks = (startDate?: Date | string, endDate: Date = new Date()) => {
    return startDate
      ? Math.floor((new Date(startDate).getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24 * 7))
      : undefined
  }

  const getDifferenceInMonths = (startDate?: Date | string, endDate: Date = new Date()) => {
    return startDate
      ? Math.floor((new Date(startDate).getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24 * 30))
      : undefined
  }

  const getDateDiffToDisplay = (startDate?: Date | string, endDate: Date = new Date()) => {
    if (!startDate) return

    const differenceInMonths = getDifferenceInMonths(startDate, endDate)!
    const differenceInWeeks = getDifferenceInWeeks(startDate, endDate)!

    if (differenceInMonths >= 1) {
      const weeks = differenceInWeeks % 4
      return `${differenceInMonths} month${differenceInMonths > 1 ? 's' : ''}${
        weeks ? ` and ${weeks} week${weeks > 1 ? 's' : ''}` : ''
      }`
    }

    const differenceInDays = getDifferenceInDays(startDate, endDate)!
    if (differenceInWeeks >= 1) {
      const days = differenceInDays % 7
      return `${differenceInWeeks} week${differenceInWeeks > 1 ? 's' : ''}${
        days ? ` and ${days} day${days > 1 ? 's' : ''}` : ''
      }`
    }

    if (differenceInDays >= 1) {
      return `${differenceInDays} day${differenceInDays > 1 ? 's' : ''}`
    }

    return 'Today'
  }

  return {
    getDifferenceInSeconds,
    getTimeToDisplay,
    getDifferenceInDays,
    getDateDiffToDisplay,
    getDifferenceInWeeks,
    getDifferenceInMonths,
  }
}
