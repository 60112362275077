import { plainToInstance } from 'class-transformer'
import { ProfileMatchingItem } from 'matching/domain'
import { useQuery } from 'react-query'
import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useProfileMatchings = (profileId: string) => {
  const { get } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { data: matchings = [], isLoading } = useQuery(
    ['profileMatchings', profileId],
    () =>
      get<Array<ProfileMatchingItem>>(`/profile/${profileId}/matchings`).then(({ data }) =>
        plainToInstance(ProfileMatchingItem, data),
      ),
    {
      enabled: Boolean(profileId),
      onError: (error: RequestError) => {
        toastError(error.message)
        captureException(error)
      },
    },
  )

  return { matchings, isLoading }
}
