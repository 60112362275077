import { EducationFormValues } from 'modules/profiles/domain'
import { date, number, object, string } from 'yup'
export { Education } from './Education'

export const schema = object().shape({
  id: string().nullable(),
  degreeTypeId: string().required(),
  degreeType: string().required(),
  endDate: date()
    .required()
    .test(
      'greater_than_start',
      'The end date must be greater than the start date.',
      function (value) {
        if (value) return value > this.parent.startDate
        return true
      },
    ),
  fieldOfStudy: string().required(),
  institutionId: number().required(),
  institutionName: string(),
  startDate: date().required(),
})

export const emptyState = {
  institutionId: null,
  institutionName: null,
  degreeType: '',
  endDate: null,
  startDate: null,
  fieldOfStudy: '',
} as EducationFormValues
