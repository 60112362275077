import { useMemo } from 'react'
import { Container } from 'onboarding/components'
import { Roles } from 'account/enums'
import { SidePanel } from 'authentication/components'
import { DarkBackground } from 'onboarding/components/Container/Container'
import { SignUpStep } from 'authentication/pages/SignUpPage/SignUpStep'

export const JobSeekerLandingPage = () => {
  const redirectUrl = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const url = urlParams.get('redirectUrl')
    const inviteId = urlParams.get('inviteId')

    if (url) return inviteId ? url.concat(`&inviteId=${inviteId}`) : url
    return '/job-seeker-onboarding'
  }, [])

  return (
    <Container
      className="bg-neutral-darkest"
      asideContent={<SidePanel accountRole={Roles.JobSeeker} />}
      background={<DarkBackground />}
      hideCardContainer={false}
      contentClassNames="sm:p-0 p-0 w-full md:w-[490px] bg-opacity-10"
      isDarkMode
      logoClassName="mb-0 mt-12 ml-6 md:ml-12"
    >
      <div className="w-full">
        <SignUpStep redirectUrl={redirectUrl} accountRole={Roles.JobSeeker} />
      </div>
    </Container>
  )
}
