import { cloneElement, useMemo } from 'react'

const sizes = {
  xl: { height: '24px', width: '24px' },
  lg: { height: '24px', width: '24px' },
  md: { height: '24px', width: '24px' },
  sm: { height: '16px', width: '16px' },
}

export const useStyledIcon = (size: 'sm' | 'md' | 'lg' | 'xl', icon?: JSX.Element) => {
  const buildStyledIcon = () => {
    if (!icon) return
    return cloneElement(icon, {
      style: {
        ...sizes[size],
      },
    })
  }

  const styleIcon = useMemo(() => buildStyledIcon(), [size, icon])

  return {
    styleIcon,
  }
}
