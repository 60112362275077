import { Suspense, useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import classNames from 'classnames'

import { Spinner } from 'shared/components/atoms'
import { NavBar } from './NavBar'

import { LayoutContext } from 'contexts/layout'

import { useAuth } from 'shared/hooks'

export const SignedInLayout = () => {
  const { user } = useAuth()
  const {
    shouldRootHavePadding,
    shouldRootHaveLimitedSize,
    backgroundColor,
    shouldHideNavbar,
    hideBanner,
    shouldScroll,
    setHideBanner,
  } = useContext(LayoutContext)

  useEffect(() => {
    if (shouldHideNavbar) setHideBanner(true)
  }, [shouldHideNavbar])

  const fallbackSpinner = (
    <div className="h-screen w-screen bg-neutral-day">
      <Spinner />
    </div>
  )

  return (
    <div className={classNames('flex flex-col items-center min-h-screen', backgroundColor)}>
      <NavBar />
      <main
        className={classNames(
          'flex-grow flex flex-col',
          'w-full  h-full',
          'px-6 py-6 md:pb-12 lg:pb-20 lg:px-0',
          {
            '!p-0': !shouldRootHavePadding,
            'max-w-[1032px]': shouldRootHaveLimitedSize,
            'mt-[60px] md:mt-16': user.isOnboarded && !shouldHideNavbar,
            'mt-0': !user.isOnboarded || shouldHideNavbar,
            // Weird breakpoint needed because this is when the banner message breaks lines
            'mb-16 md:mb-0 md:!mt-24 [@media(min-width:895px)]:!mt-20':
              !hideBanner && user.isEmployer,
            'md:max-h-[calc(100vh-136px)] [@media(min-width:895px)]:max-h-[calc(100vh-120px)] overflow-hidden':
              !shouldScroll && !hideBanner,
            'md:max-h-[calc(100vh-64px)] overflow-hidden': !shouldScroll && hideBanner,
          },
        )}
      >
        <Suspense fallback={fallbackSpinner}>
          <Outlet />
        </Suspense>
      </main>
    </div>
  )
}
