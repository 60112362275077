import { EnglishLevel, VettingPackageStatus } from 'vetting/enums'

export class VettingPackageResponse {
  status: VettingPackageStatus
  levelCategory: EnglishLevel | null
  issues: Array<{ label: string; description: string | null }>

  get newSubmissionAllowed() {
    return this.status === VettingPackageStatus.RecordAgain
  }
}
