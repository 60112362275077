import { MatchingEventFilter, ProfileEventFilter } from 'candidates-search/enums'

export class TimelineFilterValues {
  matchings: Record<string, boolean>
  matchingEventsNone: boolean
  matchingEvents: Record<MatchingEventFilter, boolean>
  profileEventsNone: boolean
  profileEvents: Record<ProfileEventFilter, boolean>

  constructor(props: Plain<TimelineFilterValues>) {
    this.matchings = props.matchings
    this.matchingEventsNone = props.matchingEventsNone
    this.matchingEvents = props.matchingEvents
    this.profileEventsNone = props.profileEventsNone
    this.profileEvents = props.profileEvents
  }

  get selectedMatchingIds() {
    return Object.keys(this.matchings).filter((key) => this.matchings[key])
  }

  get selectedMatchingEvents() {
    const keys = Object.keys(this.matchingEvents) as Array<MatchingEventFilter>
    return keys.filter((key) => this.matchingEvents[key])
  }

  get selectedProfileEvents() {
    const keys = Object.keys(this.profileEvents) as Array<ProfileEventFilter>
    return keys.filter((key) => this.profileEvents[key])
  }
}
