import classNames from 'classnames'
import { Input } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

interface Props {
  id?: string
  isSelected: boolean
  label: string
  name: string
  containerClassName?: string
  size?: 'md' | 'lg'
  outlined?: boolean
  type?: React.HTMLInputTypeAttribute
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
}

export const SelectableChip = ({
  id,
  isSelected,
  label,
  name,
  containerClassName,
  size = 'md',
  outlined,
  type,
  register,
}: Props) => {
  const sizes = {
    md: 'h-10 px-4 gap-2',
    lg: 'h-12 px-6 gap-3',
  }

  return (
    <div className={containerClassName}>
      <label
        className={classNames(
          'flex flex-row items-center justify-center relative rounded-full',
          'border border-neutral-light rounded-[4px]',
          'stroke-neutral-darkest active:!shadow-none hover:border-neutral-medium transition duration-150 focus:shadow-[0_0_0_4px_#99AEFF]',
          'focus-within:outline-neutral-darker hover:cursor-pointer',
          sizes[size],
          {
            'bg-neutral-darker border-neutral-light': isSelected && !outlined,
            'border-neutral-darker outline outline-2 outline-offset-[-2px] outline-neutral-darker':
              isSelected && outlined,
          },
        )}
      >
        <Text
          size="text-sm"
          weight="font-normal"
          className={classNames('whitespace-nowrap', {
            'text-neutral-day': isSelected && !outlined,
            'text-neutral-darkest': isSelected && outlined,
          })}
        >
          {label}
        </Text>
        <Input
          type={type}
          id={id}
          name={name}
          className="fixed opacity-0 pointer-events-none"
          register={register}
          showFieldError={false}
        />
      </label>
    </div>
  )
}
