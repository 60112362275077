import { useMutation } from 'react-query'

import { useNetwork, useToast, useMonitoring } from 'shared/hooks'

import { MatchingFlagType } from 'matching/enums'

interface Props {
  listingId: string
  matchingId: string
}

export const useDeleteFlag = ({ listingId, matchingId }: Props) => {
  const { delete: remove } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: deleteFlag, isLoading: isDeleting } = useMutation(
    (flag: MatchingFlagType) =>
      remove(`listings/${listingId}/matchings/${matchingId}/matching-flags/${flag}`),
    {
      onError: (error: RequestError) => {
        toastError('Failed to remove flag: ' + error.response?.data.message)
        captureException(error)
      },
    },
  )

  return { deleteFlag, isDeleting }
}
