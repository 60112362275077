export class SelfIntroductionAudio {
  // @todo: consider removing class
  constructor(private readonly defaultUrl: string | null) {}

  get url() {
    return this.defaultUrl || ''
  }

  get available() {
    return Boolean(this.defaultUrl)
  }
}
