import { Text } from 'shared/components/atoms'
import { Tags } from 'shared/components/atoms'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { UpsertButton } from '../../UpsertButton'
import { PositionsSlug } from 'modules/shared/enums'

interface RolesOfInterestProps {
  profile: ProfileOverview
  readOnly: boolean
  onEdit: () => void
}

export const RolesOfInterest = ({ profile, onEdit, readOnly }: RolesOfInterestProps) => {
  const roleTags = profile.interestRoles
    .concat(
      profile.otherInterestRoles?.map((role, index) => ({
        id: (index + 1) * -1,
        label: role,
        roleType: PositionsSlug.Other,
      })) || [],
    )
    .filter(({ label }) => Boolean(label))

  return (
    <div className="flex flex-col md:flex-row gap-2 md:gap-6">
      <Text
        size="text-xs"
        className="text-neutral-dark md:py-[10px] w-[100px] shrink-0 md:mt-[12.5px]"
      >
        Open to roles
      </Text>
      <div className="flex flex-row md:gap-6">
        <Tags
          isReadOnly
          values={roleTags}
          background="bg-neutral-lightest"
          containerClassName="md:p-3"
          tagClassName="py-2 px-3"
        />
        <UpsertButton onClick={onEdit} className="ml-auto md:mt-[12.5px]" readOnly={readOnly} />
      </div>
    </div>
  )
}
