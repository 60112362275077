import { Text } from 'shared/components/atoms'
import { ReactComponent as CloseIcon } from 'icons/close.svg'
import { MatchingFlagType, matchingFlagTypeLabels } from 'matching/enums'

interface FlagsProps {
  flags: Array<MatchingFlagType>
  readOnly?: boolean
  onRemove?: (flag: MatchingFlagType) => void
}

interface FlagProps {
  flag: MatchingFlagType
  readOnly?: boolean
  onRemove?: (flag: MatchingFlagType) => void
}

const Flag = ({ flag, readOnly, onRemove }: FlagProps) => (
  <div className="flex items-center gap-1 py-2 px-3 rounded-full bg-danger-lighter">
    <Text size="text-xs" className="text-danger-dark">
      {matchingFlagTypeLabels[flag]}
    </Text>
    {!readOnly && (
      <CloseIcon
        className="stroke-neutral-medium w-4 h-4 cursor-pointer -mr-1"
        onClick={() => onRemove && onRemove(flag)}
      />
    )}
  </div>
)

export const Flags = ({ flags, readOnly, onRemove }: FlagsProps) => {
  return (
    <div className="flex gap-2 flex-wrap">
      {flags.map((flag) => (
        <Flag key={flag} flag={flag} readOnly={readOnly} onRemove={onRemove} />
      ))}
    </div>
  )
}
