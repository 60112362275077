import { SecondaryButton } from 'shared/components/atoms'
import { Task } from './Task'

export const AssessmentTask = () => {
  return (
    <Task
      iconPath="images/chat.png"
      iconClassName="bg-warning-lighter"
      title="Strider Technical Assessment"
      description="You have been selected to take our technical assessment."
      cta={
        <a
          href={
            'https://onstrider.notion.site/Strider-Technical-Assessments-9b62925cd9904ed994423d2231d01828'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <SecondaryButton size="sm">Take assessment</SecondaryButton>
        </a>
      }
    />
  )
}
