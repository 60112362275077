import { Spinner } from 'shared/components/atoms'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const JoinWorkspaceRedirectCallback = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) return navigate('onboarding', { replace: true })
    return navigate(`/onboarding?signUpLinkId=${id}`, { replace: true })
  }, [id])

  return <Spinner />
}
