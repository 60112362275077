import { useMutation, useQueryClient } from 'react-query'
import { useFetchScreening } from './useFetchScreening'
import { useNetwork } from 'shared/hooks'
import { useJobListingPreview } from 'job-listing/exports'
import { useFetchScreenings } from './useFetchScreenings'
import { UpsertScreeningDTO } from 'screening/dtos'
import { ScreeningTitle } from 'screening/domain'
import { useToast } from 'shared/hooks'
import { useMonitoring } from 'shared/hooks'

export const useDuplicateScreening = (listingId: string, screeningId: string) => {
  const client = useQueryClient()
  const { post } = useNetwork()
  const { toastSuccess, toastError } = useToast()
  const { captureException } = useMonitoring()

  const { getScreening } = useFetchScreening(listingId)
  const { listing } = useJobListingPreview(listingId)
  const { screenings } = useFetchScreenings(listingId)

  const { mutateAsync: duplicateScreening, isLoading } = useMutation(
    async () => {
      const screening = await getScreening(screeningId)
      screening.title = new ScreeningTitle(listing, screenings.length).title

      const payload = new UpsertScreeningDTO(screening)
      return post<{ id: string }>(`/listings/${listingId}/screenings`, payload).then(
        ({ data }) => data,
      )
    },
    {
      onSuccess: () => {
        toastSuccess('Screening duplicated.')
        client.invalidateQueries(['screenings'])
      },
      onError: (error: RequestError) => {
        toastError(`Failed to duplicate the screening: ${error.response?.data.message}`)
        captureException(error)
      },
    },
  )

  return { duplicateScreening, isLoading }
}
