import { Bar, SliderOption } from 'shared/components/atoms'
import { useComputedBars } from 'shared/components/molecules'
import { useMemo } from 'react'

export const useAvailableOpportunities = (
  bars: Array<Pick<Bar, 'heightPercentage'>>,
  options: Array<SliderOption>,
  minValue: number,
  maxValue: number,
) => {
  const computedBars = useComputedBars(bars, options, minValue, maxValue)
  const totalValue = useMemo<number>(() => {
    return bars
      .map(({ heightPercentage }) => heightPercentage)
      .reduce((prev, current) => prev + current)
  }, [bars])
  const selectedValue = computedBars
    .filter(({ color }) => color === 'bg-neutral-darker')
    .map(({ heightPercentage }) => heightPercentage)
    .reduce((prev, current) => prev + current, 0)

  return Number(((selectedValue / totalValue) * 100).toFixed(0))
}
