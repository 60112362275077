import { plainToInstance } from 'class-transformer'
import { CandidateContext } from 'contexts/candidate'
import { useMonitoring } from 'shared/hooks'
import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { JobOpportunity } from 'job-opportunity/domain'
import { useContext } from 'react'
import { useQuery } from 'react-query'

const FT_JOB_OPPORTUNITIES = process.env.REACT_APP_FT_JOB_OPPORTUNITIES === 'true'

export const useFetchJobOpportunities = () => {
  const { candidate } = useContext(CandidateContext)

  const { toastError } = useToast()
  const { captureException } = useMonitoring()
  const { get } = useNetwork()

  const { data: jobOpportunities = [], isLoading } = useQuery(
    ['job-opportunities'],
    () =>
      get<Array<JobOpportunity>>(`profile/${candidate.id}/job-opportunities`).then(({ data }) =>
        plainToInstance(JobOpportunity, data),
      ),
    {
      onError: (error: RequestError) => {
        toastError('Failed to fetch the job opportunities.')
        captureException(error)
      },
      staleTime: 5 * 60 * 1000,
      enabled: FT_JOB_OPPORTUNITIES,
    },
  )

  const appliedJobOpportunities = jobOpportunities.filter(({ isApplied }) => isApplied)

  return { jobOpportunities, appliedJobOpportunities, isLoading }
}
