import classNames from 'classnames'
import { cn } from 'modules/shared'
import { FieldError } from 'shared/components/atoms'

export interface RadioGroupProps {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (name: string) => Array<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: (name: string) => any
  options: Array<{ value: string | number; label: string; className?: string }>
  containerClassName?: string
  controlClassName?: string
  omitErrors?: boolean
  outlined?: boolean
  disabled?: boolean
  showWarning?: boolean
}

export const RadioGroup = ({
  name,
  options,
  watch,
  register,
  omitErrors,
  containerClassName = 'gap-2',
  controlClassName,
  outlined,
  disabled,
  showWarning,
}: RadioGroupProps) => {
  const [error, fieldRegister] = register(name)
  const currentValue = watch(name)

  return (
    <div>
      <div className={classNames('flex', containerClassName)}>
        {options.map((option) => {
          const isSelected = String(currentValue) === String(option.value)

          return (
            <label
              key={option.value}
              className={cn(
                'relative overflow-hidden border py-2 px-4 text-sm text-center',
                'hover:cursor-pointer',
                'focus-within:outline-neutral-darker',
                'rounded-full',
                controlClassName,
                {
                  'text-neutral-dark hover:bg-neutral-lightest border-neutral-light': !isSelected,
                  'bg-neutral-darker text-neutral-day border-neutral-light':
                    isSelected && !outlined,
                  'text-neutral-darkest border-neutral-darker outline outline-2 outline-offset-[-2px] outline-neutral-darker':
                    isSelected && outlined,
                  'bg-neutral-lightest border-neutral-light text-neutral-medium hover:!cursor-default':
                    disabled,
                  'bg-warning-lighter border-warning-medium hover:bg-warning-lighter': showWarning,
                },
                option.className,
              )}
            >
              {option.label}
              <input
                type="radio"
                id={option.value}
                value={option.value}
                name={name}
                {...fieldRegister()}
                className="h-0 w-0 opacity-0 pointer-events-none"
              />
            </label>
          )
        })}
      </div>
      {!omitErrors && <FieldError message={error} />}
    </div>
  )
}
