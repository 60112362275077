import classNames from 'classnames'
import { SecondaryButton } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { useFormatting } from 'shared/hooks'
import { cn } from 'modules/shared'
import { ReactComponent as ProfileSearchIcon } from 'icons/file-search-02.svg'

export interface TimelineItemProps {
  date?: string | null
  title: JSX.Element
  descriptions?: JSX.Element[]
  showLine?: boolean
  actionLabel?: string
  action?: () => void
  icon?: JSX.Element
  isFirst?: boolean
}

export const Line = ({ showLine }: { showLine: boolean }) => {
  return (
    <div
      className={classNames('min-w-[1px] -ml-[41px] mr-11 -mb-10 -mt-2', {
        'bg-neutral-medium': showLine,
        'bg-transparent': !showLine,
      })}
    />
  )
}

export const Dot = ({ icon, isFirst }: { icon?: JSX.Element; isFirst: boolean }) => {
  return (
    <div
      className={cn('flex items-center justify-center w-14 h-14 rounded-full -ml-[68px] mr-4', {
        'bg-neutral-darker': isFirst,
        'bg-neutral-lightest': !isFirst,
      })}
    >
      {icon || (
        <ProfileSearchIcon
          className={cn('w-6 h-6', {
            'stroke-neutral-darker': !isFirst,
            'stroke-neutral-day': isFirst,
          })}
        />
      )}
    </div>
  )
}

export const TimelineItem = ({
  date,
  title,
  descriptions,
  showLine = true,
  actionLabel = 'View answers',
  action,
  icon,
  isFirst,
}: TimelineItemProps) => {
  const { formatDateTime } = useFormatting()

  return (
    <li className="flex-grow flex flex-col items-stretch justify-start">
      <div className="flex flex-col gap-4">
        <div className="flex items-center">
          <Dot icon={icon} isFirst={Boolean(isFirst)} />
          <div className="flex flex-col gap-1">
            {Boolean(date) && (
              <Text size="text-xs" weight="font-normal" className="text-neutral-dark">
                {formatDateTime({ date: new Date(date!) })}
              </Text>
            )}
            {title}
          </div>
        </div>
        <div className="flex flex-row">
          <Line showLine={showLine} />
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              {descriptions?.map((description) => description)}
            </div>
            {action ? <SecondaryButton onClick={action}>{actionLabel}</SecondaryButton> : null}
          </div>
        </div>
      </div>
    </li>
  )
}
