import { ToggleFormField } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'

interface HourlyRateProps {
  formControl: FormControl
}

export const HourlyRate = ({ formControl }: HourlyRateProps) => {
  return (
    <ToggleFormField
      name="hourlyRatesEnabled"
      formControl={formControl}
      label="Change to Hourly Rates"
      description="Display hourly rates and hide monthly rates on candidate profiles associated with this job listing."
    />
  )
}
