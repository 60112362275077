import { plainToInstance } from 'class-transformer'
import qs from 'qs'

import { EngagementType } from 'job-listing/enums'

import { PayRateType } from 'modules/shared/enums'
import { InstitutionTier } from 'modules/candidates/enums'
import { ScreeningAssignmentProgress } from 'modules/screening-assignment/enums'

import { DegreeType, Stack } from 'pages/JobSeekerProfile/types'
import { Skill } from 'profile/types'

interface Role {
  id: number
  label: string
}

export class Filter {
  keywords: string
  roles: Array<Role>
  educationDegree: DegreeType | null
  institutionTier: InstitutionTier | null
  restrictTierToBachelors: boolean
  skills: {
    matchAll: boolean
    stacks: Array<Stack>
  }
  primarySkills: {
    matchAll: boolean
    stacks: Array<Skill>
  }
  payRate: {
    type: PayRateType
    min: number
    max: number
  }
  yearsOfExperience: {
    min: number
    max: number
  }
  onlyVerifiedEnglishLevel: boolean
  onlyTalentWithoutActiveJob: boolean
  onlyTalentWithAdvertiseConsent: boolean
  onlyTalentWithPersonalWebsite: boolean
  englishLevel: {
    C1: boolean
    C2: boolean
    B1: boolean
    B2: boolean
    A1: boolean
    A2: boolean
  }
  profileFulfillmentStatus: {
    excellent: boolean
    sufficient: boolean
    under_review: boolean
  }
  profileStatus: {
    active: boolean
    passive: boolean
    paused: boolean
    unavailable: boolean
  }
  staStatus: {
    strong_pass: boolean
    pass: boolean
    in_progress: boolean
    no_assessment: boolean
    fail: boolean
    strong_fail: boolean
  }
  screeningProgress: {
    [ScreeningAssignmentProgress.Approved]: boolean
    [ScreeningAssignmentProgress.Declined]: boolean
    [ScreeningAssignmentProgress.Rejected]: boolean
    [ScreeningAssignmentProgress.Submitted]: boolean
    [ScreeningAssignmentProgress.Expired]: boolean
    [ScreeningAssignmentProgress.Answered]: boolean
    [ScreeningAssignmentProgress.StartedAnswering]: boolean
    [ScreeningAssignmentProgress.Viewed]: boolean
    [ScreeningAssignmentProgress.Assigned]: boolean
  }
  engagementPreferences: {
    type: EngagementType | null
    freelancingWorkingHoursPerWeek: {
      min: number
      max: number
    }
  }
  country: Array<{ id: number; label: string }>

  assessmentTags: {
    mustHave: Array<{ id: string; name: string }>
    mustNotHave: Array<{ id: string; name: string }>
  }

  proficiencies: {
    mustHave: Array<Stack>
    mustNotHave: Array<Stack>
  }

  mockInterviewDone: boolean

  get url() {
    return `?${qs.stringify(this, { encodeValuesOnly: true })}`
  }

  get englishLevelJSON() {
    return {
      onlyVerifiedCategories: this.onlyVerifiedEnglishLevel,
      categories: Object.entries(this.englishLevel)
        .filter(([_, value]) => value)
        .map(([key, _]) => key),
    }
  }

  get profileFulfillmentStatusJSON() {
    const entries = Object.entries(this.profileFulfillmentStatus).filter(([_, value]) => value)
    return entries.map(([key, _]) => key)
  }

  get profileStatusJSON() {
    const entries = Object.entries(this.profileStatus).filter(([_, value]) => value)
    return entries.map(([key, _]) => key)
  }

  get staStatusJSON() {
    const entries = Object.entries(this.staStatus).filter(([_, value]) => value)
    return entries.map(([key, _]) => key)
  }

  get screeningProgressJSON() {
    const entries = Object.entries(this.screeningProgress).filter(([_, value]) => value)
    return entries.map(([key, _]) => key)
  }

  get tierFilters() {
    return (
      {
        [InstitutionTier.One]: [InstitutionTier.One],
        [InstitutionTier.Two]: [InstitutionTier.One, InstitutionTier.Two],
        [InstitutionTier.Three]: [InstitutionTier.One, InstitutionTier.Two, InstitutionTier.Three],
      }[String(this.institutionTier)] || []
    )
  }
}

export const defaultFilter = plainToInstance(Filter, {
  keywords: '',
  roles: [],
  skills: {
    matchAll: false,
    stacks: [],
  },
  primarySkills: {
    matchAll: false,
    stacks: [],
  },
  educationDegree: null,
  institutionTier: null,
  restrictTierToBachelors: false,
  payRate: {
    type: 'Monthly',
    min: 0,
    max: Infinity,
  },
  yearsOfExperience: {
    min: 0,
    max: 10,
  },
  onlyVerifiedEnglishLevel: false,
  onlyTalentWithoutActiveJob: false,
  onlyTalentWithAdvertiseConsent: false,
  onlyTalentWithPersonalWebsite: false,
  englishLevel: {
    C1: false,
    C2: false,
    B1: false,
    B2: false,
    A1: false,
    A2: false,
  },
  profileFulfillmentStatus: {
    excellent: false,
    sufficient: false,
    under_review: false,
  },
  profileStatus: {
    active: false,
    passive: false,
    paused: false,
    unavailable: false,
  },
  staStatus: {
    strong_pass: false,
    pass: false,
    in_progress: false,
    no_assessment: false,
    fail: false,
    strong_fail: false,
  },
  screeningProgress: {
    [ScreeningAssignmentProgress.Approved]: false,
    [ScreeningAssignmentProgress.Declined]: false,
    [ScreeningAssignmentProgress.Rejected]: false,
    [ScreeningAssignmentProgress.Submitted]: false,
    [ScreeningAssignmentProgress.Expired]: false,
    [ScreeningAssignmentProgress.Answered]: false,
    [ScreeningAssignmentProgress.StartedAnswering]: false,
    [ScreeningAssignmentProgress.Viewed]: false,
    [ScreeningAssignmentProgress.Assigned]: false,
  },
  engagementPreferences: {
    type: null,
    freelancingWorkingHoursPerWeek: {
      min: 5,
      max: 40,
    },
  },
  country: [],
  assessmentTags: {
    mustHave: [],
    mustNotHave: [],
  },
  proficiencies: {
    mustHave: [],
    mustNotHave: [],
  },
  mockInterviewDone: false,
})
