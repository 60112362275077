import { cn } from 'modules/shared'
import { Paragraph, Text } from 'shared/components/atoms'

interface SkillTagWithExperienceProps {
  label: string
  experience: number
  highlighted?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

export const SkillTagWithExperience = ({
  label,
  experience,
  highlighted,
  size = 'sm',
}: SkillTagWithExperienceProps) => {
  const experienceLabel = (() => {
    if (experience === 0) return '<1 yr.'
    if (experience >= 10) return `${experience}+ yrs.`
    if (experience > 1) return `${experience} yrs.`
    return `${experience} yr.`
  })()

  const Label = () => {
    if (['xs', 'sm'].includes(size)) return <Text size="text-sm">{label}</Text>

    return <Paragraph size="body-md">{label}</Paragraph>
  }

  return (
    <div className="flex gap-2 items-center">
      <Label />
      <div
        className={cn('py-1 px-3 bg-neutral-lightest rounded-full flex items-center', {
          'bg-success-lighter': highlighted,
          'py-1 px-3': ['xs', 'sm', 'md'].includes(size),
          'py-3 px-4': size === 'lg',
        })}
      >
        <Text
          size={size === 'xs' ? 'text-xs' : 'text-sm'}
          className={cn({ 'leading-4': size === 'xs' })}
        >
          {experienceLabel}
        </Text>
      </div>
    </div>
  )
}
