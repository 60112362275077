import { useAuth } from 'shared/hooks'
import { JobListingsPage } from './JobListingsPage'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export const EmployerHomepage = () => {
  const navigate = useNavigate()
  const {
    user: { company },
  } = useAuth()

  useEffect(() => {
    if (!company?.approved) navigate('/welcome-not-ready')
  }, [company?.approved])

  if (!company?.approved) {
    return null
  }

  return <JobListingsPage />
}
