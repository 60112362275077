import { Text } from 'shared/components/atoms'
import { ChangeLog } from 'candidates-search/domain'
import { useFormatting } from 'shared/hooks'

interface WorkExperienceProps {
  changeLog: ChangeLog
}

export const WorkExperience = ({ changeLog }: WorkExperienceProps) => {
  const { formatMonthsGap } = useFormatting()
  const { workExperience } = changeLog
  if (!changeLog.isWorkExperienceRelated || !workExperience) return null

  const { title, organization, startDateObject, endDateObject } = workExperience

  return (
    <div className="flex gap-2">
      <Text size="text-xs" weight="font-normal" className="text-neutral-medium !whitespace-nowrap">
        Work experience:
      </Text>
      <Text size="text-xs" weight="font-normal" className="text-neutral-darkest">
        {`${organization.name} - ${title} - ${formatMonthsGap(startDateObject, endDateObject, false)}`}
      </Text>
    </div>
  )
}
