import { useMutation, useQueryClient } from 'react-query'

import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useProfileAdvertisement = (profileId: string) => {
  const { post, delete: deleteReq } = useNetwork()
  const { toastError, toastSuccess } = useToast()
  const { captureException } = useMonitoring()
  const client = useQueryClient()

  const { mutateAsync: grant, isLoading: isGranting } = useMutation(
    () => {
      return post(`/profile/${profileId}/preferences/advertisement-consent`)
    },
    {
      onSuccess: () => {
        client.invalidateQueries(`${profileId}-preferences`)
        toastSuccess("You've successfully granted Strider permission to advertise your profile.")
      },
      onError: (error) => {
        toastError('Failed to grant advertisement consent.')
        captureException(error)
      },
    },
  )

  const { mutateAsync: revoke, isLoading: isRevoking } = useMutation(
    (payload: {
      additionalComments: Optional<string>
      otherReason: Optional<string>
      reasons: Array<string>
    }) => {
      return post(`/profile/${profileId}/preferences/advertisement-consent/revokings`, payload)
    },
    {
      onSuccess: () => {
        client.invalidateQueries(`${profileId}-preferences`)
        toastSuccess(
          "You've successfully sent a request to remove Strider's permission to advertise your profile.",
        )
      },
      onError: (error) => {
        toastError('Failed to revoke advertisement consent.')
        captureException(error)
      },
    },
  )

  const { mutateAsync: undoRevoking, isLoading: isUndoing } = useMutation(
    () => {
      return deleteReq(`/profile/${profileId}/preferences/advertisement-consent/revokings`)
    },
    {
      onSuccess: () => {
        client.invalidateQueries(`${profileId}-preferences`)
        toastSuccess("We've undone your request to revoke Strider's advertising permission.")
      },
      onError: (error) => {
        toastError('Failed to undo advertisement consent revoking request.')
        captureException(error)
      },
    },
  )

  return { revoke, isRevoking, undoRevoking, isUndoing, grant, isGranting }
}
