export enum JobOpportunityDeclinationReasons {
  MissingRequiredSkills = 'missing_required_skills',
  UnclearJobDescription = 'unclear_job_description',
  UnwantedIndustryMarket = 'unwanted_industry_market',
  LongHiringProcess = 'long_hiring_process',
  LackCompanyInfo = 'lack_company_info',
  LowPayRate = 'low_pay_rate',
  NotAvailable = 'not_available',
  Other = 'other',
}
