import { Text } from 'shared/components/atoms'
import { useFormatting } from 'shared/hooks'
import { useRateLabel } from 'hooks/hire'
import { PaymentModel } from 'job-listing/enums'

export const HiringCost = ({
  hiringCost,
  paymentModel,
}: {
  hiringCost: number
  paymentModel: PaymentModel
}) => {
  const { formatCurrency } = useFormatting()
  const rate = formatCurrency(hiringCost)
  const { label, rateLabel } = useRateLabel({ rate, paymentModel })

  return (
    <div className="flex flex-col gap-2 mb-10 print:hidden">
      <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
        {label}
      </Text>
      <Text size="text-2xl" weight="font-medium" className="text-neutral-darkest">
        {rateLabel}
      </Text>
    </div>
  )
}
