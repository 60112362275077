import { useContext, useEffect } from 'react'

import {
  AssessmentsCard,
  BenefitsCard,
  EnglishVettingCard,
  Header,
  JobOpportunityOnHold,
  ProfilePreview,
  ReferCard,
  Stepper,
  TasksCard,
} from './components'

import { useFetchJobOpportunities } from './hooks'
import { LayoutContext } from 'contexts/layout'

import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { VettingPackageResponse } from 'vetting/domain'
import { cn } from 'modules/shared'
import { useFetchPreferences } from 'hooks/profile'
import { useProfileAvailability } from 'hooks/profile/useProfileAvailability'

interface Props {
  profile: ProfileOverview
  vetting: Nullable<VettingPackageResponse>
}

export const Dashboard = ({ profile, vetting }: Props) => {
  const { setDefaultContainer, setRootPadding, setRootHaveLimitedSize } = useContext(LayoutContext)
  const { jobOpportunities, appliedJobOpportunities } = useFetchJobOpportunities()
  const { preferences } = useFetchPreferences(profile.id)
  const { isPaused, isUnavailable } = useProfileAvailability(preferences.availability)

  useEffect(() => {
    setRootPadding(false)
    setRootHaveLimitedSize(false)
    return () => setDefaultContainer()
  }, [])

  return (
    <>
      <Header
        firstName={profile.firstName}
        jobOpportunities={jobOpportunities}
        vetting={vetting}
        profileId={profile.id}
        preferences={preferences}
        isDeactivated={isPaused || isUnavailable}
      />
      <div
        className={cn(
          'w-full max-w-[1032px] m-auto -mt-[20px]',
          'px-6 pb-[68px] xl:px-0 xl:pb-16 z-10',
          'grid grid-cols-12 md:gap-x-6 gap-y-6',
          { 'md:-mt-[70px]': !isPaused && !isUnavailable },
        )}
      >
        <div className="col-span-12">
          <ProfilePreview profile={profile} />
        </div>
        <div className="col-span-12">
          <JobOpportunityOnHold
            appliedJobOpportunities={appliedJobOpportunities}
            vetting={vetting}
          />
        </div>
        <Stepper
          profileCompleted={profile.fulfillment.percentage === 100}
          englishCheckCompleted={Boolean(vetting)}
        />
        <div className="col-span-12">
          <ReferCard />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <TasksCard
            vetting={vetting}
            appliedJobOpportunities={appliedJobOpportunities}
            isDeactivated={isPaused || isUnavailable}
          />
        </div>
        <div className="col-span-12 lg:col-span-6 flex flex-col gap-10 lg:gap-6">
          <BenefitsCard />
          <EnglishVettingCard vetting={vetting} />
          <AssessmentsCard />
        </div>
      </div>
    </>
  )
}
