import { useLocalStorage } from 'shared/hooks/storage'
const JOB_OPPORTUNITY_ANSWERS_KEY = 'job-opportunity-answers'

export interface SavedAnswer {
  index: string
  value: string | number | null
  workExperiences: Array<{ id: string; label: string }>
  noExperience: Optional<boolean>
}

type JobOpportunityAnswers = {
  [jobOpportunityId: string]: Nullable<Array<SavedAnswer>>
}

const setAnswerValue = (
  answers: JobOpportunityAnswers,
  jobOpportunityId: string,
  value: SavedAnswer,
) => {
  if (!answers[jobOpportunityId]) {
    answers[jobOpportunityId] = [value]
    return answers
  }

  const index = answers[jobOpportunityId]!.findIndex((answer) => answer.index === value.index)
  if (index === -1) {
    answers[jobOpportunityId] = [...answers[jobOpportunityId]!, value]
    return answers
  }

  answers[jobOpportunityId]![index] = value
  return answers
}

export const usePersisJobOpportunityAnswers = (jobOpportunityId: string) => {
  const [answers, setAnswers] = useLocalStorage<JobOpportunityAnswers>(
    JOB_OPPORTUNITY_ANSWERS_KEY,
    {},
  )

  const persist = (value: SavedAnswer) => {
    const newAnswers = setAnswerValue(answers, jobOpportunityId, value)
    setAnswers((prev) => Object.assign(prev, newAnswers))
  }

  const getAnswers = () => {
    return answers[jobOpportunityId]
  }

  const remove = () => {
    if (!answers) return
    const { [jobOpportunityId]: _, ...rest } = answers
    setAnswers(Object.assign({}, rest))
  }

  return { persist, getAnswers, remove }
}
