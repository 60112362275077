import { QuestionSchema } from 'screening/domain'
import { BooleanExpectedAnswer, QuestionType } from 'screening/enums'
import { AnySchema, ArraySchema, array, mixed, object, string, number } from 'yup'
import { AnyObject } from 'yup/lib/types'

const maxLengthStrategy = {
  [QuestionType.Boolean]: 0,
  [QuestionType.LongText]: 800,
  [QuestionType.ShortText]: 300,
  [QuestionType.Numeric]: 0,
}

const buildRequiredWorkExperienceSchema = (
  question: QuestionSchema,
  schema: ArraySchema<AnySchema, AnyObject>,
) => {
  if (question.type === QuestionType.Boolean) {
    schema = schema.when(`value`, {
      is: BooleanExpectedAnswer.Yes,
      then: schema.min(1, 'Select at least 1 work experience.'),
      otherwise: schema.optional(),
    })

    return schema
  }

  if (question.type === QuestionType.Numeric) {
    schema = schema.when('value', {
      is: (value: number) => value > 0,
      then: schema.min(1, 'Select at least 1 work experience.'),
      otherwise: schema.optional(),
    })

    return schema
  }

  return schema.min(1, 'Select at least 1 work experience.')
}

const buildWorkExperiencesSchema = (question: QuestionSchema) => {
  let schema: ArraySchema<AnySchema, AnyObject>

  schema = array().of(object())

  if (question.workExperienceRequired) {
    schema = buildRequiredWorkExperienceSchema(question, schema)
  } else {
    schema = schema.optional()
  }

  schema = schema.default([])

  return schema
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const numericQuestionValueValidator = (value: any) => {
  if (value === '' || value === null || isNaN(Number(value))) return undefined
  return Number(value)
}

const buildQuestionSchema = (question: QuestionSchema) => {
  const buildTextSchema = () => {
    const maxLength = maxLengthStrategy[question.type]

    if (question.required) {
      return string()
        .test(
          'max_length',
          `The answer should not have more than ${maxLength} characters`,
          function (_value) {
            return !this.parent.value || this.parent.value?.length <= maxLength
          },
        )
        .required('Required field.')
    }

    return string()
      .test(
        'max_length',
        `The answer should not have more than ${maxLength} characters`,
        function (_value) {
          return !this.parent.value || this.parent.value?.length <= maxLength
        },
      )
      .optional()
      .nullable()
  }

  const schemas = {
    [QuestionType.Boolean]: {
      build: () => {
        if (question.required) {
          return mixed()
            .oneOf([BooleanExpectedAnswer.Yes, BooleanExpectedAnswer.No])
            .required('Required field.')
        }

        return mixed().optional().nullable()
      },
    },
    [QuestionType.ShortText]: { build: buildTextSchema },
    [QuestionType.LongText]: { build: buildTextSchema },
    [QuestionType.Numeric]: {
      build: () => {
        if (question.required) {
          return number()
            .min(0)
            .required('Required field.')
            .transform((_, val) => numericQuestionValueValidator(val))
        }

        return number()
          .optional()
          .nullable()
          .transform((_, val) => numericQuestionValueValidator(val))
      },
    },
  }

  let schema = schemas[question.type].build()

  return schema
}

export const schemaBuilder = (name: string, question: QuestionSchema) => {
  return {
    [name]: object({
      value: buildQuestionSchema(question),
      workExperiences: buildWorkExperiencesSchema(question),
    }),
  }
}
