import { useMemo } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { object, string } from 'yup'

import { Autocomplete, Suggestion } from 'shared/components/atoms'
import { Tags } from '.'

import { useForm } from 'shared/hooks'
import { useTag } from './useTag'

interface Value {
  id: string | number | null
  label: string
  persisted?: boolean
}

interface SelectTagsProps {
  name: string
  id?: string
  options: Suggestion[]
  selectedOptions: Suggestion[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  children?: React.ReactNode
  containerClassName?: string
  maxTagsNumber?: number
  maxLength?: number
  warningOnly?: boolean
  error?: string
  allowCreation?: boolean
  onCreateOption?: (label: string) => Promise<{ data: Suggestion }>
  matchDescription?: boolean
  showDescription?: boolean
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  hideTags?: boolean
  onAdd?: (value: Suggestion) => void
}

const schema = object().shape({
  option: string(),
})

const queryMatch = (query: string | null, label: string) =>
  new RegExp(String(query), 'gi').test(label)

export const SelectTags = ({
  id,
  name,
  options,
  selectedOptions,
  setValue,
  watch,
  children,
  containerClassName,
  maxTagsNumber,
  maxLength,
  warningOnly = false,
  error,
  allowCreation = false,
  onCreateOption,
  matchDescription,
  showDescription,
  placeholder = 'e.g. JavaScript',
  disabled,
  isLoading,
  hideTags = false,
  onAdd,
}: SelectTagsProps) => {
  const { format } = useTag()

  const values = watch(name, []) as Array<Value>

  const addValue = (value: Suggestion) => {
    const id = value.id || null
    setValue(name, values.concat({ id, label: value.label, persisted: value.persisted }), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    })
  }

  const handleAdd = async (value: Suggestion) => {
    const add = onAdd || addValue
    if (!value) return

    if (value.id) return add(value)
    if (onCreateOption) return onCreateOption(value.label).then(({ data }) => add(data))

    const alreadyExists = values.find(({ label }) => queryMatch(label, value.label))
    if (alreadyExists) return

    add(Object.assign(value, { persisted: false }))
  }

  const handleRemove = (id: number | string | null, label: string | null) => {
    let newValues: Array<Value> = []

    if (id) newValues = values.filter(({ id: valueId }) => valueId !== id)
    else if (label) newValues = values.filter(({ label: valueLabel }) => valueLabel !== label)

    setValue(name, [...newValues], { shouldDirty: true, shouldValidate: true })
  }

  const {
    register,
    watch: watchOption,
    setValue: setValueOption,
  } = useForm({ schema, defaultValues: {} })

  const customRegister = () => [error, register('option').pop()]

  const suggestions = useMemo(
    () => options.filter(({ id }) => !values.find(({ id: valueId }) => id == valueId)),
    [values, options],
  )

  return (
    <div className={containerClassName}>
      <Autocomplete
        id={id || name}
        name="option"
        suggestions={suggestions}
        selectedSuggestions={selectedOptions}
        register={customRegister}
        watch={watchOption}
        handleAdd={handleAdd}
        allowCreation={allowCreation}
        showHint={false}
        placeholder={placeholder}
        disabled={disabled || Boolean(maxTagsNumber && values.length >= maxTagsNumber)}
        isLoading={isLoading}
        maxLength={maxLength}
        warningOnly={warningOnly}
        enableErrorState
        matchDescription={matchDescription}
        showDescription={showDescription}
        onInputChange={format}
        setValue={setValueOption}
      />
      {children}
      {!hideTags && (
        <div className="mt-3">
          <Tags values={values || []} onRemove={handleRemove} />
        </div>
      )}
    </div>
  )
}
