import { SliderOption } from 'shared/components/atoms'
import { useMemo } from 'react'

export const useEngagementPreferences = () => {
  const workingHoursOptions = useMemo(
    () =>
      Array.from(Array(9).keys())
        .slice(1)
        .map((hour) => ({
          value: hour * 5,
          label: `${hour * 5}h per week`,
        })) as Array<SliderOption>,
    [],
  )

  return { workingHoursOptions }
}
