import { ReactNode } from 'react'
import classNames from 'classnames'
import * as SliderPrimitive from '@radix-ui/react-slider'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

interface Props {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormValue: UseFormSetValue<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  step?: number
  max?: number
  trackBackground?: string
  children: (value: number) => ReactNode
}

export const SingleSelectorSlider = ({
  name,
  setFormValue,
  watch,
  children,
  step = 5,
  max = 100,
  trackBackground = 'bg-neutral-light',
}: Props) => {
  const value = watch(name) as number

  const handleChange = (values: Array<number>) => {
    const [value] = values
    setFormValue(name, value, { shouldTouch: true })
  }

  return (
    <div className="flex flex-col gap-2">
      {children(value)}
      <SliderPrimitive.Root
        className="flex items-center h-5 relative"
        step={step}
        value={[value]}
        defaultValue={[value]}
        onValueChange={handleChange}
        max={max}
      >
        <SliderPrimitive.Track className={classNames('grow h-0.5 relative', trackBackground)}>
          <SliderPrimitive.Range className="absolute bg-brand-medium h-0.5" />
        </SliderPrimitive.Track>

        <SliderPrimitive.Thumb
          className="
            block w-6 h-6 rounded-full border-2 border-neutral-light bg-neutral-day 
            hover:bg-neutral-lightest shadow-[0_4px_8px_-4px_rgba(0,0,0,0.08) cursor-pointer
          "
        />
      </SliderPrimitive.Root>
    </div>
  )
}
