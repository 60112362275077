import { Spinner } from 'shared/components/atoms'
import { CandidateContext } from 'contexts/candidate'
import { LayoutContext } from 'contexts/layout'
import { useAuth } from 'shared/hooks'
import { ProfileCertificateSection } from 'pages/JobSeekerProfile/components/ProfileCertificateSection'
import { ProfileEducationSection } from 'pages/JobSeekerProfile/components/ProfileEducationSection'
import { ProfileOverviewSection } from 'pages/JobSeekerProfile/components/ProfileOverviewSection'
import { ProfileViewTracker } from 'pages/JobSeekerProfile/components/ProfileViewTracker'
import { ProfileWorkExperienceSection } from 'pages/JobSeekerProfile/components/ProfileWorkExperienceSection'
import { ReferralSidebar } from 'pages/JobSeekerProfile/components/Sidebar'
import { useCandidatePageTitle } from 'pages/JobSeekerProfile/hooks/useCandidatePageTitle'
import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const noop = () => {}

export const ReferralProfilePage = () => {
  const {
    candidate: profile,
    isLoading: isFetching,
    isReloading: isRefetching,
    loadCandidate,
  } = useContext(CandidateContext)
  const { setRootHaveLimitedSize, setDefaultContainer, setRootPadding } = useContext(LayoutContext)

  const { id } = useParams()
  const { isPending: isUserLoading } = useAuth()

  useCandidatePageTitle(profile)

  useEffect(() => {
    loadCandidate(id)
    setRootHaveLimitedSize(false)
    setRootPadding(true)

    return () => setDefaultContainer()
  }, [])

  if (isUserLoading || (isFetching && !isRefetching)) return <Spinner />

  return (
    <div className="flex flex-col container self-center items-center md:mt-16">
      <div className="w-full flex flex-col items-start">
        <div className="flex flex-col lg:flex-row gap-16 lg:gap-16 xl:gap-28 print:gap-3 w-full justify-center">
          <ReferralSidebar profile={profile} />

          <div className="w-full flex-[0_0_680px] xl:flex-[0_0_744px]">
            <ProfileOverviewSection readOnly profile={profile} onEdit={noop} onBioEdit={noop} />
            <ProfileWorkExperienceSection
              profileId={profile.id}
              readOnly
              shouldTruncate
              experiences={profile.experiencesHistory}
              groupedExperiences={profile.groupedExperiences}
              onAddWorkExperienceClick={noop}
              onEditWorkExperienceClick={noop}
            />
            <ProfileEducationSection
              readOnly
              shouldTruncate
              educations={profile.educationHistory}
              onAddEducationClick={noop}
              onEditEducationClick={noop}
            />
            <ProfileCertificateSection
              readOnly
              shouldTruncate
              certifications={profile.certifications}
              onAddCertificationClick={noop}
              onEditCertificationClick={noop}
            />
          </div>
        </div>
      </div>
      <ProfileViewTracker profile={profile} />
    </div>
  )
}
