import { ProfileStatus } from 'modules/candidates/enums'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'

export const nullProfile: ProfileOverview = {
  id: '',
  bioText: '',
  firstName: '',
  gitRepositoriesUrl: '',
  interestRoles: [],
  lastName: '',
  location: null,
  yearlyRate: null,
  hourlyRate: null,
  personalSite: '',
  linkedInUrl: null,
  profileStacks: [],
  selfIntroduction: '',
  experiencesHistory: [],
  groupedExperiences: [],
  educationHistory: [],
  certifications: [],
  photoUrl: null,
  selfIntroductionAudioUrl: null,
  assessments: [],
  submittedAt: null,
  approvedByEmail: null,
  rejectedByEmail: null,
  vettedByEmail: null,
  introCallDone: null,
  onboardingCallDone: false,
  status: ProfileStatus.Active,
  pausedUntil: null,
  fullLocation: '',
  enableTechnicalAssessmentOptionsViewing: false,
  isMockInterviewDone: false,
  noticePeriodDays: null,
  offerSent: false,
  fulfillment: {
    percentage: 0,
    rules: [],
  },
  whatsAppNumber: null,
  lastWorkExperience: null,
  preferences: null,
  englishLevel: null,
  offers: [],
  skippedEnglishVetting: false,
  beginnerEnglishSpeaker: false,
  fewYearsOfExperience: false,
  outOfBounds: {
    byCountry: false,
    byOpenToRole: false,
    byReferralCountry: false,
  },
  referralStatus: null,
  referralDescription: null,
  matchingsForOffer: [],
  invitedFriends: false,
  canInviteFriends: false,
  jobOpportunities: [],
  assessmentTags: [],
  proficiencies: [],
  tagsUpdatedAt: null,
}
