import { Tags } from 'shared/components/atoms'
import { Stack } from 'pages/JobSeekerProfile/types'
import { ReactComponent as WarningIcon } from 'icons/alert-triangle.svg'

interface WorkExperienceTagsProps {
  skills: Array<Stack>
  readOnly: boolean
}

export const WorkExperienceTags = ({ skills, readOnly }: WorkExperienceTagsProps) => {
  const missingSkills = skills.length == 0
  const hasSkills = !missingSkills
  const showWarning = missingSkills && !readOnly

  return (
    <>
      {hasSkills && <Tags isReadOnly values={skills} tagClassName="mb-0" />}
      {showWarning && (
        <div className="flex flex-row w-full items-center bg-warning-lighter py-2 px-3 gap-3 rounded text-sm">
          <WarningIcon className="min-w-[24px] h-6 stroke-warning-medium" /> Please add the main
          skills used by you in this position.
        </div>
      )}
    </>
  )
}
