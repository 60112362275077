import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { ProfilePhoto } from './profile-photo'

export class ProfilePreview {
  constructor(private readonly profile: ProfileOverview) {}

  get photoUrl() {
    return new ProfilePhoto(this.profile.photoUrl).url
  }

  get roles() {
    return this.profile.interestRoles.length ? [this.profile.interestRoles[0]] : []
  }

  get additionalRolesLabel() {
    const roles = [...this.profile.interestRoles]
    roles.shift()
    return roles.length ? `+${roles.length} roles` : ''
  }
}
