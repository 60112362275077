import { cn } from 'modules/shared'
import { Text } from 'shared/components/atoms'
import { Checkbox } from 'shared/components/atoms/Form/Checkbox'
import { CheckboxGroupOption } from '.'

interface CheckboxGroupProps {
  options: Array<CheckboxGroupOption>
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  disabled?: boolean
  labelClassName?: string
}

export const CheckboxGroup = ({
  options,
  name,
  disabled,
  labelClassName,
  register,
}: CheckboxGroupProps) => {
  if (!options.length) return null

  return (
    <div className="flex flex-col gap-1">
      {options.map((option) => (
        <div key={option.label} className="flex items-center py-2">
          <Checkbox
            disabled={disabled}
            id={`${name}.${option.name}`}
            name={`${name}.${option.name}`}
            register={register}
          />
          <label
            htmlFor={`${name}.${option.name}`}
            className={cn(
              'flex !mb-0 select-none',
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
              labelClassName,
            )}
          >
            <Text className="text-neutral-darkest mr-1">{option.label}</Text>
            <Text className="text-neutral-medium">{option.hint}</Text>
          </label>
        </div>
      ))}
    </div>
  )
}
