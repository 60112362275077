import { CandidateContext } from 'contexts/candidate'
import { useAuth } from 'shared/hooks'
import { useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

export const JobSeekerRoutes = () => {
  const navigate = useNavigate()
  const { candidate } = useContext(CandidateContext)

  const {
    user: { accountId, isSeeker },
  } = useAuth()

  if (candidate.outOfBounds?.byCountry) navigate('/welcome-out-of-bounds')
  if (candidate.outOfBounds?.byOpenToRole) navigate('/welcome-unsupported')
  if (isSeeker) return <Outlet />
  if (accountId) navigate('/')

  return null
}
