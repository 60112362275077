import { CurrencyInput, FieldError, Input, Label, Textarea } from 'shared/components/atoms'
import { RadioGroup } from 'shared/components/atoms'
import { SelectableChip } from 'shared/components/molecules'
import {
  availabilities,
  lowPayRateOption,
  otherReasonOption,
  reasonsOptions,
  unavailableReasonOption,
} from '../consts'
import { useEffect } from 'react'
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { PaymentModel } from 'job-listing/enums'

interface Props {
  onSubmit: () => void
  watch: UseFormWatch<FieldValues>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => any[]
  setValue: UseFormSetValue<FieldValues>
  paymentModel: PaymentModel
}

export const DeclinationForm = ({ onSubmit, register, setValue, watch, paymentModel }: Props) => {
  const [reasonsError] = register('reasons')

  const isOtherReasonOptionSelected = watch(`reasons.${otherReasonOption.value}`)
  const isUnavailableOptionSelected = watch(`reasons.${unavailableReasonOption.value}`)
  const isLowPayRateOptionSelected = watch(`reasons.${lowPayRateOption.value}`)

  useEffect(() => {
    if (!isUnavailableOptionSelected) setValue('pausedDays', '')
  }, [isUnavailableOptionSelected])

  useEffect(() => {
    if (!isOtherReasonOptionSelected) setValue('otherReason', '')
  }, [isOtherReasonOptionSelected])

  useEffect(() => {
    if (!isLowPayRateOptionSelected) setValue('desiredPayRate', undefined)
  }, [isLowPayRateOptionSelected])

  return (
    <form onSubmit={onSubmit} id="job-opportunity-declination">
      <div>
        <div className="mb-10">
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            {Object.keys(reasonsOptions).map((reason) => (
              <SelectableChip
                key={reason}
                isSelected={watch(`reasons.${reason}`)}
                label={reasonsOptions[reason]}
                name={`reasons.${reason}`}
                id={reason}
                type="checkbox"
                register={register}
              />
            ))}
          </div>
          {reasonsError && (
            <div className="mt-2">
              <FieldError message={reasonsError} />
            </div>
          )}
        </div>
        {isLowPayRateOptionSelected && (
          <div className="mb-5">
            <Label htmlFor="desiredPayRate">
              What {paymentModel} pay rate would you be willing to accept for this job opportunity?
            </Label>
            <CurrencyInput
              name="desiredPayRate"
              register={register}
              setValue={setValue}
              watch={watch}
            />
          </div>
        )}
        {isUnavailableOptionSelected && (
          <div className="mb-5">
            <Label htmlFor="availability">
              For how long will you not be available for new job opportunities?
            </Label>

            <div className="flex flex-wrap gap-x-4 gap-y-2">
              <RadioGroup
                register={register}
                options={Object.keys(availabilities).map((key) => ({
                  label: availabilities[key],
                  value: key,
                }))}
                name="pausedDays"
                watch={watch}
              />
            </div>
          </div>
        )}
        {isOtherReasonOptionSelected && (
          <div className="mb-5">
            <Label htmlFor="otherReason">Please inform the other reason</Label>
            <Input name="otherReason" register={register} placeholder="Please inform" />
          </div>
        )}
      </div>
      <div>
        <Label htmlFor="notes" isOptional>
          Would you like to add something else?
        </Label>
        <Textarea name="notes" register={register} autoFocus />
      </div>
    </form>
  )
}
