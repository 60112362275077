import { SecondaryButton, Text, Title } from 'shared/components/atoms'
import { AlertDialog } from 'shared/components/molecules'
import { useToast } from 'shared/hooks'

import { ListingStatus } from 'job-listing/enums'
import { useManageJobListing } from 'job-listing/hooks'

interface JobListingPauserDialogProps {
  listingId: string
  isOpen: boolean
  closeDialog: () => void
  onClose?: (newStatus: ListingStatus) => void
}

export const JobListingPauserDialog = ({
  listingId,
  isOpen,
  closeDialog,
  onClose,
}: JobListingPauserDialogProps) => {
  const { updateStatus } = useManageJobListing(listingId)
  const { toastSuccess } = useToast()

  const pauseJobListing = () =>
    updateStatus(ListingStatus.Paused).then(() => {
      toastSuccess('Job listing paused.')
      closeDialog()
      onClose && onClose(ListingStatus.Paused)
    })

  return (
    <AlertDialog
      isOpen={isOpen}
      title={<Title size="heading">Pause job listing</Title>}
      description={
        <Text size="text-base">
          When a job listing is paused, it's no longer possible to add new candidates to the
          process. To resume using this job listing, it will need to be republished.
        </Text>
      }
      customActions={<SecondaryButton onClick={pauseJobListing}>Pause job listing</SecondaryButton>}
      onCancelClick={closeDialog}
    />
  )
}
