import { useEffect } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { Label, Paragraph, SelectTags, Text } from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'

import { useMarketType } from 'company/exports'
import { Preferences } from 'modules/profiles/profile-preferences'

interface NotWillingIndustriesProps {
  preferences: Preferences | undefined
  watch: UseFormWatch<Record<string, boolean>>
  setValue: UseFormSetValue<Record<string, Array<{ id: number; label: string | undefined }>>>
}

export const NotWillingIndustries = ({
  watch,
  setValue,
  preferences,
}: NotWillingIndustriesProps) => {
  const { marketTypes, getLabelById, isLoading: isMarketTypesLoading } = useMarketType()

  useEffect(() => {
    if (isMarketTypesLoading) return
    setValue(
      'notWillingToWorkIn',
      preferences?.notWillingToWorkIn.map(({ id }) => ({ id, label: getLabelById(id) })) || [],
    )
  }, [preferences?.notWillingToWorkIn, isMarketTypesLoading])

  return (
    <ContentWrapper>
      <Label htmlFor="notWillingToWorkIn" size="text-sm" isOptional>
        Which markets are you{' '}
        <Text size="text-sm" weight="font-medium" className="text-danger-dark">
          not willing{' '}
        </Text>
        to work in?
      </Label>
      <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark">
        Let us know which markets you are not willing to work with so that you only get matched with
        companies of your interest.
      </Paragraph>
      <SelectTags
        name="notWillingToWorkIn"
        setValue={setValue}
        watch={watch}
        options={marketTypes}
        containerClassName="mt-6"
        placeholder="e.g. Healthcare"
        selectedOptions={[]}
      />
    </ContentWrapper>
  )
}
