import { useContext, useEffect, useRef } from 'react'

import { PrimaryButton, SelectTags, Suggestion } from 'shared/components/atoms'
import { Modal, Footer } from 'shared/components/molecules'

import { EnglishCheckWarning } from './components'

import { Flags } from '../Flags'

import { useForm } from 'shared/hooks'

import { SearchContext } from 'candidates-search/contexts'

import { Candidate } from 'candidates-search/domain'
import { Tab } from 'candidates-search/enums'

import { schema } from './schema'
import { useAddFlags, useDeleteFlag } from '../../hooks'
import { MatchingFlagType, matchingFlagTypeLabels } from 'matching/enums'

interface DoubleCheckingFlagsModalProps {
  isOpen: boolean
  candidate: Candidate
  flags: Array<MatchingFlagType>
  onClose: (flags: Array<MatchingFlagType>) => void
  customTitle?: string
}

export const DoubleCheckingFlagsModal = ({
  isOpen,
  candidate,
  flags = [],
  onClose,
  customTitle,
}: DoubleCheckingFlagsModalProps) => {
  const { search, tab } = useContext(SearchContext)

  const formControl = useForm({
    schema: schema,
    defaultValues: {
      flags: flags.map((flag) => ({ id: flag, label: matchingFlagTypeLabels[flag] })),
    },
  })
  const { watch, setValue, reset } = formControl

  useEffect(() => {
    reset()
  }, [isOpen])

  const matchingId = search?.getMatchingByProfileId(candidate.id)?.matchingId

  const { addFlags } = useAddFlags({
    listingId: search?.listing?.id || '',
    matchingId: matchingId || '',
  })

  const { deleteFlag } = useDeleteFlag({
    listingId: search?.listing?.id || '',
    matchingId: matchingId || '',
  })

  const options = Object.values(MatchingFlagType).map((flag) => ({
    id: flag,
    label: matchingFlagTypeLabels[flag],
  }))
  const selectedFlags = watch('flags') as Array<{ id: MatchingFlagType; label: string }>

  const modalTitleRef = useRef(
    (() => {
      if (customTitle) return customTitle

      const prefix = flags.length ? 'Edit' : 'Add'
      const suffix = tab === Tab.Screening ? 'Screening' : 'Double Checking'

      return `${prefix} ${suffix} Flags`
    })(),
  )

  const handleAdd = async (value: Suggestion) => {
    setValue('flags', [...selectedFlags, value])
    await addFlags([value.id as MatchingFlagType])
  }

  const handleRemove = async (id: MatchingFlagType) => {
    const flags = selectedFlags.filter((flag) => flag.id !== id)
    setValue('flags', flags)
    await deleteFlag(id)
  }

  const handleClose = () => onClose(selectedFlags.map(({ id }) => id))

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Modal
        open={isOpen}
        title={modalTitleRef.current}
        handleClose={handleClose}
        contentContainerClassName="!p-10 overflow-visible"
        content={
          <>
            <form id="add-flags-form" onSubmit={handleClose}>
              <SelectTags
                name="flags"
                options={options}
                setValue={setValue}
                watch={watch}
                onAdd={handleAdd}
                placeholder="Select flags"
                hideTags
                containerClassName="mb-4"
                selectedOptions={[]}
              />
              <Flags flags={selectedFlags.map(({ id }) => id)} onRemove={handleRemove} />
            </form>
            <EnglishCheckWarning candidate={candidate} />
          </>
        }
        footer={
          <Footer>
            <PrimaryButton type="submit" form="add-flags-form">
              Done
            </PrimaryButton>
          </Footer>
        }
      />
    </div>
  )
}
