import { TrackingEvent } from '../tracking-event'

export class JobOpportunitiesDropdownOpened implements TrackingEvent {
  name = 'Job opportunity dropdown opened'

  constructor() {}

  get payload() {
    return {}
  }
}
