import { EnglishResult, englishResultLabel } from 'vetting/enums'
import { EnglishLevel, EnglishVettingProgress, selfEvaluationLabel } from '../enums'

export class EnglishVetting {
  level: EnglishResult | null
  declaredLevel: EnglishLevel | null
  vettingProgress: EnglishVettingProgress
  lastEvaluationId: string

  get isInProgress() {
    return [
      EnglishVettingProgress.InProgress,
      EnglishVettingProgress.NotStarted,
      EnglishVettingProgress.Paused,
      EnglishVettingProgress.RecordAgain,
      EnglishVettingProgress.WithIssues,
    ].includes(this.vettingProgress)
  }

  get isResultAvailable() {
    return Boolean(this.level)
  }

  get isReportAvailable() {
    return [EnglishVettingProgress.Done, EnglishVettingProgress.WithIssues].includes(
      this.vettingProgress,
    )
  }

  get isOnHold() {
    return [EnglishVettingProgress.Disqualified].includes(this.vettingProgress)
  }

  get isMissing() {
    return !this.isReportAvailable && !this.isInProgress && !this.isOnHold
  }

  get evaluationUrl() {
    if (!this.lastEvaluationId) return
    if (this.isReportAvailable)
      return `${window.location.origin}/english-check/evaluations/${this.lastEvaluationId}`
    return `${window.location.origin}/english-check/evaluations/${this.lastEvaluationId}/watch`
  }

  get progressLabel() {
    if (this.isInProgress) return 'English check in progress'
    if (this.isOnHold) return 'English check on hold'
    return (
      englishResultLabel[this.level!] || selfEvaluationLabel[this.declaredLevel!] || 'Unavailable'
    )
  }

  get smallProgressLabel() {
    if (this.isInProgress) return 'In progress'
    if (this.isOnHold) return 'On hold'
    return (
      englishResultLabel[this.level!] || selfEvaluationLabel[this.declaredLevel!] || 'Unavailable'
    )
  }
}
