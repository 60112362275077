import { ProfileWorkExperienceSection } from 'pages/JobSeekerProfile/components/ProfileWorkExperienceSection'

import { ProfileEducationSection } from 'pages/JobSeekerProfile/components/ProfileEducationSection'
import { ProfileCertificateSection } from 'pages/JobSeekerProfile/components/ProfileCertificateSection'

import { Candidate } from 'candidates-search/domain'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'

export class Content {
  constructor(
    private readonly searchCandidate: Candidate,
    private readonly profile: ProfileOverview,
  ) {}

  get workExperience() {
    return this.profile.experiencesHistory.length ? (
      <ProfileWorkExperienceSection
        readOnly
        shouldTruncate
        experiences={this.profile.experiencesHistory}
        groupedExperiences={this.profile.groupedExperiences}
        onAddWorkExperienceClick={() => {}}
        onEditWorkExperienceClick={() => {}}
        titleSize="heading"
        wrapperClassName="!mb-14"
      />
    ) : null
  }

  get educations() {
    return this.profile.educationHistory.length ? (
      <ProfileEducationSection
        readOnly
        shouldTruncate
        educations={this.profile.educationHistory}
        onAddEducationClick={() => {}}
        onEditEducationClick={() => {}}
        titleSize="heading"
        wrapperClassName="!mb-14"
      />
    ) : null
  }

  get certifications() {
    return this.profile.certifications.length ? (
      <ProfileCertificateSection
        readOnly
        shouldTruncate
        certifications={this.profile.certifications}
        onAddCertificationClick={() => {}}
        onEditCertificationClick={() => {}}
        titleSize="heading"
        wrapperClassName="!mb-14"
      />
    ) : null
  }
}
