import { useMutation, useQueryClient } from 'react-query'

import { useAuth, useMonitoring, useNetwork, useToast } from 'shared/hooks'

import { ProfileOverview } from '../../pages/JobSeekerProfile/types'
import { PositionsSlug } from 'modules/shared/enums'
import { UpdateOverview } from 'modules/profiles/dtos'

export const useOverview = (profileId: string) => {
  const client = useQueryClient()
  const { patch } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()
  const {
    user: { isSeeker },
  } = useAuth()

  const { mutateAsync: updateOverview, isLoading } = useMutation(
    ({
      overview,
      profile,
    }: {
      overview: Partial<ReturnType<typeof parseOverviewToFormSchema>>
      profile: ProfileOverview
      refetch?: boolean
    }) => {
      const updatePatch = Object.assign(parseOverviewToFormSchema(profile), overview)
      const payload = new UpdateOverview(updatePatch, isSeeker)
      return patch(`profile/${profileId}/overview`, payload.toJSON())
    },
    {
      onSuccess: (_, { refetch = true }) => {
        if (!refetch) return
        client.invalidateQueries('profile')
        client.invalidateQueries(`candidates/${profileId}`)
      },
      onError: (error: RequestError) => {
        const message = error.response?.data.message || 'Unknown error.'
        const normalizedMessage = message.replace('location.', '') // remove nested field name
        toastError('Error when updating the profile overview: ' + normalizedMessage)

        captureException(error)
      },
    },
  )

  const parseOverviewToFormSchema = (profile: ProfileOverview) => {
    let stacks = profile.profileStacks.map((profileStack) => {
      return {
        stackId: profileStack.stack.id,
        stackLabel: profileStack.stack.label,
        yearsOfExperience: profileStack.experience,
      }
    })

    const openToRoles = profile.interestRoles.concat(
      profile.otherInterestRoles?.map((role, index) => ({
        id: (index + 1) * -1,
        label: role,
        roleType: PositionsSlug.Other,
      })) || [],
    )

    return {
      stacks,
      firstName: profile.firstName,
      lastName: profile.lastName,
      location: profile.location,
      openToRoles,
      bioText: profile.bioText,
      personalSite: profile.personalSite,
      gitRepositoriesUrl: profile.gitRepositoriesUrl,
      whatsAppNumber: profile.whatsAppNumber,
      invitedFriends: profile.invitedFriends,
    }
  }

  return { updateOverview, parseOverviewToFormSchema, isUpdating: isLoading }
}
