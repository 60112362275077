export enum ProfileSections {
  Photo = 'Profile photo',
  BasicInfo = 'Basic information',
  RolesAndSkills = 'Roles and primary skills',
  Biography = 'Your bio',
  Preferences = 'Job preferences',
  WorkExperience = 'Work experience',
  Education = 'Education',
  PayRate = 'Pay rate',
}
