import { lazy, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Spinner, ClaimProtected } from 'shared/components/atoms'
import { AccountClaim, useAuth, useBrowserPrint, useLandingTracking } from 'shared/hooks'
import { CandidateContext } from 'contexts/candidate'
import { LayoutContext } from 'contexts/layout'

import { ProfileOverviewSection } from '../../components/ProfileOverviewSection'
import { ProfileEducationSection } from '../../components/ProfileEducationSection'
import { ProfileWorkExperienceSection } from '../../components/ProfileWorkExperienceSection'
import { ProfileCertificateSection } from '../../components/ProfileCertificateSection'
import { ProfileViewTracker } from '../../components/ProfileViewTracker'
import { CandidateSidebar } from '../../components/Sidebar'
import { PayRateSection } from './components'
import { useCandidatePageTitle } from '../../hooks/useCandidatePageTitle'

const Timeline = lazy(() =>
  import('candidates-search/exports').then((module) => ({ default: module.Timeline })),
)

const noop = () => {}

export const CandidateProfilePage = () => {
  const {
    candidate: profile,
    isLoading: isFetching,
    isReloading: isRefetching,
    loadCandidate,
  } = useContext(CandidateContext)
  const { setRootHaveLimitedSize, setDefaultContainer, setRootPadding, setHideBanner } =
    useContext(LayoutContext)

  const { id } = useParams()
  const { printableKey, printableRef, handlePrint } = useBrowserPrint()
  const { isPending: isUserLoading } = useAuth()
  const { isSdrFlow, resetLandingLocation } = useLandingTracking()

  useCandidatePageTitle(profile)

  useEffect(() => {
    if (isSdrFlow) resetLandingLocation()
  }, [isSdrFlow])

  useEffect(() => {
    loadCandidate(id)
    setRootHaveLimitedSize(false)
    setRootPadding(true)
    setHideBanner(true)

    return () => setDefaultContainer()
  }, [])

  if (isUserLoading || (isFetching && !isRefetching)) return <Spinner />

  return (
    <div key={printableKey} className="flex flex-col container self-center items-center md:mt-16">
      <div className="w-full flex flex-col items-start">
        <div
          ref={printableRef}
          className="flex flex-col lg:flex-row gap-16 lg:gap-16 xl:gap-28 print:gap-3 w-full justify-center"
        >
          <CandidateSidebar profile={profile} onPrint={handlePrint} />

          <div className="w-full max-w-[680px] xl:max-w-[744px] flex flex-col">
            <ProfileOverviewSection
              readOnly
              profile={profile}
              onEdit={noop}
              onBioEdit={noop}
              showAssessmentTags
            />

            <ClaimProtected require={AccountClaim.ManageProfiles}>
              <PayRateSection candidateId={id!} />
            </ClaimProtected>

            <ProfileWorkExperienceSection
              profileId={profile.id}
              readOnly
              shouldTruncate
              experiences={profile.experiencesHistory}
              groupedExperiences={profile.groupedExperiences}
              onAddWorkExperienceClick={noop}
              onEditWorkExperienceClick={noop}
            />
            <ProfileEducationSection
              readOnly
              shouldTruncate
              educations={profile.educationHistory}
              onAddEducationClick={noop}
              onEditEducationClick={noop}
            />
            <ProfileCertificateSection
              readOnly
              shouldTruncate
              certifications={profile.certifications}
              onAddCertificationClick={noop}
              onEditCertificationClick={noop}
            />
            <Timeline profileId={profile.id} />
          </div>
        </div>
      </div>
      <ProfileViewTracker profile={profile} />
    </div>
  )
}
