import { useContext } from 'react'
import { Tab } from 'candidates-search/enums'
import { SearchContext } from 'candidates-search/contexts'

interface VisibleWhenTabProps {
  is: Array<Tab>
  children: React.ReactNode
}
export const VisibleWhenTab = ({ is, children }: VisibleWhenTabProps) => {
  const { tab } = useContext(SearchContext)

  return is.includes(tab) ? <>{children}</> : null
}
