import { useAuth } from 'shared/hooks'
import { Outlet, useNavigate } from 'react-router-dom'

export const RecruiterRoutes = () => {
  const navigate = useNavigate()
  const {
    user: { accountId, isRecruiter },
  } = useAuth()

  if (isRecruiter) return <Outlet />
  if (accountId) navigate('/')

  return null
}
