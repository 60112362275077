import { useState } from 'react'

import { SurveyAnswer } from 'modules/surveys'

import { useNetwork } from 'shared/hooks'

export const useSurvey = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { post } = useNetwork()

  const submitAnswer = (payload: SurveyAnswer) => {
    setIsLoading(true)
    return post('/surveys', payload).finally(() => setIsLoading(false))
  }

  return { submitAnswer, isLoading }
}
