import { ProfileStatus } from 'modules/candidates/enums'
import { CompanySize, CompanySizeDesirableOptions } from 'company/enums'
import { Preferences } from 'modules/profiles/profile-preferences'

interface UpdatePreferencesRequest {
  jobSearchAvailability: {
    status: ProfileStatus
    days?: number
  }
  noticePeriodDays: number
  workingTime: {
    longTerm: boolean
    shortTerm: boolean
    hoursPerWeek?: {
      min: number
      max: number
    }
  }
  desirableCompanySize: Array<{
    companySize: CompanySize
    size: CompanySizeDesirableOptions
  }>
  notWillingToWorkIn: Array<number>
  allowsAdvertiseProfile: boolean
}

export class UpdatePreferencesDTO {
  constructor(private readonly preferences: Preferences) {}

  private get pausedDays() {
    const today = new Date()

    if (this.preferences.availability === ProfileStatus.Paused && !this.preferences.pausedUntil)
      return 30
    if (this.preferences.availability === ProfileStatus.Paused && this.preferences.pausedUntil)
      return Math.ceil(
        (new Date(this.preferences.pausedUntil).getTime() - today.getTime()) /
          (1000 * 60 * 60 * 24),
      )

    return undefined
  }

  private get notWillingToWorkIn() {
    return this.preferences.notWillingToWorkIn.map((value) => value.id)
  }

  private get noticePeriod() {
    const { noticePeriod } = this.preferences
    return (noticePeriod && noticePeriod * 7) || 0
  }

  private get hoursPerWeek() {
    if (this.preferences.jobTypes.shortTerm)
      return {
        min: Number(this.preferences.freelancingJobHours.min),
        max: Number(this.preferences.freelancingJobHours.max),
      }
  }

  private get workingTime() {
    return {
      longTerm: this.preferences.jobTypes.longTerm,
      shortTerm: this.preferences.jobTypes.shortTerm,
      hoursPerWeek: this.hoursPerWeek,
    }
  }

  toJSON(): UpdatePreferencesRequest {
    return {
      jobSearchAvailability: {
        status: this.preferences.availability,
        days: this.pausedDays,
      },
      noticePeriodDays: this.noticePeriod,
      workingTime: this.workingTime,
      desirableCompanySize: Object.keys(this.preferences.companySizes).map((size) => ({
        companySize: size as CompanySize,
        size: this.preferences.companySizes[size as CompanySize] as CompanySizeDesirableOptions,
      })),
      notWillingToWorkIn: this.notWillingToWorkIn,
      allowsAdvertiseProfile: this.preferences.allowsAdvertiseProfile,
    }
  }
}
