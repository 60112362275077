import { TrackingEvent } from '../tracking-event'

export class JobOpportunityView implements TrackingEvent {
  name = 'Job opportunity view'

  constructor(private id: string) {}

  get payload() {
    return { id: this.id }
  }
}
