import classNames from 'classnames'

import { Text } from 'shared/components/atoms'
import { Tooltip } from 'shared/components/atoms'

import { ReactComponent as InfoIcon } from 'icons/info-icon.svg'

interface StatementRowProps {
  id: string
  label: string
  value: string
  tooltip: string
  valueClassName?: string
}

export const StatementRow = ({ id, label, value, tooltip, valueClassName }: StatementRowProps) => {
  return (
    <div className="flex justify-between items-center">
      <Tooltip content={tooltip} childrenClassName="flex items-center gap-2">
        <Text size="text-sm" weight="font-normal" className="text-neutral-dark" id={id}>
          {label}
        </Text>
        <InfoIcon />
      </Tooltip>
      <Text
        size="text-sm"
        weight="font-medium"
        className={classNames(valueClassName)}
        aria-labelledby={id}
      >
        {value}
      </Text>
    </div>
  )
}
