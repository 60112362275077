import classNames from 'classnames'
import { CandidatePhoto } from './photo'
import { useSensitiveInformation } from '../SensitiveInformation'

interface Props {
  photoUrl: string | null
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl'
  className?: string
}

export const Picture = ({ photoUrl, className, size = 'md' }: Props) => {
  const sizes = {
    xs: 'w-6 h-6',
    sm: 'w-8 h-8',
    md: 'w-10 h-10',
    lg: 'w-14 h-14',
    xl: 'w-24 h-24',
    '2xl': 'w-[120px] h-[120px]',
    '3xl': 'w-32 h-32',
    '4xl': 'w-[168px] h-[168px] ',
  }

  const { blurSensitiveInfo } = useSensitiveInformation()

  const photo = new CandidatePhoto(photoUrl)

  return (
    <img
      src={photo.url || photo.fallbackUrl}
      className={classNames(' rounded-full object-cover', sizes[size], className, {
        'blur-sm': blurSensitiveInfo,
      })}
    />
  )
}
