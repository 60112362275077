import classNames from 'classnames'
import { ClipboardEventHandler, MouseEvent } from 'react'

import { Text } from 'shared/components/atoms'

import { Input } from '../Input'

interface IconInputProps {
  id?: Optional<string>
  icon: string | JSX.Element
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  inputClassName: string
  customError?: string
  successMessage?: string
  showFieldError?: boolean
  className?: string
  onPaste?: ClipboardEventHandler
  placeholder?: string
  autoComplete?: 'on' | 'off'
  readOnly?: boolean
  mode?: 'prefix' | 'suffix'
}

export const IconInput = ({
  id,
  icon,
  name,
  register,
  inputClassName,
  customError,
  successMessage,
  showFieldError = true,
  className,
  onPaste,
  placeholder,
  autoComplete = 'on',
  readOnly = false,
  mode = 'prefix',
}: IconInputProps) => {
  const handlePrefixClick = (event: MouseEvent<HTMLSpanElement>) => {
    const input = (event.target as HTMLSpanElement).parentElement?.querySelector('input')
    input?.focus()
  }

  return (
    <div className={classNames('relative', className)}>
      <Input
        name={name}
        register={register}
        customError={customError}
        className={inputClassName}
        successMessage={successMessage}
        showFieldError={showFieldError}
        onPaste={onPaste}
        placeholder={placeholder}
        autoComplete={autoComplete}
        readOnly={readOnly}
        id={id}
      />
      <Text
        weight="font-normal"
        size="text-base"
        className={classNames([
          'text-neutral-medium absolute top-2',
          { 'left-4': mode === 'prefix', 'right-4': mode === 'suffix' },
        ])}
        onClick={handlePrefixClick}
      >
        {icon}
      </Text>
    </div>
  )
}
