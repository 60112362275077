import { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { useMonitoring, useNetwork, useToast } from 'shared/hooks'
import { CandidateContext } from 'contexts/candidate'
import { AnswerJobOpportunityDTO } from 'job-opportunity/dtos'
import { AnswersDictionary } from 'job-opportunity/components/JobOpportunityModals/components/ApplicationModal/hooks'

export const useAnswerJobOpportunity = (
  screeningAssignmentId: string,
  jobOpportunityId: string,
  onSuccess: () => void,
) => {
  const { patch } = useNetwork()
  const { toastError } = useToast()
  const { candidate } = useContext(CandidateContext)
  const { captureException } = useMonitoring()
  const client = useQueryClient()

  const { mutateAsync: answer, isLoading } = useMutation(
    async (answers: AnswersDictionary) =>
      patch(
        `profile/${candidate.id}/screening-assignments/${screeningAssignmentId}/answers`,
        new AnswerJobOpportunityDTO(answers).toJSON(),
      ),
    {
      onSuccess: async () => {
        client.invalidateQueries('job-opportunities')
        await client.invalidateQueries(['job-opportunity', jobOpportunityId])
        onSuccess()
      },
      onError: (err) => {
        toastError('Failed to save the answers.')
        captureException(err)
      },
    },
  )

  return { answer, isLoading }
}
