import { AssessmentTag } from 'modules/profiles/domain'

export class AddAssessmentTagsDTO {
  constructor(private readonly tags: Array<AssessmentTag>) {}

  toJSON() {
    return {
      assessmentTagsIds: this.tags.map((tag) => tag.id),
    }
  }
}
