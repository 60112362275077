export enum ProfileEventFilter {
  ProfileUpdates = 'profile-updates',
  AssessmentTags = 'assessment-tags',
  Proficiencies = 'proficiencies',
  RolesOfInterest = 'roles-of-interest',
  MainSkills = 'main-skills',
  WorkExperience = 'work-experience',
  PayRate = 'pay-rate',
  EnglishCheck = 'english-check',
  STA = 'sta',
  Education = 'education',
  Certification = 'certification',
  Bio = 'bio',
  PersonalInfo = 'personal-info',
  Preferences = 'preferences',
  AdvertisementConsent = 'advertisement-consent',
}

export const profileEventFilterLabels: Record<ProfileEventFilter, string> = {
  [ProfileEventFilter.ProfileUpdates]: 'Profile updates',
  [ProfileEventFilter.AssessmentTags]: 'Assessment tags',
  [ProfileEventFilter.Proficiencies]: 'Proficiencies',
  [ProfileEventFilter.RolesOfInterest]: 'Roles of interest',
  [ProfileEventFilter.MainSkills]: 'Main skills',
  [ProfileEventFilter.WorkExperience]: 'Work experience',
  [ProfileEventFilter.PayRate]: 'Pay rate',
  [ProfileEventFilter.EnglishCheck]: 'English check',
  [ProfileEventFilter.STA]: 'STA',
  [ProfileEventFilter.Education]: 'Education',
  [ProfileEventFilter.Certification]: 'Certification',
  [ProfileEventFilter.Bio]: 'Bio',
  [ProfileEventFilter.PersonalInfo]: 'Personal info',
  [ProfileEventFilter.Preferences]: 'Preferences',
  [ProfileEventFilter.AdvertisementConsent]: 'Advertisement consent',
}
