import React, { useContext } from 'react'

import { TertiaryButton } from 'shared/components/atoms'
import { useUpsertMatching } from 'candidates-search/hooks'

import { ReactComponent as StarIcon } from 'icons/star.svg'

import { ActionProps } from '.'
import { SearchContext } from 'candidates-search/contexts'
import { MatchingStatus } from 'matching/enums'

export const Save = ({ profile }: ActionProps) => {
  const { search } = useContext(SearchContext)
  const { upsertMatching, isUpserting } = useUpsertMatching(profile.id)

  if (!search?.isListingLinked) return null

  const handleSave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    upsertMatching({ status: MatchingStatus.Saved })
  }

  return (
    <TertiaryButton
      icon={<StarIcon className="!w-6 !h-6" />}
      isIconBefore
      isLoading={isUpserting}
      onClick={handleSave}
    >
      Save
    </TertiaryButton>
  )
}
