import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Text, Paragraph } from 'shared/components/atoms'
import { ReactComponent as PlayIcon } from 'icons/play.svg'
import { useFetchRecordings } from 'vetting/exports'
import { CandidateContext } from 'contexts/candidate'
import { WatchRecordingsModal } from 'vetting/exports'
import { VettingPackageResponse } from 'vetting/domain'
import { VettingPackageStatus } from 'vetting/enums'

import { Card } from '../Card'
import { EnglishLevelLabel } from '../../consts'

export const EnglishVettingCard = ({ vetting }: { vetting: Nullable<VettingPackageResponse> }) => {
  const navigate = useNavigate()

  const [isWatchModalOpen, setIsWatchModalOpen] = useState(false)

  const { candidate } = useContext(CandidateContext)
  const { fetchedRecordings } = useFetchRecordings(candidate)

  const handleSubmitNewRecording = () => navigate('/english-vetting')
  const handleShowRecordings = () => setIsWatchModalOpen(true)
  const handleCloseModal = () => setIsWatchModalOpen(false)

  const handleClick =
    vetting?.status === VettingPackageStatus.RecordAgain
      ? handleSubmitNewRecording
      : handleShowRecordings

  if (!vetting || !fetchedRecordings) return null

  return (
    <>
      <Card className="flex flex-col items-stretch justify-center gap-4">
        <div className="flex flex-row items-center justify-between">
          <Text size="text-xl" weight="font-medium" className="text-neutral-darkest">
            English check results
          </Text>
        </div>

        <div
          className="flex flex-row items-center justify-start gap-4 p-6 border border-neutral-lighter rounded-lg hover:cursor-pointer"
          onClick={handleClick}
        >
          {/* @todo: Work on thumbnail: https://onstrider.atlassian.net/browse/STR-1718 */}
          {/* <iframe
            src={fetchedRecordings[EnglishVettingStep.AboutCandidate].embeddingUrl}
            className="w-[121px] h-[68px] rounded"
            title="About yourself recording"
          /> */}
          <div className="w-[121px] h-[68px] bg-neutral-lighter rounded flex items-center justify-center">
            <div className="w-8 h-8 rounded-full bg-neutral-medium flex items-center justify-center">
              <PlayIcon />
            </div>
          </div>
          <div className="flex-grow">
            {vetting.status === VettingPackageStatus.Approved && (
              <>
                <Text size="text-sm" weight="font-normal" className="text-neutral-dark block">
                  Your level is
                </Text>
                <Text size="text-xl" weight="font-medium" className="text-neutral-darkest">
                  {EnglishLevelLabel[vetting.levelCategory!]}
                </Text>
              </>
            )}
            {vetting.status === VettingPackageStatus.RecordAgain && (
              <>
                <Text
                  size="text-base"
                  weight="font-medium"
                  className="text-neutral-darkest block mb-1"
                >
                  It seems like there is a problem with your videos
                </Text>
                <Paragraph size="body-xs" weight="font-normal" className="text-neutral-dark">
                  Please check your videos to correct the problems and submit them again.
                </Paragraph>
              </>
            )}
            {vetting.status === VettingPackageStatus.ReviewInProgress && (
              <>
                <Text
                  size="text-base"
                  weight="font-medium"
                  className="text-neutral-darkest block mb-1"
                >
                  English check in review
                </Text>
                <Paragraph size="body-xs" weight="font-normal" className="text-neutral-dark">
                  We are evaluating the videos. We will contact you soon.
                </Paragraph>
              </>
            )}
          </div>
        </div>
      </Card>

      <WatchRecordingsModal
        open={isWatchModalOpen}
        onClose={handleCloseModal}
        recordings={fetchedRecordings}
      />
    </>
  )
}
