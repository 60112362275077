import classNames from 'classnames'

import { Paragraph, TertiaryButton, Text } from 'shared/components/atoms'

import { ReactComponent as CloseIcon } from 'icons/close.svg'
import { ReactComponent as AddIcon } from 'icons/add.svg'
import { ReactComponent as StarIcon } from 'icons/star.svg'
import { ReactComponent as CheckIcon } from 'icons/check--light.svg'

import { ProficiencyTag } from 'modules/profiles/domain'

interface Props {
  proficiencies: Array<ProficiencyTag>
  skills?: Array<{ id: number; label: string; highlight: boolean }>
  editOnHover?: boolean
  readOnly?: boolean
  onRemove: (id: number) => void
  onAdd?: () => void
}

interface TagProps {
  tag: ProficiencyTag
  type: 'starred' | 'success' | 'neutral'
  editOnHover?: boolean
  hideRemove?: boolean
  readOnly?: boolean
  onRemove?: (id: number) => void
}

const Tag = ({ tag, type, editOnHover, hideRemove, readOnly, onRemove }: TagProps) => {
  return (
    <div
      className={classNames(
        'flex items-center gap-1 py-2 px-3 rounded-full group-hover:bg-opacity-50 transition-all',
        {
          'bg-success-lighter': type === 'success',
          'bg-neutral-lightest': type === 'neutral',
          'bg-warning-lighter': type === 'starred',
        },
      )}
    >
      {type === 'starred' && (
        <StarIcon className="w-4 h-4 stroke-warning-light fill-warning-light" />
      )}
      {type === 'success' && <CheckIcon className="w-4 h-4 " />}
      <Text size="text-xs">{tag.label}</Text>
      {!hideRemove && !readOnly && (
        <CloseIcon
          className={classNames('stroke-neutral-medium hover:cursor-pointer transition-all', {
            'w-0 h-0 group-hover:w-4 group-hover:h-4': editOnHover,
            'w-4 h-4': !editOnHover,
          })}
          onClick={() => onRemove && onRemove(tag.id)}
        />
      )}
    </div>
  )
}

export const Tags = ({
  proficiencies,
  skills = [],
  editOnHover,
  readOnly,
  onRemove,
  onAdd,
}: Props) => {
  const AddProficiencyButton = () => {
    if (!editOnHover || readOnly) return null
    return (
      <TertiaryButton
        size="sm"
        className="opacity-0 group-hover:opacity-100 transition-all"
        icon={<AddIcon />}
        isIconBefore
        onClick={onAdd}
      >
        Add proficiency
      </TertiaryButton>
    )
  }

  if (!proficiencies.length && !skills.length) {
    return (
      <div className="flex gap-4 items-center">
        <Paragraph size="body-sm" className="text-neutral-medium">
          No proficiencies yet
        </Paragraph>
        <AddProficiencyButton />
      </div>
    )
  }

  return (
    <div className="flex gap-2 flex-wrap">
      {proficiencies.map((proficiency) => (
        <Tag
          key={proficiency.id}
          tag={proficiency}
          onRemove={onRemove}
          editOnHover={editOnHover}
          readOnly={readOnly}
          type="starred"
        />
      ))}
      {skills.map((skill) => (
        <Tag
          key={skill.id}
          tag={{ id: skill.id, label: skill.label }}
          type={skill.highlight ? 'success' : 'neutral'}
          hideRemove
        />
      ))}
      <AddProficiencyButton />
    </div>
  )
}
