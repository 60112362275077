export enum MatchingStatus {
  ScreeningApproved = 'screening_approved',
  Screening = 'screening',
  Saved = 'saved',
  DoubleChecking = 'double_checking',
  Shortlisted = 'shortlisted',
  Matched = 'matched',
  InterviewRequested = 'interview_requested',
  Rejected = 'rejected',
  Unavailable = 'unavailable',
  NotAFit = 'not_a_fit',
  Hired = 'hired',
}

export const clientFacingMatchingStatus = [
  MatchingStatus.Matched,
  MatchingStatus.InterviewRequested,
  MatchingStatus.Rejected,
  MatchingStatus.Unavailable,
  MatchingStatus.Hired,
]

export const internalMatchingStatus = [
  MatchingStatus.Saved,
  MatchingStatus.Shortlisted,
  MatchingStatus.NotAFit,
]

export const SUCCESS_MESSAGES = {
  [MatchingStatus.Screening]: 'Screening sent.',
  [MatchingStatus.ScreeningApproved]: '',
  [MatchingStatus.DoubleChecking]: '',
  [MatchingStatus.Saved]: 'Candidate saved.',
  [MatchingStatus.Shortlisted]: 'Candidate shortlisted.',
  [MatchingStatus.Matched]: 'Matching created.',
  [MatchingStatus.InterviewRequested]: '',
  [MatchingStatus.Rejected]: '',
  [MatchingStatus.NotAFit]: 'Candidate hidden.',
  [MatchingStatus.Unavailable]: '',

  [MatchingStatus.Hired]: '',
}
