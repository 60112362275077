import React, { useEffect } from 'react'

interface WrapperProps {
  children: React.ReactNode
  onOpenMenu?: () => void
}

export const Wrapper = ({ children, onOpenMenu }: WrapperProps) => {
  const keyDownHandler = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === '.') {
      onOpenMenu && onOpenMenu()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler)

    return () => document.removeEventListener('keydown', keyDownHandler)
  }, [])

  return (
    <div
      className="
        flex gap-2 items-center font-medium 
        whitespace-nowrap cursor-pointer text-white
      "
    >
      {children}
    </div>
  )
}
