import { useMutation, useQueryClient } from 'react-query'
import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useDeleteProficiency = (profileId: string) => {
  const { delete: remove } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()
  const client = useQueryClient()

  const { mutateAsync: deleteProficiency, isLoading: isDeleting } = useMutation(
    (id: number) => remove(`/profile/${profileId}/proficiencies/${id}`),
    {
      onSuccess: () => {
        client.invalidateQueries(`candidates/${profileId}`)
      },
      onError: (error: RequestError) => {
        toastError('Failed to remove proficiency: ' + error.response?.data.message)
        captureException(error)
      },
    },
  )

  return { deleteProficiency, isDeleting }
}
