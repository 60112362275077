import classNames from 'classnames'
import { HTMLAttributes } from 'react'

interface ProfileSectionWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  className?: string
}

export const ProfileSectionWrapper = ({
  children,
  className,
  ...restProps
}: ProfileSectionWrapperProps) => {
  return (
    <section
      {...restProps}
      className={classNames('mb-8 sm:mb-16 grow w-full print:mb-6', className)}
    >
      {children}
    </section>
  )
}
