import { MatchingPhoto } from './MatchingPhoto'
import classNames from 'classnames'
import { Tooltip } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { MatchingPreview } from 'job-listing/dtos'
import { ListingStatus } from 'job-listing/enums'

const MAX_NUMBER_PHOTOS_DESKTOP = 5
const MAX_NUMBER_PHOTOS_MOBILE = 3

interface MatchingPhotosProps {
  matchings: Array<MatchingPreview>
  matchingsCount: number
  listingStatus: ListingStatus
  listingId: string
}

export const MatchingPhotos = ({
  matchings,
  matchingsCount,
  listingStatus,
  listingId,
}: MatchingPhotosProps) => {
  if (!matchings.length) return null

  const buildMatchingPhoto = (candidatePhotoUrl: string | null, index: number) => (
    <MatchingPhoto
      key={listingId + '-' + candidatePhotoUrl + '-' + index}
      src={candidatePhotoUrl}
      listingStatus={listingStatus}
      className={classNames({
        '-mr-3': matchings.length > 1,
      })}
    />
  )

  const buildRemainingMatchings = (remainingCount: number) => (
    <div className=" flex items-center justify-center w-8 h-8 rounded-full bg-neutral-lightest -mr-3">
      <Text size="text-xs" weight="font-medium" className="text-neutral-dark">
        +{matchingsCount - remainingCount}
      </Text>
    </div>
  )

  return (
    <Tooltip content="Matchings">
      <div
        className={classNames('flex md:hidden', {
          'mr-3': matchings.length > 1,
        })}
      >
        {matchings
          .slice(0, 3)
          .map(({ profile: { photoUrl } }, index) => buildMatchingPhoto(photoUrl, index))}

        {matchingsCount > MAX_NUMBER_PHOTOS_MOBILE &&
          buildRemainingMatchings(MAX_NUMBER_PHOTOS_MOBILE)}
      </div>
      <div
        className={classNames('hidden md:flex', {
          'mr-3': matchings.length > 1,
        })}
      >
        {matchings.map(({ profile: { photoUrl } }, index) => buildMatchingPhoto(photoUrl, index))}

        {matchingsCount > MAX_NUMBER_PHOTOS_DESKTOP &&
          buildRemainingMatchings(MAX_NUMBER_PHOTOS_DESKTOP)}
      </div>
    </Tooltip>
  )
}
