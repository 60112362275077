import { useMutation, useQueryClient } from 'react-query'

import { MatchingStatus } from 'matching/enums'
import { useNetwork, useToast, useAuth } from 'shared/hooks'

export const useDeleteMatching = (
  listingId?: string,
  matchingId?: string,
  onDeleted?: () => void,
) => {
  const client = useQueryClient()
  const { delete: deleteReq } = useNetwork()
  const { toastSuccess, toastError } = useToast()
  const { user } = useAuth()

  const { mutateAsync, isLoading: isDeleting } = useMutation(
    () => deleteReq(`/listings/${listingId}/matchings/${matchingId}`),
    {
      onSuccess: () => {
        toastSuccess('Matching undone.')
        client.invalidateQueries('matchings')
        if (onDeleted) onDeleted()
      },
      onError: () => {
        toastError('Could not delete the matching.')
      },
    },
  )

  const deleteMatching = () => {
    if (!listingId || !matchingId) return
    return mutateAsync()
  }

  const canDeleteMatching = (status?: MatchingStatus, clientFacing?: boolean) => {
    if (!status) return false

    const statusesAllowedForDeletion = [
      MatchingStatus.Screening,
      MatchingStatus.ScreeningApproved,
      MatchingStatus.Saved,
      MatchingStatus.Shortlisted,
      MatchingStatus.NotAFit,
      MatchingStatus.Matched,
    ]

    const neverSharedWithClient = status === MatchingStatus.Unavailable && !clientFacing

    return (
      Boolean(matchingId && listingId) &&
      user.isMatchingsManager &&
      (statusesAllowedForDeletion.includes(status) || neverSharedWithClient)
    )
  }

  return { canDeleteMatching, deleteMatching, isDeleting }
}
