import { useAuth } from '@clerk/clerk-react'
import axios, { InternalAxiosRequestConfig } from 'axios'
import { useCallback, useEffect } from 'react'

const clerkSignInTemplate = process.env.REACT_APP_CLERK_SIGN_IN_TEMPLATE || 'token-with-email'

const client = axios.create({
  baseURL: String(process.env.REACT_APP_API_URL),
  headers: { Accept: 'application/json' },
})

const unauthenticatedClient = axios.create({
  baseURL: String(process.env.REACT_APP_API_URL),
  headers: { Accept: 'application/json' },
})

export const useNetwork = () => {
  const { getToken } = useAuth()

  const interceptor = useCallback(
    async (config: InternalAxiosRequestConfig) => {
      const token = await getToken({ template: clerkSignInTemplate })
      config.headers!.Authorization = `Bearer ${token}`

      return config
    },
    [getToken],
  )

  useEffect(() => {
    const interceptorId = client.interceptors.request.use(interceptor)

    return () => client.interceptors.request.eject(interceptorId)
  }, [interceptor])

  return client
}

export const useUnauthenticatedNetwork = () => unauthenticatedClient
