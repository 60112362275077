export class DeclineJobOpportunityDTO {
  constructor(
    private readonly reasons: { [key: string]: boolean },
    private readonly otherReason?: string,
    private readonly additionalNotes?: string,
    private readonly desiredPayRate?: string,
    private readonly pausedDays?: string,
  ) {}

  toJSON() {
    return {
      declinationFeedback: {
        otherReason: this.otherReason?.length ? this.otherReason : undefined,
        desiredPayRate: this.getDesiredPayRate(),
        reasons: this.getReasons(),
        additionalNotes: this.additionalNotes?.length ? this.additionalNotes : undefined,
        pausedDays: this.getPausedDays(),
      },
    }
  }

  private getReasons() {
    return Object.keys(this.reasons).filter((key) => this.reasons[key])
  }

  private getDesiredPayRate() {
    const desiredPayRateNumber = Number(this.desiredPayRate)
    return !this.desiredPayRate || isNaN(desiredPayRateNumber) ? undefined : desiredPayRateNumber
  }

  private getPausedDays() {
    const pausedDaysNumber = Number(this.pausedDays)
    return !this.pausedDays || isNaN(pausedDaysNumber) ? undefined : pausedDaysNumber
  }
}
