import classNames from 'classnames'
import { Title } from 'shared/components/atoms'
import { ReactNode } from 'react'
import { UpsertButton } from '../UpsertButton'

interface ProfileSectionTitleProps {
  titleSize?: 'heading' | 'large-heading'
  title: string
  onButtonClick?(): void
  isButtonEdit?: boolean
  readOnly: boolean
  children?: ReactNode
  className?: string
  id?: string
}

export const ProfileSectionTitle = ({
  id,
  titleSize = 'heading',
  title,
  onButtonClick,
  isButtonEdit = false,
  children,
  readOnly,
  className,
}: ProfileSectionTitleProps) => {
  return (
    <div className={classNames('flex flex-grow items-center h-fit', className)}>
      <div className="flex gap-3 items-center w-full">
        <Title id={id} size={titleSize} className="font-medium">
          {title}
        </Title>
        {children}
      </div>
      {Boolean(onButtonClick) && (
        <UpsertButton onClick={() => onButtonClick!()} readOnly={readOnly} isAdd={!isButtonEdit} />
      )}
    </div>
  )
}
