import { useMemo } from 'react'

import { ExperienceRange } from 'modules/candidates/experience-range/experience-range'
import { WorkExperience } from 'modules/work-experience'
import { yearsOfExperienceOptionsMap } from 'pages/JobSeekerProfile'

import { useWorkExperience } from './useWorkExperience'

const normalizeLabel = (targetId: number | null) =>
  targetId !== null ? yearsOfExperienceOptionsMap.get(targetId)?.label : null

const buildLabel = (range: ExperienceRange) => {
  if (range.years < 1) return normalizeLabel(0)

  if (range.years >= 10) return normalizeLabel(10)

  if (range.months >= 0 && range.months < 3) return normalizeLabel(range.years)

  if (range.months >= 3 && range.months < 8) return `${range.years}.5 years`

  return normalizeLabel(range.years + 1)
}

export const useProfileExperiences = (experiences: Array<WorkExperience>) => {
  const { calculateTotalWorkExperience } = useWorkExperience({ readOnly: true })

  const totalWorkExperience = useMemo(
    () => calculateTotalWorkExperience(experiences).duration,
    [experiences],
  )
  const totalWorkExperienceLabel = useMemo(
    () => buildLabel(totalWorkExperience),
    [totalWorkExperience],
  )

  const totalManagementWorkExperience = useMemo(() => {
    const managementExperiences = experiences.filter(({ numberOfManagedPeople }) =>
      Boolean(numberOfManagedPeople),
    )

    if (managementExperiences.length === 0) return null

    return calculateTotalWorkExperience(managementExperiences).duration
  }, [experiences])

  const totalManagementWorkExperienceLabel = useMemo(() => {
    if (totalManagementWorkExperience) return buildLabel(totalManagementWorkExperience)

    return null
  }, [totalManagementWorkExperience])

  return { totalWorkExperienceLabel, totalManagementWorkExperienceLabel }
}
