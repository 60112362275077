import { AnswersDictionary } from 'job-opportunity/components/JobOpportunityModals/components/ApplicationModal/hooks'

interface Answer {
  value: string | null
  linkedWorkExperienceIds: Array<string>
}
export interface AnswersRequest {
  answers: Array<Answer>
}

export class AnswerJobOpportunityDTO {
  constructor(private readonly answers: AnswersDictionary) {}

  toJSON(): AnswersRequest {
    return {
      answers: Object.keys(this.answers)
        .map((key) => {
          return {
            value:
              typeof this.answers[key].value === 'number'
                ? this.answers[key].value
                : this.answers[key].value || null,
            linkedWorkExperienceIds: this.answers[key].workExperiences.map(
              (workExperience) => workExperience.id,
            ),
          }
        })
        .filter((value) => Boolean(value)) as Array<Answer>,
    }
  }
}
