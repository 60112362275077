import { Input } from 'shared/components/atoms'
import { Label } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'

interface JobTitleProps {
  name: string
  formControl: FormControl
  autoFocus?: boolean
  customError?: string
}

export const JobTitle = ({ name, formControl, autoFocus, customError }: JobTitleProps) => {
  const { register } = formControl
  return (
    <>
      <Label htmlFor={name} size="text-sm">
        Job title
      </Label>
      <Input
        register={register}
        name={name}
        autoFocus={autoFocus}
        customError={customError}
        placeholder="e.g. Senior Software Developer"
      />
    </>
  )
}
