import { useRichText } from 'shared/components/atoms'
import { Header, InfoCards, RichTextSection, Skills } from './components'

import { JobDescription as JobDescriptionInJobOpportunity } from 'job-opportunity/domain'
import { JobDescription as JobDescriptionInScreening } from 'screening/domain'

interface Props {
  jobDescription: JobDescriptionInJobOpportunity | JobDescriptionInScreening
  title: string
  isPreview?: boolean
  showMisalignments?: boolean
  headerChildren?: JSX.Element
  compensationTitle?: string | null
  compensationLabel?: string | null
  children?: JSX.Element
  hideDescription?: boolean
}

export const JobDescription = ({
  jobDescription,
  title,
  isPreview = false,
  showMisalignments = false,
  headerChildren,
  compensationTitle,
  compensationLabel,
  children,
  hideDescription,
}: Props) => {
  const { checkIsEmpty } = useRichText()

  return (
    <div>
      <Header jobDescription={jobDescription} title={title}>
        {headerChildren}
      </Header>
      <Skills
        matchingSkills={jobDescription.matchingSkills}
        missingSkills={jobDescription.missingSkills}
        isPreview={isPreview}
      />
      <InfoCards
        jobDescription={jobDescription}
        compensationTitle={compensationTitle}
        compensationLabel={compensationLabel}
        showMisalignments={showMisalignments}
      />

      {children}

      {!hideDescription && (
        <div>
          <RichTextSection title="Requirements" content={jobDescription.detailedRequirements} />
          {!checkIsEmpty(jobDescription.projectDescription) && (
            <RichTextSection
              title="What you will work on"
              content={jobDescription.projectDescription!}
            />
          )}
          <RichTextSection
            title="Benefits of working with Strider"
            content={jobDescription.benefits}
          />
          {jobDescription.processOverview && (
            <RichTextSection title="Process overview" content={jobDescription.processOverview} />
          )}
        </div>
      )}
    </div>
  )
}
