import { Text } from 'shared/components/atoms'
import { cn } from 'modules/shared'
import { lazy, Suspense } from 'react'

const TrustpilotIcon = lazy(() =>
  import('icons/signup/trustpilot.svg').then((module) => ({ default: module.ReactComponent })),
)
const TrustpilotBlackIcon = lazy(() =>
  import('icons/signup/trustpilot-black.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface TrustpilotScoreProps {
  theme?: 'light' | 'dark'
}

export const TrustpilotScore = ({ theme = 'light' }: TrustpilotScoreProps) => {
  const isDarkTheme = theme === 'dark'
  const Icon = isDarkTheme ? TrustpilotIcon : TrustpilotBlackIcon

  return (
    <div className="flex gap-x-2 items-center justify-center">
      <Text size="text-xl" className="flex gap-[6px]">
        <span
          className={cn({ 'text-neutral-darkest': !isDarkTheme, 'text-neutral-300': isDarkTheme })}
        >
          4.8
        </span>
        <span className="text-neutral-400">/5</span>
      </Text>
      <Suspense fallback={null}>
        <Icon />
      </Suspense>
    </div>
  )
}
