import { useState, useEffect } from 'react'
import { useMonitoring } from '../monitoring'
import { useToast } from '../notifications'

export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] => {
  const { captureException } = useMonitoring()
  const { toastError } = useToast()
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key)

      if (!item) return initialValue

      try {
        return JSON.parse(item)
      } catch {
        return item
      }
    } catch (error) {
      captureException(`Error retrieving value from localStorage(${key}): ${error}`)
      return initialValue
    }
  })

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(storedValue))
    } catch (error) {
      toastError(
        'Error when storing data in local storage. Make sure you granted the right permissions to able the app to work properly.',
      )
      captureException(`Error storing value in localStorage(${key}): ${error}`)
    }
  }, [key, storedValue])

  const clear = () => {
    setStoredValue(initialValue)
    localStorage.removeItem(key)
  }

  return [storedValue, setStoredValue, clear]
}
