import { Link } from 'react-router-dom'
import { ReactComponent as RightIcon } from 'icons/icon-right.svg'
import { Crumbs } from '.'
import { Fragment } from 'react'
import classNames from 'classnames'

interface BreadcrumbsProps {
  crumbs: Crumbs[]
  className?: string
}

export const Breadcrumbs = ({ crumbs, className }: BreadcrumbsProps) => {
  if (crumbs.length <= 1) {
    return null
  }

  return (
    <div
      className={classNames(
        'flex gap-2 font-normal text-sm tracking-tight text-neutral-dark items-center',
        className,
      )}
    >
      {crumbs.map(({ name, path }, key) =>
        key + 1 === crumbs.length ? (
          <span className="text-inherit" key={name}>
            {name}
          </span>
        ) : (
          <Fragment key={name}>
            <Link className="text-inherit !text-neutral-dark" to={path}>
              {name}
            </Link>
            <RightIcon className="w-4 h-4" />
          </Fragment>
        ),
      )}
    </div>
  )
}
