import { Type } from 'class-transformer'
import { Candidate } from './candidate.domain'

export class SearchResult {
  totalRecords: number
  totalPages: number

  @Type(() => Candidate)
  profiles: Array<Candidate>
}
