import { isValidPhoneNumber, locationShape } from 'shared/components/atoms'
import { websiteSchema } from 'shared/hooks'
import { object, string } from 'yup'

export { About } from './About'

export const buildSchema = (isProductDesigner: boolean) =>
  object().shape({
    firstName: string().required(),
    lastName: string().required(),
    location: locationShape,
    personalSite: websiteSchema
      .when([], {
        is: () => isProductDesigner,
        then: string().required(),
        otherwise: string().nullable(true),
      })
      .test('is_not_linkedin', 'The site should not be your LinkedIn profile.', function (value) {
        if (value) {
          return !/linkedin.com\/in\//gi.test(value)
        }

        return true
      }),
    gitRepositoriesUrl: string()
      .nullable(true)
      .test('is_gitUrl', 'Please enter a valid GitHub/GitLab URL.', function (value) {
        if (value) {
          return /github.com\//gi.test(value) || /gitlab.com\//gi.test(value)
        }

        return true
      }),
    whatsAppNumber: string()
      .optional()
      .nullable()
      .test('valid_phone_number', 'Please enter a valid phone number.', async (value) => {
        if (value) return isValidPhoneNumber(value)

        return true
      }),
  })
