import { OfferFormValues } from 'modules/hires/domain'

export const buildDefaultValues = (
  companyName: string = 'Client',
  contractUrl?: string,
): Partial<OfferFormValues> => ({
  jobTitle: '',
  preferredStartDates: [],
  description: `The service provider will design, develop, enhance, modify, and maintain software applications in the ${companyName}'s suite of products as directed by the company. Service provider's duties will also include requirement gathering, solution design/architecture discussions, validation, and operations.`,
  contactEmail: '',
  contactFullName: '',
  contractUrl,
})
