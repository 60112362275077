import { StackExperience } from 'pages/JobSeekerProfile/types'
import { SkillTagWithExperience } from 'profile/components'

interface MainTechnologiesProps {
  values: Array<StackExperience & { highlighted?: boolean }>
}

export const MainTechnologies = ({ values }: MainTechnologiesProps) => {
  return (
    <div className="flex flex-wrap gap-6 md:p-3">
      {values.map(({ stack, experience, highlighted }) => (
        <SkillTagWithExperience
          key={stack.id}
          label={stack.label}
          experience={experience}
          highlighted={highlighted}
          size="xs"
        />
      ))}
    </div>
  )
}
