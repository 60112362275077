import { boolean, number, object, string } from 'yup'

export const schema = object().shape({
  compensation: number().nullable(),
  requestProfileImprovements: boolean(),
  improvements: string().when('requestProfileImprovements', {
    is: true,
    then: string()
      .required('Inform the improvements.')
      .test('empty_content', 'Inform the improvements.', function (value) {
        return Boolean(value?.replace('<p></p>\n', ''))
      }),
    otherwise: string().nullable(),
  }),
})
