import React from 'react'
import { useEffect, useState } from 'react'
import { useLocation, Location } from 'react-router-dom'

export const RouterContext = React.createContext({
  to: null as string | null,
  from: null as string | null,
})

const getFullPath = ({ hash, pathname, search }: Location) => {
  return [pathname, hash, search].filter((slice) => slice).join('')
}

export const RouterProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  const landingLocation = getFullPath(location)

  const [route, setRoute] = useState({
    to: landingLocation,
    from: landingLocation,
  })

  useEffect(() => {
    setRoute((previous) => ({ to: getFullPath(location), from: previous.to }))
  }, [location])

  return <RouterContext.Provider value={route}>{children}</RouterContext.Provider>
}
