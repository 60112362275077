import { Text } from 'shared/components/atoms'
import { StackExperience } from 'pages/JobSeekerProfile/types'
import { MainTechnologies } from '../../MainTechnologies'

interface MainSkillsProps {
  skills: Array<StackExperience>
  highlightedSkills?: Array<StackExperience & { highlighted?: boolean }>
}

export const MainSkills = ({ skills, highlightedSkills }: MainSkillsProps) => {
  const isEmpty = !skills.length

  if (isEmpty) return null

  return (
    <div className="flex flex-col md:flex-row gap-2 md:gap-6">
      <div className="md:h-12 flex items-center md:py-[10px] flex-shrink-0 w-[100px]">
        <Text size="text-xs" className="text-neutral-dark">
          Main skills
        </Text>
      </div>
      <MainTechnologies values={highlightedSkills || skills} />
    </div>
  )
}
