import { lazy, Suspense } from 'react'
import { Tooltip } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

const ChartIcon = lazy(() =>
  import('icons/listings/bar-chart-square-02.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface ExperienceLabelProps {
  label: string
  color: 'neutral-dark' | 'neutral-darkest'
}

export const ExperienceLabel = ({ label, color }: ExperienceLabelProps) => {
  if (!label) return null

  return (
    <Tooltip content="Experience">
      <div className="flex items-center gap-2 shrink-0">
        <Suspense fallback={null}>
          <ChartIcon className={`w-4 h-4 stroke-${color}`} />
        </Suspense>
        <Text size="text-sm" weight="font-normal" className={`text-${color}`}>
          {label}
        </Text>
      </div>
    </Tooltip>
  )
}
