import { Recruiter } from 'modules/recruiter'
import { Timeline } from './components/Timeline'
import { WelcomeBanner } from './components/WelcomeBanner'

export const Overview = ({ recruiter }: { recruiter?: Recruiter }) => {
  return (
    <div>
      <WelcomeBanner />
      <Timeline recruiter={recruiter} />
    </div>
  )
}
