import classNames from 'classnames'
import { Tooltip } from 'shared/components/atoms'

import { ReactComponent as LightIcon } from 'icons/lightbulb-02.svg'
import { SurveysContext } from 'modules/surveys'
import { useContext } from 'react'

export const FeedbackButton = () => {
  const { showSurvey } = useContext(SurveysContext)

  return (
    <Tooltip content="Share feedback">
      <div
        className={classNames(
          'hidden md:flex h-full px-3 cursor-pointer flex-col gap-1 items-center justify-center rounded relative hover:bg-neutral-darkest ml-2',
        )}
        onClick={() => showSurvey()}
      >
        <LightIcon className=" stroke-neutral-day w-6 h-6 cursor-pointer" />
      </div>
    </Tooltip>
  )
}
