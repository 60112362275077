import { DegreeType } from 'pages/JobSeekerProfile/types'
import { EducationFormValues } from '../domain/education.domain'

interface ProfileEducationRequest {
  degreeType: DegreeType
  fieldOfStudy: string
  institutionId: number
  startDate: string
  endDate: string
}

export class UpsertEducationDTO {
  constructor(private readonly education: EducationFormValues) {}

  toJSON(): ProfileEducationRequest {
    const { startDate, endDate, fieldOfStudy, degreeTypeId, institutionId } = this.education
    return Object.assign(
      {},
      {
        startDate: startDate || '',
        endDate: endDate || '',
        fieldOfStudy,
        degreeType: degreeTypeId as DegreeType,
        institutionId: institutionId || 0,
      },
    )
  }
}
