import { Paragraph, PrimaryButton } from 'shared/components/atoms'
import { ReactComponent as InfoIcon } from 'icons/info-circle.svg'

import { useFormatting } from 'shared/hooks'

import { useUpdateProfileAvailability } from 'hooks/profile'

import { ProfileStatus } from 'modules/candidates/enums'
import { Preferences } from 'modules/profiles/profile-preferences'

interface Props {
  profileId: string
  preferences: Preferences
  onUpdate: () => void
}

export const DeactivatedAccountBanner = ({ profileId, preferences, onUpdate }: Props) => {
  const { formatDate } = useFormatting()
  const { updateProfileAvailability, isLoading } = useUpdateProfileAvailability(profileId)

  const handleClick = () => {
    updateProfileAvailability({
      days: undefined,
      status: ProfileStatus.Active,
    }).then(onUpdate)
  }

  const messages: { [key: string]: string } = {
    [ProfileStatus.Paused]: `Your account is deactivated until ${formatDate({
      date: new Date(preferences.pausedUntil!),
      timeZone: 'UTC',
    })}. Your profile will not be visible.`,
    [ProfileStatus.Unavailable]:
      'Your profile is not available for opportunities. Update your status to be considered for open roles.',
  }

  const warning = messages[preferences.availability]

  if (!warning) return null

  return (
    <div className="flex flex-col sm:flex-row justify-between items-center gap-4 px-4 sm:px-10 py-4 rounded-lg border border-info-dark bg-neutral-lightest">
      <div className="flex items-center gap-4">
        <div>
          <InfoIcon className="h-6 w-6 fill-info-dark stroke-neutral-lightest" />
        </div>
        <Paragraph size="body-md">{warning}</Paragraph>
      </div>
      <PrimaryButton onClick={handleClick} isLoading={isLoading} className="w-full sm:w-[unset]">
        Reactivate account
      </PrimaryButton>
    </div>
  )
}
