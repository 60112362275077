export enum ReviewStatus {
  ToDo = 'to_do',
  InProgress = 'in_progress',
  Reviewed = 'reviewed',
  Paused = 'paused',
  RecordAgain = 'record_again',
  Prioritized = 'prioritized',
  Approved = 'approved',
  ReviewAgain = 'review_again',
  Disregarded = 'disregarded',
}
