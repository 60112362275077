import { ProfileStatus } from 'modules/candidates/enums'

import { ReactComponent as ActivelyLookingIcon } from 'icons/actively-looking.svg'
import { ReactComponent as PassiveLookingIcon } from 'icons/passive-looking.svg'
import { ReactComponent as ClockIcon } from 'icons/clock.svg'
import { ReactComponent as NotAvailableIcon } from 'icons/not-available.svg'

export const availabilityOptions = [
  {
    id: ProfileStatus.Active,
    label: 'Actively looking',
    icon: <ActivelyLookingIcon className="min-w-fit" />,
    description:
      'You are actively searching for a new job. Your profile will be shared with relevant companies.',
  },
  {
    id: ProfileStatus.Passive,
    label: 'Passively looking',
    icon: <PassiveLookingIcon className="min-w-fit" />,
    description:
      'You are not actively searching for a new job, but you are open to hearing about new opportunities. Your profile will be shared with relevant companies.',
  },
  {
    id: ProfileStatus.Paused,
    label: 'Pause for a month',
    icon: <ClockIcon className="min-w-fit stroke-neutral-500" />,
    description:
      "You are not available for new opportunities temporarily, and you don't want to hear about new opportunities for the next 30 days.",
  },
  {
    id: ProfileStatus.Unavailable,
    label: 'Not available',
    icon: <NotAvailableIcon className="min-w-fit" />,
    description:
      "You are not available for new opportunities, and you don't want to hear about new opportunities until you change your status to looking.",
  },
]
