import { RichText } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

interface Props {
  title: string
  content: string
}

export const RichTextSection = ({ title, content }: Props) => (
  <div className="mt-12">
    <Text size="text-2xl" weight="font-medium">
      {title}
    </Text>
    <RichText className="!leading-6">{content || 'Not provided'}</RichText>
  </div>
)
