import { cn } from 'modules/shared'
import { NumericExpectedAnswer } from 'screening/domain'
import { Counter } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'
import { useStringValidation } from 'shared/hooks/stringValidation'

interface Props {
  name: string
  formControl: FormControl
  disabled?: boolean
  expectedAnswer?: Nullable<NumericExpectedAnswer>
  showWarning: boolean
}

export const NumericQuestion = ({
  name,
  formControl,
  disabled,
  showWarning,
  expectedAnswer,
}: Props) => {
  const { register, getValues, setValue, watch } = formControl
  const { isEmpty } = useStringValidation()

  const valueName = `${name}.value`
  const value = watch(valueName)

  return (
    <Counter
      name={valueName}
      register={register}
      getValues={getValues}
      setValue={setValue}
      disabled={disabled}
      omitErrors
      placeholder={expectedAnswer?.min?.toString()}
      inputClassName={cn({
        'bg-success-lighter disabled:bg-success-lighter': Boolean(expectedAnswer),
      })}
      showWarning={Boolean(showWarning && isEmpty(value))}
    />
  )
}
