import { lazy, Suspense } from 'react'

import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

const Illustration = lazy(() =>
  import('icons/ilustrations/dashboard/evaluation-1.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

const PausedIllustration = lazy(() =>
  import('icons/ilustrations/time-out-for-work--streamline-manchester-black-white.svg').then(
    (module) => ({
      default: module.ReactComponent,
    }),
  ),
)

interface Props {
  isDeactivated: boolean
}

export const EmptyState = ({ isDeactivated }: Props) => {
  const illustration = isDeactivated ? (
    <PausedIllustration className="h-[150px] w-[150px] mb-4" />
  ) : (
    <Illustration className="h-[150px] w-[150px] mb-4" />
  )

  const title = isDeactivated
    ? "There'll be no tasks while your account is deactivated"
    : 'All good! You are active in our talent-pool'

  const description = isDeactivated
    ? 'Nothing to worry about. Your account is currently not being shown or considered to any Strider opportunities.'
    : 'Now you just sit and relax! We will contact you once international opportunities align with your profile.'

  return (
    <div className="flex flex-col items-center gap-6 text-center px-6 py-8">
      <Suspense fallback={null}>{illustration}</Suspense>
      <div className="flex flex-col gap-2">
        <Text size="text-base" weight="font-medium">
          {title}
        </Text>
        <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark">
          {description}
        </Paragraph>
      </div>
    </div>
  )
}
