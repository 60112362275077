import { useNetwork } from 'shared/hooks'
import { useState } from 'react'

const UPLOAD_PRESET = 'hiring-contracts'
const CLOUD = process.env.REACT_APP_CLOUDINARY_CLOUD
const API_KEY = process.env.REACT_APP_CLOUDINARY_API_KEY
const UPLOAD_URL = `https://api.cloudinary.com/v1_1/${CLOUD}/raw/upload`

const buildUploadParams = ({
  blob,
  publicId,
  signature,
  timestamp,
}: {
  blob: Blob
  publicId: string
  signature: string
  timestamp: number
}) => {
  const formData = new FormData()
  formData.append('file', blob)
  formData.append('upload_preset', UPLOAD_PRESET)
  formData.append('public_id', publicId)
  formData.append('signature', signature)
  formData.append('api_key', String(API_KEY))
  formData.append('timestamp', String(timestamp))

  return formData
}

// @todo: upload code need to be refactored, we have multiple places pretty much like this one
export const useAgreementUpload = (profileId: string) => {
  const { post } = useNetwork()
  const [isUploading, setIsUploading] = useState(false)

  const generateUploadSignature = async () => {
    const publicId = [profileId, Date.now()].join('-')

    return await post<{ signature: string; timestamp: number }>(
      `/profile/${publicId}/attachment-signatures`,
      { uploadPreset: UPLOAD_PRESET },
    ).then(({ data }) => Object.assign(data, { publicId }))
  }

  const uploadFile = async (blob: Blob) => {
    setIsUploading(true)
    const { signature, timestamp, publicId } = await generateUploadSignature().finally(() =>
      setIsUploading(false),
    )

    setIsUploading(true)
    const body = buildUploadParams({ blob, timestamp, signature, publicId: publicId })

    return fetch(UPLOAD_URL, { method: 'POST', body })
      .then((response) => response.json())
      .finally(() => setIsUploading(false))
  }

  return { uploadFile, isUploading }
}
