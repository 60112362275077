import { Text } from 'shared/components/atoms'
import { PrimaryButton, SecondaryButton } from 'shared/components/atoms'
import { IconInput } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { useForm } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { useReferralLink } from 'hooks/referrals'
import { useMemo, ClipboardEvent, useEffect } from 'react'
import { object, string } from 'yup'

interface CreateJobReferralLinkModalProps {
  open: boolean
  referrerLink: string
  referrerSlug: string
  onClose: () => void
}

const errorMessage = 'Please inform a valid job URL'

const schema = object().shape({
  jobUrl: string()
    .test('format', errorMessage, (value) => {
      return !value?.includes('http') && !value?.includes(' ')
    })
    .required(errorMessage),
})

export const CreateJobReferralLinkModal = ({
  referrerLink,
  referrerSlug,
  open,
  onClose,
}: CreateJobReferralLinkModalProps) => {
  const { createJobReferralUrl } = useReferralLink()
  const { toastSuccess } = useToast()

  const { register, watch, setValue, setError, isValid, reset } = useForm({
    schema,
    defaultValues: {},
    mode: 'onChange',
  })
  const jobUrl = watch('jobUrl')

  useEffect(() => {
    if (open) {
      reset()
    }
  }, [open])

  const link = useMemo(() => {
    if (jobUrl) return createJobReferralUrl(referrerLink, referrerSlug, jobUrl)
  }, [jobUrl, referrerLink])

  const handlePaste = (event: ClipboardEvent<Element>) => {
    const content = event.clipboardData.getData('text')
    const parts = content.split('onstrider.com/jobs/')

    if (parts.length === 2) {
      event.preventDefault()
      return setValue('jobUrl', parts[1], { shouldValidate: true, shouldTouch: true })
    }

    setError('jobUrl', { type: 'custom', message: 'Unexpected URL format' })
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(link!)
    toastSuccess('Copied to clipboard')
  }

  const handleCopyAndClose = () => {
    handleCopy()
    onClose()
  }

  return (
    <Modal
      open={open}
      handleClose={onClose}
      content={
        <form className="flex flex-col gap-2">
          <Text size="text-sm" weight="font-normal" className="mb-2">
            Have a specific Strider job in mind for a referral? You can create a custom link for any
            job from onstrider.com/jobs, just paste the job link below
          </Text>
          <IconInput
            register={register}
            name="jobUrl"
            icon={'https://onstrider.com/jobs/'}
            inputClassName="pl-[220px]"
            onPaste={handlePaste}
          />

          <div className="min-h-[96px] bg-neutral-lightest rounded-[34px] py-2 px-4 flex items-center justify-center text-center">
            <Text size="text-sm" weight="font-normal" className="break-all">
              {link && isValid ? link : 'Your custom link will be visible here.'}
            </Text>
          </div>
          <div className="flex gap-4 justify-end pt-5">
            <SecondaryButton disabled={!isValid} onClick={handleCopy}>
              Copy link
            </SecondaryButton>
            <PrimaryButton disabled={!isValid} onClick={handleCopyAndClose}>
              Copy link and close
            </PrimaryButton>
          </div>
        </form>
      }
      title="Job referral"
    />
  )
}
