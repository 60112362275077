export enum InstitutionTier {
  One = 'One',
  Two = 'Two',
  Three = 'Three',
  Unknown = 'Unknown',
}

export const institutionTierLabels = {
  [InstitutionTier.One]: 'Top 2%',
  [InstitutionTier.Two]: 'Top 3%',
  [InstitutionTier.Three]: 'Unknown',
  [InstitutionTier.Unknown]: 'Unknown',
}
