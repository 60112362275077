import { useMemo } from 'react'

import { PreviewLabel } from 'shared/components/atoms'
import { Tags } from 'shared/components/atoms'
import { Preferences } from 'modules/profiles/profile-preferences'

export const JobTypes = ({ preferences }: { preferences: Preferences }) => {
  const jobTypesTags = useMemo<Array<{ id: number; label: string }>>(() => {
    const tags = [] as Array<{ id: number; label: string }>
    if (!preferences) return tags

    if (preferences.jobTypes.longTerm) tags.push({ id: 0, label: 'Full-time' })
    if (preferences.jobTypes.shortTerm)
      tags.push({
        id: 1,
        label: `Short-term (${preferences.freelancingJobHours.min} - ${preferences.freelancingJobHours.max}h/week)`,
      })

    return tags
  }, [preferences])

  return (
    <PreviewLabel title="Open to" disabled id="open-to">
      {jobTypesTags.length ? (
        <Tags values={jobTypesTags} background="bg-neutral-lightest" isReadOnly />
      ) : (
        <Tags
          values={[{ id: 0, label: 'Not informed', className: 'text-neutral-dark' }]}
          background="bg-neutral-lightest"
          isReadOnly
        />
      )}
    </PreviewLabel>
  )
}
