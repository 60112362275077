import { IconBanner } from 'shared/components/molecules'
import { RichText } from 'shared/components/atoms'
import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { ReactComponent as InfoIcon } from 'icons/info-circle.svg'

interface Props {
  profileImprovements?: string | null
}

export const ImproveProfile = ({ profileImprovements }: Props) => {
  if (!profileImprovements) return null

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Text size="text-xl" weight="font-medium">
            Improve your profile content
          </Text>
          <Paragraph className="text-neutral-dark">
            Our talent agents have identified areas in your profile that require improvement for
            this and future job openings. We highly suggest dedicating time to refining these areas:
          </Paragraph>
        </div>
        <RichText>{profileImprovements}</RichText>
      </div>
      <IconBanner icon={<InfoIcon className="w-6 h-6 shrink-0" />}>
        <Paragraph>
          Don't worry about leaving and making profile improvements. Your progress on this
          application is saved!
        </Paragraph>
      </IconBanner>
    </div>
  )
}
