import { useContext } from 'react'
import { useQuery } from 'react-query'

import { CandidateContext } from 'contexts/candidate'
import { useAuth, useNetwork } from 'shared/hooks'

import { VettingPackageResponse } from 'vetting/domain'
import { useMonitoring } from 'shared/hooks'
import { plainToInstance } from 'class-transformer'

export const useVettingPackage = () => {
  const { candidate } = useContext(CandidateContext)
  const { get } = useNetwork()
  const { captureException } = useMonitoring()
  const { user } = useAuth()

  const {
    data = null,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(
    [candidate.id, 'vetting-package'],
    () =>
      get<Nullable<VettingPackageResponse>>('vetting-packages/latest')
        .then(({ data }) => plainToInstance(VettingPackageResponse, data))
        .catch((error: RequestError) => {
          if (error.response?.status === 404) return
          captureException(error)
        }),
    {
      staleTime: Infinity,
      retry: (_, error: RequestError) => error.response?.status !== 404,
      enabled: user.isSeeker,
    },
  )

  return {
    vettingPackage: data,
    isLoading: isLoading || isRefetching,
    refetch,
    isFirstLoading: isLoading && !isRefetching,
  }
}
