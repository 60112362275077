import { IconBanner } from 'shared/components/molecules'
import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { ReactComponent as InfoIcon } from 'icons/info-circle.svg'
import { ReactComponent as CheckIcon } from 'icons/check-circle-outlined.svg'
import { Link } from 'react-router-dom'
import { PrimaryButton } from 'shared/components/atoms'
import { useTracking } from 'tracking'
import { JobOpportunityCompleteProfileClick } from 'tracking/events/job-opportunities'

interface Props {
  jobOpportunityId: string
  isProfileCompleted: boolean
}

export const CompleteProfile = ({ isProfileCompleted, jobOpportunityId }: Props) => {
  const { trackEvent } = useTracking()

  const trackCompleteProfileClick = () => {
    trackEvent(new JobOpportunityCompleteProfileClick(jobOpportunityId))
  }

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-2">
        <Text size="text-xl" weight="font-medium">
          Complete your profile
        </Text>
        <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark">
          We use the information of your profile such as skills, experiences and preferences to
          match you with job opportunities. Make sure to create a great profile and standout.
        </Paragraph>
      </div>

      {isProfileCompleted ? (
        <IconBanner
          bgColor="bg-success-lighter"
          icon={<CheckIcon className="w-6 h-6 stroke-success-medium" />}
        >
          Your profile is completed.
        </IconBanner>
      ) : (
        <IconBanner
          bgColor="bg-neutral-lightest"
          icon={<InfoIcon className="w-6 h-6 stroke-neutral-medium" />}
        >
          Don't worry about leaving and complete the profile. Your progress on this application is
          saved!
        </IconBanner>
      )}
      {!isProfileCompleted && (
        <Link to="/profile" target="_blank" onClick={trackCompleteProfileClick}>
          <PrimaryButton className="w-full">Complete your profile</PrimaryButton>
        </Link>
      )}
    </div>
  )
}
