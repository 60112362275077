import { useCallback, useRef, useState } from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import classNames from 'classnames'

import { SecondaryButton } from 'shared/components/atoms'

import { ReactComponent as UploadIcon } from 'icons/upload-symbol.svg'
import { ReactComponent as CheckIcon } from 'icons/check.svg'
import { ReactComponent as CloseIcon } from 'icons/close.svg'
import { ReactComponent as WarningIcon } from 'icons/squared-warning.svg'
import { SizeWarning } from './SizeWarning'
import { TertiaryButton } from 'shared/components/atoms'
import { DropWarning } from './DropWarning'

export const FileUploader = ({
  file,
  setFile,
  error,
  accept = { 'application/pdf': ['.pdf'] },
}: {
  file: File | null
  setFile: (file: File | null) => void
  error?: string
  accept?: Record<string, Array<string>>
}) => {
  const [showWarning, setShowWarning] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const onDrop = useCallback(
    (acceptedFiles: Array<File>, fileRejections: Array<FileRejection>, event: DropEvent) => {
      setShowWarning(false)

      if (acceptedFiles[0]) return setFile(acceptedFiles[0])

      if (fileRejections[0] && fileRejections[0].errors[0].code === 'file-too-large') {
        return setShowWarning(true)
      }
    },
    [],
  )

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop,
    multiple: false,
    accept,
    maxSize: 1e7,
  })

  const handleDelete = () => setFile(null)
  const handleBrowseFile = () => inputRef.current?.click()

  const containerClassName = classNames({
    'bg-warning-lighter border-warning-light': error,
    'border-neutral-light': !error && !isDragAccept,
    'bg-brand-lighter border-brand-medium': isDragAccept,
    'border-solid': !error && file,
    'border-dashed': error || !file,
  })

  return (
    <>
      <div {...getRootProps()} className="w-full">
        <input {...getInputProps()} ref={inputRef} name="file-uploader" />
        {file && (
          <div
            className={classNames(
              'flex flex-row items-center p-8 gap-4',
              'rounded-lg border',
              containerClassName,
            )}
          >
            <DropWarning isVisible={isDragAccept}>
              <>
                {error ? (
                  <WarningIcon className="stroke-white w-6 h-6" />
                ) : (
                  <CheckIcon className="bg-success-light stroke-white w-6 h-6 rounded-full p-2" />
                )}

                <span className="font-medium text-sm text-neutral-darker text-ellipsis max-w-1/2 overflow-clip">
                  {file.name}
                </span>

                <span className="flex-grow font-normal text-xs text-neutral-medium">{`${Math.ceil(
                  file.size / 1024,
                )} KB`}</span>

                {error ? (
                  <SecondaryButton onClick={handleDelete} size="sm">
                    Upload new
                  </SecondaryButton>
                ) : (
                  <TertiaryButton icon={<CloseIcon />} onClick={handleDelete} size="sm" />
                )}
              </>
            </DropWarning>
          </div>
        )}
        {!file && (
          <div
            className={classNames(
              'flex flex-col sm:flex-row items-center p-8 gap-5',
              'rounded-lg border',
              containerClassName,
            )}
          >
            <DropWarning isVisible={isDragAccept}>
              <>
                <UploadIcon className="h-6 w-6 stroke-neutral-medium" />
                <span className="font-medium text-sm text-neutral-dark">
                  Drag & drop your file here
                </span>
                <span className="font-medium text-sm text-neutral-dark px-7">OR</span>
                <SecondaryButton
                  onClick={handleBrowseFile}
                  className="min-w-[118px] w-full sm:w-auto !justify-center"
                  size="sm"
                >
                  Browse files
                </SecondaryButton>
              </>
            </DropWarning>
          </div>
        )}
      </div>
      {showWarning && <SizeWarning setVisible={setShowWarning} />}
    </>
  )
}
