import { ToggleFormField } from 'shared/components/atoms'
import { ListingInfo } from 'job-listing/domains'
import { FormControl } from 'shared/hooks'

interface VisiblePayRateProps {
  listing: ListingInfo
  formControl: FormControl
}

export const VisiblePayRate = ({ listing, formControl }: VisiblePayRateProps) => {
  const description = `${listing.company.name} members can see hiring costs for candidates on this job listing.`

  return (
    <ToggleFormField
      name="hiringCostsVisible"
      formControl={formControl}
      label="Visible pay rate"
      description={description}
    />
  )
}
