import { Text } from 'shared/components/atoms'

interface InlineEmptySectionProps {
  title: string
  message: string
}

export const InlineEmptySection = ({ title, message }: InlineEmptySectionProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Text size="text-sm" weight="font-medium" className="text-neutral-dark">
        {title}
      </Text>
      <div className="flex p-4 items-center flex-col bg-neutral-lightest rounded">
        <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
          {message}
        </Text>
      </div>
    </div>
  )
}
