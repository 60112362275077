import { plainToInstance } from 'class-transformer'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { useMonitoring, useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { Referral } from 'modules/referrals/domains'

export const useReferrals = (page: number, filter: string) => {
  const { get } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const [referralCount, setReferralCount] = useState(0)
  const [referrals, setReferrals] = useState<Array<Referral>>([])

  const {
    data = { count: 0, referrals: [] },
    isLoading,
    isRefetching,
    isError,
    refetch,
  } = useQuery(
    ['referrals', page, filter],
    () =>
      get<{ count: number; referrals: Array<Referral> }>(`/referrals?page=${page}&${filter}`).then(
        ({ data: { count, referrals } }) => ({
          count,
          referrals: plainToInstance(Referral, referrals),
        }),
      ),
    {
      staleTime: 5 * 60 * 1000,
      onError: (error: RequestError) => {
        toastError(error.response.data?.message)
        captureException(error)
      },
    },
  )

  useEffect(() => {
    if (isLoading || isRefetching) return
    if (isError) return

    const { referrals: records, count } = data

    setReferralCount(count)
    let referralRecords = [...referrals]

    if (!data.referrals?.length) referralRecords = []
    else if (page === 1) referralRecords = records
    else referralRecords = referralRecords.concat(records)

    setReferrals(referralRecords)
  }, [data, isLoading, isRefetching])

  useEffect(() => {
    setReferralCount(0)
    setReferrals([])
    refetch()
  }, [filter])

  return {
    referrals,
    referralCount,
    isLoading: isLoading || isRefetching,
  }
}
