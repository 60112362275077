import { SecondaryButton } from 'shared/components/atoms'
import { Link } from 'shared/components/atoms'
import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { ReactComponent as ExternalLinkIcon } from 'icons/external-link.svg'
import { ReactComponent as AwardIcon } from 'icons/award-01.svg'
import { useTracking } from 'tracking'
import { JobOpportunityProfileTipsClick } from 'tracking/events/job-opportunities'

interface BoostCardProps {
  jobOpportunityId: string
}

export const BoostCard = ({ jobOpportunityId }: BoostCardProps) => {
  const { trackEvent } = useTracking()

  const trackClick = () => trackEvent(new JobOpportunityProfileTipsClick(jobOpportunityId))

  return (
    <div className="flex flex-col gap-4 p-8 rounded-lg border border-neutral-lighter">
      <Text size="text-xl" weight="font-medium">
        ⚡ Boost your process and stay ahead
      </Text>
      <div className="flex flex-col md:flex-row items-stretch gap-4 md:gap-2 md:items-center justify-between pt-4">
        <div className="flex gap-4">
          <AwardIcon className="w-6 h-6 shrink-0 stroke-neutral-darkest" />
          <div className="flex flex-col gap-1">
            <Text size="text-base" weight="font-medium">
              Improve your profile content
            </Text>
            <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark">
              See how you can improve your profile content and stand out to companies.
            </Paragraph>
          </div>
        </div>
        <Link
          url="https://help.onstrider.com/en/articles/6755106-describing-your-work-experience-in-a-meaningful-way"
          onClick={trackClick}
        >
          <SecondaryButton icon={<ExternalLinkIcon />} className="w-full">
            See all tips
          </SecondaryButton>
        </Link>
      </div>
    </div>
  )
}
