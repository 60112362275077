import { useMonitoring } from 'shared/hooks'
import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { useMutation, useQueryClient } from 'react-query'

export interface FormValues {
  name: string
  website: string | null
  marketTypeId: number
  companySize: string
}

export const useCompany = () => {
  const { patch } = useNetwork()
  const client = useQueryClient()
  const { toastSuccess, toastError } = useToast()

  const { captureException } = useMonitoring()

  const { mutateAsync: update, isLoading } = useMutation(
    ({ name, website, companySize, marketTypeId }: FormValues) =>
      patch('company', { name, website, companySize, marketTypeId }),
    {
      onSuccess: () => {
        toastSuccess('Company updated.')
        client.invalidateQueries('company-info')
      },
      onError: (error) => {
        toastError('An error ocurred when updating the company.')
        captureException(error)
      },
    },
  )

  return { update, isLoading }
}
