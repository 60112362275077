import { Skill } from 'profile/types'
import { array, object, string } from 'yup'

export { RolesAndSkills } from './RolesAndSkills'

export const schema = object().shape({
  stacks: array()
    .of(
      object().shape({
        stackId: string(),
        stackLabel: string(),
        yearsOfExperience: string(),
      }),
    )
    .test('at_least_three_skills', 'Choose at least three skills.', function () {
      return this.parent.stacks.filter((skill: Skill) => Boolean(Number(skill.stackId))).length >= 3
    }),
  openToRoles: array()
    .of(
      object().shape({
        id: string().nullable(),
        label: string(),
      }),
    )
    .min(1, 'Choose at least one role.'),
})
