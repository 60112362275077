import { HTMLAttributes } from 'react'

import { cn } from 'modules/shared'

interface FieldWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children: JSX.Element | (JSX.Element | false)[] | React.ReactElement
  className?: string
}

export const FieldWrapper = ({ children, className, ...restProps }: FieldWrapperProps) => {
  return (
    <div {...restProps} className={cn('relative mt-5', className)}>
      {children}
    </div>
  )
}
