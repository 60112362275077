import { Bar } from 'shared/components/atoms'

export const HistogramData: Record<string, Record<string, Array<Pick<Bar, 'heightPercentage'>>>> = {
  Entry: {
    Monthly: [
      { heightPercentage: 11 },
      { heightPercentage: 34 },
      { heightPercentage: 100 },
      { heightPercentage: 95 },
      { heightPercentage: 85 },
      { heightPercentage: 65 },
      { heightPercentage: 55 },
      { heightPercentage: 40 },
      { heightPercentage: 35 },
      { heightPercentage: 20 },
      { heightPercentage: 11 },
      { heightPercentage: 13 },
      { heightPercentage: 7 },
      { heightPercentage: 8 },
      { heightPercentage: 2 },
      { heightPercentage: 1 },
      { heightPercentage: 1 },
      { heightPercentage: 5 },
      { heightPercentage: 0 },
      { heightPercentage: 3 },
      { heightPercentage: 0 },
      { heightPercentage: 1 },
      { heightPercentage: 3 },
      { heightPercentage: 1 },
      { heightPercentage: 0 },
      { heightPercentage: 0 },
      { heightPercentage: 0 },
      { heightPercentage: 0 },
      { heightPercentage: 0 },
      { heightPercentage: 0 },
    ],
    Hourly: [
      { heightPercentage: 10 },
      { heightPercentage: 20 },
      { heightPercentage: 27 },
      { heightPercentage: 67 },
      { heightPercentage: 70 },
      { heightPercentage: 84 },
      { heightPercentage: 100 },
      { heightPercentage: 97 },
      { heightPercentage: 91 },
      { heightPercentage: 84 },
      { heightPercentage: 75 },
      { heightPercentage: 43 },
      { heightPercentage: 53 },
      { heightPercentage: 61 },
      { heightPercentage: 53 },
      { heightPercentage: 39 },
      { heightPercentage: 33 },
      { heightPercentage: 27 },
      { heightPercentage: 22 },
      { heightPercentage: 16 },
      { heightPercentage: 8 },
      { heightPercentage: 8 },
      { heightPercentage: 14 },
      { heightPercentage: 11 },
      { heightPercentage: 8 },
      { heightPercentage: 8 },
      { heightPercentage: 11 },
      { heightPercentage: 6 },
      { heightPercentage: 14 },
      { heightPercentage: 10 },
    ],
  },
  Mid: {
    Monthly: [
      { heightPercentage: 11 },
      { heightPercentage: 32 },
      { heightPercentage: 70 },
      { heightPercentage: 100 },
      { heightPercentage: 91 },
      { heightPercentage: 80 },
      { heightPercentage: 84 },
      { heightPercentage: 65 },
      { heightPercentage: 51 },
      { heightPercentage: 30 },
      { heightPercentage: 31 },
      { heightPercentage: 21 },
      { heightPercentage: 18 },
      { heightPercentage: 19 },
      { heightPercentage: 14 },
      { heightPercentage: 10 },
      { heightPercentage: 15 },
      { heightPercentage: 18 },
      { heightPercentage: 14 },
      { heightPercentage: 14 },
      { heightPercentage: 8 },
      { heightPercentage: 8 },
      { heightPercentage: 14 },
      { heightPercentage: 6 },
      { heightPercentage: 14 },
      { heightPercentage: 10 },
      { heightPercentage: 10 },
      { heightPercentage: 6 },
      { heightPercentage: 6 },
      { heightPercentage: 0 },
    ],
    Hourly: [
      { heightPercentage: 10 },
      { heightPercentage: 20 },
      { heightPercentage: 27 },
      { heightPercentage: 67 },
      { heightPercentage: 70 },
      { heightPercentage: 84 },
      { heightPercentage: 100 },
      { heightPercentage: 97 },
      { heightPercentage: 91 },
      { heightPercentage: 84 },
      { heightPercentage: 75 },
      { heightPercentage: 43 },
      { heightPercentage: 53 },
      { heightPercentage: 61 },
      { heightPercentage: 53 },
      { heightPercentage: 19 },
      { heightPercentage: 18 },
      { heightPercentage: 27 },
      { heightPercentage: 22 },
      { heightPercentage: 16 },
      { heightPercentage: 10 },
      { heightPercentage: 8 },
      { heightPercentage: 14 },
      { heightPercentage: 4 },
      { heightPercentage: 8 },
      { heightPercentage: 8 },
      { heightPercentage: 4 },
      { heightPercentage: 6 },
      { heightPercentage: 5 },
      { heightPercentage: 1 },
    ],
  },
  Upper: {
    Monthly: [
      { heightPercentage: 8 },
      { heightPercentage: 11 },
      { heightPercentage: 40 },
      { heightPercentage: 55 },
      { heightPercentage: 60 },
      { heightPercentage: 80 },
      { heightPercentage: 90 },
      { heightPercentage: 70 },
      { heightPercentage: 43 },
      { heightPercentage: 50 },
      { heightPercentage: 45 },
      { heightPercentage: 38 },
      { heightPercentage: 25 },
      { heightPercentage: 21 },
      { heightPercentage: 5 },
      { heightPercentage: 8 },
      { heightPercentage: 13 },
      { heightPercentage: 2 },
      { heightPercentage: 10 },
      { heightPercentage: 7 },
      { heightPercentage: 2 },
      { heightPercentage: 8 },
      { heightPercentage: 7 },
      { heightPercentage: 4 },
      { heightPercentage: 8 },
      { heightPercentage: 8 },
      { heightPercentage: 2 },
      { heightPercentage: 2 },
      { heightPercentage: 0 },
      { heightPercentage: 0 },
    ],
    Hourly: [
      { heightPercentage: 10 },
      { heightPercentage: 20 },
      { heightPercentage: 27 },
      { heightPercentage: 33 },
      { heightPercentage: 61 },
      { heightPercentage: 87 },
      { heightPercentage: 70 },
      { heightPercentage: 84 },
      { heightPercentage: 91 },
      { heightPercentage: 93 },
      { heightPercentage: 91 },
      { heightPercentage: 84 },
      { heightPercentage: 91 },
      { heightPercentage: 61 },
      { heightPercentage: 53 },
      { heightPercentage: 39 },
      { heightPercentage: 33 },
      { heightPercentage: 27 },
      { heightPercentage: 22 },
      { heightPercentage: 16 },
      { heightPercentage: 10 },
      { heightPercentage: 8 },
      { heightPercentage: 14 },
      { heightPercentage: 11 },
      { heightPercentage: 2 },
      { heightPercentage: 8 },
      { heightPercentage: 14 },
      { heightPercentage: 6 },
      { heightPercentage: 14 },
      { heightPercentage: 10 },
    ],
  },
}
