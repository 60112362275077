import { ClaimProtected, FieldWrapper, Label, Radio, Text } from 'shared/components/atoms'

import { AccountClaim } from 'shared/hooks'

import { PaymentModel as PaymentModelEnum } from 'job-listing/enums'
import { FormListing } from 'job-listing/domains'
import { UseFormWatch } from 'react-hook-form'
import { ListingWithMatchingsBanner } from 'job-listing/components'

interface PaymentProps {
  register: (key: string) => Array<string>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  initialListing?: FormListing
}

export const PaymentModel = ({ register, watch, initialListing }: PaymentProps) => {
  const options = [
    { value: PaymentModelEnum.Monthly, label: 'Monthly payment' },
    { value: PaymentModelEnum.Hourly, label: 'Hourly payment' },
  ]

  const paymentModel = watch('paymentModel') as PaymentModelEnum

  const hasMatchings =
    initialListing?.paymentModel === PaymentModelEnum.Hourly
      ? Boolean(initialListing.hasHourlyMatchings)
      : Boolean(initialListing?.hasMonthlyMatchings)

  const isWarningVisible = paymentModel !== initialListing?.paymentModel && hasMatchings

  return (
    <ClaimProtected require={AccountClaim.Staff}>
      <>
        <FieldWrapper className="!mt-3">
          <Label htmlFor="paymentModel">Payment model</Label>
          <div className="flex items-center gap-10 py-2">
            {options.map((option) => (
              <Label
                htmlFor={`paymentModel-${option.value}`}
                className="flex items-center gap-2 !mb-0"
                key={option.value}
              >
                <Radio
                  register={register}
                  id={`paymentModel-${option.value}`}
                  name="paymentModel"
                  value={option.value}
                />
                <Text size="text-sm" weight="font-normal" className="text-neutral-darkest">
                  {option.label}
                </Text>
              </Label>
            ))}
          </div>
        </FieldWrapper>
        <ListingWithMatchingsBanner
          initialPaymentModel={initialListing?.paymentModel}
          visible={isWarningVisible}
          className="mt-2"
        />
      </>
    </ClaimProtected>
  )
}
