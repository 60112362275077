import { useQuery } from 'react-query'

import { useNetwork } from 'shared/hooks'

export const useCountries = () => {
  const { get } = useNetwork()

  const { data: countries = [], isLoading } = useQuery(
    'countries',
    () =>
      get<Array<{ id: string; label: string }>>('/advanced-search/countries').then(
        ({ data }) => data,
      ),
    {
      staleTime: 3 * 60 * 1000,
    },
  )

  return { countries, isLoading }
}
