import classNames from 'classnames'
import { Picture } from 'shared/components/atoms'
import { useSensitiveInformation } from 'shared/components/atoms'
import { ListingStatus } from 'job-listing/enums'

interface MatchingPhotoProps {
  src: string | null
  listingStatus: ListingStatus
  className?: string
  size?: 'sm' | 'md'
}

export const MatchingPhoto = ({
  src,
  listingStatus,
  className,
  size = 'sm',
}: MatchingPhotoProps) => {
  const { blurSensitiveInfo } = useSensitiveInformation()

  return (
    <Picture
      photoUrl={src}
      size={size}
      className={classNames('border-2 border-neutral-day', className, {
        'blur-sm': blurSensitiveInfo || listingStatus === ListingStatus.Draft,
      })}
    />
  )
}
