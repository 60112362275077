import { plainToInstance } from 'class-transformer'
import { useNetwork } from 'shared/hooks'
import { useQuery } from 'react-query'
import { MatchingSettings } from 'matching/domain'

const nullSettings = plainToInstance(MatchingSettings, {
  profile: {
    id: '',
    rate: {
      min: 0,
      max: 0,
    },
  },
  client: {
    name: '',
  },
})

export const useMatchingSettings = ({
  listingId,
  matchingId,
}: {
  listingId?: string
  matchingId?: string
}) => {
  const { get } = useNetwork()

  const {
    data: matchingSettings = nullSettings,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ['matchings', matchingId, 'settings'],
    queryFn: () =>
      get<MatchingSettings>(`/listings/${listingId}/matchings/${matchingId}/settings`).then(
        ({ data }) => plainToInstance(MatchingSettings, data),
      ),
    enabled: Boolean(listingId && matchingId),
  })

  return { matchingSettings, isLoading: isLoading || isRefetching }
}
