import { usePendingAgreements } from './usePendingAgreements'
import { TermsOfService } from 'onboarding/exports'
import { useContext, useEffect, useState } from 'react'
import { useAuth } from 'shared/hooks'
import { useAgreements } from 'onboarding/exports'
import { LayoutContext } from 'contexts/layout'

export const PendingAgreementChecker = () => {
  const { shouldHideTerms } = useContext(LayoutContext)
  const { isLoaded, user } = useAuth()
  const [visible, setVisible] = useState(false)
  const [agreement] = usePendingAgreements()

  const { acceptAgreement } = useAgreements()

  useEffect(() => {
    setVisible(Boolean(agreement))
  }, [agreement])

  const handleAcceptance = () => acceptAgreement(agreement!).then(() => setVisible(false))

  if (visible && isLoaded && user.role && !shouldHideTerms) {
    return <TermsOfService agreement={agreement!} onAccept={handleAcceptance} />
  }

  return null
}
