import { array, boolean, number, object, string } from 'yup'

export { Certification } from './Certification'

export const schema = object().shape({
  id: string().nullable(),
  name: string().min(1, 'Required field.').max(100, 'Name cannot be longer than 100 characters.'),
  isTechnical: boolean(),
  stacks: array().when('isTechnical', {
    is: true,
    then: array()
      .of(
        object().shape({
          id: string(),
          label: string(),
        }),
      )
      .min(1, 'Choose at least one skill.')
      .max(10, 'Choose up to 10 skills.'),
  }),
  institutionId: number(),
  institutionName: string(),
  doesNotExpire: boolean(),
  issueDateMonth: number().min(0, 'Required field.'),
  issueDateYear: number().min(0, 'Required field.'),
  expireDateMonth: number().when('doesNotExpire', {
    is: false,
    then: number().min(0, 'Required field.'),
  }),
  expireDateYear: number().when('doesNotExpire', {
    is: false,
    then: number().min(0, 'Required field.'),
  }),
  credentialId: string().nullable().max(100, 'Credential ID cannot be longer than 100 characters.'),
  credentialUrl: string()
    .nullable()
    .max(1000, 'Credential URL cannot be longer than 1000 characters.'),
})

export const emptyState = {
  name: null,
  isTechnical: true,
  stacks: [],
  institutionId: null,
  institutionName: null,
  doesNotExpire: false,
  issueDateMonth: -1,
  issueDateYear: -1,
  expireDateMonth: -1,
  expireDateYear: -1,
  expireDate: null,
  credentialId: '',
  credentialUrl: '',
}

export const monthsOptions = [
  { id: 0, label: 'January' },
  { id: 1, label: 'February' },
  { id: 2, label: 'March' },
  { id: 3, label: 'April' },
  { id: 4, label: 'May' },
  { id: 5, label: 'June' },
  { id: 6, label: 'July' },
  { id: 7, label: 'August' },
  { id: 8, label: 'September' },
  { id: 9, label: 'October' },
  { id: 10, label: 'November' },
  { id: 11, label: 'December' },
]

export const yearsOptions = [] as Array<{ id: number; label: string }>
const currentYear = new Date().getFullYear()
for (let year = currentYear + 20; year >= 1995; year--)
  yearsOptions.push({ id: year, label: year.toString() })
