import { CurrencyInput, Label, PrimaryButton, TertiaryButton, Text } from 'shared/components/atoms'
import { Drawer } from 'shared/components/molecules'

import {
  Header,
  MissingScreening,
  QuestionsPreview,
  RequestProfileImprovements,
  ShortlistWithoutScreening,
  Simulation,
} from './components'

import { ReactComponent as ArrowLeftIcon } from 'icons/left-arrow.svg'

import { useForm } from 'shared/hooks'
import { useFetchActiveScreening } from 'screening/exports'
import { useUpsertMatching } from 'candidates-search/hooks'

import { Candidate } from 'candidates-search/domain'

import { schema } from './schema'
import { useContext, useEffect } from 'react'
import { ResultCardStateContext, SearchContext } from 'candidates-search/contexts'
import { MatchingStatus } from 'matching/enums'

interface Props {
  isOpen: boolean
  onClose: () => void
  candidate: Candidate
}

export const ScreeningDrawer = ({ isOpen, onClose, candidate }: Props) => {
  const { upsertMatching, isUpserting } = useUpsertMatching(candidate.id)
  const { search } = useContext(SearchContext)

  const listingId = isOpen ? search?.listing?.id : undefined
  const { screening } = useFetchActiveScreening(listingId || '')
  const { matching } = useContext(ResultCardStateContext)
  const formControl = useForm({
    schema,
    defaultValues: {
      requestProfileImprovements: false,
    },
  })

  const { register, setValue, watch, isValid, handleSubmit, getValues } = formControl

  const compensation = Number(watch('compensation')) || 0

  useEffect(() => {
    if (matching?.compensation) {
      setValue('compensation', matching.compensation)
    }
  }, [matching?.compensation])

  const handleSendScreening = handleSubmit(() => {
    const { improvements, compensation } = getValues()
    upsertMatching({
      status: MatchingStatus.Screening,
      profileImprovements: improvements,
      opportunityCompensation: compensation,
    })
  })

  if (!isOpen || !search) return null

  return (
    <Drawer
      isOpen
      customTitle={({ onClose }) => (
        <div className="flex items-center gap-4 px-10 py-6 border-b border-neutral-light">
          <TertiaryButton icon={<ArrowLeftIcon />} onClick={onClose} />
          <Text size="text-xl" weight="font-medium">
            Screening
          </Text>
        </div>
      )}
      position="right"
      handleClose={onClose}
      ctaContainerClassName="justify-end"
      cta={({ onClose }) => (
        <>
          <TertiaryButton onClick={onClose}>Cancel</TertiaryButton>
          <ShortlistWithoutScreening candidateId={candidate.id} onShortlisted={onClose} />
          {Boolean(screening) && (
            <PrimaryButton
              onClick={handleSendScreening}
              isLoading={isUpserting}
              type="submit"
              form="move-candidate-screening-form"
            >
              Send screening
            </PrimaryButton>
          )}
        </>
      )}
    >
      <div className="w-screen max-w-[600px] p-10 flex flex-col gap-10">
        <Header candidate={candidate} />
        <form
          className="flex flex-col gap-10"
          id="move-candidate-screening-form"
          onSubmit={handleSendScreening}
        >
          {Boolean(screening) && (
            <div>
              <Label htmlFor="compensation">Propose to candidate (USD)</Label>
              <CurrencyInput
                name="compensation"
                register={register}
                setValue={setValue}
                watch={watch}
                showError={false}
              />
            </div>
          )}
          <Simulation
            jobListingId={listingId}
            candidate={candidate}
            compensation={matching?.compensation || compensation}
            isValid={isValid}
            showMinimumStatement
          />
          {Boolean(screening) && (
            <RequestProfileImprovements formControl={formControl} firstName={candidate.firstName} />
          )}
        </form>
        {screening ? (
          <QuestionsPreview
            jobListingTitle={search?.listing?.title || ''}
            questions={screening?.questions || []}
          />
        ) : (
          <MissingScreening
            jobListingId={search.listing?.id}
            jobListingTitle={search.listing?.title}
          />
        )}
      </div>
    </Drawer>
  )
}
