import { cloneElement, forwardRef, KeyboardEvent, useEffect, useState } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import classNames from 'classnames'
import Downshift from 'downshift'

import { FieldHint, Input } from 'shared/components/atoms'
import { Placeholder, Row } from 'shared/components/organisms'

import { SuggestionList } from './components/SuggestionList'
import { Suggestion } from './types'

interface AutocompleteProps {
  suggestions: Suggestion[]
  selectedSuggestions?: Suggestion[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  name: string
  disabled?: boolean
  clearInputValue?: boolean
  showHint?: boolean
  allowCreation?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleAdd: (value: Suggestion, name: string) => Promise<any>
  inline?: boolean
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  maxLength?: number
  warningOnly?: boolean
  showFieldError?: boolean
  enableErrorState?: boolean
  inputIcon?: React.ReactElement
  matchDescription?: boolean
  showDescription?: boolean
  className?: string
  suggestionsClassName?: string
  autoFocus?: boolean
  onBlur?: () => void
  onInputChange?: (value: string) => string
  id?: string
  threshold?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue?: UseFormSetValue<Record<string, any>>
  distance?: number
  isLoading?: boolean
}

export const Autocomplete = forwardRef(function Autocomplete(
  {
    id,
    suggestions,
    selectedSuggestions = [],
    register,
    name,
    watch,
    disabled,
    onKeyDown,
    handleAdd,
    clearInputValue = true,
    showHint = true,
    allowCreation = true,
    inline = false,
    placeholder,
    maxLength,
    warningOnly,
    showFieldError = false,
    enableErrorState = false,
    inputIcon,
    matchDescription,
    showDescription,
    className,
    suggestionsClassName,
    onBlur,
    autoFocus = false,
    onInputChange,
    threshold,
    setValue,
    distance,
    isLoading,
  }: AutocompleteProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
) {
  const [typedOnInput, setTypedOnInput] = useState('')
  const [selectedItem, setSelectedItem] = useState('')

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const stateReducer = (state: any, changes: any) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        const data = {
          ...changes,
          isOpen: false,
          highlightedIndex: state.highlightedIndex,
          selectedItem: changes.inputValue,
          inputValue: clearInputValue ? '' : changes.inputValue,
        }

        if (setValue) setValue(name, data.inputValue)
        setTypedOnInput(data.inputValue)
        setSelectedItem(data.inputValue)

        if (changes.fired) return data

        // TODO - try to prevent the double event triggering without changing the state directly
        changes.fired = true

        handleAdd(changes.selectedItem || typedOnInput, name).then(
          () => (changes = changes.fired = false),
        )

        return data
      case Downshift.stateChangeTypes.changeInput:
        if (onInputChange) {
          changes.inputValue = onInputChange(changes.inputValue)
        }

        setTypedOnInput(changes.inputValue)
        return changes
      case Downshift.stateChangeTypes.keyDownEscape:
      case Downshift.stateChangeTypes.blurInput:
      case Downshift.stateChangeTypes.mouseUp:
        if (setValue && clearInputValue) setValue(name, '')

        setTypedOnInput(selectedItem)
        return changes
      default:
        return changes
    }
  }

  const buildStyledIcon = () => {
    if (!inputIcon) return
    return cloneElement(inputIcon, {
      style: {
        stroke: 'inherit',
        height: '16px',
        width: '16px',
        position: 'absolute',
        margin: '12px 12px 12px 16px',
      },
    })
  }

  const value = watch(name)
  const [error] = register(name)

  useEffect(() => {
    setSelectedItem(value || '')
    setTypedOnInput(value || '')
  }, [value])

  if (isLoading) {
    return (
      <Placeholder className="-mb-2">
        <Row height="h-10" width="w-12/12" />
      </Placeholder>
    )
  }

  return (
    <>
      <Downshift
        stateReducer={stateReducer}
        itemToString={(suggestion: Suggestion | null) => suggestion?.label || ''}
        inputValue={typedOnInput}
        defaultHighlightedIndex={0}
        ref={ref}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          openMenu,
        }) => (
          <div className={classNames('relative flex', { 'inline-flex': inline })}>
            {buildStyledIcon()}
            <Input
              {...getInputProps({
                onKeyDown,
                onFocus: openMenu,
                onClick: openMenu,
                onBlur: onBlur,
                value: inputValue,
              })}
              id={id}
              ref={null}
              name={name}
              register={register}
              disabled={disabled}
              type="text"
              showFieldError={showFieldError}
              injected={inline}
              placeholder={placeholder}
              maxLength={maxLength}
              autoFocus={autoFocus}
              className={classNames(
                {
                  'disabled:bg-transparent': inline,
                  'bg-warning-lighter outline-warning-medium outline outline-2 outline-offset-[-2px]':
                    Boolean(error),
                  'pl-11': Boolean(inputIcon),
                },
                className,
              )}
              warningOnly={warningOnly}
              enableErrorState={enableErrorState}
            />
            <SuggestionList
              suggestions={suggestions}
              selectedSuggestions={selectedSuggestions}
              inputValue={inputValue}
              isOpen={isOpen}
              selectedItem={selectedItem}
              highlightedIndex={highlightedIndex}
              getMenuProps={getMenuProps}
              getItemProps={getItemProps}
              allowCreation={allowCreation}
              matchDescription={matchDescription}
              showDescription={showDescription}
              className={suggestionsClassName}
              threshold={threshold}
              distance={distance}
            />
          </div>
        )}
      </Downshift>
      {showHint && (
        <FieldHint className="mt-0" color="text-brand-medium">
          Press enter to add.
        </FieldHint>
      )}
    </>
  )
})
