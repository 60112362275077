import { useNetwork } from 'shared/hooks'
import { SupplementalTermsFeature } from 'modules/supplemental-terms/enums'
import { useMutation, useQueryClient } from 'react-query'
import { Agreements } from 'account/types'

export const useAcceptSupplementalTerms = (feature: SupplementalTermsFeature) => {
  const { post } = useNetwork()
  const client = useQueryClient()

  const { mutateAsync: acceptTerms, isLoading: isAccepting } = useMutation(
    async (agreement: Agreements) =>
      await post(`agreements/supplemental-terms/${agreement.id}/acceptance`),

    {
      onSuccess: () => client.invalidateQueries(`supplemental-terms/${feature}/pending`),
    },
  )

  return { acceptTerms, isAccepting }
}
