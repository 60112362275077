import { cn } from 'modules/shared'
import { InputHTMLAttributes } from 'react'
import { UseFormWatch } from 'react-hook-form'
import { Paragraph } from 'shared/components/atoms'
import { FieldError } from 'shared/components/atoms'

interface TextareaProps extends InputHTMLAttributes<HTMLInputElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  name: string
  watch?: UseFormWatch<Record<string, string>>
  disabled?: boolean
  rows?: number
  maxLength?: number
  children?: string | JSX.Element | React.ReactElement | false
  customError?: string
  omitErrors?: boolean
  showCharacterCounter?: boolean
  className?: string
}

export const textareaStyles = (error?: string, className?: string) => {
  return cn(
    'w-full h-40 px-4 py-2 whitespace-pre-wrap',
    'border border-neutral-light rounded-lg',
    'font-inter font-normal text-sm text-neutral-darkest leading-[22px]',
    'disabled:bg-neutral-lightest disabled:border-neutral-light disabled:text-neutral-medium disabled:shadow-none',
    {
      'bg-warning-lighter outline-warning-medium outline outline-2 outline-offset-[-2px]': error,
    },
    className,
  )
}

export const Textarea = ({
  register,
  name,
  watch,
  disabled,
  rows,
  maxLength,
  children,
  omitErrors,
  customError,
  showCharacterCounter,
  className,
  ...restProps
}: TextareaProps) => {
  const [error, fieldRegister] = register(name)
  const classes = textareaStyles(customError || error, className)

  const value = (watch && watch(name)) || ''

  return (
    <>
      <textarea
        className={classes}
        {...fieldRegister()}
        disabled={disabled}
        maxLength={maxLength}
        rows={rows}
        {...restProps}
        id={name}
      />
      {showCharacterCounter && !(customError || error) && (
        <Paragraph className="text-neutral-dark">
          {value.length}/{maxLength || 0}
        </Paragraph>
      )}
      {children}
      {!omitErrors && <FieldError message={customError || error} />}
    </>
  )
}
