import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Spinner, Text } from 'shared/components/atoms'
import { SignInForm, SignInSidePanel } from 'authentication/components'
import { Container, LightBackground } from 'onboarding/components'

import { useLandingTracking, useRedirectToReferrals } from 'shared/hooks'

import { RouterContext } from 'contexts/router'

const SignInPage = () => {
  const route = useContext(RouterContext)
  const { isSdrFlow } = useLandingTracking()
  const navigate = useNavigate()
  const { shouldRedirectToReferrals } = useRedirectToReferrals()

  useEffect(() => {
    if (isSdrFlow) navigate('/signup?redirectUrl=/employer-onboarding')
  }, [isSdrFlow])

  if (isSdrFlow) return <Spinner />

  return (
    <Container
      background={<LightBackground />}
      asideContent={<SignInSidePanel />}
      asideClassName="max-w-[897px] h-[calc(100vh-40px)] m-0"
      containerClassNames="items-center"
      logoClassName="mb-0 mt-12 ml-6 md:ml-12"
      contentClassNames="sm:p-0 p-0 w-full md:w-[490px]"
      cardDescription={
        <Text className="block text-center max-w-sm">
          Anyone with a Strider account can log in here whether you're hiring, looking for a job, or
          referring talent.
        </Text>
      }
    >
      <SignInForm redirectUrl={shouldRedirectToReferrals ? '/referrals/' : route.from} />
    </Container>
  )
}

export { SignInPage }
