import { ForwardedRef, forwardRef } from 'react'
import classNames from 'classnames'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import Input, { CurrencyInputProps as InputProps } from 'react-currency-input-field'

import { FieldError } from 'shared/components/atoms'
import { Paragraph } from 'shared/components/atoms'

interface CurrencyInputProps extends InputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  className?: string
  inputClassName?: string
  autoFocus?: boolean
  showError?: boolean
  alwaysValidate?: boolean
}

export const CurrencyInput = forwardRef(function CurrencyInput(
  {
    register,
    name,
    watch,
    setValue,
    className,
    inputClassName,
    allowDecimals = true,
    autoFocus,
    showError = true,
    alwaysValidate = false,
    ...restProps
  }: CurrencyInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [error] = register(name)
  const value = watch(name)

  const handleChange = (value: string | undefined) =>
    setValue(name, value, { shouldValidate: alwaysValidate, shouldDirty: true })

  const handleFocusOut = () => setValue(name, value, { shouldValidate: true, shouldDirty: true })

  return (
    <div className={classNames('flex flex-col relative', className)}>
      <Paragraph
        size="body-md"
        weight="font-normal"
        className="text-neutral-medium absolute top-1 left-4"
      >
        $
      </Paragraph>
      <Input
        id={name}
        decimalSeparator="."
        groupSeparator=","
        allowDecimals={allowDecimals}
        placeholder="0.00"
        name={name}
        value={value}
        onValueChange={handleChange}
        onBlur={handleFocusOut}
        autoFocus={autoFocus}
        className={classNames(
          'w-full h-10 py-2 pl-8 pr-4',
          'border border-neutral-light rounded-lg',
          'font-inter font-normal text-base text-neutral-darkest',
          'disabled:bg-neutral-lightest disabled:border-neutral-light disabled:text-neutral-medium disabled:shadow-none',
          {
            'bg-danger-lighter outline-danger-light outline outline-2 outline-offset-[-2px]': error,
          },
          inputClassName,
        )}
        ref={ref}
        aria-labelledby={name}
        {...restProps}
      />
      {showError && <FieldError message={error} />}
    </div>
  )
})
