import { MatchingSettingsFormSchema } from 'matching/domain'

export class UpsertMatchingSettingsDTO {
  constructor(private readonly settings: MatchingSettingsFormSchema) {}

  toJSON() {
    return {
      compensation: Number(this.settings.compensation),
      fee: Number(this.settings.fee),
      briefing: this.settings.briefing,
      highlightedSkills: this.settings.highlightedSkills.map((skill) => skill.id),
    }
  }
}
