import { Text } from 'shared/components/atoms'
import { ReactComponent as CloseIcon } from 'icons/close-circle.svg'

export const DisqualifiedBadge = () => {
  return (
    <div className="flex gap-2 items-center justify-center px-4 py-2 h-10 bg-neutral-lightest">
      <CloseIcon className="w-6 h-6" />
      <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
        Disqualified
      </Text>
    </div>
  )
}
