import { CandidateContext } from 'contexts/candidate'
import { useMonitoring } from 'shared/hooks'
import { useNetwork } from 'shared/hooks'
import { JobOpportunity } from 'job-opportunity/domain'
import { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useTracking } from 'tracking'
import {
  JobOpportunityStartedAnswering,
  JobOpportunityView,
} from 'tracking/events/job-opportunities'

export const useTrackJobOpportunity = (jobOpportunity?: JobOpportunity) => {
  const { candidate } = useContext(CandidateContext)

  const { patch } = useNetwork()
  const { captureException } = useMonitoring()
  const { trackEvent } = useTracking()
  const client = useQueryClient()

  const { mutateAsync: startFilling } = useMutation(
    async () => {
      if (!jobOpportunity?.id || jobOpportunity.startedFillingAt) return

      trackEvent(new JobOpportunityStartedAnswering(jobOpportunity.id))

      return patch(
        `profile/${candidate.id}/screening-assignments/${jobOpportunity.screening.id}/fills`,
      )
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['job-opportunity', jobOpportunity!.id])
        client.invalidateQueries('job-opportunities')
      },
      onError: (error) => {
        captureException(error)
      },
    },
  )

  const { mutateAsync: view } = useMutation(
    async () => {
      if (!jobOpportunity?.id) return

      trackEvent(new JobOpportunityView(jobOpportunity.id))

      if (jobOpportunity.firstViewedAt) return

      return patch(
        `profile/${candidate.id}/screening-assignments/${jobOpportunity.screening.id}/views`,
      )
    },
    {
      onSuccess: () => {
        client.invalidateQueries('job-opportunities')
      },
      onError: (error) => {
        captureException(error)
      },
    },
  )

  return { startFilling, view }
}
