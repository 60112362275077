import { SelectStacks } from 'profile/exports'
import { FormControl } from 'shared/hooks'

interface Props {
  formControl: FormControl
  name: string
  error?: string
}

export const RequiredSkills = ({ formControl, name, error }: Props) => {
  const { watch } = formControl
  const stacks = watch(name)

  return (
    <>
      <SelectStacks
        formControl={formControl}
        name={name}
        label="Desired skills"
        selectedOptions={stacks}
        error={error}
        allowCreation
        containerClassName="mb-10"
      />
    </>
  )
}
