import { useQuery } from 'react-query'

import { useMonitoring, useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'

import { FetchSearchDTO, GroupedSearchesResponse } from 'candidates-search/dtos'

export const useSearches = () => {
  const { get } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const {
    data: groupedSearches = [],
    isLoading,
    isRefetching,
  } = useQuery(
    'searches',
    () =>
      get<Array<GroupedSearchesResponse>>(`/advanced-search`).then(({ data }) =>
        new FetchSearchDTO(data).toDomain(),
      ),
    {
      onError: (error: RequestError) => {
        toastError(error.response.data?.message)
        captureException(error)
      },
    },
  )

  return { groupedSearches, isLoading, isRefetching }
}
