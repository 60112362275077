import { FormListing } from 'job-listing/domains'

export class SubscribersRequest {
  constructor(private readonly listing: FormListing) {}

  toJSON() {
    return {
      subscribersId: this.subscribersIds,
    }
  }

  private get subscribersIds() {
    return this.listing.subscribers?.map(({ id }) => id)
  }
}
