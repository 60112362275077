import { AlertDialog } from 'shared/components/molecules'
import { Text } from 'shared/components/atoms'
import { useTeleport } from 'hooks/teleport'
import { useNavigate } from 'react-router-dom'
import { ListingInfo } from 'job-listing/domains'

export const TeleportToOwnerDialog = ({ listing }: { listing?: ListingInfo }) => {
  const navigate = useNavigate()
  const { teleport } = useTeleport()

  const handleDialogCancel = () => navigate(`/listings/${listing?.id}/matchings`, { replace: true })
  const handleDialogConfirm = () => {
    if (!listing) return
    teleport(listing.company.id).then(() => {
      window.location.reload()
    })
  }

  return (
    <AlertDialog
      isOpen
      preventOpenChangeHandling
      title="Teleport required"
      description={
        <Text size="text-base" weight="font-normal">
          Before setting a new screening, teleport to the listings's company.
        </Text>
      }
      onCancelClick={handleDialogCancel}
      confirmText="Teleport"
      onConfirmClick={handleDialogConfirm}
    />
  )
}
