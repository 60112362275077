import classNames from 'classnames'
import { ReactNode } from 'react'

import { Text } from 'components/Text'
import { Title } from 'shared/components/atoms'
import { WorkExperience } from 'modules/work-experience'
import { UpsertButton } from '../UpsertButton'
import { ManagementInfo } from './ManagementInfo'

interface HeaderProps {
  title: ReactNode
  subtitle: React.ReactNode
  subHeader?: JSX.Element | null
  experience?: WorkExperience
  readOnly: boolean
  className?: string
  handleEdit?(): void
}

export const Header = ({
  title,
  subtitle,
  subHeader,
  experience,
  readOnly,
  className = 'mb-0',
  handleEdit,
}: HeaderProps) => {
  return (
    <div className={classNames('flex-grow flex flex-col gap-1', className)}>
      <div className="flex-grow flex flex-row items-center justify-between">
        <div className="flex flex-col sm:flex-row sm:items-center gap-1 sm:gap-4">
          <Title size="small-heading" className="flex">
            {title}
          </Title>
        </div>
        <UpsertButton onClick={handleEdit!} readOnly={readOnly} />
      </div>
      {subtitle}
      <Text className="!text-neutral-dark">{subHeader}</Text>
      <ManagementInfo experience={experience} />
    </div>
  )
}
