import { useMutation } from 'react-query'

import { useMonitoring, useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'

import { EnglishVettingProgress } from 'modules/candidates/enums'
import { EnglishVetting } from 'modules/candidates/domain'

export const useEnglishEvaluation = () => {
  const { post } = useNetwork()
  const { toastSuccess, toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: sendEvaluationToQueue } = useMutation((profileId: string) =>
    post(`vettings/evaluations/pending/${profileId}/resume`)
      .then(() => {
        toastSuccess('Evaluation sent to queue.')
      })
      .catch((error: RequestError) => {
        toastError('Failed to send evaluation to queue')
        captureException(error)
      }),
  )

  const isEvaluationOnHold = (evaluation: EnglishVetting | null) => {
    if (!evaluation) return false
    return [EnglishVettingProgress.Disqualified].includes(evaluation.vettingProgress)
  }

  return { sendEvaluationToQueue, isEvaluationOnHold }
}
