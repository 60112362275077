import { useQuery } from 'react-query'

import { AssessmentTag } from 'modules/profiles/domain'
import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useAssessmentTags = ({ enabled }: { enabled: boolean }) => {
  const { get } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { data: tags = [], isLoading } = useQuery(
    'assessmentTags',
    () => get<Array<AssessmentTag>>('profile/profile-tags').then(({ data }) => data),
    {
      enabled,
      staleTime: Infinity,
      onError: (error) => {
        toastError('Failed to fetch assessment tags.')
        captureException(error)
      },
    },
  )

  const getById = (id: string) => tags.find((tag) => tag.id === id)

  return { tags, isLoading, getById }
}
