import { PaymentModel } from 'job-listing/enums'
import { Warning } from 'shared/components/molecules'

interface ListingWithMatchingsBannerProps {
  initialPaymentModel?: PaymentModel
  visible: boolean
  className?: string
}

export const ListingWithMatchingsBanner = ({
  initialPaymentModel,
  visible,
  className,
}: ListingWithMatchingsBannerProps) => {
  const from = initialPaymentModel === PaymentModel.Hourly ? 'hourly' : 'monthly'
  const to = initialPaymentModel === PaymentModel.Hourly ? 'monthly' : 'hourly'

  if (!visible) return null

  return (
    <Warning className={className}>
      This job listing has candidates matched with {from} values. Switching to {to} rates requires
      reviewing all match values so that customers can see the values.
    </Warning>
  )
}
