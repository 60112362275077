import { MobileWarning } from 'shared/components/molecules'
import { AccountClaim, useAuth } from 'shared/hooks'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

export const EnglishCheckRoutes = () => {
  const navigate = useNavigate()
  const { user, isLoaded } = useAuth()
  const hasAccess = [
    AccountClaim.EnglishCheckEvaluations,
    AccountClaim.EnglishCheckManagement,
    AccountClaim.EnglishCheckViewEvaluation,
  ].some((claim) => user.claims.includes(claim))

  useEffect(() => {
    if (isLoaded && !hasAccess) navigate('/')
  }, [hasAccess, isLoaded])

  if (hasAccess)
    return (
      <>
        <Outlet />
        <MobileWarning
          title="The english check only works on desktop devices"
          text="Please use a larger-screen device to access this feature."
          className="h-screen w-full fixed top-0 left-0 lg:hidden bg-white"
        />
      </>
    )

  return null
}
