import { OrganizationIcon } from 'shared/components/molecules'

interface InstitutionIconProps {
  domain: string | null
  icon: React.ReactNode
}

export const InstitutionIcon = ({ domain, icon }: InstitutionIconProps) => {
  return <OrganizationIcon domain={domain} icon={icon} />
}
