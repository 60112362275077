import { useMutation } from 'react-query'

import { useMonitoring, useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { HiringMode, useAuth } from 'shared/hooks'
import { CreateJobOfferDTO } from 'modules/hires/dtos'
import { OfferFormValues } from 'modules/hires/domain'

export const useMakeOffer = (matchingId: string, hiringMode: HiringMode) => {
  const { post } = useNetwork()
  const { toastError } = useToast()
  const { supplementalTermsUrl, user } = useAuth()
  const userFullName = [user.firstName, user.lastName].join(' ')
  const { captureException } = useMonitoring()

  const { mutateAsync: makeOffer, isLoading } = useMutation(
    async (offerRequest: OfferFormValues) => {
      const request = Object.assign({}, offerRequest, {
        contactFullName: offerRequest.contactFullName || userFullName,
        contactEmail: offerRequest.contactEmail || user.email,
      })

      const payload = new CreateJobOfferDTO(
        matchingId,
        request,
        supplementalTermsUrl,
        hiringMode,
      ).toJSON()

      await post('hires/job-offer', payload).catch((error: RequestError) => {
        toastError('Error when creating the offer: ' + error.response?.data.message)
        captureException(error)
      })
    },
  )

  return { makeOffer, isRequesting: isLoading }
}
