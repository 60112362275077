import { Screening } from '../domain'
import { UpdateJobDescriptionDTO } from './update-job-description.dto'
import { UpdateQuestionsDTO } from './update-questions.dto'

export class UpsertScreeningDTO {
  constructor(private readonly screening: Partial<Screening>) {}

  private get questions() {
    const { questions } = this.screening
    if (questions) return new UpdateQuestionsDTO(questions).toJSON()
  }

  private get jobDescription() {
    const { jobDescription } = this.screening
    if (jobDescription) return new UpdateJobDescriptionDTO(jobDescription).toJSON()
  }

  toJSON() {
    return {
      title: this.screening.title,
      questions: this.questions,
      deadline: Number(this.screening.deadline) || null,
      minimumPayRate: Number(this.screening.minimumPayRate) || null,
      maximumPayRate: Number(this.screening.maximumPayRate) || null,
      paymentModel: this.screening.paymentModel,
      jobDescription: this.jobDescription,
    }
  }
}
