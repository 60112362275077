import { cn } from 'modules/shared'
import { ReactNode } from 'react'

export interface FieldHintProps {
  children: ReactNode
  className?: string
  color?: 'text-neutral-dark' | 'text-brand-medium' | 'text-neutral-medium'
  size?: 'sm' | 'md' | 'lg'
}

export const FieldHint = ({
  children,
  className,
  color = 'text-neutral-dark',
  size = 'md',
}: FieldHintProps) => {
  const classes = cn(
    'block mb-2 mt-1 font-normal',
    className,
    {
      'text-xs': size === 'sm',
      'text-sm': size === 'md',
      'text-sm sm:text-base': size === 'lg',
    },
    color,
  )

  return <span className={classes}>{children}</span>
}
