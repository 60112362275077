import { useState } from 'react'
import * as Dialog from '@radix-ui/react-alert-dialog'

import {
  NullStepsWizard,
  PrimaryButton,
  SecondaryButton,
  StepsWizard,
  StepsWizardInstance,
  TertiaryButton,
} from 'shared/components/atoms'
import { ProgressDots } from './ProgressDots'

import { ReactComponent as CloseIcon } from 'icons/close.svg'

interface FeaturedSlidesProps {
  children: Array<JSX.Element>
  trigger?: JSX.Element
  open?: boolean
  nextLabel?: string
  backLabel?: string
  confirmLabel: string
  cancelLabel?: string
  steps: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm: any
  headerImages?: Record<number, string>
  confirmationStep?: number
}

export const FeaturedSlides = ({
  children,
  trigger,
  open,
  nextLabel = 'Continue',
  backLabel = 'Back',
  confirmLabel,
  cancelLabel = "I'll do it later",
  steps,
  onConfirm,
  confirmationStep = steps,
  headerImages = {},
}: FeaturedSlidesProps) => {
  const [step, setStep] = useState(1)
  const [isOpen, setIsOpen] = useState(open)

  const [instance, setInstance] = useState<StepsWizardInstance>(NullStepsWizard)

  const isConfirmationStep = step === confirmationStep
  const isLastStep = step === steps
  const isFistStep = step === 1
  const actionLabel = isConfirmationStep ? confirmLabel : nextLabel
  const previousLabel = isFistStep ? cancelLabel : backLabel
  const headerImage = headerImages[step]
  const disableNextButton = isLastStep && !isConfirmationStep

  const handleStepChange = ({ activeStep }: { activeStep: number }) => {
    setStep(activeStep)
  }

  const handleClose = () => {
    setStep(1)
    setIsOpen(false)
  }

  const handleCancelClick = () => {
    if (!isFistStep) {
      setStep(step - 1)
      return instance.previousStep()
    }

    handleClose()
  }

  const handleConfirmClick = () => {
    if (isConfirmationStep) {
      onConfirm()
    }

    if (isLastStep) {
      setStep(1)
      return setIsOpen(false)
    }

    instance.nextStep()
  }

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setIsOpen(true)
  }

  return (
    <>
      <div onClick={handleClick} onKeyPress={handleClick} role="button" tabIndex={-1}>
        {trigger}
      </div>
      <Dialog.Root open={isOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/40 z-40" />
          <Dialog.Content
            className="
            fixed bottom-0 lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 z-40
            w-full lg:max-w-[600px] h-full lg:h-fit
            bg-white lg:rounded-lg
            flex flex-col pb-6 overflow-x-hidden justify-between md:justify-start
          "
          >
            <div>
              <header className="relative w-full h-10 -mb-10 bg-transparent">
                {headerImage && <img src={headerImage} key={headerImage} className="fixed r-0" />}
                <TertiaryButton
                  size="md"
                  icon={<CloseIcon />}
                  onClick={handleClose}
                  className="bg-transparent !absolute right-0 z-10"
                />
              </header>
              <Dialog.Description asChild>
                <StepsWizard
                  className="w-full pb-10"
                  onStepChange={handleStepChange}
                  instance={setInstance}
                >
                  {children}
                </StepsWizard>
              </Dialog.Description>
            </div>
            <div>
              <div className="flex flex-col-reverse md:flex-row gap-4 w-full px-10">
                <Dialog.Cancel asChild>
                  <SecondaryButton
                    size="md"
                    onClick={handleCancelClick}
                    className="w-full md:w-1/2"
                  >
                    {previousLabel}
                  </SecondaryButton>
                </Dialog.Cancel>
                <Dialog.Action asChild>
                  <PrimaryButton
                    size="md"
                    onClick={handleConfirmClick}
                    className="w-full md:w-1/2"
                    disabled={disableNextButton}
                  >
                    {actionLabel}
                  </PrimaryButton>
                </Dialog.Action>
                <ProgressDots length={steps} activeStep={step} className="md:hidden" />
              </div>
              <ProgressDots length={steps} activeStep={step} className="hidden md:flex" />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  )
}
