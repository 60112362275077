import { ProfileStatus } from 'modules/candidates/enums'
import { useMutation, useQueryClient } from 'react-query'
import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useUpdateProfileAvailability = (profileId: string) => {
  const { post } = useNetwork()
  const client = useQueryClient()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: updateProfileAvailability, isLoading } = useMutation(
    (payload: { days?: number; status: ProfileStatus }) => {
      return post(`/profile/${profileId}/job-search-availability`, payload)
    },
    {
      onSuccess: () => {
        client.invalidateQueries(`candidates/${profileId}`)
        client.invalidateQueries('profile')
        client.invalidateQueries(`${profileId}-preferences`)
      },
      onError: (error) => {
        toastError('Failed to update the profile availability')
        captureException(error)
      },
    },
  )

  return { updateProfileAvailability, isLoading }
}
