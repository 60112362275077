import { AlertDialog } from 'shared/components/molecules'
import { SecondaryButton, PrimaryButton, DestructiveButton } from 'shared/components/atoms'
import { TertiaryButton } from 'shared/components/atoms'
import { Text } from 'components/Text'

export const Footer = ({
  isEdit,
  onSubmit,
  handleClose,
  handleDelete,
  form,
  deleteButtonLabel,
  isLoading,
  isDeleting,
}: {
  isEdit: boolean | undefined
  onSubmit(addAnother: boolean): void
  handleClose(): void
  handleDelete(): void
  form: string
  deleteButtonLabel: string
  isLoading: boolean
  isDeleting: boolean
}) => {
  return isEdit ? (
    <div className="w-full h-full flex flex-col-reverse md:flex-row items-center justify-between gap-3">
      <AlertDialog
        title="Are you sure?"
        description={<Text>This action cannot be undone.</Text>}
        confirmText="Yes, I want to delete it"
        trigger={
          <DestructiveButton className="w-full md:w-fit order-2 md:order-1" isLoading={isDeleting}>
            {deleteButtonLabel}
          </DestructiveButton>
        }
        onConfirmClick={() => handleDelete()}
      />

      <div className="flex w-full md:w-fit flex-col md:flex-row gap-3 md:gap-4 md:order-2">
        <TertiaryButton
          size="md"
          onClick={handleClose}
          className="w-full md:w-fit order-2 md:order-1"
          isLoading={isLoading}
        >
          Cancel
        </TertiaryButton>
        <PrimaryButton
          size="md"
          type="submit"
          form={form}
          className="w-full order-1 md:order-2 md:w-fit"
          isLoading={isLoading}
        >
          Save
        </PrimaryButton>
      </div>
    </div>
  ) : (
    <>
      <TertiaryButton
        size="md"
        onClick={handleClose}
        className="w-full md:w-fit"
        isLoading={isLoading}
      >
        Cancel
      </TertiaryButton>
      <SecondaryButton
        size="md"
        onClick={() => onSubmit(true)}
        className="w-full md:w-fit"
        isLoading={isLoading}
      >
        Save and add another
      </SecondaryButton>
      <PrimaryButton
        size="md"
        type="submit"
        form={form}
        className="w-full md:w-fit"
        isLoading={isLoading}
      >
        Save
      </PrimaryButton>
    </>
  )
}
