import { Paragraph } from 'shared/components/atoms'
import { Tags } from 'shared/components/atoms'

import { usePayRateTags } from 'hooks/payRate'

interface PayRateProps {
  hourlyRate?: {
    min?: number
    max?: number
  }
  monthlyRate: {
    min?: number
    max?: number
  }
}

export const PayRate = ({ hourlyRate, monthlyRate }: PayRateProps) => {
  const payRateTags = usePayRateTags(
    { monthlyRate, hourlyRate },
    { monthlySuffix: 'mos', hourlySuffix: 'hr' },
  )

  return (
    <div>
      <Paragraph size="body-xs" weight="font-normal" className="text-neutral-dark mb-1">
        Pay rate
      </Paragraph>
      <Tags values={payRateTags} background="bg-neutral-lightest" isReadOnly />
    </div>
  )
}
