import { useState } from 'react'

import { Checkbox, FieldError, Label, PreviewLabel } from 'shared/components/atoms'

import { ListingTermsModal } from '../../ListingTermsModal'

import { useFormatting } from 'shared/hooks'
import { ListingTerm } from 'job-listing/dtos'

interface ListingTermsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  errorMessage?: string
  disabled?: boolean
  term: ListingTerm
}
export const ListingTerms = ({
  register,
  errorMessage,
  disabled = false,
  term,
}: ListingTermsProps) => {
  const [termsModalVisible, setTermsModalVisible] = useState(false)
  const { formatCurrency } = useFormatting()
  const feeLabel = Number(term.upfrontFee) ? formatCurrency(term.upfrontFee) : `$0 (free)`

  const handleListingTermsClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    setTermsModalVisible(true)
  }

  return (
    <>
      <ListingTermsModal
        content={term.content}
        setTermsModalVisible={setTermsModalVisible}
        isVisible={termsModalVisible}
      />
      <div className="flex flex-col gap-8">
        <div className="bg-neutral-lightest p-6 rounded-lg">
          <div>
            <div className="flex flex-col lg:flex-row gap-y-4 lg:gap-y-0 gap-x-14 whitespace-nowrap">
              <PreviewLabel title="Listing fee" titleSize="small-heading">
                {feeLabel}
              </PreviewLabel>
              <PreviewLabel title="Success fee" titleSize="small-heading">
                {Number(term.successFee)}% of first year pay
              </PreviewLabel>
              <PreviewLabel title="Guarantee period" titleSize="small-heading">
                {term.guaranteeDays} days
              </PreviewLabel>
            </div>
          </div>
        </div>
        <div>
          <Label htmlFor="acceptTerms" weight="font-normal" className="cursor-pointer">
            <Checkbox register={register} name="acceptTerms" id="acceptTerms" disabled={disabled} />
            I agree to the{' '}
            <a href="#" className="underline text-info-medium" onClick={handleListingTermsClick}>
              listing terms
            </a>
            <FieldError message={errorMessage} />
          </Label>
        </div>
      </div>
    </>
  )
}
