import { useEffect, useState } from 'react'

import { RegularOfferForm } from './components/RegularOfferForm'
import { RetentionOfferForm } from './components/RetentionOfferForm'
import { SelectMatchingModal } from './components/SelectMatchingModal'

import { HiringMode, useAuth } from 'shared/hooks'

import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { useHiringCost } from './hooks'

interface MakeOfferModalProps {
  profile: ProfileOverview
  isOpen: boolean
  matchingId?: string
  onClose: () => void
}

export const MakeOfferModal = ({ profile, isOpen, matchingId, onClose }: MakeOfferModalProps) => {
  const [selectedMatchingId, setSelectedMatchingId] = useState<string>()

  const { user } = useAuth()
  const { hiringMode } = user.company || {}

  const showRegularForm = hiringMode !== HiringMode.ThroughUsEmployerOfRecord
  const { hiringCost, paymentModel } = useHiringCost(profile, selectedMatchingId || '')

  const handleSelectMatchingConfirm = (matchingId: string) => {
    setSelectedMatchingId(matchingId)
  }

  const handleClose = () => {
    setSelectedMatchingId(undefined)
    onClose()
  }

  useEffect(() => {
    if (matchingId) return setSelectedMatchingId(matchingId)

    const { matchingsForOffer } = profile
    if (matchingsForOffer.length === 1) return setSelectedMatchingId(matchingsForOffer[0].id)
  }, [isOpen])

  if (!isOpen) return null
  if (!selectedMatchingId) {
    return (
      <SelectMatchingModal
        profile={profile}
        onConfirm={handleSelectMatchingConfirm}
        onCancel={handleClose}
      />
    )
  }

  if (showRegularForm) {
    return (
      <RegularOfferForm
        matchingId={selectedMatchingId}
        hiringCost={hiringCost}
        paymentModel={paymentModel}
        profile={profile}
        isOpen
        onClose={handleClose}
      />
    )
  }

  return (
    <RetentionOfferForm
      matchingId={selectedMatchingId}
      hiringCost={hiringCost}
      paymentModel={paymentModel}
      profile={profile}
      isOpen
      onClose={handleClose}
    />
  )
}
