import * as RadixTabs from '@radix-ui/react-tabs'
import classNames from 'classnames'

interface TabsListProps {
  children: React.ReactNode
  className?: string
}

export const TabsList = ({ children, className }: TabsListProps) => {
  return (
    <RadixTabs.List
      className={classNames('flex flex-nowrap whitespace-nowrap overflow-x-auto', className)}
    >
      {children}
    </RadixTabs.List>
  )
}
