import classNames from 'classnames'

import { Banners } from './components/Banners'
import { useFormatting } from 'shared/hooks'
import { StatementRow } from './components/StatementRow'
import { MatchingSimulation as MatchingSimulationType } from 'hooks/matchings/useMatchingSimulation'
import { PaymentModel } from 'job-listing/enums'

interface MatchingSimulationProps {
  simulation: MatchingSimulationType | null
  client: string
  isMissingParams: boolean
  minCompensation: number
  maxCompensation: number
  isValid: boolean
  readOnly?: boolean
  paymentModel: PaymentModel
  showMinimumStatement?: boolean
}

export const MatchingSimulation = ({
  simulation,
  client,
  isMissingParams,
  minCompensation,
  maxCompensation,
  isValid,
  readOnly,
  paymentModel,
  showMinimumStatement,
}: MatchingSimulationProps) => {
  const { formatCurrency } = useFormatting()

  const paymentModelLabel = paymentModel === PaymentModel.Hourly ? 'hour' : 'month'

  if (!simulation) return null

  return (
    <div className="flex flex-col gap-6 sm:gap-10 -mt-9">
      <Banners
        client={client}
        withinBudget={simulation.withinBudget}
        budgetDifference={simulation.budgetDifference}
        isMissingParams={isMissingParams}
        inConflict={simulation.conflict && !readOnly}
        budget={simulation.budget}
        paymentModel={paymentModel}
      />

      <div className="flex flex-col gap-4">
        <StatementRow
          id="simulationFee"
          label="Strider Fee"
          tooltip="Value of the fee the client will pay per month"
          value={`${formatCurrency(simulation.fee)}/${paymentModelLabel}`}
        />
        <StatementRow
          id="simulationPayRate"
          label="Candidate's expected pay rate"
          tooltip="How much the candidates wants to earn per month"
          value={`${formatCurrency(minCompensation)} – ${formatCurrency(
            maxCompensation,
          )}/${paymentModelLabel}`}
        />
        <div className="h-[1px] w-full bg-neutral-lighter" />
        {showMinimumStatement ? (
          <StatementRow
            id="simulationMinTalentCost"
            label="Minimum total talent cost"
            tooltip="How much our client will pay per month"
            value={`${formatCurrency(minCompensation + simulation.fee)}/${paymentModelLabel}`}
          />
        ) : (
          <StatementRow
            id="simulationTotalTalentCost"
            label="Total Talent Cost"
            tooltip="How much our client will pay per month"
            value={isValid ? `${formatCurrency(simulation.cost)}/${paymentModelLabel}` : '–'}
            valueClassName={classNames({
              'text-success-dark': isValid && simulation.withinBudget,
              'text-danger-dark': isValid && !simulation.withinBudget,
            })}
          />
        )}
      </div>
    </div>
  )
}
