import { useQuery } from 'react-query'

import { useMonitoring, useNetwork } from 'shared/hooks'
import { FetchInstitutionResponse } from 'modules/profiles/dtos'

export const useFetchInstitutions = (profileId: string) => {
  const { get } = useNetwork()
  const { captureException } = useMonitoring()

  const {
    data: institutions = [],
    refetch: getInstitution,
    isLoading,
    isRefetching,
  } = useQuery(
    'institutions',
    () =>
      get<FetchInstitutionResponse>(`profile/${profileId}/institutions`).then(
        ({ data }) => data || [],
      ),
    {
      staleTime: Infinity,
      onError: (error) => {
        captureException(error)
      },
    },
  )

  return { institutions, getInstitution, isLoading: isLoading || isRefetching }
}
