import { SkillItem } from './SkillItem'
import { FieldHint, Label } from 'shared/components/atoms'
import { Skill } from 'profile/types'
import { emptySkill } from '.'
import classNames from 'classnames'
import { YearOfExperienceOptions, yearsOfExperienceOptions } from 'pages/JobSeekerProfile'
import { FormControl, useStacksList, useStacks } from 'shared/hooks'
import { useEffect, useMemo } from 'react'
import { Placeholder, Row } from 'shared/components/organisms'

const MAX_NUMBER_OF_SKILLS = 10

interface Props {
  formControl: FormControl
  className?: string
  name?: string
  showHint?: boolean
  showLabel?: boolean
  minRequiredSkills?: number
  allowCreation?: boolean
  yearsOptions?: YearOfExperienceOptions[]
}

export const MainSkillsSelect = ({
  formControl,
  className,
  name = 'stacks',
  showHint = true,
  showLabel = true,
  minRequiredSkills = 3,
  allowCreation = true,
  yearsOptions = yearsOfExperienceOptions,
}: Props) => {
  const { setValue, watch } = formControl

  const { createStack } = useStacks()
  const { stacks: allStacks, isLoading } = useStacksList()

  const stacks = watch(name, []) as Array<Skill>
  const stackOptions = useMemo(
    () => allStacks.filter(({ id }) => !stacks.find(({ stackId }) => id === stackId)),
    [stacks, allStacks],
  )

  const handleAddStack = () => {
    if (stacks.length === MAX_NUMBER_OF_SKILLS) return
    if (!stacks.find(({ stackId }) => stackId === 0)) {
      const newStacks = stacks.concat([{ stackId: 0, stackLabel: '', yearsOfExperience: 0 }])
      setValue(name, newStacks, { shouldValidate: true })
    }
  }

  const handleRemoveStack = (index: number) => {
    setValue(
      name,
      stacks.filter((_item, currentIndex) => index !== currentIndex),
      { shouldValidate: true },
    )
  }

  const handleCreateStack = (label: string) => createStack({ label })

  useEffect(() => {
    if (stacks.length) return

    setValue(name, Array(minRequiredSkills).fill({ ...emptySkill }))
  }, [minRequiredSkills])

  useEffect(() => {
    if (!stacks.length) return

    const anyEmptyRow = () => stacks.find(({ stackId }) => !stackId)
    if (!anyEmptyRow() && stacks.length < MAX_NUMBER_OF_SKILLS) {
      setValue(name, stacks.concat([{ ...emptySkill }]))
    }
  }, [])

  return (
    <div className={classNames(className)}>
      <div className="w-full">
        {showLabel && (
          <div className="flex gap-4 mb-2">
            <Label htmlFor="mainSkills" size="text-sm">
              What are your main skills?
            </Label>
            <FieldHint className="!mt-0">select at least 3 skills</FieldHint>
          </div>
        )}
        {stacks.map((stack, index) => {
          return isLoading ? (
            <Placeholder>
              <Row height="h-14" width="w-12/12" />
            </Placeholder>
          ) : (
            <SkillItem
              key={index}
              index={index}
              stack={stack}
              stacks={stacks}
              isRemoveVisible={stacks.length > minRequiredSkills}
              onRemoveStack={() => handleRemoveStack(index)}
              stackOptions={stackOptions}
              setValue={setValue}
              createOption={handleCreateStack}
              onAddSkill={handleAddStack}
              name={name}
              allowCreation={allowCreation}
              yearsOptions={yearsOptions}
            />
          )
        })}
      </div>
      {showHint && (
        <span className="font-normal text-sm text-neutral-dark">{`${
          stacks.filter((stack) => Boolean(stack.stackId)).length
        } of ${MAX_NUMBER_OF_SKILLS} main skills`}</span>
      )}
    </div>
  )
}
