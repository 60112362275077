import { Text } from 'shared/components/atoms'
import { Tooltip } from 'shared/components/atoms'
import { ReactComponent as UsersIcon } from 'icons/users-01.svg'
import { WorkExperience } from 'modules/work-experience'

export const ManagementInfo = ({ experience }: { experience?: WorkExperience }) => {
  if (!experience?.numberOfManagedPeople) return null

  const managingPercentage = experience.percentageOfWorkManagingPeople
  const workingPercentage = 100 - managingPercentage
  const label = `Time alocation: ${workingPercentage}% building, ${managingPercentage}% managing ${experience.numberOfManagedPeople} people`

  return (
    <div
      role="img"
      aria-label={label}
      className="flex flex-col justify-start items-start gap-2 sm:flex-row sm:items-center sm:gap-6 mt-3 hover:cursor-pointer"
    >
      <Tooltip
        className="!bg-neutral-day !rounded-lg !shadow-level-3"
        content={
          <div className="p-4 flex flex-col items-stretch gap-2">
            <Text size="text-xs" weight="font-medium" className="text-neutral-darkest">
              Work distribution
            </Text>
            <div className="flex flex-row items-center gap-2">
              <div className="w-4 h-1 bg-brand-medium rounded" />
              <Text size="text-xs" weight="font-normal" className="text-neutral-darkest">
                {workingPercentage}% building
              </Text>
            </div>
            <div className="flex flex-row items-center gap-2">
              <div className="w-4 h-1 bg-info-medium rounded" />
              <Text size="text-xs" weight="font-normal" className="text-neutral-darkest">
                {managingPercentage}% managing
              </Text>
            </div>
          </div>
        }
      >
        <div className="flex flex-row items-center justify-start gap-2 print:hidden">
          <Text size="text-xs" weight="font-normal" className="text-neutral-dark">
            Building
          </Text>
          <div className="flex flex-row justify-start items-center gap-[2px] overflow-hidden w-[100px]">
            <div
              className="h-1 rounded-l-full bg-brand-medium"
              style={{ flexGrow: workingPercentage / 100 }}
            />
            <div
              className="h-1 rounded-r-full bg-info-medium"
              style={{ flexGrow: managingPercentage / 100 }}
            />
          </div>
          <Text size="text-xs" weight="font-normal" className="text-neutral-dark">
            Managing
          </Text>
        </div>
        <div className="hidden print:flex flex-row gap-2">
          <Text size="text-xs" weight="font-normal" className="text-neutral-darkest">
            {workingPercentage}% Building
          </Text>
          <Text size="text-xs" weight="font-normal" className="text-neutral-darkest">
            {' '}
            -{' '}
          </Text>
          <Text size="text-xs" weight="font-normal" className="text-neutral-darkest">
            Managing {managingPercentage}%
          </Text>
        </div>
      </Tooltip>
      <Tooltip content="Number of managed people">
        <div className="flex flex-row items-center gap-2 rounded-[100px] bg-neutral-lightest px-2 py-1">
          <UsersIcon className="w-4 h-4 stroke-neutral-medium" />
          <Text size="text-xs" weight="font-normal" className="text-neutral-darkest">
            {experience.numberOfManagedPeople} people
          </Text>
        </div>
      </Tooltip>
    </div>
  )
}
