import { useLocalStorage } from 'shared/hooks/storage'

const STEPPER_DISMISSED_KEY = 'dismiss_task_stepper'

export const useDismissed = () => {
  const [isDismissed, setIsDismissed] = useLocalStorage(STEPPER_DISMISSED_KEY, false)

  const setDismissed = () => {
    setIsDismissed(true)
  }

  return { setDismissed, isDismissed }
}
