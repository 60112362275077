import { useEffect, useState } from 'react'

type Debounce<T> = {
  debouncedValue: T
  isDebouncing: boolean
}

type DebounceHook = <T>(value: T, delay: number) => Debounce<T>

export const useDebounce: DebounceHook = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const [isDebouncing, setDebouncing] = useState(false)

  useEffect(() => {
    setDebouncing(true)
    const handler = setTimeout(() => {
      setDebouncing(false)
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return { debouncedValue, isDebouncing }
}
