import { AxiosResponse } from 'axios'
import { useEffect, useMemo } from 'react'
import { number, object, string } from 'yup'
import { UseFormSetValue } from 'react-hook-form'

import { Select, useTag } from 'shared/components/atoms'
import { Autocomplete, Suggestion } from 'shared/components/atoms'

import { useForm } from 'shared/hooks'

import { ReactComponent as TrashIcon } from 'icons/trash.svg'

import { TertiaryButton } from 'shared/components/atoms'
import { Skill } from 'profile/types'
import { YearOfExperienceOptions, yearsOfExperienceOptions } from 'pages/JobSeekerProfile'

interface Props {
  index: number
  stack: Skill
  stacks: Array<Skill>
  stackOptions: Array<Suggestion>
  isRemoveVisible: boolean
  onRemoveStack(): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createOption: (label: string) => Promise<AxiosResponse<Suggestion, any>>
  onAddSkill: () => void
  yearsOptions: YearOfExperienceOptions[]
  name?: string
  allowCreation?: boolean
}

const schema = object().shape({
  stack: string(),
  yearsOfExperience: number(),
})

export const SkillItem = ({
  index,
  stack,
  stacks,
  stackOptions,
  isRemoveVisible,
  onRemoveStack,
  setValue,
  createOption,
  onAddSkill,
  yearsOptions = yearsOfExperienceOptions,
  name = 'stacks',
  allowCreation = true,
}: Props) => {
  const { register, watch, reset } = useForm({ schema, defaultValues: {} })
  const { format } = useTag()

  const yearsOfExperience = watch('yearsOfExperience') as number

  const setSkill = (data: Suggestion) => {
    stack.stackId = Number(data.id!)
    stack.stackLabel = data.label
    stacks.splice(index, 1, stack)
    setValue(name, stacks, { shouldValidate: true })
    onAddSkill()
  }

  const handleChangeSkill = async (stack: Suggestion) => {
    if (stack.id) return setSkill(stack)
    createOption(stack.label).then(({ data }) => setSkill(data))
  }

  const selectedSuggestions = useMemo(
    () => stacks.map(({ stackId, stackLabel }) => ({ id: stackId, label: stackLabel })),
    [stacks],
  )

  useEffect(() => {
    if (!yearsOfExperience) return
    stack.yearsOfExperience = yearsOfExperience
    stacks.splice(index, 1, stack)
    setValue(name, stacks)
    onAddSkill()
  }, [yearsOfExperience])

  useEffect(() => {
    reset({
      stack: stack.stackLabel,
      yearsOfExperience: stack.yearsOfExperience,
    })
  }, [stack, reset])

  return (
    <div
      className="
        grid grid-cols-12 sm:grid-cols-9 gap-4 items-center 
        bg-neutral-lightest rounded-xl p-2 mb-2
      "
    >
      <div className="col-span-10 sm:col-span-8 grid grid-cols-2 gap-2">
        <div className="col-span-2 sm:col-span-1">
          <Autocomplete
            register={register}
            watch={watch}
            name="stack"
            clearInputValue={false}
            showHint={false}
            suggestions={stackOptions}
            selectedSuggestions={selectedSuggestions}
            handleAdd={handleChangeSkill}
            placeholder="E.g React.js"
            maxLength={100}
            onInputChange={format}
            allowCreation={allowCreation}
          />
        </div>
        <Select
          name="yearsOfExperience"
          register={register}
          options={yearsOptions}
          showFieldError={false}
          className="col-span-2 sm:col-span-1"
        />
      </div>
      <div className="col-span-2 sm:col-span-1 flex justify-center">
        {Boolean(isRemoveVisible && !!stack.stackId) && (
          <TertiaryButton
            icon={<TrashIcon className="!w-6 !h-6 stroke-neutral-darkest" />}
            onClick={onRemoveStack}
          />
        )}
      </div>
    </div>
  )
}
