import { Expose, Type } from 'class-transformer'
import { Stack } from 'modules/stack'
import { WorkExperience } from 'modules/work-experience'

@Expose()
class Rate {
  min: number
  max: number
}

@Expose()
class Profile {
  id: string
  firstName: string
  lastName: string
  photoUrl: string

  @Type(() => WorkExperience)
  lastWorkExperience: WorkExperience

  @Type(() => Rate)
  rate: Rate

  get fullName() {
    return [this.firstName, this.lastName].filter((item) => item).join(' ')
  }
}

@Expose()
class Client {
  name: string
}

@Expose()
class AccountRoleDTO {
  email: string
}

@Expose()
export class MatchingSettings {
  id: string

  fee: number

  compensation: number

  briefing: string

  @Type(() => Stack)
  highlightedSkills: Array<Stack>

  updatedAt: string | null

  @Type(() => AccountRoleDTO)
  updatedBy: AccountRoleDTO | null

  @Type(() => Profile)
  profile: Profile

  @Type(() => Client)
  client: Client
}

export class MatchingSettingsFormSchema {
  compensation: number
  fee: number
  briefing: string
  highlightedSkills: Array<Stack>
}
