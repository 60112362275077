export const COUNTRY_FLAGS = {
  Brazil: '🇧🇷',
  Brasil: '🇧🇷',
  BR: '🇧🇷',
  Argentina: '🇦🇷',
  AR: '🇦🇷',
  Colombia: '🇨🇴',
  Colômbia: '🇨🇴',
  CO: '🇨🇴',
  Mexico: '🇲🇽',
  México: '🇲🇽',
  MX: '🇲🇽',
} as Record<string, string>
