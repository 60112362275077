import { IconBanner } from 'shared/components/molecules'
import { Label, ToggleFormField } from 'shared/components/atoms'
import { RichTextEditor } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'
import { ReactComponent as InfoIcon } from 'icons/info-circle.svg'

interface Props {
  formControl: FormControl
  firstName: string
}

export const RequestProfileImprovements = ({ formControl, firstName }: Props) => {
  const { setValue, watch, getError } = formControl

  const request = watch('requestProfileImprovements') as boolean

  return (
    <>
      <div className="flex flex-col gap-4">
        <ToggleFormField
          name="requestProfileImprovements"
          formControl={formControl}
          label="Request for profile content improvements"
          description="You can write instructions to this candidate to improve the content on their profile before showing it to a company."
        />

        {request && (
          <IconBanner
            icon={<InfoIcon className="w-6 h-6 shrink-0" />}
            bgColor="bg-neutral-lightest"
          >
            You don't need to remind candidates about their English Check, as it will be asked
            automatically if it's pending.
          </IconBanner>
        )}
      </div>
      {request && (
        <div>
          <Label htmlFor="improvements" className="flex flex-col gap-1">
            Write all the things you need {firstName} to improve
          </Label>
          <RichTextEditor
            name="improvements"
            setValue={setValue}
            error={getError('improvements')}
          />
        </div>
      )}
    </>
  )
}
