import { TrackingEvent } from '../tracking-event'

export class SurveyClosing implements TrackingEvent {
  name = 'Survey closing'

  constructor(private readonly initialProduct?: string) {}

  get payload() {
    return { initialProduct: this.initialProduct }
  }
}
