import * as RadixTabs from '@radix-ui/react-tabs'

import './Tabs.scss'

interface TabsProps {
  defaultValue: string
  children: React.ReactNode
  value?: string
  className?: string
  orientation?: 'horizontal' | 'vertical'
}

export const Tabs = ({
  defaultValue,
  value,
  children,
  className,
  orientation = 'horizontal',
}: TabsProps) => {
  return (
    <RadixTabs.Root
      className={className}
      defaultValue={defaultValue}
      orientation={orientation}
      value={value}
    >
      {children}
    </RadixTabs.Root>
  )
}
