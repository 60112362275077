export class UpdateOverview {
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly values: { [x: string]: any },
    private readonly isSeeker: boolean,
  ) {}

  private get interestRoles() {
    if (!this.values.openToRoles) return

    return this.values.openToRoles
      .filter(({ id }: { id: number }) => id !== null && id >= 0)
      .map(({ id }: { id: number }) => id)
  }

  private get otherPositionDescription() {
    if (this.isSeeker || !this.values.openToRoles) return

    return this.values.openToRoles
      .filter(({ id }: { id: number }) => id === null || id < 0)
      .map(({ label }: { label: string }) => label)
      .join(',')
  }

  private get profileStacks() {
    if (!this.values.stacks?.length) return

    return this.values.stacks
      .filter(({ stackId }: { stackId: number }) => stackId !== 0)
      .map(({ stackId, yearsOfExperience }: { stackId: number; yearsOfExperience: number }) => ({
        stackId: Number(stackId),
        experience: Number(yearsOfExperience),
      }))
  }

  toJSON() {
    return {
      bioText: this.values.bioText,
      firstName: this.values.firstName,
      lastName: this.values.lastName,
      location: this.values.location,
      gitRepositoriesUrl: this.values.gitRepositoriesUrl || null,
      personalSite: this.values.personalSite || null,
      interestRoles: this.interestRoles,
      otherPositionDescription: this.otherPositionDescription,
      whatsAppNumber: this.values.whatsAppNumber || null,
      skippedEnglishVetting: this.values.skippedEnglishVetting || null,
      invitedFriends: this.values.invitedFriends || null,
      profileStacks: this.profileStacks,
    }
  }
}
