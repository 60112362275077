import classNames from 'classnames'
import { HTMLAttributes, MouseEvent } from 'react'
import { Size, Weight } from '.'
import { cn } from 'modules/shared'

interface TextProps extends HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode
  size?: Size
  weight?: Weight
  className?: string
  onClick?: (event: MouseEvent<HTMLSpanElement>) => void
}

export const textStyles = (size: Size = 'text-sm', weight: Weight = 'font-normal') =>
  classNames('font-inter whitespace-pre-wrap', styles[size], weight)

const styles = {
  'text-7xl': 'text-7xl leading-[5.625rem] tracking-[-0.02em]',
  'text-6xl': 'text-6xl leading-[4.5rem] tracking-[-0.02em]',
  'text-5xl': 'text-5xl leading-[3.75rem] tracking-[-0.02em]',
  'text-4xl': 'text-4xl leading-[3.25rem] tracking-[-0.02em]',
  'text-3xl': 'text-[2rem] leading-10 tracking-[-0.02em]',
  'text-2xl': 'text-2xl leading-8 tracking-[-0.02em]',
  'text-xl': 'text-xl leading-[1.875rem]',
  'text-lg': 'text-lg leading-7',
  'text-base': 'text-base leading-6',
  'text-sm': 'text-sm leading-5',
  'text-xs': 'text-xs leading-[1.125rem]',
  'text-2xs': 'text-[0.625rem] leading-4',
}

export const Text = ({ children, size, weight, className, onClick, ...restProps }: TextProps) => {
  const classes = textStyles(size, weight)
  return (
    <span {...restProps} className={cn(classes, className)} onClick={onClick}>
      {children}
    </span>
  )
}
