import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { PrimaryButton } from 'shared/components/atoms'

import { ReactComponent as ShareIcon } from 'icons/share-03.svg'
import { lazy } from 'react'

const Illustration = lazy(() =>
  import('icons/ilustrations/finance-money-checkbook.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

export const WelcomeBanner = () => {
  const title = 'Welcome to Refer'
  const paragraphCopy =
    'Before we can pay you, we need to have some more information. Fill out our payment details form so we know where to send your future earnings!'
  return (
    <div className="bg-warning-lighter rounded-lg flex flex-row items-center justify-between gap-[92px] p-6 md:p-10">
      <div className="flex flex-col items-stretch justify-center gap-2">
        <Text size="text-xl" weight="font-medium" className="text-neutral-darkest block md:hidden">
          {title}
        </Text>
        <Text size="text-2xl" weight="font-medium" className="text-neutral-darkest hidden md:block">
          {title}
        </Text>
        <Paragraph
          size="body-sm"
          weight="font-normal"
          className="text-neutral-darkest block md:hidden"
        >
          {paragraphCopy}
        </Paragraph>
        <Paragraph
          size="body-md"
          weight="font-normal"
          className="text-neutral-darkest hidden md:block"
        >
          {paragraphCopy}
        </Paragraph>
        <PrimaryButton
          icon={<ShareIcon className="stroke-neutral-day" />}
          className="mt-4 md:w-fit"
          onClick={() => window.open('https://zycuy3zsvr7.typeform.com/to/RpIU88XH', '_target')}
        >
          Payment details form
        </PrimaryButton>
      </div>
      <Illustration className="hidden md:block flex-grow min-w-fit" />
    </div>
  )
}
