import * as Dialog from '@radix-ui/react-alert-dialog'
import classNames from 'classnames'

import { PrimaryButton } from 'shared/components/atoms'
import { TertiaryButton } from 'shared/components/atoms'
import { Title } from 'shared/components/atoms'

import { ReactComponent as CloseIcon } from 'icons/close.svg'
import { cn } from 'modules/shared'

interface AlertDialogProps {
  trigger?: React.ReactNode
  title?: string | JSX.Element
  description: JSX.Element
  cancelText?: string
  confirmText?: string
  onConfirmClick?: () => void
  onCancelClick?: () => void
  customActions?: React.ReactNode
  isOpen?: boolean
  isDismissible?: boolean
  isCancellable?: boolean
  form?: string
  imagePath?: string
  titleSize?: 'heading' | 'large-heading'
  autoFocusConfirmButton?: boolean
  isLoading?: boolean
  preventOpenChangeHandling?: boolean
  titleClassName?: string
  descriptionClassName?: string
}

export const AlertDialog = ({
  trigger,
  title,
  description,
  onConfirmClick,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  isOpen,
  onCancelClick,
  isDismissible,
  isCancellable = true,
  form,
  customActions,
  imagePath,
  titleSize = 'heading',
  autoFocusConfirmButton = false,
  isLoading = false,
  preventOpenChangeHandling = false,
  titleClassName,
  descriptionClassName,
}: AlertDialogProps) => {
  const handleOpenChange = (open: boolean) =>
    !open && !preventOpenChangeHandling && onCancelClick && onCancelClick()

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
      {Boolean(trigger) && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Portal>
        <div onClick={(event) => event.stopPropagation()}>
          <Dialog.Overlay className="fixed inset-0 bg-black/40 z-40" />
          <Dialog.Content
            className="
              flex flex-col          
              fixed bottom-0 lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 z-[60]
              w-full lg:max-w-[600px] max-h-full h-fit
              bg-neutral-day rounded-t-lg lg:rounded-lg
            "
          >
            {Boolean(imagePath) && (
              <img src={imagePath} className="rounded-t-lg w-full" alt="" role="presentation" />
            )}
            <Dialog.Title asChild>
              <div
                className={cn(
                  'flex items-center justify-between p-6 lg:pt-8 lg:px-10',
                  titleClassName,
                )}
              >
                {typeof title === 'string' ? <Title size={titleSize}>{title}</Title> : title}
                {isDismissible && (
                  <Dialog.Cancel asChild>
                    <div>
                      <TertiaryButton
                        size="md"
                        icon={<CloseIcon className="!w-6 !h-6" />}
                        onClick={onCancelClick}
                      />
                    </div>
                  </Dialog.Cancel>
                )}
              </div>
            </Dialog.Title>
            <Dialog.Description asChild>
              <div
                className={cn(
                  'flex-grow overflow-auto px-5 py-3 sm:px-10 sm:py-6',
                  descriptionClassName,
                )}
              >
                {description}
              </div>
            </Dialog.Description>
            <div
              className={classNames(
                'flex flex-col-reverse items-stretch justify-end gap-4',
                'lg:flex-row lg:items-center ',
                'py-4 lg:pb-8 lg:pt-0 px-6 lg:px-10',
                'border-t lg:border-0 border-neutral-lighter',
              )}
            >
              {Boolean(isCancellable) && (
                <Dialog.Cancel>
                  <TertiaryButton
                    size="md"
                    onClick={onCancelClick}
                    className="w-full"
                    disabled={isLoading}
                  >
                    {cancelText}
                  </TertiaryButton>
                </Dialog.Cancel>
              )}
              <Dialog.Action asChild>
                {customActions || (
                  <div>
                    <PrimaryButton
                      size="md"
                      onClick={onConfirmClick}
                      form={form}
                      className="w-full"
                      autoFocus={autoFocusConfirmButton}
                      isLoading={isLoading}
                    >
                      {confirmText}
                    </PrimaryButton>
                  </div>
                )}
              </Dialog.Action>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
