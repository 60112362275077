import { Text } from 'shared/components/atoms'

export const Header = () => {
  const columns = [
    {
      value: 'Candidate',
      width: 'min-w-[300px] md:w-[35%]',
    },
    {
      value: 'Role',
      width: 'min-w-[250px] md:w-1/4',
    },
    {
      value: 'Signed up',
      width: 'min-w-[150px] md:w-1/5',
    },
    {
      value: 'Status',
      width: 'min-w-[200px] md:w-1/5',
    },
  ]

  return (
    <thead>
      <tr>
        {columns.map(({ value, width }, index) => (
          <th key={`header-${index}`} className={width}>
            <Text size="text-xs" weight="font-medium" className="text-neutral-dark">
              {value}
            </Text>
          </th>
        ))}
      </tr>
    </thead>
  )
}
