export enum ContractLength {
  UpToThreeMonths = 'up_to_three_months',
  UpToSixMonths = 'up_to_six_months',
  UpToTwelveMonths = 'up_to_twelve_months',
}

export const contractLengthLabels = {
  [ContractLength.UpToThreeMonths]: '1 - 3 months',
  [ContractLength.UpToSixMonths]: '3 - 6 months',
  [ContractLength.UpToTwelveMonths]: '6 - 12 months',
}
