import classNames from 'classnames'

export const GradientSpinner = ({
  from,
  via,
  to,
  className = 'w-6 h-6',
  innerClassName = 'bg-white w-4 h-4',
}: {
  from: string
  via?: string
  to: string
  className?: string
  innerClassName?: string
}) => {
  return (
    <div
      className={classNames(
        `relative animate-spin rounded-full bg-gradient-to-r`,
        from,
        via,
        to,
        className,
      )}
    >
      <div
        className={classNames(
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full',
          innerClassName,
        )}
      />
    </div>
  )
}
