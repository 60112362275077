export enum ApplicationSourceOptions {
  AllSources = 'all-sources',
  NewCandidates = 'new-candidates',
}

export const applicationSourceLabels: Record<ApplicationSourceOptions, string> = {
  [ApplicationSourceOptions.AllSources]: 'All sources',
  [ApplicationSourceOptions.NewCandidates]: 'New candidates only',
}

export enum ApplicationSourceSubOptions {
  Assigned = 'assigned',
  Applied = 'applied',
}

export const applicationSourceSubLabels: Record<ApplicationSourceSubOptions, string> = {
  [ApplicationSourceSubOptions.Assigned]: 'Assigned candidates',
  [ApplicationSourceSubOptions.Applied]: 'Applied candidates',
}

export enum ScreeningOptions {
  AllCandidates = 'all-screening',
}

export const screeningLabels: Record<ScreeningOptions, string> = {
  [ScreeningOptions.AllCandidates]: 'All candidates on Screening',
}

export enum ScreeningSubOptions {
  Pending = 'pending',
  Declined = 'declined',
  Expired = 'expired',
}

export const screeningSubLabels: Record<ScreeningSubOptions, string> = {
  [ScreeningSubOptions.Pending]: 'Pending answer (Assigned, Viewed, Started)',
  [ScreeningSubOptions.Expired]: 'Expired answer limit',
  [ScreeningSubOptions.Declined]: 'Declined job opportunity',
}

export enum NotAFitOptions {
  AllCandidates = 'all-not-a-fit',
}

export const notAFitLabels: Record<NotAFitOptions, string> = {
  [NotAFitOptions.AllCandidates]: 'All candidates on Not a fit',
}

export enum NotAFitSubOptions {
  Rejected = 'rejected',
  Declined = 'declined',
}

export const notAFitSubLabels: Record<NotAFitSubOptions, string> = {
  [NotAFitSubOptions.Rejected]: 'Rejected on previous compatibility check',
  [NotAFitSubOptions.Declined]: 'Declined job opportunity',
}
