import { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { noScrollbarsClassName } from 'react-remove-scroll-bar'

import { Text, Paragraph } from 'shared/components/atoms'
import { UserDropdown } from '../UserDropdown'
import { Logo } from './components/Logo'

import { useAuth } from 'shared/hooks'
import { useNavBar } from './hooks'

import { LayoutContext } from 'contexts/layout'

import { ReactComponent as MobileMenuIcon } from 'icons/navbar/mobile-menu.svg'
import { ReactComponent as CloseIcon } from 'icons/close.svg'

export const NavBar = () => {
  const { user } = useAuth()
  const { shouldHideNavbar } = useContext(LayoutContext)
  const { navigationOptions } = useNavBar({
    iconClassName: 'w-6 h-6 stroke-inherit fill-inherit',
  })

  const [showMobileNavigation, setShowMobileNavigation] = useState(false)

  const handleMobileButtonClick = () => setShowMobileNavigation((previous) => !previous)
  const handleMobileLinkClick = () => setShowMobileNavigation(false)

  useEffect(() => {
    const container = document.getElementById('mobile-navigation-container')
    if (!container) return
    container.classList.toggle('-translate-y-full', !showMobileNavigation)
    container.classList.toggle('translate-y-0', showMobileNavigation)
  }, [showMobileNavigation])

  const DesktopNavigation = () => (
    <div className="hidden md:flex flex-row gap-4 justify-center items-center h-full w-full">
      {navigationOptions.map(({ to, isActive, label, isSeen, onClick }) => (
        <Link
          to={to}
          key={to}
          className={classNames(
            'w-fit h-full px-3',
            'flex justify-center items-center',
            'rounded hover:bg-neutral-darkest',
            {
              'bg-neutral-darkest': isActive,
            },
          )}
          onClick={() => onClick && onClick()}
        >
          <Text size="text-sm" weight="font-normal" className="text-neutral-day relative">
            {label}
            {isSeen === false && (
              <div className="absolute top-0 -right-2 rounded-full w-2 h-2 bg-gradient-to-br from-danger-light to-warning-medium" />
            )}
          </Text>
        </Link>
      ))}
    </div>
  )

  const MobileNavigation = useMemo(
    () =>
      function MobileNavigation() {
        return (
          <ul
            id="mobile-navigation-container"
            className={classNames(
              'flex flex-col gap-4 md:hidden',
              'fixed z-10 w-screen h-screen p-6 pt-[84px] bg-neutral-darker',
              '-translate-y-full transition-all',
            )}
          >
            {navigationOptions.map(
              ({ to, activeIcon, icon, label, isActive, hiddenOnMobile, isSeen, onClick }, index) =>
                !hiddenOnMobile && (
                  <li key={index}>
                    <Link
                      to={to}
                      className="flex flex-row gap-4 items-center px-6 py-2 hover:bg-neutral-darkest rounded-lg"
                      onClick={() => {
                        handleMobileLinkClick()
                        onClick && onClick()
                      }}
                    >
                      <span
                        className={classNames('w-6', {
                          'stroke-transparent fill-neutral-day': isActive,
                          'stroke-neutral-day fill-transparent': !isActive,
                        })}
                      >
                        {isActive ? activeIcon : icon}
                      </span>
                      <Paragraph
                        size="body-md"
                        weight="font-normal"
                        className="text-neutral-day relative"
                      >
                        {label}
                        {isSeen === false && (
                          <div className="absolute top-1 -right-2 rounded-full w-2 h-2 bg-gradient-to-br from-danger-light to-warning-medium" />
                        )}
                      </Paragraph>
                    </Link>
                  </li>
                ),
            )}
          </ul>
        )
      },
    [navigationOptions],
  )

  if (!user.isOnboarded || shouldHideNavbar) return null

  return (
    <div className="z-30 w-screen h-fit">
      <nav
        className={classNames(
          'fixed inset-0 z-20',
          'flex items-center justify-between',
          'h-fit md:h-16 md:py-3',
          'bg-neutral-darker',
          noScrollbarsClassName,
        )}
      >
        <button className="h-[60px] px-4 py-2 md:hidden z-10" onClick={handleMobileButtonClick}>
          {showMobileNavigation ? (
            <CloseIcon className="stroke-neutral-day w-6 h-6" />
          ) : (
            <MobileMenuIcon />
          )}
        </button>

        <Logo />

        <DesktopNavigation />

        <UserDropdown />
      </nav>
      <MobileNavigation />
    </div>
  )
}
