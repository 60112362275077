import { useMutation } from 'react-query'
import { useNetwork, useToast, useMonitoring } from 'shared/hooks'

export const useRequestEmailChange = (accountId: string) => {
  const { post } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: requestEmailChange, isLoading } = useMutation(
    (payload: { newEmailAddress: string; additionalComments: string }) => {
      return post(`accounts/${accountId}/email-change-requests`, payload)
    },
    {
      onError: (error) => {
        toastError('Failed to request the email change')
        captureException(error)
      },
    },
  )

  return { requestEmailChange, isLoading }
}
