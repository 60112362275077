import classNames from 'classnames'
import { useMemo } from 'react'

export const ProgressDots = ({
  length,
  activeStep,
  className,
}: {
  length: number
  activeStep: number
  className: string
}) => {
  const dots = useMemo(() => {
    const items = []
    for (let index = 1; index <= length; index++) {
      items.push({ active: index === activeStep, index })
    }

    return items
  }, [length, activeStep])

  return (
    <ul
      className={classNames('flex gap-2 w-full h-10 items-center justify-center mt-4', className)}
    >
      {dots.map(({ active, index }) => (
        <li
          key={index}
          className={classNames('h-2 w-2 rounded-full', {
            'bg-neutral-light': !active,
            'bg-neutral-darker': active,
          })}
        />
      ))}
    </ul>
  )
}
