import { JobOpportunity } from 'pages/JobSeekerProfile/types'
import { Text } from 'shared/components/atoms'
import { useMemo } from 'react'

export const RepliedScreeningTitle = ({ jobOpportunity }: { jobOpportunity?: JobOpportunity }) => {
  const resultLabel = useMemo(() => {
    if (jobOpportunity?.declinedAt) {
      return { label: 'Not Interested', className: 'text-danger-dark' }
    }

    if (jobOpportunity?.approvedAt) {
      return { label: 'Interested', className: 'text-success-dark' }
    }

    if (jobOpportunity?.rejectedAt) {
      return { label: 'Not a fit', className: 'text-neutral-medium' }
    }

    if (jobOpportunity?.submittedAt) {
      return { label: 'Submitted', className: 'text-neutral-medium' }
    }
  }, [jobOpportunity])

  return (
    <div className="flex flex-row gap-1">
      <Text size="text-base" weight="font-medium">
        Replied Screening
        {Boolean(resultLabel) && (
          <>
            {' '}
            -{' '}
            <Text size="text-base" weight="font-medium" className={resultLabel!.className}>
              {resultLabel!.label}
            </Text>
          </>
        )}
      </Text>
    </div>
  )
}
