import { useMutation, useQueryClient } from 'react-query'

import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

import { Teammate } from 'company/domain'

export const useTeammates = (errorCallback: () => void) => {
  const client = useQueryClient()
  const api = useNetwork()
  const { toastSuccess, toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: removeTeammate } = useMutation(
    (teammate: Teammate) => {
      const endpoint = teammate.accepted ? 'account/company' : 'company/invites'
      return api.delete(`${endpoint}/${teammate.email}`)
    },
    {
      onSuccess: () => {
        client.invalidateQueries('company-info')
        toastSuccess('Team member removed.')
      },
      onError: (error: RequestError) => {
        toastError('An error occurred while trying to remove the team member.')
        captureException(error)
      },
    },
  )

  const { mutateAsync: updateTeammateRole } = useMutation(
    (teammate: Partial<Teammate>) => {
      return api.patch(`account/company/${teammate.email}`, { role: teammate.role })
    },
    {
      onSuccess: () => client.invalidateQueries('company-info'),
      onError: (error: RequestError) => {
        toastError('An error occurred while trying to update the team member role.')
        captureException(error)
        errorCallback()
      },
    },
  )

  return { removeTeammate, updateTeammateRole }
}
