import { useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  applicationSourceLabels,
  ApplicationSourceOptions,
  applicationSourceSubLabels,
  ApplicationSourceSubOptions,
  notAFitLabels,
  NotAFitOptions,
  notAFitSubLabels,
  NotAFitSubOptions,
  screeningLabels,
  ScreeningOptions,
  screeningSubLabels,
  ScreeningSubOptions,
} from 'screening/enums'
import { BreakdownSelectionOption } from 'shared/components/molecules/BreakdownSelection/types'
import { useNetwork } from 'shared/hooks'

type ScreeningAssignmentReportType = {
  total: number
  screening: {
    total: number
    pending: number
    expired: number
    declined: number
  }
  notAFit: {
    total: number
    declined: number
    rejected: number
  }
}

export type ScreeningAssignmentReport = ScreeningAssignmentReportType & {
  applied: ScreeningAssignmentReportType
  assigned: ScreeningAssignmentReportType
}

const nullReportType = {
  total: 0,
  screening: {
    total: 0,
    pending: 0,
    expired: 0,
    declined: 0,
  },
  notAFit: {
    total: 0,
    declined: 0,
    rejected: 0,
  },
}

const nullReport = {
  ...nullReportType,
  applied: nullReportType,
  assigned: nullReportType,
}

export const useScreeningSharingReport = (
  listingId: string,
  selectedSources?: Array<ApplicationSourceSubOptions>,
) => {
  const { get } = useNetwork()

  const {
    data: report = nullReport,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['matchings-stats', listingId],
    () =>
      get<ScreeningAssignmentReport>(
        `listings/${listingId}/matchings/reports/new-screening-assignment/counts`,
      ).then(({ data }) => data),
    {
      staleTime: 5 * 60 * 1000,
    },
  )

  const selectedSourceReport = useMemo(() => {
    if (!selectedSources?.length || selectedSources.length > 1) return report

    const appliedOnly = selectedSources?.includes(ApplicationSourceSubOptions.Applied)
    const assignedOnly = selectedSources?.includes(ApplicationSourceSubOptions.Assigned)

    let data: ScreeningAssignmentReportType = report

    if (appliedOnly) data = report.applied
    if (assignedOnly) data = report.assigned

    return data
  }, [selectedSources, report])

  const sourceOptions = useMemo(() => {
    return [
      {
        name: ApplicationSourceOptions.NewCandidates,
        label: applicationSourceLabels[ApplicationSourceOptions.NewCandidates],
      },
      {
        name: ApplicationSourceOptions.AllSources,
        label: applicationSourceLabels[ApplicationSourceOptions.AllSources],
        subOptions: [
          {
            parent: ApplicationSourceOptions.AllSources,
            name: ApplicationSourceSubOptions.Assigned,
            label: applicationSourceSubLabels[ApplicationSourceSubOptions.Assigned],
            hint: String(report.assigned.total),
          },
          {
            parent: ApplicationSourceOptions.AllSources,
            name: ApplicationSourceSubOptions.Applied,
            label: applicationSourceSubLabels[ApplicationSourceSubOptions.Applied],
            hint: String(report.applied.total),
          },
        ],
      },
    ] as Array<BreakdownSelectionOption>
  }, [report])

  const { screeningOptions, notAFitOptions } = useMemo(() => {
    return {
      screeningOptions: [
        {
          name: ScreeningOptions.AllCandidates,
          label: screeningLabels[ScreeningOptions.AllCandidates],
          hint: selectedSourceReport.screening.total,
          subOptions: [
            {
              parent: ScreeningOptions.AllCandidates,
              name: ScreeningSubOptions.Pending,
              label: screeningSubLabels[ScreeningSubOptions.Pending],
              hint: selectedSourceReport.screening.pending,
            },
            {
              parent: ScreeningOptions.AllCandidates,
              name: ScreeningSubOptions.Expired,
              label: screeningSubLabels[ScreeningSubOptions.Expired],
              hint: selectedSourceReport.screening.expired,
            },
            {
              parent: ScreeningOptions.AllCandidates,
              name: ScreeningSubOptions.Declined,
              label: screeningSubLabels[ScreeningSubOptions.Declined],
              hint: selectedSourceReport.screening.declined,
            },
          ],
        },
      ] as Array<BreakdownSelectionOption>,

      notAFitOptions: [
        {
          name: NotAFitOptions.AllCandidates,
          label: notAFitLabels[NotAFitOptions.AllCandidates],
          hint: selectedSourceReport.notAFit.total,
          subOptions: [
            {
              parent: NotAFitOptions.AllCandidates,
              name: NotAFitSubOptions.Rejected,
              label: notAFitSubLabels[NotAFitSubOptions.Rejected],
              hint: selectedSourceReport.notAFit.rejected,
            },
            {
              parent: NotAFitOptions.AllCandidates,
              name: NotAFitSubOptions.Declined,
              label: notAFitSubLabels[NotAFitSubOptions.Declined],
              hint: selectedSourceReport.notAFit.declined,
            },
          ],
        },
      ] as Array<BreakdownSelectionOption>,
    }
  }, [selectedSourceReport])

  return {
    report,
    sourceOptions,
    screeningOptions,
    notAFitOptions,
    selectedSourceReport,
    isLoading: isLoading || isFetching,
    refetch,
  }
}
