import { Suspense, lazy } from 'react'
import classNames from 'classnames'

import { ButtonType, PrimaryButton } from 'shared/components/atoms'
import { CollapsedMenu } from 'shared/components/molecules'

import { OfferSentBadge } from './OfferSentBadge'

import { ReactComponent as DotsIcon } from 'icons/dots.svg'

import { useProfileEmployerActions } from './useProfileEmployerActions'

import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { MakeOfferModal } from '../MakeOfferModal'
import { AccountClaim, useAuth } from 'shared/hooks'

const ScreeningSendingModal = lazy(() =>
  import('./components/ScreeningSendingModal/ScreeningSendingModal').then((module) => ({
    default: module.ScreeningSendingModal,
  })),
)

const ProfileSettings = lazy(() =>
  import('components/organisms/Profile/Settings/ProfileSettings').then((module) => ({
    default: module.ProfileSettings,
  })),
)

interface EmployerActionsProps {
  profile: ProfileOverview
  onExport: (fileName: string) => void
  isStandAloneProfile: boolean
  className?: string
  isVisible?: boolean
  withExtendedOptions?: boolean
  contextualMenuTriggerSize?: 'md' | 'lg'
}

export const EmployerActions = ({
  profile,
  onExport,
  isStandAloneProfile,
  className,
  isVisible = true,
  withExtendedOptions = false,
  contextualMenuTriggerSize = 'md',
}: EmployerActionsProps) => {
  const {
    user: { claims },
  } = useAuth()

  const {
    handleRequestInterview,
    interviewRequested,
    isRequestingInterview,
    isOfferFormOpen,
    closeOfferModal,
    menuItems,
    handleCloseMatchingCreation,
    isSendScreeningModalOpen,
    handleCloseProfileSettings,
    isProfileSettingsModalOpen,
  } = useProfileEmployerActions(profile, onExport, withExtendedOptions, isStandAloneProfile)

  const showSendScreeningModal = Boolean(
    isSendScreeningModalOpen && claims.includes(AccountClaim.CreateMatchings),
  )

  if (!isVisible) {
    return null
  }

  return (
    <div
      className={classNames(
        'flex gap-4 print:hidden',
        isStandAloneProfile ? 'justify-start w-full' : 'justify-end',
        className,
      )}
    >
      {Boolean(isStandAloneProfile && profile.offerSent) && <OfferSentBadge />}
      {isStandAloneProfile && !profile.offerSent && (
        <div className="flex w-full">
          <PrimaryButton
            disabled={interviewRequested}
            onClick={handleRequestInterview}
            isLoading={isRequestingInterview}
            className="grow"
          >
            {interviewRequested ? 'Interview requested' : 'Request interview'}
          </PrimaryButton>
        </div>
      )}
      <CollapsedMenu
        buttonType={isStandAloneProfile ? ButtonType.Secondary : ButtonType.Tertiary}
        triggerElement={<DotsIcon className="w-6 h-6" />}
        items={menuItems}
        triggerElementSize={contextualMenuTriggerSize}
      />
      <MakeOfferModal profile={profile} isOpen={isOfferFormOpen} onClose={closeOfferModal} />
      {showSendScreeningModal && (
        <Suspense fallback={null}>
          <ScreeningSendingModal
            isOpen={isSendScreeningModalOpen}
            onClose={handleCloseMatchingCreation}
          />
        </Suspense>
      )}
      {isProfileSettingsModalOpen && (
        <Suspense fallback={null}>
          <ProfileSettings profileId={profile.id} onClose={handleCloseProfileSettings} />
        </Suspense>
      )}
    </div>
  )
}
