import classNames from 'classnames'

export const Loading = ({
  size,
  bgColor,
}: {
  size: 'sm' | 'md' | 'lg' | 'xl'
  bgColor: 'bg-neutral-dark' | 'bg-neutral-lightest' | 'bg-warning-lighter' | 'bg-neutral-day'
}) => {
  return (
    <div
      className={classNames(
        {
          'h-4 w-4': size === 'sm' || size === 'md',
          'h-6 w-6': size === 'lg' || size === 'xl',
        },
        'absolute animate-spin rounded-full bg-gradient-to-r from-neutral-dark to-neutral-day ',
      )}
    >
      <div
        className={classNames(
          {
            'h-3 w-3': size === 'sm' || size === 'md',
            'h-4 w-4': size === 'lg' || size === 'xl',
          },
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full',
          bgColor,
        )}
      ></div>
    </div>
  )
}
