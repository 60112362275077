import { plainToInstance } from 'class-transformer'
import { JobDescription } from 'screening/domain/job-description.domain'

export const emptyJobDescription = plainToInstance(JobDescription, {
  title: '',
  engagementType: null,
  contractDuration: undefined,
  workingHoursPerWeek: 5,
  minimumTotalExperience: 0,
  minimumEnglishLevel: undefined,
  requiredSkills: [],
  detailedRequirements: '',
  projectDescription: '',
  hiringThroughUs: null,
  benefits:
    "<p>By completing your profile and recording your introduction videos, you'll gain access to benefits in health care, international transfers, language learning, and more (https://help.onstrider.com/en/collections/3690246-benefits).</p><p>If selected for an interview, you'll receive personalized training and have a dedicated team to support you throughout the hiring process.</p><p>You'll have our HR support in your preferred language to alleviate concerns about payroll, time off, and any additional information you may need.</p><p>As a US-based company, Strider plays a crucial role in contract mediation, ensuring trust and reliability.</p><p>We'll provide work equipment and check in monthly to ensure you have a successful experience in your new job.</p>",
  processOverview:
    "<p><em>We'll share information about the interview process soon. This is what Strider's standard process looks like:</em></p>\n<ol>\n<li>Intro Call [30 minutes]</li>\n<li>Strider Technical Assessment (https://help.onstrider.com/en/collections/3787982-vetting)</li>\n<li>Final Interview [up to 2 hours]</li>\n<li>Offer</li>\n</ol>",
})
