import classNames from 'classnames'
import { Text } from 'components/Text'
import { lazy } from 'react'
import { Title } from 'shared/components/atoms'

const BeProductive = lazy(() =>
  import('icons/ilustrations/be-productive.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

export const MobileWarning = ({
  className,
  title,
  text,
}: {
  className: string
  title: string
  text: string
}) => {
  return (
    <div
      className={classNames(
        'flex-grow flex flex-col items-center justify-center gap-4 mb-auto',
        className,
      )}
    >
      <BeProductive />
      <Title size="heading">{title}</Title>
      <Text size="body" className="text-center">
        {text}{' '}
      </Text>
    </div>
  )
}
