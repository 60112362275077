import { ContentWrapper } from 'components/ContentWrapper'
import { Label } from 'shared/components/atoms'
import { RadioGroup } from 'shared/components/atoms'
import { UseFormWatch } from 'react-hook-form'

interface NoticePeriodProps {
  register: (key: string) => Array<string>
  watch: UseFormWatch<Record<string, string | number>>
}

export const NoticePeriod = ({ register, watch }: NoticePeriodProps) => {
  const options = [
    { value: 0, label: 'Immediately' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6+' },
  ]

  return (
    <ContentWrapper isForm className="!mb-3">
      <Label htmlFor="noticePeriod" size="text-sm" className="!mb-4">
        How many weeks do you need before starting your new job?
      </Label>
      <RadioGroup
        register={register}
        watch={watch}
        name="noticePeriod"
        options={options}
        controlClassName="flex-grow"
      />
    </ContentWrapper>
  )
}
