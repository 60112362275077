import { useEffect, useMemo, useRef } from 'react'
import classNames from 'classnames'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { FieldError, Input, Label, Radio, Text } from 'shared/components/atoms'

import { ReactComponent as CheckIcon } from 'icons/check-circle.svg'
import { ContactOptions } from 'modules/hires/enums'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'

interface SignerContactProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  profile: ProfileOverview
  getError: (key: string) => string
  companyName: string
}

export const SignerContact = ({
  register,
  watch,
  setValue,
  profile,
  getError,
  companyName,
}: SignerContactProps) => {
  const fullNameRef = useRef<HTMLInputElement>(null)

  const contact = watch('contact')
  const showContactFields = contact === ContactOptions.SomeoneElse

  const options = useMemo(
    () => [
      {
        value: ContactOptions.Me,
        title: 'I will sign the agreement',
        description: `You are authorized to sign the retention agreement on behalf of ${companyName}, and the agreement will be emailed to you for signature when ${profile.firstName} accepts.`,
      },
      {
        value: ContactOptions.SomeoneElse,
        title: 'Someone else will sign the agreement',
        description: `The agreement will be emailed for signature to who you specify when ${profile.firstName} accepts.`,
      },
    ],
    [profile.firstName, companyName],
  )

  useEffect(() => {
    if (showContactFields) {
      return fullNameRef.current?.focus()
    }

    setValue('contactFullName', '', { shouldValidate: true })
    setValue('contactEmail', '', { shouldValidate: true })
  }, [showContactFields])

  return (
    <div className="flex flex-col gap-3">
      {options.map((option, index) => (
        <Label
          className="flex gap-4 py-4 px-6 rounded-lg border border-neutral-light cursor-pointer items-center"
          htmlFor={option.value}
          key={index}
        >
          <div className="flex flex-col gap-1 py-4 ">
            <Text size="text-base" weight="font-medium">
              {option.title}
            </Text>
            <Text size="text-sm" weight="font-normal">
              {option.description}
            </Text>
            <Radio
              name="contact"
              id={option.value}
              value={option.value}
              register={register}
              className="hidden"
            />
          </div>
          <div>
            <CheckIcon
              className={classNames('w-6 h-6 min-w-[24px] fill-neutral-darker', {
                invisible: contact !== option.value,
              })}
            />
          </div>
        </Label>
      ))}
      <FieldError message={getError('contact')} />
      {showContactFields && (
        <div className="flex flex-col gap-1 p-6 bg-neutral-lightest rounded-lg">
          <div>
            <Label htmlFor="contactFullName" size="text-sm">
              Full name
            </Label>
            <Input
              register={register}
              name="contactFullName"
              id="contactFullName"
              ref={fullNameRef}
            />
          </div>
          <div>
            <Label htmlFor="contactEmail" size="text-sm">
              Email
            </Label>
            <Input register={register} name="contactEmail" id="contactEmail" />
          </div>
        </div>
      )}
    </div>
  )
}
