import { QuestionSchema } from '../domain'
import { QuestionType } from '../enums'

export class UpdateQuestionsDTO {
  constructor(private readonly questions: Array<QuestionSchema>) {}

  toJSON() {
    return this.questions.map(
      ({ type, title, description, expectedAnswer, required, workExperienceRequired }) => ({
        type,
        title,
        description: description,
        expectedAnswer: [QuestionType.Boolean, QuestionType.Numeric].includes(type)
          ? expectedAnswer
          : undefined,
        required,
        workExperienceRequired,
      }),
    )
  }
}
