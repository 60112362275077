import { useAuth } from 'shared/hooks'
import { Outlet, useNavigate } from 'react-router-dom'

export const EmployerRoutes = () => {
  const navigate = useNavigate()
  const {
    user: { accountId, isEmployer },
  } = useAuth()

  if (isEmployer) return <Outlet />
  if (accountId) navigate('/')

  return null
}
