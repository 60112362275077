import { WorkExperience } from 'modules/work-experience'

export const useExpirenceLocation = () => {
  const getLocationLabel = (experience: WorkExperience) => {
    if (
      experience.location &&
      experience.location.toLowerCase().indexOf('remote') < 0 &&
      experience.isRemote
    ) {
      return `${experience.location} (Remote)`
    }
    return experience.location
  }
  return { getLocationLabel }
}
