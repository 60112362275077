import { ReferralStatus } from '../enum'

export class ReferredProfile {
  id: string
  fullName: string
  photoUrl: string | null
  interestRoles: Array<string>
}

export class Referral {
  id: string
  status: ReferralStatus
  profileEmail: string
  createdAt: string
  profile: ReferredProfile
  statusDescription: string
}

export class ReferralStatsReport {
  counts: Array<{ status: ReferralStatus; count: number }>
  total: number
}
