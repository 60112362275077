import { Label, Slider } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'
import { experienceOptions } from 'modules/candidates/consts'

interface Props {
  formControl: FormControl
  name: string
  isRangedSlider?: boolean
  error?: string
}

export const TotalExperience = ({ formControl, name, isRangedSlider, error }: Props) => {
  const { setValue, watch } = formControl

  return (
    <div aria-labelledby="total-experience">
      <Label id="total-experience" htmlFor={name} size="text-sm" className="!mb-6">
        Total experience
      </Label>
      <Slider
        name={name}
        options={experienceOptions}
        setFormValue={setValue}
        isRangeSlider={Boolean(isRangedSlider)}
        watch={watch}
        error={error}
        showFieldError
        updatePositionOnWatch
      />
    </div>
  )
}
