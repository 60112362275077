import { useEffect } from 'react'
import { setLocale } from 'yup'
import classNames from 'classnames'

import { Checkbox, Input, Label, Select } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { ContentWrapper } from 'components/ContentWrapper'
import { Text } from 'components/Text'
import { useForm } from 'shared/hooks'
import { useCertification } from 'pages/JobSeekerProfile/hooks'
import { CertificationFormValuesFactory } from 'modules/profiles/factories/certification.factory'
import { CertificationFormValues } from 'modules/profiles/domain'
import { ProfileCertification, Stack } from 'pages/JobSeekerProfile/types'
import { SelectInstitution, SelectStacks } from 'profile/components'

import { Footer } from '../components/Footer'
import { schema, emptyState, monthsOptions, yearsOptions } from '.'

interface CertificationProps {
  profileId: string
  certification?: ProfileCertification
  isEdit?: boolean
  handleClose(): void
}

setLocale({
  mixed: {
    notType: 'Required field.',
    required: 'Required field.',
  },
})

const MAX_SKILLS = 10

export const Certification = ({
  profileId,
  certification,
  isEdit,
  handleClose,
}: CertificationProps) => {
  const { upsertCertification, deleteCertification, isDeleting, isUpserting } =
    useCertification(profileId)

  const formControl = useForm({
    schema,
    defaultValues: emptyState,
  })

  const { register, handleSubmit, setValue, getValues, watch, reset, isDirty } = formControl

  const doesNotExpire = watch('doesNotExpire') as boolean
  const isTechnical = watch('isTechnical') as boolean
  const stacks = watch('stacks') as Array<Stack>

  const onSubmit = (addAnother: boolean) => {
    const submit = handleSubmit(() => {
      const values = getValues() as CertificationFormValues
      upsertCertification(values).then(() => {
        if (addAnother) return reset(emptyState)
        handleClose()
      })
    })

    submit()
  }

  const handleDelete = () => {
    deleteCertification(certification!.id).then(() => handleClose())
  }

  useEffect(() => {
    !isTechnical && setValue('stacks', [])
  }, [isTechnical])

  useEffect(() => {
    doesNotExpire && setValue('expireDateMonth', -1)
    doesNotExpire && setValue('expireDateYear', -1)
  }, [doesNotExpire])

  useEffect(() => {
    if (isEdit && certification) return reset(CertificationFormValuesFactory.build(certification))
    reset(emptyState)
  }, [])

  return (
    <Modal
      handleClose={handleClose}
      showDismissalConfirmation={isDirty}
      title={isEdit ? 'Edit certification' : 'Add certification'}
      content={
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit(false)
          }}
          id="certificationForm"
        >
          <ContentWrapper className="mt-4">
            <Label htmlFor="name">Name</Label>
            <Input
              register={register}
              name="name"
              placeholder="Tell us the certification or course name"
            />
            <Label htmlFor="isTechnical" className="mb-0" weight="font-normal">
              <Checkbox register={register} name="isTechnical" id="isTechnical" /> This is a
              technical certification
            </Label>
          </ContentWrapper>
          {isTechnical && (
            <ContentWrapper isForm>
              <SelectStacks
                formControl={formControl}
                name="stacks"
                label="Certified skills"
                selectedOptions={stacks}
                allowCreation
              />
              <Text
                size="small-body"
                className={classNames('text-neutral-dark', {
                  'mt-3': Boolean(stacks.length),
                })}
              >
                {stacks.length} of {MAX_SKILLS}
              </Text>
            </ContentWrapper>
          )}
          <ContentWrapper isForm>
            <SelectInstitution
              profileId={profileId}
              label="Issuing Organization"
              formControl={formControl}
            />
          </ContentWrapper>
          <ContentWrapper isForm className="flex flex-row gap-6 items-end">
            <div className="flex-grow sm:max-w-[calc(50%-12px)]">
              <Label htmlFor="issueDateMonth">Issue date</Label>
              <Select
                id="issueDateMonth"
                register={register}
                name="issueDateMonth"
                options={monthsOptions}
                placeholder="Month"
              />
            </div>
            <Select
              aria-label="Issue date's year"
              register={register}
              name="issueDateYear"
              options={yearsOptions.filter(
                (year) => Number(year.label) <= new Date().getFullYear(),
              )}
              className="flex-grow sm:max-w-[calc(50%-12px)]"
              placeholder="Year"
            />
          </ContentWrapper>
          <ContentWrapper>
            {!doesNotExpire && (
              <div className="flex flex-row gap-6 items-end flex-wrap">
                <div className="flex-grow sm:max-w-[calc(50%-12px)]">
                  <Label htmlFor="expireDateMonth">Expire date</Label>
                  <Select
                    id="expireDateMonth"
                    register={register}
                    name="expireDateMonth"
                    options={monthsOptions}
                    placeholder="Month"
                  />
                </div>
                <Select
                  aria-label="Expire date's year"
                  register={register}
                  name="expireDateYear"
                  options={yearsOptions}
                  className="flex-grow sm:max-w-[calc(50%-12px)]"
                  placeholder="Year"
                />
              </div>
            )}
            <Label htmlFor="doesNotExpire" className="mb-0" weight="font-normal">
              <Checkbox register={register} name="doesNotExpire" id="doesNotExpire" /> This
              credential does not expire
            </Label>
          </ContentWrapper>
          <ContentWrapper isForm>
            <Label htmlFor="credentialId" isOptional>
              Credential ID
            </Label>
            <Input register={register} name="credentialId" />
          </ContentWrapper>
          <ContentWrapper isForm>
            <Label htmlFor="credentialUrl" isOptional>
              Credential URL
            </Label>
            <Input register={register} name="credentialUrl" />
          </ContentWrapper>
        </form>
      }
      footer={
        <Footer
          isEdit={isEdit}
          onSubmit={onSubmit}
          handleClose={handleClose}
          handleDelete={handleDelete}
          form="certificationForm"
          deleteButtonLabel="Delete certification"
          isLoading={isUpserting}
          isDeleting={isDeleting}
        />
      }
    />
  )
}
