import { ReactComponent as InfoIcon } from 'icons/info-circle.svg'
import { cn } from 'modules/shared'

interface BannerProps {
  children: React.ReactNode
  icon?: Nullable<JSX.Element>
  bgColor?: 'bg-info-lighter' | 'bg-warning-lighter'
}

export const Banner = ({
  children,
  icon = <InfoIcon className="w-[20px] h-[20px] stroke-info-medium" />,
  bgColor = 'bg-info-lighter',
}: BannerProps) => {
  return (
    <div className={cn('flex gap-2 p-4 rounded-lg text-sm', bgColor)}>
      <span>{icon}</span>
      {children}
    </div>
  )
}
