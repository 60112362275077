import { lazy, Suspense } from 'react'

import { cn } from 'modules/shared'
import { AppealingTitle, Text } from 'shared/components/atoms'

const Softbank = lazy(() =>
  import('icons/signup/companies/softbank.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const Combinator = lazy(() =>
  import('icons/signup/companies/y-combinator.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const Bloomberg = lazy(() =>
  import('icons/signup/companies/bloomberg.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const Beta = lazy(() =>
  import('icons/signup/companies/beta.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const Apax = lazy(() =>
  import('icons/signup/companies/apax.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const Pareto = lazy(() =>
  import('icons/signup/companies/pareto.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const Redpoint = lazy(() =>
  import('icons/signup/companies/redpoint.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const RedpointWhite = lazy(() =>
  import('icons/signup/companies/redpoint-white.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)
const Nea = lazy(() =>
  import('icons/signup/companies/nea.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

export type Mode = 'lightMode' | 'darkMode'

interface Props {
  children: React.ReactNode
  mode: Mode
  title: string
  hideCompanies?: boolean
  titleClassName?: string
  containerClassName?: string
}

export const SidePanelContainer = ({
  children,
  mode,
  title,
  hideCompanies = false,
  containerClassName,
  titleClassName,
}: Props) => {
  const iconStyles = mode === 'darkMode' ? 'fill-neutral-50' : 'fill-neutral-600'

  const companies: Array<React.ReactNode> = [
    <Softbank key="softbank" className={iconStyles} />,
    <Combinator key="combinator" className={iconStyles} />,
    <div key="bloombergbeta" className="flex gap-1 items-center">
      <Bloomberg className={iconStyles} />
      <Beta className={iconStyles} />
    </div>,
    <Apax key="apax" className={iconStyles} />,
    <Pareto key="pareto" className={iconStyles} />,
    mode === 'darkMode' ? <RedpointWhite key="redpoint-white" /> : <Redpoint key="redpoint" />,
    <Nea key="nea" className={iconStyles} />,
  ]

  const styles: Record<Mode, { container: string; title: string; footerTitle: string }> = {
    darkMode: {
      container: 'bg-neutral-day bg-opacity-5',
      title: 'text-neutral-300',
      footerTitle: 'text-neutral-100',
    },
    lightMode: {
      container: 'bg-neutral-day bg-opacity-32',
      title: 'text-neutral-600',
      footerTitle: 'text-neutral-700',
    },
  }

  return (
    <div
      className={cn(
        'flex flex-col w-full rounded-2xl gap-y-8 py-16 items-center justify-between z-10',
        styles[mode].container,
        containerClassName,
      )}
    >
      <div>
        <AppealingTitle
          className={cn('text-center max-w-[560px]', styles[mode].title, titleClassName)}
        >
          {title}
        </AppealingTitle>
      </div>
      <div className="flex flex-col gap-y-4 h-full w-full">{children}</div>
      {!hideCompanies && (
        <div className="flex flex-col items-center justify-center gap-8">
          <Text className={cn('text-center', styles[mode].footerTitle)}>
            Leading companies backed by top investors hire on Strider
          </Text>
          <div className="flex gap-8 flex-wrap items-center justify-center max-w-[560px]">
            <Suspense fallback={null}>{companies}</Suspense>
          </div>
        </div>
      )}
    </div>
  )
}
