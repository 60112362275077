import { Collapse } from 'shared/components/molecules'
import { Text } from 'shared/components/atoms'
import { useState } from 'react'
import { ReactComponent as ChevronDownIcon } from 'icons/chevron-down.svg'
import { cn } from 'modules/shared'
import { QuestionSchema } from 'screening/domain'
import { QuestionItem } from './components'

interface Props {
  jobListingTitle: string
  questions: Array<QuestionSchema>
}

export const QuestionsPreview = ({ jobListingTitle, questions }: Props) => {
  const [isCollapseExpanded, setCollapseExpanded] = useState(false)
  const handleCollapseClick = (isCollapseExpanded: boolean) =>
    setCollapseExpanded(isCollapseExpanded)

  return (
    <div className="px-8 py-6 border border-neutral-lighter rounded-lg">
      <Collapse
        initiallyExpanded
        triggerClassName="h-fit"
        triggerContent={
          <div className="w-full flex flex-row justify-between">
            <Text weight="font-medium">{jobListingTitle} Screening questions</Text>
            <ChevronDownIcon
              className={cn('w-6 h-6 stroke-neutral-darker transition duration-150 ease-in-out', {
                '-rotate-180': isCollapseExpanded,
                'rotate-0': !isCollapseExpanded,
              })}
            />
          </div>
        }
        onStateChange={handleCollapseClick}
      >
        <ul className="flex flex-col gap-8 mt-4">
          {questions.map((question, index) => (
            <QuestionItem key={index} index={index} question={question} />
          ))}
        </ul>
      </Collapse>
    </div>
  )
}
