import { useLocalStorage } from 'shared/hooks/storage'

const REFERRAL_PAGE_SEEN_KEY = 'referral_page_seen'

export const useReferralPageSeen = () => {
  const [isSeen, setIsSeen] = useLocalStorage(REFERRAL_PAGE_SEEN_KEY, false)

  const setSeen = () => {
    setIsSeen(true)
  }

  return { isSeen, setSeen }
}
