import { Paragraph } from 'shared/components/atoms'
import { ReactComponent as TrophyIcon } from 'icons/trophy-02.svg'

import { InstitutionTier, institutionTierLabels } from 'modules/candidates/enums'
import classNames from 'classnames'

interface InstitutionTierBadgeProps {
  tier: InstitutionTier
}

export const InstitutionTierBadge = ({ tier }: InstitutionTierBadgeProps) => {
  if (![InstitutionTier.One, InstitutionTier.Two].includes(tier)) return null

  const bgColor = {
    [InstitutionTier.One]: 'bg-warning-light bg-opacity-[32%]',
    [InstitutionTier.Two]: 'bg-warning-light bg-opacity-[20%]',
  }[String(tier)]

  return (
    <div className={classNames('flex grow-0 gap-2 items-center py-1 px-3 rounded', bgColor)}>
      <TrophyIcon className="w-3 h-3 fill-warning-medium stroke-warning-medium" />
      <Paragraph size="body-xs" weight="font-normal" className="!whitespace-nowrap">
        {institutionTierLabels[tier]} in LATAM
      </Paragraph>
    </div>
  )
}
