import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { useBrowserURL, useQueryParams } from 'shared/hooks'

import { Filter, Search, SearchResult, defaultFilter } from 'candidates-search/domain'
import { SortType, Tab } from 'candidates-search/enums'

export const SearchContext = React.createContext({
  isInitializing: true,
  search: undefined as Search | undefined,
  searchResult: { profiles: [], totalPages: 0, totalRecords: 0 } as SearchResult,
  filter: defaultFilter as Filter,
  lastFilter: defaultFilter as Filter,
  currentPage: 1,
  sort: SortType.Relevance,
  tab: Tab.AllCandidates,
  applyTabFilter: false,
  setIsInitializing: (() => null) as Dispatch<SetStateAction<boolean>>,
  setSearch: (() => null) as Dispatch<SetStateAction<Search | undefined>>,
  setSearchResult: (() => null) as Dispatch<SetStateAction<SearchResult>>,
  setFilter: (() => null) as Dispatch<SetStateAction<Filter>>,
  setLastFilter: (() => null) as Dispatch<SetStateAction<Filter>>,
  setCurrentPage: (() => null) as Dispatch<SetStateAction<number>>,
  setSort: (() => null) as Dispatch<SetStateAction<SortType>>,
  setTab: (() => null) as Dispatch<SetStateAction<Tab>>,
  setApplyTabFilter: (() => null) as Dispatch<SetStateAction<boolean>>,
})

export const SearchProvider = ({ children }: { children: React.ReactNode }) => {
  const params = useQueryParams()
  const { replaceCurrentURL } = useBrowserURL()

  const [isInitializing, setIsInitializing] = useState(true)
  const [search, setSearch] = useState<Search | undefined>()
  const [searchResult, setSearchResult] = useState<SearchResult>({
    profiles: [],
    totalPages: 0,
    totalRecords: 0,
  })
  const [filter, setFilter] = useState(defaultFilter)
  const [lastFilter, setLastFilter] = useState(defaultFilter)
  const [currentPage, setCurrentPage] = useState(1)
  const [sort, setSort] = useState(SortType.Relevance)
  const [tab, setTab] = useState((params.get('tab') as Tab) || Tab.AllCandidates)
  const [applyTabFilter, setApplyTabFilter] = useState(false)

  const searchContext = {
    isInitializing,
    search,
    searchResult,
    filter,
    lastFilter,
    currentPage,
    sort,
    tab,
    applyTabFilter,
    setIsInitializing,
    setSearch,
    setSearchResult,
    setFilter,
    setLastFilter,
    setCurrentPage,
    setSort,
    setTab,
    setApplyTabFilter,
  }

  useEffect(() => {
    if (!search?.id) {
      return replaceCurrentURL('/candidates/search')
    }
    replaceCurrentURL(`/candidates/search/${search.id}?tab=${tab}`)
  }, [search?.id, tab])

  return <SearchContext.Provider value={searchContext}>{children}</SearchContext.Provider>
}
