import { Expose, Type } from 'class-transformer'
import { Stack } from './stack'

@Expose()
class Organization {
  id: string
  name: string
  website?: string | null
}

@Expose()
export class WorkExperience {
  id: string
  title: string
  location: string
  startDate: string | Date
  endDate: string | Date | null | undefined
  description: string | null
  numberOfManagedPeople: number
  percentageOfWorkManagingPeople: number
  isRemote: boolean
  incomplete: boolean

  @Type(() => Organization)
  experienceOrganization: Organization

  @Type(() => Stack)
  stacks: Array<Stack>
}
