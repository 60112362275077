export enum InterviewType {
  StriderOnboarding = 'StriderOnboarding',
  IntroCall = 'IntroCall',
  TechCall = 'TechCall',
  OfferCall = 'OfferCall',
  Other = 'Other',
}

export const enum InterviewStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Rescheduled = 'Rescheduled',
  Expired = 'Expired',
}

export class InterviewResponse {
  id: string
  type: InterviewType
  rescheduleLink: string | null
  status: InterviewStatus
  expiresAt: Date
}
