import { PrimaryButton, TertiaryButton } from 'shared/components/atoms'
import { useHelp } from 'modules/help'
import { Offer } from 'pages/JobSeekerProfile/types'
import { useNavigate } from 'react-router-dom'
import { Task } from './Task'

interface Props {
  offers: Array<Offer>
}

export const OfferTasks = ({ offers }: Props) => {
  const navigate = useNavigate()
  const { showNewMessages } = useHelp()

  const redirectToOffer = (id: string) => navigate(`/offers/${id}`)
  const willOfferExpireSoon = (date: string) => {
    const now = new Date()
    const expirationDate = new Date(date)
    return (
      now.getDate() === expirationDate.getDate() &&
      now.getMonth() === expirationDate.getMonth() &&
      now.getFullYear() === expirationDate.getFullYear()
    )
  }

  const buildTitleLabel = (companyName: string, expiresAt: string) =>
    willOfferExpireSoon(expiresAt)
      ? `Your offer from ${companyName} expires today!`
      : `${companyName} sent you an offer!`

  const buildDescriptionLabel = (companyName: string, expiresAt: string) =>
    willOfferExpireSoon(expiresAt)
      ? `Your offer from ${companyName} will expire today. Check here all the details, like the compensation, start date, and the contract.`
      : `You received a job offer from ${companyName} through Strider's platform. Check here all the details, like the compensation, start date, and the contract.`

  return (
    <>
      {offers.map(({ id, companyName, expiresAt }, index) => (
        <Task
          key={`offer-${index}`}
          iconPath="images/party.png"
          iconClassName="bg-warning-lighter"
          title={buildTitleLabel(companyName, expiresAt)}
          description={buildDescriptionLabel(companyName, expiresAt)}
          cta={
            <div className="flex gap-3">
              <PrimaryButton size="sm" onClick={() => redirectToOffer(id)}>
                View offer
              </PrimaryButton>
              <TertiaryButton
                size="sm"
                onClick={() =>
                  showNewMessages(`I need help with my job offer made by ${companyName}`)
                }
              >
                I need help
              </TertiaryButton>
            </div>
          }
        />
      ))}
    </>
  )
}
