import { useEffect, useRef } from 'react'

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef<() => void>()
  const timerId = useRef<NodeJS.Timer>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => savedCallback.current && savedCallback.current()

    if (delay !== null) {
      timerId.current = setInterval(tick, delay)
      return clear
    }
  }, [delay])

  const clear = () => clearInterval(Number(timerId.current))

  return { clear }
}
