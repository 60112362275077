import { Link } from 'react-router-dom'
import { Text } from 'shared/components/atoms'
import { ChangeLog } from 'candidates-search/domain'

interface ScreeningVersionProps {
  changeLog: ChangeLog
}

export const ScreeningVersion = ({ changeLog }: ScreeningVersionProps) => {
  const listingId = changeLog.listing?.id

  if (!changeLog.screening) return null

  return (
    <div className="flex gap-2">
      <Text size="text-xs" weight="font-normal" className="text-neutral-medium">
        Screening version:
      </Text>
      <Text size="text-xs" weight="font-normal" className="text-neutral-darkest underline">
        <Link
          to={`/listings/${listingId}/screenings`}
          className="h-fit text-inherit"
          target="_blank"
        >
          {changeLog.screening.title}
        </Link>
      </Text>
    </div>
  )
}
