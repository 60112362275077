import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import classNames from 'classnames'
import { Text } from 'components/Text'
import { useEffect, useState } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

interface Props {
  name: string
  options: Array<string>
  defaultOption: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormValue: UseFormSetValue<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  rootClassName?: string
}

export const ToggleGroup = ({
  name,
  options,
  defaultOption,
  setFormValue,
  watch,
  rootClassName,
}: Props) => {
  const [value, setValue] = useState(defaultOption)

  const formValue = watch(name)

  useEffect(() => setValue(formValue), [formValue])

  return (
    <ToggleGroupPrimitive.Root
      className={classNames(
        'flex bg-neutral-day border rounded border-neutral-light',
        rootClassName,
      )}
      type="single"
      value={value}
      onValueChange={(value: string) => {
        if (value) {
          setValue(value)
          setFormValue(name, value)
        }
      }}
    >
      {options.map((option) => (
        <ToggleGroupPrimitive.Item
          key={option}
          value={option}
          className="p-1 text-neutral-dark flex-1 border-l border-neutral-light first:border-l-0
            data-state-on:text-neutral-darkest data-state-on:bg-neutral-lightest
            hover:bg-neutral-lightest  hover:text-neutral-darkest"
        >
          <Text size="small-body" color="text-inherit">
            {option}
          </Text>
        </ToggleGroupPrimitive.Item>
      ))}
    </ToggleGroupPrimitive.Root>
  )
}
