import { Text } from 'components/Text'
import { useFormatting } from 'shared/hooks'

import { ReactComponent as AlertIcon } from 'icons/info-circle.svg'

import { PaymentModel } from 'job-listing/enums'

interface OverBudgetBannerProps {
  difference: number
  client: string
  paymentModel: PaymentModel
}

export const OverBudgetBanner = ({ difference, client, paymentModel }: OverBudgetBannerProps) => {
  const { formatCurrency } = useFormatting()

  const formattedDifference = formatCurrency(Math.abs(difference))
  const budget = {
    [PaymentModel.Monthly]: 'monthly',
    [PaymentModel.Hourly]: 'hourly,',
  }

  return (
    <div className="flex gap-3 px-3 py-2 bg-neutral-lightest rounded-lg">
      <AlertIcon className="stroke-neutral-medium h-6 w-6 min-w-[24px]" />
      <Text>
        The total talent cost is <span className="text-danger-dark">{formattedDifference}</span>{' '}
        over {client} {budget[paymentModel]} budget. Consider negotiating with the client if you
        think this would be a good candidate.
      </Text>
    </div>
  )
}
