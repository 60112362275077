import { JobDescription } from 'screening/domain'
import { BlockMessageLevel, BlockType, ChangeLogType } from '../../enums'
import { Type } from 'class-transformer'
import { formatDateTime, isDateTime } from 'shared/hooks/formatting/useFormatting'

export class Profile {
  id: string
  firstName: string
  lastName: string
  email: string
}

export class Author {
  email: string
  photoUrl: string
  id: number
}

export class WorkExperienceOrganization {
  id: string
  name: string
}

export class WorkExperience {
  id: string
  title: string
  startDate: string
  endDate: Nullable<string>

  @Type(() => WorkExperienceOrganization)
  organization: WorkExperienceOrganization

  get startDateObject() {
    return new Date(this.startDate)
  }

  get endDateObject() {
    return this.endDate ? new Date(this.endDate) : undefined
  }
}

export class Listing {
  id: string
  title: string
}

export class Company {
  id: number
  name: string
}

export class Screening {
  id: string
  title: string
  jobDescription: JobDescription
}

export class DescriptionBlock {
  type: BlockType
  messageLevel: BlockMessageLevel
  label: string
  redirectUrl?: string
}

export class ChangeHistory {
  date: string
  propertyDescription: string
  oldValue: string | number | boolean | null
  newValue: string | number | boolean | null

  get hasOldValue() {
    return this.oldValue !== null
  }

  get hasNewValue() {
    return this.newValue !== null
  }

  get formattedOldValue() {
    if (typeof this.oldValue === 'boolean') {
      return this.oldValue ? 'Yes' : 'No'
    }

    return this.applyFormatDateTime(this.oldValue)
  }

  get formattedNewValue() {
    if (typeof this.newValue === 'boolean') {
      return this.newValue ? 'Yes' : 'No'
    }

    return this.applyFormatDateTime(this.newValue)
  }

  get isLongText() {
    const oldValueLength = this.formattedOldValue?.toString().length || 0
    const newValueLength = this.formattedNewValue?.toString().length || 0

    return oldValueLength > 100 || newValueLength > 100
  }

  private applyFormatDateTime(value: string | number | boolean | null) {
    if (typeof value === 'string' && isDateTime(value)) {
      return formatDateTime({ date: new Date(value) })
    }
    return value
  }
}

export class ChangeLog {
  id: string
  profile: Profile
  author: Nullable<Author>
  type: ChangeLogType

  @Type(() => WorkExperience)
  workExperience: Nullable<WorkExperience>

  listing: Nullable<Listing>
  company: Nullable<Company>
  screening: Nullable<Screening>
  date: string
  description: Nullable<Array<DescriptionBlock>>

  @Type(() => ChangeHistory)
  details: Nullable<Array<ChangeHistory>>

  get isMatchingRelated() {
    return [
      ChangeLogType.MatchingUpserted,
      ChangeLogType.ScreeningAssigned,
      ChangeLogType.ScreeningApplied,
      ChangeLogType.ScreeningMissed,
      ChangeLogType.ScreeningViewed,
      ChangeLogType.ScreeningStarted,
      ChangeLogType.ScreeningDeclined,
      ChangeLogType.ScreeningAnswered,
      ChangeLogType.ScreeningSubmitted,
    ].includes(this.type)
  }

  get isWorkExperienceRelated() {
    return [
      ChangeLogType.ProfileWorkExperienceDeleted,
      ChangeLogType.ProfileWorkExperienceUpserted,
    ].includes(this.type)
  }

  get hasDetails() {
    return (
      this.screening ||
      this.author ||
      this.isMatchingRelated ||
      (this.details && this.details?.length > 0)
    )
  }
}
