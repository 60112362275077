import { Paragraph, Text } from 'shared/components/atoms'
import { WorkExperience } from 'modules/work-experience'
import { WorkExperienceItem } from './WorkExperienceItem'
import { Answer } from './Answer'
import { QuestionSchema } from 'screening/domain'

interface Props {
  text?: string | null
  workExperiences?: Array<string>
  candidateExperiences: { [key: string]: WorkExperience }
  index: number
  question: QuestionSchema
}

export const ApplicationReviewItem = ({
  text,
  workExperiences,
  candidateExperiences,
  index,
  question,
}: Props) => {
  const experiences = workExperiences?.map((workExperienceId) => {
    return candidateExperiences[workExperienceId]
  })

  return (
    <div className="flex gap-x-4">
      <div className="flex items-center justify-center bg-neutral-lightest rounded-lg w-8 h-8">
        <Text>{index + 1}</Text>
      </div>
      <div className="w-full">
        <Paragraph size="body-lg" weight="font-medium">
          {question.title}
          {Boolean(question.required) && '*'}
        </Paragraph>

        <Answer
          questionType={question.type}
          value={text}
          expectedAnswer={question.expectedAnswer}
          required={question.required}
        />

        {Boolean(experiences?.length) && (
          <div className="mt-6">
            <Text weight="font-medium" className="text-neutral-dark">
              Link a work experience
            </Text>

            <div className="flex flex-col gap-y-4 mt-2">
              {experiences!.map((experience, index) => (
                <WorkExperienceItem key={index} workExperience={experience} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
