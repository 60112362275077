import { Link } from 'react-router-dom'

import { Paragraph, PrimaryButton, Text } from 'shared/components/atoms'
import { AlertDialog } from 'shared/components/molecules'

import { BoostCard } from './components/BoostCard'

import { ReactComponent as CheckIcon } from 'icons/check--thin.svg'

interface ImproveProfileDialogProps {
  onFinish: () => void
  jobOpportunityId: string
}

export const ImproveProfileDialog = ({ onFinish, jobOpportunityId }: ImproveProfileDialogProps) => {
  return (
    <AlertDialog
      isOpen
      isCancellable={false}
      title={
        <div className="flex gap-4">
          <div className="w-8 h-8 p-2 bg-success-lighter rounded-full">
            <CheckIcon className="w-4 h-4 stroke-success-medium" />
          </div>
          <Text size="text-xl" weight="font-medium">
            Application sent
          </Text>
        </div>
      }
      titleClassName="pb-2 sm:pb-0"
      description={
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <Paragraph
              size="body-sm"
              weight="font-normal"
              className="text-neutral-dark"
              truncate={false}
            >
              It's important to keep{' '}
              <Link to="/profile" target="_blank">
                your profile
              </Link>{' '}
              updated with detailed descriptions of your achievements as companies review it
              thoroughly to evaluate if you're a good fit.
            </Paragraph>
          </div>
          <BoostCard jobOpportunityId={jobOpportunityId} />
        </div>
      }
      customActions={
        <PrimaryButton className="w-full lg:mt-2" onClick={onFinish}>
          Got it
        </PrimaryButton>
      }
    />
  )
}
