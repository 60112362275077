import React, { useContext } from 'react'

import { TertiaryButton } from 'shared/components/atoms'
import { ActionProps } from '.'

import { ReactComponent as ShortlistIcon } from 'icons/bookmark.svg'

import { useUpsertMatching } from 'candidates-search/hooks'

import { SearchContext } from 'candidates-search/contexts'
import { MatchingStatus } from 'matching/enums'

export const Shortlist = ({ profile }: ActionProps) => {
  const { search } = useContext(SearchContext)
  const { upsertMatching, isUpserting } = useUpsertMatching(profile.id)

  if (!search?.isListingLinked) return null

  const handleShortlist = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    upsertMatching({ status: MatchingStatus.Shortlisted })
  }

  return (
    <TertiaryButton
      onClick={handleShortlist}
      icon={<ShortlistIcon className="!w-6 !h-6" />}
      isIconBefore
      isLoading={isUpserting}
    >
      Shortlist
    </TertiaryButton>
  )
}
