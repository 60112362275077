import classNames from 'classnames'
import { textStyles } from '../Text/Text'
import './CountBadge.scss'

interface CountBadgeProps {
  value: number
  total?: number
  isMaximum?: boolean
  className?: string
}

export const CountBadge = ({ value, total, isMaximum, className }: CountBadgeProps) => {
  const buildLabel = () => {
    if (total !== undefined) return `${value} / ${total}`
    if (value > 1000) return `${Math.floor(value / 1000)}k${isMaximum ? '+' : ''}`
    return value
  }

  return (
    <span
      className={classNames(
        'inline-flex items-center bg-neutral-lightest text-neutral-dark h-[20px] px-1 rounded !whitespace-nowrap',
        textStyles('text-sm', 'font-normal'),
        className,
      )}
    >
      {buildLabel()}
    </span>
  )
}
