import { Agreements } from 'account/types'
import { useMonitoring, useNetwork } from 'shared/hooks'
import { useQuery } from 'react-query'
import { SupplementalTermsFeature } from 'modules/supplemental-terms/enums'

const usePendingSupplementalTerms = (
  feature: SupplementalTermsFeature,
): [Agreements | null, boolean, boolean] => {
  const { get } = useNetwork()
  const { captureException } = useMonitoring()

  const {
    data: supplementalTerms = null,
    isError: error,
    isLoading,
  } = useQuery(
    `supplemental-terms/${feature}/pending`,
    async () =>
      await get<Agreements>(`agreements/supplemental-terms/${feature}/pending`)
        .then(({ data }) => data)
        .catch((error: RequestError) => {
          captureException(error)
        }),
    {
      retry: 5,
    },
  )

  return [supplementalTerms, error, isLoading]
}

export { usePendingSupplementalTerms }
