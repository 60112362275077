import { useEffect } from 'react'
import { useRedirectToReferrals } from './useRedirectToReferrals'
import { useSessionStorage } from '../storage'

const landingLocationKey = 'landingLocation'
const defaultUrl = '/'

export const useLandingTracking = () => {
  const { onSessionStart } = useRedirectToReferrals()
  const [landingLocation, setLandingLocation, resetLandingLocation] = useSessionStorage<
    Nullable<string>
  >(landingLocationKey, null)

  const getCurrentUrl = () => {
    const { pathname, search, hash } = new URL(window.location.href)

    return [pathname, search, hash].join('')
  }

  const getLandingLocation = () => {
    return landingLocation || defaultUrl
  }

  const getLandingLocationParams = () => {
    const landingLocation = getLandingLocation()
    const queryString = landingLocation.substring(landingLocation.indexOf('?'))

    return new URLSearchParams(queryString)
  }

  useEffect(() => {
    const newLandingLocation = landingLocation || getCurrentUrl()
    setLandingLocation(newLandingLocation)
    onSessionStart(newLandingLocation)
  }, [])

  const isSdrFlow = Boolean(getLandingLocation().match(/candidates/))

  return { getLandingLocation, resetLandingLocation, isSdrFlow, getLandingLocationParams }
}
