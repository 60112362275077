export enum ProfileFulfillmentStatus {
  Excellent = 'excellent',
  Sufficient = 'sufficient',
  UnderReview = 'under_review',
  Rejected = 'rejected',
  OutOfBounds = 'out_of_bounds',
}

export enum STAStatus {
  Done = 'done',
  NoAssessment = 'no_assessment',
  InProgress = 'in_progress',
}

export enum EducationType {
  Done = 'done',
  InProgress = 'in_progress',
  NotInformed = 'not_informed',
}
