import { AssessmentTag } from 'modules/profiles/domain'
import { AddAssessmentTagsDTO } from 'modules/profiles/dtos'
import { useMutation, useQueryClient } from 'react-query'
import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useAddTags = (profileId: string) => {
  const { patch } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()
  const client = useQueryClient()

  const { mutateAsync: addTags, isLoading } = useMutation(
    (tags: Array<AssessmentTag>) => {
      const payload = new AddAssessmentTagsDTO(tags).toJSON()
      return patch(`profile/${profileId}/profile-tags`, payload)
    },
    {
      onSuccess: () => {
        client.invalidateQueries(`candidates/${profileId}`)
      },
      onError: (error: RequestError) => {
        toastError('Failed to add tags: ' + error.response?.data.message)
        captureException(error)
      },
    },
  )

  return { addTags, isLoading }
}
