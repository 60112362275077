import classNames from 'classnames'
import { ReactComponent as HelpIcon } from 'icons/help-circle.svg'
import { ReactComponent as HelpOutlinedIcon } from 'icons/help-circle-outlined.svg'
import { Text } from 'components/Text'
import { useHelp } from '../useHelp'
import { Tooltip } from 'shared/components/atoms'

export const ChatLauncher = ({ className }: { className: string }) => {
  const { toggleChat, unreadMessagesCount, isChatOpen } = useHelp()

  return (
    <Tooltip content="Ask for help">
      <div
        className={classNames(
          'h-full px-3 cursor-pointer flex flex-col gap-1 items-center rounded relative hover:bg-neutral-darkest',
          className,
        )}
        onClick={toggleChat}
      >
        {isChatOpen ? (
          <HelpIcon className="w-6 h-6 fill-neutral-day" />
        ) : (
          <HelpOutlinedIcon className="w-6 h-6 stroke-neutral-day" />
        )}
        <Text size="caption" className="text-neutral-light md:hidden">
          Help
        </Text>
        {Boolean(unreadMessagesCount) && (
          <Text
            className="
            bg-brand-medium absolute right-1 top-1 px-[2px] rounded-2xl !text-[10px] font-medium h-[16px] 
            flex items-center border-2 border-neutral-darker box-content
          "
            color="text-white"
          >
            {' '}
            {unreadMessagesCount}{' '}
          </Text>
        )}
      </div>
    </Tooltip>
  )
}
