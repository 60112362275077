import { FormEvent } from 'react'

import { Text } from 'shared/components/atoms'
import { Link } from 'shared/components/atoms'
import { Banner } from 'shared/components/molecules'
import { FileUploader } from 'shared/components/atoms'

interface ImportFormProps {
  onSubmit: (event: FormEvent) => void
  file: File | null
  setFile: (file: File | null) => void
  error: string
}

export const ImportForm = ({ onSubmit, file, setFile, error }: ImportFormProps) => {
  return (
    <form onSubmit={onSubmit} className="gap-10 flex flex-col" id="importing-upload">
      <Text size="text-sm" weight="font-normal" className="mt-4">
        Upload a LinkedIn exported PDF to import work experiences to your profile here. Not sure how
        to do it?{' '}
        <Link url="https://linkedin.com/help/linkedin/answer/a541960/save-a-profile-as-a-pdf">
          See how to get the PDF from your LinkedIn
        </Link>
      </Text>

      <Banner>
        <p>
          By importing a file to an existing profile you might override existing information on work
          experience section.
        </p>
      </Banner>

      <div className="flex flex-col gap-2">
        <FileUploader file={file} setFile={setFile} error={error} />
        <Text size="text-sm" weight="font-normal" className="text-warning-darker">
          {error || ' '}
        </Text>
      </div>
    </form>
  )
}
