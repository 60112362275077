import { HomepageListing } from 'job-listing/dtos'
import { InfiniteScroll } from 'shared/components/atoms'
import { ListingCard } from '../ListingCard'

interface ListingListProps {
  listings: Array<HomepageListing>
  isFirstPage: boolean
  onFetchMore: () => void
  hasMore: boolean
}

export const ListingList = ({ listings, isFirstPage, hasMore, onFetchMore }: ListingListProps) => {
  if (!listings.length) return null

  return (
    <InfiniteScroll
      dataLength={listings.length}
      onFetchMore={onFetchMore}
      hasMore={hasMore}
      isFirstPage={isFirstPage}
    >
      <div className="flex flex-wrap justify-start gap-6 text-sm">
        {listings!.map((listing) => (
          <ListingCard key={listing.id} listing={listing} />
        ))}
      </div>
    </InfiniteScroll>
  )
}
