import { FieldHint, Label, RichTextEditor, Textarea } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'

interface Props {
  formControl: FormControl
  name: string
  omitErrors?: boolean
  isOptional?: boolean
  richTextEditor?: boolean
  defaultValue?: string
  label?: string
}

export const ProjectDescription = ({
  formControl,
  name,
  omitErrors,
  isOptional,
  richTextEditor,
  defaultValue,
  label = 'Company and project description',
}: Props) => {
  const { register, setValue } = formControl

  return (
    <>
      <Label htmlFor={name} size="text-sm" isOptional={isOptional}>
        {label}
      </Label>
      <FieldHint className="!mb-6">
        Tell us more about what they'd be working on. It's helpful to describe what they'd be
        building and the technologies they'd use to build it.
      </FieldHint>
      {richTextEditor ? (
        <RichTextEditor
          name={name}
          setValue={setValue}
          defaultValue={defaultValue}
          omitErrors
          showBoldOption
          showHighlightOption
        />
      ) : (
        <Textarea register={register} id={name} name={name} omitErrors={omitErrors} />
      )}
    </>
  )
}
