import classNames from 'classnames'
import { Text } from 'components/Text'
import { ReactComponent as WarningIcon } from 'icons/alert-triangle.svg'

interface BannerProps {
  children: string | React.ReactNode
  className?: string
  icon?: React.ReactNode | null
  showIcon?: boolean
}

export const Warning = ({ children, className, icon, showIcon = true }: BannerProps) => {
  return (
    <div
      className={classNames(
        'p-4 flex flex-row items-start gap-3 bg-warning-lighter rounded-lg',
        className,
      )}
    >
      {showIcon && (icon || <WarningIcon className="min-w-[24px] h-6 stroke-warning-medium" />)}
      <Text size="small-body" weight="font-normal">
        {children}
      </Text>
    </div>
  )
}
