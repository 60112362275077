import { array, boolean, date, object, string } from 'yup'

export { RegularOfferForm } from './RegularOfferForm'

export const schema = object()
  .shape({
    jobTitle: string().required('Please share the job title for this offer.'),
    preferredStartDates: array()
      .of(date())
      .min(2, 'Suggest at least 2 start dates')
      .max(10, 'Suggest up to 10 start dates'),
    description: string().required('Please provide a description'),
    contractUrl: string().nullable(),
    termsAccepted: boolean().isTrue('You should accept the Supplemental Terms.'),
  })
  .required()
