import { PaymentModel } from 'job-listing/enums'
import { ScreeningStatus } from '../enums'
import { JobDescription } from './job-description.domain'
import { QuestionSchema } from './question.domain'
import { Type, plainToInstance } from 'class-transformer'
import { emptyJobDescription } from 'screening/consts'

export const DEADLINE_DEFAULT_VALUE = Number(
  process.env.REACT_APP_SCREENING_DEADLINE_DEFAULT_VALUE || 48,
)

export class Screening {
  id: string
  title: string
  questions: Array<QuestionSchema>
  deadline: number | null
  minimumPayRate: number
  maximumPayRate: number
  paymentModel: PaymentModel | null
  @Type(() => JobDescription)
  jobDescription: JobDescription | null
  status: ScreeningStatus

  constructor(screening: Partial<Screening> = {}) {
    Object.assign(this, screening)
  }

  buildForm() {
    return {
      id: this.id,
      title: this.title,
      paymentModel: this.paymentModel,
      questions: this.questions,
      acceptanceCriteria: {
        deadline: this.deadline || DEADLINE_DEFAULT_VALUE,
        minimumPayRate: this.minimumPayRate,
        maximumPayRate: this.maximumPayRate,
      },
      jobDescription: this.jobDescription || emptyJobDescription,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromForm(values: Record<string, any>) {
    return plainToInstance(Screening, {
      id: values.id,
      title: values.title,
      paymentModel: values.paymentModel,
      deadline: values.acceptanceCriteria.deadline,
      jobDescription: values.jobDescription,
      maximumPayRate: values.acceptanceCriteria.maximumPayRate,
      minimumPayRate: values.acceptanceCriteria.minimumPayRate,
      questions: values.questions,
    })
  }
}
