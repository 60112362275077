import { AlertDialog } from 'shared/components/molecules'
import { ReactComponent as AlertIcon } from 'icons/alert-triangle.svg'
import { Text } from 'shared/components/atoms'

interface ConfirmationDialogProps {
  open: boolean
  clientName: string
  isLoading: boolean
  onCancel: () => void
  onConfirm: () => void
}

export const ConfirmationDialog = ({
  open,
  clientName,
  onConfirm,
  onCancel,
  isLoading,
}: ConfirmationDialogProps) => {
  return (
    <AlertDialog
      isOpen={open}
      isDismissible
      title={`Confirm teleport to "${clientName}"`}
      description={
        <div className="flex gap-3 px-3 py-2 bg-warning-lighter rounded">
          <AlertIcon className="stroke-warning-medium h-6 w-6 min-w-[24px]" />
          <Text size="text-sm" weight="font-medium">
            Page refresh required. Unsaved changes will be lost.
          </Text>
        </div>
      }
      onCancelClick={onCancel}
      onConfirmClick={onConfirm}
      autoFocusConfirmButton
      isLoading={isLoading}
    />
  )
}
