import { Roles } from 'account/enums'
import { Persona } from 'modules/persona'

export class TrackingIdentity {
  constructor(
    private role: Roles | undefined,
    private email: string,
    private company: Partial<{ id: number; name: string }> | null,
  ) {}

  get payload() {
    return {
      persona: this.label,
      email: this.email,
      companyId: this.company?.id,
      company: this.company?.name,
      role: this.role,
    }
  }

  get trackingBlocked() {
    const internalUser = String(this.email).toLowerCase().includes('@onstrider.com')

    return this.role === Roles.JobSeeker || internalUser
  }

  private get label() {
    return new Persona(this.role).label
  }
}
