import { useState } from 'react'

import { ClaimProtected, Paragraph, Text } from 'shared/components/atoms'

import { AddAssessmentTagsModal } from '../AddAssessmentTagsModal'

import { Tags } from '../Tags'

import { AccountClaim, useAuth, useFormatting } from 'shared/hooks'
import { useDeleteTag } from '../../hooks'

import { AssessmentTag } from 'modules/profiles/domain'
import { cn } from 'modules/shared'
import { LastUsers } from './components'

interface Props {
  profileId: string
  tags: Array<AssessmentTag>
  lastUpdatedAt?: string | null
  hideTitle?: boolean
  containerClassName?: string
  onTagsAdded?: (tags: Array<AssessmentTag>) => void
  onTagsRemoved?: (id: string) => void
}
export const AssessmentTagsSection = ({
  profileId,
  tags,
  lastUpdatedAt,
  hideTitle,
  containerClassName,
  onTagsAdded,
  onTagsRemoved,
}: Props) => {
  const { deleteTag } = useDeleteTag(profileId)
  const {
    user: { claims },
  } = useAuth()
  const { formatDate } = useFormatting()

  const [addAssessmentTagsModalOpen, setAddAssessmentTagsModalOpen] = useState(false)

  const handleRemove = (id: string) => {
    deleteTag(id)
    if (onTagsRemoved) onTagsRemoved(id)
  }
  const handleAdd = () => setAddAssessmentTagsModalOpen(true)
  const handleClose = (tags: Array<AssessmentTag>) => {
    setAddAssessmentTagsModalOpen(false)
    if (onTagsAdded) onTagsAdded(tags)
  }

  return (
    <ClaimProtected require={AccountClaim.ViewProfileAssessmentTags}>
      <div className="print:hidden">
        <div
          className={cn('flex flex-col gap-4', containerClassName)}
          onClick={(event) => event.stopPropagation()}
        >
          {!hideTitle && (
            <div className="flex items-center gap-4">
              <Text size="text-xl" weight="font-medium">
                Assessment notes
              </Text>
              {Boolean(lastUpdatedAt) && (
                <Paragraph className="text-neutral-dark">
                  Last update {formatDate({ date: new Date(lastUpdatedAt!) })}
                </Paragraph>
              )}
            </div>
          )}
          <div className="flex items-start gap-2">
            <LastUsers tags={tags} />
            <div className="w-full p-2 hover:bg-neutral-lightest hover:bg-opacity-50 transition-all group">
              <Tags
                tags={tags}
                editOnHover
                onRemove={handleRemove}
                onAdd={handleAdd}
                readOnly={!claims.includes(AccountClaim.ManageAssessmentTags)}
              />
            </div>
          </div>
        </div>
        <AddAssessmentTagsModal
          isOpen={addAssessmentTagsModalOpen}
          profileId={profileId}
          onClose={handleClose}
          tags={tags}
        />
      </div>
    </ClaimProtected>
  )
}
