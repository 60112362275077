import { DegreeType } from './types'

export * from './pages'

export class YearOfExperienceOptions {
  id: number
  label: string
  abbreviation: string
}
export const yearsOfExperienceOptions: YearOfExperienceOptions[] = [
  { id: 0, label: 'less than 1 year', abbreviation: '< 1 yr.' },
  { id: 1, label: '1 year', abbreviation: '1 yr.' },
  { id: 2, label: '2 years', abbreviation: '2 yr.' },
  { id: 3, label: '3 years', abbreviation: '3 yr.' },
  { id: 4, label: '4 years', abbreviation: '4 yr.' },
  { id: 5, label: '5 years', abbreviation: '5 yr.' },
  { id: 6, label: '6 years', abbreviation: '6 yr.' },
  { id: 7, label: '7 years', abbreviation: '7 yr.' },
  { id: 8, label: '8 years', abbreviation: '8 yr.' },
  { id: 9, label: '9 years', abbreviation: '9 yr.' },
  { id: 10, label: '10+ years', abbreviation: '10+ yr.' },
]

export const yearsOfExperienceOptionsMap = new Map(
  yearsOfExperienceOptions.map((option) => [option.id, option]),
)

export const degreeTypeLabels = {
  [DegreeType.Bachelor]: `Bachelor's Degree`,
  [DegreeType.Master]: `Master's Degree`,
  [DegreeType.MBA]: 'MBA',
  [DegreeType.Doctoral]: 'Doctoral Degree',
  [DegreeType.HighSchool]: 'Secondary / High School Diploma',
  [DegreeType.Other]: 'Other degree',
} as Record<DegreeType, string>

export const degreeTypeOptions = Object.values(DegreeType).map((value) => ({
  id: value,
  label: degreeTypeLabels[value],
}))
