import { useMutation, useQueryClient } from 'react-query'

import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

import { ListingStatus } from 'job-listing/enums'

export const useManageJobListing = (id: string) => {
  const client = useQueryClient()

  const { patch } = useNetwork()
  const { toastError } = useToast()

  const { captureException } = useMonitoring()

  const { mutateAsync: updateStatus } = useMutation(
    (status: ListingStatus) => patch(`listings/${id}/status/${status}`),
    {
      onSuccess: () => {
        client.invalidateQueries('listings')
        client.invalidateQueries(`listings/${id}/preview`)
      },
      onError: (error: RequestError) => {
        toastError('Failed to update listing status.')
        captureException(error)
      },
    },
  )

  return {
    updateStatus,
  }
}
