import classNames from 'classnames'
import { Text } from 'shared/components/atoms'
import { ReactComponent as Icon } from 'icons/x-square.svg'

export const FieldError = ({ message, className }: { message?: string; className?: string }) => {
  return (
    <div className={classNames('flex items-center gap-2 my-[2px] lg:my-2')}>
      <>
        {Boolean(message) && <Icon className="w-4 h-4 fill-danger-medium stroke-neutral-day" />}
        <Text
          size="text-sm"
          weight="font-normal"
          className={classNames('text-danger-darker', className)}
        >
          {message}&nbsp;
        </Text>
      </>
    </div>
  )
}
