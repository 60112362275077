import { Preferences } from 'modules/profiles/profile-preferences'
import { WorkExperience } from 'modules/work-experience'
import { ProfileCertification, ProfileEducation } from 'pages/JobSeekerProfile/types'

export { WorkExperience } from './WorkExperience'
export { Education } from './Education'
export { Certification } from './Certification'
export { Bio } from './Bio'
export { About } from './About'
export { Preferences } from './Preferences'
export { RolesAndSkills } from './RolesAndSkills'
export { PayRate } from './PayRate'

export type EditModalDataType =
  | WorkExperience
  | ProfileEducation
  | ProfileCertification
  | Preferences
  | undefined

export enum Modal {
  None = 'none',
  AddWorkExperience = 'add-work-experience',
  EditEducation = 'edit-education',
  EditCertification = 'edit-certification',
  Bio = 'Bio',
  About = 'About',
  Preferences = 'Preferences',
  PayRate = 'PayRate',
  RolesAndSkills = 'RolesAndSkills',
}
