import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { ReactComponent as WebsiteIcon } from 'icons/website.svg'
import { ReactComponent as CodeIcon } from 'icons/code.svg'
import { ReactComponent as LinkedInIcon } from 'icons/linkedin.svg'
import { Link } from 'shared/components/atoms'
import { PreviewLabel } from 'shared/components/atoms'

interface LinksCollectionProps {
  profile: ProfileOverview
  title?: string
}

export const LinksCollection = ({ profile, title }: LinksCollectionProps) => {
  const links = [
    { label: 'personal website', icon: <WebsiteIcon />, href: profile.personalSite },
    { label: 'github profile', icon: <CodeIcon />, href: profile.gitRepositoriesUrl },
    { label: 'linkedin profile url', icon: <LinkedInIcon />, href: profile.linkedInUrl },
  ].filter(({ href }) => Boolean(href))

  if (links.length === 0) return null

  const elements = (
    <ul className="flex flex-row gap-3">
      {links.map((link, index) => (
        <li key={index} className="">
          <Link
            className="flex items-center justify-center h-10 w-10 bg-neutral-lightest rounded-full"
            url={link.href!}
            aria-label={link.label}
          >
            {link.icon}
          </Link>
        </li>
      ))}
    </ul>
  )

  if (title) {
    return (
      <PreviewLabel title="Links" disabled className="print:hidden">
        {elements}
      </PreviewLabel>
    )
  }

  return elements
}
