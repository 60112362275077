import { Paragraph } from 'shared/components/atoms'
import { useImage } from 'shared/hooks'

export const TeamReviewBanner = () => {
  const { optimizedImageUrl } = useImage()

  return (
    <div className="flex items-center gap-4 bg-brand-lighter px-4 py-2 rounded-lg mt-6">
      <div className="flex items-center shrink-0">
        <img
          src={optimizedImageUrl(
            'https://res.cloudinary.com/onstrider/image/upload/v1709067507/team/assessment-author-1_r1fvcr.png',
          )}
          className="w-6 h-6"
        />
        <img
          src={optimizedImageUrl(
            'https://res.cloudinary.com/onstrider/image/upload/v1709067494/team/assessment-author-2_ptrh2m.png',
          )}
          className="w-6 h-6 -ml-2"
        />
        <img
          src={optimizedImageUrl(
            'https://res.cloudinary.com/onstrider/image/upload/v1709067493/team/assessment-author-3_lxay2l.png',
          )}
          className="w-6 h-6 -ml-2"
        />
      </div>
      <Paragraph size="body-xs" weight="font-normal" className="text-neutral-darker">
        We're here for you! Each application is carefully reviewed by our team.
      </Paragraph>
    </div>
  )
}
