import { useMemo } from 'react'
import { useToast } from 'shared/hooks'

export const useSlug = (slug: string, path: string) => {
  const { toastSuccess } = useToast()

  const fullLink = useMemo(
    () => (slug ? `${window.location.host}/${path}/${slug}` : null),
    [slug, path],
  )

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(fullLink!)
    toastSuccess('Copied to clipboard')
  }

  return { fullLink, handleCopyToClipboard }
}
