import { useContext, useEffect, useRef, useState } from 'react'

import {
  Checkbox,
  Label,
  NullStepsWizard,
  PrimaryButton,
  StepsWizardInstance,
  TertiaryButton,
} from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'

import {
  ApplicationReview,
  CompatibilityCheck,
  ModalContent,
  CompleteProfile,
  ApplicationSent,
  ImproveProfile,
} from './components'

import { ApplicationModalSteps } from './enums'

import { FormControl, useForm } from 'shared/hooks'
import { useVettingPackage } from 'vetting/exports'
import { useTracking } from 'tracking'
import { useFormBuilder } from './hooks'
import {
  JobOpportunityApplicationSubmitted,
  JobOpportunityTabFocus,
} from 'tracking/events/job-opportunities'
import {
  useAnswerJobOpportunity,
  useFetchJobOpportunity,
  usePersisJobOpportunityAnswers,
  useSubmitJobOpportunity,
} from 'job-opportunity/hooks'

import { JobOpportunity } from 'job-opportunity/domain'

import { CandidateContext } from 'contexts/candidate'

interface Props {
  jobOpportunity: JobOpportunity
  open: boolean
  onBack: () => void
  onClose: () => void
  onSubmit: () => void
}

export const ApplicationModal = ({ jobOpportunity, open, onBack, onClose, onSubmit }: Props) => {
  const { isPending } = jobOpportunity

  const contentRef = useRef<Nullable<HTMLDivElement>>(null)
  const scrollToTop = () => {
    if (contentRef.current) contentRef.current.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const {
    candidate: {
      fulfillment: { percentage },
      experiencesHistory,
    },
    refetch: refetchCandidate,
  } = useContext(CandidateContext)

  const [currentStep, setCurrentStep] = useState(1)
  const [instance, setInstance] = useState<StepsWizardInstance>(NullStepsWizard)
  const [applicationSentDialogOpen, setApplicationSentDialogOpen] = useState(false)

  const { schema, defaultValues, isLoading } = useFormBuilder(jobOpportunity, experiencesHistory)

  const { register, watch, handleSubmit, getValues, getError, setValue, setFocus, errors } =
    useForm({
      schema,
      defaultValues: {
        answers: defaultValues,
      },
      mode: 'onChange',
    })
  const { trackEvent } = useTracking()
  const { remove: removeAnswersFromLocalStorage } = usePersisJobOpportunityAnswers(
    jobOpportunity.screening.id,
  )
  const { submit, isLoading: isSubmitting } = useSubmitJobOpportunity(
    jobOpportunity.screening.id,
    jobOpportunity.id,
  )
  const { answer, isLoading: isAnswering } = useAnswerJobOpportunity(
    jobOpportunity.screening.id,
    jobOpportunity.id,
    () => {
      removeAnswersFromLocalStorage()
      handleNextStep()
    },
  )
  const { fetchJobOpportunity, isRefetching: isJobOpportunityRefetching } = useFetchJobOpportunity(
    jobOpportunity.id,
  )
  const { refetch: refetchEnglishCheck } = useVettingPackage()

  const isProfileCompleted = percentage === 100

  const handleGoBack = () => instance.goToStep(currentStep - 1)
  const handleNextStep = () => instance.goToStep(currentStep + 1)

  const handleSubmitApplication = () => {
    submit().then(() => {
      trackEvent(new JobOpportunityApplicationSubmitted(jobOpportunity.id))
      setApplicationSentDialogOpen(true)
    })
  }

  const handleFinish = () => {
    setApplicationSentDialogOpen(false)
    onSubmit()
  }

  const handleClickBack = () => {
    if (isPending) {
      tabs[currentStep - 1].onBack()
      return
    }

    onClose()
  }

  useEffect(() => {
    const onFocus = async () => {
      if (!open) return
      trackEvent(new JobOpportunityTabFocus(jobOpportunity.id))
      refetchCandidate()
      refetchEnglishCheck()
      const updatedJobOpportunity = await fetchJobOpportunity()

      if (!updatedJobOpportunity.isPending) setApplicationSentDialogOpen(true)
    }

    window.addEventListener('focus', onFocus)
    return () => window.removeEventListener('focus', onFocus)
  }, [open])

  useEffect(() => {
    if (!errors.answers) return

    const index = errors.answers.findIndex(
      (answer: {
        value: { message: string; type: string }
        workExperiences: { message: string; type: string }
      }) => Boolean(answer),
    )

    if (index === -1) return

    const name = `answers.${index}.value`
    setFocus(name)
  }, [errors])

  useEffect(() => {
    if (instance.null || !isProfileCompleted) return
    handleNextStep()
  }, [instance])

  useEffect(() => {
    scrollToTop()
  }, [currentStep])

  const tabs = [
    {
      step: ApplicationModalSteps.CompleteProfile,
      component: (
        <CompleteProfile
          key={ApplicationModalSteps.CompleteProfile}
          isProfileCompleted={isProfileCompleted}
          jobOpportunityId={jobOpportunity.id}
        />
      ),
      visible: isPending,
      onNext: handleNextStep,
      nextDisabled: !isProfileCompleted,
      onBack,
    },
    {
      step: ApplicationModalSteps.CompatibilityCheck,
      component: (
        <CompatibilityCheck
          key={ApplicationModalSteps.CompatibilityCheck}
          jobOpportunity={jobOpportunity}
          formControl={{ register, watch, getValues, getError, setValue } as FormControl}
          isLoading={isLoading}
        />
      ),
      visible: isPending,
      onNext: handleSubmit(() => {
        const { answers } = getValues()
        answer(answers)
      }),
      onBack: handleGoBack,
    },
    {
      step: ApplicationModalSteps.ImproveProfile,
      component: <ImproveProfile profileImprovements={jobOpportunity.profileImprovements} />,
      visible: Boolean(jobOpportunity.profileImprovements),
      onNext: handleNextStep,
      nextDisabled: watch('improvementsApplied') !== true,
      onBack: handleGoBack,
    },
    {
      step: ApplicationModalSteps.ApplicationReview,
      component: (
        <ApplicationReview
          key={ApplicationModalSteps.ApplicationReview}
          jobOpportunity={jobOpportunity}
        />
      ),
      visible: !applicationSentDialogOpen,
      onNext: handleSubmitApplication,
      onBack: isPending ? handleGoBack : onClose,
    },
  ].filter(({ visible }) => visible)

  if (!open) return null

  if (applicationSentDialogOpen) {
    return <ApplicationSent onFinish={handleFinish} jobOpportunityId={jobOpportunity.id} />
  }

  return (
    <Modal
      mobilePositioning="bottom"
      title="Application"
      open={open}
      handleClose={onClose}
      contentContainerClassName="!py-10 overflow-x-hidden"
      content={
        <ModalContent
          currentStep={currentStep}
          tabs={tabs}
          setCurrentStep={setCurrentStep}
          setInstance={setInstance}
          showHeader={isPending}
        />
      }
      contentRef={contentRef}
      footer={
        <div className="flex flex-col-reverse sm:flex-row justify-end items-center gap-4 w-full">
          {tabs[currentStep - 1]?.step === ApplicationModalSteps.ImproveProfile && (
            <Label htmlFor="improvementsApplied" className="mb-0 mr-auto" weight="font-normal">
              <Checkbox id="improvementsApplied" name="improvementsApplied" register={register} />
              I've finished improving the issues above.
            </Label>
          )}
          <TertiaryButton onClick={handleClickBack}>Back</TertiaryButton>
          {isPending && (
            <PrimaryButton
              onClick={tabs[currentStep - 1]?.onNext}
              disabled={Boolean(tabs[currentStep - 1]?.nextDisabled)}
              className="w-full sm:w-fit"
              isLoading={isAnswering || isJobOpportunityRefetching || isSubmitting}
            >
              {tabs[currentStep - 1]?.step === ApplicationModalSteps.ApplicationReview
                ? 'Submit application'
                : 'Next'}
            </PrimaryButton>
          )}
        </div>
      }
    />
  )
}
