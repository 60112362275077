export enum ScreeningAssignmentProgress {
  Assigned = 'assigned',
  Viewed = 'viewed',
  StartedAnswering = 'started_answering',
  Answered = 'answered',
  Submitted = 'submitted',
  Approved = 'approved',
  Rejected = 'rejected',
  Declined = 'declined',
  Expired = 'expired',
  Pending = 'pending',
}

export const screeningAssignmentProgressLabels: Record<ScreeningAssignmentProgress, string> = {
  [ScreeningAssignmentProgress.Declined]: 'Declined',
  [ScreeningAssignmentProgress.Approved]: 'Approved',
  [ScreeningAssignmentProgress.Rejected]: 'Rejected',
  [ScreeningAssignmentProgress.Submitted]: 'Submitted',
  [ScreeningAssignmentProgress.Answered]: 'Answered',
  [ScreeningAssignmentProgress.StartedAnswering]: 'Started Answering',
  [ScreeningAssignmentProgress.Viewed]: 'Viewed',
  [ScreeningAssignmentProgress.Assigned]: 'Assigned',
  [ScreeningAssignmentProgress.Expired]: 'Expired',
  [ScreeningAssignmentProgress.Pending]: 'Pending',
}
