import { useAuth } from 'shared/hooks'
import { useEffect } from 'react'
import { useHelp } from '../useHelp'

import './ChatWidget.scss'

export const ChatWidget = () => {
  const { user } = useAuth()
  const { initializeChatWidget, isChatOpen } = useHelp()

  useEffect(() => {
    if (user.accountId) {
      initializeChatWidget(user)
    }
  }, [user.accountId])

  useEffect(() => {
    document.body.classList.toggle('chat--hidden', !isChatOpen)
  }, [isChatOpen])

  return null
}
