import React, { useContext, useState } from 'react'

import { ClaimProtected, TertiaryButton } from 'shared/components/atoms'
import { DoubleCheckingFlagsModal } from 'candidates-search/components'
import { ActionProps } from '.'

import { ReactComponent as Icon } from 'icons/arrow-square-right.svg'

import { AccountClaim, useAuth } from 'shared/hooks'
import { ResultCardStateContext, SearchContext } from 'candidates-search/contexts'
import { useUpsertMatching } from 'candidates-search/hooks'
import { MatchingStatus } from 'matching/enums'

export const MoveToDoubleChecking = ({ profile }: ActionProps) => {
  const { search } = useContext(SearchContext)
  const { doubleCheckingFlags } = useContext(ResultCardStateContext)

  const {
    user: { claims },
  } = useAuth()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { upsertMatching, updateResultList, isUpserting } = useUpsertMatching(profile.id)

  if (!search?.isListingLinked) return null

  const handleOpenModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    upsertMatching({ status: MatchingStatus.DoubleChecking, preventResultListUpdate: true })

    if (claims.includes(AccountClaim.ManageMatchingFlags)) return setIsModalOpen(true)
    updateResultList()
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    updateResultList()
  }

  return (
    <div>
      <TertiaryButton
        onClick={handleOpenModal}
        icon={<Icon className="!w-6 !h-6" />}
        isIconBefore
        isLoading={isUpserting}
      >
        Double-checking
      </TertiaryButton>
      <ClaimProtected require={AccountClaim.ManageMatchingFlags}>
        <DoubleCheckingFlagsModal
          candidate={profile}
          flags={doubleCheckingFlags}
          onClose={handleCloseModal}
          isOpen={isModalOpen}
          customTitle="Edit Double Checking Flags"
        />
      </ClaimProtected>
    </div>
  )
}
