import { textStyles } from 'shared/components/atoms'
import { ReactNode } from 'react'
import { cn } from 'modules/shared'
import { Text } from '../../Text'

export interface LabelProps {
  id?: string
  htmlFor: string
  children?: ReactNode
  weight?: 'font-normal' | 'font-medium'
  className?: string
  size?: 'text-base' | 'text-xs' | 'text-sm'
  cursor?: 'cursor-pointer'
  isOptional?: boolean
  required?: boolean
  title?: string
  onClick?: () => void
}

export const Label = ({
  id,
  htmlFor,
  children,
  weight = 'font-medium',
  className,
  size = 'text-sm',
  cursor,
  isOptional,
  title,
  required = false,
  onClick,
}: LabelProps) => {
  const classes = cn(
    'flex mb-2 cursor-pointer items-center',
    textStyles(size, weight),
    className,
    cursor,
  )

  return (
    <label id={id} htmlFor={htmlFor} className={classes} title={title} onClick={onClick}>
      {children}
      {required && <span className="text-error-700 font-inherit">&nbsp;*</span>}
      {isOptional && (
        <Text size="text-xs" className="text-neutral-medium ml-4">
          optional
        </Text>
      )}
    </label>
  )
}
