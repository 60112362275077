import { useContext, useState } from 'react'

import { TertiaryButton } from 'shared/components/atoms'
import { ActionProps } from '.'
import { ScreeningDrawer } from 'candidates-search/components'

import { ReactComponent as Icon } from 'icons/arrow-square-right.svg'

import { SearchContext } from 'candidates-search/contexts'

export const MoveToScreening = ({ profile, onClick }: ActionProps & { onClick?: () => void }) => {
  const { search } = useContext(SearchContext)

  const [isScreeningDrawerOpen, setIsScreeningDrawerOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    onClick && onClick()
    setIsScreeningDrawerOpen(true)
  }

  const handleScreeningDrawerClose = () => setIsScreeningDrawerOpen(false)

  if (!search?.isListingLinked) return null
  return (
    <>
      <TertiaryButton onClick={handleClick} icon={<Icon className="!w-6 !h-6" />} isIconBefore>
        Screening
      </TertiaryButton>
      <ScreeningDrawer
        candidate={profile}
        isOpen={isScreeningDrawerOpen}
        onClose={handleScreeningDrawerClose}
      />
    </>
  )
}
