import * as Dialog from '@radix-ui/react-dialog'

import { Title } from 'shared/components/atoms'
import classNames from 'classnames'
import styles from './TermsModal.module.scss'
import { TertiaryButton } from 'shared/components/atoms'

import { ReactComponent as CloseIcon } from 'icons/close.svg'

interface TermsModalProps {
  title: string
  content?: React.ReactElement
  footer?: React.ReactElement
  open?: boolean
  onCancelClick: () => void
}

const TermsModal = ({ title, content, footer, open = true, onCancelClick }: TermsModalProps) => {
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/40 z-50" />
        <Dialog.Content
          className={classNames(
            'fixed top-1/2 lg:left-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[60] \
            w-full h-full lg:max-w-[600px] lg:max-h-[585px] \
            bg-white lg:rounded-2xl \
            flex flex-col',
          )}
        >
          <div
            className={classNames(
              'flex items-center justify-between \
              py-4 px-5 lg:py-[30px] lg:px-10 \
              border-b border-neutral-light',
            )}
          >
            <Title size="heading">{title}</Title>
            <TertiaryButton
              size="md"
              icon={<CloseIcon className="!w-6 !h-6" />}
              onClick={onCancelClick}
            />
          </div>
          <div className={styles.modal__content}>{content}</div>
          <div
            className={classNames(
              'flex grow items-center justify-end \
              gap-4 py-6 px-5 lg:px-10 \
              border-t border-neutral-light',
            )}
          >
            {footer}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export { TermsModal }
