import { Suspense, lazy } from 'react'
import classNames from 'classnames'
import { RHAP_UI } from 'react-h5-audio-player/lib/constants'

import { ReactComponent as PlayIcon } from 'icons/control-play.svg'
import { ReactComponent as PauseIcon } from 'icons/control-pause.svg'

import 'react-h5-audio-player/lib/styles.css'
import './AudioPlayer.scss'

const ReactAudioPlayer = lazy(() => import(/*  webpackPrefetch: true */ 'react-h5-audio-player'))

interface AudioPlayerProps {
  src: string
  onCanPlay: () => void
  onError: () => void
  visible: boolean
}

export const AudioPlayer = ({ src, onCanPlay, onError, visible }: AudioPlayerProps) => {
  return (
    <Suspense fallback={null}>
      <ReactAudioPlayer
        className={classNames({
          '!hidden': !visible,
          '!flex': visible,
        })}
        src={src}
        onCanPlay={onCanPlay}
        showJumpControls={false}
        showSkipControls={false}
        layout="horizontal-reverse"
        customVolumeControls={[]}
        customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
        customIcons={{ play: <PlayIcon />, pause: <PauseIcon /> }}
        onError={onError}
      />
    </Suspense>
  )
}
