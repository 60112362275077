import { Link } from 'shared/components/atoms'
import { DescriptionBlock as DescriptionBlockDomain } from 'candidates-search/domain'
import { BlockMessageLevel, BlockType } from 'candidates-search/enums'
import { cn } from 'modules/shared'
import { ReactComponent as StarIcon } from 'icons/star.svg'

interface DescriptionBlockProps {
  block: DescriptionBlockDomain
}

export const DescriptionBlock = ({ block }: DescriptionBlockProps) => {
  const { type, messageLevel, label } = block

  const bgColor = {
    [BlockMessageLevel.Success]: 'bg-success-lighter',
    [BlockMessageLevel.Warning]: 'bg-warning-lighter',
    [BlockMessageLevel.Info]: 'bg-info-lighter',
    [BlockMessageLevel.Danger]: 'bg-danger-lighter',
    [BlockMessageLevel.Neutral]: 'bg-neutral-lightest',
  } as Record<BlockMessageLevel, string>

  const textColor = {
    [BlockMessageLevel.Success]: 'text-success-dark',
    [BlockMessageLevel.Warning]: 'text-warning-dark',
    [BlockMessageLevel.Info]: 'text-info-dark',
    [BlockMessageLevel.Danger]: 'text-danger-dark',
    [BlockMessageLevel.Neutral]: 'text-neutral-darkest',
  } as Record<BlockMessageLevel, string>

  if (type === BlockType.Label && messageLevel === BlockMessageLevel.Normal) {
    return <>{label}</>
  }

  if (type === BlockType.Badge) {
    return (
      <span
        className={cn(
          'py-1 px-2 rounded-full',
          bgColor[block.messageLevel],
          textColor[block.messageLevel],
        )}
      >
        {block.label}
      </span>
    )
  }

  if (type === BlockType.Label) {
    return <span className={textColor[block.messageLevel]}>{block.label}</span>
  }

  if (type === BlockType.StarBadge) {
    return (
      <span className="flex w-fit gap-1 py-1 px-2 rounded-full bg-warning-lighter">
        <StarIcon className="w-4 h-4 stroke-warning-light fill-warning-light" />
        {block.label}
      </span>
    )
  }

  if (type === BlockType.Anchor && block.redirectUrl) {
    return <Link url={block.redirectUrl}>{block.label}</Link>
  }

  return null
}
