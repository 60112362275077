import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { useEffect } from 'react'
import { ProfileView, useTracking } from 'tracking'

export const ProfileViewTracker = ({ profile }: { profile: ProfileOverview }) => {
  const { trackEvent } = useTracking()

  useEffect(() => {
    trackEvent(new ProfileView(profile.id, profile.interestRoles[0]?.label))
  }, [])

  return null
}
