import { PrimaryButton, TertiaryButton } from 'shared/components/atoms'
import { ReactComponent as ThumbsDownIcon } from 'icons/dislike.svg'
import { ReactComponent as ThumbsUpIcon } from 'icons/like.svg'

interface Props {
  onReject: () => void
  onApprove: () => void
  onClose: () => void
  isPending: boolean
  disableInterested: boolean
}

export const Footer = ({ onReject, onApprove, onClose, isPending, disableInterested }: Props) => {
  return (
    <div className="flex flex-col-reverse sm:flex-row justify-end items-center gap-4 w-full">
      <TertiaryButton
        icon={isPending ? <ThumbsDownIcon /> : undefined}
        onClick={isPending ? onReject : onClose}
        className="w-full sm:w-fit"
      >
        {isPending ? 'Not interested' : 'Close'}
      </TertiaryButton>
      {isPending && (
        <PrimaryButton
          icon={isPending ? <ThumbsUpIcon /> : undefined}
          onClick={onApprove}
          className="w-full sm:w-fit"
          disabled={disableInterested}
        >
          {isPending ? "I'm interested" : 'View application'}
        </PrimaryButton>
      )}
    </div>
  )
}
