import { CompanySize } from 'company/enums'
import { ContractLength, EngagementType } from 'job-listing/enums'
import { EnglishResult } from 'vetting/enums'

export enum Preferences {
  Experience = 'experience',
  MonthlyRate = 'monthlyRate',
  HourlyRate = 'hourlyRate',
  EngagementType = 'engagementType',
  MarketType = 'marketType',
  CompanySize = 'companySize',
  WorkingHours = 'workingHours',
  EnglishLevelB1Applicant = 'englishLevelB1Applicant',
}

export class JobDescription {
  title: string
  minimumTotalExperience: number
  minimumEnglishLevel?: EnglishResult
  engagementType?: EngagementType
  contractDuration?: ContractLength
  workingHoursPerWeek?: number
  requiredSkills: Array<{ id: number; label: string }>
  detailedRequirements: string
  projectDescription?: string
  benefits: string
  processOverview?: string
  marketType: string
  companyName: string
  companyWebsite: string
  companySize: CompanySize
  matchingSkills: Array<{ id: number; label: string }>
  missingSkills: Array<{ id: number; label: string }>
  minimumYearsOfExperience: number
  hiringThroughUs: boolean
  misalignments: Array<{ preference: Preferences; label: string }>

  get misalignedPreferences(): Array<Preferences> {
    return this.misalignments.map(({ preference }) => preference)
  }
}
