import { Link } from 'react-router-dom'
import { Text } from 'shared/components/atoms'
import { PrimaryButton } from 'shared/components/atoms'

import { ReactComponent as SettingsIcon } from 'icons/settings-04.svg'

interface Props {
  jobListingId?: string
  jobListingTitle?: string
}

export const MissingScreening = ({ jobListingId, jobListingTitle }: Props) => {
  return (
    <div className="flex flex-col gap-6 p-6 bg-neutral-lightest rounded-xl">
      <Text weight="font-medium">
        The job listing {jobListingTitle} does not have any screening questions configured.
      </Text>
      <Link to={`/listings/${jobListingId}/screenings`}>
        <PrimaryButton icon={<SettingsIcon />} isIconBefore className="w-full">
          Setup screening questions
        </PrimaryButton>
      </Link>
    </div>
  )
}
