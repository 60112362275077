import { RadioGroup } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'
import { BooleanExpectedAnswer } from 'screening/enums'
import { cn } from 'modules/shared'

interface Props {
  name: string
  formControl: FormControl
  disabled?: boolean
  expectedAnswer?: Nullable<BooleanExpectedAnswer>
  showWarning: boolean
}

export const BooleanQuestion = ({
  name,
  formControl,
  disabled,
  showWarning,
  expectedAnswer,
}: Props) => {
  const { watch, register } = formControl

  const isExpectedAnswer = (option: BooleanExpectedAnswer) => {
    if (!expectedAnswer) return false

    return [option, BooleanExpectedAnswer.Both].includes(expectedAnswer)
  }

  return (
    <RadioGroup
      options={[
        {
          label: 'Yes',
          value: BooleanExpectedAnswer.Yes,
          className: cn({ 'bg-success-lighter': isExpectedAnswer(BooleanExpectedAnswer.Yes) }),
        },
        {
          label: 'No',
          value: BooleanExpectedAnswer.No,
          className: cn({ 'bg-success-lighter': isExpectedAnswer(BooleanExpectedAnswer.No) }),
        },
      ]}
      name={`${name}.value`}
      register={register}
      watch={watch}
      omitErrors
      disabled={disabled}
      showWarning={showWarning}
    />
  )
}
