import { useDate } from 'shared/hooks'
import { JobOpportunity } from 'job-opportunity/domain'
import { JobOpportunityStatus } from 'job-opportunity/enums'

export const useJobOpportunities = () => {
  const { getDifferenceInSeconds } = useDate()

  const getTimeRemainingColors = (
    differenceInSeconds?: number,
  ): {
    progressBarColor:
      | 'bg-success-medium'
      | 'bg-warning-light'
      | 'bg-danger-light'
      | 'bg-neutral-darkest'
    textColor:
      | 'text-success-light'
      | 'text-warning-light'
      | 'text-danger-light'
      | 'text-neutral-darkest'

    itemTextColor:
      | 'text-success-medium'
      | 'text-warning-light'
      | 'text-danger-light'
      | 'text-neutral-darkest'
  } => {
    if (!differenceInSeconds) {
      return {
        progressBarColor: 'bg-neutral-darkest',
        textColor: 'text-neutral-darkest',
        itemTextColor: 'text-neutral-darkest',
      }
    }

    const differenceInHours = differenceInSeconds / (60 * 60)

    if (differenceInHours > 8) {
      return {
        progressBarColor: 'bg-success-medium',
        textColor: 'text-success-light',
        itemTextColor: 'text-success-medium',
      }
    }
    if (differenceInHours > 1) {
      return {
        progressBarColor: 'bg-warning-light',
        textColor: 'text-warning-light',
        itemTextColor: 'text-warning-light',
      }
    }
    return {
      progressBarColor: 'bg-danger-light',
      textColor: 'text-danger-light',
      itemTextColor: 'text-danger-light',
    }
  }

  const getProgressBarPercentage = (initialDate: Date, finalDate: Date, now: Date) => {
    const totalTime = getDifferenceInSeconds(initialDate, finalDate)
    const missingTime = getDifferenceInSeconds(now, finalDate)

    return (missingTime * 100) / totalTime
  }

  const getJobOpportunityClosestToDeadline = (jobOpportunities: JobOpportunity[]) => {
    if (!jobOpportunities.length) return undefined

    const openJobOpportunies = jobOpportunities.filter(
      (jobOpportunity) =>
        jobOpportunity.status === JobOpportunityStatus.Pending &&
        getDifferenceInSeconds(new Date(), jobOpportunity.deadlineAt) > 0,
    )

    const openOpportunitiesSortedByDeadline = openJobOpportunies.sort(
      (prevOpportunity, nextOpportunity) =>
        new Date(prevOpportunity.deadlineAt).getTime() -
        new Date(nextOpportunity.deadlineAt).getTime(),
    )

    return openOpportunitiesSortedByDeadline[0]
  }

  return {
    getTimeRemainingColors,
    getProgressBarPercentage,
    getJobOpportunityClosestToDeadline,
  }
}
