import { useAuth } from 'shared/hooks'
import { Outlet, useNavigate } from 'react-router-dom'

export const StriderRoutes = () => {
  const navigate = useNavigate()
  const {
    user: { accountId, email },
  } = useAuth()

  if (email.endsWith('@onstrider.com')) return <Outlet />
  if (accountId) navigate('/')
  return null
}
