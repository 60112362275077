import { InfiniteScroll } from 'shared/components/atoms'
import { SecondaryButton } from 'shared/components/atoms'
import { InstructionsModal } from '../InstructionsModal'
import { Row } from './Row'

import { useTimeline } from '../../hooks/useTimeline'

import { TimelineItemType } from 'modules/referrals/enum'
import { Recruiter } from 'modules/recruiter'

export const Timeline = ({ recruiter }: { recruiter?: Recruiter }) => {
  const timeline = useTimeline()

  return (
    <InfiniteScroll dataLength={0} onFetchMore={() => {}} hasMore={false} isFirstPage>
      <table className="md:table-fixed text-left mt-10">
        <thead>
          <tr>
            <th className="min-w-[300px] md:w-full" />
            <th className="min-w-[150px] md:min-w-[240px]" />
            <th className="min-w-[160px]" />
          </tr>
        </thead>
        <tbody>
          {timeline.map((item, index) => (
            <Row key={index} item={item} index={index} />
          ))}
          <Row
            item={{
              type: TimelineItemType.Joined,
              description: 'Welcome to Strider Refer',
              date: new Date(),
            }}
            index={timeline.length}
            action={
              <InstructionsModal recruiter={recruiter}>
                <SecondaryButton size="sm">Show me how it works</SecondaryButton>
              </InstructionsModal>
            }
          />
        </tbody>
      </table>
    </InfiniteScroll>
  )
}
