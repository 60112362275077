import { WorkExperience } from 'modules/work-experience'

export const useProfileWorkExperiences = (workExperiences: Array<WorkExperience>) => {
  return workExperiences.reduce(
    (obj, workExperience) => {
      obj[workExperience.id] = workExperience
      return obj
    },
    {} as { [key: string]: WorkExperience },
  )
}
