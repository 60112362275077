import classNames from 'classnames'

import { Text, Slider, Label, Paragraph } from 'shared/components/atoms'
import { Radio } from 'shared/components/atoms'
import { RadioGroup } from 'shared/components/atoms'

import { useEngagementPreferences } from 'hooks/engagementPreferences'
import { FormControl } from 'shared/hooks'

import { EngagementType } from 'job-listing/enums'
import { contractLengthOptions } from './options'

interface ContractProps {
  name: string
  contractDurationName: string
  workingHoursPerWeekName: string
  formControl: FormControl
  className?: string
}

export const Contract = ({
  name,
  contractDurationName,
  workingHoursPerWeekName,
  formControl,
  className,
}: ContractProps) => {
  const { register, watch, setValue } = formControl
  const { workingHoursOptions } = useEngagementPreferences()

  const engagementType = watch(name)
  const isShortSelected = engagementType === EngagementType.ShortTerm

  return (
    <div className={classNames('flex flex-col gap-6', className)}>
      <div className="flex flex-col gap-2">
        <Text size="text-base" weight="font-medium" className="text-neutral-darkest">
          Engagement type
        </Text>
        <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark">
          Tell us how long you intend to hire so we can align expectations with candidates.
        </Paragraph>
      </div>

      <Label htmlFor={`${name}-long`} className="flex flex-row items-start gap-2">
        <Radio
          register={register}
          id={`${name}-long`}
          name={name}
          value={EngagementType.LongTerm}
        />
        <div className="flex flex-col gap-2">
          <Text size="text-sm" weight="font-medium" className="text-neutral-darkest">
            Long-term engagement / team member
          </Text>
          <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
            Strider developers work full-time, dedicated and embedded on your team.
          </Text>
        </div>
      </Label>

      <div className="flex flex-col gap-4">
        <Label htmlFor={`${name}-short`} className="flex flex-row items-start gap-2">
          <Radio
            register={register}
            id={`${name}-short`}
            name={name}
            value={EngagementType.ShortTerm}
          />
          <div className="flex flex-col gap-2">
            <Text size="text-sm" weight="font-medium" className="text-neutral-darkest">
              Short-term engagement / freelance
            </Text>
            <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
              Strider developers work on short-term projects either full-time or freelancing.
            </Text>
          </div>
        </Label>

        {isShortSelected && (
          <div className="pl-9 -mb-9">
            <RadioGroup
              register={register}
              watch={watch}
              name={contractDurationName}
              options={contractLengthOptions}
            />
          </div>
        )}
      </div>

      {isShortSelected && (
        <div className="flex flex-col gap-4 pl-9 pt-4">
          <Text size="text-sm" weight="font-medium" className="text-neutral-darkest">
            Working hours
          </Text>
          <Slider
            name={workingHoursPerWeekName}
            watch={watch}
            setFormValue={setValue}
            options={workingHoursOptions}
            isRangeSlider={false}
            updatePositionOnWatch
          />
        </div>
      )}
    </div>
  )
}
