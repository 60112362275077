import { TrackingEvent } from '../tracking-event'

export class JobOpportunitiesDropdownImpression implements TrackingEvent {
  name = 'Job opportunity dropdown impression'

  constructor() {}

  get payload() {
    return {}
  }
}
