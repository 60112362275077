import { useMonitoring, useNetwork, useToast } from 'shared/hooks'
import { FetchCompanyInfoDTO, FetchCompanyInfoResponse } from 'company/dtos'
import { useQuery } from 'react-query'

export const useFetchCompanyInfo = () => {
  const { toastError } = useToast()
  const { get } = useNetwork()
  const { captureException } = useMonitoring()

  const { data, isLoading, isRefetching } = useQuery(
    'company-info',
    () =>
      get<FetchCompanyInfoResponse>('company/info').then(({ data }) =>
        new FetchCompanyInfoDTO(data).toDomain(),
      ),
    {
      retry: 3,
      onError: (error: RequestError) => {
        toastError('Error when trying to retrieve company information')
        captureException(error)
      },
    },
  )

  return { company: data, isLoading: isLoading || isRefetching }
}
