import ReCAPTCHA from 'react-google-recaptcha'

import { GradientSpinner } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { useReferralLink } from './useReferralLink'
import { useEffect, useRef, useState } from 'react'
import { useToast } from 'shared/hooks'
import classNames from 'classnames'

import { ReactComponent as Logo } from 'icons/brand/brand-colored-positive.svg'
import backgroundImage from '../../../../icons/refer/ellipses.png'

interface ReferrerCardProps {
  referrerSlug: string
  onToken: (token: string) => void
}

const DEFAULT_PICTURE =
  'https://res.cloudinary.com/onstrider/image/upload/f_auto,q_auto/v1/default/missing-photo_skcszi'
const recaptchaSiteKey = String(process.env.REACT_APP_RECAPTCHA_SITE_KEY)
const REDIRECT_MESSAGE_CHANGE_TIME = 15 * 1000

export const ReferrerCard = ({ referrerSlug, onToken }: ReferrerCardProps) => {
  const { toastError } = useToast()
  const reCaptchaRef = useRef<ReCAPTCHA>(null)
  const [token, setToken] = useState('')
  const [redirectMessage, setRedirectMessage] = useState('We are redirecting you to the platform')
  const { referralLink } = useReferralLink(referrerSlug, token)
  const photoUrl = `${referralLink.photoUrl}?s=256&d=${DEFAULT_PICTURE}`

  useEffect(() => {
    const timer = setTimeout(
      () => setRedirectMessage('It is taking more time than expected. Hold on tight'),
      REDIRECT_MESSAGE_CHANGE_TIME,
    )

    reCaptchaRef.current?.executeAsync().then((token) => {
      if (!token) {
        return toastError('Unexpected error. Please reload the page and try again.')
      }

      onToken(token)
      setToken(token)
    })

    return () => clearTimeout(timer)
  }, [])

  const backgroundImageUrl = referralLink.photoUrl ? backgroundImage : ''

  return (
    <div className="flex flex-col h-screen w-full">
      <div
        className={classNames(
          'flex flex-col h-screen w-full gap-10 items-center justify-center relative bg-no-repeat transition-all duration-200 bg-[length:0_0] md:bg-auto',
        )}
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundPositionX: 'center',
          backgroundPositionY: 'calc(50% - 38px)',
        }}
      >
        <ReCAPTCHA sitekey={recaptchaSiteKey} size="invisible" ref={reCaptchaRef} />
        <div className="flex flex-col w-full items-center justify-center gap-4">
          <Logo className="h-7 w-auto" />
          <GradientSpinner
            from="from-white"
            to="to-[#7E8E9D]"
            className={classNames('h-4 w-4', {
              hidden: referralLink.photoUrl,
            })}
            innerClassName="h-3 w-3 bg-neutral-day"
          />
        </div>
        <div
          className={classNames(
            'flex flex-col w-full gap-10 items-center justify-center transition-all duration-500 opacity-0 max-h-0 overflow-hidden',
            {
              'max-h-96 overflow-visible opacity-100': referralLink.photoUrl,
            },
          )}
        >
          <div className="relative">
            {Boolean(referralLink.photoUrl) && (
              <img
                src={photoUrl}
                className="
                  h-32 w-32 after:content-[''] m-0 rounded-full
                  after:z-[-1] after:top-1/2 after:left-1/2 after:transform after:-translate-x-1/2 after:-translate-y-1/2
                  after:bg-ellipse after:bg-no-repeat after:bg-center after:w-[600px] after:h-[600px] after:absolute
                "
              />
            )}
          </div>

          <div className="flex flex-col gap-1 items-center">
            <Text size="text-xl" weight="font-medium" className="w-full text-center">
              {referralLink.fullName} &nbsp;
            </Text>
            <Text
              size="text-lg"
              weight="font-medium"
              className="w-full text-center text-neutral-dark"
            >
              has referred you to Strider
            </Text>
          </div>
          <div className="flex gap-2 bg-neutral-lightest py-3 px-5 rounded-[100px] items-center">
            <GradientSpinner
              from="from-white"
              to="to-[#7E8E9D]"
              className="h-4 w-4"
              innerClassName="h-3 w-3 bg-neutral-lightest"
            />
            <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
              {redirectMessage}
            </Text>
          </div>
        </div>
      </div>
      <div className="flex gap-1 text-[10px] self-end p-1">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </div>
  )
}
