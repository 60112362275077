import classNames from 'classnames'
import { Label } from 'shared/components/atoms'
import { Title } from 'shared/components/atoms'
import { ReactNode } from 'react'

export const Wrapper = ({
  title,
  label,
  id,
  children,
  className,
}: {
  title?: string
  label?: string | ReactNode
  id?: string
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div className={classNames('mb-12', className)}>
      {title && (
        <Title size="micro-heading" className="mb-2">
          {title}
        </Title>
      )}
      {label && (
        <Label htmlFor={id || ''} size="text-sm">
          {label}
        </Label>
      )}
      {children}
    </div>
  )
}
