import { TrackingEvent } from '../tracking-event'

export class PayRateModalImpression implements TrackingEvent {
  name = 'Pay rate modal impression'

  constructor(
    private id: string,
    private primaryRole: Optional<string>,
    private readonly isDirectAccess: boolean,
  ) {}

  get payload() {
    return {
      primaryRole: this.primaryRole || 'N/A',
      id: this.id,
      isDirectAccess: this.isDirectAccess,
    }
  }
}
