import { Text } from 'shared/components/atoms'

interface CandidatePhotoProps {
  name: string
  role: string
  photo: string
}

export const CandidatePhoto = ({ name, role, photo }: CandidatePhotoProps) => {
  return (
    <div key={name} className="shrink-0 w-[256.5px] h-[320px] relative">
      <img src={photo} className="absolute inset-0 rounded-lg" alt={name} />

      <div
        className={`
          absolute left-0 right-0 bottom-0 
          h-[98px] py-4 px-6 rounded-b-lg
          flex flex-col justify-center gap-1
        `}
        style={{
          background:
            'linear-gradient(180.17deg, rgba(0, 0, 0, 0) 2.95%, rgba(0, 0, 0, 0.983164) 66.9%, #000000 99.85%)',
        }}
      >
        <Text size="text-base" className="text-neutral-day">
          {name}
        </Text>
        <Text size="text-sm" className="text-neutral-400">
          {role}
        </Text>
      </div>
    </div>
  )
}
