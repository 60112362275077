import classNames from 'classnames'
import { useStyledIcon } from 'shared/hooks'
import { ButtonHTMLAttributes, forwardRef, Ref } from 'react'
import { Loading } from '../Loading'

interface PrimaryButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
  type?: 'button' | 'submit' | 'reset'
  form?: string
  size?: 'sm' | 'md' | 'lg' | 'xl'
  className?: string
  onClick?: (() => void) | ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void)
  icon?: React.ReactElement
  isIconBefore?: boolean
  isLoading?: boolean
  disabled?: boolean
  autoFocus?: boolean
}

export const PrimaryButton = forwardRef(function PrimaryButton(
  {
    children,
    type = 'button',
    form,
    size = 'md',
    className,
    onClick,
    icon,
    isIconBefore,
    isLoading,
    disabled,
    autoFocus,
    ...restProps
  }: PrimaryButtonProps,
  ref: Ref<HTMLButtonElement>,
) {
  const { styleIcon } = useStyledIcon(size, icon)

  const content = isIconBefore ? (
    <>
      {styleIcon}
      {children}
    </>
  ) : (
    <>
      {children}
      {styleIcon}
    </>
  )

  // @todo make the style definition simpler and remove the size check repetition
  return (
    <button
      className={classNames(
        'flex flex-row items-center justify-center relative',
        'rounded-[4px]',
        'font-normal tracking-tight print:hidden whitespace-nowrap',
        {
          'text-neutral-day stroke-neutral-day bg-neutral-darkest active:bg-neutral-dark active:!shadow-none \
          hover:bg-neutral-darker transition duration-150 \
          focus:shadow-[0_0_0_4px_#99AEFF]': !isLoading,
          'bg-neutral-dark text-transparent stroke-transparent': isLoading,
          '!bg-neutral-darkest-0.48': disabled,
        },
        !!children && {
          'h-8 px-3 text-sm gap-2': size === 'sm',
          'h-10 px-4 text-sm gap-2': size === 'md',
          'h-12 px-6 text-base gap-3': size === 'lg',
          'h-16 px-6 text-xl gap-3': size === 'xl',
        },
        !children && {
          'h-8 w-8 flex-[0_0_32px]': size === 'sm',
          'h-10 w-10 flex-[0_0_40px]': size === 'md',
          'h-12 w-12 flex-[0_0_48px]': size === 'lg',
          'h-16 w-16 flex-[0_0_64px]': size === 'xl',
        },
        className,
      )}
      onClick={onClick}
      type={type}
      form={form}
      disabled={disabled || isLoading}
      ref={ref}
      autoFocus={autoFocus}
      {...restProps}
    >
      {isLoading && <Loading size={size} bgColor="bg-neutral-dark" />}
      {content}
    </button>
  )
})
