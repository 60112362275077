import { PaymentModel } from 'job-listing/enums'
import { JobOpportunityStatus } from 'job-opportunity/enums'
import { JobDescription } from './job-description.domain'
import { QuestionSchema } from 'screening/domain'
import { Answer } from './answer.domain'
import { Type } from 'class-transformer'

export class JobOpportunity {
  id: string
  createdAt: Date
  deadlineAt: Date
  rejectedAt: Date | null
  firstViewedAt: Date | null
  startedFillingAt: Date | null
  status: JobOpportunityStatus
  screeningAnswers: Array<Answer> | null
  profileImprovements: string | null
  submittedAt: Date | null
  declinedAt: Date | null
  compensation: number | null
  isApplication: boolean
  screening: {
    id: string
    minimumPayRate: number | null
    maximumPayRate: number | null
    questions: Array<QuestionSchema>
    paymentModel: PaymentModel
  }

  @Type(() => JobDescription)
  jobDescription: JobDescription

  get isPending() {
    return this.status === JobOpportunityStatus.Pending
  }

  get isOpen() {
    return [
      JobOpportunityStatus.Pending,
      JobOpportunityStatus.AwaitingReview,
      JobOpportunityStatus.InProgress,
    ].includes(this.status)
  }

  get isApplied() {
    return [JobOpportunityStatus.AwaitingReview, JobOpportunityStatus.InProgress].includes(
      this.status,
    )
  }

  get isClosed() {
    return [
      JobOpportunityStatus.Closed,
      JobOpportunityStatus.Expired,
      JobOpportunityStatus.NotInterested,
      JobOpportunityStatus.NotPicked,
    ].includes(this.status)
  }
}
