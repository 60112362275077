const MAX_FILE_LENGTH_MBS = 1

export class ProfilePictureUpload {
  constructor(public readonly file: Blob | null = null) {}

  static fromElement(input: EventTarget & HTMLInputElement) {
    const file = input.files && input.files[0]
    return new ProfilePictureUpload(file)
  }

  static fromFile(file: File | null) {
    return new ProfilePictureUpload(file)
  }

  get tooBig() {
    if (!this.file) return false

    const mbSize = this.file.size / 1024 / 1024

    return mbSize > MAX_FILE_LENGTH_MBS
  }

  get empty() {
    return !this.file
  }
}
