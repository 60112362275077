import { useMutation, useQueryClient } from 'react-query'
import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { useLandingTracking } from 'shared/hooks'
import {
  CreateRecruiterDTO,
  CreateRecruiterValues,
  CreateSeekerRecruiterDTO,
  CreateSeekerRecruiterValues,
} from 'modules/recruiter'
import { SignUp, useTracking } from 'tracking'
import { useState } from 'react'
import { useMonitoring } from 'shared/hooks'

export const useCreateRecruiter = (showError = true) => {
  const { getLandingLocation } = useLandingTracking()
  const { post } = useNetwork()
  const { toastError } = useToast()
  const client = useQueryClient()
  const { dataLayer, trackEvent } = useTracking()

  const { captureException } = useMonitoring()

  const [unprocessableError, setUnprocessableError] = useState(false)

  const { mutateAsync: create, isLoading: isCreating } = useMutation(
    (values: CreateRecruiterValues) => {
      const landingLocation = getLandingLocation()
      const queryString = landingLocation.substring(landingLocation.indexOf('?'))
      const searchParams = new URLSearchParams(queryString)

      return post('/onboarding/recruiter', new CreateRecruiterDTO(values, searchParams).toJSON())
    },
    {
      onSuccess: () => {
        client.invalidateQueries('recruiter')
        client.invalidateQueries('accounts/me')
        dataLayer.push({ event: 'sign_up' })
        trackEvent(new SignUp())
      },
      onError: (error: RequestError) => {
        toastError(error.response?.data.message)
        captureException(error)
      },
    },
  )

  const { mutateAsync: createSeekerRecruiter, isLoading: isSeekerCreating } = useMutation(
    (values: CreateSeekerRecruiterValues | null) =>
      post('/recruiter/job-seeker', new CreateSeekerRecruiterDTO(values).toJSON()),
    {
      retry: false,
      onSuccess: () => {
        setUnprocessableError(false)
        client.invalidateQueries('recruiter')
      },
      onError: (error: RequestError) => {
        if (error.response?.status === 422) setUnprocessableError(true)
        if (showError) {
          toastError(error.response?.data.message)
          captureException(error)
        }
      },
    },
  )

  return {
    create,
    createSeekerRecruiter,
    isCreating: isCreating || isSeekerCreating,
    unprocessableError,
  }
}
