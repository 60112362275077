import { Collection, CollectionItem } from 'shared/components/atoms'
import { useAuth } from 'shared/hooks'
import { useHelp } from 'modules/help'
import { REFERRAL_ARTICLES_IDS } from 'pages/Refer/consts'

export const Handbook = () => {
  const {
    user: { isRecruiter },
  } = useAuth()

  const { showArticle } = useHelp()

  const list = [
    {
      title: 'Getting started & referring talent',
      description: 'Learn how to set up your account and start referring talent.',
      visible: isRecruiter,
      callback: () => showArticle(REFERRAL_ARTICLES_IDS.GettingStarted),
    },
    {
      title: 'Guidelines',
      description:
        'Understand the rules and guidelines around submitting candidates and earning through Refer.',
      visible: isRecruiter,
      callback: () => showArticle(REFERRAL_ARTICLES_IDS.Guidelines),
    },
    {
      title: 'Strider process for talent',
      description:
        "Understand what the talent you refer sees when going through Strider's process.",
      visible: true,
      callback: () => showArticle(REFERRAL_ARTICLES_IDS.Process),
    },
    {
      title: 'Fee schedule & payment details',
      description: 'All about how much you can earn and how payouts work.',
      visible: true,
      callback: () => showArticle(REFERRAL_ARTICLES_IDS.FeeSchedule),
    },
    {
      title: 'FAQ',
      description: 'Frequently asked questions about Refer.',
      visible: true,
      callback: () => {
        window.open('https://help.onstrider.com/en/collections/3946146-faq', '_blank')
      },
    },
  ] as Array<{ title: string; description: string; visible: boolean; callback: () => void }>

  return (
    <Collection>
      {list
        .filter((item) => item.visible)
        .map((item) => (
          <CollectionItem
            key={item.title}
            title={item.title}
            description={item.description}
            onClick={item.callback}
          />
        ))}
    </Collection>
  )
}
