import { useContext } from 'react'

import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { TertiaryButton } from 'shared/components/atoms'
import { Card } from '../Card'

import { ReactComponent as AssessmentIcon } from 'icons/layout-alt-02.svg'
import { ReactComponent as ChevronRight } from 'icons/chevron-right.svg'

import { CandidateContext } from 'contexts/candidate'
import { Assessment } from 'modules/candidates/domain'

export const AssessmentsCard = () => {
  const { candidate } = useContext(CandidateContext)

  const handleShowAssessment = (assessment: Assessment) => {
    if (!assessment?.url) return
    window.open(assessment!.url, '_blank')
  }

  const handleAssessmentRetake = () => {
    //@todo: handle action - https://onstrider.atlassian.net/browse/STR-1487
  }

  if (!candidate.assessments.length) return null
  return (
    <Card className="flex flex-col items-stretch justify-center">
      <div className="flex flex-row items-center justify-between">
        <Text size="text-xl" weight="font-medium" className="text-neutral-darkest">
          Strider Technical Assessment
        </Text>

        <TertiaryButton size="sm" onClick={handleAssessmentRetake} className="hidden">
          Retake assessment
        </TertiaryButton>
      </div>

      <ul>
        {candidate.assessments.map((assessment, index) => (
          <li
            key={index}
            className="flex flex-row items-center justify-between gap-4 p-6 mt-4 border border-neutral-lighter rounded-lg hover:cursor-pointer"
            onClick={() => handleShowAssessment(assessment)}
          >
            <div className="flex flex-row items-center gap-4">
              <div className="w-12 h-12 bg-neutral-lightest rounded-full p-3">
                <AssessmentIcon className="w-6 h-6 stroke-neutral-medium" />
              </div>
              <div>
                <Paragraph size="body-xs" weight="font-normal" className="text-neutral-dark block">
                  {assessment.domain}
                </Paragraph>
                <Text size="text-base" weight="font-medium" className="text-neutral-darkest block">
                  {assessment.passed ? 'Pass' : 'Fail'}
                </Text>
              </div>
            </div>
            <ChevronRight className="w-6 h-6 stroke-neutral-darkest" />
          </li>
        ))}
      </ul>
    </Card>
  )
}
