import { useEffect } from 'react'

import { SecondaryButton } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { Paragraph } from 'shared/components/atoms'
import { Card } from '../Card'

import { ReactComponent as CoinsStackedIcon } from 'icons/refer/coins-stacked.svg'
import { ReactComponent as LinkIcon } from 'icons/advanced-search/link-01.svg'

import { ReferBannerImpression, ReferBannerClick, useTracking } from 'tracking'
import { useRecruiter } from 'hooks/recruiter'
import { useSlug } from 'hooks/referrals'
import { useFormatting } from 'shared/hooks'
import { Link } from 'react-router-dom'
import { REFER_BONUS } from 'modules/referrals/consts'

export const ReferCard = () => {
  const { formatNumberByLanguage } = useFormatting()
  const { trackEvent } = useTracking()
  const { recruiter } = useRecruiter()
  const { handleCopyToClipboard } = useSlug(recruiter.slug || '', 'refer')

  const handleClick = () => {
    trackEvent(new ReferBannerClick())
    handleCopyToClipboard()
  }

  useEffect(() => {
    trackEvent(new ReferBannerImpression())
  }, [])

  return (
    <Card className="flex flex-col md:flex-row items-center gap-6" borderOnMobile>
      <div className="hidden md:flex items-center justify-center w-12 h-12 rounded-full bg-warning-lighter">
        <CoinsStackedIcon className="flex-shrink-0" />
      </div>
      <div className="flex flex-col gap-2 md:gap-0 mr-auto">
        <Text size="text-base" weight="font-medium" className="text-neutral-darker">
          Earn up to ${formatNumberByLanguage(REFER_BONUS)} USD per referral
        </Text>
        <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark">
          Refer developers to Strider and get paid when they get hired.
        </Paragraph>
      </div>
      {recruiter.slug ? (
        <SecondaryButton
          className="w-full md:w-fit"
          icon={<LinkIcon className="stroke-neutral-darker" />}
          isIconBefore
          onClick={handleClick}
        >
          Copy Refer link
        </SecondaryButton>
      ) : (
        <Link to="/refer" className="w-full md:w-fit">
          <SecondaryButton className="w-full">Explore Refer</SecondaryButton>
        </Link>
      )}
    </Card>
  )
}
