import { ListingSettings } from 'job-listing/domains'
import { AnySchema, boolean, object, string } from 'yup'

export { ListingSettingsModal } from './ListingSettingsModal'

export const schema = object<Record<keyof ListingSettings, AnySchema>>().shape({
  jobOffersEnabled: boolean(),
  hiringCostsVisible: boolean(),
  hourlyRatesEnabled: boolean(),
  matchingsChecklist: string(),
  internalNotes: string(),
  isTalentReview: boolean(),
})
