import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { Tasks as TasksEnum } from 'modules/candidates/enums'
import compact from 'lodash/compact'
import { JobOfferState } from 'modules/offers/enums'
import { VettingPackageResponse } from 'vetting/domain'
import { InterviewResponse, InterviewType } from '.'

export class Tasks {
  constructor(
    private readonly profile: ProfileOverview,
    private readonly pendingInterviews: Array<InterviewResponse>,
    private readonly vetting?: Nullable<VettingPackageResponse>,
  ) {}

  get pendingTasks() {
    return compact([
      this.completeProfileTask,
      this.assessmentTask,
      this.offerTask,
      this.englishVetting,
      this.liveInterview,
    ])
  }

  get pendingOffers() {
    const { offers } = this.profile
    return offers.filter(({ state }) => state === JobOfferState.NotAccepted)
  }

  get pendingLiveInterview() {
    return this.pendingInterviews.find(
      (interview) => interview.type === InterviewType.StriderOnboarding,
    )
  }

  private get completeProfileTask() {
    if (this.profile.fulfillment.percentage < 100) return TasksEnum.CompleteProfile
  }

  private get assessmentTask() {
    const { enableTechnicalAssessmentOptionsViewing, assessments } = this.profile
    if (enableTechnicalAssessmentOptionsViewing && !assessments.length) return TasksEnum.Assessment
  }

  private get offerTask() {
    if (this.pendingOffers.length) return TasksEnum.Offer
  }

  private get englishVetting() {
    if (!this.vetting && this.profile.fulfillment.percentage === 100)
      return TasksEnum.EnglishVetting
  }

  private get liveInterview() {
    if (this.pendingLiveInterview) return TasksEnum.LiveInterview
  }
}
