export const isPersonalEmail = async (email: string) => {
  const normalizedEmail = email.toLowerCase()

  const { freeEmailDomains } = await import(
    /*  webpackPrefetch: true */
    'free-email-domains-typescript'
  )

  return freeEmailDomains.some((domain) => normalizedEmail.endsWith(domain))
}
