import { useUnauthenticatedNetwork } from 'shared/hooks'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

export const useProfileRoles = (usedRoles: Array<{ id: number; label: string }>) => {
  const { get } = useUnauthenticatedNetwork()

  const { data: profileRoles = [], refetch: getProfileRoles } = useQuery(
    'profile/profile-roles',
    async () =>
      await get<Array<{ id: number; label: string }>>('profile/profile-roles').then(
        ({ data }) => data,
      ),
  )

  const roleOptions = useMemo(
    () => profileRoles.filter(({ id }) => !usedRoles.find(({ id: roleId }) => id === roleId)),
    [usedRoles, profileRoles],
  )

  return { roleOptions, getProfileRoles }
}
