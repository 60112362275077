import { EnglishLevel } from 'modules/candidates/enums'
import { EnglishResult, ReviewIssue, ReviewStatus } from 'vetting/enums'

class EvaluatorProfile {
  firstName?: string
  lastName?: string
}

class Evaluator {
  accountId: string
  email: string
  profile?: EvaluatorProfile
}

class CandidateProfile {
  id: string
  englishLevel: EnglishLevel
  firstName: string
  lastName: string
  photoUrl: string
  numberOfReviews: number
}

class EnglishPackage {
  createdAt: string
  profile: CandidateProfile
}

export class EnglishPackageEvaluationPreview {
  evaluator?: Evaluator
  id: string
  issues: Array<ReviewIssue>
  package: EnglishPackage
  reviewedAt?: string
  level?: EnglishResult
  status: ReviewStatus
  prevStatus?: ReviewStatus

  constructor(entity: Partial<EnglishPackageEvaluationPreview>) {
    Object.assign(this, entity)
  }

  get shouldBeFiltered() {
    const shouldBeFiltered = {
      [ReviewStatus.ToDo]: this.status === ReviewStatus.Paused,
      [ReviewStatus.Prioritized]: this.status === ReviewStatus.Paused,
      [ReviewStatus.Reviewed]:
        this.status === ReviewStatus.Paused ||
        this.status === ReviewStatus.ToDo ||
        this.status === ReviewStatus.Approved,
      [ReviewStatus.Approved]: this.status === ReviewStatus.ReviewAgain,
      [ReviewStatus.Paused]:
        this.status === ReviewStatus.ToDo || this.status === ReviewStatus.Reviewed,
    } as Record<ReviewStatus, boolean>

    return shouldBeFiltered[this.prevStatus as ReviewStatus] || false
  }

  get evaluatorName() {
    return this.evaluator?.profile?.firstName && this.evaluator?.profile?.lastName
      ? `${this.evaluator?.profile?.firstName} ${this.evaluator?.profile?.lastName}`
      : this.evaluator?.email
  }

  transitToPrioritized() {
    this.status = ReviewStatus.Prioritized
  }

  transitToTodo() {
    this.prevStatus = this.status
    this.status = ReviewStatus.ToDo
  }

  transitToReviewAgain() {
    this.prevStatus = this.status
    this.status = ReviewStatus.ReviewAgain
  }

  transitToApproved() {
    this.prevStatus = this.status
    this.status = ReviewStatus.Approved
  }

  transitToPaused() {
    this.prevStatus = this.status
    this.status = ReviewStatus.Paused
  }

  transitToUnpaused() {
    this.prevStatus = this.status
    if (this.issues.length) return (this.status = ReviewStatus.Reviewed)
    this.status = ReviewStatus.ToDo
  }
}
