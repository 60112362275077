export enum Country {
  Brazil = 'BR',
  LocalizedBrazil = 'Brasil',
  InternationalBrazil = 'Brazil',

  Argentina = 'AR',
  LocalizedArgentina = 'Argentina',

  Colombia = 'CO',
  LocalizedColombia = 'Colombia',

  Mexico = 'MX',
  LocalizedMexico = 'México',
  InternationalMexico = 'Mexico',

  Unknown = 'Unknown',
}

export class CountryCode {
  static Brazil = [Country.Brazil, Country.InternationalBrazil, Country.LocalizedBrazil]
  static Argentina = [Country.Argentina, Country.LocalizedArgentina]
  static Colombia = [Country.Colombia, Country.LocalizedColombia]
  static Mexico = [Country.Mexico, Country.LocalizedMexico, Country.InternationalMexico]
}
