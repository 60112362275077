import React, { useContext } from 'react'

import { TertiaryButton } from 'shared/components/atoms'
import { ActionProps } from '.'

import { ReactComponent as HideIcon } from 'icons/eye-off.svg'

import { useUpsertMatching } from 'candidates-search/hooks'
import { SearchContext } from 'candidates-search/contexts'
import { MatchingStatus } from 'matching/enums'

export const Hide = ({ profile }: ActionProps) => {
  const { search } = useContext(SearchContext)
  const { upsertMatching, isUpserting } = useUpsertMatching(profile.id)

  if (!search?.isListingLinked) return null

  const handleHide = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    upsertMatching({ status: MatchingStatus.NotAFit })
  }

  return (
    <TertiaryButton
      onClick={handleHide}
      icon={<HideIcon className="!w-6 !h-6" />}
      isIconBefore
      isLoading={isUpserting}
    >
      Hide
    </TertiaryButton>
  )
}
