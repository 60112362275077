import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { useNetwork } from 'shared/hooks'
import { ChangeLog } from 'candidates-search/domain'
import { TimelineFilterValues } from 'candidates-search/domain'
import { FetchTimelineDTO } from 'candidates-search/dtos'
import { plainToInstance } from 'class-transformer'

const PAGE_SIZE = 30

interface Props {
  profileId: string
  filter: Optional<TimelineFilterValues>
  page: number
  enabled: boolean
}

const defaultData = {
  items: [],
  hasMore: false,
}

export const useFetchTimeline = ({ profileId, page, enabled, filter }: Props) => {
  const { post } = useNetwork()

  const [timelineItems, setTimelineItems] = useState<Array<ChangeLog>>([])
  const {
    data = defaultData,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery(
    ['timeline', profileId],
    () => {
      if (!filter) return

      const payload = new FetchTimelineDTO({
        profileId,
        filter,
        page,
        pageSize: PAGE_SIZE,
      }).toJSON()

      if (!payload.eventTypes?.length) return { items: [], hasMore: false }

      return post<{ items: Array<ChangeLog>; hasMore: boolean }>(`/change-logs`, payload).then(
        ({ data }) => ({ items: plainToInstance(ChangeLog, data.items), hasMore: data.hasMore }),
      )
    },
    { enabled: Boolean(profileId) && enabled },
  )

  useEffect(() => {
    if (!data) return

    if (!page) setTimelineItems([])
    setTimelineItems((prev) => [...prev, ...data.items])
  }, [data])

  return {
    timelineItems,
    isLoading: isLoading || isRefetching,
    hasMore: Boolean(data.hasMore),
    refetch,
  }
}
