import { lazy, Suspense, useState } from 'react'
import classNames from 'classnames'

import { TertiaryButton } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

const LogoFrame = lazy(() =>
  import('icons/logo-frame--with-background.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface ProfileUpdatedBannerProps {
  onDimiss: () => void
}

export const ProfileUpdatedBanner = ({ onDimiss }: ProfileUpdatedBannerProps) => {
  const [hide, setHide] = useState(false)

  const handleDimiss = () => {
    setHide(true)
    onDimiss()
  }
  return (
    <div
      className={classNames(
        'flex flex-col items-center w-full mb-6 sm:gap-4 sm:flex-row',
        'transition-max-h duration-200 ease-in-out overflow-y-hidden',
        hide ? 'max-h-0' : 'max-h-52',
      )}
    >
      <div className="flex flex-grow items-center gap-3 py-2 px-4 bg-brand-lighter rounded-lg">
        <Suspense fallback={null}>
          <LogoFrame className="w-8 h-8 shrink-0" />
        </Suspense>
        <Text size="text-sm" weight="font-normal">
          The <b className="font-semibold">Strider team</b> has made changes to improve your
          profile.
        </Text>
      </div>
      <TertiaryButton onClick={handleDimiss}>Dismiss</TertiaryButton>
    </div>
  )
}
