import { User } from 'shared/hooks'
import { Persona } from 'modules/persona'
import { useContext } from 'react'
import { useIntercom } from 'react-use-intercom'
import { HelpContext } from './HelpProvider'

export const useHelp = () => {
  const { boot, update, show, hide, shutdown, showNewMessages, showArticle } = useIntercom()
  const {
    setIsChatOpen,
    isChatOpen,
    unreadMessagesCount,
    isChatInitialized,
    setIsChatInitialized,
  } = useContext(HelpContext)

  const onNavigation = () => {
    if (isChatInitialized) update()
  }

  const initializeChatWidget = (user: User) => {
    const persona = new Persona(user.role)

    boot({
      email: user.email,
      userHash: user.helpChatToken,
      userId: user.accountId,
      customAttributes: {
        persona: persona.label,
      },
    })
    setIsChatInitialized(true)
  }

  const onProfileLoaded = ({
    id,
    firstName,
    lastName,
  }: {
    id: string
    firstName: string
    lastName: string
  }) => {
    const profileUrl = `${window.location.origin}/candidates/${id}`
    const adminPanelUrl = `https://strider.retool.com/apps/de7a796a-fe1c-11ec-b4a5-abf654c4fffd/Job%20seeker%20profile?=&id=${id}`
    const name = [firstName, lastName].join(' ')

    update({ name, customAttributes: { profileUrl, adminPanelUrl } })
  }

  const toggleChat = () => {
    if (isChatOpen) {
      hide()
      return setIsChatOpen(false)
    }

    show()
    return setIsChatOpen(true)
  }

  return {
    initializeChatWidget,
    onNavigation,
    onProfileLoaded,
    toggleChat,
    isChatOpen,
    unreadMessagesCount,
    shutdownChat: shutdown,
    showNewMessages,
    showArticle,
  }
}
