import { useLocalStorage } from '../storage'

const REDIRECT_TO_REFERRALS = 'redirect_to_referrals'

// @todo: refactor to work in a generic way - STR-3335
export const useRedirectToReferrals = () => {
  const [shouldRedirectToReferrals, setShouldRedirect] = useLocalStorage(
    REDIRECT_TO_REFERRALS,
    false,
  )

  const setShouldRedirectToReferrals = () => setShouldRedirect(true)
  const removeShouldRedirectToReferrals = () => setShouldRedirect(false)

  const onSessionStart = (landingLocation?: string) => {
    if (landingLocation?.includes('/referrals')) setShouldRedirectToReferrals()
  }

  return {
    shouldRedirectToReferrals,
    setShouldRedirectToReferrals,
    removeShouldRedirectToReferrals,
    onSessionStart,
  }
}
