import { PaymentModel } from 'job-listing/enums'
import { MatchingSettingsFormSchema } from 'matching/domain'
import { AnySchema, array, number, object, string } from 'yup'

export const schema = object<Record<keyof MatchingSettingsFormSchema, AnySchema>>().shape({
  compensation: number().when('paymentModel', {
    is: PaymentModel.Monthly,
    then: number()
      .required()
      .min(1000, "The candidate's expected pay rate can't be less than $1000")
      .max(30000, "The candidate's expected pay rate can't be greater than $30000"),
    otherwise: number()
      .required()
      .min(1, "The candidate's expected pay rate can't be less than $1")
      .max(200, "The candidate's expected pay rate can't be greater than $200"),
  }),
  paymentModel: string(),
  briefing: string(),
  highlightedSkills: array().of(object().shape({ id: number(), label: string() })),
})
