import { useNetwork } from 'shared/hooks'
import { FetchJobListingPayRate } from 'job-listing/dtos'
import { useQuery } from 'react-query'

export const useFetchJobListingPayRate = (jobListingId: string) => {
  const { get } = useNetwork()

  const { data: payRate } = useQuery(['job-listing-pay-rate', jobListingId], () =>
    get<FetchJobListingPayRate>(`listings/${jobListingId}/pay-rate`).then(
      ({ data }) => data.payRate,
    ),
  )

  return { payRate }
}
