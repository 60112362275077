import { useEffect, useState } from 'react'

import { CollapsedMenu, CollapsedMenuItem } from 'shared/components/molecules'
import { JobListingCloserDialog, JobListingPauserDialog } from 'job-listing/exports'

import { AccountClaim, useAuth } from 'shared/hooks'

import { ListingStatus } from 'job-listing/enums'
import { HomepageListing } from 'job-listing/dtos'

import { ReactComponent as DotsIcon } from 'icons/dots.svg'

interface ContextualMenuProps {
  listing: HomepageListing
  onClose: (newStatus: ListingStatus) => void
}

export const ContextualMenu = ({ listing, onClose }: ContextualMenuProps) => {
  const [status, setStatus] = useState<ListingStatus>()
  const [isCloseJobListingOpened, setIsCloseJobListingOpened] = useState<boolean>(false)
  const [isPauseJobListingOpened, setIsPauseJobListingOpened] = useState<boolean>(false)

  const openCloseJobListingDialog = () => setIsCloseJobListingOpened(true)
  const dismissCloseJobListingDialog = () => setIsCloseJobListingOpened(false)

  const openPauseJobListingDialog = () => setIsPauseJobListingOpened(true)
  const dismissPauseJobListingDialog = () => setIsPauseJobListingOpened(false)

  const { user } = useAuth()

  const handleConfirm = (newStatus: ListingStatus) => {
    setStatus(newStatus)
    onClose(newStatus)
  }

  useEffect(() => setStatus(listing.status), [listing])

  const menuItems: Array<CollapsedMenuItem> = [
    {
      label: 'Pause',
      callback: openPauseJobListingDialog,
      visible:
        status === ListingStatus.Active && user.claims.includes(AccountClaim.PauseJobListings),
    },
    {
      label: 'Close',
      callback: openCloseJobListingDialog,
      visible:
        status !== ListingStatus.Closed && user.claims.includes(AccountClaim.CloseJobListings),
    },
  ].filter(({ visible }) => visible)

  if (!menuItems.length) return null

  return (
    <>
      <CollapsedMenu triggerElement={<DotsIcon className="!w-6 !h-6" />} items={menuItems} />
      <JobListingCloserDialog
        listingId={listing.id}
        isTalentReview={listing.isTalentReview}
        isOpen={isCloseJobListingOpened}
        closeDialog={dismissCloseJobListingDialog}
        onClose={handleConfirm}
      />
      <JobListingPauserDialog
        listingId={listing.id}
        isOpen={isPauseJobListingOpened}
        closeDialog={dismissPauseJobListingDialog}
        onClose={handleConfirm}
      />
    </>
  )
}
