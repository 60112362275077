import { useUser, useAuth as useClerkAuth } from '@clerk/clerk-react'
import { Roles } from 'account/enums'
import { useNetwork } from 'shared/hooks'
import { useContext, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { User, UserContext, UserResponse } from './index'

const buildFromResponse = (payload: UserResponse) => ({
  id: payload.id,
  email: payload.email,
  role: payload.role,
  isManager: payload.role === Roles.EmployerOwner,
  isSeeker: payload.role === Roles.JobSeeker,
  isEmployer: [Roles.EmployerOwner, Roles.EmployerViewer].includes(payload.role),
  isRecruiter: payload.role === Roles.Recruiter,
  isViewerOnly: payload.role === Roles.EmployerViewer,
  isOnboarded: [
    Roles.JobSeeker,
    Roles.EmployerOwner,
    Roles.EmployerViewer,
    Roles.Recruiter,
  ].includes(payload.role),
  company: payload.company,
  accountId: payload.accountId,
  helpChatToken: payload.intercomHmacToken,
  isCommunicationAllowed: payload.isCommunicationAllowed,
  isStriderStaff: payload.isStriderStaff,
  isEnglishEvaluator: payload.isEnglishEvaluator,
  isEnglishVettingAdmin: payload.isEnglishVettingAdmin,
  isJobSeekerProfilesManager: payload.isJobSeekerProfilesManager,
  isMatchingsManager: payload.isMatchingsManager,
  isJobListingSettingsManager: payload.isJobListingSettingsManager,
  claims: payload.claims || [],
  demoModeActive: payload.demoModeActive,
  jobOffersCount: payload.jobOffersCount,
})

export const useAuth = () => {
  const client = useQueryClient()
  const { get } = useNetwork()
  const { user: clerkUser } = useUser()
  const { getToken, signOut } = useClerkAuth()
  const { firstName, lastName, primaryEmailAddress, fullName, imageUrl } = clerkUser || {}

  const { user, setUser, isLoaded, setIsLoaded, notFound, setNotFound } = useContext(UserContext)
  const supplementalTermsUrl =
    user.company?.id === 183
      ? String(process.env.REACT_APP_ZAMP_OFFER_SUPPLEMENTAL_TERM_URL)
      : String(process.env.REACT_APP_OFFER_SUPPLEMENTAL_TERM_URL)
  const supplementalTermsLabel =
    user.company?.id === 183 ? 'Supplemental Terms - Talent Engagement' : 'Talent Engagement Terms'

  const patchUser = (patch: Partial<User>) =>
    setUser((current) => Object.assign({}, current, patch))

  const { isLoading, refetch } = useQuery(
    'accounts/me',
    () => {
      setIsLoaded(false)
      setNotFound(false)
      return get<UserResponse>('accounts/me')
        .then(({ data }) => {
          patchUser(buildFromResponse(data))
          setIsLoaded(true)
        })
        .catch(({ response }) => {
          setNotFound(response.status === 404)
          setIsLoaded(true)
        })
    },
    { cacheTime: Infinity, staleTime: Infinity },
  )

  const handleRefetch = async () => {
    // see frontend/src/pages/PendingAgreementChecker/usePendingAgreements.tsx
    await client.invalidateQueries('pending-agreements')
    await client.invalidateQueries('agreements/latest-accepted')
    await refetch()
  }

  useEffect(() => {
    patchUser({
      firstName,
      lastName,
      email: primaryEmailAddress?.emailAddress,
      fullName,
      imageUrl,
    })
  }, [firstName, lastName, primaryEmailAddress?.emailAddress, fullName, imageUrl])

  return {
    user,
    isLoading,
    isLoaded,
    notFound,
    refetch,
    refreshUserInfo: handleRefetch,
    isPending: !isLoaded || isLoading,
    supplementalTermsUrl,
    supplementalTermsLabel,
    signOut,
    getToken,
  }
}
