import { Link } from 'react-router-dom'

import { Paragraph, PrimaryButton, Text } from 'shared/components/atoms'
import { AlertDialog } from 'shared/components/molecules'

import { useTracking } from 'tracking'
import { JobOpportunityCompleteEnglishCheckClick } from 'tracking/events/job-opportunities'

import { ReactComponent as CheckIcon } from 'icons/check--thin.svg'
import { ReactComponent as VideoRecorderIcon } from 'icons/video-recorder.svg'

interface EnglishCheckMandatoryDialogProps {
  jobOpportunityId: string
}

export const EnglishCheckMandatoryDialog = ({
  jobOpportunityId,
}: EnglishCheckMandatoryDialogProps) => {
  const { trackEvent } = useTracking()

  const trackRecordEnglishCheckClick = () => {
    trackEvent(new JobOpportunityCompleteEnglishCheckClick(jobOpportunityId))
  }

  return (
    <AlertDialog
      isOpen
      isCancellable={false}
      title={
        <div className="flex gap-4">
          <div className="w-8 h-8 p-2 bg-success-lighter rounded-full">
            <CheckIcon className="w-4 h-4 stroke-success-medium" />
          </div>
          <Text size="text-xl" weight="font-medium">
            Application questions are finished
          </Text>
        </div>
      }
      titleClassName="pb-2 sm:pb-0"
      description={
        <div className="flex flex-col gap-8">
          <Paragraph
            size="body-sm"
            weight="font-normal"
            className="text-neutral-dark"
            truncate={false}
          >
            Next step: record your English Check videos. We need to assess your English skills
            before moving forward.
          </Paragraph>

          <div className="flex flex-col gap-4 bg-warning-lighter border border-warning-medium rounded-lg p-8">
            <Text size="text-xl" weight="font-medium">
              Recording the English Check is mandatory to complete your job application
            </Text>
            <div className="flex items-center gap-4 py-4">
              <VideoRecorderIcon className="w-6 h-6 stroke-neutral-darkest shrink-0" />
              <Text>
                You'll need to record three videos, each with 3 minutes max. Make sure you're in a
                quiet place before you start recording.
              </Text>
            </div>
          </div>
        </div>
      }
      customActions={
        <Link
          to="/english-vetting"
          onClick={trackRecordEnglishCheckClick}
          className="w-full lg:mt-2"
        >
          <PrimaryButton className="w-full">Take your English Check</PrimaryButton>
        </Link>
      }
    />
  )
}
