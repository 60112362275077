export enum ScreeningRejectionReason {
  IncorrectAnswer = 'incorrect_answer',
  IncompatibleEnglishLevel = 'incompatible_english_level',
  ManualReview = 'manual_review',
}

export const screeningRejectionReasonLabels = {
  [ScreeningRejectionReason.IncorrectAnswer]: 'Incorrect Answer',
  [ScreeningRejectionReason.IncompatibleEnglishLevel]: 'Incompatible English Level',
  [ScreeningRejectionReason.ManualReview]: 'Manual Review',
}
