import { useMemo, useState } from 'react'

export const useTruncateItems = <T>(
  items: Array<T>,
  shouldTruncate?: boolean,
  visibleItemsCount: number = 3,
) => {
  const [truncate, setTruncate] = useState(shouldTruncate)

  const visibleItems = useMemo<Array<T>>(() => {
    if (truncate) return items.slice(0, visibleItemsCount)

    return [...items]
  }, [items, truncate])

  return {
    visibleItems,
    truncatedItemsCount: items.length - visibleItems.length,
    setTruncate,
  }
}
