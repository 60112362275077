import { CheckboxGroup, DropdownWrapper, SelectTags } from 'shared/components/atoms'
import { IconInput } from 'shared/components/atoms/Form/Input/IconInput'

import { statusOptions } from '../options'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { CountBadge } from 'shared/components/atoms'

import { ReactComponent as LightningIcon } from 'icons/refer/lightning-01.svg'
import { ReactComponent as BriefcaseIcon } from 'icons/briefcase-02-outlined.svg'
import { ReactComponent as SearchIcon } from 'icons/search-md.svg'
import { useProfileRoles } from 'hooks/profile/useProfileRoles'

interface FilterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
}

export const Filter = ({ register, watch, setValue }: FilterProps) => {
  const roles = watch('roles') as Array<{ id: number; label: string }>
  const status = watch('status') as { [x: string]: boolean }
  const checkedStatus = Object.entries(status).filter(([_, status]) => status)

  const { roleOptions } = useProfileRoles(roles)

  return (
    <div className="grow flex flex-col sm:flex-row justify-between gap-4 sm:gap-0 py-10">
      <IconInput
        icon={<SearchIcon className="w-6 h-6 stroke-neutral-medium" />}
        name="search"
        register={register}
        inputClassName="pl-12"
        showFieldError={false}
        placeholder="Search"
      />

      <div className="flex gap-4">
        <DropdownWrapper
          text="Role"
          icon={<BriefcaseIcon />}
          triggerContent={
            Boolean(roles.length) && (
              <CountBadge value={roles.length} className="w-5 flex items-center justify-center" />
            )
          }
        >
          <SelectTags
            name="roles"
            options={roleOptions}
            setValue={setValue}
            watch={watch}
            matchDescription
            showDescription={false}
            placeholder="e.g. Full-stack Developer"
            selectedOptions={[]}
          />
        </DropdownWrapper>

        <DropdownWrapper
          text="Status"
          icon={<LightningIcon />}
          triggerContent={
            Boolean(checkedStatus.length) && (
              <CountBadge
                value={checkedStatus.length}
                className="w-5 flex items-center justify-center"
              />
            )
          }
        >
          <CheckboxGroup name="status" options={statusOptions} register={register} />
        </DropdownWrapper>
      </div>
    </div>
  )
}
