import { ProfileMatchingItem } from 'matching/domain'
import { CheckboxGroupOption } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'
import { Filter } from '../Filter'

interface MatchingFilterProps {
  matchings: Array<ProfileMatchingItem>
  formControl: FormControl
  disabled: boolean
}

export const MatchingFilter = ({ matchings, formControl, disabled }: MatchingFilterProps) => {
  const { setValue } = formControl

  const handleClear = () => {
    const value = matchings.reduce<Record<string, boolean>>((acc, matching) => {
      acc[matching.id] = false
      return acc
    }, {})

    setValue('matchings', value)
  }

  const options: Array<CheckboxGroupOption> = matchings.map((matching) => ({
    label: `${matching.listingTitle} (${matching.companyName})`,
    name: matching.id,
  }))

  return (
    <Filter
      text="Job listing"
      formControl={formControl}
      name="matchings"
      options={options}
      onClear={handleClear}
      disabled={disabled}
    />
  )
}
