import { TrackingEvent } from '../tracking-event'

export class DiscoveryCallRequest implements TrackingEvent {
  name = 'Discovery call request'

  constructor(private readonly email: string) {}

  get payload() {
    return { email: this.email }
  }
}
