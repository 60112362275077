import { Search } from 'candidates-search/domain'
import { Candidate } from 'candidates-search/domain'

export const usePayRateValues = (profile: Candidate, search?: Search) => {
  const hourlyFee = search?.listing?.company.hourlyFee || 0
  const annualFee = search?.listing?.company.annualFee || 0
  const monthlyFee = Number((annualFee / 12).toFixed(2))

  return {
    Annually: {
      label: 'Annual Rate (USD)',
      available: Boolean(profile.yearlyRate.min && profile.yearlyRate.max),
      value: {
        min: profile.yearlyRate.min,
        max: profile.yearlyRate.max,
        fee: annualFee,
      },
    },
    Monthly: {
      label: 'Monthly Rate (USD)',
      available: Boolean(profile.monthlyRate.min && profile.monthlyRate.max),
      value: {
        min: profile.monthlyRate.min,
        max: profile.monthlyRate.max,
        fee: monthlyFee,
      },
    },
    Hourly: {
      label: 'Hourly Rate (USD)',
      available: Boolean(profile.hourlyRate.min && profile.hourlyRate.max),
      value: {
        min: profile.hourlyRate.min,
        max: profile.hourlyRate.max,
        fee: hourlyFee,
      },
    },
    Missing: {
      label: 'Not informed',
      available: false,
      value: {
        min: 0,
        max: 0,
        fee: 0,
      },
    },
  }
}
