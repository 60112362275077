import { useState } from 'react'
import classNames from 'classnames'

import { Text } from 'components/Text'
import { Tab } from '.'

interface AnchorBarProps {
  tabs: Array<Tab>
  className?: string
}

export const AnchorBar = ({ tabs, className }: AnchorBarProps) => {
  //@todo: improve anchoring behavior https://onstrider.atlassian.net/browse/STR-1096
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const handleTabClick = (
    ref: React.MutableRefObject<HTMLDivElement | undefined>,
    index: number,
  ) => {
    setSelectedIndex(index)
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <nav
      className={classNames(
        'z-10',
        'flex flex-row justify-start gap-12',
        'w-full h-12 px-10',
        'bg-neutral-day border-b border-neutral-light',
        className,
      )}
    >
      {tabs.map((tab, index) => {
        const isSelected = selectedIndex === index

        return (
          <div
            key={index}
            onClick={() => handleTabClick(tab.ref, index)}
            className={classNames('w-fit h-12 flex items-center justify-center', 'cursor-pointer', {
              'border-b-2 border-neutral-darkest': isSelected,
            })}
          >
            <Text
              size="small-body"
              color={isSelected ? 'text-neutral-darkest' : 'text-neutral-dark'}
            >
              {tab.label}
            </Text>
          </div>
        )
      })}
    </nav>
  )
}
