import { useMutation } from 'react-query'

import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

import { MatchingStatus, SUCCESS_MESSAGES } from 'matching/enums'

interface UpdateMatchingRequest {
  status: MatchingStatus
  bookmarked: boolean
  profileImprovements?: string
  opportunityCompensation?: number
}

export const useUpdateMatching = (listingId?: string, matchingId?: string) => {
  const { patch } = useNetwork()
  const { toastError, toastSuccess } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: update } = useMutation(
    async ({
      bookmarked,
      status,
      opportunityCompensation,
      profileImprovements,
    }: UpdateMatchingRequest & { showSuccessMessage?: boolean }) =>
      patch(`/listings/${listingId}/matchings/${matchingId}`, {
        bookmarked,
        status,
        opportunityCompensation: opportunityCompensation
          ? Number(opportunityCompensation)
          : undefined,
        profileImprovements,
      }),
    {
      onSuccess: (_, { status, showSuccessMessage }) => {
        if (showSuccessMessage && SUCCESS_MESSAGES[status]) toastSuccess(SUCCESS_MESSAGES[status])
      },
      onError: (error: RequestError) => {
        toastError('Error when updating the matching record.')
        captureException(error)
      },
    },
  )

  return { update }
}
