import { useContext } from 'react'
import { useMutation } from 'react-query'

import { useNetwork } from 'shared/hooks'

import { useToast } from 'shared/hooks'
import { useMonitoring } from 'shared/hooks'

import { SearchContext } from 'candidates-search/contexts'

export const useScreening = (candidateId: string) => {
  const { search } = useContext(SearchContext)

  const { post } = useNetwork()
  const { toastSuccess, toastError } = useToast()
  const { captureException } = useMonitoring()

  const matchingId = search?.getMatchingByProfileId(candidateId)?.matchingId
  const jobListingId = search?.listing?.id

  const { mutateAsync: send, isLoading } = useMutation(
    () => post(`/listings/${jobListingId}/screenings/${matchingId}/send`),
    {
      onSuccess: () => {
        toastSuccess('Screening sent.')
      },
      onError: (error: RequestError) => {
        toastError(error.response?.data.message)
        captureException(error)
      },
    },
  )

  return { send, isLoading }
}
