import classNames from 'classnames'

import { ProgressBar, Text } from 'shared/components/atoms'

import { useDate } from 'shared/hooks'
import { useJobOpportunities } from 'job-opportunity/hooks'

import { JobOpportunity } from 'job-opportunity/domain'

interface Props {
  jobOpportunity: JobOpportunity
  now: Date
}

export const DeadlineProgressBar = ({ jobOpportunity, now }: Props) => {
  const { getDifferenceInSeconds, getTimeToDisplay } = useDate()
  const { getTimeRemainingColors, getProgressBarPercentage } = useJobOpportunities()

  const differenceInSeconds = getDifferenceInSeconds(now, jobOpportunity.deadlineAt)

  const isValueGreaterThanZero = differenceInSeconds > 0

  const { progressBarColor, itemTextColor } = getTimeRemainingColors(
    isValueGreaterThanZero ? differenceInSeconds : 0,
  )
  const percentage = isValueGreaterThanZero
    ? getProgressBarPercentage(jobOpportunity.createdAt, jobOpportunity.deadlineAt, now)
    : 0

  const { hours, minutes } = getTimeToDisplay(isValueGreaterThanZero ? differenceInSeconds : 0)

  return (
    <div className="flex flex-col my-3">
      <Text size="text-sm" className="text-neutral-dark mb-2">
        Your window to reply is closing in{' '}
        <span className={classNames(['font-medium', itemTextColor])}>
          {hours}h {minutes}min
        </span>
      </Text>
      <ProgressBar color={progressBarColor} progress={percentage} background="bg-neutral-lighter" />
    </div>
  )
}
