import { Text, Title, PrimaryButton } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { lazy, Suspense } from 'react'

const VideoConferenceIcon = lazy(() =>
  import('icons/ilustrations/video-conference.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const WelcomeBackModal = ({ isOpen, onClose }: Props) => (
  <Modal
    title=""
    open={isOpen}
    handleClose={onClose}
    titleContainerClassName="border-0"
    content={
      <div className="flex flex-col items-center">
        <Suspense fallback={null}>
          <VideoConferenceIcon />
        </Suspense>
        <Title size="large-heading" className="text-center mt-10 mb-6">
          We're glad to see you here again
        </Title>
        <Text size="text-base" className="text-neutral-darker text-center">
          Welcome back! We're thrilled to have you return to Strider. Let's pick up where we left
          off and explore new opportunities together.
        </Text>
      </div>
    }
    footer={<PrimaryButton onClick={onClose}>Done</PrimaryButton>}
    showClose={false}
  />
)
