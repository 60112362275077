import { PrimaryButton } from 'shared/components/atoms'
import { TermsModal } from 'shared/components/molecules'

interface ListingTermsModalProps {
  content: string
  setTermsModalVisible(value: boolean): void
  isVisible: boolean
}

export const ListingTermsModal = ({
  content,
  setTermsModalVisible,
  isVisible,
}: ListingTermsModalProps) => {
  const handleCloseClick = () => setTermsModalVisible(false)

  if (isVisible)
    return (
      <TermsModal
        title="Listing terms"
        content={<div dangerouslySetInnerHTML={{ __html: content }} />}
        onCancelClick={handleCloseClick}
        footer={
          <PrimaryButton onClick={handleCloseClick} className="w-full lg:w-fit" autoFocus>
            Close
          </PrimaryButton>
        }
      />
    )

  return null
}
