import { Text } from 'shared/components/atoms'
import { ListingInfo } from 'job-listing/domains'
import { JobListingPreview } from 'job-listing/exports'

interface ListingDetailsProps {
  listing: Optional<ListingInfo>
}

export const ListingDetails = ({ listing }: ListingDetailsProps) => {
  if (!listing) return null

  return (
    <div className="flex flex-col gap-8 mb-10">
      <Text size="text-xl">Listing details</Text>
      <JobListingPreview listing={listing!} readOnly initiallyExpanded showDraftBanner={false} />
    </div>
  )
}
