import { useContext } from 'react'

import { Paragraph, Text } from 'shared/components/atoms'
import { JobOpportunity } from 'job-opportunity/domain'
import { ApplicationReviewItem } from 'job-opportunity/components'
import { CandidateContext } from 'contexts/candidate'
import { WorkExperience } from 'modules/work-experience'
import { useCompensation } from 'hooks/payRate'

interface Props {
  jobOpportunity: JobOpportunity
}

export const ApplicationReview = ({ jobOpportunity }: Props) => {
  const {
    candidate: { experiencesHistory },
  } = useContext(CandidateContext)

  const {
    screeningAnswers,
    screening: { questions, paymentModel, minimumPayRate, maximumPayRate },
    compensation,
  } = jobOpportunity

  const { compensationLabel, compensationTitle } = useCompensation({
    compensation,
    minimumPayRate,
    maximumPayRate,
    paymentModel,
  })

  const candidateExperiences = experiencesHistory.reduce(
    (obj, experience) => {
      obj[experience.id] = experience
      return obj
    },
    {} as { [key: string]: WorkExperience },
  )

  if (!screeningAnswers) return null

  return (
    <div>
      <div>
        <Text size="text-xl" weight="font-medium">
          Review your application
        </Text>
        <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark mt-2">
          We will review the information you provided, including what's listed in your profile, to
          evaluate your eligibility for the position.
        </Paragraph>
      </div>
      {Boolean(compensationLabel && compensationTitle) && (
        <div className="flex flex-col mt-10">
          <Text className="text-neutral-dark">{compensationTitle}</Text>
          <Text size="text-xl" weight="font-medium" className="text-neutral-darkest">
            {compensationLabel}
          </Text>
        </div>
      )}
      <div className="flex flex-col gap-y-14 mt-10">
        {questions.map((question, i) => (
          <ApplicationReviewItem
            key={i}
            index={i}
            question={question}
            text={screeningAnswers[i].value}
            workExperiences={screeningAnswers[i].linkedWorkExperienceIds}
            candidateExperiences={candidateExperiences}
          />
        ))}
      </div>
    </div>
  )
}
