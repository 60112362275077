import { useEffect } from 'react'

interface RedirectProps {
  url: string
}

export const Redirect = ({ url }: RedirectProps) => {
  useEffect(() => {
    window.location.replace(url)
  }, [url])

  return null
}
