import { HideScrollContainer } from 'shared/components/atoms'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { ProgressIndicator } from '../ProgressIndicator'
import { Heading } from './components/Heading'
import { LinksCollection } from './components/LinksCollection'
import classNames from 'classnames'
import { useContext } from 'react'
import { LayoutContext } from 'contexts/layout'
import { DeactivatedAccountBadge } from 'components/molecules/Badges'
import { Preferences } from 'modules/profiles/profile-preferences'
import { useProfileAvailability } from 'hooks/profile/useProfileAvailability'

interface SidebarProps {
  profile: ProfileOverview
  onAboutEdit: () => void
  onAddWorkExperienceClick: () => void
  onAddEducationClick: () => void
  onEditBio: () => void
  onPreferencesClick: () => void
  onEditRolesAndSkillsClick: () => void
  onPayRateClick: () => void
  preferences: Preferences
}

export const Sidebar = ({
  profile,
  onAboutEdit,
  onAddWorkExperienceClick,
  onAddEducationClick,
  onEditBio,
  onPreferencesClick,
  onEditRolesAndSkillsClick,
  onPayRateClick,
  preferences,
}: SidebarProps) => {
  const { shouldHideNavbar } = useContext(LayoutContext)
  const { isPaused } = useProfileAvailability(preferences.availability)

  return (
    <HideScrollContainer
      role="complementary"
      className={classNames(
        'flex flex-col gap-12',
        'h-full lg:h-screen lg:w-[264px]',
        'static lg:sticky lg:top-0 ',
        'lg:pb-20 lg:-mb-20',
        'lg:overflow-y-scroll',
        {
          'lg:pt-[88px] lg:-mt-[88px]': shouldHideNavbar,
          'lg:pt-[152px] lg:-mt-[152px]': !shouldHideNavbar,
        },
      )}
    >
      <Heading profile={profile} readOnly={false} onEdit={onAboutEdit}>
        {Boolean(isPaused && preferences.pausedUntil) && (
          <DeactivatedAccountBadge date={preferences.pausedUntil!} />
        )}
        <LinksCollection profile={profile} />
      </Heading>
      <ProgressIndicator
        profile={profile}
        readOnly={false}
        onAddWorkExperienceClick={onAddWorkExperienceClick}
        onAddEducationClick={onAddEducationClick}
        onEditBio={onEditBio}
        onPreferencesClick={onPreferencesClick}
        onEditRolesAndSkillsClick={onEditRolesAndSkillsClick}
        onAboutEdit={onAboutEdit}
        onPayRateClick={onPayRateClick}
      />
    </HideScrollContainer>
  )
}
