import { plainToInstance } from 'class-transformer'
import { useNetwork } from 'shared/hooks'
import { Recruiter } from 'modules/recruiter/recruiter'
import { useQuery } from 'react-query'
import { nullRecruiter } from './null-recruiter'

export const useRecruiter = () => {
  const { get } = useNetwork()

  const {
    data: recruiter = nullRecruiter,
    refetch: fetchRecruiter,
    isLoading,
    isRefetching,
  } = useQuery(
    'recruiter',
    async () =>
      await get<Recruiter>('recruiter').then(({ data }) => plainToInstance(Recruiter, data)),
    {
      retry: (_, error: RequestError) => error.response?.status !== 404,
    },
  )

  return {
    recruiter,
    fetchRecruiter,
    isLoading: isLoading || isRefetching,
  }
}
