import classNames from 'classnames'

import { Text } from 'shared/components/atoms'

import { ReactComponent as ArrowIcon } from 'icons/refer/arrow-narrow-up-right.svg'
import { ReactComponent as CurrencyIcon } from 'icons/refer/currency-dollar-circle.svg'
import { ReactComponent as FlagIcon } from 'icons/refer/flag-05.svg'
import { ReactComponent as LinkBrokenIcon } from 'icons/refer/link-broken.svg'

import { useFormatting } from 'shared/hooks'

import { TimelineItemType } from 'modules/referrals/enum'
import { TimelineItem } from 'modules/referrals/domains'

interface RowProps {
  item: TimelineItem
  index: number
  action?: React.ReactNode
}

export const Row = ({ item: { type, description, date, amount }, index, action }: RowProps) => {
  const { formatDate, formatCurrency } = useFormatting()

  const badge = {
    [TimelineItemType.Joined]: {
      icon: <FlagIcon />,
      bgColor: 'bg-neutral-lightest',
    },
    [TimelineItemType.Income]: {
      icon: <ArrowIcon className="stroke-success-medium" />,
      bgColor: 'bg-success-lighter',
    },
    [TimelineItemType.PendingReceive]: {
      icon: <ArrowIcon className="stroke-success-medium" />,
      bgColor: 'bg-success-lighter',
    },
    [TimelineItemType.PayoutTransfer]: {
      icon: <CurrencyIcon />,
      bgColor: 'bg-neutral-darkest',
    },
    [TimelineItemType.ContractSuspended]: {
      icon: <LinkBrokenIcon />,
      bgColor: 'bg-danger-lighter',
    },
  }

  const typeDescription = {
    [TimelineItemType.Joined]: 'Joined',
    [TimelineItemType.Income]: 'Income',
    [TimelineItemType.PendingReceive]: 'Pending to receive',
    [TimelineItemType.PayoutTransfer]: 'Payout transfer',
    [TimelineItemType.ContractSuspended]: 'Contract suspended',
  }

  return (
    <tr className="h-[72px]" key={index}>
      <td className="flex flex-row justify-start items-center gap-6 py-4">
        <div
          className={classNames(
            'w-10 h-10 flex items-center justify-center bg-neutral-lightest rounded',
            badge[type].bgColor,
          )}
        >
          {badge[type].icon}
        </div>
        <div className="flex flex-col items-start gap-1">
          <Text size="text-sm" weight="font-medium" className="text-neutral-darkest">
            {description}
          </Text>
          <Text size="text-xs" weight="font-normal" className="text-neutral-dark">
            {typeDescription[type]}
          </Text>
        </div>
      </td>
      <td>
        <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
          {formatDate({ date: date, day: '2-digit', month: 'short', year: 'numeric' })}
        </Text>
      </td>
      <td className="text-right">
        {amount !== undefined && (
          <Text
            size="text-base"
            weight="font-medium"
            className={classNames({
              'text-success-medium': type === TimelineItemType.PayoutTransfer,
              'text-neutral-darkest': type !== TimelineItemType.PayoutTransfer,
            })}
          >
            {type === TimelineItemType.PayoutTransfer ? '-' : '+'}
            {formatCurrency(amount, 0)}
          </Text>
        )}
        {action}
      </td>
    </tr>
  )
}
