import { useListingsFetch } from 'job-listing/exports'
import { usePageTitle, useAuth } from 'shared/hooks'
import { useState } from 'react'
import { Header } from './components/Header'
import { ListingList } from './components/ListingList'
import { EmptyState } from './components/EmptyState'
import { ReferralBanner } from './components/ReferralBanner'
import { useReferralBannerDismissed } from './hooks'

export const JobListingsPage = () => {
  usePageTitle('Job listings')

  const [page, setPage] = useState(1)

  const { listings, count, isLoading } = useListingsFetch(page)

  const handleFetchMore = () => {
    setPage(page + 1)
  }

  const {
    user: { company },
  } = useAuth()
  const { isDismissed, setDismissed } = useReferralBannerDismissed()

  const isVisible = !isDismissed && Boolean(company?.jobOffersCount)

  return (
    <div className="flex-grow flex flex-col w-full">
      <Header />
      {isVisible && <ReferralBanner onClick={setDismissed} />}
      <ListingList
        listings={listings}
        isFirstPage={page === 1}
        hasMore={listings.length < count}
        onFetchMore={handleFetchMore}
      />
      {!listings.length && <EmptyState isLoading={isLoading} />}
    </div>
  )
}
