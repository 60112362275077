import { EngagementType } from 'job-listing/enums'
import { JobDescription } from '../domain/job-description.domain'

export class UpdateJobDescriptionDTO {
  constructor(private readonly jobDescription: JobDescription) {}

  private get contractDuration() {
    const { engagementType, contractDuration } = this.jobDescription
    if (engagementType === EngagementType.ShortTerm) return contractDuration
  }

  private get workingHoursPerWeek() {
    const { engagementType, workingHoursPerWeek } = this.jobDescription
    if (engagementType === EngagementType.ShortTerm) return workingHoursPerWeek
  }

  toJSON() {
    return {
      title: this.jobDescription.title,
      marketType: this.jobDescription.marketType,
      minimumYearsOfExperience: Number(this.jobDescription.minimumTotalExperience),
      minimumEnglishLevel: this.jobDescription.minimumEnglishLevel,
      engagementType: this.jobDescription.engagementType,
      contractDuration: this.contractDuration,
      workingHoursPerWeek: this.workingHoursPerWeek,
      requiredSkills: this.jobDescription.requiredSkills,
      detailedRequirements: this.jobDescription.detailedRequirements,
      projectDescription: this.jobDescription.projectDescription || undefined,
      benefits: this.jobDescription.benefits,
      processOverview: this.jobDescription.processOverview || undefined,
      companyName: this.jobDescription.companyName,
      companyWebsite: this.jobDescription.companyWebsite,
      companySize: this.jobDescription.companySize,
    }
  }
}
