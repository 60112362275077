import { MouseEvent, useMemo, useState } from 'react'

import { SecondaryButton, Text, Title } from 'shared/components/atoms'
import { AlertDialog, InfoBanner } from 'shared/components/molecules'
import { SyncedToCloseBadge } from 'components/molecules'

import { AccountClaim, useAuth, useToast } from 'shared/hooks'
import { useManageJobListing } from 'job-listing/hooks'
import { useMatchingsReports } from 'hooks/matchings'

import { ListingStatus } from 'job-listing/enums'

interface JobListingCloserDialogProps {
  listingId: string
  isOpen: boolean
  isTalentReview: boolean
  closeDialog: () => void
  onClose?: (newStatus: ListingStatus) => void
}

export const JobListingCloserDialog = ({
  listingId,
  isOpen,
  isTalentReview,
  closeDialog,
  onClose,
}: JobListingCloserDialogProps) => {
  const { updateStatus } = useManageJobListing(listingId)
  const { toastSuccess } = useToast()

  const {
    report: { count },
  } = useMatchingsReports(listingId)

  const customInfo = useMemo(() => {
    if (isTalentReview) {
      return (
        <Text>
          No candidate will be notified about the closing of this job listing because it's set to
          <span className="italic">Talent Review</span>.
        </Text>
      )
    }

    if (count === 0) {
      return <Text>No candidate is at the stage of being notified about this closing.</Text>
    }

    return (
      <Text>
        {count} candidate(s) will be notified by email about the closing of this job listing after a
        few minutes delay.
      </Text>
    )
  }, [count])

  const closeListing = () =>
    updateStatus(ListingStatus.Closed).then(() => {
      toastSuccess('Job listing closed.')
      closeDialog()
      onClose && onClose(ListingStatus.Closed)
    })

  return (
    <AlertDialog
      isOpen={isOpen}
      title={
        <div className="flex gap-4 items-center">
          <Title size="heading">Close job listing</Title>
          <SyncedToCloseBadge />
        </div>
      }
      description={
        <div className="flex flex-col gap-6">
          <Text size="text-base">
            When a job listing is closed, it's no longer possible to add new candidates to the
            process, and the active screening will be archived. To resume using this job listing, it
            will need to be republished.
          </Text>
          <InfoBanner bgColor="bg-info-lighter" iconColor="stroke-info-medium">
            {customInfo}
          </InfoBanner>
        </div>
      }
      customActions={<SecondaryButton onClick={closeListing}>Close job listing</SecondaryButton>}
      onCancelClick={closeDialog}
    />
  )
}

interface JobListingCloserProps {
  onClose: () => void
  children: ({ onClick }: { onClick: (event: MouseEvent<HTMLElement>) => void }) => JSX.Element
  listingId: string
  isTalentReview: boolean
}

export const JobListingCloser = ({
  isTalentReview,
  children,
  listingId,
  onClose,
}: JobListingCloserProps) => {
  const {
    user: { claims },
  } = useAuth()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const openDialog = () => setIsOpen(true)
  const closeDialog = () => setIsOpen(false)

  const handleListingPause = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    openDialog()
  }

  if (!claims.includes(AccountClaim.CloseJobListings)) return null

  return (
    <>
      {children({ onClick: handleListingPause })}
      {isOpen && (
        <JobListingCloserDialog
          listingId={listingId}
          isOpen={isOpen}
          closeDialog={closeDialog}
          onClose={onClose}
          isTalentReview={isTalentReview}
        />
      )}
    </>
  )
}
