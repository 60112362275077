import { TrackingEvent } from '../tracking-event'

export class JobOpportunityCompleteProfileClick implements TrackingEvent {
  name = 'Job opportunity complete profile click'

  constructor(private id: string) {}

  get payload() {
    return {
      id: this.id,
    }
  }
}
