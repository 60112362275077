import { useQuery } from 'react-query'
import { useEffect } from 'react'

import { useDebounce, useMonitoring, useNetwork, useToast } from 'shared/hooks'

export type MatchingSimulation = {
  budgetDifference: number
  fee: number
  cost: number
  conflict: boolean
  withinBudget: boolean
  budget: number
}

export const useMatchingSimulation = (
  profileId: string,
  jobListingId: string,
  compensation: number,
  isValid: boolean,
  fee = 0,
) => {
  const { post } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { debouncedValue: debouncedCompensation, isDebouncing } = useDebounce(compensation, 700)
  const { debouncedValue: debouncedFee, isDebouncing: isDebouncingFee } = useDebounce(fee, 700)

  const {
    data: simulation = null,
    refetch,
    isLoading,
    isFetching,
    isRefetching,
  } = useQuery(
    ['matching-simulations', profileId, jobListingId],
    () =>
      post<MatchingSimulation>('/matching-simulations', {
        jobListingId,
        profileId,
        compensation: debouncedCompensation,
        fee: debouncedFee,
      }).then(({ data }) => data),
    {
      onError: (error: RequestError) => {
        toastError(error.response.data?.message)
        captureException(error)
      },
      retry: false,
      enabled: false,
    },
  )

  useEffect(() => {
    if (isDebouncing || isDebouncingFee || !isValid) return
    refetch()
  }, [debouncedCompensation, debouncedFee])

  return {
    simulation,
    isLoading: [isFetching, isLoading, isRefetching, isDebouncing, isDebouncingFee].some(
      (value) => value,
    ),
  }
}
