import { useMonitoring, useNetwork, useToast } from 'shared/hooks'
import { ListingResponse } from 'job-listing/dtos'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

export const useJobListings = (companyId: number) => {
  const { get } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  // @todo - consume new endpoint from listings module when available -
  const { data: jobListingsData = [] } = useQuery({
    queryKey: ['advanced-search/clients/job-listings', companyId],
    queryFn: async () =>
      await get<Array<ListingResponse>>(`advanced-search/clients/${companyId}/job-listings`)
        .then(({ data }) => data)
        .catch((error: RequestError) => {
          toastError('Error while fetching the listings of the selected company')
          captureException(error)
        }),
    enabled: Boolean(companyId),
    staleTime: 5 * 60 * 1000,
  })

  const jobListingsOptions = useMemo(
    () => jobListingsData.map(({ id, title, status }) => ({ id, label: `${title} (${status})` })),
    [jobListingsData],
  )
  return {
    jobListingsOptions,
  }
}
