import { useNavigate } from 'react-router-dom'
import { AccountClaim, useAuth } from 'shared/hooks'

interface ClaimProtectedProps {
  require: AccountClaim | Array<AccountClaim>
  children: JSX.Element
  redirectUrl?: string
}

export const ClaimProtected = ({ require, children, redirectUrl }: ClaimProtectedProps) => {
  const { user } = useAuth()
  const navigate = useNavigate()

  const requirements = Array.isArray(require) ? require : [require]
  const allowed = requirements.every((claim) => user.claims.includes(claim))

  if (allowed) return children
  if (redirectUrl) navigate(redirectUrl)

  return null
}
