import { useMemo } from 'react'
import classNames from 'classnames'

import { Text } from 'shared/components/atoms'

import { ReferralStatus } from 'modules/referrals/enum'

interface CounterProps {
  id: string
  label: string
  counter: number
  iconColor: 'bg-info-light' | 'bg-success-light' | 'bg-neutral-darkest'
}
const Counter = ({ id, label, counter, iconColor }: CounterProps) => {
  return (
    <div className="w-full flex flex-row-reverse sm:flex-col items-stretch justify-end sm:justify-center gap-4 sm:gap-1">
      <div className="flex flex-row items-center justify-start gap-2">
        <div className={classNames('w-2 h-2 rounded-full', iconColor)} />
        <Text id={id} size="text-xs" weight="font-normal" className="text-neutral-darkest">
          {label}
        </Text>
      </div>
      <Text
        size="text-2xl"
        weight="font-normal"
        className="text-neutral-darkest w-8 sm:w-fit sm:text-4xl"
        aria-labelledby={id}
      >
        {counter}
      </Text>
    </div>
  )
}

interface PercentageBarProps {
  quantity: number
  total: number
  color: 'bg-info-light' | 'bg-success-light' | 'bg-neutral-darkest'
}
const PercentageBar = ({ quantity, total, color }: PercentageBarProps) => {
  if (!quantity) return null

  return (
    <div
      className={classNames('h-2 rounded-lg', color)}
      style={{ width: `${(quantity / total) * 100}%` }}
    />
  )
}

interface TalentFunnelProps {
  counts: Array<{ status: ReferralStatus; count: number }>
}

export const TalentFunnel = ({ counts }: TalentFunnelProps) => {
  const getCountValue = (desiredStatus: ReferralStatus) =>
    counts.find(({ status }) => status === desiredStatus)?.count || 0

  const signedUp = getCountValue(ReferralStatus.SignedUp)
  const completedProfile = getCountValue(ReferralStatus.ProfileCompleted)
  const hired = getCountValue(ReferralStatus.Hired) + getCountValue(ReferralStatus.Probation)

  const total = useMemo<number>(
    () => signedUp + completedProfile + hired,
    [signedUp, completedProfile, hired],
  )

  return (
    <div className="flex flex-col items-stretch justify-start gap-6 p-8 border border-neutral-lighter rounded-lg shadow-level-1">
      <Text size="text-xl" weight="font-normal" className="text-neutral-darkest">
        Talent overview
      </Text>
      {total ? (
        <div className="flex flex-row items-center gap-1">
          <PercentageBar quantity={signedUp} total={total} color="bg-info-light" />
          <PercentageBar quantity={completedProfile} total={total} color="bg-success-light" />
          <PercentageBar quantity={hired} total={total} color="bg-neutral-darkest" />
        </div>
      ) : (
        <div className="h-2 rounded-lg bg-neutral-lightest" />
      )}
      <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
        <Counter id="signed-up" label="Signed up" counter={signedUp} iconColor="bg-info-light" />
        <Counter
          id="completed-profile"
          label="Completed profile"
          counter={completedProfile}
          iconColor="bg-success-light"
        />
        <Counter id="hired" label="Hired" counter={hired} iconColor="bg-neutral-darkest" />
      </div>
    </div>
  )
}
