import { useQueryParams } from 'shared/hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface RedirectWithQueryParamsProps {
  to: string
}

export const RedirectWithQueryParams = ({ to }: RedirectWithQueryParamsProps) => {
  const queryParams = useQueryParams()
  const navigate = useNavigate()

  useEffect(() => {
    const url = [to, queryParams].join('?')

    navigate(url)
  }, [])

  return null
}
