import { ContentWrapper } from '../../../../components/ContentWrapper'
import { ProfileEducation } from 'pages/JobSeekerProfile/types'
import { InstitutionIcon } from 'components/InstitutionIcon'
import { ReactComponent as BookIcon } from 'icons/book-open-01.svg'
import { UpsertButton } from '../UpsertButton'
import { SensitiveInformation } from 'shared/components/atoms'
import { InstitutionTierBadge } from 'components/molecules/Badges'
import { Text } from 'shared/components/atoms'

interface EducationRecordProps {
  readOnly: boolean
  education: ProfileEducation
  onButtonClick(education: ProfileEducation): void
  className?: string
}

export const EducationRecord = ({
  readOnly,
  education,
  onButtonClick,
  className,
}: EducationRecordProps) => {
  const getDateFullYear = (date: string) => new Date(date).getUTCFullYear()

  const label = `Education: ${education.institution.label} ${education.startDate} - ${education.endDate}`

  return (
    <ContentWrapper key={`education-${education.id}`} className={className} ariaLabel={label}>
      <div className="flex gap-8">
        <div className="flex items-center justify-center w-10 h-10 sm:w-12 sm:h-12 rounded-lg bg-neutral-lightest">
          <InstitutionIcon
            domain={education.institution.website}
            icon={<BookIcon className="w-6 h-6 stroke-neutral-medium" />}
          />
        </div>
        <div className="flex-grow flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="flex gap-3 items-start">
              <SensitiveInformation>
                <Text size="text-base" weight="font-medium" className="text-neutral-darkest">
                  {education.institution.label}
                </Text>
              </SensitiveInformation>
              <div className="py-[2px]">
                <InstitutionTierBadge tier={education.institution.tier} />
              </div>
              <UpsertButton
                onClick={() => onButtonClick(education)}
                readOnly={readOnly}
                className="ml-auto"
              />
            </div>
            <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
              {education.degreeType}, {education.fieldOfStudy}
            </Text>
            <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
              {getDateFullYear(education.startDate)} - {getDateFullYear(education.endDate)}
            </Text>
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}
