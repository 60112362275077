import { useInstitution } from 'pages/JobSeekerProfile/hooks/useInstitution'
import { useFetchInstitutions } from 'profile/hooks'
import { Autocomplete, FieldError, Label, Suggestion } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'

interface SelectInstitutionProps {
  profileId: string
  formControl: FormControl
  label: string
}

export const SelectInstitution = ({ profileId, formControl, label }: SelectInstitutionProps) => {
  const { createInstitution, isLoading: isCreating } = useInstitution(profileId)
  const { institutions, isLoading } = useFetchInstitutions(profileId)
  const { register, watch, setValue, getError } = formControl

  const setInstitution = (data: Suggestion) => {
    setValue('institutionId', data.id, { shouldValidate: true })
    setValue('institutionName', data.label, { shouldValidate: true })
  }

  const handleInstitutionChange = async (value: Suggestion) => {
    if (value.id) return setInstitution(value)
    createInstitution(value.label).then((response) => {
      setInstitution(response.data)
    })
  }

  return (
    <>
      <Label htmlFor="institutionName">{label}</Label>
      <Autocomplete
        allowCreation
        showHint={false}
        clearInputValue={false}
        register={register}
        watch={watch}
        name="institutionName"
        suggestions={institutions}
        handleAdd={handleInstitutionChange}
        placeholder="College, University, Institution ..."
        distance={600}
        isLoading={isLoading || isCreating}
      />
      <FieldError message={getError('institutionName')} />
    </>
  )
}
