import { Paragraph } from 'shared/components/atoms'
import { useFormatting } from 'shared/hooks'

interface Props {
  date?: string
}

export const DeactivatedAccountBadge = ({ date }: Props) => {
  const { formatDate } = useFormatting()

  return (
    <div className="flex gap-3 items-center">
      <div className="shrink-0 print:hidden bg-warning-lighter px-3 py-1 rounded">
        <Paragraph size="body-xs" className="text-warning-darker">
          Deactivated account
        </Paragraph>
      </div>
      {Boolean(date) && (
        <div>
          <Paragraph size="body-xs">
            Until {formatDate({ date: new Date(date!), timeZone: 'UTC' })}
          </Paragraph>
        </div>
      )}
    </div>
  )
}
