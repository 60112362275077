import classNames from 'classnames'

export type Bar = {
  heightPercentage: number
  color: 'bg-neutral-lightest' | 'bg-neutral-darker'
}

interface HistogramProps {
  bars: Array<Bar>
  containerClassName?: string
  barsClassName?: string
}

export const Histogram = ({ bars, containerClassName, barsClassName }: HistogramProps) => {
  return (
    <div
      data-testid="histogram-container"
      className={classNames(
        'flex flex-row items-end gap-[0.125rem] h-[6.3125rem]',
        containerClassName,
      )}
    >
      {bars.map(({ heightPercentage, color }, index) => (
        <div
          data-testid="histogram-bar"
          key={index}
          className={classNames('flex-grow rounded-t-sm', color, barsClassName)}
          style={{ height: `${heightPercentage}%` }}
        />
      ))}
    </div>
  )
}
