import { useMonitoring, useNetwork, useToast } from 'shared/hooks'
import { useMutation, useQueryClient } from 'react-query'
import { MatchingSettingsFormSchema } from 'matching/domain'
import { UpsertMatchingSettingsDTO } from 'matching/dtos'

export const useUpdateMatchingSettings = ({
  profileId,
  listingId,
  matchingId,
}: {
  profileId: string
  listingId?: string
  matchingId?: string
}) => {
  const { patch } = useNetwork()
  const { toastError, toastSuccess } = useToast()
  const client = useQueryClient()
  const { captureException } = useMonitoring()

  const { mutateAsync: updateMatchingSettings, isLoading } = useMutation(
    (settings: MatchingSettingsFormSchema & { preventSuccessToast?: boolean }) =>
      patch(
        `/listings/${listingId}/matchings/${matchingId}/settings`,
        new UpsertMatchingSettingsDTO(settings).toJSON(),
      ),
    {
      onError: (error: RequestError) => {
        toastError('Failed to save matching settings.')
        captureException(error)
      },
      onSuccess: (_, { preventSuccessToast }) => {
        client.invalidateQueries('matchings')
        client.invalidateQueries(`candidates/${profileId}`)

        if (preventSuccessToast) return

        toastSuccess('Matching settings updated.')
      },
    },
  )

  return { updateMatchingSettings, isUpdatingSettings: isLoading }
}
