import classNames from 'classnames'

import { ReactComponent as ExternalIcon } from 'icons/external-link.svg'
import { Text } from 'shared/components/atoms'
import { Link } from 'shared/components/atoms'

import { Assessment } from 'modules/candidates/domain'
import { ConditionalWrapper } from 'shared/components/molecules'
import { AssessmentResult, assessmentResultLabel } from 'modules/candidates/enums'

interface AssessmentLinkProps {
  assessment: Assessment
}

export const AssessmentLink = ({ assessment }: AssessmentLinkProps) => {
  return (
    <li>
      <ConditionalWrapper
        shouldWrap={Boolean(assessment.url)}
        wrapper={(children) => (
          <Link className="flex gap-4 items-center" url={assessment.url!}>
            {children}
            <ExternalIcon className="stroke-neutral-dark h-3 w-3 ml-auto" />
          </Link>
        )}
      >
        <div className="flex flex-col">
          <Text size="text-sm" weight="font-medium">
            {assessment.domain}
          </Text>
          <Text
            size="text-sm"
            weight="font-normal"
            className={classNames({
              'text-success-medium': assessment.passed,
              'text-danger-medium': !assessment.passed,
              'text-warning-medium': assessment.result === AssessmentResult.WeakPass,
            })}
          >
            {assessmentResultLabel[assessment.result]}
          </Text>
        </div>
      </ConditionalWrapper>
    </li>
  )
}
