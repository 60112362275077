import { EditorState } from 'draft-js'
import { ReactComponent as EditIcon } from 'icons/edit-03.svg'

interface Props {
  onChange?: Function
  editorState?: EditorState
  backgroundColor: string
}

export const CustomHighlight = ({ onChange, editorState, backgroundColor }: Props) => {
  const handleChange = async () => {
    const { RichUtils } = await import(/* webpackPrefetch: true */ 'draft-js')
    if (!onChange || !editorState) return

    let result = RichUtils.toggleInlineStyle(editorState, `bgcolor-${backgroundColor}`)
    result = RichUtils.toggleInlineStyle(result, 'HIGHLIGHT')
    onChange(result)
  }

  return (
    <div className="rdw-colorpicker-wrapper" onClick={handleChange}>
      <div className="rdw-option-wrapper">
        <EditIcon />
      </div>
    </div>
  )
}
