import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { useMutation, useQueryClient } from 'react-query'
import { useContext } from 'react'
import { CandidateContext } from 'contexts/candidate'
import { useMonitoring } from 'shared/hooks'

export const useSubmitJobOpportunity = (
  screeningAssignmentId: string,
  jobOpportunityId: string,
) => {
  const { post } = useNetwork()
  const { toastSuccess, toastError } = useToast()
  const { candidate } = useContext(CandidateContext)
  const { captureException } = useMonitoring()
  const client = useQueryClient()

  const { mutateAsync: submit, isLoading } = useMutation(
    async () =>
      post(`profile/${candidate.id}/screening-assignments/${screeningAssignmentId}/submissions`),
    {
      onSuccess: () => {
        toastSuccess('Application submitted.')
        client.invalidateQueries('job-opportunities')
        client.invalidateQueries(['job-opportunity', jobOpportunityId])
        client.invalidateQueries(`${candidate.id}-preferences`)
      },
      onError: (err) => {
        toastError('Failed to submit the application.')
        captureException(err)
      },
    },
  )

  return { submit, isLoading }
}
