import * as Dropdown from '@radix-ui/react-dropdown-menu'

import { buildSecondaryButtonStyles } from '../Button/SecondaryButton/SecondaryButton'
import { buildTertiaryButtonStyles } from '../Button/TertiaryButton/TertiaryButton'
import { ButtonType } from '../Button'

import { useStyledIcon } from 'shared/hooks'
import { cn } from 'modules/shared'

interface DropdownProps {
  children?: React.ReactNode
  text?: string
  icon?: JSX.Element
  triggerContent?: React.ReactNode
  size?: 'sm' | 'md' | 'lg' | 'xl'
  maxWidth?: string
  iconContainerClassName?: string
  buttonType?: ButtonType
  triggerClassName?: string
  contentClassName?: string
  disabled?: boolean
  align?: 'start' | 'center' | 'end'
  footer?: React.ReactNode
}

export const DropdownWrapper = ({
  children,
  text,
  icon,
  triggerContent,
  size = 'md',
  maxWidth = 'max-w-[241px]',
  iconContainerClassName,
  buttonType = ButtonType.Secondary,
  triggerClassName,
  contentClassName,
  disabled,
  align = 'end',
  footer,
}: DropdownProps) => {
  const { styleIcon } = useStyledIcon(size, icon)

  const triggerStyles = {
    [ButtonType.Secondary]: buildSecondaryButtonStyles(size, children),
    [ButtonType.Tertiary]: buildTertiaryButtonStyles(size, children),
  }

  return (
    <Dropdown.Root modal={false}>
      <Dropdown.Trigger
        className={cn(
          triggerStyles[buttonType],
          { 'text-neutral-medium': disabled },
          triggerClassName,
        )}
        disabled={disabled}
      >
        {Boolean(styleIcon) && <div className={iconContainerClassName}>{styleIcon}</div>}
        {text}
        {triggerContent}
      </Dropdown.Trigger>
      <Dropdown.Content
        align={align}
        className={cn([
          'bg-neutral-day rounded px-4 py-5 shadow-level-3 z-20',
          maxWidth,
          contentClassName,
        ])}
      >
        {children}
        {footer}
      </Dropdown.Content>
    </Dropdown.Root>
  )
}
