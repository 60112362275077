import { Expose } from 'class-transformer'
import { HiringMode } from 'shared/hooks'
import { OfferFormValues } from '../domain'

interface CreateJobOfferRequest {
  matchingId: string
  preferredStartDates: Array<{ startDate: string }>
  description: string
  jobTitle: string
  contractUrl: string | null
  supplementalTermsUrl: string | null
  hiringMode: HiringMode
  contactEmail: string
  contactFullName: string
}

@Expose()
export class CreateJobOfferDTO {
  constructor(
    private readonly matchingId: string,
    private readonly values: OfferFormValues,
    private readonly supplementalTermsUrl: string | null,
    private readonly hiringMode: HiringMode,
  ) {}

  toJSON(): CreateJobOfferRequest {
    const supplementalTermsUrl = this.requiresSupplementalTermsAcceptance
      ? this.supplementalTermsUrl
      : null

    return {
      matchingId: this.matchingId,
      preferredStartDates: this.preferredStartDates,
      description: this.values.description,
      jobTitle: this.values.jobTitle,
      contractUrl: this.contractUrl,
      supplementalTermsUrl,
      contactFullName: this.values.contactFullName,
      contactEmail: this.values.contactEmail,
      hiringMode: this.hiringMode,
    }
  }

  private get preferredStartDates() {
    const dates = this.values.preferredStartDates || []

    return dates.map((date) => ({
      startDate: [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getDate().toString().padStart(2, '0'),
      ].join('-'),
    }))
  }

  private get contractUrl() {
    const { contractUrl } = this.values
    if (contractUrl) return contractUrl

    return String(process.env.REACT_APP_DEFAULT_SAMPLE_AGREEMENT_URL)
  }

  private get requiresSupplementalTermsAcceptance() {
    return this.hiringMode !== HiringMode.ThroughUsEmployerOfRecord
  }
}
