import { ScreeningStatus } from '../enums'

export class ScreeningItem {
  id: string
  title: string
  status: ScreeningStatus
  author: { email: string }
  publisher?: { email: string }
  receivedCount: number
  pendingCount: number
  approvedCount: number
  rejectedCount: number
}
