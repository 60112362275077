import { Text } from 'shared/components/atoms'
import { Tasks } from 'modules/candidates/enums'
import { JobOpportunity } from 'job-opportunity/domain'

interface JobOpportunityOnHoldTagProps {
  appliedJobOpportunities: Array<JobOpportunity>
  holdTasks: Array<Tasks>
}

export const JobOpportunityOnHoldTag = ({
  appliedJobOpportunities,
  holdTasks,
}: JobOpportunityOnHoldTagProps) => {
  if (!appliedJobOpportunities.length || !holdTasks.length) return null
  return (
    <div className="bg-warning-lighter py-1 px-2 rounded-[4px] flex items-center justify-center">
      <Text size="text-xs" weight="font-medium" className="text-warning-darker">
        {appliedJobOpportunities.length}{' '}
        {appliedJobOpportunities.length === 1 ? 'opportunity' : 'opportunities'} on hold
      </Text>
    </div>
  )
}
