import classNames from 'classnames'
import { Text } from 'shared/components/atoms'
import { ReactComponent as Icon } from 'icons/check-circle.svg'

export const FieldSuccess = ({ message, className }: { message?: string; className?: string }) => {
  if (!message) return null

  return (
    <div className="flex items-center gap-2 mt-2">
      <Icon className="w-4 h-4 fill-success-medium" />
      <Text
        size="text-sm"
        weight="font-normal"
        className={classNames('text-success-dark', className)}
      >
        {message}
      </Text>
    </div>
  )
}
