import { ReactNode } from 'react'

import { SecondaryButton } from 'shared/components/atoms'
import { ReactComponent as EditIcon } from 'icons/edit.svg'
import { ReactComponent as AddIcon } from 'icons/add.svg'

interface UpsertButtonProps {
  onClick: () => void
  isAdd?: boolean
  children?: ReactNode
  className?: string
  readOnly: boolean
}

export const UpsertButton = ({
  onClick,
  isAdd,
  className,
  readOnly,
  children,
}: UpsertButtonProps) => {
  if (readOnly) return null

  return (
    <SecondaryButton
      icon={isAdd ? <AddIcon /> : <EditIcon />}
      isIconBefore
      size="sm"
      onClick={onClick}
      className={className}
    >
      <span className="hidden sm:block">{children || (isAdd ? 'Add new' : 'Edit')}</span>
    </SecondaryButton>
  )
}
