import { Text } from 'components/Text'
import { useFormatting } from 'shared/hooks'

export const MonthsGap = ({ startDate, endDate }: { startDate: Date; endDate?: Date }) => {
  const { formatMonthsGap } = useFormatting()
  const copy = formatMonthsGap(startDate, endDate)

  return (
    <>
      <Text size="body" color="text-neutral-dark" className="block sm:hidden">
        {copy}
      </Text>
      <Text size="small-body" color="text-neutral-dark" className="hidden sm:block">
        {copy}
      </Text>
    </>
  )
}
