import { MatchingFlagType } from 'matching/enums'
import { AssessmentTag, ProficiencyTag } from 'modules/profiles/domain'
import { Candidate, Matching } from 'candidates-search/domain'
import { SearchContext } from 'candidates-search/contexts'
import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react'

export const ResultCardStateContext = createContext({
  assessmentTags: [] as Array<AssessmentTag>,
  setAssessmentTags: (() => null) as Dispatch<SetStateAction<Array<AssessmentTag>>>,
  proficiencyTags: [] as Array<ProficiencyTag>,
  setProficiencyTags: (() => null) as Dispatch<SetStateAction<Array<ProficiencyTag>>>,
  doubleCheckingFlags: [] as Array<MatchingFlagType>,
  setDoubleCheckingFlags: (() => null) as Dispatch<SetStateAction<Array<MatchingFlagType>>>,
  matching: null as Matching | null,
  setMatching: (() => null) as Dispatch<SetStateAction<Matching | null>>,
})

export const ResultCardStateProvider = ({
  profile,
  children,
}: {
  profile: Candidate
  children: React.ReactNode
}) => {
  const { search } = useContext(SearchContext)
  const listingId = search?.listing?.id || ''

  const [assessmentTags, setAssessmentTags] = useState<Array<AssessmentTag>>(
    profile.assessmentTags || [],
  )
  const [proficiencyTags, setProficiencyTags] = useState<Array<ProficiencyTag>>(
    profile.proficiencies || [],
  )
  const [doubleCheckingFlags, setDoubleCheckingFlags] = useState<Array<MatchingFlagType>>(
    profile.getFlagsByJobListingId(listingId),
  )

  const [matching, setMatching] = useState<Matching | null>(
    profile.getMatchingByJobListingId(listingId) || null,
  )

  const value = {
    assessmentTags,
    setAssessmentTags,
    proficiencyTags,
    setProficiencyTags,
    doubleCheckingFlags,
    setDoubleCheckingFlags,
    matching,
    setMatching,
  }
  return <ResultCardStateContext.Provider value={value}>{children}</ResultCardStateContext.Provider>
}
