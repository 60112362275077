import { HideScrollContainer, Tooltip } from 'shared/components/atoms'
import { ProfileStatusBadge, ReferralStatusBadge } from 'components/molecules/Badges'

import { AssessmentsCollection } from '../AssessmentsCollection'
import { Heading } from './components/Heading'
import { LinksCollection } from './components/LinksCollection'
import { PublicPreferences } from './components/PublicPreferences'

import { ReactComponent as InfoIcon } from 'icons/info-icon.svg'

import { useDate } from 'shared/hooks'

import { ProfileOverview } from 'pages/JobSeekerProfile/types'

interface ReferralSidebarProps {
  profile: ProfileOverview
}

export const ReferralSidebar = ({ profile }: ReferralSidebarProps) => {
  const { getDifferenceInDays } = useDate()
  const days = getDifferenceInDays(profile.preferences?.pausedUntil ?? undefined)

  return (
    <HideScrollContainer className="flex flex-col gap-12 h-full static lg:sticky lg:top-0 lg:h-screen lg:overflow-y-scroll lg:-mt-32 lg:-mb-32 lg:pt-32">
      <div className="flex flex-col gap-6 w-full max-w-[264px]">
        <Heading profile={profile} readOnly onEdit={() => {}} />
        <ProfileStatusBadge
          status={profile.preferences?.status}
          days={days}
          withBackground
          className="w-fit h-8"
        />

        {Boolean(profile.referralStatus) &&
          (profile.referralDescription ? (
            <Tooltip content={profile.referralDescription}>
              <div className="flex gap-2 items-center">
                <ReferralStatusBadge status={profile.referralStatus!} /> <InfoIcon />
              </div>
            </Tooltip>
          ) : (
            <ReferralStatusBadge status={profile.referralStatus!} />
          ))}
      </div>

      <PublicPreferences profile={profile} />

      <LinksCollection profile={profile} title="Links" />

      <AssessmentsCollection profile={profile} />
    </HideScrollContainer>
  )
}
