import { useUnauthenticatedNetwork } from 'shared/hooks'
import { useQuery } from 'react-query'

const nullReferralLink = {
  fullName: '',
  photoUrl: '',
}

export const useReferralLink = (referrerSlug: string, token: string) => {
  const { get } = useUnauthenticatedNetwork()

  const { data: referralLink = nullReferralLink, isLoading } = useQuery(
    ['referral-links', referrerSlug],
    () =>
      get<{ email: string; fullName: string; photoUrl: string }>(
        `/referral-links/${referrerSlug}`,
        { headers: { 'X-RECAPTCHA-TOKEN': token } },
      ).then(({ data }) => data),
    {
      enabled: Boolean(referrerSlug && token),
    },
  )

  return { referralLink, isLoading }
}
