import { useNetwork } from 'shared/hooks'
import { FetchScreeningDTO, FetchScreeningResponse } from 'screening/dtos'
import { useQuery } from 'react-query'

export const useFetchScreening = (listingId: string, screeningId?: string) => {
  const { get } = useNetwork()

  const getScreening = (screeningId: string) =>
    get<FetchScreeningResponse>(`listings/${listingId}/screenings/${screeningId}`).then(
      ({ data }) => new FetchScreeningDTO(data).toDomain(),
    )

  const { data: screening, isLoading } = useQuery(
    ['screening', screeningId],
    () => getScreening(screeningId!),
    { enabled: Boolean(screeningId) },
  )

  return { screening, isLoading, getScreening }
}
