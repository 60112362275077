import { User } from 'shared/hooks'
import { useMemo } from 'react'

// @todo - move this to an environment variable
const blacklist = ['none']

export const useBlacklist = (user: User) => {
  const userDomain = useMemo(() => user.email.split('@').pop(), [user])
  const isUserBlocked = useMemo(() => blacklist.includes(userDomain!), [userDomain])

  return { isUserBlocked }
}
