import * as InfiniteScrollPrimitive from 'react-infinite-scroll-component'
import { Loading } from '../Button'
import { Text } from '../Text'

interface InfiniteScrollProps {
  dataLength: number
  onFetchMore: () => void
  hasMore: boolean
  children: JSX.Element
  isFirstPage: boolean
  scrollableTarget?: string
  endMessage?: string
}

export const InfiniteScroll = ({
  dataLength,
  onFetchMore,
  hasMore,
  children,
  isFirstPage,
  scrollableTarget,
  endMessage = "You've reached the bottom. There is nothing else here.",
}: InfiniteScrollProps) => {
  return (
    <InfiniteScrollPrimitive.default
      dataLength={dataLength}
      next={onFetchMore}
      hasMore={hasMore}
      loader={
        <div className="flex justify-center py-4">
          <Loading size="xl" bgColor="bg-neutral-day" />
        </div>
      }
      endMessage={
        <>
          {!isFirstPage && (
            <div className="flex justify-center py-4 text-center">
              <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
                {endMessage}
              </Text>
            </div>
          )}
        </>
      }
      scrollableTarget={scrollableTarget}
    >
      {children}
    </InfiniteScrollPrimitive.default>
  )
}
