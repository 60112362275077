import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { useEffect } from 'react'
import { useTracking, PayRateModalImpression } from 'tracking'

interface PayRateModalImpressionTrackerProps {
  profile: ProfileOverview
  isDirectAccess: boolean
}

export const PayRateModalImpressionTracker = ({
  profile,
  isDirectAccess,
}: PayRateModalImpressionTrackerProps) => {
  const { trackEvent } = useTracking()

  useEffect(() => {
    trackEvent(
      new PayRateModalImpression(profile.id, profile.interestRoles[0]?.label, isDirectAccess),
    )
  }, [])

  return null
}
