import { useLocalStorage } from 'shared/hooks'

const WATCH_INSTRUCTIONS_KEY = 'watchedReferInstructions'

export const useInstructionsModal = () => {
  const [watchedInstructions, setWatchedInstructions] = useLocalStorage(
    WATCH_INSTRUCTIONS_KEY,
    false,
  )

  const onWatch = () => setWatchedInstructions(true)

  return { watchedInstructions, onWatch }
}
