import { Expose } from 'class-transformer'

@Expose()
export class Location {
  constructor(
    private readonly city: string,
    private readonly state: string,
    private readonly country: string,
    private readonly utcOffsetMinutes: number,
  ) {}

  get label() {
    const stateName =
      this.state.indexOf('State of ') !== -1 ? this.state.split('State of ')[1] : this.state

    const location = [this.city, stateName, this.country]
      .filter((value) => Boolean(value))
      .join(', ')

    return `${location} (${this.timezone})`
  }

  get blurLocation() {
    return `${this.country} (${this.timezone})`
  }

  get timezone() {
    return `GMT${new Intl.NumberFormat('en-US', {
      signDisplay: 'exceptZero',
    }).format(this.utcOffsetMinutes / 60)}`
  }
}
