import { AnswerRating, AudioQuality, VideoQuality } from '../../enums'

export class EvaluationAnswers {
  recorderMatchesProfile: boolean
  videoQuality: VideoQuality
  videoQualityDescription: string | null
  audioQuality: AudioQuality
  audioQualityDescription: string | null
  sentence?: AnswerRating
  sentenceDescription?: string | null
  verbsConjugation?: AnswerRating
  verbsConjugationDescription?: string | null
  sentenceStructuring?: AnswerRating
  sentenceStructuringDescription?: string | null
  paraphrasing?: AnswerRating
  paraphrasingDescription?: string | null
  wordsVariety?: AnswerRating
  wordsVarietyDescription?: string | null
  wordsAccuracy?: AnswerRating
  wordsAccuracyDescription?: string | null
  speechRate?: AnswerRating
  speechRateDescription?: string | null
  logicalSequencing?: AnswerRating
  logicalSequencingDescription?: string | null
  cohesion?: AnswerRating
  cohesionDescription?: string | null
  firstLanguageInfluence?: AnswerRating
  firstLanguageInfluenceDescription?: string | null
  unintelligibleWordsPerSentence?: AnswerRating
  unintelligibleWordsPerSentenceDescription?: string | null
  meaningGraspingEffort?: AnswerRating
  meaningGraspingEffortDescription?: string | null

  technicalChallengeMentioned?: boolean
  technicalChallengeResponsibilitiesMentioned?: boolean
  technicalChallengeApproachMentioned?: boolean
  technicalChallengeOutcomeMentioned?: boolean
  technicalChallengeLearningsMentioned?: boolean

  questionsMentioned?: boolean

  naturalSpeaking?: boolean
  mentionedPersonalInformation?: boolean
  additionalComments?: string | null
}
