import { ContactOptions } from 'modules/hires/enums'
import { array, date, mixed, object, string } from 'yup'

export { RetentionOfferForm } from './RetentionOfferForm'

export const schema = object()
  .shape({
    jobTitle: string().required('Please share the job title for this offer.'),
    preferredStartDates: array()
      .of(date())
      .min(2, 'Suggest at least 2 start dates')
      .max(10, 'Suggest up to 10 start dates'),
    contact: mixed().oneOf(Object.values(ContactOptions), 'Please select one option.'),
    contactFullName: string().when('contact', {
      is: ContactOptions.SomeoneElse,
      then: string().required('Please inform a contact name.'),
      otherwise: string().optional(),
    }),
    contactEmail: string().when('contact', {
      is: ContactOptions.SomeoneElse,
      then: string()
        .required('Please inform a contact email.')
        .email('Please enter a valid email.'),
      otherwise: string().optional(),
    }),
  })
  .required()
