import { AlertDialog } from 'shared/components/molecules'
import { Text } from 'shared/components/atoms'
import { useNavigate } from 'react-router-dom'

export const MissingWorkspaceSettingsDialog = ({ listingId }: { listingId: string }) => {
  const navigate = useNavigate()

  const handleDialogCancel = () => navigate(`/listings/${listingId}/matchings`, { replace: true })
  const handleDialogConfirm = () => navigate('/settings')

  return (
    <AlertDialog
      isOpen
      preventOpenChangeHandling
      title="Workspace setting required"
      description={
        <Text size="text-base" weight="font-normal">
          Before setting a new screening, please ensure that all general workspace settings are set.
        </Text>
      }
      onCancelClick={handleDialogCancel}
      confirmText="Go to settings"
      onConfirmClick={handleDialogConfirm}
    />
  )
}
