import classNames from 'classnames'
import { Text } from 'shared/components/atoms'

import { ReactComponent as CheckIcon } from 'icons/check-circle-outlined.svg'
import { ReactComponent as XSquareIcon } from 'icons/x-square--outlined.svg'

interface ContentAuditProps {
  issues: Array<{ label: string; description?: string | null }>
  showHeader?: boolean
  showDescription?: boolean
  showSuccess?: boolean
  className?: string
}

export const ContentAudit = ({
  issues,
  showHeader = true,
  showDescription = true,
  showSuccess = true,
  className,
}: ContentAuditProps) => {
  return (
    <div className={classNames('flex flex-col gap-6', className)}>
      {showHeader && (
        <div className="flex flex-col gap-1">
          <Text size="text-base" weight="font-medium">
            Content audit 📝
          </Text>
          <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
            Check if the video contents were the same as requested by Strider
          </Text>
        </div>
      )}
      <div>
        {issues.length ? (
          <ul className="flex flex-col gap-2">
            {issues.map((issue) => (
              <li
                key={issue.label}
                className="flex gap-4 items-start bg-warning-lighter py-2 px-3 rounded"
              >
                <XSquareIcon className="h-[20px] w-[20px] min-w-[20px] stroke-warning-medium" />
                <div className="flex flex-col">
                  <Text size="text-sm" weight="font-normal">
                    {issue.label}
                  </Text>
                  {Boolean(issue.description) && showDescription && (
                    <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
                      {issue.description}
                    </Text>
                  )}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          showSuccess && (
            <div className="flex gap-3 bg-success-lighter rounded py-2 px-3">
              <CheckIcon className="stroke-success-medium h-[20px] w-[20px]" />
              <Text size="text-sm" weight="font-normal">
                All topics covered
              </Text>
            </div>
          )
        )}
      </div>
    </div>
  )
}
