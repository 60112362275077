import { useFormatting } from 'shared/hooks'
import { useMemo } from 'react'
import { PaymentModel } from 'job-listing/enums'

interface UseCompensationProps {
  compensation?: number | null
  minimumPayRate?: number | null
  maximumPayRate?: number | null
  paymentModel?: PaymentModel | null
}

export const useCompensation = ({
  compensation,
  minimumPayRate,
  maximumPayRate,
  paymentModel,
}: UseCompensationProps) => {
  const { formatCurrencyRange } = useFormatting()

  const formatCompensationLabel = (
    minimumPayRate?: number | null,
    maximumPayRate?: number | null,
    paymentModel?: PaymentModel | null,
  ) => {
    if (!paymentModel) return null

    const paymentModelLabels = {
      [PaymentModel.Monthly]: 'month',
      [PaymentModel.Hourly]: 'hour',
    }

    const formattedRange = formatCurrencyRange(minimumPayRate, maximumPayRate)

    if (!formattedRange) return null
    return `${formattedRange} / ${paymentModelLabels[paymentModel]}`
  }

  const compensationTitle = useMemo(() => {
    if (!compensation && !paymentModel) return

    if (compensation) return `Your ${paymentModel} pay rate`

    return `${paymentModel!.charAt(0).toUpperCase() + paymentModel!.slice(1)} pay rate`
  }, [compensation, paymentModel])

  const compensationLabel = useMemo(() => {
    if (compensation) return formatCompensationLabel(compensation, null, paymentModel)

    return formatCompensationLabel(minimumPayRate, maximumPayRate, paymentModel)
  }, [compensation, minimumPayRate, maximumPayRate, paymentModel])

  return {
    compensationTitle,
    compensationLabel,
  }
}
