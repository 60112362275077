import { CompanySize } from 'company/enums'
import { Preferences } from 'job-opportunity/domain'
import { FormListing } from 'job-listing/domains'
import { ContractLength, EngagementType } from 'job-listing/enums'
import { EnglishResult } from 'vetting/enums'

export class JobDescription {
  title: string
  minimumTotalExperience: number
  minimumEnglishLevel?: EnglishResult
  engagementType?: EngagementType | null
  contractDuration?: ContractLength
  workingHoursPerWeek?: number
  requiredSkills: Array<{ id: number; label: string }>
  detailedRequirements: string
  projectDescription?: string
  benefits: string
  processOverview?: string
  marketType?: string
  companyName?: string
  companyWebsite?: string
  companySize?: CompanySize
  hiringThroughUs: boolean

  static buildFromJobListing(jobListing: FormListing) {
    return {
      title: jobListing.jobTitle,
      minimumTotalExperience: jobListing.experienceRange?.min || 0,
      minimumEnglishLevel: undefined,
      engagementType: jobListing.engagementType,
      contractDuration: jobListing.contractLength,
      workingHoursPerWeek: jobListing.freelancingWorkingHoursPerWeek,
      requiredSkills: jobListing.stacks?.map(({ id, label }) => ({ id, label })) || [],
      detailedRequirements: jobListing.detailedRequirements,
      projectDescription: jobListing.projectDescription,
    } as JobDescription
  }

  get matchingSkills() {
    return []
  }

  get missingSkills() {
    return this.requiredSkills
  }

  get minimumYearsOfExperience() {
    return this.minimumTotalExperience
  }

  get misalignments() {
    return [] as Array<{ preference: Preferences; label: string }>
  }

  get misalignedPreferences(): Array<Preferences> {
    return []
  }
}
