import { ReactComponent as CloseIcon } from 'icons/warning-close.svg'

export const SizeWarning = ({ setVisible }: { setVisible(visible: boolean): void }) => {
  return (
    <div role="alert" className="mt-4 p-4 flex flex-row items-start gap-2 bg-warning-lighter">
      <button onClick={() => setVisible(false)}>
        <CloseIcon />
      </button>
      <p className="font-normal text-sm text-warning-dark">
        We are unable to upload files over 10 MB. Try uploading a smaller or compressed PDF file.
      </p>
    </div>
  )
}
