import { PreviewLabel } from 'shared/components/atoms'
import { Tags } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

import { ReactComponent as CheckIcon } from 'icons/check-circle.svg'

import { usePayRateTags } from 'hooks/payRate'

interface PayRateProps {
  hourlyRate?: {
    min?: number
    max?: number
  }
  monthlyRate: {
    min?: number
    max?: number
  }
  openToNegotiation: boolean
}

export const PayRate = ({ hourlyRate, monthlyRate, openToNegotiation }: PayRateProps) => {
  const payRateTags = usePayRateTags({ monthlyRate, hourlyRate })

  return (
    <PreviewLabel title="Pay rate (USD)" disabled={true}>
      <Tags values={payRateTags} background="bg-neutral-lightest" isReadOnly />
      {openToNegotiation && (
        <div className="mt-4">
          <CheckIcon className="w-4 h-4 fill-neutral-medium inline mr-2" />
          <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
            Flexible to negotiate
          </Text>
        </div>
      )}
    </PreviewLabel>
  )
}
