import { cn } from 'modules/shared'
import { AdditionalInformation, ProfileHeader } from './components'
import { Candidate } from 'candidates-search/domain'

interface CandidateInfoProps {
  candidate: Candidate
  fromDrawer?: boolean
  children?: React.ReactNode
  minimal?: boolean
}

export const CandidateInfo = ({ candidate, fromDrawer, children, minimal }: CandidateInfoProps) => {
  return (
    <div
      className={cn(
        'flex flex-col gap-8 xl:shrink-0',
        'w-full',
        'bg-neutral-lightest bg-opacity-50 rounded-lg p-6',
        'transition-all duration-300',
        {
          'flex-row gap-4': fromDrawer,
          'xl:w-[300px]': !fromDrawer,
          'py-3 pl-10 items-center rounded-none': minimal,
        },
      )}
    >
      <ProfileHeader
        candidate={candidate}
        minimal={minimal}
        containerClassName={cn({ 'min-w-[344px]': fromDrawer })}
      />
      {!minimal && <AdditionalInformation candidate={candidate} />}
      {children}
    </div>
  )
}
