import { useMonitoring, useNetwork } from 'shared/hooks'

import { useToast } from 'shared/hooks'
import { useMutation } from 'react-query'

export const useTeleport = () => {
  const { toastError } = useToast()
  const { post } = useNetwork()
  const { captureException } = useMonitoring()

  const { mutateAsync: teleport, isLoading: isTeleporting } = useMutation((companyId: number) =>
    post(`/companies/${companyId}/teleports`).catch((error: RequestError) => {
      toastError('Failed to teleport. Please try again later.')
      captureException(error)
    }),
  )

  return { teleport, isTeleporting }
}
