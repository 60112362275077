import { Roles } from 'account/enums'

type SendInvitesRequest = Array<{ email: string; role: Roles }>

export type SendInvitesValues = Array<{
  email: string
  role: Roles.EmployerOwner | Roles.EmployerViewer
}>

export class SendInvitesDTO {
  constructor(private readonly values: SendInvitesValues) {}

  toJSON(): SendInvitesRequest {
    return this.values
  }
}
