import { useMemo } from 'react'

export const useTabs = (isStriderStaff: boolean) => {
  const tabs = useMemo(
    () => [
      ...(isStriderStaff ? [{ tab: 'overview', label: 'Overview' }] : []),
      { tab: 'refer', label: 'Refer Talent' },
      { tab: 'handbook', label: 'Handbook' },
    ],
    [isStriderStaff],
  )

  return {
    tabs,
  }
}
