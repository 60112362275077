import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Dashboard } from './pages'

import { Spinner } from 'shared/components/atoms'

import { CandidateContext } from 'contexts/candidate'

import { useVettingPackage } from 'vetting/exports'
import { useJobListingApplication } from 'hooks/applications'
import { ExternalApplicationModal } from './components/ExternalApplicationModal'

interface Props {
  isJobOpportunity?: boolean
}

export const JobSeekerHomepage = ({ isJobOpportunity = false }: Props) => {
  const { candidate, loadCandidate, isLoading, isFirstLoading } = useContext(CandidateContext)
  const { vettingPackage, isFirstLoading: isVettingLoading } = useVettingPackage()
  const { isJobApplicationFlow, applyOnJobListing, isFeedbackModalOpen, setIsFeedbackModalOpen } =
    useJobListingApplication()
  const navigate = useNavigate()

  const isApplicationFlow = isJobApplicationFlow()

  useEffect(() => loadCandidate(), [])

  useEffect(() => {
    if (isLoading || !candidate.id) return

    if (candidate.outOfBounds?.byCountry) {
      navigate('/welcome-out-of-bounds')
      return
    }

    if (candidate.outOfBounds?.byOpenToRole) {
      navigate('/welcome-unsupported')
      return
    }

    if (candidate.canInviteFriends && !candidate.invitedFriends) {
      navigate('/invite-friends')
      return
    }

    if (isApplicationFlow && !isJobOpportunity) {
      applyOnJobListing()
      return
    }
  }, [candidate, isLoading, isApplicationFlow])

  const handleCancel = () => setIsFeedbackModalOpen(false)

  if (!candidate.id || isFirstLoading || isVettingLoading) return <Spinner />

  return (
    <>
      <Dashboard profile={candidate} vetting={vettingPackage} />
      <ExternalApplicationModal
        open={isFeedbackModalOpen}
        onCancel={handleCancel}
        profile={candidate}
        vetting={vettingPackage}
      />
    </>
  )
}
