import { useMemo } from 'react'

import { IconBanner } from 'shared/components/molecules'
import { ReviewListingCard } from 'job-listing/exports'

import { ReactComponent as InfoIcon } from 'icons/info-circle.svg'

import { useHelp } from 'modules/help'

import { EngagementType, contractLengthLabels } from 'job-listing/enums'
import { companySizeLabels } from 'company/enums'
import { COMPANY_SIZE_EMPLOYEES_NUMBER } from 'company/consts'

import {
  JobDescription as JobDescriptionInJobOpportunity,
  Preferences,
} from 'job-opportunity/domain'
import { JobDescription as JobDescriptionInScreening } from 'screening/domain'

const INTERCOM_ARTICLE_ID = 6752045

interface InfoCardsProps {
  jobDescription: JobDescriptionInJobOpportunity | JobDescriptionInScreening
  compensationTitle?: string | null
  compensationLabel?: string | null
  showMisalignments?: boolean
}

export const InfoCards = ({
  jobDescription,
  compensationTitle,
  compensationLabel,
  showMisalignments,
}: InfoCardsProps) => {
  const {
    minimumEnglishLevel,
    minimumYearsOfExperience,
    companySize,
    marketType,
    engagementType,
    hiringThroughUs,
    contractDuration,
    workingHoursPerWeek,
  } = jobDescription

  const { showArticle } = useHelp()

  const engagementTypeText = {
    [EngagementType.LongTerm]: 'Long-term',
    [EngagementType.ShortTerm]: 'Short-term',
  }

  const { misalignedPreferences } = jobDescription

  const contractTypeDescription = useMemo(() => {
    if (!engagementType) return 'N/A'

    if (engagementType === EngagementType.LongTerm || !workingHoursPerWeek) {
      return engagementTypeText[engagementType]
    }

    return `${engagementTypeText[engagementType]} (${workingHoursPerWeek}h / week)`
  }, [engagementType, workingHoursPerWeek])

  const cards = [
    {
      title: compensationTitle,
      description: compensationLabel,
      warning:
        misalignedPreferences.includes(Preferences.MonthlyRate) ||
        misalignedPreferences.includes(Preferences.HourlyRate),
      visible: Boolean(compensationTitle && compensationLabel),
    },
    {
      title: 'Minimum required experience',
      description: minimumYearsOfExperience
        ? `${minimumYearsOfExperience} year${minimumYearsOfExperience > 1 ? 's' : ''}`
        : 'Any',
      warning: misalignedPreferences.includes(Preferences.Experience),
      visible: true,
    },
    {
      title: 'Minimum english level',
      description: minimumEnglishLevel ?? 'N/A',
      warning: misalignedPreferences.includes(Preferences.EnglishLevelB1Applicant),
      visible: true,
    },
    {
      title: 'Contract type',
      description: contractTypeDescription,
      warning:
        misalignedPreferences.includes(Preferences.EngagementType) ||
        misalignedPreferences.includes(Preferences.WorkingHours),
      visible: true,
    },
    {
      title: 'Company size (employees)',
      description: companySize
        ? `${companySizeLabels[companySize]} (${COMPANY_SIZE_EMPLOYEES_NUMBER[companySize]})`
        : 'N/A',
      warning: misalignedPreferences.includes(Preferences.CompanySize),
      visible: true,
    },
    {
      title: 'Expected contract duration',
      description: contractDuration ? contractLengthLabels[contractDuration] : 'N/A',
      warning: false,
      visible: Boolean(engagementType === EngagementType.ShortTerm && contractDuration),
    },
    {
      title: 'Market/Industry',
      description: marketType ?? 'N/A',
      warning: misalignedPreferences.includes(Preferences.MarketType),
      visible: true,
    },
  ].filter(({ visible }) => visible)

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        {cards.map((card, index) => (
          <ReviewListingCard
            key={index}
            title={card.title!}
            description={card.description!}
            warning={showMisalignments && card.warning}
            className={
              cards.length % 2 !== 0 && index === cards.length - 1 ? 'col-span-2' : 'col-span-1'
            }
          />
        ))}
      </div>
      {Boolean(hiringThroughUs) && (
        <div className="mt-4">
          <IconBanner icon={<InfoIcon className="w-6 h-6 flex-shrink-0 stroke-neutral-medium" />}>
            <>
              Strider acts as an intermediary and manages the contract details on behalf of the
              company.{' '}
              <span
                className="text-info-medium cursor-pointer"
                onClick={() => showArticle(INTERCOM_ARTICLE_ID)}
              >
                Learn more
              </span>
            </>
          </IconBanner>
        </div>
      )}
    </div>
  )
}
