import { Step } from './components/Step'

import { useSteps } from './hooks'

interface StepsProps {
  profileCompleted: boolean
  englishCheckCompleted: boolean
}

export const Steps = ({ profileCompleted, englishCheckCompleted }: StepsProps) => {
  const steps = useSteps(profileCompleted, englishCheckCompleted)

  return (
    <ul className="flex gap-3">
      {steps.map((step, index) => (
        <Step step={step} index={index} key={index} />
      ))}
    </ul>
  )
}
