import { Stack } from 'modules/stack'
import { CertificationFormValues } from '../domain'

interface UpsertCertificationRequest {}

export class UpsertCertificationDTO {
  constructor(private readonly certification: CertificationFormValues) {}

  private getDate(dateMonth: number, dateYear: number): string {
    const date = new Date(dateYear, dateMonth, 1)
    const year = date.getFullYear()
    const month = date.getMonth() + 1

    const formattedMonth = month.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })

    return [year, formattedMonth, '01'].join('-')
  }

  toJSON(): UpsertCertificationRequest {
    return {
      name: this.certification.name,
      stacks: this.certification.stacks.map((stack: Stack) => stack.id),
      institutionId: this.certification.institutionId,
      issueDate: this.getDate(this.certification.issueDateMonth, this.certification.issueDateYear),
      expireDate: this.certification.doesNotExpire
        ? null
        : this.getDate(this.certification.expireDateMonth, this.certification.expireDateYear),
      credentialId: this.certification.credentialId || null,
      credentialUrl: this.certification.credentialUrl || null,
    }
  }
}
