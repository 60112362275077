import { Text } from 'shared/components/atoms'
import { ReactComponent as CheckIcon } from 'icons/check-circle-outlined.svg'

export const InterviewRequestedBadge = () => {
  return (
    <div className="flex gap-2 items-center justify-center px-4 py-2 h-10 bg-success-lighter">
      <CheckIcon className="w-4 h-4 stroke-success-dark" />
      <Text size="text-sm" weight="font-normal" className="text-success-dark">
        Interview requested
      </Text>
    </div>
  )
}
