import { PreviewLabel } from 'shared/components/atoms'
import { ProfileStatusBadge } from 'components/molecules/Badges'
import { Preferences } from 'modules/profiles/profile-preferences'
import { useDate } from 'shared/hooks'

export const Availability = ({ preferences }: { preferences: Preferences }) => {
  const { getDifferenceInDays } = useDate()
  const days = getDifferenceInDays(preferences.pausedUntil)

  return (
    <PreviewLabel title="Availability status" disabled id="availability-status">
      <ProfileStatusBadge
        status={preferences.availability}
        days={days}
        withBackground
        className="w-fit h-8"
      />
    </PreviewLabel>
  )
}
