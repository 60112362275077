import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { plainToInstance } from 'class-transformer'

import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

import { CompanyBasicInfo } from 'company/domain'
import { OrganizationIcon } from 'shared/components/molecules'

export const useCompanies = () => {
  const { get } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const {
    data: companiesData = [],
    refetch,
    isLoading,
    isRefetching,
  } = useQuery(
    'companies',
    async () =>
      await get<Array<CompanyBasicInfo>>('companies')
        .then(({ data }) => plainToInstance(CompanyBasicInfo, data))
        .catch((error: RequestError) => {
          toastError('Error while fetching companies')
          captureException(error)
        }),
    {
      staleTime: 5 * 60 * 1000,
    },
  )

  const companiesOptions = useMemo(
    () => companiesData.map(({ id, name }) => ({ id, label: name })),
    [companiesData],
  )
  const pluralize = (count: number) => (count > 1 ? 's' : '')
  const approvedCompanyOptions = useMemo(
    () =>
      companiesData
        .filter(({ approved }) => approved)
        .map((company) => ({
          id: company.id,
          label: company.name,
          icon: OrganizationIcon({ domain: company.website, size: 'sm' }),
          description: `${company.activeListingsCount} Active listing${pluralize(company.activeListingsCount)} • ${company.usersCount} Team member${pluralize(company.usersCount)}`,
        })),
    [companiesData],
  )

  return {
    companiesOptions,
    approvedCompanyOptions,
    companies: companiesData,
    refetch,
    isLoading: isLoading || isRefetching,
  }
}
