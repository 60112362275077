import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'

import { RouterContext } from 'contexts/router'

import { useLandingTracking, useNetwork } from 'shared/hooks'
import { useLocalStorage } from 'shared/hooks/storage'

const onboardingKey = 'onb'

export const useOnboarding = () => {
  const route = useContext(RouterContext)

  const client = useQueryClient()
  const { post } = useNetwork()
  const navigate = useNavigate()
  const { getLandingLocation } = useLandingTracking()
  const [seamlessOnboarded, setSeamlessOnboarded] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData, clear] = useLocalStorage<Nullable<Record<string, any>>>(onboardingKey, null)

  const { mutateAsync: doSeamlessOnboarding } = useMutation(async () => post('/onboarding'), {
    onSuccess: async () => {
      setSeamlessOnboarded(true)
      await client.invalidateQueries('pending-agreements')
      await client.invalidateQueries('accounts/me')
      navigate(route.from ?? getLandingLocation())
    },
  })

  const getPartialData = () => data

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const persistPartialData = (values: Record<string, any>) => setData(values)

  return {
    doSeamlessOnboarding,
    seamlessOnboarded,
    getPartialData,
    persistPartialData,
    clearPersistedData: clear,
  }
}
