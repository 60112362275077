import { Text, FieldWrapper } from 'shared/components/atoms'
import { DetailedRequirements, ProjectDescription } from 'job-listing/components'
import { FormControl } from 'shared/hooks'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const JobDescription = ({ register }: { register: (key: string) => any }) => {
  return (
    <div className="mt-16">
      <Text size="text-xl" weight="font-medium" className="text-neutral-darkest">
        Job description
      </Text>
      <FieldWrapper className="!mt-10">
        <DetailedRequirements
          formControl={{ register } as FormControl}
          name="detailedRequirements"
        />
      </FieldWrapper>

      <FieldWrapper className="!mt-3">
        <ProjectDescription formControl={{ register } as FormControl} name="projectDescription" />
      </FieldWrapper>
    </div>
  )
}
