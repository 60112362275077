import { PaymentModel } from 'job-listing/enums'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'

export const useHiringCost = (profile: ProfileOverview, matchingId: string) => {
  const matching = profile.matchingsForOffer.find(({ id }) => id === matchingId)
  const hiringCost = Number(matching?.hiringCost ?? 0)
  const paymentModel = matching?.paymentModel || PaymentModel.Monthly

  return { hiringCost, paymentModel }
}
