import { AppealingTitle, Text, TitleLevels } from 'shared/components/atoms'
import { TrustpilotScore } from 'authentication/components'

import { Candidates } from './components'

export const AsideContent = () => {
  return (
    <div
      className={`
        w-full flex flex-col justify-start items-center gap-6 p-8
      bg-neutral-day bg-opacity-33 rounded-2xl
        max-h-full overflow-x-hidden overflow-y-auto
      `}
    >
      <div className="flex flex-col gap-4 items-center">
        <AppealingTitle level={TitleLevels.H2} className="text-neutral-600 text-center">
          Join Strider as a Freelance Tech Recruiter
        </AppealingTitle>
        <Text size="text-lg" className="text-neutral-600 text-center">
          Create your account to connect top developers with leading companies and start earning
          rewards, in USD, today.
        </Text>
      </div>

      <Candidates />

      <TrustpilotScore />
    </div>
  )
}
