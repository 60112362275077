import classNames from 'classnames'
import { ReactNode, useState, MouseEvent } from 'react'
import { Size, Weight } from '.'

export interface ParagraphProps {
  children: ReactNode
  size?: Size
  weight?: Weight
  className?: string
  truncate?: boolean
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
  id?: string
}

const styles = {
  'body-lg': 'text-xl leading-8',
  'body-md': 'text-base leading-8',
  'body-sm': 'text-sm leading-6',
  'body-xs': 'text-xs leading-5',
  'body-2xs': 'text-[0.625rem] leading-4',
} as Record<Size, string>

export const Paragraph = ({
  children,
  className,
  truncate = true,
  size = 'body-sm',
  weight = 'font-normal',
  onClick,
  id,
}: ParagraphProps) => {
  const [showReadMore, setShowReadMore] = useState(children?.toString().length! > 300)

  return (
    <div
      id={id}
      className={classNames('font-inter whitespace-pre-wrap', styles[size], weight, className)}
      onClick={onClick}
    >
      {showReadMore && truncate ? (
        <>
          <p className="hidden print:block">{children}</p>
          <p className="relative line-clamp-3 text-ellipsis print:hidden">
            <span>{children}</span>
            <span
              onClick={() => setShowReadMore(false)}
              className="absolute right-0 bottom-0 bg-white cursor-pointer "
            >
              {'... '}
              <span className="text-inherit !text-neutral-darkest !font-medium">Read more</span>
            </span>
          </p>
        </>
      ) : (
        <p className="text-inherit overflow-hidden text-ellipsis">{children}</p>
      )}
    </div>
  )
}
