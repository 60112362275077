import classNames from 'classnames'

export const Line = ({ showLine, expanded }: { showLine: boolean; expanded?: boolean }) => {
  return (
    <div
      className={classNames(
        'min-w-[2px] -ml-14 mr-14 -mb-10',
        {
          'bg-neutral-light': showLine,
          'bg-white': !showLine,
        },
        expanded ? '-mt-[60px]' : '-mt-10',
      )}
    />
  )
}

export const Dot = () => {
  return <div className="bg-neutral-light w-2 h-2 rounded-full -ml-[59px] mr-[52px] mt-[10px]" />
}
