import { array, object, string } from 'yup'

export const schema = object().shape({
  assessments: array().of(
    object({
      id: string().required(),
      name: string().required(),
      type: string().required(),
    }),
  ),
})
