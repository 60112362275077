import { useHelp } from 'modules/help'
import { useRef, useEffect } from 'react'

export const usePageTitle = (title: string | null) => {
  const defaultTitle = useRef('Strider')
  const { onNavigation } = useHelp()

  useEffect(() => {
    document.title = title ? `${title} | Strider` : defaultTitle.current
    if (title) {
      onNavigation()
    }
  }, [title])

  useEffect(
    () => () => {
      document.title = defaultTitle.current
    },
    [],
  )
}
