import { AssessmentTag } from 'modules/profiles/domain'
import { AssessmentTagType } from 'modules/profiles/enums'

const sortMap = {
  [AssessmentTagType.RedFlag]: 1,
  [AssessmentTagType.Warning]: 2,
  [AssessmentTagType.Quality]: 3,
}

export const useSortTags = (tags: Array<AssessmentTag>) => {
  return [...tags].sort((a, b) => sortMap[a.type] - sortMap[b.type])
}
