import { AccountDeletionRequest } from 'account/domain'
import { useMutation } from 'react-query'
import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useRequestAccountDeletion = (accountId: string) => {
  const { delete: remove } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const { mutateAsync: requestAccountDeletion, isLoading } = useMutation(
    (payload: { tags: Array<String>; additionalComments: string }) => {
      return remove<AccountDeletionRequest>(`accounts/${accountId}`, { data: payload }).then(
        ({ data }) => data,
      )
    },
    {
      onError: (error) => {
        toastError('Failed to request the account deletion')
        captureException(error)
      },
    },
  )

  return { requestAccountDeletion, isLoading }
}
