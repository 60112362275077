import classNames from 'classnames'
import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { ReactNode } from 'react'

interface Props {
  iconPath: string
  title: string
  description: string
  cta: ReactNode
  iconClassName?: string
}

export const Task = ({ iconPath, title, description, cta, iconClassName }: Props) => {
  return (
    <div className="flex gap-4">
      <div
        className={classNames(
          'flex items-center justify-center bg-warning-lighter w-10 h-10 rounded-full shrink-0',
          iconClassName,
        )}
      >
        <img src={iconPath} className="w-5 h-5" />
      </div>
      <div>
        <Text size="text-base" weight="font-medium" className="mb-1">
          {title}
        </Text>
        <Paragraph
          size="body-xs"
          weight="font-normal"
          className="text-neutral-dark mb-3"
          truncate={false}
        >
          {description}
        </Paragraph>
        {cta}
      </div>
    </div>
  )
}
