import { AppealingTitle, Carousel, Text, TitleSizes } from 'shared/components/atoms'

import { lazy, Suspense } from 'react'

const Response = lazy(() =>
  import('icons/signup/companies/response.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

export const SignInSidePanel = () => {
  const testimonials = [
    {
      text: '“Strider has been a valuable partner, saving us time and money by quickly providing high-quality candidates. I recommend Strider to any software company looking for top talent.',
      name: 'Mikhail Opletayev',
      role: 'CTO at Petvisor',
      image: 'images/signup/employers/mikhail-opletayev.png',
      icon: <img src="images/signup/companies/petvisor.png" alt="" className="h-7 w-32" />,
    },
    {
      text: '“Strider offers real international opportunities. They give real attention to the people. They provide real support. They ask for real feedback. They are real people. I am thankful for everything, and I hope this partnership stays for years!',
      name: 'Lucas B.',
      role: 'Mobile Software Developer',
      image: 'images/signup/job-seekers/lucas-b.png',
    },
    {
      text: "“Strider is a true extension of our team. They've worked closely with us to develop the perfect job description, hiring process, onboarding process, and even HR best practices!",
      name: 'Keivan Shahida',
      role: 'Co-founder & CEO at Response (YC S22)',
      image: 'images/signup/employers/keivan-shahida.png',
      icon: (
        <Suspense fallback={null}>
          <Response />
        </Suspense>
      ),
    },
    {
      text: "“I was so impressed with the way they do what they promise: they landed me a new job within a week. Every person I talked to was extremely professional. Strider is truly incredible it's almost too good to be true, but it is!",
      name: 'Ginevra H.',
      role: 'Product Designer',
      image: 'images/signup/job-seekers/ginevra-h.png',
    },
  ]

  return (
    <div className="flex flex-col w-full h-full rounded-2xl gap-y-8 pb-15 pt-24 px-8 items-center justify-center z-10 bg-neutral-day bg-opacity-32 relative backdrop-blur-xs">
      <div className="max-w-[576px] w-full">
        <Carousel>
          {testimonials.map(({ name, text, icon, image, role }) => (
            <div key={`${name}-testimonial`} className="flex flex-col gap-14 items-start">
              <AppealingTitle weight="font-normal" className="text-start" size={TitleSizes.xs}>
                {text}
              </AppealingTitle>
              <div className="flex w-full justify-between items-center">
                <div className="flex gap-4 items-center">
                  <img src={image} className="h-12 w-12 rounded-full" alt="" />
                  <div className="flex flex-col items-start">
                    <Text size="text-lg" weight="font-medium">
                      {name}
                    </Text>
                    <Text size="text-base" className="text-neutral-600">
                      {role}
                    </Text>
                  </div>
                </div>
                {icon}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}
