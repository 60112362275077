import { FieldError, FieldHint, Label, RichTextEditor, Textarea } from 'shared/components/atoms'
import { FormControl } from 'shared/hooks'

interface Props {
  formControl: FormControl
  name: string
  showCustomError?: boolean
  error?: string
  richTextEditor?: boolean
  defaultValue?: string
}

export const DetailedRequirements = ({
  formControl,
  name,
  showCustomError,
  error,
  richTextEditor,
  defaultValue,
}: Props) => {
  const { register, setValue } = formControl

  return (
    <>
      <Label htmlFor={name} size="text-sm">
        Detailed requirements
      </Label>
      <FieldHint className="!mb-6">
        Share bullet points or a description of what you're looking for in this role. Please
        indicate any strict requirements for the position.
      </FieldHint>
      {richTextEditor ? (
        <RichTextEditor
          name={name}
          setValue={setValue}
          defaultValue={defaultValue}
          omitErrors
          showBoldOption
          showHighlightOption
        />
      ) : (
        <Textarea
          register={register}
          id="detailedRequirements"
          name={name}
          omitErrors={showCustomError}
        />
      )}
      {showCustomError && <FieldError message={error} />}
    </>
  )
}
