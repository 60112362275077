import { Text } from 'components/Text'

import { ReactComponent as AlertIcon } from 'icons/alert-triangle.svg'

export const ConflictBanner = () => {
  return (
    <div className="flex gap-3 px-3 py-2 bg-warning-lighter rounded">
      <AlertIcon className="stroke-warning-medium h-6 w-6 min-w-[24px]" />
      <Text>
        Conflict: There's already a matching between this candidate and the chosen job listing.
      </Text>
    </div>
  )
}
