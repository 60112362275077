export class ExperienceRange {
  constructor(
    private startDate: Date,
    private endDate: Date,
  ) {}

  get months() {
    return this.totalMonths % 12
  }

  get years() {
    return (this.totalMonths - this.months) / 12
  }

  private get totalMonths() {
    const endMonth = this.endDate.getMonth() + 1 // inclusive range
    const startMonth = this.startDate.getMonth()
    const yearsDiff = this.endDate.getFullYear() - this.startDate.getFullYear()

    const months = endMonth - startMonth + 12 * yearsDiff

    return months >= 0 ? months : 0
  }
}
