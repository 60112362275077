import { useQuery } from 'react-query'

import { useNetwork } from 'shared/hooks'

import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { Recording, VideoRecordingsResponse } from 'vetting/domain'
import { EnglishVettingStep, RecordingStep, VideoRecordingSubject } from 'vetting/enums'

const recordingStep = {
  [VideoRecordingSubject.PastChallenges]: EnglishVettingStep.TechnicalChallenges,
  [VideoRecordingSubject.FutureInterviews]: EnglishVettingStep.FutureInterviews,
} as Record<VideoRecordingSubject, RecordingStep>

export const useFetchRecordings = (profile: ProfileOverview) => {
  const { get } = useNetwork()

  const { data, isLoading } = useQuery(
    [profile.id, 'recordings'],
    async () => {
      return await get<Array<VideoRecordingsResponse>>(
        `/vettings/${profile.id}/video-recordings`,
      ).then(({ data }) => {
        const recordings = {} as Record<RecordingStep, Recording>

        data.forEach((recording) => {
          recordings[recordingStep[recording.subject]] = {
            id: recording.id,
            pendingUpload: recording.pendingUpload,
            uploadProgress: 100,
            embeddingUrl: recording.embeddingUrl,
            lengthInSeconds: recording.lengthInSeconds,
            uploadSettings: recording.uploadSettings
              ? {
                  id: recording.id,
                  title: recording.title,
                  uploadSettings: recording.uploadSettings,
                }
              : undefined,
            submittedToEvaluation: recording.submittedToEvaluation,
          }
        })

        return recordings
      })
    },
    { staleTime: Infinity, enabled: Boolean(profile.id) },
  )

  return { fetchedRecordings: data, isLoading }
}
