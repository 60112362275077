import { useMonitoring } from 'shared/hooks'
import { useNetwork } from 'shared/hooks'
import { useQuery } from 'react-query'

export const useCandidateMatching = (jobListingId: string, candidateId: string) => {
  const { get } = useNetwork()
  const { captureException } = useMonitoring()

  const {
    data: matchingId,
    isLoading,
    isFetching,
    isRefetching,
  } = useQuery(
    ['candidate-matching', jobListingId, candidateId],
    () =>
      get<string>(`/listings/${jobListingId}/candidates/${candidateId}/matchings`).then(
        ({ data }) => data,
      ),
    {
      onError: (error: RequestError) => {
        if (error.response?.status === 404) {
          return null
        }

        captureException(error)
      },
      retry: false,
      enabled: Boolean(candidateId && jobListingId),
    },
  )

  return {
    matchingId,
    isLoading: isFetching || isLoading || isRefetching,
  }
}
