// Don't remove/change an item from here, just add a new one
export enum PositionsSlug {
  SoftwareEngineer = 'software_engineer',
  DataEngineer = 'data_engineer',
  Design = 'design',
  InfrastructureAndDevOps = 'infrastructure_&_dev_ops',
  QualityAssurance = 'quality_assurance',
  Leadership = 'leadership',
  Other = 'other',
  // Same of 'Design' and 'Leadership'.
  // For now they will be maintained for compatibility
  ProductDesigner = 'product_designer',
  SoftwareEngineeringLeadManager = 'software_engineering_lead_manager',
}
