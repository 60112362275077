import { useJobListingPreview } from 'job-listing/exports'
import { Outlet, useParams } from 'react-router-dom'
import { MissingWorkspaceSettingsDialog } from './components/MissingWorkspaceSettingsDialog'
import { TeleportToOwnerDialog } from './components/TeleportToOwnerDialog'
import { useAuth } from 'shared/hooks'

export const ScreeningRoutes = () => {
  const { listingId = '' } = useParams()
  const {
    user: { company },
  } = useAuth()
  const { listing, isLoading } = useJobListingPreview(listingId)

  const wrongCompany = company?.id !== listing?.company.id
  const missingWorkspaceSettings = !listing?.company.marketType?.id || !listing?.company.companySize

  if (isLoading) return null

  if (wrongCompany) {
    return (
      <>
        <TeleportToOwnerDialog listing={listing} />
        <Outlet />
      </>
    )
  }

  if (missingWorkspaceSettings) {
    return (
      <>
        <MissingWorkspaceSettingsDialog listingId={listingId} />
        <Outlet />
      </>
    )
  }

  return <Outlet />
}
