import { Roles } from 'account/enums'

export class Persona {
  constructor(private role: Roles | undefined) {}

  get label() {
    const mapping: { [key in Roles]: string } = {
      [Roles.EmployerOwner]: 'employer',
      [Roles.EmployerViewer]: 'employer',
      [Roles.JobSeeker]: 'job-seeker',
      [Roles.Recruiter]: 'recruiter',
    }

    return mapping[this.role as Roles] || 'unknown'
  }
}
