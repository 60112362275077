import { useContext } from 'react'

import { SearchContext } from 'candidates-search/contexts'

import { defaultFilter } from 'candidates-search/domain'

export const useFilter = () => {
  const { lastFilter, setFilter } = useContext(SearchContext)

  const resetFilter = () => {
    setFilter(defaultFilter)
  }

  const undoLastFilter = () => {
    setFilter(lastFilter)
  }

  return {
    resetFilter,
    undoLastFilter,
  }
}
