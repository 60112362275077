import React, { useState, useRef, ReactNode, useEffect } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import { canvasPreview } from './canvasPreview'

import 'react-image-crop/dist/ReactCrop.css'

import { SecondaryButton, PrimaryButton } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'

import { useProfilePictureUploader } from './useProfilePictureUploader'
import { ProfilePictureUpload } from './profile-picture-upload'

import { useMonitoring, useToast } from 'shared/hooks'

const aspect = 1
const imageSize = 500

const centerAspectCrop = (mediaWidth: number, mediaHeight: number) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 60,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

interface ProfilePictureUploaderProps {
  children: ({
    signature,
    onSelectFile,
    imgSrc,
    onFileSet,
    isCropping,
  }: {
    imgSrc: string
    signature: string
    isCropping: boolean
    onSelectFile: ({ target }: React.ChangeEvent<HTMLInputElement>) => void
    onFileSet: (file: File | null) => void
  }) => ReactNode
  profileId: string
}

export const ProfilePictureUploader = ({ children, profileId }: ProfilePictureUploaderProps) => {
  const { uploadFile, isUploading } = useProfilePictureUploader({ profileId })
  const { toastError } = useToast()
  const [signature, setSignature] = useState('default')
  const { captureException } = useMonitoring()

  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const [imgSrc, setImgSrc] = useState('')
  const [latestUploadSrc, setLatestUploadSrc] = useState('')
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const upload = ProfilePictureUpload.fromElement(event.target)
    handleUpload(upload, event)
  }

  const handleFileSet = (file: File | null) => {
    const upload = ProfilePictureUpload.fromFile(file)
    handleUpload(upload)
  }

  const handleUpload = (
    upload: ProfilePictureUpload,
    event?: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (upload.empty) return

    if (upload.tooBig) {
      return toastError('Image is too big, please upload a file up to 1 MB.')
    }

    setCrop(undefined) // Makes crop preview update between images.
    const reader = new FileReader()
    reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
    reader.readAsDataURL(upload.file!)

    if (event?.target) event.target.value = '' // enable same picture re-selection
  }

  const handleImageUpload = ({ currentTarget }: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = currentTarget

    setCrop(centerAspectCrop(width, height))
  }

  const updatePreview = (blob: Blob) => {
    if (latestUploadSrc) {
      URL.revokeObjectURL(latestUploadSrc)
    }
    const previewUrl = URL.createObjectURL(blob)
    setLatestUploadSrc(previewUrl)
  }

  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
    }
  }, [completedCrop])

  const handleBlob = async (blob: Blob | null) => {
    if (!blob) return

    // @todo: loading state
    uploadFile(blob)
      .then(() => updatePreview(blob))
      .then(() => {
        setSignature(signature)
        closeModal()
      })
      .catch((error) => {
        toastError('Could not upload the new image, please try again.')
        captureException(error)
      })
  }

  const handleConfirm = () => {
    previewCanvasRef.current?.toBlob(handleBlob, 'image/jpeg', 1)
  }

  const closeModal = () => setImgSrc('')

  return (
    <>
      {children({
        onSelectFile: handleFileSelection,
        onFileSet: handleFileSet,
        signature,
        imgSrc: latestUploadSrc,
        isCropping: Boolean(imgSrc),
      })}
      <Modal
        open={Boolean(imgSrc)}
        showClose={false}
        title="Profile photo"
        content={
          <div className="flex justify-center">
            {Boolean(imgSrc) && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(crop) => setCompletedCrop(crop)}
                aspect={aspect}
                circularCrop
              >
                <img ref={imgRef} src={imgSrc} onLoad={handleImageUpload} />
              </ReactCrop>
            )}
            {Boolean(completedCrop) && (
              <canvas
                ref={previewCanvasRef}
                className="hidden"
                style={{
                  objectFit: 'contain',
                  width: imageSize,
                  height: imageSize,
                }}
              />
            )}
          </div>
        }
        footer={
          <div className="w-full flex flex-row items-center justify-between">
            <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
            <PrimaryButton onClick={handleConfirm} isLoading={isUploading}>
              Save
            </PrimaryButton>
          </div>
        }
      />
    </>
  )
}
