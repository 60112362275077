import { degreeTypeLabels } from 'pages/JobSeekerProfile'
import { ProfileEducation } from 'pages/JobSeekerProfile/types'
import { EducationFormValues } from '../domain/education.domain'

export class EducationFormValuesFactory {
  static build(education: ProfileEducation): EducationFormValues {
    return Object.assign({}, education, {
      institutionId: Number(education.institution.id),
      institutionName: education.institution.label,
      degreeType: degreeTypeLabels[education.degreeType],
      degreeTypeId: education.degreeType,
    })
  }
}
