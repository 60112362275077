import { Tags } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

interface ScreeningFeedbackProps {
  declinedReasons?: string[]
  additionalNotes?: string
}

export const ScreeningFeedback = ({ declinedReasons, additionalNotes }: ScreeningFeedbackProps) => {
  const feedbackTags =
    declinedReasons?.map((reason, index) => ({
      id: 'feedback-tag-' + index,
      label: reason,
      background: 'bg-neutral-lightest',
    })) || []

  return (
    <div className="flex flex-col gap-4">
      {Boolean(feedbackTags.length) && (
        <div className="flex flex-col gap-3">
          <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
            Feedback:
          </Text>
          <Tags isReadOnly values={feedbackTags} background="bg-neutral-lightest" />
        </div>
      )}
      <div className="flex gap-2">
        <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
          Additional Notes:
        </Text>
        <Text size="text-sm" weight="font-normal">
          {additionalNotes ?? 'N/A'}
        </Text>
      </div>
    </div>
  )
}
