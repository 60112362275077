import { Roles } from 'account/enums'
import { plainToInstance } from 'class-transformer'
import { Company } from 'company/domain'

export type FetchCompanyInfoResponse = {
  company: {
    id: number
    approvedAt: Date | null
    approverEmail: string | null
    candidatesTrackerUrl: string | null
    employeesNumber: number | null
    hiringMode: string
    name: string
    updatedAt: Date
    website: string | null
    marketType?: {
      id: number
      label: string
    }
    companySize?: string
  }
  employees: Array<{ id: number; role: Roles; email: string }>
  invites: Array<{ id: number; role: Roles; email: string }>
}

export class FetchCompanyInfoDTO {
  constructor(private readonly response: FetchCompanyInfoResponse) {}

  toDomain(): Company {
    return plainToInstance(Company, {
      id: this.response.company.id,
      name: this.response.company.name,
      website: this.response.company.website,
      employees: this.response.employees,
      invites: this.response.invites,
      marketType: this.response.company.marketType,
      companySize: this.response.company.companySize,
      settings: {
        hiringMode: this.response.company.hiringMode,
      },
    })
  }
}
