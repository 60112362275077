import { PaymentModel } from 'job-listing/enums'

export const useRateLabel = ({
  rate,
  paymentModel,
}: {
  rate: string
  paymentModel: PaymentModel
}) => {
  const label = {
    [PaymentModel.Monthly]: 'Monthly billable rate (USD)',
    [PaymentModel.Hourly]: 'Hourly billable rate (USD)',
  }

  const rateLabel = {
    [PaymentModel.Monthly]: `${rate} per month`,
    [PaymentModel.Hourly]: `${rate} per hour`,
  }

  return { label: label[paymentModel], rateLabel: rateLabel[paymentModel] }
}
