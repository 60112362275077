export enum HighlightMetadata {
  Email = 'email',
  PrimayStackLabel = 'primaryStacks.label',
  SecondaryStackLabel = 'secondaryStacks.label',
  Bio = 'bio',
  WorkExperiencesTitle = 'workExperiences.title',
  WorkExperiencesDescription = 'workExperiences.description',
  WorkExperiencesOrganizationName = 'workExperiences.organizationName',
  WorkExperiencesOrganizationWebsite = 'workExperiences.organizationWebsite',
  WorkExperiencesLocation = 'workExperiences.location',
  EducationsFieldOfStudy = 'educations.fieldOfStudy',
  EducationsInstitutionName = 'educations.institutionName',
  CertificationsName = 'certifications.name',
  CertificationsInstitutionName = 'certifications.institutionName',
  Location = 'location',
  InterestRoles = 'interestRoles.label',
}

export interface HighlightPrefixesInterface {
  [HighlightMetadata.Email]: string
  [HighlightMetadata.Bio]: string
  [HighlightMetadata.WorkExperiencesTitle]: string
  [HighlightMetadata.WorkExperiencesDescription]: string
  [HighlightMetadata.WorkExperiencesOrganizationName]: string
  [HighlightMetadata.WorkExperiencesOrganizationWebsite]: string
  [HighlightMetadata.WorkExperiencesLocation]: string
  [HighlightMetadata.EducationsFieldOfStudy]: string
  [HighlightMetadata.EducationsInstitutionName]: string
  [HighlightMetadata.CertificationsName]: string
  [HighlightMetadata.CertificationsInstitutionName]: string
  [HighlightMetadata.Location]: string
  [HighlightMetadata.InterestRoles]: string
}

export const HighlightPrefixes = {
  [HighlightMetadata.Email]: 'Email: ',
  [HighlightMetadata.Bio]: 'About: ',
  [HighlightMetadata.WorkExperiencesTitle]: 'Work experience: ',
  [HighlightMetadata.WorkExperiencesDescription]: 'Work description: ',
  [HighlightMetadata.WorkExperiencesOrganizationName]: 'Company name: ',
  [HighlightMetadata.WorkExperiencesOrganizationWebsite]: 'Company website: ',
  [HighlightMetadata.WorkExperiencesLocation]: 'Work location: ',
  [HighlightMetadata.EducationsFieldOfStudy]: 'Education: ',
  [HighlightMetadata.EducationsInstitutionName]: 'Education institution: ',
  [HighlightMetadata.CertificationsName]: 'Certification: ',
  [HighlightMetadata.CertificationsInstitutionName]: 'Certification institution: ',
  [HighlightMetadata.Location]: 'Based location: ',
  [HighlightMetadata.InterestRoles]: 'Open to roles: ',
} as HighlightPrefixesInterface
