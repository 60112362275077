import { useEffect, useState } from 'react'

import { Text } from 'shared/components/atoms'
import { PrimaryButton, SecondaryButton, TertiaryButton } from 'shared/components/atoms'
import { Input } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'

import { ReactComponent as AddIcon } from 'icons/add.svg'
import { ReactComponent as TrashIcon } from 'icons/trash.svg'

import { useForm } from 'shared/hooks'
import { useSendInvites } from 'company/exports'

import { FormValues } from '.'
import { buildSchema } from './schema'

import { Roles } from 'account/enums'
import { Teammate } from 'company/domain'

interface InviteTeammateModalProps {
  isOpen: boolean
  onClose: (newTeammates: Array<Teammate>) => void
  teammates: Array<Teammate>
}

export const InviteTeammateModal = ({ isOpen, onClose, teammates }: InviteTeammateModalProps) => {
  const [emails, setEmails] = useState<Array<string>>([])

  const { uniqueValidation, isLoading } = useSendInvites(teammates, emails)

  const { register, handleSubmit, getValues, reset } = useForm({
    schema: buildSchema(uniqueValidation),
    defaultValues: { email: '' } as FormValues,
  })

  const handleAddEmail = handleSubmit(() => {
    const values = getValues() as FormValues
    setEmails((emails) => [values.email, ...emails])
    reset()
  })

  const handleRemoveEmail = (index: number) => {
    emails.splice(index, 1)
    setEmails([...emails])
  }

  const handleSendInvites = () => {
    const newTeammates = teammates.concat(
      emails.map((email) => ({ email, role: Roles.EmployerViewer, accepted: false })),
    )
    onClose(newTeammates)
  }

  useEffect(() => {
    reset()
    setEmails([])
  }, [isOpen])

  return (
    <Modal
      open={isOpen}
      mobilePositioning="bottom"
      title={
        <Text size="text-xl" weight="font-medium" className="text-neutral-darkest">
          Invite team members
        </Text>
      }
      content={
        <div className="flex flex-col gap-1">
          <form
            id="add-teammate-email-form"
            onSubmit={handleAddEmail}
            className="flex flex-row justify-between gap-4"
          >
            <div className="flex-grow">
              <Input register={register} type="email" name="email" id="email" />
            </div>
            <SecondaryButton onClick={handleAddEmail} isIconBefore icon={<AddIcon />}>
              <span className="hidden sm:block">Add</span>
            </SecondaryButton>
          </form>
          <ul>
            {emails.map((email, index) => (
              <li
                key={email}
                className="flex flex-row items-center justify-between py-4 border-b border-neutral-lighter"
              >
                <Text size="text-base" weight="font-normal" className="text-neutral-darkest">
                  {email}
                </Text>
                <TertiaryButton
                  icon={<TrashIcon className="stroke-neutral-darker" />}
                  onClick={() => handleRemoveEmail(index)}
                />
              </li>
            ))}
          </ul>
        </div>
      }
      footer={
        <>
          <TertiaryButton onClick={() => onClose(teammates)} className="w-full sm:w-fit">
            Cancel
          </TertiaryButton>
          <PrimaryButton
            onClick={handleSendInvites}
            isLoading={isLoading}
            className="w-full sm:w-fit"
          >
            Send invites
          </PrimaryButton>
        </>
      }
      handleClose={() => onClose(teammates)}
    />
  )
}
