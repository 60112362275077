import { Paragraph, Title } from 'shared/components/atoms'

interface ProfileEmptyStateSectionProps {
  id?: string
  children: React.ReactNode
  actions?: React.ReactNode
  title: string
  content: string
}

export const ProfileEmptyStateSection = ({
  id,
  children,
  title,
  content,
  actions,
}: ProfileEmptyStateSectionProps) => {
  return (
    <div
      className="flex flex-col flex-grow justify-center gap-6 items-center rounded-lg border-dashed border py-6 px-8 
        sm:flex-row sm:justify-between sm:h-60 sm:py-8 sm:px-16 sm:gap-8"
    >
      <div className="flex  flex-col gap-2 items-center sm:items-start">
        <Title id={id}>{title}</Title>
        <Paragraph size="body-sm" weight="font-normal" className="text-center sm:text-left">
          {content}
        </Paragraph>
        <div className="mt-2 w-full">{actions}</div>
      </div>
      {children}
    </div>
  )
}
