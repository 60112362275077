import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { StepsWizard, StepsWizardInstance } from 'shared/components/atoms'
import { AccountType, SwitchToLoginLink } from 'authentication/components'

import { Roles } from 'account/enums'
import { useToast } from 'shared/hooks'

import { SignUpStep } from './SignUpStep'
import { SidePanel } from 'authentication/components/SidePanel'
import { Container } from 'onboarding/components'
import { DarkBackground, LightBackground } from 'onboarding/components/Container/Container'
import { cn } from 'modules/shared'

const SignUpPage = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const redirectUrl = urlParams.get('redirectUrl') || ''
  const navigate = useNavigate()
  const { toastError } = useToast()

  const jobSeekerRoutes = ['/job-seeker-onboarding', '/job-seeker-onboarding?referral=']
  const employerRoutes = ['/employer-onboarding', '/listings/create?onboarding=1']
  const recruiterRoutes = ['/recruiter-onboarding']

  const isJobSeekerRoute = jobSeekerRoutes.some((url) =>
    redirectUrl.includes(url.toLocaleLowerCase()),
  )
  const isEmployerRoute = employerRoutes.some((url) =>
    redirectUrl.includes(url.toLocaleLowerCase()),
  )
  const isRecruiterRoute = recruiterRoutes.some((url) =>
    redirectUrl.includes(url.toLocaleLowerCase()),
  )

  const isRoleSelected = isJobSeekerRoute || isEmployerRoute || isRecruiterRoute

  const calculateInitialStep = useCallback(() => (isRoleSelected ? 2 : 1), [isRoleSelected])

  const getInitialRole = useCallback(() => {
    if (isJobSeekerRoute) return Roles.JobSeeker

    if (isEmployerRoute) return Roles.EmployerOwner

    if (isRecruiterRoute) return Roles.Recruiter
  }, [isJobSeekerRoute, isEmployerRoute, isRecruiterRoute])

  const [instance, setInstance] = useState<Nullable<StepsWizardInstance>>(null)
  const [accountRole, setAccountRole] = useState<Optional<Roles>>(getInitialRole())

  const handleAccountTypeSelected = (accountRole: Roles, nextStep?: () => void) => {
    setAccountRole(accountRole)
    nextStep!()

    if (accountRole === Roles.JobSeeker) {
      return navigate('/signup?redirectUrl=/job-seeker-onboarding')
    }

    if (accountRole === Roles.EmployerOwner) {
      return navigate('/signup?redirectUrl=/employer-onboarding')
    }

    if (accountRole === Roles.Recruiter) {
      return navigate('/signup?redirectUrl=/recruiter-onboarding')
    }

    toastError('Invalid account type')
  }

  const isJobSeekerSelected = accountRole === Roles.JobSeeker

  const text: Record<Roles, string> = {
    [Roles.JobSeeker]: 'Not a developer or a job seeker?',
    [Roles.EmployerOwner]: 'Not an employer?',
    [Roles.EmployerViewer]: 'Not an employer?',
    [Roles.Recruiter]: 'Not a freelance recruiter?',
  }

  useEffect(() => {
    const initialRole = getInitialRole()
    setAccountRole(initialRole)
  }, [getInitialRole])

  useEffect(() => {
    const initialStep = calculateInitialStep()
    instance?.goToStep(initialStep)
  }, [instance, calculateInitialStep])

  return (
    <Container
      className={isJobSeekerSelected ? 'bg-neutral-darkest' : undefined}
      asideContent={<SidePanel accountRole={accountRole} />}
      background={isJobSeekerSelected ? <DarkBackground /> : <LightBackground />}
      contentClassNames={cn('sm:p-0 p-0 w-full md:w-[490px]', {
        'bg-opacity-60': !isJobSeekerSelected,
        'bg-opacity-10': isJobSeekerSelected,
      })}
      chooseDifferentAccount={accountRole ? text[accountRole] : undefined}
      isDarkMode={isJobSeekerSelected}
      logoClassName="mb-0 mt-12 ml-6 md:ml-12"
    >
      <StepsWizard
        isLazyMount
        initialStep={calculateInitialStep()}
        instance={setInstance}
        transitions={{
          enterLeft: 'fadeIn',
          enterRight: 'fadeIn',
          exitLeft: 'fadeOut',
          exitRight: 'fadeOut',
        }}
      >
        <AccountType
          footer={<SwitchToLoginLink />}
          onAccountTypeSelected={handleAccountTypeSelected}
        />
        <div className="w-full">
          <SignUpStep redirectUrl={redirectUrl} accountRole={accountRole} />
        </div>
      </StepsWizard>
    </Container>
  )
}

export { SignUpPage }
