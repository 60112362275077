import { ProficiencyTag } from 'modules/profiles/domain'

export class AddProficienciesDTO {
  constructor(private readonly proficiencies: Array<ProficiencyTag>) {}

  toJSON() {
    return {
      proficiencyIds: this.proficiencies.map((proficiency) => proficiency.id),
    }
  }
}
