import classNames from 'classnames'
import { Text } from 'shared/components/atoms'

import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { ReactNode } from 'react'
import { UpsertButton } from '../../UpsertButton'
import { ProfilePicture } from '../../ProfileHeader/ProfilePicture'
import { ProfilePictureInstructions } from '../../ProfilePictureInstructions'
import { useAuth } from 'shared/hooks'
import { SensitiveInformation } from 'shared/components/atoms'

interface HeadingProps {
  profile: ProfileOverview
  readOnly: boolean
  onEdit: () => void
  children?: ReactNode
}

export const Heading = ({ profile, readOnly, onEdit, children }: HeadingProps) => {
  const {
    user: { isStriderStaff },
  } = useAuth()
  const fullName = [profile.firstName, profile.lastName].join(' ')

  return (
    <div className="flex flex-col gap-6">
      <div>
        <ProfilePicture profileId={profile.id} readOnly={readOnly} photoUrl={profile.photoUrl}>
          {({ imageUrl, onLoadError, onTriggered, onFileSet, isCropping, className }) => (
            <ProfilePictureInstructions
              onConfirm={onTriggered}
              onFileSet={onFileSet}
              disabled={readOnly}
              isCropping={isCropping}
            >
              <div
                className={classNames('w-[100px] h-[100px] md:w-[168px] md:h-[168px] relative', {
                  'cursor-pointer': !readOnly,
                })}
              >
                <img
                  src={imageUrl}
                  className={classNames('rounded-full', className)}
                  onError={onLoadError}
                  alt={profile.firstName}
                />
                {!isStriderStaff && (
                  <UpsertButton
                    className="bottom-2 right-2 !absolute !gap-0"
                    onClick={() => void 0}
                    readOnly={readOnly}
                  >
                    <div className="hidden" />
                  </UpsertButton>
                )}
              </div>
            </ProfilePictureInstructions>
          )}
        </ProfilePicture>
      </div>

      <div className="flex flex-col gap-2">
        <SensitiveInformation>
          <Text size="text-2xl" weight="font-medium">
            {fullName}
          </Text>
        </SensitiveInformation>
        <Text size="text-sm" weight="font-normal" className="text-neutral-dark">
          {profile.fullLocation}
        </Text>
      </div>

      {children}

      <UpsertButton onClick={onEdit} readOnly={readOnly} className="w-fit" />
    </div>
  )
}
