import { Paragraph } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { ReactComponent as ChevronRight } from 'icons/chevron-right.svg'

interface CollectionItemProps {
  onClick: () => void
  title: string
  description: string
}

export const CollectionItem = ({ onClick, title, description }: CollectionItemProps) => {
  return (
    <li
      onClick={onClick}
      className="
      flex flex-row items-center justify-between gap-16
      p-6 border-b border-neutral-lighter cursor-pointer
      hover:bg-neutral-lightest transition-all
    "
    >
      <div className="flex flex-col gap-1">
        <Text size="text-base" weight="font-normal" className="text-neutral-darkest">
          {title}
        </Text>
        <Paragraph size="body-xs" weight="font-normal" className="text-neutral-dark">
          {description}
        </Paragraph>
      </div>
      <ChevronRight className="flex-shrink-0 w-6 h-6 stroke-neutral-dark" />
    </li>
  )
}
