import { useContext } from 'react'

import { Link, Paragraph } from 'shared/components/atoms'
import { ConditionalWrapper } from 'shared/components/molecules'

import { useAuth, useFormatting, useToast } from 'shared/hooks'

import { SearchContext } from 'candidates-search/contexts'

import { Candidate, SearchMetadata } from 'candidates-search/domain'
import { cn } from 'modules/shared'

import { Infos } from './infos'

interface AdditionalInformationProps {
  candidate: Candidate & SearchMetadata
}

export const AdditionalInformation = ({ candidate }: AdditionalInformationProps) => {
  const { formatCurrency } = useFormatting()
  const {
    user: { claims },
  } = useAuth()
  const { filter } = useContext(SearchContext)
  const { toastSuccess } = useToast()

  const { infos } = new Infos(candidate, filter, formatCurrency, claims, toastSuccess)

  const readyToStart = (() => {
    if (!candidate.noticePeriodDays) {
      return 'Ready to start immediately'
    }

    return `Ready to start in ${(candidate.noticePeriodDays / 7).toFixed(0)} weeks`
  })()

  return (
    <div className={cn('flex flex-col gap-6', { 'opacity-50': candidate.isPaused })}>
      <ul className="flex flex-col gap-2">
        {infos.map((info, index) => (
          <li key={index} className="flex gap-2 items-start">
            <ConditionalWrapper
              shouldWrap={Boolean(info.url)}
              wrapper={(children) => (
                <Link
                  url={info.url!}
                  target="_blank"
                  className="flex gap-2 items-start hover:underline decoration-neutral-dark"
                >
                  {children}
                </Link>
              )}
            >
              <>
                <div className="pt-1">{info.icon}</div>
                <Paragraph
                  size="body-sm"
                  className={cn('text-ellipsis overflow-hidden text-neutral-dark', {
                    'text-warning-darker': info.warning,
                  })}
                >
                  {info.text}
                </Paragraph>
              </>
            </ConditionalWrapper>
          </li>
        ))}
      </ul>
      <Paragraph size="body-sm" className="text-neutral-dark">
        {readyToStart}
      </Paragraph>
    </div>
  )
}
