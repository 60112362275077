import { plainToInstance } from 'class-transformer'
import { GroupedSearches, ListingInfo } from '../domain'

export interface SavedSearchResponse {
  id: string
  title: string
  url: string
  listing: Nullable<ListingInfo>
  published: boolean
}

export interface GroupedSearchesResponse {
  groupName: string
  searches: Array<SavedSearchResponse>
}

export class FetchSearchDTO {
  constructor(private readonly data: Array<GroupedSearchesResponse>) {}

  toDomain(): Array<GroupedSearches> {
    return plainToInstance(GroupedSearches, this.data)
  }
}
