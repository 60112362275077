import { PreviewLabel, SensitiveInformation } from 'shared/components/atoms'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { useState } from 'react'
import { AudioPlayer } from '../SelfIntroduction/AudioPlayer'
import { SelfIntroductionAudio as SelfIntroductionAudioObject } from '../SelfIntroduction/self-introduction-audio'

interface SelfIntroductionAudioProps {
  profile: ProfileOverview
}

export const SelfIntroductionAudio = ({ profile }: SelfIntroductionAudioProps) => {
  const [loaded, setLoaded] = useState(false)
  const [loadError, setLoadError] = useState(false)

  const file = new SelfIntroductionAudioObject(profile.selfIntroductionAudioUrl)
  const audioUrl = file.url

  const handleLoad = () => setLoaded(true)
  const handleError = () => {
    setLoaded(false)
    setLoadError(true)
  }

  if (!file.available || loadError) return null

  return (
    <div className="mb-10 print:hidden">
      <PreviewLabel
        title={
          <>
            Listen to a self-introduction by{' '}
            <SensitiveInformation>
              <>{profile.firstName}</>
            </SensitiveInformation>
          </>
        }
        disabled
      >
        <AudioPlayer
          key={audioUrl}
          src={audioUrl}
          onCanPlay={handleLoad}
          onError={handleError}
          visible={loaded}
        />
      </PreviewLabel>
    </div>
  )
}
