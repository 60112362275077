import classNames from 'classnames'
import { TertiaryButton } from 'shared/components/atoms'
import { ReactComponent as ChevronDown } from 'icons/chevron-down.svg'

interface TruncateButtonProps {
  truncatedItemsCount: number
  children: React.ReactNode
  onClick: () => void
  size?: 'lg' | 'md'
  className?: string
}

export const TruncateButton = ({
  truncatedItemsCount,
  children,
  size = 'lg',
  className,
  onClick,
}: TruncateButtonProps) => {
  if (!truncatedItemsCount) return null

  return (
    <TertiaryButton
      size={size}
      icon={<ChevronDown className="w-4 h-4 stroke-neutral-dark" />}
      className={classNames('mt-8 sm:mt-10 w-full', className)}
      showFocus={false}
      onClick={onClick}
    >
      {children}
    </TertiaryButton>
  )
}
