import { useContext } from 'react'

import { PrimaryButton } from 'shared/components/atoms'
import { Modal, Success, Warning } from 'shared/components/molecules'
import { ApplicationReviewItem } from 'job-opportunity/exports'
import { WorkExperience } from 'modules/work-experience'
import { JobOpportunity, ProfileOverview } from 'pages/JobSeekerProfile/types'
import { SearchContext } from 'candidates-search/contexts'
import { screeningRejectionReasonLabels } from 'modules/screening-assignment/enums'

interface Props {
  profile: ProfileOverview
  jobOpportunity: JobOpportunity
  open: boolean
  workExperiences: { [key: string]: WorkExperience }
  onClose: () => void
}
export const AnswersModal = ({
  open,
  onClose,
  workExperiences,
  jobOpportunity,
  profile,
}: Props) => {
  const { questions, screeningAnswers: answers, approvedAt, rejectedAt } = jobOpportunity

  const { search } = useContext(SearchContext)

  if (!answers || !search?.listing) return null

  const ModalContent = (
    <div className="flex flex-col gap-12">
      {Boolean(approvedAt) && (
        <Success>
          {profile.firstName} was approved in {search!.listing!.company.name}'s{' '}
          {search!.listing!.title} screening
        </Success>
      )}

      {Boolean(rejectedAt) && (
        <Warning showIcon={false}>
          {profile.firstName} was rejected in {search!.listing!.company.name}'s{' '}
          {search!.listing!.title} screening. <br />
          {Boolean(jobOpportunity.rejectionReasons.length) && (
            <>
              Reason:{' '}
              {jobOpportunity.rejectionReasons
                .map((rejectionReason) => screeningRejectionReasonLabels[rejectionReason])
                .join(', ')}
            </>
          )}
        </Warning>
      )}

      <div className="flex flex-col gap-y-14">
        {questions?.map((question, i) => (
          <ApplicationReviewItem
            key={i}
            index={i}
            text={answers[i].value}
            workExperiences={answers[i].linkedWorkExperienceIds}
            candidateExperiences={workExperiences}
            question={question}
          />
        ))}
      </div>
    </div>
  )

  return (
    <Modal
      mobilePositioning="bottom"
      titleContainerClassName="!py-6 !px-10"
      title="Answers"
      open={open}
      handleClose={onClose}
      contentContainerClassName="!p-10"
      content={ModalContent}
      footer={
        <div className="flex-grow w-full flex flex-row gap-4 justify-end">
          <PrimaryButton onClick={onClose}>Close</PrimaryButton>
        </div>
      }
    />
  )
}
