import { useLandingTracking } from 'shared/hooks'
import { SignUpForm } from 'authentication/components'
import { Roles } from 'account/enums'

interface Props {
  redirectUrl: string
  accountRole: Optional<Roles>
}

const SignUpStep = ({ redirectUrl, accountRole }: Props) => {
  const { isSdrFlow, getLandingLocation } = useLandingTracking()

  return (
    <>
      <SignUpForm
        redirectUrl={isSdrFlow ? getLandingLocation() : redirectUrl}
        theme={accountRole === Roles.JobSeeker ? 'dark' : undefined}
        hideLogo
      />
    </>
  )
}

export { SignUpStep }
