import classNames from 'classnames'
import { ListingStatus } from 'job-listing/enums'
import { Text } from 'shared/components/atoms'
import { Tooltip } from 'shared/components/atoms'

interface ListingStatusBadgeProps {
  status: ListingStatus
  className?: string
}

export const ListingStatusBadge = ({ status, className }: ListingStatusBadgeProps) => {
  const baseClasses = 'flex w-fit justify-center items-center px-2 py-1 rounded inline-block'

  const listingStatusBadges = {
    [ListingStatus.Active]: (
      <Text
        weight="font-medium"
        size="text-xs"
        className={classNames(baseClasses, 'bg-success-lighter text-success-darker', className)}
      >
        Active
      </Text>
    ),
    [ListingStatus.Paused]: (
      <Text
        weight="font-medium"
        size="text-xs"
        className={classNames(baseClasses, 'bg-warning-lighter text-warning-dark', className)}
      >
        Paused
      </Text>
    ),
    [ListingStatus.Draft]: (
      <Text
        weight="font-medium"
        size="text-xs"
        className={classNames(baseClasses, 'bg-info-lighter text-info-medium', className)}
      >
        Draft
      </Text>
    ),
    [ListingStatus.Closed]: (
      <Text
        weight="font-medium"
        size="text-xs"
        className={classNames(baseClasses, 'bg-neutral-lightest text-neutral-dark', className)}
      >
        Closed
      </Text>
    ),
  }

  return <Tooltip content="Status">{listingStatusBadges[status] || 'Not available'}</Tooltip>
}
