import classNames from 'classnames'
import { Text } from 'components/Text'
import { ReactComponent as CheckIcon } from 'icons/check--light.svg'

export const OfferSentBadge = ({ className }: { className?: string }) => {
  return (
    <div
      className={classNames(
        'h-10 flex flex-row items-center gap-2 px-4 bg-success-lighter rounded-sm min-w-fit print:hidden',
        className,
      )}
    >
      <CheckIcon className="w-4 h-4 stroke-success-dark" />
      <Text size="small-body" className="text-success-dark">
        Offer sent
      </Text>
    </div>
  )
}
