import { TrackingEvent } from '../tracking-event'

export class SurveyModalView implements TrackingEvent {
  name = 'Survey modal view'

  constructor(private readonly initialProduct?: string) {}

  get payload() {
    return { initialProduct: this.initialProduct }
  }
}
