import { JobOpportunityDeclinationReasons } from '../../../../enums'

export const otherReasonOption = {
  label: 'Other...',
  value: JobOpportunityDeclinationReasons.Other,
}
export const unavailableReasonOption = {
  label: "I'm not available",
  value: JobOpportunityDeclinationReasons.NotAvailable,
}
export const lowPayRateOption = {
  label: 'Low pay rate',
  value: JobOpportunityDeclinationReasons.LowPayRate,
}

export const reasonsOptions = {
  [JobOpportunityDeclinationReasons.MissingRequiredSkills]: "I don't have the required skills",
  [JobOpportunityDeclinationReasons.UnclearJobDescription]: 'Unclear job description',
  [JobOpportunityDeclinationReasons.UnwantedIndustryMarket]: 'Unwanted industry/market',
  [JobOpportunityDeclinationReasons.LongHiringProcess]: 'Long hiring process',
  [JobOpportunityDeclinationReasons.LackCompanyInfo]: 'Lack of company info',
  [lowPayRateOption.value]: lowPayRateOption.label,
  [unavailableReasonOption.value]: unavailableReasonOption.label,
  [otherReasonOption.value]: otherReasonOption.label,
}

export const availabilities: { [key: string]: string } = {
  30: '30 days',
  90: '90 days',
  180: '180 days',
  unavailable: 'Until I change back',
}
