import { RegisterOptions, useForm as reactHookUseForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AnyObjectSchema } from 'yup'
import { FormControl } from '.'

export const useForm = ({
  schema,
  defaultValues,
  mode = 'onSubmit',
}: {
  schema: AnyObjectSchema
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValues: Record<string, any>
  mode?: 'onSubmit' | 'onChange' | 'onBlur'
}): FormControl => {
  const {
    control,
    reset,
    watch,
    getValues,
    register,
    setValue,
    handleSubmit,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isDirty, isValid, dirtyFields, submitCount, touchedFields },
    setFocus,
  } = reactHookUseForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode,
  })

  return {
    watch,
    reset,
    control,
    getValues,
    handleSubmit,
    setValue,
    trigger,
    register: (key: string) => [
      errors[key]?.message,
      (options?: RegisterOptions) => register(key, options),
    ],
    getError: (field: string) => errors[field]?.message || errors[field],
    isDirty,
    isValid,
    dirtyFields,
    errors,
    submitCount,
    setError,
    clearErrors,
    touchedFields,
    setFocus,
  }
}
