import 'reflect-metadata'

import { init as initSentry, BrowserTracing } from '@sentry/react'
import React from 'react'
import TagManager from 'react-gtm-module'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import './index.scss'
import 'react-toastify/dist/ReactToastify.min.css'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { ToastIcon, CloseButton } from 'shared/components/atoms'

initSentry({
  release: process.env.REACT_APP_HEROKU_RELEASE_VERSION,
  dsn: process.env.REACT_APP_SENTRY_DNS,
  environment: process.env.REACT_APP_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  ignoreErrors: [
    '/Could not seamlessly onboard user./',
    /Non-Error promise rejection captured with value: Object Not Found Matching Id:(\d+), MethodName:([^,]+), ParamCount:(\d+)/,
    /There is already an encoder stored which handles exactly the same mime types/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.3,
})

TagManager.initialize({
  gtmId: String(process.env.REACT_APP_GTM_ID),
  auth: String(process.env.REACT_APP_GTM_AUTH),
  preview: String(process.env.REACT_APP_GTM_PREVIEW),
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <ToastContainer
      hideProgressBar
      className="text-sm !top-2 md:!top-20"
      bodyClassName="!items-start"
      icon={ToastIcon}
      closeButton={CloseButton}
      pauseOnFocusLoss
      pauseOnHover
    />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
