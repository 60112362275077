import { useState } from 'react'

import { AppealingTitle } from 'shared/components/atoms'

import { Option } from '.'
import { Roles } from 'account/enums'
import { cn } from 'modules/shared'

interface Props {
  footer: React.ReactNode
  classNames?: string
  onAccountTypeSelected: (accountRole: Roles, nextStep?: () => void) => void
  nextStep?: () => void
}

export const AccountType = ({ footer, classNames, onAccountTypeSelected, nextStep }: Props) => {
  const [selectedRole, setSelectedRole] = useState<Roles>()

  const selectOnboarding = (accountRole: Roles) => {
    setSelectedRole(accountRole)
    onAccountTypeSelected(accountRole, nextStep)
  }

  return (
    <div
      className={cn(
        'flex w-full md:max-w-[490px] flex-col gap-12 p-6 md:p-12 bg-transparent backdrop-blur-xs rounded-2xl',
        classNames,
      )}
    >
      <AppealingTitle>I want to join Strider to...</AppealingTitle>

      <div className="flex flex-col gap-4 w-full">
        <Option
          isSelected={selectedRole === Roles.JobSeeker}
          title="Get a job"
          description="I'm a developer and want to find a new opportunity."
          onClick={() => selectOnboarding(Roles.JobSeeker)}
        />
        <Option
          isSelected={selectedRole === Roles.EmployerOwner}
          title="Hire developers"
          description="I'm an employer and want to hire developers."
          onClick={() => selectOnboarding(Roles.EmployerOwner)}
        />

        <Option
          isSelected={selectedRole === Roles.Recruiter}
          title="Refer talent"
          description="I'm a recruiter and want to refer developers."
          onClick={() => selectOnboarding(Roles.Recruiter)}
        />
      </div>

      {footer}
    </div>
  )
}
