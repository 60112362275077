import { useLocalStorage } from 'shared/hooks/storage'

const REFERRAL_BANNER_DISMISSED_KEY = 'referral_banner_dismissed'

export const useReferralBannerDismissed = () => {
  const [isDismissed, setIsDismissed] = useLocalStorage(REFERRAL_BANNER_DISMISSED_KEY, false)

  const setDismissed = () => {
    setIsDismissed(true)
  }

  return { isDismissed, setDismissed }
}
