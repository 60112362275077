import { TrackingEvent } from '../tracking-event'

export class InviteFriendDoItLater implements TrackingEvent {
  name = 'Invite friends do it later click'

  constructor(private userEmail: string) {}

  get payload() {
    return {
      userEmail: this.userEmail,
    }
  }
}
