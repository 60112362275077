import { Title } from 'shared/components/atoms'
import { Paragraph } from 'shared/components/atoms'
import { Tags } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

interface Props {
  roles: Array<{ id: number; label: string }>
  additionalRolesLabel: string
}

export const OpenToRoles = ({ roles, additionalRolesLabel }: Props) => {
  return (
    <div className="flex flex-col gap-1">
      <Paragraph size="body-xs" weight="font-normal" className="text-neutral-dark">
        Open to roles
      </Paragraph>
      <div className="flex gap-2 items-center">
        {!roles.length && <Title size="micro-heading">Not Informed</Title>}
        {Boolean(roles.length) && (
          <>
            <Tags values={roles} background="bg-neutral-lightest" isReadOnly />
            <Text size="text-sm" weight="font-normal">
              {additionalRolesLabel}
            </Text>
          </>
        )}
      </div>
    </div>
  )
}
