import { Tags } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { ReactComponent as AlertIcon } from 'icons/alert-triangle.svg'

interface Props {
  skills: Array<{ id: number; label: string }>
  isPreview: boolean
}

export const MissingSkills = ({ skills, isPreview }: Props) => {
  if (!skills.length && isPreview) return null

  const MissingSkillsQuantity = ({ isPreview }: { isPreview: boolean }) => {
    if (isPreview) return null
    return (
      <div className="flex gap-x-2 mb-4 items-center">
        <AlertIcon className="stroke-neutral-medium h-4 w-4" />
        <Text size="text-sm" className="text-neutral-dark">
          <span className="font-medium text-neutral-dark">{skills!.length} main skills</span>{' '}
          missing on your profile
        </Text>
      </div>
    )
  }
  return (
    <div>
      <MissingSkillsQuantity isPreview={isPreview} />
      <div>
        <Tags background="bg-neutral-lightest" values={skills!} isReadOnly />
      </div>
    </div>
  )
}
