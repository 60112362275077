export const Developers = () => {
  const developers = [
    'rychillie-u',
    'caue-t',
    'romulo-p',
    'luiza-f',
    'tasiana-f',
    'pedro-l',
    'dina-a',
  ]

  return (
    <section className="flex animate-swipe">
      {developers.map((developer) => (
        <div className="mx-4 h-80 w-64" key={developer}>
          <img
            src={`images/signup/account-type/${developer}.png`}
            className="h-80 w-64"
            alt={developer}
          />
        </div>
      ))}
    </section>
  )
}
