import { useMonitoring } from 'shared/hooks'
import { useNetwork } from 'shared/hooks'
import { Subscription } from 'job-listing/domains'
import { useMutation } from 'react-query'

interface SubscribeRequest {
  listingId: string
  subscribers: Array<{ id: number; label: string }>
}

const isUserSubscribed = (userEmail: string, subscribers: Subscription[]) =>
  Boolean(subscribers.find(({ email }) => email === userEmail))

export const useSubscription = () => {
  const { post, delete: remove } = useNetwork()
  const { captureException } = useMonitoring()

  const { mutateAsync: subscribe } = useMutation(
    ({ listingId, subscribers }: SubscribeRequest) =>
      post(`/listings/${listingId}/notifications/subscribe`, {
        subscribersId: subscribers.map(({ id }) => id),
      }),
    {
      onError: (error) => {
        captureException(error)
      },
    },
  )

  const { mutateAsync: unsubscribe } = useMutation(
    ({ listingId, subscriptionId }: { listingId: string; subscriptionId: string }) =>
      remove(`/listings/${listingId}/notifications/subscribe/${subscriptionId}`),
    {
      onError: (error) => {
        captureException(error)
      },
    },
  )

  return { subscribe, unsubscribe, isUserSubscribed }
}
