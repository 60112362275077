import { number, object } from 'yup'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import classNames from 'classnames'

import { Paragraph, Label } from 'shared/components/atoms'
import { useAvailableOpportunities } from 'hooks/payRate/useAvailableOpportunities'
import { HistogramSlider } from 'shared/components/molecules'
import {
  GOOD_AVAILABLE_OPPORTUNITIES,
  MONTHLY_GROWING_RATE,
  MONTHLY_MAX,
  MONTHLY_MIN,
  NEUTRAL_AVAILABLE_OPPORTUNITIES,
  usePayRateOptions,
} from 'hooks/payRate'
import { PayRateType } from 'modules/shared/enums'
import { useHistogram } from 'hooks/payRate/useHistogram'

export const monthlyRateShape = object().shape({
  min: number(),
  max: number(),
})

interface MonthlyRateFieldProps {
  name: string
  declaredYearsOfExperience?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
}

export const MonthlyRateField = ({
  name,
  declaredYearsOfExperience,
  watch,
  setValue,
}: MonthlyRateFieldProps) => {
  const { monthlyHistogram } = useHistogram(declaredYearsOfExperience ?? 0)

  const monthlyRateOptions = usePayRateOptions({
    type: PayRateType.Monthly,
    minValue: MONTHLY_MIN,
    maxValue: MONTHLY_MAX,
    step: MONTHLY_GROWING_RATE,
  })

  const minValue = watch(`${name}.min`)
  const maxValue = watch(`${name}.max`)

  const availableOpportunities = useAvailableOpportunities(
    monthlyHistogram,
    monthlyRateOptions,
    minValue,
    maxValue,
  )

  return (
    <>
      <div>
        <Label htmlFor={name} id="monthlyRate">
          What monthly pay rate are you considering from companies on Strider?
        </Label>
        <Paragraph
          size="body-sm"
          weight="font-normal"
          className={classNames('mt-2', {
            'text-success-dark': availableOpportunities >= GOOD_AVAILABLE_OPPORTUNITIES,
            'text-neutral-dark':
              availableOpportunities >= NEUTRAL_AVAILABLE_OPPORTUNITIES &&
              availableOpportunities < GOOD_AVAILABLE_OPPORTUNITIES,
            'text-danger-dark': availableOpportunities < NEUTRAL_AVAILABLE_OPPORTUNITIES,
          })}
        >
          Matching about {availableOpportunities}% of the available opportunities
        </Paragraph>
      </div>

      <HistogramSlider
        name={name}
        watch={watch}
        setValue={setValue}
        options={monthlyRateOptions}
        bars={monthlyHistogram}
        labelledBy="monthlyRate"
      />
    </>
  )
}
