import { cn } from 'modules/shared'
import { FieldError } from 'shared/components/atoms'
import { SecondaryButton } from 'shared/components/atoms'
import { Input } from 'shared/components/atoms'
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'

import { ReactComponent as AddIcon } from 'icons/add.svg'
import { ReactComponent as MinusIcon } from 'icons/minus.svg'

import './Counter.module.scss'

interface CounterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (key: string) => Array<any>
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues: UseFormGetValues<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  containerClassName?: string
  inputClassName?: string
  omitErrors?: boolean
  disabled?: boolean
  placeholder?: string
  showWarning?: boolean
}

export const Counter = ({
  register,
  name,
  getValues,
  setValue,
  containerClassName,
  inputClassName,
  omitErrors,
  disabled,
  placeholder = '0',
  showWarning,
}: CounterProps) => {
  const [error] = register(name)

  const handleMinus = () => {
    const value = Number(getValues(name))
    if (isNaN(value)) {
      updateValue(name, 0)
      return
    }

    if (value > 0) {
      updateValue(name, value - 1)
    }
  }
  const updateValue = (name: string, value: number) =>
    setValue(name, Number(value), { shouldDirty: true, shouldTouch: true, shouldValidate: true })

  const handleAdd = () => {
    const value = Number(getValues(name))
    if (isNaN(value)) {
      updateValue(name, 1)
      return
    }

    updateValue(name, value + 1)
  }

  return (
    <>
      <div className={cn('flex flex-row justify-start items-center gap-4', containerClassName)}>
        <SecondaryButton
          aria-label="minus"
          size="md"
          icon={<MinusIcon />}
          onClick={handleMinus}
          disabled={disabled}
          className={cn({
            'bg-warning-lighter border-warning-medium hover:border-warning-medium': showWarning,
          })}
        />
        <Input
          type="number"
          register={register}
          name={name}
          showFieldError={false}
          className={cn('w-[72px] text-center', inputClassName, {
            'bg-warning-lighter border-warning-medium': showWarning,
          })}
          disabled={disabled}
          placeholder={placeholder}
        />
        <SecondaryButton
          aria-label="plus"
          size="md"
          icon={<AddIcon />}
          onClick={handleAdd}
          disabled={disabled}
          className={cn({
            'bg-warning-lighter border-warning-medium hover:border-warning-medium': showWarning,
          })}
        />
      </div>
      {!omitErrors && <FieldError message={error} />}
    </>
  )
}
