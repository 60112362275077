import { useMutation, useQueryClient } from 'react-query'
import { useMonitoring, useNetwork, useToast } from 'shared/hooks'

export const useDeleteTag = (profileId: string) => {
  const { delete: remove } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()
  const client = useQueryClient()

  const { mutateAsync: deleteTag, isLoading: isDeleting } = useMutation(
    (id: string) => remove(`/profile/${profileId}/profile-tags/${id}`),
    {
      onSuccess: () => {
        client.invalidateQueries(`candidates/${profileId}`)
      },
      onError: (error: RequestError) => {
        toastError('Failed to remove tag: ' + error.response?.data.message)
        captureException(error)
      },
    },
  )

  return { deleteTag, isDeleting }
}
