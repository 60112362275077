import { lazy, useMemo } from 'react'
import classNames from 'classnames'

import { SecondaryButton } from 'shared/components/atoms'
import { ContentWrapper } from 'components/ContentWrapper'
import { EducationRecord } from './EducationRecord'
import { ProfileSectionTitle } from '../ProfileSectionTitle'
import { ProfileSectionWrapper } from '../ProfileSectionWrapper'
import { ProfileEmptyStateSection } from '../ProfileEmptyStateSection'

import { ProfileEducation } from 'pages/JobSeekerProfile/types'
import { TruncateButton } from 'shared/components/molecules'
import { useTruncateItems } from 'shared/components/molecules/TruncateButton/hooks'

interface ProfileEducationSectionProps {
  readOnly: boolean
  educations: ProfileEducation[]
  onAddEducationClick(): void
  onEditEducationClick(education: ProfileEducation): void
  wrapperClassName?: string
  titleSize?: 'heading' | 'large-heading'
  placeholder?: JSX.Element
  shouldTruncate?: boolean
}

const EducationIllustration = lazy(() =>
  import('icons/ilustrations/streamlinehq-good-job-keep-it-up.svg').then((module) => ({
    default: module.ReactComponent,
  })),
)

export const ProfileEducationSection = ({
  readOnly,
  educations,
  onAddEducationClick,
  onEditEducationClick,
  wrapperClassName,
  titleSize,
  placeholder,
  shouldTruncate,
}: ProfileEducationSectionProps) => {
  const sortedEducationHistory = useMemo<Array<ProfileEducation>>(() => {
    if (!educations[0]) return []

    return educations.sort(({ startDate: startDate1 }, { startDate: startDate2 }) => {
      if (startDate1 > startDate2) return -1
      return 1
    })
  }, [educations])

  const { visibleItems, truncatedItemsCount, setTruncate } = useTruncateItems<ProfileEducation>(
    sortedEducationHistory,
    shouldTruncate,
  )

  const handleTruncateClick = () => setTruncate(false)

  if (!educations.length && readOnly) return placeholder ?? null

  return (
    <ProfileSectionWrapper
      className={classNames('print:break-inside-avoid-page', wrapperClassName)}
      aria-labelledby="education-title"
    >
      {Boolean(sortedEducationHistory.length) && (
        <ContentWrapper>
          <ProfileSectionTitle
            readOnly={readOnly}
            titleSize={titleSize}
            title="Education"
            id="education-title"
            onButtonClick={onAddEducationClick}
          />
        </ContentWrapper>
      )}

      {visibleItems.length ? (
        visibleItems.map((education, index) => (
          <EducationRecord
            className={classNames({
              '!mb-0': index === visibleItems.length - 1,
            })}
            readOnly={readOnly}
            onButtonClick={onEditEducationClick}
            key={education.id}
            education={education}
          />
        ))
      ) : (
        <ProfileEmptyStateSection
          id="education-title"
          title="Education"
          content="Share where you studied, what you studied, and which degrees you've received."
          actions={
            <SecondaryButton size="sm" onClick={onAddEducationClick} className="w-full sm:w-fit">
              Add education
            </SecondaryButton>
          }
        >
          <EducationIllustration className="h-[150px] min-w-[150px]" />
        </ProfileEmptyStateSection>
      )}
      <TruncateButton truncatedItemsCount={truncatedItemsCount} onClick={handleTruncateClick}>
        Show other {truncatedItemsCount} educations
      </TruncateButton>
    </ProfileSectionWrapper>
  )
}
