export const useTag = () => {
  const format = (value: string) => {
    // prevent comma separated list
    const list = value.split(/\,|\+|\|/)
    if (list.length > 1) {
      value = (list.shift() ?? '').trim()
    }

    // remove forbiden char and limit spacing usage
    return value
      .replace(/[^a-zA-Z0-9\s\.\-]/g, '')
      .split(' ')
      .slice(0, 3)
      .join(' ')
  }

  return { format }
}
