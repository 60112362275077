import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { ListingTerms } from './components'

import { useNetwork } from 'shared/hooks'

import { ListingTerm } from 'job-listing/dtos'

export const JobListingTerms = ({
  currentListingTerm,
  skipTermsAcceptance,
  setValue,
  register,
  getError,
}: {
  currentListingTerm?: ListingTerm
  skipTermsAcceptance: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getError: any
}) => {
  const { get } = useNetwork()

  const errorMessage = getError('acceptTerms')
  const [termToBeShown, setTermToBeShown] = useState<ListingTerm>({
    id: -1,
    successFee: '',
    upfrontFee: '',
    guaranteeDays: 0,
    content: '',
  })
  const {
    data: term,
    isLoading,
    isIdle,
    isError,
  } = useQuery('listingTerm', () => get<ListingTerm>('/listings-term').then(({ data }) => data))

  useEffect(() => {
    setValue('acceptTerms', Boolean(currentListingTerm))
    term && setTermToBeShown(currentListingTerm || term)
  }, [currentListingTerm, setValue, term, isLoading])

  useEffect(() => {
    setValue('termId', termToBeShown?.id)
  }, [termToBeShown?.id])

  if (isLoading || isIdle || isError) {
    return null
  }

  return (
    <>
      {!skipTermsAcceptance && (
        <div className="mt-5">
          <ListingTerms
            register={register}
            errorMessage={errorMessage}
            term={termToBeShown}
            disabled={Boolean(currentListingTerm)}
          />
        </div>
      )}
    </>
  )
}
