interface CreateSeekerRecruiterRequest {
  countryTaxResidence?: string
  USTaxPayer?: boolean
}

export interface CreateSeekerRecruiterValues {
  location: {
    country: string
  }
  USTaxPayer: string
  isTosAccepted: boolean
  isCommunicationAccepted: boolean
}

export class CreateSeekerRecruiterDTO {
  constructor(private readonly values: CreateSeekerRecruiterValues | null) {}

  toJSON(): CreateSeekerRecruiterRequest {
    if (!this.values) return {}

    return {
      countryTaxResidence: this.values.location.country,
      USTaxPayer: this.values.USTaxPayer === 'true',
    }
  }
}
