import { ProfileCertification } from 'pages/JobSeekerProfile/types'
import { CertificationFormValues } from '../domain'

export class CertificationFormValuesFactory {
  static build(certification: ProfileCertification): CertificationFormValues {
    return {
      id: certification.id,
      name: certification.name,
      isTechnical: !!certification.stacks.length,
      stacks: certification.stacks,
      institutionId: certification.institution.id,
      institutionName: certification.institution.label,
      doesNotExpire: !certification.expireDate,
      issueDateMonth: new Date(certification.issueDate).getUTCMonth(),
      issueDateYear: new Date(certification.issueDate).getUTCFullYear(),
      expireDateMonth: new Date(certification.expireDate).getUTCMonth(),
      expireDateYear: new Date(certification.expireDate).getUTCFullYear(),
      credentialId: certification.credentialId,
      credentialUrl: certification.credentialUrl,
    }
  }
}
