import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { Bar, Histogram } from 'shared/components/atoms'
import { Slider, SliderOption } from 'shared/components/atoms'
import { useComputedBars } from './hooks'

interface HistogramSliderProps {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<Record<string, any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<Record<string, any>>
  options: Array<SliderOption>
  bars: Array<Pick<Bar, 'heightPercentage'>>
  labelledBy?: string
}

export const HistogramSlider = ({
  name,
  watch,
  setValue,
  options,
  bars,
  labelledBy,
}: HistogramSliderProps) => {
  const minValue = watch(`${name}.min`)
  const maxValue = watch(`${name}.max`)
  const computedBars = useComputedBars(bars, options, minValue, maxValue)

  return (
    <div className="flex flex-col items-stretch">
      <Histogram bars={computedBars} containerClassName="-mb-[9px]" />
      <Slider
        isRangeSlider
        name={name}
        watch={watch}
        setFormValue={setValue}
        options={options}
        aria-labelledby={labelledBy}
      />
    </div>
  )
}
