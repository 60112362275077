import { useEffect, useState } from 'react'
import { object, string } from 'yup'
import { AlertDialog } from 'shared/components/molecules'
import { useForm } from 'shared/hooks'

import { useReferralLink } from './useReferralLink'
import {
  Label,
  FieldHint,
  IconInput,
  PrimaryButton,
  SecondaryButton,
  Text,
} from 'shared/components/atoms'
import { useDebounce } from 'shared/hooks'

interface CreateReferralLinkModalProps {
  children: ({
    openModal,
    isLoading,
  }: {
    openModal: () => void
    isLoading: boolean
  }) => React.ReactNode
}

const schema = object().shape({
  slug: string().required(),
})

const DEFAULT_VALUE = 'app.onstrider.com/r/'

export const CreateReferralLinkModal = ({ children }: CreateReferralLinkModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showSlugSuccess, setShowSlugSuccess] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const { createLink, validate, isLoading } = useReferralLink()

  const { register, handleSubmit, getValues, watch, setError, getError, clearErrors, reset } =
    useForm({
      schema,
      defaultValues: { slug: '' },
    })

  const slug = watch('slug') as string

  useEffect(() => {
    setShowSlugSuccess(false)
    setButtonDisabled(true)
  }, [slug])

  const { debouncedValue } = useDebounce(slug, 500)

  useEffect(() => {
    if (debouncedValue)
      validate(debouncedValue)
        .then(() => {
          setShowSlugSuccess(true)
          setButtonDisabled(false)
          clearErrors('slug')
        })
        .catch((error: RequestError) => {
          setError('slug', {
            message: error.response?.data?.message || 'Link not allowed',
            type: 'validate',
          })
        })
  }, [debouncedValue])

  const handleFormSubmit = handleSubmit(() => {
    const { slug } = getValues()
    createLink(slug).then(() => closeModal())
  })

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => {
    reset()
    setIsModalOpen(false)
    setShowSlugSuccess(false)
  }

  return (
    <>
      {children({ isLoading, openModal })}
      <AlertDialog
        isOpen={isModalOpen}
        isCancellable={false}
        imagePath="/images/refer/link-background.png"
        customActions={
          <div className="flex flex-col-reverse md:grid md:grid-cols-2 gap-4 w-full">
            <SecondaryButton className="col-span-2 lg:col-span-1" onClick={closeModal}>
              I'll do it later
            </SecondaryButton>
            <PrimaryButton
              className="col-span-2 lg:col-span-1"
              type="submit"
              form="referral-link"
              disabled={buttonDisabled}
            >
              Create
            </PrimaryButton>
          </div>
        }
        title="Create your unique referral link"
        titleSize="large-heading"
        description={
          <div className="flex flex-col items-stretch gap-8">
            <div className="flex flex-col gap-4">
              <Text weight="font-normal" size="text-base">
                To refer talented people to Strider, you'll need to create your own referral link.
                Keep in mind that you can only create your referral link once.
              </Text>
            </div>
            <form id="referral-link" onSubmit={handleFormSubmit}>
              <Label htmlFor="slug">Your referral link</Label>
              <IconInput
                icon={DEFAULT_VALUE}
                name="slug"
                id="slug"
                register={register}
                inputClassName="pl-[169px]"
                customError={getError('slug')}
                successMessage={showSlugSuccess ? `'${slug}' is available!` : undefined}
              />
              <FieldHint className="mt-4">
                Minimum of 6 characters (letters, numbers, or _)
              </FieldHint>
            </form>
          </div>
        }
      />
    </>
  )
}
