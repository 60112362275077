import { useMutation, useQueryClient } from 'react-query'

import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'

import { UpdatePreferencesDTO } from 'modules/profiles/dtos'
import { Preferences } from 'modules/profiles/profile-preferences'
import { useMonitoring } from 'shared/hooks'

export const useUpdatePreferences = (profileId: string) => {
  const { toastError } = useToast()
  const { patch } = useNetwork()
  const { captureException } = useMonitoring()
  const client = useQueryClient()

  const { mutateAsync: updatePreferences, isLoading } = useMutation(
    (preferences: Preferences) => {
      const payload = new UpdatePreferencesDTO(preferences).toJSON()
      return patch(`/profile/${profileId}/preferences`, payload)
    },
    {
      onSuccess: () => {
        client.invalidateQueries(`candidates/${profileId}`)
        client.invalidateQueries('profile')
        client.invalidateQueries(`${profileId}-preferences`)
      },
      onError: (error) => {
        toastError('Failed to update the preferences')
        captureException(error)
      },
    },
  )

  return { updatePreferences, isLoading }
}
