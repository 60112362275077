import { useMemo } from 'react'

import { PreviewLabel, Tags } from 'shared/components/atoms'

import { Preferences } from 'modules/profiles/profile-preferences'
import { useMarketType } from 'company/exports'

export const NotInterested = ({ preferences }: { preferences: Preferences }) => {
  const { getLabelById, isLoading } = useMarketType()

  const tags = useMemo<Array<{ id: number; label: string }>>(() => {
    if (isLoading || !preferences) return []

    return preferences.notWillingToWorkIn.map(({ id }) => ({ id, label: getLabelById(id) || '' }))
  }, [isLoading, preferences])

  if (!tags.length) return null
  return (
    <PreviewLabel title="Not interested in" disabled id="not-interested-in">
      <Tags values={tags} background="bg-neutral-lightest" isReadOnly />
    </PreviewLabel>
  )
}
