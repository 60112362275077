import { Tooltip } from 'shared/components/atoms'
import { ClaimProtected } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { AccountClaim } from 'shared/hooks'

import { ReactComponent as CoinsIcon } from 'icons/listings/coins-stacked-01.svg'

interface RateLabelProps {
  label: string
  color: 'neutral-dark' | 'neutral-darkest'
}

export const RateLabel = ({ label, color }: RateLabelProps) => {
  return (
    <ClaimProtected require={AccountClaim.Staff}>
      <Tooltip content="Monthly budget (USD)">
        <div className="flex items-center gap-2 shrink-0">
          <CoinsIcon className={`w-4 h-4 stroke-${color}`} />
          <Text size="text-sm" weight="font-normal" className={`text-${color}`}>
            {label}
          </Text>
        </div>
      </Tooltip>
    </ClaimProtected>
  )
}
