import classNames from 'classnames'
import { SyntheticEvent, useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'shared/hooks'
import { Text } from 'components/Text'
import { ChatLauncher, useHelp } from 'modules/help'
import { FeedbackButton } from './FeedbackButton'
import { useLastAcceptedAgreement } from 'hooks/agreement'
import { TermsOfService } from 'onboarding/exports'
import { SurveysContext } from 'modules/surveys'
import { DemoModeToggler } from './DemoModeToggler'
import { AvaiabilityIcon } from './AvaiabilityIcon'
import { BlurredModeToggler } from './BlurredModeToggler'
import { cn } from 'modules/shared'

const defaultUserImage = '/icons/user.svg'

export const UserDropdown = () => {
  const [hidden, setIsHidden] = useState(true)
  const [isToSVisible, setIsToSVisible] = useState(false)
  const navigate = useNavigate()

  const {
    user: { imageUrl, fullName, email },
    signOut,
  } = useAuth()

  const { user } = useAuth()
  const { toggleChat, shutdownChat } = useHelp()
  const { showSurvey } = useContext(SurveysContext)
  const { agreement } = useLastAcceptedAgreement()

  const toggleVisibility = () => setIsHidden((previous) => !previous)

  const classes = classNames(
    'bg-neutral-day divide-y divide-neutral-lighter rounded shadow w-54 h-fit absolute',
    'top-12 right-0 max-w-[200px]',
    { hidden },
  )
  const backdropClasses = classNames('fixed top-0 left-0 w-screen h-screen z-20', { hidden })

  const handleSignOut = () => {
    signOut().then(() => {
      shutdownChat()
      window.location.assign('/login')
    })
  }

  const handleProfileImageLoadError = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const target = event.target as HTMLImageElement
    if (!target.src.match(defaultUserImage)) {
      target.src = defaultUserImage
    }
  }

  const handleOpenTermsModal = () => setIsToSVisible(true)
  const handleCloseTermsModal = () => setIsToSVisible(false)

  const handleClickUsername = () => {
    if (!user.isSeeker) return
    navigate('/account-settings')
  }

  return (
    <div className="flex flex-row h-full min-w-fit mb:mr-8">
      <div className={backdropClasses} onClick={toggleVisibility} />
      <ChatLauncher className="hidden md:flex justify-center" />
      <FeedbackButton />

      <div
        className="flex h-full px-6 rounded md:flex-none w-fit items-center justify-center cursor-pointer z-30 relative"
        onClick={toggleVisibility}
        aria-label="Account menu"
      >
        <div className="relative">
          <img
            className="w-8 h-8 rounded-full"
            src={imageUrl}
            onError={handleProfileImageLoadError}
          />
          <AvaiabilityIcon />
        </div>

        <div className={classes} role="menu">
          <div
            className={cn('px-4 py-3', { 'hover:bg-neutral-lightest': user.isSeeker })}
            onClick={handleClickUsername}
          >
            <Text>{fullName}</Text>
            <Text color="text-neutral-dark" truncate className="font-medium">
              {email}
            </Text>
          </div>

          <ul className="text-sm" aria-labelledby="dropdownInformationButton">
            {user.isManager && (
              <li>
                <Link to="/settings" className="block px-4 py-2 hover:bg-neutral-lightest">
                  <Text>Settings</Text>
                </Link>
              </li>
            )}
            <li>
              <a
                href="/privacy-policy.pdf"
                target="_blank"
                rel="nofollow"
                className="flex items-center gap-2 px-4 py-2 hover:bg-neutral-lightest"
              >
                <Text>Privacy Policy</Text>
              </a>
            </li>
            <li>
              <Text
                className="self-center gap-2 px-4 py-2 hover:bg-neutral-lightest"
                onClick={handleOpenTermsModal}
              >
                Terms of Use
              </Text>
            </li>
          </ul>

          <ul className="md:hidden">
            <li className="px-4 py-2" onClick={toggleChat}>
              <Text>Ask for help</Text>
            </li>

            <li className="px-4 py-2" onClick={() => showSurvey()}>
              <Text>Give us feedback</Text>
            </li>
          </ul>

          <div>
            <a
              href="#"
              onClick={handleSignOut}
              className="block px-4 py-2 hover:bg-neutral-lightest"
            >
              <Text>Sign out</Text>
            </a>
          </div>

          <DemoModeToggler />

          <BlurredModeToggler />
        </div>
      </div>
      {isToSVisible && (
        <TermsOfService
          title="Terms of Service"
          agreement={agreement}
          onAccept={() => {}}
          onCancel={handleCloseTermsModal}
          readOnly
        />
      )}
    </div>
  )
}
