import { Paragraph, PrimaryButton, TertiaryButton, Title } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'

import { DeclinationForm } from './DeclinationForm'

import { useDeclineJobOpportunity } from 'job-opportunity/hooks'
import { useForm } from 'shared/hooks'
import { schema } from './schema'

import { JobOpportunity } from 'job-opportunity/domain'
import { DeclineJobOpportunityDTO } from 'job-opportunity/dtos'

interface Props {
  open: boolean
  onCancel: () => void
  onDecline: () => void
  jobOpportunity: JobOpportunity
}

export const DeclinationModal = ({ open, onCancel, onDecline, jobOpportunity }: Props) => {
  const { register, watch, handleSubmit, getValues, setValue, reset, isDirty } = useForm({
    schema,
    defaultValues: { reasons: {} },
  })

  const handleClose = () => {
    reset()
    onCancel()
  }

  const { decline } = useDeclineJobOpportunity(jobOpportunity.id, jobOpportunity.screening.id)

  const handleFormSubmit = handleSubmit(() => {
    const { notes, reasons, otherReason, pausedDays, desiredPayRate } = getValues()

    decline(
      new DeclineJobOpportunityDTO(
        reasons,
        otherReason,
        notes,
        desiredPayRate,
        pausedDays,
      ).toJSON(),
    )

    onDecline()
    reset()
  })

  return (
    <Modal
      mobilePositioning="bottom"
      title={
        <div className="flex flex-col">
          <Title size="heading" className="hidden sm:block">
            Help us improve our job matchings for you
          </Title>
          <Title size="heading" className="sm:hidden">
            Help us improve our matchings for you
          </Title>
          <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark">
            Please let us know why you are not interested in this job opportunity.
          </Paragraph>
        </div>
      }
      open={open}
      handleClose={handleClose}
      showDismissalConfirmation={isDirty}
      contentContainerClassName="!py-10"
      content={
        <DeclinationForm
          onSubmit={handleFormSubmit}
          register={register}
          setValue={setValue}
          watch={watch}
          paymentModel={jobOpportunity.screening.paymentModel}
        />
      }
      footer={
        <div className="flex flex-col-reverse sm:flex-row justify-end items-center gap-4 w-full">
          <TertiaryButton onClick={handleClose} className="w-full sm:w-fit">
            Back
          </TertiaryButton>
          <PrimaryButton
            type="submit"
            form="job-opportunity-declination"
            className="w-full sm:w-fit"
          >
            Submit feedback
          </PrimaryButton>
        </div>
      }
    />
  )
}
