import { ReactNode } from 'react'

import { Text, Breadcrumbs, Crumbs } from 'shared/components/atoms'

interface WrapperProps {
  children: ReactNode
  title: string
  isOnboarding?: boolean
}

export const Wrapper = ({ children, title, isOnboarding }: WrapperProps) => {
  const crumbs: Array<Crumbs> = [
    { name: 'Job listings', path: '/' },
    { name: title, path: '' },
  ]

  return (
    <div className="grid grid-cols-12 sm:gap-x-6 pt-6">
      <section className="col-span-12 md:col-start-3 md:col-end-11">
        {!isOnboarding && <Breadcrumbs crumbs={crumbs} className="mb-3" />}
        <Text size="text-2xl" weight="font-medium" className="text-neutral-darkest mb-12">
          {title}
        </Text>
        {children}
      </section>
    </div>
  )
}
