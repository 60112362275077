import { Theme } from '@clerk/types'
import { cn } from 'modules/shared'
import { appealingTitleStyles } from 'shared/components/atoms'

type FormElements = Theme['elements']

export interface AppearanceStyles {
  centralized?: Optional<boolean>
  theme?: Optional<'light' | 'dark'>
  hideLogo?: Optional<boolean>
}

export const appearance: Theme = {
  elements: {
    rootBox: 'w-full block font-inter',
    main: 'gap-4',
    card: 'w-full shadow-none bg-transparent px-6 pt-8 pb-12 md:p-12 max-w-none',
    headerSubtitle: 'hidden',
    headerTitle: 'font-medium text-base md:text-xl text-neutral-darkest font-medium',
    dividerLine: 'hidden',
    dividerText: 'uppercase text-neutral-medium tracking-tight font-medium text-sm',
    socialButtonsBlockButton: 'flex items-center w-full h-12',
    socialButtonsBlockButtonArrow: 'hidden',
    socialButtonsBlockButtonText:
      'flex-grow-0 flex flex-row items-center gap-x-3 p-0 m-0 font-normal text-base text-left text-neutral-darkest',
    formButtonPrimary: `h-12 bg-neutral-darkest hover:bg-neutral-darkest text-base font-normal normal-case`,
    formFieldInput: 'h-12 text-base',
    footerAction: 'flex w-full justify-center',
    formFieldLabel: 'font-medium text-sm text-neutral-darkest mb-1',
    footerActionText: 'font-medium text-sm text-neutral-darkest',
    footerActionLink: 'font-medium text-sm text-info-medium',
    headerBackLink: 'text-neutral-darkest',
  },
}

export const darkThemeAppearance: Theme = {
  elements: Object.assign<object, FormElements, FormElements>({}, appearance.elements, {
    headerTitle: 'font-medium text-base md:text-xl text-neutral-25 font-medium',
    socialButtonsBlockButton:
      'flex items-center justify-center w-full h-12 bg-neutral-day border border-neutral-light hover:bg-neutral-day',
    socialButtonsBlockButtonText:
      'gap-x-3 p-0 m-0 font-normal w-fit text-base text-left text-neutral-darkest',
    formButtonPrimary:
      'h-12 text-neutral-darkest hover:text-neutral-lightest border border-solid border-neutral-light bg-neutral-day hover:bg-neutral-600 text-base font-normal normal-case',
    formFieldInput:
      'h-12 text-base bg-neutral-day bg-opacity-10 border border-neutral-light text-neutral-lightest',
    formFieldLabel: 'font-medium text-sm text-neutral-lightest mb-1',
    footerActionText: 'font-medium text-sm text-neutral-lightest',
    footerActionLink: 'font-medium text-sm text-blue-300',
    formFieldSuccessText__password: 'text-neutral-lightest',
    formFieldInfoText__password: 'text-neutral-lightest',
    identityPreviewText: 'text-neutral-lightest',
    formHeaderTitle: 'text-neutral-lightest',
    formHeaderSubtitle: 'text-neutral-lightest',
    formResendCodeLink: 'text-blue-300',
    alertText: 'text-neutral-lightest',
    alert__danger: 'border-neutral-light bg-neutral-day bg-opacity-10',
    identityPreview: 'bg-neutral-day bg-opacity-10 border-neutral-light',
  }),
}

export const lightThemeAppearance: Theme = {
  elements: Object.assign<object, FormElements, FormElements>({}, appearance.elements, {
    rootBox: 'flex justify-center w-full font-inter',
    card: 'w-full gap-12 shadow-none p-0 bg-transparent max-w-none',
    header: 'mt-4',
    socialButtonsBlockButton:
      'bg-neutral-day rounded-lg h-12 flex items-center justify-center gap-3',
    formFieldInput: 'rounded-lg h-12',
    formButtonPrimary:
      'h-12 bg-neutral-darkest hover:bg-neutral-darkest text-base font-normal normal-case rounded-lg',
  }),
}

export const signUpAppearance: ({ centralized, theme, hideLogo }: AppearanceStyles) => Theme = ({
  centralized,
  theme,
  hideLogo,
}: AppearanceStyles) => {
  let styles
  if (theme === 'dark') styles = darkThemeAppearance
  else if (theme === 'light') styles = lightThemeAppearance
  else styles = appearance

  return {
    elements: Object.assign<object, FormElements, FormElements>({}, styles.elements, {
      logoBox: cn({
        'flex justify-center -mt-10': centralized,
        flex: !centralized,
        hidden: hideLogo,
      }),
      logoImage: cn({ '-mt-32': centralized }),
      headerTitle: cn('font-medium text-base md:text-xl text-neutral-darkest font-medium', {
        [appealingTitleStyles({ className: 'text-neutral-25' })]: theme === 'dark',
        [appealingTitleStyles({ className: 'text-neutral-darkest' })]: theme === 'light',
      }),
      formFieldRow__name: 'hidden',
    }),
  }
}
