import { ContractLength, contractLengthLabels } from 'job-listing/enums'

export const contractLengthOptions = [
  {
    value: ContractLength.UpToThreeMonths,
    label: contractLengthLabels[ContractLength.UpToThreeMonths],
  },
  {
    value: ContractLength.UpToSixMonths,
    label: contractLengthLabels[ContractLength.UpToSixMonths],
  },
  {
    value: ContractLength.UpToTwelveMonths,
    label: contractLengthLabels[ContractLength.UpToTwelveMonths],
  },
] as Array<{ value: string; label: string }>
