import { plainToInstance } from 'class-transformer'
import { useMutation } from 'react-query'

import { useMonitoring, useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { ReferralStatsReport } from 'modules/referrals/domains'

export const useReferralStats = () => {
  const { post } = useNetwork()
  const { toastError } = useToast()
  const { captureException } = useMonitoring()

  const {
    data: report = { counts: [], total: 0 },
    mutateAsync: generateReport,
    isLoading,
  } = useMutation(
    ['referral-stats-report'],
    () =>
      post<ReferralStatsReport>(`/referrals/stats-report`).then(({ data }) =>
        plainToInstance(ReferralStatsReport, data),
      ),
    {
      onError: (error: RequestError) => {
        toastError(error.response.data?.message)
        captureException(error)
      },
    },
  )

  return {
    report,
    generateReport,
    isLoading,
  }
}
