import { TrackingEvent } from '../tracking-event'

export class InviteFriendCopyReferLink implements TrackingEvent {
  name = 'Invite friends copy refer link click'

  constructor(
    private userEmail: string,
    private slug: string,
  ) {}

  get payload() {
    return {
      userEmail: this.userEmail,
      slug: this.slug,
    }
  }
}
