import { useQuery } from 'react-query'
import { plainToInstance } from 'class-transformer'
import { useNetwork } from 'shared/hooks'
import { useToast } from 'shared/hooks'
import { ScreeningItem } from 'screening/domain'
import { useMonitoring } from 'shared/hooks'

export const useFetchScreenings = (listingId: string) => {
  const { toastError } = useToast()
  const { get } = useNetwork()
  const { captureException } = useMonitoring()

  const {
    data: screenings = [],
    isLoading,
    refetch,
    isRefetching,
  } = useQuery(
    ['screenings'],
    () =>
      get<Array<ScreeningItem>>(`listings/${listingId}/screenings`).then(({ data }) =>
        plainToInstance(ScreeningItem, data),
      ),
    {
      onError: (error: RequestError) => {
        toastError('Failed to fetch screenings.')
        captureException(error)
      },
      staleTime: 5 * 60 * 1000,
    },
  )

  return { screenings, isLoading: isLoading || isRefetching, refetch }
}
