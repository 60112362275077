import { ListingInfo } from 'job-listing/domains'

export class ScreeningTitle {
  constructor(
    private readonly listing: ListingInfo | undefined,
    private readonly existingScreeningsCount: number,
  ) {}

  private get titleSuffix() {
    if (this.existingScreeningsCount) return `(${this.existingScreeningsCount})`
    return ''
  }

  get title() {
    return `${this.listing?.title || ''} ${this.titleSuffix}`
  }
}
