import classNames from 'classnames'
import { Text } from 'components/Text'

interface BannerProps {
  children: string | React.ReactNode
  className?: string
}

export const Success = ({ children, className }: BannerProps) => {
  return (
    <div
      className={classNames(
        'p-4 flex flex-row items-start gap-3 bg-success-lighter rounded-lg',
        className,
      )}
    >
      <Text size="small-body" weight="font-normal">
        {children}
      </Text>
    </div>
  )
}
