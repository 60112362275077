import { HTMLAttributes, ReactNode } from 'react'
import { TagOption } from '.'
import { Tag } from './Tag'
import { cn } from 'modules/shared'

interface TagsProps extends HTMLAttributes<HTMLDivElement> {
  values: Array<TagOption>
  onRemove?(id: number | string | null, label: string | null): void
  isReadOnly?: boolean
  children?: ReactNode
  icon?: React.ReactNode
  background?: 'bg-success-lighter' | 'bg-neutral-lightest'
  tagClassName?: string
  containerClassName?: string
  omitWhenEmpty?: boolean
}

export const Tags = ({
  values,
  onRemove,
  isReadOnly,
  children,
  icon,
  background = 'bg-neutral-lightest',
  tagClassName,
  containerClassName,
  omitWhenEmpty = true,
  ...restProps
}: TagsProps) => {
  if (!values.length && omitWhenEmpty) return null

  return (
    // Please, don't remove the print:gap-0 prop otherwise the export PDF feature won't work anymore.
    <div {...restProps} className={cn('flex flex-wrap gap-2 print:gap-0', containerClassName)}>
      {values.map(({ id, label, persisted, highlight, className, hint }, index) => (
        <Tag
          key={`${id}-${index}`}
          id={id}
          label={label}
          persisted={persisted}
          highlight={highlight}
          className={className}
          index={index}
          isReadOnly={isReadOnly}
          icon={icon}
          background={background}
          onRemove={onRemove}
          tagClassName={tagClassName}
          hint={hint}
        />
      ))}
      {children}
    </div>
  )
}
