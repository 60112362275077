import { TrackingEvent } from '../tracking-event'

export class JobOpportunityStatusExplanationClick implements TrackingEvent {
  name = 'Job opportunity status explanation click'

  constructor(private articleId: number) {}

  get payload() {
    return { articleId: this.articleId }
  }
}
