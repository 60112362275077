import { useQueryClient } from 'react-query'

import { PrimaryButton, SecondaryButton } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { Subscribers } from 'job-listing/components'
import { useForm } from 'shared/hooks'
import { ListingInfo } from 'job-listing/domains'

import { useSubscription } from './useSubscription'
import { schema } from './schema'

export const AddWatchersModal = ({
  listing,
  employees,
  handleClose,
}: {
  listing: ListingInfo
  employees: Array<{ id: number; email: string }>
  handleClose: () => void
}) => {
  const client = useQueryClient()
  const { subscribe } = useSubscription()
  const { handleSubmit, getError, watch, setValue, reset } = useForm({
    schema,
    defaultValues: {
      subscribers: listing.subscriptions.map(({ subscriberId, email }) => {
        return { id: subscriberId, label: email }
      }),
    },
  })

  const subscribers: Array<{ id: number; label: string }> = watch('subscribers')

  const onClose = () => {
    handleClose()
  }

  const onSubmit = handleSubmit(() => {
    subscribe({ listingId: listing.id, subscribers }).then(() =>
      client.invalidateQueries(['listings', listing.id, 'preview']),
    )
    reset()
    onClose()
  })

  return (
    <Modal
      withBorders={false}
      handleClose={onClose}
      title="Add watchers"
      content={
        <form id="addWatchersForm" onSubmit={onSubmit} noValidate>
          <Subscribers
            subscribers={subscribers}
            employees={employees}
            getError={getError}
            setValue={setValue}
          />
        </form>
      }
      footer={
        <>
          <SecondaryButton onClick={onClose} className="w-full md:w-fit">
            Cancel
          </SecondaryButton>
          <PrimaryButton type="submit" form="addWatchersForm" className="w-full md:w-fit">
            Add watchers
          </PrimaryButton>
        </>
      }
    />
  )
}
