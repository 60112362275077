import { AppealingTitle, Text, TitleSizes } from 'shared/components/atoms'

import { ReactComponent as CheckIcon } from 'icons/check-thin.svg'

export const GetHelp = () => {
  const sentences = [
    'Hire elite developers.',
    'Fill positions in 1-2 weeks.',
    'Save on average 42% versus a US hire.',
  ]

  return (
    <div className="flex flex-col p-8 rounded-2xl -z-10 bg-neutral-day bg-opacity-60 backdrop:blur-sm gap-8 h-fit w-fit">
      <AppealingTitle size={TitleSizes.xs}>Learn how Strider can help you</AppealingTitle>

      <div className="flex flex-col gap-4">
        {sentences.map((sentence, index) => {
          return (
            <div className="flex gap-2 items-center" key={`sentence-${index}`}>
              <CheckIcon className="stroke-success-dark w-6 h-6" />
              <Text size="text-base" className="text-neutral-600">
                {sentence}
              </Text>
            </div>
          )
        })}
      </div>

      <div className="flex gap-2 items-center">
        <div className="flex">
          <img src="/images/discovery-call/candidate-one.png" className="w-8 h-8 -ml-2" alt="" />
          <img src="/images/discovery-call/candidate-two.png" className="w-8 h-8 -ml-2" alt="" />
          <img src="/images/discovery-call/candidate-three.png" className="w-8 h-8 -ml-2" alt="" />
        </div>
        <Text size="text-base" weight="font-medium" className="text-neutral-900">
          35,000+
        </Text>
        <Text size="text-base" className="text-neutral-600">
          developers are waiting for you.
        </Text>
      </div>
    </div>
  )
}
