import React, { useContext } from 'react'

import { TertiaryButton } from 'shared/components/atoms'
import { useDeleteMatching } from 'hooks/matchings'
import { useUpdateSearchInfo } from 'candidates-search/hooks'
import { Candidate } from 'candidates-search/domain'

import { ReactComponent as ShowIcon } from 'icons/advanced-search/show.svg'
import { SearchContext } from 'candidates-search/contexts'

interface UndoMatchingProps {
  profile: Candidate
  children?: string
  className?: string
  icon?: React.ReactElement
  size?: 'lg' | 'md'
}

export const UndoMatching = ({
  profile,
  children,
  className,
  icon = <ShowIcon className="!w-6 !h-6" />,
  size = 'md',
}: UndoMatchingProps) => {
  const { search, setSearchResult } = useContext(SearchContext)
  const { updateSearchInfo } = useUpdateSearchInfo()

  const matching = search?.getMatchingByProfileId(profile.id)

  const updateSearch = () => {
    setSearchResult((previous) => ({
      profiles: previous.profiles.filter(({ id }) => id !== profile.id),
      totalRecords: previous.totalRecords - 1,
      totalPages: previous.totalPages,
    }))
  }

  const { deleteMatching, isDeleting } = useDeleteMatching(
    search?.listing?.id,
    matching?.matchingId,
    updateSearch,
  )

  const undo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    deleteMatching()?.then(() => updateSearchInfo(search?.id))
  }

  return (
    <TertiaryButton
      onClick={undo}
      icon={icon}
      isIconBefore
      isLoading={isDeleting}
      className={className}
      size={size}
    >
      {children}
    </TertiaryButton>
  )
}
