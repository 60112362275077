import { IconBanner } from 'shared/components/molecules'
import { ReactComponent as AlertIcon } from 'icons/alert-triangle.svg'

import { Candidate } from 'candidates-search/domain'

export const EnglishCheckWarning = ({ candidate }: { candidate: Candidate }) => {
  if (!candidate.englishLevel?.isMissing) return null

  return (
    <IconBanner
      icon={<AlertIcon className="w-6 h-6 stroke-warning-medium shrink-0" />}
      bgColor="bg-warning-lighter"
      className="mt-4"
    >
      Candidate is missing English Check. This will still be visible on their profile but won't be
      added as a flag.
    </IconBanner>
  )
}
