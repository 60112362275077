import { cn } from 'modules/shared'
import React from 'react'
import { PrimaryButton, TertiaryButton } from 'shared/components/atoms'

interface FooterProps {
  children: React.ReactNode
}
export const Footer = ({ children }: FooterProps) => {
  return (
    <div className="flex w-full gap-4 justify-end flex-col-reverse sm:flex-row">{children}</div>
  )
}

export const ModalFooter = ({
  form,
  confirmLabel,
  cancelLabel,
  isLoading,
  className,
  onCancel,
  onConfirm,
}: {
  confirmLabel: string
  cancelLabel: string
  isLoading: boolean
  form?: string
  className?: string
  onConfirm: () => void
  onCancel: () => void
}) => {
  const type = form ? 'submit' : 'button'

  return (
    <div
      className={cn(
        'flex flex-col-reverse md:flex-row justify-end items-center gap-2 md:gap-4 w-full',
        className,
      )}
    >
      <TertiaryButton onClick={onCancel} isLoading={isLoading} className="w-full md:w-fit">
        {cancelLabel}
      </TertiaryButton>
      <PrimaryButton
        form={form}
        type={type}
        onClick={onConfirm}
        isLoading={isLoading}
        className="w-full md:w-fit"
      >
        {confirmLabel}
      </PrimaryButton>
    </div>
  )
}
