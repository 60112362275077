export enum JobOpportunityStatus {
  Pending = 'pending',
  AwaitingReview = 'awaiting_review',
  NotInterested = 'not_interested',
  NotPicked = 'not_picked',
  InProgress = 'in_progress',
  Expired = 'expired',
  Closed = 'closed',
  Received = 'received',
}
