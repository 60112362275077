import { Text, CurrencyInput } from 'shared/components/atoms'
import { RateLowerThanRequestedBanner, MatchingSimulation } from 'matching/exports'

import { FormControl } from 'shared/hooks'

import { MatchingSimulation as MatchingSimulationDomain } from 'hooks/matchings'
import { PaymentModel } from 'job-listing/enums'

interface CandidateCompensationProps {
  formControl: FormControl
  compensation: { min: number; max: number }
  simulation: Nullable<MatchingSimulationDomain>
  paymentModel: PaymentModel
  client: string
  isMissingParams: boolean
}

export const CandidateCompensation = ({
  formControl,
  compensation,
  simulation,
  paymentModel,
  client,
  isMissingParams,
}: CandidateCompensationProps) => {
  const { register, watch, setValue, isValid } = formControl

  const compensationValue = Number(watch('compensation'))

  const rateLowerThanExpected = compensationValue < compensation.min
  const showRateWarning = Boolean(isValid && rateLowerThanExpected)

  return (
    <div className="flex flex-col gap-8">
      <Text size="text-xl">Payrate & cost</Text>

      <div className="flex flex-col">
        <Text size="text-sm" weight="font-medium" className="mb-2">
          Enter the candidate's expected pay rate for this matching
        </Text>
        <CurrencyInput
          name="compensation"
          register={register}
          watch={watch}
          setValue={setValue}
          showError={!showRateWarning}
          alwaysValidate
        />
        {showRateWarning && <RateLowerThanRequestedBanner className="mt-6 mb-10" />}
      </div>
      <MatchingSimulation
        client={client}
        isMissingParams={isMissingParams}
        minCompensation={compensation.min}
        maxCompensation={compensation.max}
        simulation={simulation}
        isValid={isValid}
        paymentModel={paymentModel}
      />
    </div>
  )
}
