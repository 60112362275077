export enum MatchingFlagType {
  ScreeningExpired = 'matching.expired',
  DeclinedPayRate = 'matching.declined-pay-rate',
  PayRateMisalignment = 'matching.pay-rate-misalignment',
  PoorProfileContent = 'matching.poor-profile-content',
  InconsistentInfo = 'matching.inconsistent-info',
  LowConfidence = 'matching.low-confidence',
}

export const matchingFlagTypeLabels = {
  [MatchingFlagType.ScreeningExpired]: 'Expired',
  [MatchingFlagType.DeclinedPayRate]: 'Declined Pay Rate',
  [MatchingFlagType.PayRateMisalignment]: 'Pay Rate Misalignment',
  [MatchingFlagType.PoorProfileContent]: 'Poor Profile Content',
  [MatchingFlagType.InconsistentInfo]: 'Inconsistent Info',
  [MatchingFlagType.LowConfidence]: 'Low Confidence',
}
