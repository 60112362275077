import { HTMLAttributes } from 'react'
import classNames from 'classnames'

import styles from './HideScrollContainer.module.scss'

interface HideScrollContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  className?: string
}

export const HideScrollContainer = ({
  children,
  className,
  ...restProps
}: HideScrollContainerProps) => {
  return (
    <div {...restProps} className={classNames(className, styles.container)}>
      {children}
    </div>
  )
}
