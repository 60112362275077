import { useEffect, useState } from 'react'
import * as Collapsible from '@radix-ui/react-collapsible'

import { cn } from 'modules/shared'
import { Text } from 'components/Text'
import { ReactComponent as ChevronUpIcon } from 'icons/chevron-up.svg'
import { ReactComponent as ChevronDownIcon } from 'icons/chevron-down.svg'

import './Collapse.scss'

interface CollapseProps {
  children: string | React.ReactNode
  contentOnTop?: boolean
  onStateChange: (isExpanded: boolean) => void
  triggerContent?: React.ReactNode
  triggerClassName?: string
  contentClassName?: string
  initiallyExpanded?: boolean
  containerClassName?: string
}

const Content = ({
  children,
  contentClassName,
}: {
  children: React.ReactNode
  contentClassName?: string
}) => {
  useEffect(() => {
    const elements = document.querySelectorAll('.collapsible-content')

    elements.forEach((element) => {
      const htmlElement = element as HTMLElement
      let timeoutId: NodeJS.Timeout

      const transitionEndHandler = () => {
        if (htmlElement.getAttribute('data-state') === 'open') {
          timeoutId = setTimeout(() => {
            htmlElement.style.overflow = 'visible'
          }, 300)
        }

        if (htmlElement.getAttribute('data-state') === 'closed') {
          htmlElement.style.overflow = 'hidden'
        }
      }

      htmlElement.addEventListener('animationend', transitionEndHandler)

      return () => {
        htmlElement.removeEventListener('animationend', transitionEndHandler)
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
      }
    })
  }, [])

  return (
    <Collapsible.Content
      className={cn(
        'relative data-state-open:expand data-state-closed:collapse mb-6 collapsible-content',
        contentClassName,
      )}
    >
      {children}
    </Collapsible.Content>
  )
}

const Collapse = ({
  children,
  contentOnTop,
  onStateChange,
  triggerContent,
  triggerClassName,
  contentClassName,
  initiallyExpanded = false,
  containerClassName,
}: CollapseProps) => {
  const [isExpanded, setExpanded] = useState(initiallyExpanded)
  const handleCollapseClick = () => setExpanded(!isExpanded)

  useEffect(() => {
    onStateChange(isExpanded)
  }, [isExpanded])

  return (
    <Collapsible.Root open={isExpanded} className={containerClassName}>
      {contentOnTop && <Content contentClassName={contentClassName}>{children}</Content>}
      <Collapsible.Trigger
        onClick={handleCollapseClick}
        className={cn('flex w-full items-center justify-center gap-2 h-8', triggerClassName)}
      >
        {triggerContent ||
          (isExpanded ? (
            <>
              <Text size="small-body">See less</Text>
              <ChevronUpIcon className="w-6 h-6 stroke-neutral-darkest" />
            </>
          ) : (
            <>
              <Text size="small-body">See details</Text>
              <ChevronDownIcon className="w-6 h-6 stroke-neutral-darkest" />
            </>
          ))}
      </Collapsible.Trigger>
      {!contentOnTop && <Content contentClassName={contentClassName}>{children}</Content>}
    </Collapsible.Root>
  )
}

export { Collapse }
