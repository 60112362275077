import { ReactComponent as ExcellentIcon } from 'icons/advanced-search/excellent-profile.svg'
import { ReactComponent as ReadyIcon } from 'icons/advanced-search/ready-profile.svg'
import { ReactComponent as UnderReviewIcon } from 'icons/advanced-search/under-review.svg'
import { ReactComponent as RejectedIcon } from 'icons/advanced-search/rejected-profile.svg'

import { Tooltip } from 'shared/components/atoms'
import { ProfileFulfillmentStatus } from 'candidates-search/enums'

interface Props {
  status: ProfileFulfillmentStatus
}

export const ProfileFulfillmentBadge = ({ status }: Props) => {
  const badges = {
    excellent: {
      component: <ExcellentIcon />,
      tooltip: 'Excellent',
    },
    sufficient: {
      component: <ReadyIcon />,
      tooltip: 'Sufficient',
    },
    under_review: {
      component: <UnderReviewIcon />,
      tooltip: 'Under review',
    },
    rejected: {
      component: <RejectedIcon />,
      tooltip: 'Rejected',
    },
    out_of_bounds: {
      component: <RejectedIcon />,
      tooltip: 'Out of bounds',
    },
  }

  if (!status || !badges[status]) return null

  return (
    <>
      {Boolean(badges[status].component) && (
        <Tooltip content={badges[status].tooltip} className="z-40">
          {badges[status].component}
        </Tooltip>
      )}
    </>
  )
}
