import { useNavigate } from 'react-router-dom'
import { useAuth } from 'shared/hooks'

interface EmployerOwnerOnlyProps {
  children: JSX.Element
  redirectUrl?: string
}

export const EmployerOwnerOnly = ({ children, redirectUrl }: EmployerOwnerOnlyProps) => {
  const {
    user: { isManager },
  } = useAuth()
  const navigate = useNavigate()

  if (isManager) return children
  if (redirectUrl) navigate(redirectUrl)
  return null
}
