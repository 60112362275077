import { Tooltip } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'

import { ReactComponent as ClockIcon } from 'icons/clock.svg'

interface ContractLabelProps {
  label: string
  color: 'neutral-dark' | 'neutral-darkest'
}

export const ContractLabel = ({ label, color }: ContractLabelProps) => {
  return (
    <Tooltip content="Contract details">
      <div className="flex items-center gap-2 shrink-0">
        <ClockIcon className={`w-4 h-4 stroke-${color}`} />
        <Text size="text-sm" weight="font-normal" className={`text-${color}`}>
          {label}
        </Text>
      </div>
    </Tooltip>
  )
}
