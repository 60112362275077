import { useNetwork } from 'shared/hooks'
import { useMutation } from 'react-query'

const UPLOAD_PRESET = 'resumes'
const CLOUD = process.env.REACT_APP_CLOUDINARY_CLOUD
const API_KEY = process.env.REACT_APP_CLOUDINARY_API_KEY
const UPLOAD_URL = `https://api.cloudinary.com/v1_1/${CLOUD}/raw/upload`

const buildUploadParams = ({
  file,
  profileId,
  signature,
  timestamp,
}: {
  file: File
  profileId: string
  signature: string
  timestamp: number
}) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('upload_preset', UPLOAD_PRESET)
  formData.append('public_id', profileId)
  formData.append('signature', signature)
  formData.append('api_key', String(API_KEY))
  formData.append('timestamp', String(timestamp))

  return formData
}

export const useImportProfile = (profileId: string) => {
  const { post } = useNetwork()

  const generateUploadSignature = async (profileId: string) => {
    return await post<{ signature: string; timestamp: number }>(
      `/profile/${profileId}/attachment-signatures`,
      { uploadPreset: UPLOAD_PRESET },
    ).then(({ data }) => data)
  }

  const sendImportToApi = async (file: File, profileId: string) => {
    const formData = new FormData()
    formData.append('file', file!)

    await post(`/profile/${profileId}/import`, formData)
  }

  const { mutateAsync: importProfile, isLoading: isImporting } = useMutation(async (file: File) => {
    const { signature, timestamp } = await generateUploadSignature(profileId)
    const body = buildUploadParams({ file, timestamp, signature, profileId })

    await fetch(UPLOAD_URL, { method: 'POST', body })
    return await sendImportToApi(file, profileId)
  })

  return { importProfile, isImporting }
}
