import { TrackingEvent } from '../tracking-event'

export class InviteFriendClick implements TrackingEvent {
  name = 'Invite friends send invite click'

  constructor(
    private invitedBy: string,
    private inviteTo: string,
  ) {}

  get payload() {
    return {
      invitedBy: this.invitedBy,
      inviteTo: this.inviteTo,
    }
  }
}
