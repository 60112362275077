import { EngagementType } from 'job-listing/enums'
import { array, boolean, number, object, string } from 'yup'

const errorMessages = {
  default: 'Please fill out this field.',
  missingRole: 'Please choose a role',
  missingJobTitle: 'Please share the job title for this role.',
  missingDomain: 'Please tell us which domain the engineer is oriented towards.',
  missingPosition: "Please tell us which role you're hiring for.",
  missingStacks: 'Please add at least one desired skill.',
  tooManyStacks: (maxLength: number) => `Please add up to ${maxLength} items.`,
  invalidOpeningsNumber: 'Invalid number.',
  missingSalary: 'Please share your budget.',
  tooLowSalary: 'Must be greater than 1',
  fromGreaterThanTo: 'From value must be greater than to value.',
  missingTermsAcceptance: 'Please accept the listing terms to proceed.',
  missingSubscribers: 'Please add at least one person to listen for updates',
}

export const schema = object({
  jobTitle: string().required(errorMessages.missingJobTitle),
  roleId: number().nullable(),
  roleLabel: string().required(errorMessages.missingRole).nullable(),
  roleType: string(),
  experienceRange: object().shape({
    min: string().required(),
    max: string().required(),
  }),
  numberOfOpenings: number()
    .min(1, errorMessages.invalidOpeningsNumber)
    .required(errorMessages.default),
  paymentModel: string().required(),
  salaryFrom: number()
    .required(errorMessages.missingSalary)
    .min(1, errorMessages.tooLowSalary)
    .typeError(errorMessages.missingSalary),
  salaryTo: number()
    .required(errorMessages.missingSalary)
    .min(1, errorMessages.tooLowSalary)
    .typeError(errorMessages.missingSalary)
    .test('to_greater_than_from', errorMessages.fromGreaterThanTo, function (value) {
      if (!this.parent.salaryFrom) return true
      if (this.parent.salaryFrom > this.parent.salaryTo) return false
      return true
    }),

  stacks: array()
    .of(
      object().shape({
        id: number(),
        preference: string(),
      }),
    )
    .min(1, errorMessages.missingStacks)
    .max(10, errorMessages.tooManyStacks(10)),
  detailedRequirements: string().required(),
  projectDescription: string().required(),
  engagementType: string().required(),
  contractLength: string().nullable().when('engagementType', {
    is: EngagementType.ShortTerm,
    then: string().required(),
  }),
  freelancingWorkingHoursPerWeek: number().nullable().when('engagementType', {
    is: EngagementType.ShortTerm,
    then: number().required(),
  }),
  hiringThroughUs: boolean(),
  canSkipTermsAcceptance: boolean(),
  acceptTerms: boolean().when('canSkipTermsAcceptance', {
    is: true,
    then: boolean().optional(),
    otherwise: boolean().isTrue(errorMessages.missingTermsAcceptance),
  }),
  subscribers: array()
    .of(
      object().shape({
        id: number(),
        label: string(),
      }),
    )
    .min(1, errorMessages.missingSubscribers),
  isTalentReview: boolean(),
}).required()
