import { BooleanExpectedAnswer, booleanExpectedAnswerLabels, QuestionType } from 'screening/enums'
import { Paragraph, Text } from 'shared/components/atoms'
import { NumericExpectedAnswer } from 'screening/domain'
import { AccountClaim, useAuth } from 'shared/hooks'
import { cn } from 'modules/shared'
import { useMemo } from 'react'

interface Props {
  value?: string | null
  questionType: QuestionType
  expectedAnswer: BooleanExpectedAnswer | NumericExpectedAnswer | null
  required: boolean
}

export const Answer = ({ questionType, value, expectedAnswer, required }: Props) => {
  const {
    user: { claims },
  } = useAuth()

  const hasPermission = claims.includes(AccountClaim.ScreeningManagement)
  const showExpectedAnswer = hasPermission && expectedAnswer

  const correctAnswer = useMemo(() => {
    if (!expectedAnswer) return true

    if (questionType === QuestionType.Boolean) {
      return expectedAnswer === BooleanExpectedAnswer.Both || value === expectedAnswer
    }

    if (questionType === QuestionType.Numeric) {
      const { min } = expectedAnswer as NumericExpectedAnswer
      const numericValue = Number(value)

      return !min || numericValue >= min
    }

    return false
  }, [expectedAnswer, questionType, value])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isBooleanExpectedAnswer = (value: any): value is BooleanExpectedAnswer => {
    return Object.values(BooleanExpectedAnswer).includes(value)
  }

  const expectedAnswerLabel = isBooleanExpectedAnswer(expectedAnswer)
    ? booleanExpectedAnswerLabels[expectedAnswer]
    : expectedAnswer

  const showWarning = hasPermission && !correctAnswer && required
  const showSuccess = hasPermission && correctAnswer && required

  const label = {
    [QuestionType.Boolean]: '',
    [QuestionType.ShortText]: 'Describe your experience in a few words',
    [QuestionType.LongText]: 'Describe your experience',
    [QuestionType.Numeric]: '',
  }

  const content = {
    [QuestionType.Boolean]: (
      <div>
        <div
          className={cn('flex justify-between items-center mt-6 p-6 border rounded-lg mb-2', {
            'bg-warning-lighter': showWarning,
            'bg-success-lighter': showSuccess,
          })}
        >
          {hasPermission && <Paragraph>Candidate's answer</Paragraph>}
          <Paragraph
            size="body-lg"
            weight="font-normal"
            className={cn('text-neutral-darkest capitalize', {
              'text-warning-dark': showWarning,
              'text-success-dark': showSuccess,
            })}
          >
            {value || 'Not provided'}
          </Paragraph>
        </div>

        {showExpectedAnswer && (
          <Paragraph className="text-neutral-dark">
            Expected answer: <span className="text-inherit">{expectedAnswerLabel}</span>
          </Paragraph>
        )}
      </div>
    ),
    [QuestionType.Numeric]: (
      <div>
        <div
          className={cn('flex justify-between items-center mt-6 p-6 border rounded-lg mb-2', {
            'bg-warning-lighter': showWarning,
            'bg-success-lighter': showSuccess,
          })}
        >
          {hasPermission && <Paragraph>Candidate's answer</Paragraph>}
          <Paragraph
            size="body-lg"
            weight="font-normal"
            className={cn('text-neutral-darkest', {
              'text-warning-dark': showWarning,
              'text-success-dark': showSuccess,
            })}
          >
            {value ?? 'Not provided'}
          </Paragraph>
        </div>

        {showExpectedAnswer && (
          <Paragraph className="text-neutral-dark">
            Min value expected: {(expectedAnswer as NumericExpectedAnswer).min}
          </Paragraph>
        )}
      </div>
    ),
  } as Record<QuestionType, JSX.Element>

  if (content[questionType]) return content[questionType]

  return (
    <div className="mt-6">
      <Text weight="font-medium" className="text-neutral-dark">
        {label[questionType]}
      </Text>
      <Paragraph truncate={false} className="mt-2">
        {value ?? 'Not provided'}
      </Paragraph>
    </div>
  )
}
