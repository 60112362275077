import { Link } from 'shared/components/atoms'
import { Paragraph } from 'shared/components/atoms'
import { Candidate } from 'candidates-search/domain'

interface Props {
  candidate: Candidate
}

export const LastExperienceLabel = ({ candidate }: Props) => {
  const experience = candidate.workExperiences[0]

  return (
    <Paragraph size="body-sm" weight="font-normal" className="text-neutral-dark">
      {experience ? (
        <>
          {experience.title}
          {experience.organizationWebsite ? (
            <Link url={experience.organizationWebsite} className="!text-neutral-darkest">
              {' '}
              @{experience.organizationName}
            </Link>
          ) : (
            <span className="text-neutral-darker"> @{experience.organizationName}</span>
          )}{' '}
          • {candidate.experienceTimeLabel}
        </>
      ) : (
        'No work experiences'
      )}
    </Paragraph>
  )
}
