import { Picture } from 'shared/components/atoms'
import { Text } from 'shared/components/atoms'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'
import { ReactComponent as CompanyIcon } from 'icons/company.svg'
import { SensitiveInformation } from 'shared/components/atoms'

interface ModalHeaderProps {
  profile: ProfileOverview
}

export const ModalHeader = ({ profile: { firstName, photoUrl } }: ModalHeaderProps) => {
  return (
    <div className="w-full flex items-center justify-between">
      <Text size="text-2xl" weight="font-medium" className="text-neutral-darkest flex">
        You are about to hire{' '}
        <SensitiveInformation>
          <>{firstName}</>
        </SensitiveInformation>
      </Text>
      <div className="flex">
        <Picture photoUrl={photoUrl} />
        <CompanyIcon className="-ml-4 w-16 h-16 stroke-neutral-light bg-neutral-lightest rounded-full p-2" />
      </div>
    </div>
  )
}
