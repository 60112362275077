import React from 'react'

export const MonitoringContext = React.createContext({
  startReplaySession: async () => {},
})

export const MonitoringProvider = ({ children }: { children: React.ReactNode }) => {
  const startReplaySession = async () => {
    const { Replay, getCurrentHub } = await import(/* webpackPrefetch: true */ '@sentry/react')

    const client = getCurrentHub().getClient()
    const integration = client?.getIntegration(Replay)
    if (client && !integration) {
      client.addIntegration!(
        new Replay({
          networkDetailAllowUrls: [window.location.origin],
        }),
      )
    }
  }

  const monitoringContext = {
    startReplaySession,
  }

  return (
    <MonitoringContext.Provider value={monitoringContext}>{children}</MonitoringContext.Provider>
  )
}
