interface CreateRecruiterRequest {
  firstName: string
  lastName: string
  countryTaxResidence: string
  USTaxPayer: boolean
  phoneNumber?: string
  linkedInUrl: string
  utm: {
    utmSource: string | null
    utmMedium: string | null
    utmCampaign: string | null
    utmTerm: string | null
    utmContent: string | null
  }
}

export interface CreateRecruiterValues {
  firstName: string
  lastName: string
  linkedInUrl: string
  location: {
    country: string
  }
  USTaxPayer: string
  phoneNumber?: string
  isTosAccepted: boolean
  isCommunicationAccepted: boolean
}

export class CreateRecruiterDTO {
  constructor(
    private readonly values: CreateRecruiterValues,
    private readonly urlSearchParams: URLSearchParams,
  ) {}

  toJSON(): CreateRecruiterRequest {
    return {
      firstName: this.values.firstName,
      lastName: this.values.lastName,
      linkedInUrl: this.values.linkedInUrl?.trim(),
      countryTaxResidence: this.values.location.country,
      USTaxPayer: this.values.USTaxPayer === 'true',
      phoneNumber: this.values.phoneNumber || undefined,
      utm: {
        utmSource: this.urlSearchParams.get('utm_source') || null,
        utmMedium: this.urlSearchParams.get('utm_medium') || null,
        utmCampaign: this.urlSearchParams.get('utm_campaign') || null,
        utmTerm: this.urlSearchParams.get('utm_term') || null,
        utmContent: this.urlSearchParams.get('utm_content') || null,
      },
    }
  }
}
